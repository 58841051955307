import { Sheet, TabPanel, Tabs } from "@mui/joy";
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import Task from "./Task";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import { currentTaskSectionIndexAtom } from "recoil/currentTaskSectionIndexAtom";
import { currentDragTaskAtom } from "recoil/currentDragTaskAtom ";
import { openTaskInNewTaskSection, transferTaskToNewSection } from "./logics";
import TaskTabs from "./tab/TaskTabs";

export default function TaskSection({ index }: { index: number }) {
  const [taskSectionState, setTaskSectionState] =
    useRecoilState(taskSectionAtom);
  const { openTaskKeyList, currentTaskKey } = (taskSectionState || [])[index];

  const [currentDragTask, setCurrentDragTask] =
    useRecoilState(currentDragTaskAtom);
  const [, setCurrentTaskSectionIndex] = useRecoilState(
    currentTaskSectionIndexAtom
  );

  useEffect(() => {
    if (
      taskSectionState?.find((element) => element.openTaskKeyList.length === 0)
    ) {
      setTaskSectionState((taskSectionState) =>
        taskSectionState?.filter(
          (element) => element.openTaskKeyList.length > 0
        )
      );
      setCurrentTaskSectionIndex(
        taskSectionState?.filter(
          (element) => element.openTaskKeyList.length > 0
        ).length - 1
      );
    }
  }, [setCurrentTaskSectionIndex, setTaskSectionState, taskSectionState]);

  const setCurrentIndex = useSetRecoilState(currentTaskSectionIndexAtom);

  const dropInExistingSection = () => {
    if (taskSectionState && currentDragTask) {
      transferTaskToNewSection(
        currentDragTask.taskKey,
        taskSectionState,
        setTaskSectionState,
        setCurrentTaskSectionIndex,
        currentDragTask.taskSectionIndex,
        index
      );
    }
    setCurrentDragTask(undefined);
  };

  const dropInNewSection = (isRight?: boolean) => {
    if (taskSectionState && currentDragTask) {
      openTaskInNewTaskSection(
        currentDragTask.taskKey,
        taskSectionState,
        setTaskSectionState,
        setCurrentTaskSectionIndex,
        currentDragTask.taskSectionIndex,
        index + (isRight ? 1 : 0)
      );
    }
    setCurrentDragTask(undefined);
  };

  const showDropZone = (target: HTMLElement) => {
    (target as HTMLElement).style.backgroundColor = "rgba(0,0,0,0.1)";
  };
  const hideDropZone = (target: HTMLElement) => {
    (target as HTMLElement).style.backgroundColor = "transparent";
  };

  function DragZone() {
    return (
      <>
        {[0, 0].map((each, dropzoneIndex) => (
          <div
            key={`dropzone${dropzoneIndex}_${index}`}
            className={`absolute w-[50%] top-0 h-full z-[100] ${
              !currentDragTask && " pointer-events-none"
            }`}
            style={{ left: `${50 * dropzoneIndex}%` }}
            onDrop={(e) => {
              hideDropZone(e.target as HTMLElement);
              dropInNewSection(dropzoneIndex === 1);
            }}
            onDragOver={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onDragEnter={(e) => {
              showDropZone(e.target as HTMLElement);
            }}
            onDragLeave={(e) => {
              hideDropZone(e.target as HTMLElement);
            }}
          />
        ))}
        <div
          className={`absolute w-[50%] left-[50%] translate-x-[-50%] transition-all top-0 h-full z-[100] ${
            !currentDragTask && " pointer-events-none"
          }`}
          onDrop={(e) => {
            hideDropZone(
              document.getElementById(`middle${index}`) as HTMLElement
            );
            dropInExistingSection();
          }}
          onDragOver={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onDragEnter={() => {
            showDropZone(
              document.getElementById(`middle${index}`) as HTMLElement
            );
          }}
          onDragLeave={() => {
            hideDropZone(
              document.getElementById(`middle${index}`) as HTMLElement
            );
          }}
        />
        <div
          id={`middle${index}`}
          className={`absolute w-[100%] left-0 transition-all top-0 h-full z-[100] pointer-events-none`}
        />
      </>
    );
  }

  return (
    <Sheet
      sx={{ backgroundColor: "background.level2" }}
      className="w-full flex-1 h-full flex flex-col min-w-0"
      onClick={() => {
        setCurrentIndex(index);
      }}
    >
      {openTaskKeyList && openTaskKeyList.length > 0 && (
        <>
          {/* <Tabs
            aria-label={`Basic tabs_${index}`}
            sx={{
              width: "100%",
              height: "var(--Header-height)",
              position: "flex",
            }}
            value={currentTaskKey}
          >
            <TaskTabs index={index} />
          </Tabs> */}
          <Tabs
            aria-label={`Basic tabs_${index}`}
            sx={{
              width: "100%",
              height: "100%",
              position: "flex",
            }}
            value={currentTaskKey}
          >
            <div className="w-full h-full min-h-0 relative">
              {openTaskKeyList.map((taskKey) => (
                <TabPanel
                  key={`taskPanel${taskKey}`}
                  keepMounted
                  value={taskKey}
                  sx={{
                    p: 0,
                    overflow: "scroll",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Task taskKey={taskKey} />
                </TabPanel>
              ))}
              <DragZone />
            </div>
          </Tabs>
        </>
      )}
    </Sheet>
  );
}
