import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  conceptApi,
  CONCEPT_DOMAIN,
  CONCEPT_NOTE_DETAIL_ENDPOINT,
} from "api/conceptApi";

import ButtonWithModal from "components/common/ButtonWithModal";
import Link from "components/common/Link";
import * as _ from "lodash";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";

export default function ConceptNoteConfirmButton({
  conceptNoteKey,
  label,
  disabled,
}: {
  conceptNoteKey: string;
  label: string;
  disabled?: boolean;
}) {
  const queryClient = useQueryClient();
  const patientState = useRecoilValue(patientAtom);

  const confirmConceptNoteQueryFn = useCallback(
    () =>
      conceptApi.confirmConceptNote(
        `${patientState?.patientId}`,
        `${conceptNoteKey}`
      ),
    [patientState?.patientId, conceptNoteKey]
  );

  const { mutate: confirmConceptNote } = useMutation(
    confirmConceptNoteQueryFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_DETAIL_ENDPOINT,
        ]);
      },
    }
  );

  return (
    <ButtonWithModal
      buttonText={""}
      size="lg"
      title={`이 ${label}(으)로 <${label} 살펴보기>를 진행해도 될까요?`}
      render={
        <Link
          text="확정"
          opacity={disabled ? 0.4 : 1}
          textColor="primary.solid"
        />
      }
      disableModalOpen={disabled}
      actionText={"확정하기"}
      action={confirmConceptNote}
    />
  );
}
