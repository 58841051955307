import {
  ButtonGroupMultipleChoice,
  DateSelect,
  DividerLine,
  LineWithSolidLabel,
  Percentage,
  SolidLabelLine,
  TextareaLine,
} from "data/CellComponent";
import dayjs from "dayjs";
import {
  Instruction,
  InstructionWithButtonGroup,
  InstructionWithContent,
  InstructionWithDataWithContent,
} from "../../BlockComponent";
import { MeditationType } from "./5-7-T2";

const today = dayjs().format("YYYY-MM-DD");

export const ThoughtRecordTemplate = ({
  isPositiveAvailable,
  isSituationUpgraded,
  isEmotionUpgraded,
  isPhysicalResponseUpgraded,
  isBehaviorUpgraded,
  isWillingnessAvailable,
  isFromMeditationRecord,
  meditationList,
  includeLastBlock,
}: {
  isPositiveAvailable?: boolean;
  isSituationUpgraded?: boolean;
  isEmotionUpgraded?: boolean;
  isPhysicalResponseUpgraded?: boolean;
  isBehaviorUpgraded?: boolean;
  isWillingnessAvailable?: boolean;
  isFromMeditationRecord?: boolean;
  meditationList?: MeditationType[];
  includeLastBlock?: boolean;
}) => [
  ...(isPositiveAvailable
    ? [
        InstructionWithButtonGroup(
          `template_sentiment`,
          [
            {
              text: "나쁜 일이에요",
              blockIdForBranch: "template_end",
              showBlockIdList: [
                "emotion_negative",
                "impactScore_after",
                "meditation_0",
                "meditation_1",
                "meditation_2",
              ],
              hideBlockIdList: ["emotion_positive"],
            },
            {
              text: "좋은 일이에요",
              blockIdForBranch: "template_end",
              showBlockIdList: ["emotion_positive"],
              hideBlockIdList: [
                "emotion_negative",
                "impactScore_after",
                "meditation_0",
                "meditation_1",
                "meditation_2",
              ],
            },
          ],
          { id: "sentiment" }
        ),
      ]
    : []),
  InstructionWithContent(`template_impact`, [
    ...LineWithSolidLabel("영향 정도", [
      Percentage({
        id: `percentageThought`,
      }),
    ]),
  ]),
  InstructionWithContent(
    `template_when`,
    LineWithSolidLabel("날짜", [DateSelect({ id: "date", value: today })])
  ),

  InstructionWithContent(
    `template_situation`,
    isSituationUpgraded
      ? [
          ...LineWithSolidLabel(
            "간접 상황",
            TextareaLine({
              id: "situation_indirect",
              placeholder: "최근의 맥락",
            })
          ),
          ...LineWithSolidLabel(
            "직접 상황",
            TextareaLine({
              id: "situation_direct",
              placeholder: "직전의 트리거",
            })
          ),
        ]
      : [...LineWithSolidLabel("상황", TextareaLine({ id: "situation" }))]
  ),
  InstructionWithContent(`template_thought`, [
    ...LineWithSolidLabel("생각", TextareaLine({ id: "thought" })),
  ]),
  ...(isEmotionUpgraded
    ? [
        InstructionWithContent(
          `template_emotion_detail_0`,
          [
            [
              ButtonGroupMultipleChoice(
                negativeEmotionList.map(
                  ({ emotion, detailEmotionList }, index) => ({
                    text: emotion,
                    additionalContent: [
                      // DividerLine({ id: `divider_${emotion}` }),
                      SolidLabelLine(emotion, {
                        id: `label_${emotion}`,
                      }),
                      [
                        ButtonGroupMultipleChoice(
                          detailEmotionList.map((each) => ({
                            text: each,
                          })),
                          { id: `emotion_detail_${emotion}` }
                        ),
                      ],
                    ],
                  })
                ),
                { id: "emotion_domain" }
              ),
            ],
          ],
          { blockId: "emotion_negative" }
        ),
        InstructionWithContent(
          `template_emotion_detail_0`,
          [
            [
              ButtonGroupMultipleChoice(
                positiveEmotionList.map(
                  ({ emotion, detailEmotionList }, index) => ({
                    text: emotion,
                    additionalContent: [
                      // DividerLine({ id: `divider_${emotion}` }),
                      SolidLabelLine(emotion, {
                        id: `label_${emotion}`,
                      }),
                      [
                        ButtonGroupMultipleChoice(
                          detailEmotionList.map((each) => ({
                            text: each,
                          })),
                          { id: `emotion_detail_${emotion}` }
                        ),
                      ],
                    ],
                  })
                ),
                { id: "emotion_domain" }
              ),
            ],
          ],
          { blockId: "emotion_positive" }
        ),
      ]
    : [
        InstructionWithContent(
          `template_emotion`,
          LineWithSolidLabel("감정", TextareaLine({ id: "emotion" }))
        ),
      ]),
  InstructionWithContent(
    `template_physicalResponse`,

    isPhysicalResponseUpgraded
      ? [
          ...LineWithSolidLabel(
            "신체의 변화",
            TextareaLine({
              id: "physicalResponse_physiological",
              placeholder: "피부, 근육, 장기의 생리적 반응",
            })
          ),
          ...LineWithSolidLabel(
            "신체의 언어적 표현",
            TextareaLine({
              id: "physicalResponse_expressive",
              placeholder: "얼굴과 몸의 반사적인 반응",
            })
          ),
        ]
      : [
          ...LineWithSolidLabel(
            "신체 반응",
            TextareaLine({ id: "physicalResponse" })
          ),
        ],
    { blockId: "physicalResponse" }
  ),
  InstructionWithContent(
    `template_behavior`,
    isBehaviorUpgraded
      ? [
          ...LineWithSolidLabel(
            "충동",
            TextareaLine({ id: "behavior_impulse" })
          ),
          ...LineWithSolidLabel(
            "실제 행동",
            TextareaLine({ id: "behavior_real" })
          ),
        ]
      : [...LineWithSolidLabel("행동", TextareaLine({ id: "behavior" }))],

    { blockId: "template_end" }
  ),
  ...(isWillingnessAvailable
    ? [
        ...ResetImpactLevel,
        ...(!isFromMeditationRecord &&
        meditationList &&
        meditationList.includes("grounding")
          ? [
              InstructionWithButtonGroup(
                `template_meditation_0`,
                [
                  {
                    text: "네",
                    showFromId: "meditation_1",
                    hideFromId: "thoughtRecord_last",
                  },
                  {
                    text: "아니요",
                    showFromId: "thoughtRecord_last",
                    hideFromId: "meditation_1",
                  },
                ],
                undefined,
                { blockId: "meditation_0" }
              ),

              InstructionWithButtonGroup(
                "template_meditation_1",
                [
                  {
                    text: "감정 - 심호흡 그라운딩",
                    showFromId: "grounding",
                    hideFromId: "relaxation",
                  },
                  {
                    text: "신체 반응 - 근육이완법",
                    showFromId: "relaxation",
                    hideFromId: "grounding",
                  },
                  ...(meditationList?.includes("breath")
                    ? [
                        {
                          text: "생각 - 호흡 명상",
                          showFromId: "breath",
                          hideFromId: "grounding",
                        },
                      ]
                    : []),
                  ...(meditationList?.includes("willingness")
                    ? [
                        {
                          text: "심하진 않음 - 기꺼이 경험하기",
                          showFromId: "willingness",
                          hideFromId: "grounding",
                        },
                      ]
                    : []),
                ],
                { id: "meditation_type" },
                { blockId: "meditation_1" }
              ),
              Instruction("template_meditation_2", { blockId: "meditation_2" }),
            ]
          : []),
      ]
    : []),
  ...(includeLastBlock ? ThoughtRecordLastCard : []),
];

export const ResetImpactLevel = [
  InstructionWithDataWithContent(
    { A: { id: "percentageThought" } },
    `template_impact_after`,
    [...LineWithSolidLabel("영향 정도", [Percentage()])],
    { blockId: "impactScore_after" }
  ),
];

export const ThoughtRecordLastCard = [
  Instruction("last_typography_0", { blockId: "thoughtRecord_last" }),
];

const negativeEmotionList = [
  {
    emotion: "분노",
    detailEmotionList: [
      "화남",
      "비통함",
      "격노",
      "분통",
      "복수심",
      "심술남",
      "속상함",
      "토라짐",
      "짜증",
      "노여움",
      "안달남",
      "잔인함",
      "언짢음",
      "약오름",
      "불만족",
      "적대감",
      "악의에 참",
    ],
  },
  {
    emotion: "혐오감",
    detailEmotionList: [
      "역겨움",
      "싫음",
      "불쾌함",
      "반감",
      "구역질이 남",
      "질색함",
      "생색냄",
      "조롱",
      "증오",
      "섬뜩함",
      "저항감",
      "앙심",
      "악감정",
      "경멸",
      "무시",
      "질색",
      "지긋지긋함",
      "멸시",
    ],
  },
  {
    emotion: "부러움",
    detailEmotionList: [
      "열망",
      "불쾌함",
      "탐욕",
      "옹졸함",
      "씁쓸함",
      "불만족",
      "못마땅함",
      "시기",
      "분함",
      "탐냄",
      "언짢음",
      "낙담",
      "소유욕",
    ],
  },
  {
    emotion: "두려움",
    detailEmotionList: [
      "공포",
      "경악",
      "겁이 남",
      "충격",
      "거북함",
      "불안",
      "초조함",
      "히스테리",
      "압도됨",
      "긴장됨",
      "걱정",
      "우려",
      "놀람",
      "조마조마함",
      "공황",
      "무서움",
    ],
  },
  {
    emotion: "질투",
    detailEmotionList: [
      "집착",
      "경쟁심",
      "경계심",
      "조심함",
      "방어적인 태도",
      "의심",
      "불신",
      "소유욕",
    ],
  },
  {
    emotion: "슬픔",
    detailEmotionList: [
      "실망",
      "연민",
      "꺾임",
      "단절",
      "우울",
      "절망",
      "향수",
      "번뇌",
      "불쾌함",
      "괴로움",
      "침울",
      "비탄",
      "무시",
      "낭패",
      "불안정",
      "낙담",
      "비참",
      "소외",
      "상처",
      "서러움",
      "암울",
      "고립",
      "불만족",
      "거부당한 느낌",
      "패배감",
      "외로움",
      "비애",
      "심란함",
      "불행",
    ],
  },
  {
    emotion: "수치감",
    detailEmotionList: [
      "비난받는 느낌",
      "당혹감",
      "굴욕",
      "부끄러움",
      "회한",
      "심란함",
      "창피함",
    ],
  },
  {
    emotion: "죄책감",
    detailEmotionList: ["비난받는 느낌", "회한", "미안함", "후회", "안쓰러움"],
  },
];

const positiveEmotionList = [
  {
    emotion: "행복",
    detailEmotionList: [
      "만족",
      "즐거움",
      "유쾌함",
      "황홀함",
      "기쁨",
      "축복",
      "승리감",
      "반가움",
      "흥미",
      "열광",
      "귀여움",
      "열정",
      "자긍심",
      "안도감",
      "명랑함",
      "흥분",
      "열의",
      "의기양양함",
      "재미",
      "설렘",
      "환희",
      "흥겨움",
      "신남",
      "매혹",
      "흥취",
      "엉뚱함",
      "편안함",
      "희망",
      "희열",
      "발랄함",
    ],
  },
  {
    emotion: "사랑",
    detailEmotionList: [
      "매력",
      "황홀함",
      "집착",
      "동정",
      "흠모",
      "배려",
      "우애",
      "갈망",
      "다정다감",
      "애착",
      "매혹",
      "심취",
      "욕망",
      "따뜻함",
      "흥분",
      "연민",
      "친절함",
      "열정",
      "좋아함",
      "감동",
    ],
  },
];
