import { Button } from "@mui/joy";
import useDetectIOS from "hooks/useDetectIOS";
import useIsInappWebview from "hooks/useIsInappWebview";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import useAppleLogin, {
  apple_cliendId,
  apple_redirectUrl,
} from "hooks/useAppleLogin";
import useGoogleLogin from "hooks/useGoogleLogin";
import useKakaoLogin from "hooks/useKakaoLogin";
import apple_logo from "static/image/common/apple_logo.png";
import kakao_logo from "static/image/common/kakao_logo.png";
import google_logo from "static/image/common/google_logo.png";
import AppleLogin from "react-apple-login";
import { isBrowser } from "react-device-detect";
import { useTranslation } from "react-i18next";

export default function LoginButtonByDevice({
  loginType,
}: {
  loginType?: "apple" | "google" | "kakao";
}) {
  const user = useRecoilValue(userAtom);
  const disabled = !!user?.accessToken;

  const handleKakaoLogin = useKakaoLogin();
  const handleAppleLogin = useAppleLogin();
  const handleGooglelogin = useGoogleLogin();
  const { isIOS } = useDetectIOS();
  const isInappWebview = useIsInappWebview();

  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  const loginDataByType: {
    [key in "apple" | "google" | "kakao"]: {
      logo?: string;
      buttonText: string;
      backgroundColor: string;
      color: string;
      borderColor?: string;
      action: () => void;
    };
  } = {
    apple: {
      logo: apple_logo,
      buttonText: t_ui("로그인", { type: "Apple" }),
      backgroundColor: "#000000",
      color: "#ffffff",
      action: handleAppleLogin,
    },
    google: {
      logo: google_logo,
      buttonText: t_ui("로그인", { type: "Google" }),
      backgroundColor: "#ffffff",
      color: "#3A3C40",
      borderColor: "rgba(0,0,0,0.1)",
      action: handleGooglelogin,
    },
    kakao: {
      logo: kakao_logo,
      buttonText: "카카오 로그인",
      backgroundColor: "#FEE500",
      color: "#232832",
      action: handleKakaoLogin,
    },
  };

  const type = loginType ? loginType : isIOS ? "apple" : "google";

  const currentLoginData = loginDataByType[type];

  function ButtonTemplate({ onClick }: { onClick?: () => void }) {
    return (
      <Button
        onClick={
          onClick ? onClick : !disabled ? currentLoginData.action : () => {}
        }
        sx={{
          backgroundColor: currentLoginData.backgroundColor,
          border: `1px solid ${currentLoginData.borderColor}`,

          color: currentLoginData.color,
          "&:hover": {
            backgroundColor: `${currentLoginData.backgroundColor}88`,
          },
          opacity: disabled ? 0.3 : 1,
        }}
        startDecorator={
          <img
            src={currentLoginData.logo}
            alt={"empty"}
            className="w-[20px] "
          />
        }
      >
        {!user?.accessToken ? currentLoginData.buttonText : t_ui("로그인 완료")}
      </Button>
    );
  }

  return isBrowser && type === "apple" ? (
    <AppleLogin
      clientId={apple_cliendId}
      redirectURI={apple_redirectUrl}
      responseType="code id_token"
      responseMode="fragment"
      render={(props) => <ButtonTemplate onClick={props.onClick} />}
    />
  ) : (
    <ButtonTemplate />
  );
}
