import { atom } from "recoil";

interface CurrentDragTaskType {
  taskKey: string;
  taskSectionIndex: number;
}

export const currentDragTaskAtom = atom<CurrentDragTaskType | undefined>({
  key: "current-drag-task",
  default: undefined,
});
