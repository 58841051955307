import { ButtonGroupMultipleChoice } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
} from "../../BlockComponent";
import {
  SegmentationNoteLastCard,
  SegmentationNoteTemplate,
} from "./segmentationNote";

const NotificationTimeBlock = InstructionWithContent(
  "notification_2",
  [
    [
      ButtonGroupMultipleChoice(
        [
          {
            text: "저녁 7시",
          },
          {
            text: "저녁 8시",
          },
          {
            text: "저녁 9시",
          },
          {
            text: "밤 10시",
          },
          {
            text: "밤 11시",
          },
          {
            text: "밤 12시",
          },
        ],
        { id: "notification_time" }
      ),
    ],
  ],
  { blockId: "알림 허용" }
);
export const Content_5_4 = (isPushNotificationGranted: boolean) => [
  ...SegmentationNoteTemplate({
    isThoughtTrapAvailable: false,
  }),
  Header1("initial_typography_1"),
  Instruction("initial_typography_2"),
  Instruction("initial_typography_3"),
  Instruction("initial_typography_4"),
  Instruction("initial_typography_5"),
  Instruction("initial_typography_6"),
  Instruction("initial_typography_7"),
  Instruction("initial_typography_8"),
  Instruction("initial_typography_9"),
  Instruction("initial_typography_10"),
  Instruction("initial_typography_11"),
  ...(!isPushNotificationGranted
    ? [
        InstructionWithContent("notification_1", [
          [
            {
              type: "pushNotification",
              content: { showFromIdIfDenied: "알림 미허용" },
            },
          ],
        ]),
        NotificationTimeBlock,
      ]
    : [NotificationTimeBlock]),
  ...SegmentationNoteLastCard,
];