import { useRecoilValue } from "recoil";
import { Stack, Typography } from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import TestResult from "components/dashboard/TestResult";
import { patientAtom } from "recoil/patientAtom";
import {
  dashboardApi,
  DashboardType,
  DASHBOARD_DETAIL_ENDPOINT,
  DASHBOARD_DOMAIN,
} from "api/dashboardApi";
import { useQuery } from "@tanstack/react-query";
import ContentTemplate from "../content/ContentTemplate";
import Progress from "components/dashboard/Progress";
import ConcernAndGoal from "components/dashboard/ConcernAndGoal";
import { taskListAtom } from "recoil/taskListAtom";

export default function MyProgressContent() {
  const patientState = useRecoilValue(patientAtom);

  const queryFn = useCallback(
    () => dashboardApi.load(`${patientState?.patientId}`),
    [patientState]
  );
  const [dashboardData, setDashboardData] = useState<DashboardType>();
  useQuery([DASHBOARD_DOMAIN, DASHBOARD_DETAIL_ENDPOINT], queryFn, {
    enabled: !!patientState?.patientId,
    onSuccess: (data) => {
      setDashboardData(data.result);
    },
  });

  useEffect(() => {
    if (!patientState?.patientId) {
      setDashboardData({
        concept: {},
        history: {},
        selfCheckResult: {},
      });
    }
  }, [patientState]);

  const taskListState = useRecoilValue(taskListAtom);
  const is1_2_A_done = taskListState.find(
    (element) => element.taskId === "1-2-A"
  )?.lastDoneAt;

  return (
    <ContentTemplate>
      {dashboardData && (
        <Stack spacing={"100px"} sx={{}}>
          <Stack spacing={"16px"}>
            <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
              나의 프로그램
            </Typography>
            <ConcernAndGoal />
          </Stack>

          {is1_2_A_done && <Progress />}
          <Stack spacing={2}>
            <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
              부정적 반응
            </Typography>
            <TestResult {...dashboardData.selfCheckResult} />
          </Stack>
        </Stack>
      )}
    </ContentTemplate>
  );
}
