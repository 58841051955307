import { Box, CircularProgress, Stack, Typography } from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  HumanCheckTaskType,
  workflowApi,
  WorkflowType,
  WORKFLOW_DETAIL_ENDPOINT,
  WORKFLOW_DOMAIN,
  WORKFLOW_HUMAN_CHECK_DETAIL_ENDPOINT,
  WorkIdType,
  WorkStatusType,
} from "api/workflowApi";
import { patientAtom } from "recoil/patientAtom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { theme } from "styles/theme";
import Link from "components/common/Link";
import PresetTask from "./PresetTask";
import { CONCEPT_DOMAIN, CONCEPT_NOTE_DETAIL_ENDPOINT } from "api/conceptApi";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import TooManyTurnsTask from "./TooManyTurnsTask";
import DisagreeTask from "./DisagreeTask";
import TrackToggleButton from "./TrackToggleButton";
import useRefetchTaskList from "hooks/useRefetchTaskList";

export function Status({ status }: { status: WorkStatusType }) {
  return (
    <Box
      sx={{
        width: "20px",
        height: "20px",
        p: "0px",
        flexShrink: 0,
      }}
    >
      <Stack
        justifyContent={"center"}
        alignItems="center"
        sx={{
          width: "100%",
          height: "100%",
          p: "2px",
          backgroundColor: status === "ready" ? "primary.soft" : undefined,
          borderRadius: "50%",
          borderColor:
            status === "human_check_required" ? "danger.500" : "primary.solid",
          borderWidth:
            status === "completed" || status === "human_check_required"
              ? "2px"
              : 0,
        }}
      >
        {status !== "ready" &&
          (status === "processing" ? (
            <Stack
              alignItems={"center"}
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <CircularProgress
                size="sm"
                color="primary"
                sx={{
                  "--CircularProgress-size": "20px",
                  "--CircularProgress-trackThickness": "2px",
                  "--CircularProgress-progressThickness": "2px",
                  "--CircularProgress-trackColor":
                    theme.vars.palette.primary.soft,
                }}
              />
            </Stack>
          ) : status === "completed" ? (
            <Box
              sx={{
                color: "primary.solid",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{ fontSize: "inherit", fontWeight: 700 }}
                className="material-symbols-rounded"
              >
                check
              </span>
            </Box>
          ) : status === "human_check_required" ? (
            <Box
              sx={{
                color: "danger.500",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{ fontSize: "inherit", fontWeight: 700 }}
                className="material-symbols-rounded"
              >
                exclamation
              </span>
            </Box>
          ) : (
            <></>
          ))}
      </Stack>
    </Box>
  );
}

function Work({
  workflowId,
  workId,
  status,
  previousWorkDone,
}: {
  workflowId: string;
  workId: WorkIdType;
  status: WorkStatusType;
  previousWorkDone: boolean;
}) {
  const workflowMapping: {
    [key in WorkIdType]: { title: string; autoUnavailable?: boolean };
  } = {
    start_update: { title: "업데이트 시작", autoUnavailable: true },
    check_chat: { title: "채팅 확인/답장", autoUnavailable: true },
    comment_task: {
      title: "활동지 확인/코멘트",
      autoUnavailable: true,
    },
    update_concept_note: {
      title: "생기지 확인/개념화",
      autoUnavailable: true,
    },
    confirm_concept_note: {
      title: "개념화 노트 확정",
      autoUnavailable: true,
    },
    comment_thought_record: {
      title: "생기지 코멘트",
      autoUnavailable: true,
    },
    update_task: { title: "활동지 교체" },
    preset_task: { title: "활동지 세팅" },
    send_task_update_message: { title: "업데이트 알리기" },
  };
  const queryClient = useQueryClient();
  const patientState = useRecoilValue(patientAtom);

  const resetTaskSection = useResetRecoilState(taskSectionAtom);

  type humanCheckType = "preset" | "too-many-turns" | "disagree";
  const [humanCheckTaskList, setHumanCheckTaskList] = useState<
    (HumanCheckTaskType & { type?: humanCheckType })[] | undefined
  >();

  const workCompleteQueryFn = useCallback(
    () =>
      workflowApi.complete(`${patientState?.patientId}`, workflowId, workId),
    [patientState?.patientId, workflowId, workId]
  );

  const { mutate: complete } = useMutation(workCompleteQueryFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        WORKFLOW_DOMAIN,
        WORKFLOW_DETAIL_ENDPOINT,
      ]);
      if (workId === "comment_task") {
        resetTaskSection();
      }
      setHumanCheckTaskList(undefined);
    },
  });

  const humanCheckDetailQueryFn = useCallback(
    () =>
      workflowApi.getHumanCheckDetail(
        `${patientState?.patientId}`,
        workflowId,
        workId
      ),
    [patientState?.patientId, workflowId]
  );
  useQuery(
    [WORKFLOW_DOMAIN, WORKFLOW_HUMAN_CHECK_DETAIL_ENDPOINT],
    humanCheckDetailQueryFn,
    {
      enabled: status === "human_check_required",
      onSuccess: (data) => {
        if (data && data.result) {
          const presetTaskList = data.result.presetTaskList;
          const tooManyTurnsTaskList = data.result.tooManyTurnsTaskList;
          const disagreeTaskList = data.result.disagreeTaskList;
          setHumanCheckTaskList([
            ...(presetTaskList?.map((each) => ({
              ...each,
              type: "preset" as humanCheckType,
            })) || []),
            ...(tooManyTurnsTaskList?.map((each) => ({
              ...each,
              type: "too-many-turns" as humanCheckType,
            })) || []),
            ...(disagreeTaskList?.map((each) => ({
              ...each,
              type: "disagree" as humanCheckType,
            })) || []),
          ]);
        }
      },
    }
  );

  useEffect(() => {
    if (status === "human_check_required") {
      if (workId === "send_task_update_message") {
        setHumanCheckTaskList([{ taskKey: "chat", isCompleted: false }]);
      }
    } else {
      setHumanCheckTaskList(undefined);
    }
  }, [status]);

  return (
    <Stack direction={"row"} spacing={"12px"} alignItems="start">
      <Status status={status} />
      <Stack direction={"column"} sx={{ flex: 1 }}>
        <Stack
          direction={"row"}
          spacing={"12px"}
          justifyContent="space-between"
        >
          <Typography>{workflowMapping[workId]?.title}</Typography>
          {status === "ready" &&
            previousWorkDone &&
            workflowMapping[workId]?.autoUnavailable && (
              <Link
                text="완료"
                opacity={1}
                textColor="primary.solid"
                onClick={complete}
              />
            )}
        </Stack>
        {status === "human_check_required" &&
          humanCheckTaskList?.map(({ taskKey, isCompleted, type }, index) =>
            type === "too-many-turns" ? (
              <TooManyTurnsTask
                key={`too-many-turns_task_${taskKey}`}
                taskKey={taskKey}
                isCompleted={isCompleted}
                workflowId={workflowId}
                showDoneButton={workId !== "send_task_update_message"}
                isLast={humanCheckTaskList
                  .filter((element) => element.taskKey !== taskKey)
                  .every((element) => element.isCompleted)}
                workCompleteAction={complete}
              />
            ) : type === "disagree" ? (
              <DisagreeTask
                key={`disagree_task_${taskKey}`}
                taskKey={taskKey}
                isCompleted={isCompleted}
                workflowId={workflowId}
                showDoneButton={workId !== "send_task_update_message"}
                isLast={humanCheckTaskList
                  .filter((element) => element.taskKey !== taskKey)
                  .every((element) => element.isCompleted)}
                workCompleteAction={complete}
              />
            ) : (
              <PresetTask
                key={`preset_task_${taskKey}`}
                taskKey={taskKey}
                isCompleted={isCompleted}
                workflowId={workflowId}
                showDoneButton={workId !== "send_task_update_message"}
                isLast={humanCheckTaskList
                  .filter((element) => element.taskKey !== taskKey)
                  .every((element) => element.isCompleted)}
                workCompleteAction={complete}
              />
            )
          )}
      </Stack>
    </Stack>
  );
}

export default function CoachWorkflow() {
  const queryClient = useQueryClient();
  const patientState = useRecoilValue(patientAtom);

  const [workflowDetail, setWorkflowDetail] = useState<WorkflowType>();

  const isProcessing = workflowDetail?.isInProgress;
  const refetchTaskList = useRefetchTaskList();

  const workflowDetailQueryFn = useCallback(
    () => workflowApi.getDetail(`${patientState?.patientId}`),
    [patientState?.patientId]
  );
  useQuery(
    [WORKFLOW_DOMAIN, WORKFLOW_DETAIL_ENDPOINT, patientState?.patientId],
    workflowDetailQueryFn,
    {
      ...(isProcessing && { refetchInterval: 1000 }),
      onSuccess: (data) => {
        if (data && data.result) {
          const workflow = data.result;
          console.log("WORKFLOW: ", workflow, workflow.workList);
          setWorkflowDetail(workflow);

          const updateTaskWork = workflow.workList.find(
            (element) => element.workId === "update_task"
          );
          const updateTaskWorkSeq =
            workflow.workList.find(
              (element) => element.workId === "update_task"
            )?.seq || 0;
          if (
            updateTaskWork?.status === "completed" &&
            workflow.workList.find(
              (element) => element.seq === updateTaskWorkSeq + 1
            )?.status === "ready"
          ) {
            refetchTaskList();
            queryClient.invalidateQueries([
              CONCEPT_DOMAIN,
              CONCEPT_NOTE_DETAIL_ENDPOINT,
              patientState?.patientId,
            ]);
          }
        }
      },
    }
  );

  return workflowDetail ? (
    <Stack direction={"column"} spacing={1} sx={{ py: "12px" }}>
      {workflowDetail.workList.map(({ workId, seq, status }, index) => (
        <div key={workId}>
          <Stack direction={"column"}>
            <Work
              key={workId}
              workflowId={workflowDetail.workflowId}
              workId={workId}
              status={status}
              previousWorkDone={
                index === 0 ||
                workflowDetail.workList[index - 1].status === "completed"
              }
            />
            {/* {index < workflowDetail?.workList.length - 1 && (
                <>
                  <Progressbar
                    percentage={
                      status === "completed"
                        ? 100
                        : status === "processing"
                        ? 50
                        : 0
                    }
                  />
                </>
              )} */}
          </Stack>
        </div>
      ))}
      {/* <TrackToggleButton /> */}
    </Stack>
  ) : (
    <></>
  );
}
