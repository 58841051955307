import { Dayjs } from "dayjs";
import { atom } from "recoil";

export interface TokenType {
  accessToken?: string;
}

export const tokenAtom = atom<TokenType | undefined>({
  key: "token",
  default: undefined,
});
