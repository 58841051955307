export const behavioralActivationResource = {
  en: {},
  ko: {
    날짜: "날짜",
    시각: "시각",
    활동: "활동",
    "감정/생각": "감정/생각",
    기분: "기분",
  },
};
