import { useQuery } from "@tanstack/react-query";
import {
  conceptApi,
  CONCEPT_DETAIL_ENDPOINT,
  CONCEPT_DOMAIN,
  CONCEPT_NOTE_DETAIL_ENDPOINT,
} from "api/conceptApi";
import { convertNoteToReal } from "components/ai/InsideC";
import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { conceptAtom } from "recoil/conceptAtom";
import { conceptNoteAtom } from "recoil/conceptNoteAtom";
import { patientAtom } from "recoil/patientAtom";

export default function useSetConceptData({
  taskKey,
}: { taskKey?: string } = {}) {
  const patientState = useRecoilValue(patientAtom);

  const setConceptNoteState = useSetRecoilState(conceptNoteAtom);
  const setConceptState = useSetRecoilState(conceptAtom);

  const getConceptNotequeryFn = useCallback(
    () => conceptApi.noteDetail(`${patientState?.patientId}`, taskKey),
    [patientState?.patientId, taskKey]
  );

  const { isFetching: isConceptNoteFetching } = useQuery(
    [
      CONCEPT_DOMAIN,
      CONCEPT_NOTE_DETAIL_ENDPOINT,
      patientState?.patientId,
      taskKey,
    ],
    getConceptNotequeryFn,
    {
      enabled: !!patientState?.patientId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data && data.result) {
          const conceptNote = convertNoteToReal(data.result);
          console.log(
            "CONCEPT_NOTE_DETAIL:",
            taskKey,
            patientState?.patientId,
            conceptNote
          );
          setConceptNoteState({ ...conceptNote, count: 0 });
        }
      },
    }
  );
  const getConceptqueryFn = useCallback(
    () => conceptApi.detail(`${patientState?.patientId}`, taskKey),
    [patientState?.patientId, taskKey]
  );

  const { isFetching: isConceptFetching } = useQuery(
    [CONCEPT_DOMAIN, CONCEPT_DETAIL_ENDPOINT, patientState?.patientId, taskKey],
    getConceptqueryFn,
    {
      enabled: !!patientState?.patientId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data && data.result) {
          const concept = data.result;
          console.log(
            "[TEST]",
            "CONCEPT_DETAIL:",
            taskKey,
            patientState?.patientId,
            concept
          );
          setConceptState(concept);
        }
      },
    }
  );

  return { isConceptNoteFetching, isConceptFetching };
}
