export const translation6_5_A_common = {
  en: {
    v1: {
      part1: {
        part1_typography_0_0_0:
          "Let's put my automatic thoughts together and reflect.",
        part1_typography_1_0_0:
          "Considering these automatic thoughts, what kind of person might I be? Are there common threads in these thoughts?",
        part1_typography_2_0_0:
          "Try writing down how you see yourself, others, and the world. Do you notice a consistent perspective? If it's hard to decide based on these notes, feel free to leave it blank.",
        part1_typography_3_0_0:
          "Do you think these thoughts genuinely represent your views about yourself, others, and the world?",
      },
      part2: {
        part2_typography_0_0_0:
          "What thoughts have we been surrendering to, overcompensating for, or avoiding? Let's explore deeper.",
        part2_typography_1_0_0: `Let's explore what we are surrendering to.`,
        part2_typography_2_0_0: `What thoughts have I been passively accepting? What core belief might be underlying this?`,
        part2_typography_3_0_0: `What do you think might be the "?" in the thought map?`,
        part2_typography_4_0_0:
          "Let's explore what we are overcompensating for.",
        part2_typography_5_0_0: `What thoughts have I been trying to disprove? What core belief could be driving this?`,
        part2_typography_6_0_0: `What seems to be the "?" in the thought map?`,
        part2_typography_7_0_0: `Let's explore what we are avoiding.`,
        part2_typography_8_0_0: `Which thoughts have I been trying to dodge? What core belief could be behind this?`,
        part2_typography_9_0_0: `What do you think is the "?" in the thought map?`,
      },
    },
  },
  ko: {
    v1: {
      part1: {
        part1_typography_0_0_0:
          "자, 나의 자동적 사고들을 종합해서 생각해볼게요.",
        part1_typography_1_0_0:
          "위와 같은 자동적 사고를 하는 나는 어떤 사람일까요? 세 가지 자동적 사고에서 나타나는 공통점이 있을까요?",
        part1_typography_2_0_0:
          "자신, 타인, 세상을 어떻게 평가하고 바라보는지, 특별히 일관되게 가지고 있는 입장이 있는지 적어보세요. 위 기록만으로 판단할 수 없는 것 같다면 비워두어도 좋아요.",
        part1_typography_3_0_0:
          "실제 나는 자신, 타인, 세상에 대해 위와 같은 생각을 가지고 있다고 생각하나요?",
      },
      part2: {
        part2_typography_0_0_0:
          "그렇다면 우리는 어떤 생각에 대해 굴복, 과잉보상, 회피하고 있었던 걸까요? 더 깊은 곳으로 들어가봐요.",
        part2_typography_1_0_0: `무엇에 굴복하고 있는지 살펴볼게요.`,
        part2_typography_2_0_0: `나는 어떠한 생각에 그대로 순응하고 있었던 걸까요? 이면엔 어떤 핵심 믿음이 있는 걸까요?`,
        part2_typography_3_0_0: `생각 지도의 "?"에는 어떠한 생각이 있는 것 같나요?`,
        part2_typography_4_0_0: "무엇에 과잉보상하고 있는지 살펴볼게요.",
        part2_typography_5_0_0: `나는 어떠한 생각이 틀렸음을 증명하려고 했던 걸까요? 이면엔 어떤 핵심 믿음이 있는 걸까요?`,
        part2_typography_6_0_0: `생각 지도의 "?"에는 어떠한 생각이 있는 것 같나요?`,
        part2_typography_7_0_0: `무엇에 회피하고 있는지 살펴볼게요.`,
        part2_typography_8_0_0: `나는 어떠한 생각을 마주하지 않고자 했던 걸까요? 이면엔 어떤 핵심 믿음이 있는 걸까요?`,
        part2_typography_9_0_0: `생각 지도의 "?"에는 어떠한 생각이 있는 것 같나요?`,
      },
    },
  },
};

export const translation_6_5_A = {
  en: {
    v1: {
      typography_0_0_0:
        "Do you recall our talk about core beliefs? Those deep-seated, fundamental beliefs we hold about ourselves, others, and our identity?",
      typography_1_0_0:
        "We're about to dive into an activity to unearth these core beliefs.",
      typography_2_0_0:
        "Finding them can be challenging since they lie at the very heart of our deepest thoughts. ",
      typography_3_0_0:
        "Remember, if it gets tough, you can always ask for help.",
      typography_4_0_0: "Let's take this step by step.",
      typography_5_0_0: "Finding common threads",
      typography_6_0_0:
        "I'll walk you through the patterns of my automatic thoughts, one by one.",
      typography_7_0_0: "Here's the first automatic thought.",
      typography_8_0_0: "Now, the second one.",
      typography_9_0_0: "And the third.",

      ...translation6_5_A_common.en.v1.part1,
      typography_10_0_0: "Alright, ready for another activity?",
      typography_11_0_0: `Finding what we cope with`,
      typography_12_0_0:
        "We previously looked into which coping strategies align with three types of automatic thoughts. Let's revisit that.",

      ...translation6_5_A_common.en.v1.part2,
      typography_13_0_0:
        "Great job. It looks like we've gathered enough information to identify our core beliefs.",
      typography_14_0_0: `Shall we call it a day? Next time, we'll sift through this information and pinpoint our core beliefs.`,
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "나, 타인, 그리고 자신에 대한 가장 근원적이고 본질적인 믿음을 ‘핵심 믿음’이라고 한다는 것, 기억하시나요?",
      typography_1_0_0:
        "지금부터는 나의 핵심 믿음을 찾아보는 활동을 할 거예요. ",
      typography_2_0_0:
        "핵심 믿음은 가장 깊은 곳에 있는 중심적인 생각이기 때문에 이를 찾아보는 일이 쉽지 않을 수 있어요. ",
      typography_3_0_0:
        "만약 진행하다가 어렵다면 언제든 도움을 요청하셔도 좋아요.",
      typography_4_0_0: "자, 그럼 하나씩 진행해볼게요.",
      typography_5_0_0: "공통점 찾기",
      typography_6_0_0: "여태 찾은 나의 자동적 사고 패턴을 하나씩 살펴볼게요.",
      typography_7_0_0: "첫 번째 자동적 사고예요.",
      typography_8_0_0: "두 번째 자동적 사고예요.",
      typography_9_0_0: "세 번째 자동적 사고예요.",

      ...translation6_5_A_common.ko.v1.part1,
      typography_10_0_0: "좋아요. 이번에는 다른 활동을 해볼까요?",
      typography_11_0_0: `대처 전략의 대상 찾기`,
      typography_12_0_0:
        "지난 번 우린 세 가지 자동적 사고가 어떤 대처 전략에 해당하는지 알아보았어요. 다시 한번 볼까요?",

      ...translation6_5_A_common.ko.v1.part2,
      typography_13_0_0:
        "좋아요. 핵심 믿음을 찾기 위한 충분한 정보를 수집한 것 같네요.",
      typography_14_0_0: `오늘은 여기까지 해볼까요? 다음 시간에는 이 정보들을 추려서 핵심 믿음을 찾아보도록 할게요.`,
    },
  },
};
