import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import { replaceColor } from "lottie-colorify";
import CheckSolid from "static/lottie/check_solid.json";

export function CheckLottie(props) {
  const { containerSize } = props;
  const likecontainer = useRef();

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: likecontainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: replaceColor("#2C4BEC", "#479683", CheckSolid),
    });
    return () => instance.destroy();
  }, []);

  return (
    <div>
      <div
        style={{ width: containerSize, height: containerSize }}
        ref={likecontainer}
      ></div>
    </div>
  );
}
