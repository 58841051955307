import { Radio, Stack, Typography } from "@mui/joy";
import { ProgramContentType } from "data/BlockComponent";
import { EvaluationType } from "data/selfCheckData";
import * as _ from "lodash";
import { useState } from "react";

export default function SelfCheckRadioButtonCell({
  defaultValue,
  blockIndex,
  evaluation,
  setData,
  lineIndex,
  cellIndex,
}: {
  defaultValue?: number;
  blockIndex: number;
  evaluation: EvaluationType;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
}) {
  const [selected, setSelected] = useState<number | undefined>(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedScore = Number(event.target.value);
    setSelected(selectedScore);
    setData((data) => {
      if (data) {
        const data_temp = _.cloneDeep(data);
        if (event.target.value !== undefined) {
          data_temp[blockIndex].nextBlockId = event.target.value;
        }

        const currentContent =
          data_temp[blockIndex].lines[lineIndex][cellIndex].content || {};
        data_temp[blockIndex].lines[lineIndex][cellIndex].content = {
          ...currentContent,
          selectedScore: selectedScore,
        };
        return data_temp;
      }
      return data;
    });
  };

  return (
    <Stack
      direction={"row"}
      justifyContent="space-between"
      flexWrap={"nowrap"}
      sx={{ maxWidth: "300px", mt: 1 }}
    >
      {/* <Grid container flexGrow={1}>
        {Object.entries(evaluation).map(([key, score]) => (
          <Grid key={key} xs={3}>
            <Stack spacing={1} alignItems={"center"}>
              <Radio
                checked={selected === score}
                onChange={handleChange}
                value={score}
                name="radio-buttons"
                slotProps={{ input: { "aria-label": "A" } }}
              />
              <Typography level="body-xs">{key}</Typography>
            </Stack>
          </Grid>
        ))}
      </Grid> */}
      {Object.entries(evaluation).map(([key, score]) => (
        <Stack key={key} sx={{ flex: 1 }}>
          <Stack spacing={1} alignItems={"center"}>
            <Radio
              checked={selected === score}
              onChange={handleChange}
              value={score}
              name="radio-buttons"
              slotProps={{ input: { "aria-label": "A" } }}
            />
            <Typography sx={{ textAlign: "center" }} level="body-xs">
              {key}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
