import { Box, Typography } from "@mui/joy";
import { findSubstringIndices } from "logic/logics";
import Link from "components/common/Link";

export default function TypographyWithLinkSubstring({
  mainString,
  linkSubstrings,
  linkColor,
  linkOpenType,
}: {
  mainString: string;
  linkSubstrings: { linkString: string; url: string }[];
  linkColor?: string;
  linkOpenType?: "modal" | "link";
}) {
  const linkIndices = linkSubstrings.map((each) => ({
    ...each,
    ...findSubstringIndices(mainString, each.linkString),
  }));
  const splittedText = [mainString.substring(0, linkIndices[0].startIndex)];
  linkIndices.forEach(({ startIndex, endIndex }, index) => {
    if (index > 0) {
      splittedText.push(
        mainString.substring(linkIndices[index - 1].endIndex + 1, startIndex)
      );
    }
    splittedText.push(mainString.substring(startIndex, endIndex + 1));
    if (index === linkIndices.length - 1) {
      splittedText.push(mainString.substring(endIndex + 1));
    }
  });

  return (
    <Box>
      {splittedText.map((each, index) =>
        linkSubstrings.map((each) => each.linkString).includes(each) ? (
          <span key={`${each}_${index}`} style={{ display: "inline" }}>
            <Link
              key={`${each}_${index}`}
              level={"body-sm"}
              text={each}
              url={
                linkSubstrings.find(({ linkString }) => linkString === each)
                  ?.url || ""
              }
              opacity={linkColor ? 1 : 0.6}
              textColor={linkColor}
              linkOpenType={linkOpenType}
            />
          </span>
        ) : (
          <span key={`${each}${index}`} style={{ display: "inline" }}>
            <Typography key={`${each}${index}`}>{each}</Typography>
          </span>
        )
      )}
    </Box>
  );
}
