export const translation_9_8_A = {
  en: {},
  ko: {
    v1: {
      typography_0: `다음으로 ‘기꺼이 경험하기’를 적용해 볼 부분은 신체 반응이에요.`,
      typography_1: `신체 반응 또한 많은 분들이 자세히 관찰하는 데에 어려움을 겪는답니다.`,
      typography_2: `단 한 번도 나의 신체를 주의 깊게 바라본 적이 없기 때문이에요.`,
      typography_3: `우리는 앞으로 신체 반응을 다음과 같은 두 가지 영역으로 나눠서 기록해 볼 거랍니다.`,
      typography_4: `생리적 몸의 변화`,
      typography_5: `첫 번째는 ‘생리적 몸의 변화'예요.`,
      typography_6: `쉽게 말하면 피부, 근육, 장기에서 느껴지는 감각이라고 생각해주시면 된답니다.`,
      typography_7: `얼굴이 화끈거림, 어깨가 당김, 두통을 느낌, 배가 싸함, 호흡이 가빠짐, 심장이 두근거림.`,
      typography_8: `이런 것들이 생리적 몸의 변화예요.`,
      typography_9: `앞으로는 이렇게 ‘피부, 근육, 장기'를 기준으로 더 자세히 관찰해보면 기록하기 더 좋을 거예요.`,
      typography_10: `얼굴과 몸의 언어적 표현`,
      typography_11: `두 번째는 ‘얼굴과 몸의 언어적 표현’이에요.`,
      typography_12: `이는 다른 사람이 관찰할 수 있는 나의 반사적인 신체 반응이에요.`,
      typography_13: `찡그리기, 미소 짓기, 손을 꽉 움켜쥐기, 시선을 아래로 응시하기, 눈을 피하기, 팔짱 끼기, 뒤로 기대기.`,
      typography_14: `이런 것들이 얼굴과 몸의 언어적 표현이랍니다.`,
      typography_15: `이 표현들은 거의 무의식적으로 나타나서 ‘행동'이라기 보다는 ‘신체 반응'에 가까워요.`,
      typography_16: `얼굴과 몸의 언어적 표현은 반사적인 반응이기 때문에 신체 반응이라고 생각해주시면 된답니다.`,
      typography_17: `기꺼이 경험하며 관찰해요`,
      typography_18: `역시나 세세하게 신체 반응을 관찰하기 위해서는, 이를 기꺼이 경험하며 관찰할 수밖에 없답니다.`,
      typography_19: `불편한 감각이 느껴져도 거부하고, 피하고, 화내기보다는`,
      typography_20: `그것이 없어질 것을 바라지 않고, 기꺼이 경험하며 새로운 관계를 맺으려고 노력해보세요.`,
      typography_21: `그때부터 신체 반응은 그저 마음속에 떠오르는 하나의 경험으로 자리 잡게 될 거랍니다.`,
      typography_22: `마찬가지로 처음엔 쉽진 않겠지만, 적극적인 관찰자가 되어 스스로를 바라보다 보면 나도 몰랐던 새로운 경험들을 마주할 수 있을 거예요.`,
      typography_23: `자, 그러면 오늘 배운 내용을 토대로 기록을 작성해볼까요?`,
    },
  },
};
