import { axiosInstance, CustomResponseFormat } from "api";
import { AutomaticThoughtType, ConceptType } from "api/conceptApi";
import { DashboardType } from "api/dashboardApi";

export function getCopingStrategyStateFromData(concept?: ConceptType) {
  if (concept) {
    const copingStrategyList = Object.entries(concept)
      .filter(([key]) => key.startsWith("automaticThought"))
      .map(([, values]) => (values as AutomaticThoughtType)?.copingStrategy);

    return {
      hasSurrender: copingStrategyList.includes("굴복"),
      hasOvercompensation: copingStrategyList.includes("과잉보상"),
      hasAvoidance: copingStrategyList.includes("회피"),
    };
  }
}

export default async function getCopingStrategyState(patientId: string) {
  try {
    const response = (await axiosInstance.get("/dashboard/detail", {
      params: { pid: patientId },
    })) as CustomResponseFormat;
    if (response.code === 0) {
      return getCopingStrategyStateFromData(
        (response?.result as DashboardType)?.concept
      );
      // console.log(data);
    } else {
    }
  } catch (error: any) {}
}
