export const translation_4_4_1_A = {
  en: {
    v1: {
      typography_0_0_0: "Hey, have you ever watched <The Truman Show>?",
      typography_1_0_0:
        "In that movie, the main character's life is on live TV for the whole world.",
      typography_2_0_0: "Everything revolves around him.",
      typography_3_0_0:
        "But guess what? Sometimes, we feel like our own lives are the center of the universe too.",
      typography_4_0_0: "Our personal world",
      typography_5_0_0:
        "We often see everything around us in relation to ourselves.",
      typography_6_0_0:
        "It’s like saying, “The sun's out, I’m in a good mood” - giving personal meaning to even natural events.",
      typography_7_0_0: "This might be a natural instinct.",
      typography_8_0_0:
        "Think about it - if our ancestors didn’t pay attention to dangers around them, they wouldn’t have lasted long!",
      typography_9_0_0:
        "So, we’ve evolved to connect pretty much every event to ourselves. It's like we’re hardwired to think everything has something to do with us.",
      typography_10_0_0: "It’s actually an advantage for us to think this way.",
      typography_11_0_0: "Downside of relating",
      typography_12_0_0:
        "When this self-relating meets negative thinking, it can lead us down a tricky path.",
      typography_13_0_0:
        "We tend to overemphasize our role in things, often blaming ourselves.",
      typography_14_0_0: "This is what we call 'Extra Blame,'",
      typography_15_0_0: "and it falls under 'inaccurate thinking.'",
      typography_16_0_0:
        "It's when we take the blame for things, even when they’re not really connected to what we’ve done. ",
      typography_17_0_0:
        "Like a team leader who thinks every setback is their fault,",
      typography_18_0_0:
        "or someone who faced exclusion as a kid and believes it was all because of their own shortcomings.",
      typography_19_0_0:
        "Here’s a story about a famous goalkeeper. In an interview, they said they lost the game because they couldn't stop a shot.",
      typography_20_0_0:
        "But everyone who watched the game didn’t blame the goalie at all.",
      typography_21_0_0:
        "The shot from the opposing player was just incredibly good.",
      typography_22_0_0: "The root of Extra Blame",
      typography_23_0_0:
        "This trap often comes from deeper, ingrained thoughts.",
      typography_24_0_0:
        "Like someone who firmly believes they're always lacking will likely blame themselves when things go wrong.",
      typography_25_0_0:
        "Or someone who thinks they must always lead by example tends to pin failures on themselves.",
      typography_26_0_0:
        "Falling into this trap can really knock our confidence and self-esteem, making things feel even worse.",
      typography_27_0_0:
        "We start telling ourselves, “See, I just don’t measure up,”",
      typography_28_0_0: "and that negative thinking gets even stronger.",
      typography_29_0_0:
        "When faced with similar situations again, we're quicker to fall into the trap of Extra Blame.",
      typography_30_0_0: "Distancing from Extra Blame",
      typography_31_0_0:
        "Stepping back from Extra Blame is pretty straightforward.",
      typography_32_0_0:
        "Before getting too caught up in those popcorn-like thoughts that pop into your mind,",
      typography_33_0_0: "recognize that they’re there.",
      typography_34_0_0:
        "Take a step back and look at these thoughts objectively.",
      typography_35_0_0: "It’s not about shrugging off responsibility,",
      typography_36_0_0:
        "but understanding how and why we fall into these thinking traps.",
      typography_37_0_0:
        "Recognizing how they stir up anxiety, sadness, or shame can really help us create some mental space.",
      typography_38_0_0:
        "Just being aware of this can give us some breathing room from our thoughts.",
      typography_39_0_0:
        "And as we get better at this distancing, we won't overreact to negative situations as much. ",
      typography_40_0_0:
        "Even when things don’t go as planned, we can bounce back stronger and make choices aligned with our values.",
      typography_41_0_0:
        "So, how about we take a closer look at how much impact our actions really have?",
    },
  },
  ko: {
    v1: {
      typography_0_0_0: "영화 <트루먼쇼>를 본 적이 있나요?",
      typography_1_0_0:
        "<트루먼쇼>에서 주인공의 삶은 세상 모든 사람에게 TV로 생중계됩니다.",
      typography_2_0_0: "방송에서 세상은 모두 주인공 중심으로 돌아가죠.",
      typography_3_0_0:
        "그런데 그거 아시나요? 현실 세계에서도 세상은 나를 중심으로 돌아간답니다.",
      typography_4_0_0: "나의 세상",
      typography_5_0_0: "우리는 세상의 모든 것을 나 자신과 관계 짓곤 해요.",
      typography_6_0_0:
        "심지어 우리는 자연 현상에도 손쉽게 개인적인 의미를 부여합니다. “날씨가 좋아서 나는 오늘 기분이 좋아”",
      typography_7_0_0:
        "어쩌면 진화적으로 생각해 보았을 때 이는 당연한 일일지도 모릅니다.",
      typography_8_0_0:
        "눈앞에 나타난 짐승에 아무런 의미를 부여하지 않고, 가만히 멀뚱멀뚱 쳐다만 보고 있다면 곧 잡아먹히고 말 거니까요.",
      typography_9_0_0:
        "즉, 우리는 가능한 모든 사건을 우리 자신과 관련지어 생각하도록 진화되었습니다.",
      typography_10_0_0: "그게 더 유리하기 때문이죠.",
      typography_11_0_0: "관련 짓기의 부작용",
      typography_12_0_0:
        "하지만 문제가 있습니다. 자신과의 관련 짓기가 부정적인 믿음과 만나면 금방 생각함정을 만들어 낸다는 사실이죠.",
      typography_13_0_0:
        "인간은 정보처리 과정에서 어떠한 결론의 다양한 근거 중 어느 하나(아주 흔히 '나')의 지분을 매우 크게 계산하곤 합니다.",
      typography_14_0_0: "이러한 생각함정을 '책임 과다'라고 합니다.",
      typography_15_0_0:
        "책임 과다는 '정확하지 않은 생각'에 해당하는 생각함정이죠.",
      typography_16_0_0:
        "자신의 행동과 무관한 상황에서도 어떠한 일의 책임을 자신의 책임으로 돌리는 겁니다.",
      typography_17_0_0:
        "예를 들면, 팀을 이끌고 있는 리더는 항상 팀과 관련된 안 좋은 결과들이 ‘자신이 부족해서’ 벌어진 것으로 생각하기도 하고,",
      typography_18_0_0:
        "어렸을 적 따돌림을 당한 경험이 있는 사람들은 자신도 모르게 ‘내가 못 나서’ 따돌림을 당했다고 생각하기도 합니다.",
      typography_19_0_0:
        "어느 유명한 골키퍼가 인터뷰에서 이렇게 말했습니다. 자신이 슛을 막지 못했기 때문에 경기에서 졌다고요.",
      typography_20_0_0:
        "하지만 경기를 지켜본 사람들 모두 골키퍼의 실수 따위는 생각조차 나지 않았어요.",
      typography_21_0_0: "상대 팀 선수가 찼던 슛이 굉장히 환상적이었거든요.",
      typography_22_0_0: "책임 과다의 뿌리",
      typography_23_0_0:
        "책임 과다는 흔히 자동적 사고보다 더 뿌리 깊은 생각에서 만들어질 때가 많아요.",
      typography_24_0_0:
        "“나는 부족하다”라고 굳게 믿고 있는 사람은 일이 제대로 풀리지 않았을 때 그 원인을 자신에게 돌리기 쉽죠.",
      typography_25_0_0:
        "“나는 늘 솔선수범해야 한다”라고 믿고 있는 사람도 마찬가지로 실패의 원인을 자신에게 귀속시키곤 합니다.",
      typography_26_0_0:
        "이 생각함정에 빠지면 자존감과 자신감이 낮아지기 마련입니다. 그로 인해서 일은 더 안 풀리게 되죠.",
      typography_27_0_0:
        "우리 내면에는 다음과 같은 목소리가 들려옵니다. ‘거봐. 너는 부족하다고 하잖아?’",
      typography_28_0_0: "결국 부정적인 생각은 더욱 강화됩니다.",
      typography_29_0_0:
        "그리고 비슷한 상황이 닥치면 더 강하게 책임 과다 생각함정이 발동하죠.",
      typography_30_0_0: "책임 과다로부터 거리두기",
      typography_31_0_0: "책임 과다로부터 거리를 두는 방법은 간단합니다.",
      typography_32_0_0:
        "마음속에 팝콘처럼 떠오른 생각에 강하게 매몰되기 전에,",
      typography_33_0_0: "그러한 생각이 마음속에 떠올랐음을 인지하고,",
      typography_34_0_0:
        "한 걸음 떨어져서 그 생각이 어떠한 생각인지 객관적으로 살펴보는 것이죠.",
      typography_35_0_0:
        "이는 만사에 ‘나는 책임이 없소’와 같이 생각하며 살자는 뜻이 아니랍니다.",
      typography_36_0_0:
        "그보다는 우리의 마음속에서 어떻게 책임 과다 생각함정이 발생했는지 알아차리자는 것이죠.",
      typography_37_0_0:
        "그러한 생각이 어떠한 방식으로 나에게 불안감, 우울감, 수치심 등 다양한 감정을 만들어 내고 있는지를 아는 것은 거리를 두는 데에 아주 큰 도움이 된답니다.",
      typography_38_0_0:
        "그것을 바라볼 수 있는 것만으로도 우리는 생각으로부터 제법 거리를 둘 수 있을 거랍니다.",
      typography_39_0_0:
        "그리고 거리두기가 익숙해지면 같은 상황이 발생해도 부정적인 사건에 대해 과하게 반응하지 않을 수 있을 거예요.",
      typography_40_0_0:
        "그렇다면 우리는 설령 어떠한 일이 잘 풀리지 않더라도 더 높은 회복탄력성을 가지고, 나의 가치에 맞는 방향으로 나아가기로 다시 한번 선택할 수 있답니다.",
      typography_41_0_0:
        "자, 그러면 이제 나의 기여가 실제로 얼마만큼의 영향을 주었을지 살펴볼까요?",
    },
  },
};
