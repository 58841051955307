import * as React from "react";
import Typography from "@mui/joy/Typography";
import { PATIENT_DOMAIN, PATIENT_LIST_ENDPOINT } from "api/patientApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { ListItemButton } from "@mui/joy";
import { notificationApi } from "api/notificationApi";

export default function PatientRemindButton({
  patientId,
  onSuccess,
}: {
  patientId: string;
  onSuccess: () => void;
}) {
  const queryClient = useQueryClient();

  const sendNotificationQueryFn = useCallback(
    () => notificationApi.done(`${patientId}`),
    [patientId]
  );

  const { mutate: remind } = useMutation(sendNotificationQueryFn, {
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries([PATIENT_DOMAIN, PATIENT_LIST_ENDPOINT]);
    },
  });

  return (
    <ListItemButton
      onClick={(e) => {
        e.stopPropagation();
        remind();
      }}
    >
      <Typography>리마인드 완료</Typography>
    </ListItemButton>
  );
}
