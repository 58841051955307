import { isDev } from "config";
import useGetEventFromApp from "hooks/useGetEventFromApp";
import { useEffect, useState } from "react";
import { sendMessage } from "utils/webview";
import useLogin from "./useLogin";

export const apple_cliendId = isDev
  ? "dev.orwellhealth.distancing.login"
  : "com.orwellhealth.distancing.login";
export const apple_redirectUrl = isDev
  ? "https://dev-www.distancing.im/oauth/apple"
  : `${window.location.origin}/oauth/apple`;

export default function useAppleLogin() {
  const handleAppleLogin = () => {
    sendMessage(JSON.stringify({ event: "apple_login" }));
  };
  const [idToken, setIdToken] = useState<string>();

  const login = useLogin({
    url: `/user/login`,
    requestBody: JSON.stringify({
      idToken: idToken,
      type: "apple",
    }),
  });

  useEffect(() => {
    if (idToken) {
      login();
    }
  }, [idToken]);

  const parsedMessageFromApp = useGetEventFromApp();

  useEffect(() => {
    if (parsedMessageFromApp?.event === "apple_idToken") {
      const idToken = parsedMessageFromApp.params.value;
      setIdToken(idToken);
    }
  }, [parsedMessageFromApp]);

  return handleAppleLogin;
}
