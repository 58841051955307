import Link from "components/common/Link";
import { Stack } from "@mui/joy";
import TaskLink from "../TaskLink";

export default function HumanCheckTask({
  taskKey,
  isCompleted,
  showDoneButton,
  taskCompleteAction,
}: {
  taskKey: string;
  isCompleted?: boolean;
  showDoneButton?: boolean;
  taskCompleteAction: () => void;
}) {
  return (
    <Stack direction={"row"} spacing={"12px"} justifyContent="space-between">
      <TaskLink taskKey={taskKey} />
      {showDoneButton && (
        <Link
          text={isCompleted ? "완료됨" : "완료"}
          opacity={1}
          textColor={isCompleted ? "primary.deactivated" : "primary.solid"}
          disabled={isCompleted}
          onClick={taskCompleteAction}
        />
      )}
    </Stack>
  );
}
