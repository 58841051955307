import { Box } from "@mui/joy";

export default function SelfCheckProgressBar({
  progress,
}: {
  progress: number;
}) {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "3px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          width: "100%",
          height: "3px",
          backgroundColor: "background.level2",
        }}
        className="transition-all"
      >
        {progress > 0 && (
          <Box
            sx={{
              position: "absolute",
              width: `${progress}%`,
              height: "100%",
              backgroundColor: "primary.soft",
            }}
            className="transition-all duration-500"
          ></Box>
        )}
      </Box>
    </Box>
  );
}
