export const translation_101_2_4_A = {
  en: {},
  ko: {
    v1: {
      "관계 (가족, 친구, 파트너)": "관계 (가족, 친구, 파트너)",
      "건강한 몸과 마음": "건강한 몸과 마음",
      "자기 계발": "자기 계발",
      창의성: "창의성",
      "직업과 경력": "직업과 경력",
      "교육과 학습": "교육과 학습",
      기타: "기타",
      typography_0_0_0: "이제 나의 가치를 알아볼 텐데요.",
      typography_1_0_0:
        "가치를 생각해 보기 전에 한 가지 중요한 점을 알려드릴게요.",
      typography_2_0_0: "가치는 ‘방향'이지 목표나 결과가 아니랍니다.",
      typography_3_0_0:
        "가치는 그저 내가 특정한 형태의 삶을 살고 싶다고 선택한 방향성일 뿐이에요.",
      typography_4_0_0:
        "물론 그 방향성으로 나아가기 위해 단계적인 목표를 세울 수도 있겠죠.",
      typography_5_0_0: "하지만 가치 자체는 달성할 수 있는 것이 아니랍니다.",
      typography_6_0_0:
        "그 매 순간 내가 어떠한 곳으로 나아갈지에 대한 방향성일 뿐이죠.",
      typography_7_0_0:
        '가령 “지적이고 똑똑한 사람이 되고 싶다"는 건 가치가 될 수 있지만, “명문대에 입학하기"는 가치가 아니라 목표일 뿐이에요.',
      typography_8_0_0: "이 차이가 중요한 이유를 눈치채셨나요?",
      typography_9_0_0:
        "가치가 목표나 결과가 아니라 방향성이라면, 우리는 당장 오늘부터, 지금 이 순간부터 나의 가치에 맞는 삶을 살도록 선택할 수 있다는 것이에요.",
      typography_10_0_0:
        "비록 작더라도 나의 가치에 맞는 과제들을 하나씩 해결해 나가면, 언젠가 내가 원하는 모습의 삶으로 나아가고 있는 자신을 발견할 수 있을 거예요.",
      typography_11_0_0: "지금부터 나의 가치는 무엇인지 알아보도록 할게요.",
      typography_12_0_0: "이 기록을 다른 사람에게 보여줄 필요는 없답니다.",
      typography_13_0_0: "그러니 스스로에게 정직하게 적어보세요.",
      typography_14_0_0:
        "남들의 눈치를 보지 말고, 다른 사람의 기대를 충족시키는 것을 신경 쓰지 마세요.",
      typography_15_0_0:
        "가치는 각자가 ‘선택'한 문제일 뿐이지, 옳고 그른 답은 없답니다.",
      typography_16_0_0: "자, 그러면 적어볼까요?",
      typography_17_0_0:
        "내가 삶에서 중요하게 생각하는 영역은 무엇인가요? 아래 영역 중 세 가지를 선택해 볼까요?",
      typography_18_0_0:
        "각각의 영역에서 구체적으로 나는 어떤 사람이 되고 싶나요?",
      typography_19_0_0:
        "이 가치가 나에게 왜 중요한가요? 어떻게 이것이 당신에게 중요한 가치가 되었나요?",
      typography_20_0_0:
        "이 가치가 정말로 실현된다면 어떤 기분이 들까요? 무엇이 좋을까요?",
      typography_21_0_0:
        "만약 내가 이 가치에 부합하는 사람이 되었다고 생각해 볼게요. 그렇다면 지금 무기력을 겪고 있는 나는 당장 어떻게 다르게 행동했을까요?",
      typography_22_0_0: "좋아요. 다시 한번 가치에 대해 읽어보세요.",
      typography_23_0_0:
        "“아무도 내 기록을 보지 않는다 해도 나는 여전히 이와 같이 적을 것인가?”라는 질문에 대해 생각해보세요.",
      typography_24_0_0:
        '만약 “그렇다"라고 답할 수 없다면 기록을 다시 수정하세요.',
      typography_25_0_0:
        "내 가치 중에서 진실처럼 들리지 않거나, 나의 가슴에서 나온 것이기보다는 남들이 보기에 ‘좋은 사람이 되고자'하는 것에 비중을 둔 부분이 있다면, 기록을 다시 수정하세요.",
      typography_26_0_0:
        "이 목록은 다른 누군가를 위한 것이 아니라 나 자신을 위한 것이랍니다.",
      typography_27_0_0:
        "살펴보아도 문제가 없다면 앞으로 이 가치를 실현할 수 있게 행동 변화를 만들어내고자 해요.",
      typography_28_0_0:
        "물론 당장 이 가치를 실현하는 활동부터 시작하진 않을 거예요.",
      typography_29_0_0:
        "아주 쉽고 간단한 활동부터 시작해서 무기력에서 빠져나와 점차 이 가치에 맞는 삶으로 나아갈 계획이에요.",
      typography_30_0_0:
        "하지만 잊지 마세요. 우리가 지금 행동 변화를 만들고 있는 사소한 부분은 아주 조만간 이 가치를 향해 나아가기 위해 시작한 것이라는 점을요.",
      typography_31_0_0:
        "자, 이제 마지막으로 다음 활동에서는 이 가치가 악순환을 벗어나 선순환을 만드는 것과 어떤 관련이 있는지 살펴볼 거예요.",
    },
  },
};
