import { isDev } from "config";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";

export default function useSubscriptionStatus() {
  const user = useRecoilValue(userAtom);
  const subscription = user?.subscription;

  const hasFreeTrialUsed = subscription?.hasFreeTrialUsed;

  const isSubscribing = subscription?.isSubscribing;
  const hasFirstMonthBenefitReceived =
    subscription?.hasFirstMonthBenefitReceived;

  const expiryDaysLeft =
    subscription?.isSubscribing && subscription.expiryDaysLeft;
  const isCanceled =
    subscription?.isSubscribing && subscription.expiryDaysLeft !== null;
  const isExpired = !subscription?.isSubscribing && subscription?.hasStarted;
  const isNotStarted =
    !subscription?.isSubscribing && !subscription?.hasStarted;

  const isSubscribingWithoutCancel =
    !isDev &&
    subscription?.isSubscribing &&
    subscription.expiryDaysLeft === null;

  return {
    isSubscribing,
    isSubscribingWithoutCancel,
    hasFreeTrialUsed,
    hasFirstMonthBenefitReceived,
    isCanceled,
    isExpired,
    isNotStarted,
    expiryDaysLeft,
  };
}
