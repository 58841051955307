export const translation_101_2_3_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "앞선 활동을 통해 언제부턴가 삶이 악순환 패턴을 빠져 무기력을 돌게 되었다는 점을 잘 이해하셨을 거예요.",
      typography_1_0_0:
        "그렇다면 우리는 이 삶을 어떻게 바꿔나가야 하는 걸까요?",
      typography_2_0_0:
        "이를 이해하기 위해서는 ‘가치'라는 것을 이해할 필요가 있어요.",
      typography_3_0_0:
        "비유를 하나 들어볼게요. 당신이 ‘나의 인생'이라는 이름의 버스를 운전하고 있다고 생각해 보세요.",
      typography_4_0_0: "다른 여느 버스들처럼 이 버스에도 승객이 올라타요.",
      typography_5_0_0:
        "당신의 승객에는 기억, 신체 감각, 감정, 생각 등이 포함될 거예요.",
      typography_6_0_0: "승객 중에는 당신이 좋아하는 사람도 있어요.",
      typography_7_0_0: "하지만 당신이 싫어하는 사람도 버스에 올라탑니다.",
      typography_8_0_0:
        "당신이 제발 다른 버스로 갈아타기를 바라는 험상궂은 폭력배처럼 말이에요.",
      typography_9_0_0:
        "삶을 살아가다 보면 그런 불편한 승객을 마주하기 마련이에요. 그것 자체를 피할 수는 없죠.",
      typography_10_0_0:
        "디스턴싱을 시작하기 전까지 당신의 초점은 이 불편한 승객을 향해 있었을 거예요.",
      typography_11_0_0:
        "당신이 무기력, 슬럼프, 번아웃, 자책, 우울, 불안 등으로 괴로워했다면, 당신은 아마 다음과 같은 시도를 해 보았을 거예요.",
      typography_12_0_0: "승객과 싸우기",
      typography_13_0_0:
        "당신은 아마도 운전을 멈추고 그 원치 않는 승객을 강제로 쫓아내려고 안간힘을 써보았을 거예요.",
      typography_14_0_0:
        "하지만 여기서 중요한 건 부득이 운전을 멈춰야 한다는 것이에요.",
      typography_15_0_0:
        "어떻게든 불편한 승객을 내리게 하려고 당신의 인생을 보류해야만 하죠.",
      typography_16_0_0:
        "하지만 그 승객들을 좀처럼 버스에서 잘 내리려고 하지 않아요.",
      typography_17_0_0:
        "그 과정에서 점차 언제부터인가 삶의 주제는 무기력, 슬럼프, 번아웃, 자책, 우울, 불안 같은 불편한 승객을 없애는 것으로 바뀌어가고 있죠.",
      typography_18_0_0: "승객 회피하기",
      typography_19_0_0:
        "때로는 승객이 버스 안에 없는 것처럼 노력해 보기도 합니다.",
      typography_20_0_0:
        "우리는 피하고, 폭식, 유튜브와 같은 단순 유희, 술, 약물 등을 활용하며 승객의 존재를 부정하죠.",
      typography_21_0_0: "하지만 이 방식이 치러야 할 대가는 매우 커요.",
      typography_22_0_0: "당신은 그 대가로 당신의 자유를 넘겨주어야 하죠.",
      typography_23_0_0:
        "원치 않는 승객을 시야에서 사라지게 하려고, 당신은 슬픈 협상안을 제시해야 해요.",
      typography_24_0_0:
        "즉, 그가 뒷좌석에 숨어 조용히 있는 대신에, 당신은 그가 가고자 하는 곳으로 버스를 운전해야 하죠.",
      typography_25_0_0:
        "그 결과 잠깐의 비정상적인 상태인 것 같았던 무기력은 점차 삶의 정상적인 모습으로 자리 잡고 있어요.",
      typography_26_0_0: "버스를 운전할 방향",
      typography_27_0_0: "나의 버스 안에서 불편한 승객이 타고 있는 것 같나요?",
      typography_28_0_0: "그 승객들을 도대체 어떻게 해야 할지 잘 모르겠나요?",
      typography_29_0_0:
        "걱정하지 마세요. 디스턴싱은이 알려진 모든 인지심리학적 기법들을 동원해 그 승객들을 어떻게 대해야 하는지 알려드릴 거예요.",
      typography_30_0_0: "그런데 대체 이 버스는 어디로 가고 있었던 걸까요?",
      typography_31_0_0:
        "이 무기력을 이겨내는 게 내 삶의 주제는 아니었을 거예요.",
      typography_32_0_0:
        "버스의 운전석에 앉아 보면, 위쪽에 이 버스가 어디로 가고 있는지를 알려 주는 작은 표지판을 확인할 수 있어요.",
      typography_33_0_0:
        "버스가 어디를 향해 가는지는 승객의 순간순간의 변덕에 달려 있지 않죠.",
      typography_34_0_0:
        "목적지를 정하고 그곳을 향해 운전해 가는 것은 운전자에게 달린 몫이에요.",
      typography_35_0_0:
        "이제, 당신이 ‘나의 인생'이라는 이 버스가 궁극적으로 어디로 향했으면 좋겠는지를 살펴보아야 할 시간이에요.",
      typography_36_0_0:
        "우리는 불편한 승객과 무관하게 내가 나아가기로 ‘선택한 삶의 방향'을 ‘가치'라고 부른답니다.",
      typography_37_0_0:
        "우리는 앞으로 당신이 무기력을 벗어나 가치를 향해 나아갈 수 있도록 도울 거예요.",
      typography_38_0_0:
        "내가 진정으로 원했던 삶으로 나아갈 수 있도록 말이에요.",
      typography_39_0_0:
        "다음 시간에는 가치가 정확히 무엇인지 이해하고, 나의 가치를 찾아보도록 할게요.",
    },
  },
};
