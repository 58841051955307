import { Chip } from "@mui/joy";

export function RepeatChip() {
  return (
    <Chip
      size="sm"
      startDecorator={
        <span style={{ fontSize: "16px" }} className="material-symbols-rounded">
          repeat
        </span>
      }
    >
      반복
    </Chip>
  );
}

export function DoneChip() {
  return (
    <Chip
      size="sm"
      startDecorator={
        <span style={{ fontSize: "20px" }} className="material-symbols-rounded">
          done
        </span>
      }
    >
      완료
    </Chip>
  );
}

export function LockChip() {
  return (
    <Chip
      size="sm"
      sx={{ backgroundColor: "transparent" }}
      startDecorator={
        <span style={{ fontSize: "20px" }} className="material-symbols-rounded">
          lock
        </span>
      }
    ></Chip>
  );
}

export function IncompleteChip() {
  return (
    <Chip size="sm" color="danger">
      미완성
    </Chip>
  );
}
