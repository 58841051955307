import { ProgramContentType } from "data/BlockComponent";
import { useRecoilValue } from "recoil";
import AudioButtonWebview from "./audio/AudioButtonWebview";
import AudioButtonWeb from "./audio/AudioButtonWeb";
import { AudioType, ContentType } from "data/CellComponent";
import useIsInappWebview from "hooks/useIsInappWebview";
import useDetectIOS from "hooks/useDetectIOS";
import useGetLocale from "hooks/useGetLocale";
import { userAtom } from "recoil/userAtom";

export default function AudioButtonCell2({
  content,
  blockIndex,
  lineIndex,
  cellIndex,
  taskKey,
  meditationRecordKey,
  setData,
  readonly,
}: {
  content: ContentType;
  blockIndex: number;
  lineIndex: number;
  cellIndex: number;
  taskKey: string;
  meditationRecordKey?: string;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  readonly: boolean;
}) {
  const isInappWebview = useIsInappWebview();
  const { isIOS } = useDetectIOS();

  const user = useRecoilValue(userAtom);
  const isCoach = (user?.accessToken && user?.role !== "patient") || false;

  const locale = useGetLocale();
  return isInappWebview && !isIOS ? (
    <AudioButtonWebview
      key={meditationRecordKey !== undefined ? meditationRecordKey : taskKey}
      isCurrentTask
      taskKey={taskKey}
      meditationRecordKey={meditationRecordKey}
      url={`https://media.distancing.im/audio/${
        (content as AudioType).audioUrl
      }_${locale}.mp3`}
      initialTime={Math.floor((content as AudioType).currentTime || 0)}
      setData={setData}
      blockIndex={blockIndex}
      lineIndex={lineIndex}
      cellIndex={cellIndex}
      isCoach={isCoach}
      disabled={readonly}
      {...((content as AudioType).extraAudioUrl && {
        extraAudioUrl: `https://media.distancing.im/audio/${
          (content as AudioType).extraAudioUrl
        }_${locale}.mp3`,
      })}
      extraLoopNumber={(content as AudioType).extraLoopNumber}
    />
  ) : (
    <AudioButtonWeb
      key={meditationRecordKey !== undefined ? meditationRecordKey : taskKey}
      isCurrentTask
      url={`https://media.distancing.im/audio/${
        (content as AudioType).audioUrl
      }_${locale}.mp3`}
      initialTime={Math.floor((content as AudioType).currentTime || 0)}
      setData={setData}
      blockIndex={blockIndex}
      lineIndex={lineIndex}
      cellIndex={cellIndex}
      isCoach={isCoach}
      disabled={readonly}
      {...((content as AudioType).extraAudioUrl && {
        extraAudioUrl: `https://media.distancing.im/audio/${
          (content as AudioType).extraAudioUrl
        }_${locale}.mp3`,
      })}
      extraLoopNumber={(content as AudioType).extraLoopNumber}
    />
  );
}