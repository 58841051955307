import { automaticThoughtIdType } from "api/conceptApi";
import {
  Instruction,
  InstructionWithContent,
  InstructionWithData,
} from "../../BlockComponent";
import { AutomaticThought, LastCard } from "../common";

export const Content_4_6_A = (automaticThoughtId: automaticThoughtIdType) => [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  Instruction("typography_2_0_0"),
  Instruction("typography_3_0_0"),
  Instruction("typography_4_0_0"),
  Instruction("typography_5_0_0"),
  Instruction("typography_6_0_0"),
  Instruction("typography_7_0_0"),
  Instruction("typography_8_0_0"),
  Instruction("typography_9_0_0"),
  Instruction("typography_10_0_0"),
  InstructionWithContent(
    `typography_11_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),
  Instruction("typography_12_0_0"),
  InstructionWithData(
    { A: { id: "생각" }, B: { id: "감정" }, C: { id: "행동" } },
    "typography_13_0_0"
  ),
  InstructionWithData(
    { A: { id: "생각" }, C: { id: "행동" } },
    "typography_14_0_0"
  ),
  Instruction("typography_15_0_0"),
  Instruction("typography_16_0_0"),
  Instruction("typography_17_0_0"),
  Instruction("typography_18_0_0"),
  InstructionWithData(
    { A: { id: "생각" }, B: { id: "행동" } },
    "typography_19_0_0"
  ),
  Instruction("typography_20_0_0"),
  Instruction("typography_21_0_0"),
  Instruction("typography_22_0_0"),
  Instruction("typography_23_0_0"),
  Instruction("typography_24_0_0"),
  Instruction("typography_25_0_0"),

  ...LastCard,
];
