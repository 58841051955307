import { ProgramType } from "data/programData";
import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const THOUGHTRECORD_DOMAIN = "thought-record";

export const THOUGHTRECORD_LOAD_ENDPOINT = "data/load";
export const THOUGHTRECORD_SAVE_ENDPOINT = "data/save";
export const THOUGHTRECORD_DONE_ENDPOINT = "done";
export const THOUGHTRECORD_UNDONE_ENDPOINT = "undone";
export const THOUGHTRECORD_LIST_ENDPOINT = "list";

export const THOUGHTRECORD_RAW_SUBMIT_ENDPOINT = "raw/submit";
export const THOUGHTRECORD_ABC_ENDPOINT = "abc/detail";
export const THOUGHTRECORD_CONVERSATION_MESSAGE_LIST_ENDPOINT =
  "conversation/message/list";
export const THOUGHTRECORD_CONVERSATION_MESSAGE_DETAIL_ENDPOINT =
  "conversation/message/detail";
export const THOUGHTRECORD_CONVERSATION_CONTINUE_ENDPOINT =
  "conversation/continue";

interface LoadedDataType {
  data: ProgramType;
}
export type SentimentType = "positive" | "negative";
export interface ThoughtRecordType {
  thoughtRecordKey: string;
  isDone?: boolean;
  situation?: string;
  date?: string;
  isHighlight?: boolean;
  hasAiRecommendation?: boolean;
  lastDoneAt?: string;
  sentiment?: SentimentType;
  shouldBeDone?: boolean; // 프론트에서만 씀
}
export interface ABCType {
  situation?: string;
  thoughtList?: string[];
  emotionList?: string[];
  behaviorList?: string[];
  physicalReactionList?: string[];
  isProcessing?: boolean;
}

export interface ConversationMessageType {
  messageId: string;
  role: string;
  message: string;
  isIgnored: boolean;
  isInitialABCFilled: boolean;
  sentiment?: "negative" | "positive";
}

export const thoughtRecordApi = {
  getData: (trk: string) =>
    axiosInstance.get<CustomResponseFormat<LoadedDataType>>(
      `${THOUGHTRECORD_DOMAIN}/${THOUGHTRECORD_LOAD_ENDPOINT}`,
      { params: { trk: trk } }
    ),
  saveData: (trk: string, pid: string, body?: ProgramType) =>
    axiosInstance.post<void>(
      `${THOUGHTRECORD_DOMAIN}/${THOUGHTRECORD_SAVE_ENDPOINT}`,
      { data: body },
      {
        params: { trk: trk, pid: pid },
      }
    ),
  done: (trk: string) =>
    axiosInstance.post<void>(
      `${THOUGHTRECORD_DOMAIN}/${THOUGHTRECORD_DONE_ENDPOINT}`,
      undefined,
      { params: { trk: trk } }
    ),
  undone: (trk: string, pid: string) =>
    axiosInstance.post<void>(
      `${THOUGHTRECORD_DOMAIN}/${THOUGHTRECORD_UNDONE_ENDPOINT}`,
      undefined,
      { params: { trk: trk, pid: pid } }
    ),

  getList: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<ThoughtRecordType[]>>(
      `${THOUGHTRECORD_DOMAIN}/${THOUGHTRECORD_LIST_ENDPOINT}`,
      { params: { pid: pid } }
    ),

  submitRaw: (trk: string, content: string) =>
    axiosInstance.post<void>(
      `${THOUGHTRECORD_DOMAIN}/${THOUGHTRECORD_RAW_SUBMIT_ENDPOINT}`,
      { content: content },
      { params: { trk: trk } }
    ),
  getABC: (trk: string) =>
    axiosInstance.get<CustomResponseFormat<ABCType>>(
      `${THOUGHTRECORD_DOMAIN}/${THOUGHTRECORD_ABC_ENDPOINT}`,
      { params: { trk: trk } }
    ),
  getMessageList: (trk: string) =>
    axiosInstance.get<CustomResponseFormat<ConversationMessageType[]>>(
      `${THOUGHTRECORD_DOMAIN}/${THOUGHTRECORD_CONVERSATION_MESSAGE_LIST_ENDPOINT}`,
      { params: { trk: trk } }
    ),
  getConversationMessageDetail: (trk: string, msg_id: string) =>
    axiosInstance.get<CustomResponseFormat<ConversationMessageType>>(
      `${THOUGHTRECORD_DOMAIN}/${THOUGHTRECORD_CONVERSATION_MESSAGE_DETAIL_ENDPOINT}`,
      { params: { trk: trk, msg_id: msg_id } }
    ),
  continueConversation: (trk: string, message: string) =>
    axiosInstance.post<CustomResponseFormat<{ nextMessageId: string }>>(
      `${THOUGHTRECORD_DOMAIN}/${THOUGHTRECORD_CONVERSATION_CONTINUE_ENDPOINT}`,
      { message: message },
      { params: { trk: trk } }
    ),
};
