export const translation_meditationTool = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "지금부터 명상을 진행해 볼게요, 어떤 명상을 진행할까요?",
      typography_1_0_0:
        "호흡에 초점을 두고 진행할까요? 익숙해졌다면 호흡 없이 해보아도 좋아요.",
      typography_1_0_0_0:
        "지금부터 하루의 시작을 기꺼이 경험해보려 해요. 어느 정도 시간이 있을까요?",
      typography_1_0_0_1:
        "지금부터 생각의 강 훈련으로 기꺼이 경험해보려 해요. 어느 정도 시간이 있을까요?",
      typography_1_0_0_2:
        "지금부터 생각의 공장 훈련으로 기꺼이 경험해보려 해요. 어느 정도 시간이 있을까요?",
      typography_1_0_0_3:
        "지금부터 생각의 하늘 훈련으로 기꺼이 경험해보려 해요. 어느 정도 시간이 있을까요?",
      typography_1_0_0_4:
        "지금부터 하루의 마무리를 기꺼이 경험해보려 해요. 어느 정도 시간이 있을까요?",
      typography_2_0_0: "준비됐다면 음성 안내에 따라 훈련을 시작하세요.",
    },
  },
};
