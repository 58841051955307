export const translation_4_1 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "우리는 앞으로 생각과의 관계를 다시 맺어나갈 겁니다.",
      typography_1_0_0:
        '디스턴싱 프로그램은 이 방법이 인간의 "문제 있는 마음"을 수리할 수 있는 유일무일한 방법이라고 주장합니다.',
      typography_2_0_0:
        "이 프로그램을 만나기 전까지 여러분들은 부정적인 생각들을 어떻게든 교정하거나 대체하려고 노력해왔을 겁니다.",
      typography_3_0_0: '"나는 왜 남들처럼 행복하지 못할까?"',
      typography_4_0_0: '"난 왜 이 모양일까?"',
      typography_5_0_0: '"그때 그렇게 하지 않았더라면..."',
      typography_6_0_0:
        "이라는 생각에, 다음과 같은 방식으로 교정하고 대체하려 해왔겠죠.",
      typography_7_0_0: '"그건 나쁜 생각이야. 하지말자."',
      typography_8_0_0: '"긍정적으로 생각해."',
      typography_9_0_0: '"이게 더 올바른 마음가짐이야."',
      typography_10_0_0:
        "하지만 아마 이러한 방식으로는 도저히 마음을 다스릴 수 없다는 것을 발견했을겁니다.",
      typography_11_0_0:
        "일은 뜻대로 되지 않고, 우린 여전히 힘들고 더 괴로워질 뿐이죠.",
      typography_12_0_0: "도대체 이런 방식은 왜 효과가 없는 걸까요?",
      typography_13_0_0: "생각과 거리가 가까울 때",
      typography_14_0_0: "다시 팝콘 비유로 설명해보겠습니다.",
      typography_15_0_0:
        "우리는 마음속에서 튀어오르는 팝콘을 아주 가까이서 바라보고 있습니다.",
      typography_16_0_0: "심지어는 그 위에 올라타 같이 튀어오르기도 하죠.",
      typography_17_0_0:
        "팝콘은 레몬처럼 작용하여 우리 마음에 다양한 감정을 불러일으킵니다.",
      typography_18_0_0: "침샘에서 침이 나오는 것처럼요.",
      typography_19_0_0:
        "이렇게 감정에 영향을 받은 우리는, 질색하며 내키지 않는 팝콘은 어떻게든 치워버리려 노력합니다.",
      typography_20_0_0:
        "또는 내키는 팝콘을 선택해 그렇지 않은 팝콘과 바꿔치기 하려고 하기도 하죠.",
      typography_21_0_0: "하지만 쉽지 않습니다.",
      typography_22_0_0:
        "몇몇 팝콘은 그렇게 하는 데에 어찌어찌 성공했을지도 모릅니다.",
      typography_23_0_0: "하지만 어쩌죠? 팝콘은 다시 또 떠오릅니다.",
      typography_24_0_0:
        "이것이 우리가 부정적인 생각들을 교정하고, 없애려하고, 피하려고 했던 지난 노력들입니다.",
      typography_25_0_0: "이렇게는 도저히 마음을 다스릴 수 없습니다.",
      typography_26_0_0: "거리를 멀리할 수 있다면",
      typography_27_0_0:
        "하지만 만약, 우리가 팝콘과의 거리를 더 멀리할 수 있다면 어떨까요?",
      typography_28_0_0: "팝콘과 나 사이에 제법 거리감이 있다면 어떨까요?",
      typography_29_0_0:
        "내키는 팝콘인지 아닌지, 즉, 팝콘이 어떠한지가 중요한 것이 아니라,",
      typography_30_0_0: "그저 팝콘과 새로운 관계를 맺을 수 있다면 어떨까요?",
      typography_31_0_0:
        "만약 그것이 가능하면 어떠한 일이 일어나는지, 다음 활동에서 자세히 살펴보도록 하죠.",
    },
  },
};
