import { Box, Stack, Typography } from "@mui/joy";

export default function ProgressBar({
  label,
  percentage,
}: {
  label: string;
  percentage: number;
}) {
  return (
    <Stack
      direction="column"
      spacing={"10px"}
      justifyContent="center"
      alignItems={"center"}
    >
      <Typography
        level="body-xs"
        sx={{ ...(percentage === 0 && { opacity: 0.2 }) }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "5px",
          backgroundColor: "#F3F5F8",
        }}
      >
        {percentage > 0 && (
          <Box
            sx={{
              position: "absolute",
              width: `${percentage}%`,
              height: "100%",
              backgroundColor: "#298E88",
            }}
            className="transition-all duration-500"
          ></Box>
        )}
      </Box>
    </Stack>
  );
}
