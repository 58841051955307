import {
  intermediateBeliefKeyMapping,
  IntermediateBeliefKeyType,
} from "api/conceptApi";
import { Thoughtmap } from "data/CellComponent";
import {
  Header1,
  Header1WithData,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithContent,
  InstructionWithDataWithTextarea,
  InstructionWithTextarea,
} from "../../BlockComponent";

import {
  IntermediateBelief,
  CoreExperience,
  LastCard,
  ConceptItem,
} from "../common";

export const Part1 = (
  intermediateBeliefKeyList?: IntermediateBeliefKeyType[]
) =>
  intermediateBeliefKeyList
    ?.map((key) => [
      Header1WithData(
        { A: { value: `${intermediateBeliefKeyMapping[key]}` } },
        "part2_typography_0_0_0"
      ),
      InstructionWithDataWithContent(
        { A: { value: `${intermediateBeliefKeyMapping[key]}` } },
        "part2_typography_1_0_0",
        IntermediateBelief({ intermediateBeliefKeyList: [key] })
      ),

      Instruction("part2_typography_2_1_0"),
      InstructionWithDataWithTextarea(
        { A: { value: `${intermediateBeliefKeyMapping[key]}` } },
        "part2_typography_3_0_0",
        { id: `${key}의 이점` }
      ),
      InstructionWithTextarea("part2_typography_4_0_0", {
        id: `${key}의 이점의 결과`,
      }),
      Instruction("part2_typography_5_0_0"),
      Instruction("part2_typography_6_0_0"),
      InstructionWithDataWithTextarea(
        { A: { value: `${intermediateBeliefKeyMapping[key]}` } },
        "part2_typography_7_0_0",
        {
          id: `${key}의 손해`,
        }
      ),
      InstructionWithTextarea("part2_typography_8_0_0", {
        id: `${key}의 손해의 결과`,
      }),
      InstructionWithTextarea("part2_typography_9_0_0", {
        id: `${key}의 결론`,
      }),
    ])
    .flat() || [];

export const Part2 = (
  intermediateBeliefKeyList?: IntermediateBeliefKeyType[]
) =>
  intermediateBeliefKeyList
    ?.map((key) => [
      InstructionWithData(
        {
          A: { value: `${intermediateBeliefKeyMapping[key]}` },
          B: { id: `${key}의 이점` },
        },
        "part3_typography_0_0_0"
      ),
    ])
    .flat() || [];

export const Part3 = (
  intermediateBeliefKeyList?: IntermediateBeliefKeyType[]
) =>
  intermediateBeliefKeyList
    ?.map((key) => [
      InstructionWithData(
        {
          A: { value: `${intermediateBeliefKeyMapping[key]}` },
          B: { id: `${key}의 손해` },
        },
        "part4_typography_0_0_0"
      ),
      InstructionWithData(
        { A: { id: `${key}의 손해의 결과` } },
        "part4_typography_1_0_0"
      ),
      InstructionWithData(
        { A: { id: `${key}의 결론` } },
        "part4_typography_2_0_0"
      ),
    ])
    .flat() || [];

export const Content_6_9_A = (
  intermediateBeliefKeyList?: IntermediateBeliefKeyType[]
) => {
  return [
    Instruction(`typography_0`),
    Instruction(`typography_1`),
    Instruction(`typography_2`),
    Instruction(`typography_3`),
    Instruction(`typography_4`),
    Instruction(`typography_5`),
    Instruction(`typography_6`),
    Instruction(`typography_7`),
    Instruction(`typography_8`),
    InstructionWithContent(`typography_9`, [Thoughtmap()]),
    Instruction(`typography_10`),
    Instruction(`typography_11`, { blockId: "common1_end" }),

    Header1(`typography_12`),
    Instruction(`typography_13`),
    Instruction(`typography_14`),
    Instruction(`typography_15`),
    Instruction(`typography_16`),
    Instruction(`typography_17`),
    Instruction(`typography_18`),
    Instruction(`typography_19`),
    Instruction(`typography_20`),
    Instruction(`typography_21`),
    Instruction(`typography_22`),
    Instruction(`typography_23`),
    Instruction(`typography_24`),

    Header1(`typography_25`),
    Instruction(`typography_26`),
    Instruction(`typography_27`),
    InstructionWithContent(`typography_28`, [
      Thoughtmap(undefined, "coreBelief"),
    ]),
    InstructionWithTextarea("typography_29", { id: "고통스런 감정" }),
    Instruction(`typography_30`),
    Instruction(`typography_31`),
    Instruction(`typography_32`),
    Instruction(`typography_33`),
    ...Part1(intermediateBeliefKeyList),
    Instruction(`typography_34`),
    Instruction(`typography_35`),
    Instruction(`typography_36`),
    Instruction(`typography_37`),
    Instruction(`typography_38`),
    Instruction(`typography_39`),

    Header1(`typography_40`),
    Instruction(`typography_41`),
    Instruction(`typography_42`),
    Instruction(`typography_43`),
    InstructionWithContent(`typography_44`, CoreExperience()),
    InstructionWithContent(
      `typography_44_1`,
      ConceptItem({ conceptKey: "coreBelief" })
    ),
    InstructionWithTextarea(`typography_45`),
    InstructionWithTextarea(`typography_46`),
    InstructionWithTextarea(`typography_47`),
    Instruction(`typography_48`),
    Instruction(`typography_49`),
    Instruction(`typography_50`),
    Instruction(`typography_51`),

    Header1(`typography_52`),
    InstructionWithContent(
      `typography_53`,
      ConceptItem({ conceptKey: "coreBelief" })
    ),
    InstructionWithContent(`typography_54`, CoreExperience()),
    InstructionWithData({ A: { id: "고통스런 감정" } }, `typography_55`),
    InstructionWithContent(
      `typography_56`,
      ConceptItem({ conceptKey: "intermediateBelief" })
    ),
    ...Part2(intermediateBeliefKeyList),
    Instruction(`typography_57`),
    Instruction(`typography_58`),
    Instruction(`typography_59`),
    Instruction(`typography_60`),
    Instruction(`typography_61`),
    ...Part3(intermediateBeliefKeyList),
    Instruction(`typography_62`),
    Instruction(`typography_63`),
    Instruction(`typography_64`),

    Header1(`typography_65`),
    Instruction(`typography_66`),
    Instruction(`typography_67`),
    Instruction(`typography_68`),
    Instruction(`typography_69`),
    Instruction(`typography_70`),
    Instruction(`typography_71`),
    Instruction(`typography_72`),
    Instruction(`typography_73`),
    Instruction(`typography_74`),
    Instruction(`typography_75`),
    Instruction(`typography_76`),
    Instruction(`typography_77`),
    Instruction(`typography_78`),
    Instruction(`typography_79`),
    Instruction(`typography_80`),
    Instruction(`typography_81`),
    ...LastCard,
  ];
};
