import { Box } from "@mui/joy";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ABCStatusType,
  ConversationGroupType,
  ConversationMessageType,
  ThoughtRecordType,
  toolApi,
  TOOL_THOUGHTRECORD_CONVERSATION_DETAIL_ENDPOINT,
  TOOL_THOUGHTRECORD_CONVERSATION_MESSAGE_DETAIL_ENDPOINT,
  TOOL_THOUGHTRECORD_DETAIL_ENDPOINT,
  TOOL_THOUGHTRECORD_DOMAIN,
} from "api2/toolApi";
import React, { useCallback, useEffect, useRef, useState } from "react";
import BottomSheet from "components/common/BottomSheet";
import Button from "./Button";
import { usePrevious } from "@uidotdev/usehooks";
import * as _ from "lodash";
import useIsKeyboardOpen from "hooks/useIsKeyboardOpen";
import MessageList from "./MessageList";
import { FindingTrapIcon, IsolatingIcon } from "./Status";

const SimpleDownArrowWithCircle = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="white" fillOpacity="0.2" />
      <path
        d="M5 7L8 10L11 7"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default function Conversation({
  ttr,
  ABCData,
  isConversationOpen,
  setIsConversationOpen,
  isCompleted,
}: {
  ttr: string;
  ABCData?: ThoughtRecordType;
  isConversationOpen: boolean;
  setIsConversationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCompleted: boolean;
}) {
  const textareaRef = useRef<HTMLDivElement>(null);

  const previousABCData = usePrevious(ABCData);
  const [isABCAllFilled, setIsABCAllFilled] = useState<boolean>(false);

  const [showChageIndicator, setShowChangeIndicator] = useState<boolean>(false);

  useEffect(() => {
    if (isConversationOpen) {
      textareaRef.current?.focus();
    }
  }, [isConversationOpen]);

  const [messageList, setMessageList] = useState<ConversationMessageType[]>();

  const [userMessage, setUserMessage] = useState<string>("");
  const [isShowUserTextarea, setIsShowUserTextarea] = useState<boolean>(false);

  const dataByStatus: {
    status: ABCStatusType;
    conversation_group: ConversationGroupType;
    icon: React.ReactNode;
    title: string;
  }[] = [
    {
      status: "in_progress_isolation",
      conversation_group: "isolation",
      icon: <IsolatingIcon color="#ffffff" />,
      title: "분리하기",
    },
    {
      status: "in_progress_finding_thought_trap",
      conversation_group: "finding_thought_trap",
      icon: <FindingTrapIcon color="#ffffff" />,
      title: "생각함정 찾기",
    },
  ];

  const getMessageListByGroup = (
    group: ConversationGroupType,
    messageList?: ConversationMessageType[]
  ) => {
    return messageList?.filter((element) => element.group === group);
  };

  const currentStatusIndex = dataByStatus.findIndex(
    (element) => element.status === ABCData?.status
  );
  const currentConversationGroup =
    dataByStatus.find((element) => element.status === ABCData?.status)
      ?.conversation_group || dataByStatus[0].conversation_group;
  const currentMessageList = getMessageListByGroup(
    currentConversationGroup,
    messageList
  );

  useEffect(() => {
    if (ABCData) {
      if (
        ABCData.situation &&
        ABCData.thoughtList.length > 0 &&
        ABCData.emotionList.length > 0
      ) {
        setIsABCAllFilled(true);
      } else {
        setIsABCAllFilled(false);
      }
    }

    function removeIsCompletedField(data?: ThoughtRecordType) {
      return Object.fromEntries(
        Object.entries(data || {}).filter(
          ([key, value]) => key !== "isCompleted"
        )
      );
    }
    if (
      previousABCData &&
      !_.isEqual(
        removeIsCompletedField(ABCData),
        removeIsCompletedField(previousABCData)
      )
    ) {
      console.log("ABC updated", ABCData, previousABCData);
      setShowChangeIndicator(true);
    } else {
      setShowChangeIndicator(false);
    }
  }, [ABCData]);

  useEffect(() => {
    if (showChageIndicator) {
      setTimeout(() => {
        setShowChangeIndicator(false);
      }, 3000);
    }
  }, [showChageIndicator]);

  const getConversationDetailQueryFn = useCallback(
    () => toolApi.getConversationDetail(ttr),
    [ttr, isABCAllFilled]
  );

  useQuery(
    [
      TOOL_THOUGHTRECORD_DOMAIN,
      TOOL_THOUGHTRECORD_CONVERSATION_DETAIL_ENDPOINT,
    ],
    getConversationDetailQueryFn,
    {
      enabled: !!ABCData,
      ...((!currentMessageList || currentMessageList?.length === 0) && {
        refetchInterval: 1000,
      }),
      onSuccess: (data) => {
        if (data.result) {
          const messageList = data.result?.messageList;
          let newMessageList = messageList;
          if (messageList && messageList.length > 0) {
            newMessageList = removePreviousStatusUnansweredQuestion(
              messageList,
              currentStatusIndex,
              isCompleted
            );

            if (isABCAllFilled) {
              if (
                messageList &&
                messageList[messageList.length - 1].isIgnored
              ) {
                newMessageList = messageList.filter(
                  (element) => !element.isIgnored
                );
              }
            }
            setMessageList(newMessageList);

            queryClient.invalidateQueries([
              TOOL_THOUGHTRECORD_DOMAIN,
              TOOL_THOUGHTRECORD_DETAIL_ENDPOINT,
            ]);
          }
        }
      },
    }
  );

  useEffect(() => {
    if (messageList && messageList.length > 0) {
      //항상 맨 아래로
      if (!isCompleted) {
        setTimeout(() => {
          contentRef.current?.scrollTo({
            top: contentRef.current?.scrollHeight,
            behavior: "smooth",
          });
        }, 0);
      }

      if (isABCAllFilled) {
        if (
          messageList[messageList.length - 1].role === "assistant" &&
          !messageList[messageList.length - 1].isLast
        ) {
          setIsShowUserTextarea(true);
        } else {
          setIsShowUserTextarea(false);
        }
      } else {
        setIsShowUserTextarea(true);
      }
    }
  }, [messageList]);

  const [nextMessageId, setNextMessageId] = useState<string | undefined>();

  const getConversationMessageDetailQueryFn = useCallback(
    () => toolApi.getConversationMessageDetail(ttr, nextMessageId || ""),
    [nextMessageId]
  );

  useQuery(
    [
      TOOL_THOUGHTRECORD_DOMAIN,
      TOOL_THOUGHTRECORD_CONVERSATION_MESSAGE_DETAIL_ENDPOINT,
    ],
    getConversationMessageDetailQueryFn,
    {
      enabled: !!nextMessageId,
      ...(nextMessageId && { refetchInterval: 1000 }),
      onSuccess: (data) => {
        if (data.result?.message) {
          setUserMessage("");
          setNextMessageId(undefined);
          queryClient.invalidateQueries([
            TOOL_THOUGHTRECORD_DOMAIN,
            TOOL_THOUGHTRECORD_CONVERSATION_DETAIL_ENDPOINT,
          ]);
        }
      },
    }
  );

  const queryClient = useQueryClient();

  const { mutate: continueConversation } = useMutation(
    () => toolApi.continueConversation(ttr, userMessage),
    {
      onSuccess: (data) => {
        if (data.result) {
          const nextMessageId = data.result.nextMessageId;
          setNextMessageId(nextMessageId);
          const lastMessage = {
            messageId: "new",
            role: "user",
            message: userMessage,
            group: currentConversationGroup,
          } as ConversationMessageType;
          setMessageList((messageList) => [
            ...(messageList || []),
            lastMessage,
          ]);
        }
      },
    }
  );
  const { mutate: completeConversation } = useMutation(
    () => toolApi.completeConversation(ttr, userMessage),
    {
      onSuccess: () => {
        setUserMessage("");
        queryClient.invalidateQueries([
          TOOL_THOUGHTRECORD_DOMAIN,
          TOOL_THOUGHTRECORD_CONVERSATION_DETAIL_ENDPOINT,
        ]);
        setIsConversationOpen(false);
      },
    }
  );

  function removePreviousStatusUnansweredQuestion(
    messageList: ConversationMessageType[],
    currentStatusIndex: number,
    isCompleted: boolean
  ) {
    if (
      isCompleted &&
      messageList[messageList.length - 1].role === "assistant"
    ) {
      messageList.splice(messageList.length - 1, 1);
    }
    for (let i = messageList.length - 1; i > 1; i--) {
      if (messageList[i].group !== messageList[i - 1].group) {
        const currentMessageStatusIndex = dataByStatus.findIndex(
          (element) => element.conversation_group === messageList[i - 1].group
        );
        if (
          currentMessageStatusIndex < currentStatusIndex &&
          messageList[i - 1].role === "assistant"
        ) {
          messageList.splice(i - 1, 1);
        }
      }
    }
    return messageList;
  }

  const contentRef = useRef<HTMLDivElement>(null);

  const isKeyboardOpen = useIsKeyboardOpen();

  useEffect(() => {
    if (isKeyboardOpen) {
      if (contentRef.current) {
        setTimeout(() => {
          contentRef.current?.scrollTo({
            top: contentRef.current?.scrollHeight,
            behavior: "smooth",
          });
        }, 0);
      }
    }
  }, [isKeyboardOpen]);

  return (
    <BottomSheet
      isVisible={isConversationOpen}
      setIsVisible={setIsConversationOpen}
      backgrounColor="#222222"
    >
      <Box
        ref={contentRef}
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          overflow: "scroll",
          padding: "20px",
          pb: "100px",
        }}
      >
        {dataByStatus.map(
          ({ status, conversation_group, title, icon }, index) => {
            const isCurrentStepCompleted =
              isCompleted ||
              index < currentStatusIndex ||
              ABCData?.isProcessing ||
              false;
            const currentMessageList = getMessageListByGroup(
              conversation_group,
              messageList
            );
            const nextConversationGroup =
              dataByStatus[index + 1]?.conversation_group;
            const nextMessageList = getMessageListByGroup(
              nextConversationGroup,
              messageList
            );
            const isVisible =
              index === currentStatusIndex ||
              (currentMessageList || []).length > 0;

            const isNextVisible =
              index + 1 === currentStatusIndex ||
              (nextMessageList || []).length > 0;
            return isVisible ? (
              <React.Fragment key={`conversation_${conversation_group}`}>
                <MessageList
                  title={title}
                  titleStartDecorator={icon}
                  messageList={currentMessageList}
                  userMessage={userMessage}
                  setUserMessage={setUserMessage}
                  isShowUserTextarea={isShowUserTextarea}
                  continueAction={continueConversation}
                  completeAction={completeConversation}
                  isCompleted={isCurrentStepCompleted}
                  isLoading={
                    (currentMessageList || []).length === 0 || !!nextMessageId
                  }
                  canStop={
                    !!currentMessageList &&
                    currentMessageList.length > 0 &&
                    (currentMessageList[currentMessageList.length - 1].role ===
                      "user" ||
                      (conversation_group === "isolation"
                        ? isABCAllFilled && currentMessageList.length > 6
                        : currentMessageList[currentMessageList.length - 1]
                            .isLast))
                  }
                />
                {isNextVisible && (
                  <Box
                    sx={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "white",
                      opacity: 0.1,
                      my: "40px",
                    }}
                  />
                )}
              </React.Fragment>
            ) : (
              <React.Fragment
                key={`conversation_${conversation_group}`}
              ></React.Fragment>
            );
          }
        )}
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: showChageIndicator ? "32px" : "0px",
          opacity: showChageIndicator ? 1 : 0,
          transition: "0.4s",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Button
          size="sm"
          isRound
          buttonText="더 분리되었어요"
          color="gradient-orange"
          onClick={() => {
            setIsConversationOpen(false);
            setShowChangeIndicator(false);
          }}
          endDecorator={<SimpleDownArrowWithCircle />}
        />
      </Box>
    </BottomSheet>
  );
}
