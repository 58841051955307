import Typography from '@mui/joy/Typography';
import useOpenTask from 'hooks/useOpenTask';
import { Box, Stack } from '@mui/joy';
import useGetTaskTitleFromTaskKey from 'hooks/useGetTaskTitleFromTaskKey';
import useIsInappWebview from 'hooks/useIsInappWebview';
import { ProgressIcon, RightArrow } from 'static/image/common/IconAssets';

export default function OverviewItemV2({ taskKey }: { taskKey: string }) {
  const openTask = useOpenTask(`${taskKey}`);
  const title = useGetTaskTitleFromTaskKey(taskKey);
  const isInappWebview = useIsInappWebview();

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing='10px'
      sx={{
        cursor: 'pointer',
        px: '20px',
        py: '16px',
        borderRadius: '12px',
        ...(isInappWebview
          && {
            backgroundColor: 'white',
            // '&:hover': {
            //   backgroundColor: 'background.level1',
            // },
          }),
      }}
    >
      <Box
        sx={{
          width: '20px',
          height: '20px',
          mr: '6px',
        }}
      >
        {taskKey === 'progress' ? (
          <ProgressIcon />
        ) : (
          <></>
        )}
      </Box>
      <Typography
        sx={{
          flex: 1,
          fontSize: '16px',
          lineHeight: 1,
          whiteSpace: 'nowrap',
        }}
        fontWeight={600}
        endDecorator={(
          <Box
            sx={{
              transform: 'translateX(-4px)',
            }}
          >
            <RightArrow />
          </Box>
        )}
        onClick={() => {
          (document.activeElement as HTMLElement)?.blur();
          openTask();
        }}>
        {title}
      </Typography>
    </Stack>
  );
}
