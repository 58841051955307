import { useState } from "react";
import { Button, Option, Select, Stack } from "@mui/joy";
import TitleWithSubtitle from "components/common/TitleWithSubtitle";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/paths";
import FullPageWithCenterContent from "pages/container/FullPageWithCenterContent";

export const institutionData = [
  { name: "서울대학교", domain: "snu.ac.kr" },
  { name: "카이스트", domain: "kaist.ac.kr" },
  { name: "연세대학교" },
  { name: "홍익대학교" },
  { name: "한국외국어대학교" },
  { name: "서울여자대학교" },
  { name: "한양대학교" },
  { name: "이화여자대학교" },
  { name: "네이버" },
  { name: "초등교사" },
  { name: "기타" },
];

export default function InstitutionSelectPage() {
  let navigate = useNavigate();
  const [institution, setInstitution] = useState<string>();

  return (
    <FullPageWithCenterContent
      content={
        <Stack spacing={3} sx={{ width: "100%" }}>
          <TitleWithSubtitle
            title={"소속 학교/단체를 선택해주세요"}
            sx={{ width: "100%", textAlign: "center" }}
          />
          <Select
            className="w-full"
            value={institution}
            onChange={(e) => {
              const selected = (e?.target as HTMLElement)?.innerText;
              setInstitution(selected);
            }}
          >
            {institutionData.map(({ name }, index) => (
              <Option key={name} value={name}>
                {name}
              </Option>
            ))}
          </Select>
          <Stack>
            <Button
              size="lg"
              disabled={!institution}
              onClick={() => {
                navigate(
                  `${PATH.emailAuth}?type=${institutionData.findIndex(
                    (element) => element.name === institution
                  )}`
                );
              }}
            >
              다음
            </Button>
            <Button
              onClick={() => {
                navigate(PATH.emailAuth);
              }}
              variant={"plain"}
              size="lg"
            >
              {"이미 계정이 있어요"}
            </Button>
          </Stack>
        </Stack>
      }
    />
  );
}
