export const translation_101_2_5_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "앞서 무기력의 악순환이 다음과 같은 모습을 보인다는 것을 배웠어요.",
      typography_1_0_0:
        "우리는 이 악순환을 다음과 같은 선순환 구조로 만들 거예요.",
      typography_2_0_0:
        "즉, 일상적인 사건들 속에서 나의 가치에 맞는 행동들을 선택할 수 있도록 연습할 거예요.",
      typography_3_0_0:
        "특히 그러한 행동 중 즐거움이나 성취감과 같은 긍정적인 보상을 주는 ‘긍정 강화 행동'을 찾고, 이를 더 자주 연습할 거예요.",
      typography_4_0_0:
        "‘긍정 강화 행동'은 단기적으로는 나에게 긍정적인 보상을 주고,",
      typography_5_0_0:
        "장기적으로는 나의 가치에 부합하는 삶을 살 수 있도록 도와줄 거예요.",
      typography_6_0_0:
        "그 결과, 잃어버렸던 자기효능감이 조금씩 증가하고, 이는 다시 나의 가치를 강화해줄 것이랍니다.",
      typography_7_0_0:
        "이렇게 우리는 악순환에서 벗어나 선순환으로 나아갈 거예요.",
      typography_8_0_0: "다음과 같은 식일 거예요.",
      typography_9_0_0: "악순환에서 나의 패턴은 다음과 같았어요.",
      typography_10_0_0: "선순환에서는 이런 패턴을 만들 수 있어요.",
      "$A$, $B$, $C$": "$A$, $B$, $C$",
      typography_10_1_0: "일상 속 다양한 사건",
      typography_10_3_0: "긍정적인 보상",
      typography_10_4_0: "선순환 형성",
      typography_11_0_0: "가치를 향한 길은 일직선의 도로는 아니에요.",
      typography_12_0_0:
        "원하는 방향으로 가다 보면 때로 장애물이 그 길을 가로막기 때문이죠.",
      typography_13_0_0: "우리 모두는 인간이기 때문에 길은 똑바르지 않아요.",
      typography_14_0_0:
        "우리는 동쪽으로 가려고 때로 방황하며 자신도 모르고 북쪽으로 향해 있음을 발견하기도 하죠.",
      typography_15_0_0: "당연한 일이에요. 그러니 걱정하지 마세요.",
      typography_16_0_0:
        "디스턴싱이 그러한 상황에서 다시 방향성을 잡아 궁극적으로 가치에 기반한 선순환으로 나아갈 수 있도록 도와드릴 테니까요.",
      typography_17_0_0:
        "자, 그러면 이제 선순환을 향한 활동에 더 집중해 보도록 할까요?",
      typography_18_0_0:
        "살펴보아도 문제가 없다면 앞으로 이 가치를 실현할 수 있게 행동 변화를 만들어내고자 해요.",
      typography_19_0_0:
        "물론 당장 이 가치를 실현하는 활동부터 시작하진 않을 거예요.",
      typography_20_0_0:
        "아주 쉽고 간단한 활동부터 시작해서 무기력에서 빠져나와 점차 이 가치에 맞는 삶으로 나아갈 계획이에요.",
      typography_21_0_0:
        "하지만 잊지 마세요. 우리가 지금 행동 변화를 만들고 있는 사소한 부분은 아주 조만간 이 가치를 향해 나아가기 위해 시작한 것이라는 점을요.",
      typography_22_0_0:
        "자, 이제 마지막으로 다음 활동에서는 이 가치가 악순환을 벗어나 선순환을 만드는 것과 어떤 관련이 있는지 살펴볼 거예요.",
    },
  },
};
