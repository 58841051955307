export const translation_3_2 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "이번엔 생각의 상징적 효과가 어떻게 우리를 괴롭게 만드는지 살펴보겠습니다.",
      typography_1_0_0:
        "여러분들이 지금까지 살면서 겪었던 경험 중 가장 부끄러운 일,",
      typography_2_0_0: "또는 최근 겪었던 아주 괴로웠던 일을 떠올려보세요.",
      typography_3_0_0:
        "그리고 잠시 동안 이 일을 실제로 머리속에서 되새겨 보세요.",
      typography_4_0_0:
        "당시 나의 모습, 주변의 환경, 그때의 기분 등 그 경험을 다시 되짚어 보세요.",
      typography_5_0_0: "잠시 눈을 감고 과거로 다녀와도 좋습니다.",
      typography_6_0_0: "꼭 해보시고 다음으로 넘어가주세요.",
      typography_7_0_0: "실재가 된 생각",
      typography_8_0_0:
        "어땠나요? 아마 과거와 동일하게 부끄러움이나 괴로움을 느꼈을 것입니다.",
      typography_9_0_0:
        "레몬으로 했던 실험과 같이, 생각의 상징적 효과는 이렇게 정신적 실재를 만들며 우리를 괴롭힙니다.",
      typography_10_0_0: "생각이 그토록 상징적이라면, 우린 어떻게 해야 할까요?",
      typography_11_0_0:
        "실재하지 않는 것을 실재한다고 믿으며 계속해서 괴로워해야 할까요?",
      typography_12_0_0: "다시 레몬",
      typography_13_0_0: "다시 레몬을 떠올려 봅니다.",
      typography_14_0_0:
        "하지만 이번에는 눈을 감고 레몬이라는 단어를 30초 동안 반복해서 빠르게 말해볼 겁니다.",
      typography_15_0_0:
        "레몬, 레몬, 레몬, 레몬, 레몬, 레몬, 레몬... 이렇게요.",
      typography_16_0_0: "꼭 소리를 내서 말해야 합니다. 빠르게요.",
      typography_17_0_0:
        "바보같아 보여 부끄럽다면 혼자 있는 공간으로 이동하셔서 꼭 해보시길 바랍니다.",
      typography_18_0_0: "자, 30초입니다. 꼭 해보시고 다음으로 넘어가주세요.",
      typography_19_0_0: "껍데기 뿐인 레몬",
      typography_20_0_0: "자, 다시 레몬을 떠올려봅니다. 어떤가요?",
      typography_21_0_0: "침샘에서 침이 흘러나오나요?",
      typography_22_0_0:
        "지금 나의 머리속에서 레몬이 이전에 상상했던 것 만큼 강렬한가요?",
      typography_23_0_0: "아마 아닐 겁니다.",
      typography_24_0_0:
        "이처럼 우리는 생각과의 관계를 아주 간단한 방법으로 해체시킬 수 있습니다.",
      typography_25_0_0: "그러면 생각은 상징적인 효과를 잃게 됩니다.",
      typography_26_0_0:
        "레몬은 더이상 정신적 실재가 아닌, 그냥 단어일 뿐입니다.",
      typography_27_0_0:
        "ㄹ,ㅔ,ㅁ,ㅗ,ㄴ 이라는 모음과 자음으로 구성된 문자에 지나지 않습니다.",
      typography_28_0_0: "생각과 관계를 다시 맺기",
      typography_29_0_0:
        "이렇듯 우린 생각과 관계를 다시 맺으며 괴로움에서 벗어날 수 있습니다.",
      typography_30_0_0:
        "앞으로 우린 다양한 방법으로 생각과 관계를 다시 맺는 법을 터득해 나갈 것입니다.",
      typography_31_0_0:
        "그것이 자동적이면서 상징성이 강한 생각을 두고 우리가 취할 수 있는 유일한 해결책입니다.",
      typography_32_0_0:
        "이 기술이 바로 디스턴싱에서 여러분들에게 가르쳐드리고 싶은 것이죠.",
      typography_33_0_0:
        "프로그램을 충실히 따라오고 꾸준히 훈련한다면, 아마 여러분들은 삶에서 많은 것들을 바꿀 수 있을 것입니다.",
    },
  },
};
