import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import { Box, ColorPaletteProp, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { addAlpha } from "styles/hexOpacity";
import { sendMessage } from "utils/webview";

export default function NonDismissableModal({
  title,
  subtitle,
  textAlign,
  content,
  action,
  cancelAction,
  actionText,
  cancelText,
  actionButtonColor,
  actionButtonFullWidth,
  actionButtonDisabled,
}: {
  title?: string;
  subtitle?: string;
  textAlign?: "center";
  content?: React.ReactNode;
  action?: () => void;
  cancelAction?: () => void;
  actionText?: string;
  cancelText?: string;
  actionButtonColor?: ColorPaletteProp;
  actionButtonFullWidth?: boolean;
  actionButtonDisabled?: boolean;
}) {
  const [open, setOpen] = useState(false);

  function sendModalEventToApp(status: "open" | "close") {
    sendMessage(
      JSON.stringify({
        event: "modal",
        params: {
          status: status,
        },
      })
    );
  }

  useEffect(() => {
    sendModalEventToApp("open");
    return () => {
      sendModalEventToApp("close");
    };
  }, []);

  return (
    <React.Fragment>
      <Modal
        open={true}
        sx={{
          "&>.MuiModal-backdrop": {
            backdropFilter: "none",
            backgroundColor: addAlpha("#000000", 0.3),
            transition: "all 0.3s",
          },
        }}
      >
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          onClick={(e) => {
            // e.preventDefault();
            e.stopPropagation();
          }}
        >
          {title && (
            <Typography
              id="nested-modal-title"
              level="h2"
              sx={{ textAlign: textAlign }}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              id="nested-modal-description"
              textColor="text.tertiary"
              sx={{ textAlign: textAlign }}
            >
              {subtitle}
            </Typography>
          )}
          {content}
          {actionText && (
            <Box
              sx={{
                mt: 1,
                display: "flex",
                gap: 1,
                flexDirection: { xs: "column", sm: "row-reverse" },
              }}
            >
              {actionText && (
                <Button
                  disabled={actionButtonDisabled}
                  variant="solid"
                  size="lg"
                  color={actionButtonColor || "primary"}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (action) {
                      action();
                    }
                    setOpen(false);
                  }}
                  sx={{ width: actionButtonFullWidth ? "100%" : "auto" }}
                >
                  {actionText}
                </Button>
              )}
              {cancelText && (
                <Button
                  variant="plain"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (cancelAction) {
                      cancelAction();
                    }
                    setOpen(false);
                  }}
                >
                  {cancelText}
                </Button>
              )}
            </Box>
          )}
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
