import { getLanguageCode } from "logic/logics";
import { useRecoilValue } from "recoil";
import { localizationAtom } from "recoil/localizationAtom";

export default function useGetLocale() {
  const locale = useRecoilValue(localizationAtom);
  const localeWithDefault =
    locale.startsWith("ko") || locale.startsWith("en")
      ? getLanguageCode(locale)
      : "en";
  return localeWithDefault;
}
