import { Stack, Typography } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import {
  ThoughtRecordItemType,
  toolApi,
  TOOL_THOUGHTRECORD_DOMAIN,
  TOOL_THOUGHTRECORD_LIST_BY_DATE_ENDPOINT,
} from "api2/toolApi";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/paths";
import { addAlpha } from "styles/hexOpacity";
import Status from "./Status";

function Record(props: ThoughtRecordItemType) {
  const navigate = useNavigate();
  return (
    <Stack
      sx={{
        border: `1px solid ${addAlpha("#000000", 0.1)}`,
        borderRadius: "20px",
        backgroundColor: "white",
        p: "24px",
      }}
      spacing="8px"
      onClick={() => {
        const thoughtRecordId = props.thoughtRecordId;
        const path = PATH.thoughtRecordTool_isolation.replace(
          ":ttr",
          thoughtRecordId
        );
        navigate(path);
      }}
    >
      <Typography
        sx={{
          fontSize: "17px",
          color: "#222222",
          fontWeight: 500,
          ...(!props.title && { opacity: 0.5 }),
        }}
      >
        {props.title || "아직 구체화되지 않았어요"}
      </Typography>

      <Status status={props.status} />
    </Stack>
  );
}

export default function RecordList({ date }: { date: string }) {
  // const [thoughtRecordList, setThoughtRecordList] =
  //   useState<ThoughtRecordItemType[]>();

  const getListByDateQueryFn = useCallback(
    () => toolApi.getListByDate(date),
    [date]
  );

  const { data: thoughtRecordListData, refetch: getListByDate } = useQuery(
    [TOOL_THOUGHTRECORD_DOMAIN, TOOL_THOUGHTRECORD_LIST_BY_DATE_ENDPOINT, date],
    getListByDateQueryFn,
    {
      suspense: true,
    }
  );
  const thoughtRecordList = thoughtRecordListData?.result;

  return (
    <Stack
      sx={{
        flex: 1,
        width: "100%",
        px: "16px",
      }}
      spacing="8px"
    >
      {thoughtRecordList && thoughtRecordList.length > 0 ? (
        thoughtRecordList.map((each, index) => (
          <Record key={each.thoughtRecordId} {...each} />
        ))
      ) : (
        <Stack
          sx={{ flex: 1, textAlign: "center" }}
          spacing="16px"
          justifyContent={"center"}
          alignItems="center"
        >
          <Typography
            sx={{ fontSize: "20px", color: "#222222", fontWeight: 700 }}
          >
            {"마음속 괴로움, 꺼내어 분리해보세요"}
          </Typography>
          <Typography sx={{ fontSize: "16px", color: "#222222", opacity: 0.5 }}>
            {`“생각은 나“가 아니니까요.\n떼어놓고 보면 다른 것들이 보이기 시작할 거예요.`}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
