import { ChartsXAxis, ResponsiveChartContainer } from "@mui/x-charts";
import {
  LinePlot,
  MarkPlot,
  MarkElement,
  MarkElementProps,
  LineElementProps,
  LineElement,
} from "@mui/x-charts/LineChart";
import { theme } from "styles/theme";
import { Box, Stack, Typography } from "@mui/joy";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { usePrevious } from "@uidotdev/usehooks";
import { extractHexColor } from "styles/hexOpacity";

export default function LineChart({
  id,
  data,
  maxScore,
  placeholder,
  abnormalScore = 0,
  xLabel,
  renderIfFocus,
  showYRange,
  showXAxis,
  showMarkValue,
}: {
  id: string;
  data: number[];
  maxScore: number;
  placeholder?: string;
  abnormalScore?: number;
  xLabel?: string[];
  renderIfFocus?: React.ReactNode[];
  showYRange?: boolean;
  showXAxis?: boolean;
  showMarkValue?: boolean;
}) {
  const previousData = usePrevious(data);
  const highlightColor = theme.vars.palette.primary.solid;
  const normalColor =
    abnormalScore === 0
      ? highlightColor
      : theme.vars.palette.primary.deactivated;
  const CustomMark = ({
    color,
    props,
    onClick,
    value,
    showMarkValue,
  }: {
    color?: string;
    props: MarkElementProps;
    onClick: (event: React.MouseEvent) => void;
    value: number;
    showMarkValue?: boolean;
  }) => {
    const customStyle = { stroke: color, fill: color };
    const valueTextColor = extractHexColor(theme.vars.palette.common.black);
    return (
      <>
        <MarkElement
          {...props}
          style={customStyle}
          onClick={(event: React.MouseEvent) => {
            onClick(event);
          }}
        />

        {showMarkValue && (
          <text
            x={props.x}
            y={props.y - 10} // 점 위에 표시하기 위해 y좌표를 올립니다.
            textAnchor="middle"
            fill={valueTextColor}
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            {value}
          </text>
        )}
      </>
    );
  };

  const GradientDefs = ({
    highlightList,
    highlightColor,
    normalColor,
    id,
  }: {
    highlightList: boolean[];
    highlightColor: string;
    normalColor: string;
    id: string;
  }) => {
    return (
      <defs>
        <linearGradient id={id}>
          {highlightList
            .filter((element, index) => index < highlightList.length - 1)
            .map((isHighlight, index) => (
              <Fragment key={`${id}_${index}_fragment`}>
                <stop
                  offset={`${Math.floor(
                    index * (100 / (highlightList.length - 1))
                  )}%`}
                  stopColor={isHighlight ? highlightColor : normalColor}
                />
                <stop
                  offset={`${Math.floor(
                    (index + 1) * (100 / (highlightList.length - 1))
                  )}%`}
                  stopColor={isHighlight ? highlightColor : normalColor}
                />
              </Fragment>
            ))}
        </linearGradient>
      </defs>
    );
  };
  const CustomLine = ({ props }: { props: LineElementProps }) => {
    const customStyle = {
      stroke:
        data.length === 2 && data[0] === data[1]
          ? data[0] < abnormalScore
            ? highlightColor
            : normalColor
          : `url(#lineGradient_${id})`,
      // stroke: "#000000",
    };
    return <LineElement {...props} style={customStyle} />;
  };

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const [selectedMark, setSelectedMark] = useState<{
    index: number;
    positionX: number;
    positionY: number;
  }>();
  const renderIfFocusWidth = 200;

  const [chartContainer, setChartContainer] = useState<{
    offsetLeft: number;
    offsetWidth: number;
  }>();

  useEffect(() => {
    if (data.length > 0 && chartContainerRef.current && !selectedMark) {
      setChartContainer({
        offsetLeft: chartContainerRef.current.offsetLeft,
        offsetWidth: chartContainerRef.current.offsetWidth,
      });
      const lastMarkIndex = data.length - 1;
      setTimeout(() => {
        if (chartContainerRef.current) {
          const lastMarkElement = chartContainerRef.current.querySelectorAll(
            ".MuiMarkElement-root"
          )[lastMarkIndex];

          if (lastMarkElement) {
            const rect = lastMarkElement.getBoundingClientRect();
            if (renderIfFocus) {
              setSelectedMark({
                index: lastMarkIndex,
                positionX:
                  rect.left -
                  chartContainerRef.current.getBoundingClientRect().left +
                  rect.width / 2,
                positionY:
                  rect.top -
                  chartContainerRef.current.getBoundingClientRect().top +
                  rect.height,
              });
            }
          }
        }
      }, 100);
    }
  }, [data, id]);

  const handleMarkClick = (event: React.MouseEvent, index: number) => {
    const element = event.target as HTMLElement;
    const rect = element?.getBoundingClientRect();

    setSelectedMark({
      index,
      positionX:
        rect.left -
        (chartContainerRef.current?.getBoundingClientRect().left || 0) +
        rect.width / 2,
      positionY:
        rect.top -
        (chartContainerRef.current?.getBoundingClientRect().top || 0) +
        rect.height,
    });
  };

  const marginY = 20;
  const chartHeight = 200;
  const xLabelTranslateY = 20;

  const unitWidth = Math.max(
    60,
    (chartContainerRef.current?.offsetWidth || 0) / (data.length || 1)
  );

  //그래프 최신부분부터 보여주기
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollWidth - scrollRef.current.offsetWidth,
        behavior: "auto",
      });
    }
  }, [scrollRef, data]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: chartHeight + xLabelTranslateY,
        overflowX: "hidden", // 가로 스크롤 허용
        overflowY: "hidden", // 세로 스크롤 숨김
      }}
      ref={chartContainerRef}
    >
      <Box
        sx={{
          position: "absolute",
          width: "1px",
          height: `${chartHeight}px`,
          backgroundColor: "text.positive",
          opacity: 0.1,
        }}
      />
      {abnormalScore > 0 && abnormalScore < 100 && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "1px",
            top: `${
              marginY +
              (chartHeight - (marginY + xLabelTranslateY)) *
                (1 - abnormalScore / maxScore)
            }px`,
            backgroundColor: "text.positive",
            opacity: 0.1,
          }}
        />
      )}
      {showXAxis && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "1px",
            top: `${marginY + (chartHeight - marginY)}px`,
            backgroundColor: "text.positive",
            opacity: 0.1,
          }}
        />
      )}

      {showYRange &&
        [0, maxScore].map((each, index) => (
          <Typography
            key={`scoreLabel${each}`}
            level="body-xs"
            sx={{
              position: "absolute",
              ...(index === 1
                ? { top: `${marginY}px`, transform: "translateY(-50%)" }
                : {
                    bottom: `${marginY + xLabelTranslateY}px`,
                    transform: "translateY(50%)",
                  }),
              right: 0,
              opacity: 0.5,
            }}
          >
            {each}
          </Typography>
        ))}
      {data.length > 0 ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflowX: "auto", // 가로 스크롤 허용
            overflowY: "hidden", // 세로 스크롤 숨김
            whiteSpace: "nowrap", // 요소가 줄바꿈되지 않도록 설정
          }}
          ref={scrollRef}
        >
          <Box
            sx={{
              width: `${data.length * unitWidth}px`,
              height: "100%",
              display: "inline-block",
            }}
          >
            <ResponsiveChartContainer
              margin={{
                top: marginY,
                left: 40,
                right: 40,
                bottom: marginY + xLabelTranslateY,
              }}
              series={[
                {
                  curve: "linear",
                  type: "line",
                  data: data,
                },
              ]}
              xAxis={[
                {
                  scaleType: "point",
                  data: xLabel || data.map((each, index) => `${index}`),
                  id: "bottom-axis-id",
                },
              ]}
              yAxis={[
                {
                  min: 0,
                  max: maxScore,
                  scaleType: "linear",
                  data: data,
                  id: "right-axis-id",
                },
                {
                  scaleType: "point",
                  data: data.map((each) => ""),
                  id: "left-axis-id",
                },
              ]}
              sx={{
                ".MuiMarkElement-root": {
                  strokeWidth: 5,
                  scale: "0.6",
                },
                ".MuiChartsAxis-root": {
                  // transform: "translateY(0px)",
                },
              }}
              disableAxisListener
            >
              <GradientDefs
                highlightList={data.map(
                  (each, index) =>
                    index < data.length - 1 &&
                    data[index] < abnormalScore &&
                    data[index + 1] < abnormalScore
                )}
                highlightColor={highlightColor}
                normalColor={normalColor}
                id={`lineGradient_${id}`}
              />
              <LinePlot
                slots={{
                  line: (props) => <CustomLine props={props} />,
                }}
              />
              <MarkPlot
                slots={{
                  mark: (props) => (
                    <CustomMark
                      props={props}
                      color={
                        data[props.dataIndex] < abnormalScore
                          ? highlightColor
                          : normalColor
                      }
                      onClick={(event: React.MouseEvent) =>
                        handleMarkClick(event, props.dataIndex)
                      }
                      value={data[props.dataIndex]}
                      showMarkValue={showMarkValue}
                    />
                  ),
                }}
              />
              <ChartsXAxis
                position="bottom"
                axisId="bottom-axis-id"
                disableLine
                disableTicks
                tickLabelStyle={{
                  opacity: xLabel ? 0.5 : 0,
                  transform: `translateY(${marginY}px)`,
                }}
              />
            </ResponsiveChartContainer>
          </Box>
          {selectedMark && renderIfFocus && (
            <>
              <Box
                sx={{
                  position: "absolute",
                  left: `${selectedMark.positionX - 5}px`,
                  top: `${selectedMark.positionY}px`,
                  color: "background.level2",
                  fontSize: "15px",
                }}
              >
                ▲
              </Box>
              <div
                style={{
                  position: "absolute",
                  left: `${Math.max(
                    10,
                    Math.min(
                      selectedMark.positionX - renderIfFocusWidth / 2,
                      (chartContainer?.offsetLeft || 0) +
                        (chartContainer?.offsetWidth || 0) -
                        renderIfFocusWidth -
                        10
                    )
                  )}px`,
                  top: `${selectedMark.positionY + 15}px`,
                }}
              >
                <Box
                  sx={{
                    width: `${renderIfFocusWidth}px`,
                    py: "8px",
                    px: "16px",
                    backgroundColor: "background.level2",
                    borderRadius: "4px",
                  }}
                >
                  {renderIfFocus[selectedMark.index]}
                </Box>
              </div>
            </>
          )}
        </Box>
      ) : (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            pb: "20px",
          }}
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: "14px", opacity: 0.3, fontWeight: 500 }}>
            {placeholder}
          </Typography>
        </Stack>
      )}
    </div>
  );
}
