import {
  Box,
  ListItemButton,
  ListItemContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/joy";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import dayjs from "dayjs";
import * as _ from "lodash";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { layoutAtom } from "recoil/layoutAtom";
import { ActivityRecordType } from "api/activityRecordApi";

export default function ActivityRecordListItem({
  isFirstRecordOfMonth,
  activityRecord,
  isSelected,
  setCurrentActivityRecord,
  isSucceeded,
  shouldBeDone,
}: {
  isFirstRecordOfMonth: boolean;
  activityRecord: ActivityRecordType;
  isSelected?: boolean;
  setCurrentActivityRecord: React.Dispatch<
    React.SetStateAction<ActivityRecordType | undefined>
  >;
  isSucceeded?: boolean;
  shouldBeDone?: boolean;
}) {
  const { activityRecordKey, date, time, activity, isHighlight } =
    activityRecord;

  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";
  const { isTaskContainerSmall } = useRecoilValue(layoutAtom);

  return (
    <div key={activityRecordKey}>
      {isFirstRecordOfMonth && (
        <Typography
          level="body-xs"
          sx={{
            px: "12px",
            py: "8px",
            opacity: "0.5",
            fontWeight: 500,
            whiteSpace: "nowrap",
          }}
        >
          {`${dayjs(date).format("MMM")}`}
        </Typography>
      )}
      <ListItemButton
        onClick={() => {
          setCurrentActivityRecord(activityRecord);
          (document.activeElement as HTMLElement)?.blur();
        }}
      >
        <ListItemContent>
          <Box
            sx={{
              py: "12px",
              borderRadius: "4px",
              backgroundColor: isSelected ? "background.level2" : "transparent",
            }}
          >
            <Stack
              key={`activityRecord_${activityRecordKey}`}
              direction={"row"}
              alignItems="center"
              sx={{
                position: "relative",
              }}
            >
              {isHighlight && (
                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    width: "2px",
                    height: "100%",
                    borderRadius: "10px",
                    backgroundColor: "primary.solid",
                  }}
                />
              )}

              <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                sx={{
                  width: "48px",
                  minWidth: "48px",
                  mr: "8px",
                }}
              >
                <Typography
                  level={"title-lg"}
                  {...(shouldBeDone && { color: "danger" })}
                >
                  {dayjs(date).get("date")}
                </Typography>
                <Typography
                  sx={{ opacity: 0.5 }}
                  {...(shouldBeDone && { color: "danger" })}
                >
                  {dayjs(date).format("ddd")}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  flex: 1,
                  opacity: isTaskContainerSmall ? 0 : 1,
                  pr: "20px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {`${dayjs(`${date}T${time}`).format("a h시")}, `}
                {activity}
                {/* {activityRecord.activityRecordKey}
                <br />
                {activityRecord.taskKey} */}
              </Typography>
              {isSucceeded && (
                // <SvgIcon
                //   sx={{
                //     width: "16px",
                //     height: "16px",
                //     mr: "8px",
                //     color: "primary.solid",
                //   }}
                // >
                //   <AutoAwesomeIcon />
                // </SvgIcon>
                <Box sx={{ color: "primary.solid" }}>
                  <span
                    style={{
                      marginRight: "8px",
                    }}
                    className="material-symbols-rounded"
                  >
                    done
                  </span>
                </Box>
              )}
            </Stack>
          </Box>
        </ListItemContent>
      </ListItemButton>
    </div>
  );
}
