import {
  Box,
  ListItemButton,
  ListItemContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/joy";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import dayjs from "dayjs";
import * as _ from "lodash";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { layoutAtom } from "recoil/layoutAtom";
import { memo } from "react";
import { MeditationRecordType } from "api/meditationRecordApi";

export default function RecordListItem({
  width,
  direction = "column",
  isFirstRecordOfMonth,
  record,
  isSelected,
  setCurrentRecord,
  shouldBeDone,
  durationMinutes,
}: {
  width: string;
  direction?: "row" | "column";
  isFirstRecordOfMonth: boolean;
  record: MeditationRecordType;
  isSelected?: boolean;
  setCurrentRecord: React.Dispatch<
    React.SetStateAction<MeditationRecordType | undefined>
  >;
  shouldBeDone?: boolean;
  durationMinutes?: number;
}) {
  const { meditationRecordKey, date, title, isHighlight, hasAiRecommendation } =
    record;

  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";
  const isManual = isCoach && user?.isCoachAiSupportDisabled;
  const { isTaskContainerSmall } = useRecoilValue(layoutAtom);

  const hasAiComment = isCoach && !isManual && hasAiRecommendation;

  return (
    <Stack direction={direction} key={meditationRecordKey}>
      {isFirstRecordOfMonth && (
        <Typography
          level="body-xs"
          sx={{
            px: "12px",
            py: "8px",
            opacity: "0.5",
            fontWeight: 500,
            whiteSpace: "nowrap",
          }}
        >
          {`${dayjs(date).format("MMM")}`}
        </Typography>
      )}
      <ListItemButton
        sx={{ width: width, "--ListItem-radius": "6px" }}
        onClick={() => {
          setCurrentRecord(record);
          (document.activeElement as HTMLElement)?.blur();
        }}
      >
        <ListItemContent sx={{ borderRaius: "6px" }}>
          <Box
            sx={{
              py: "12px",
              borderRadius: "6px",
              backgroundColor: isSelected ? "background.level2" : "transparent",
            }}
          >
            <Stack
              key={`record_${meditationRecordKey}`}
              direction={"row"}
              alignItems="center"
              sx={{
                position: "relative",
              }}
            >
              {isHighlight && (
                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    width: "2px",
                    height: "100%",
                    borderRadius: "10px",
                    backgroundColor: "primary.solid",
                  }}
                />
              )}

              <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                sx={{
                  width: "48px",
                  minWidth: "48px",
                  mr: "8px",
                }}
              >
                <Typography
                  level={"title-lg"}
                  {...(shouldBeDone && { color: "danger" })}
                >
                  {dayjs(date).get("date")}
                </Typography>
                <Typography
                  sx={{ opacity: 0.5 }}
                  {...(shouldBeDone && { color: "danger" })}
                >
                  {dayjs(date).format("ddd")}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  flex: 1,
                  opacity: isTaskContainerSmall ? 0 : 1,
                  pr: hasAiComment ? "12px" : "20px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {title}
                {durationMinutes !== undefined && durationMinutes > 0 && (
                  <Typography
                    sx={{ flex: 1, opacity: 0.6, ml: "4px" }}
                    fontWeight={500}
                  >
                    {`∙${durationMinutes}분`}
                  </Typography>
                )}
              </Typography>
              {hasAiComment && (
                <SvgIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    mr: "8px",
                    color: "primary.solid",
                  }}
                >
                  <AutoAwesomeIcon />
                </SvgIcon>
              )}
            </Stack>
          </Box>
        </ListItemContent>
      </ListItemButton>
    </Stack>
  );
}
