import { Stack, Typography } from "@mui/joy";
import {
  PaymentButton,
  Keypoints,
  QnA,
  Price,
  FreeTrialInstruction,
  Reviews,
  FreeTrialTimeline,
} from "components/task/payment/elements";
import TitleWithSubtitle from "components/common/TitleWithSubtitle";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import ContentTemplate from "../content/ContentTemplate";
import Link from "components/common/Link";
import useGetUrls from "hooks/useGetUrls";
import FloatingArea from "components/common/FloatingArea";

export default function PaymentContent() {
  const { hasFreeTrialUsed } = useSubscriptionStatus();
  const { privacyPolicyUrl, servicePolicyUrl } = useGetUrls();
  const title = !hasFreeTrialUsed
    ? "코치 선생님과\n프로그램을 시작하세요"
    : "코치 선생님과\n프로그램을 이어가세요";
  const subtitle = !hasFreeTrialUsed
    ? "체험이 종료되기 전 알려드릴 거예요."
    : undefined;

  return (
    <ContentTemplate>
      <Stack spacing={3} sx={{ pb: "150px" }}>
        <Stack spacing={"52px"}>
          <Stack spacing={"30px"}>
            <TitleWithSubtitle
              title={title}
              subtitle={subtitle}
              titleLevel="h2"
              sx={{ textAlign: "center" }}
            />
            {!hasFreeTrialUsed && <FreeTrialTimeline />}
            <Stack spacing={"12px"}>
              <Keypoints /> <Price />
            </Stack>
          </Stack>
          <Stack spacing={"30px"}>
            <Typography
              level="h2"
              fontWeight={700}
              sx={{ textAlign: "center" }}
            >
              {"생각과 거리두고\n가치로 나아가세요"}
            </Typography>
            <Reviews />
          </Stack>
          <Stack spacing={"30px"}>
            <Typography
              level="h2"
              fontWeight={700}
              sx={{ textAlign: "center" }}
            >
              {"자주 묻는 질문"}
            </Typography>
            <QnA />
          </Stack>
        </Stack>
        <FloatingArea>
          {!hasFreeTrialUsed && <FreeTrialInstruction />}
          <PaymentButton />
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ pt: 1, wordBreak: "keep-all" }}
          >
            <Link text={"개인정보 처리방침"} url={privacyPolicyUrl} />
            <Link text={"서비스 이용약관"} url={servicePolicyUrl} />
          </Stack>
        </FloatingArea>
      </Stack>
    </ContentTemplate>
  );
}
