import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { tokenAtom } from "recoil/tokenAtom";
import useFetch2 from "./useFetch2";

export default function useRefreshTokenWeb() {
  const [refreshTokenResult, refreshToken, errorData] = useFetch2({
    url: "/tool/token/refresh",
  });
  const setTokenState = useSetRecoilState(tokenAtom);

  useEffect(() => {
    if (!errorData && refreshTokenResult) {
      setTokenState((state) => ({
        ...state,
        accessToken: refreshTokenResult.accessToken,
      }));
    }
  }, [refreshTokenResult, errorData]);

  return [refreshTokenResult, refreshToken, errorData];
}
