export const translation_5_1 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "지금까지 배웠던 내용을 잠깐 상기해봅시다.",
      typography_1_0_0: "능동적으로 생각하는 나는 착각이다. (명제 1)",
      typography_2_0_0: "생각은 자동적으로 마음속에 떠오르고 (명제 2)",
      typography_3_0_0: "상징적인 효과를 발휘하며 (명제 3)",
      typography_4_0_0: "우리와의 거리를 좁히며 문제를 발생시킨다. (명제 4)",
      typography_5_0_0:
        "줗습니다. 우리는 생각과 거리를 두는 것이 중요하다는 사실을 이제는 알고 있습니다.",
      typography_6_0_0: "그럼 이제 어떻게 해야할까요?",
      typography_7_0_0: "복잡한 현실",
      typography_8_0_0:
        "생각과 거리를 두고자 생각을 관찰하다보면 재미난 현상을 발견하게 됩니다.",
      typography_9_0_0:
        "많은 경우 생각, 감정, 감각, 행동이 통합되어 하나의 꾸러미로 마음속에 떠오른다는 것입니다.",
      typography_10_0_0:
        '예를 들어, "이번 프로젝트는 내가 다 망친거야." 이런 생각이 떠오르면 곧바로 아주 강렬한 감정이 찾아옵니다.',
      typography_11_0_0:
        "죄책감, 후회, 미련, 분노, 슬픔, 자괴감 같은 감정들일테죠.",
      typography_12_0_0: "강력한 신체 감각이 동반될 때도 있습니다.",
      typography_13_0_0: "두통이 찾아오고, 가슴이 답답하고, 호흡이 가빠옵니다.",
      typography_14_0_0: "특정한 행동을 하고 싶은 충동도 느껴집니다.",
      typography_15_0_0:
        "다 그만두고 싶다, 퇴사하고 싶다 등이 그 예시일 겁니다.",
      typography_16_0_0:
        "이처럼 생각은 감정, 감각, 행동과 함께 하나의 꾸러미처럼 떠오릅니다.",
      typography_17_0_0: "용어의 정리",
      typography_18_0_0:
        "지금부터는 '나', '마음', '생각', 이 세 용어를 조금 더 정확하게 사용할 겁니다.",
      typography_19_0_0:
        "가장 작은 것은 '생각'입니다.",
      typography_20_0_0:
        "생각은 감정, 감각, 행동들과 같이 꾸러미로 떠오릅니다.",
      typography_21_0_0:
        "이 꾸러미가 떠오르는 공간이 바로 '마음'입니다. 팝콘 기계라고 보아도 좋겠네요. 즉, 마음은 생각을 포함합니다.",
      typography_22_0_0: "그리고 그 마음을 포함하는 것이 '나' 입니다.",
      typography_23_0_0:
        "이렇게 생각해보면, 생각 그 자체가 '나'가 아니라는 사실이 좀 더 쉽게 이해가 되실 겁니다.",
      typography_24_0_0: "생각은 '나'가 아닙니다.",
      typography_25_0_0:
        "'나'는 마음속 공간에서 떠오르는 생각을 바라보는 주체인 것이죠.",
      typography_26_0_0: "거리두기를 방해하는 꾸러미",
      typography_27_0_0: "자, 다시 꾸러미로 돌아오겠습니다.",
      typography_28_0_0:
        "꾸러미 속 생각, 감정, 감각, 행동은 서로 영향을 주고 받습니다.",
      typography_29_0_0:
        "생각이 감정을 만들기도 하고, 감정이 생각을 만들기도 합니다.",
      typography_30_0_0:
        "또 어떤 감각 때문에 생각이 떠오르기도 하고, 또 반대로 생각이 감각을 만들어내기도 합니다.",
      typography_31_0_0: "이는 얼기설기 얽힌 실타래와 같습니다.",
      typography_32_0_0:
        "이런 꾸러미를 마주하면 생각을 생각으로 바라보는 일은 더욱 어려워집니다.",
      typography_33_0_0:
        "다음 활동지에서는 그럼 어떻게 하면 되는지, 구체적인 방법을 한 번 알아보도록 하지요.",
    },
  },
};
