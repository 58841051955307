import { Button } from "@mui/joy";
import useDetectIOS from "hooks/useDetectIOS";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import apple_logo from "static/image/common/apple_logo.png";
import google_logo from "static/image/common/google_logo.png";
import { useTranslation } from "react-i18next";

export default function LoginButtonTemplate({
  loginType,
  action,
  isLoggedIn,
}: {
  loginType?: "apple" | "google";
  action: () => void;
  isLoggedIn: boolean;
}) {
  const user = useRecoilValue(userAtom);
  const disabled = isLoggedIn;
  const { isIOS } = useDetectIOS();

  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  const loginDataByType: {
    [key in "apple" | "google"]: {
      logo?: string;
      buttonText: string;
      backgroundColor: string;
      color: string;
      borderColor?: string;
    };
  } = {
    apple: {
      logo: apple_logo,
      buttonText: t_ui("로그인", { type: "Apple" }),
      backgroundColor: "#000000",
      borderColor: "#000000",
      color: "#ffffff",
    },
    google: {
      logo: google_logo,
      buttonText: t_ui("로그인", { type: "Google" }),
      backgroundColor: "#ffffff",
      color: "#3A3C40",
      borderColor: "rgba(0,0,0,0.1)",
    },
  };

  const type = loginType ? loginType : isIOS ? "apple" : "google";

  const currentLoginData = loginDataByType[type];

  return (
    <Button
      onClick={!disabled ? action : () => {}}
      size="lg"
      sx={{
        backgroundColor: currentLoginData.backgroundColor,
        border: `1px solid ${currentLoginData.borderColor}`,
        color: currentLoginData.color,
        "&:hover": {
          backgroundColor: `${currentLoginData.backgroundColor}`,
          color: currentLoginData.color,
          opacity: 0.6,
        },
        "&:focus": {
          backgroundColor: currentLoginData.backgroundColor,
        },
        opacity: disabled ? 0.3 : 1,
      }}
      startDecorator={
        <img src={currentLoginData.logo} alt={"empty"} className="w-[20px] " />
      }
    >
      {!isLoggedIn ? currentLoginData.buttonText : t_ui("로그인 완료")}
    </Button>
  );
}
