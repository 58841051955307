import { useEffect, useState } from "react";
import { ProgramType } from "data/programData";
import * as _ from "lodash";
import useFetch from "./useFetch";
import { setDataWithEditorKeyAndTranslationVersion } from "./useGetBlankTaskData";
import useGetBlankActivityRecordData from "./useGetBlankActivityRecordData";

export default function useGetActivityRecordData({
  ark,
  taskKey,
}: {
  ark?: string;
  taskKey?: string;
}) {
  const [data, setData] = useState<ProgramType>();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const blankActivityRecordData = useGetBlankActivityRecordData({
    ark: ark,
    taskKey: taskKey,
  });

  const [fetchedData, getData] = useFetch({
    url: "/activity-record/data/load",
    params: { ark: ark },
  });

  useEffect(() => {
    if (ark) {
      getData();
      setIsFetching(true);
    }
  }, [ark]);

  useEffect(() => {
    if (fetchedData) {
      if (fetchedData.result && fetchedData.result.data) {
        const totalData = fetchedData.result.data;

        if (totalData && totalData.content.length > 0) {
          console.log("FETCH: ", totalData);
          setData(setDataWithEditorKeyAndTranslationVersion(totalData));
        }
      } else {
        console.log("RAW: ", ark, taskKey, blankActivityRecordData);
        setData(blankActivityRecordData);
      }
    }
  }, [fetchedData]);

  useEffect(() => {
    if (data) {
      setIsFetching(false);
    }
  }, [data]);

  return { data, refetch: getData, isFetching };
}
