import { Stack, Typography } from "@mui/joy";
import Chip from "components/common/Chip";
import { Status } from "components/common/Status";
import { useRecoilState } from "recoil";
import { paymentOrderAtom } from "recoil/paymentOrderAtom";
import { appLog } from "utils/webview";

export const planData = [
  {
    price: "240,000원",
    duration: "3개월",
    description: "3개월 후 효과가 없으면 100% 페이백 해드려요.",
    label: "효과 보장",
    productId: "orwellhealth.distancing.subscription10",
  },
];

export default function SubscriptionPlanOnly3Month() {
  const [paymentOrderState, setPaymentOrderState] =
    useRecoilState(paymentOrderAtom);

  return (
    <Stack
      sx={{
        width: "100%",
        maxWidth: "400px",
        backgroundColor: "white",
        borderRadius: "12px",
        borderWidth: "2px",
        borderColor: "primary.solid"
      }}
    >
      {planData.map(
        ({ price, duration, description, label, productId }, index) => (
          <Stack
            direction="row"
            alignItems={"center"}
            spacing="8px"
            sx={{
              p: "20px",
            }}
            onClick={() => {
              appLog(`click ${productId}`);
              setPaymentOrderState((state) => ({
                ...state,
                productId: productId,
              }));
            }}
          >
            <Stack direction={"column"} sx={{ gap: "2px", width: "100%" }}>
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <Typography level={"title-md"} sx={{ whiteSpace: "nowrap" }}>
                  <Typography level={"h4"} fontWeight={600}>
                    {price}
                  </Typography>
                  {` / ${duration}`}
                </Typography>
                <Chip label={label} />
              </Stack>
              <Typography level={"body-xs"} sx={{ opacity: 0.6 }}>
                {description}
              </Typography>
            </Stack>
            <Stack
              sx={{
                flexShrink: 0,
              }}
            >
            </Stack>
          </Stack>
        )
      )}
    </Stack>
  );
}
