import { useEffect, useState } from "react";
export default function useIsKeyboardOpen() {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false);
  const handleResize = () => {
    if (window.visualViewport) {
      if (window.visualViewport.height < window.innerHeight) {
        setIsKeyboardOpen(true);
      } else {
        setIsKeyboardOpen(false);
      }
    }
  };

  useEffect(() => {
    window.visualViewport &&
      window.visualViewport.addEventListener("resize", handleResize);
    return () => {
      window.visualViewport &&
        window.visualViewport.removeEventListener("resize", handleResize);
    };
  }, []);

  return isKeyboardOpen;
}
