import { behavioralActivationResource } from "translation/behavioralActivation";
import { successActivityResource } from "./104-2-A";

export const translation_101_1_3_T = {
  en: {},
  ko: {
    v1: {
      ...behavioralActivationResource.ko,
      네: "네",
      아니요: "아니요",
      typography_0_0_0: "언제 있었던 일인가요?",
      typography_1_0_0:
        "어떤 활동이었나요? 간단한 활동명을 적어주세요(예: 친구와 약속, 식사 등).",
      typography_2_0_0:
        "활동 계획 목록에 포함시킬까요? 어떤 이름으로 포함시킬까요?",
      typography_3_0_0: "어떤 이름으로 포함시킬까요?",
      typography_7_0_0: "구체적으로 무엇을 했나요? (누구와, 무엇을, 어떻게)",
      typography_4_0_0: "어떻게 느끼고 생각했나요?",
      typography_5_0_0:
        "기분은 어땠나요? (0점은 최고 나쁨, 5점은 보통, 10은 최고로 좋음)",
      typography_6_0_0: "기록을 마쳤어요. [마치기]를 눌러주세요.",

      fromtask_typography_0_0_0: "이 때 긍정 활동을 하기로 계획했어요.",
      fromtask_typography_1_0_0: "그리고 다음의 활동을 계획대로 해냈어요.",
      ...successActivityResource,
    },
  },
};
