export const translation_9_13_A = {
  en: {},
  ko: {
    v1: {
      typography_0: `자, 마음속 괴로움을 기꺼이 경험하기 위한 모든 준비를 마쳤어요.`,
      typography_1: `<생각기록지>에서는 피하지 않고 각 요소를 더 자세히, 적극적으로 관찰하려고 노력해봤다면,`,
      typography_2: `이제는 명상 훈련을 하며 피하지 않고 그 경험을 몸과 호흡으로 온전히 느껴보는 거예요.`,
      typography_3: `방법은 간단하답니다.`,
      typography_4: `생각이나 느낌을 그저 놓아주고 제자리로 돌아오기 전에`,
      typography_5: `잠시 그 생각이나 느낌을 마음속에 남아있게 해 보는 거예요.`,
      typography_6: `그런 다음 그것이 나의 신체에서는 어떻게 느껴지는지 탐색해보세요.`,
      typography_7: `경험에 휩쓸리며 당황하지 말고,`,
      typography_8: `‘생각의 강', '생각의 하늘', '생각의 공장'에서 만들었던 공간감 속에서,`,
      typography_9: `그 경험을 더 자세히 관찰해보면 된답니다.`,
      typography_10: `그 과정에서 언젠가 더 이상 그 경험이 나의 주의를 끌지 않는 순간을 발견하게 될 텐데요.`,
      typography_11: `그러면 자연스럽게 놓아주고 제자리로 돌아오는 연습을 하면 돼요.`,
      typography_12: `그러다가 또 부정적인 경험을 마주한다면 다시 그것을 온전히 느끼는 연습을 반복하면 된답니다.`,
      typography_13: `이 연습을 할 때는 다시 한번 ‘기꺼이 경험하기'의 개념을 기억해주세요.`,
      typography_14: `기꺼이 경험하기는 ‘억지로 마주하기’가 아니랍니다.`,
      typography_15: `싫지만 버티는 게 아니라 마음속에 떠오른 경험들이 하나의 심리적 사건이라는 점을 이해하고 바라보는 것이랍니다.`,
      typography_16: `또한 기꺼이 경험하기는 어떤 목적이나 결과를 기대하는 게 아니랍니다.`,
      typography_17: `“이것만 하고 나면 부정적인 감정이 사라질 거야"라는 생각으로 연습을 진행하면 생각처럼 잘되지 않을 거예요.`,
      typography_18: `회피할수록 강해질 뿐이니까요.`,
      typography_19: `텅 빈 마음, 긍정적인 것들로 가득 찬 마음, 온전히 맑고 깨끗한 마음을 좇지 말고,`,
      typography_20: `지금 내 마음속에 떠오르는 것들을 기꺼이 인정하고, 수용하고, 허용하며 호기심을 가지고 관찰해보세요.`,
      typography_21: `자, 이제부터 <명상훈련지>에서 ‘기꺼이 경험하기' 명상을 훈련할 수 있을 거예요.`,
      typography_22: `부정적인 경험으로 마음속에 괴로움이 피어날 때 사용해주세요.`,
    },
  },
};
