import { Box, Button, Stack, Typography } from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";
import { Status } from "components/common/Status";
import { format, subDays } from "date-fns";
import useFetch from "hooks/useFetch";
import { useEffect, useRef, useState } from "react";
import logo from "static/logo.png";
import html2canvas from "html2canvas";
import { sendMessage } from "utils/webview";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import useIsInappWebview from "hooks/useIsInappWebview";

export default function ShareButton({ isShown }: { isShown: boolean }) {
  const imageCardRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const isInappWebview = useIsInappWebview();

  // 2. 다운로드 버튼 클릭 시 실행될 함수
  const handleDownload = async () => {
    if (!imageCardRef.current) return;

    try {
      const canvas = await html2canvas(imageCardRef.current, {
        scale: 3, // 해상도를 3배로 증가
        backgroundColor: null, // 배경 투명하게
        logging: false, // 디버그 로그 끄기
      });

      // canvas를 이미지로 변환하고 다운로드
      const image = canvas.toDataURL("image/png", 1.0);
      if (!isInappWebview) {
        const link = document.createElement("a");
        link.download = "디스턴싱_요약카드.png";
        link.href = image;
        link.click();
      } else {
        sendMessage(
          JSON.stringify({
            event: "save_image",
            params: {
              url: image,
            },
          })
        );
      }
    } catch (error) {
      console.error("Error converting div to image:", error);
    }
  };

  const patientState = useRecoilValue(patientAtom);
  const [detailResult, getDetail] = useFetch({
    url: `/share-card/detail`,
    params: { pid: `${patientState?.patientId}` },
  });

  useEffect(() => {
    getDetail();
  }, []);

  // 오늘부터 7일 전까지의 날짜 배열을 생성하는 함수
  const getLast7Days = () => {
    const dates = [];
    for (let i = 6; i >= 0; i--) {
      const date = subDays(new Date(), i);
      dates.push(format(date, "yyyy-MM-dd")); // 날짜 포맷 지정
    }
    return dates;
  };

  const CustomTypography = ({
    sx,
    children,
  }: {
    sx: SxProps;
    children: any;
  }) => {
    return (
      <Typography
        sx={{
          fontWeight: 700,
          fontFamily: "Gaegu",
          letterSpacing: "-0.1em",
          ...sx,
        }}
      >
        {children}
      </Typography>
    );
  };

  const FlowerImage1 = () => {
    return (
      <svg
        width="107"
        height="86"
        viewBox="0 0 107 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.8">
          <path
            d="M69.6245 80.8569C61.8111 82.6072 53.8449 67.7281 53.8449 67.7281C53.8449 67.7281 49.3468 76.4799 44.449 76.1143C39.7771 75.7656 36.3452 67.2407 36.3452 67.2407C36.3452 67.2407 14.8696 82.3913 7.38951 72.6927C-3.19899 58.9638 24.8335 45.1199 24.8335 45.1199C24.8335 45.1199 6.45046 38.7619 7.38952 30.642C8.87138 17.8286 36.3687 27.989 36.3687 27.989C36.3687 27.989 33.0474 11.5307 40.1323 7.88138C48.3854 3.6304 58.3866 23.133 58.3866 23.133C58.3866 23.133 87.1391 -3.64523 99.0422 7.88138C110.557 19.032 84.7368 44.2314 84.7368 44.2314C84.7368 44.2314 101.474 51.4069 99.0422 59.0993C96.464 67.2548 77.2463 60.4676 77.2463 60.4676C77.2463 60.4676 77.8975 79.0035 69.6245 80.8569Z"
            stroke="#A9AB93"
            strokeWidth="10"
          />
        </g>
      </svg>
    );
  };
  const FlowerImage2 = () => {
    return (
      <svg
        width="99"
        height="98"
        viewBox="0 0 99 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.8">
          <path
            d="M40.4826 5.69789C18.203 12.0161 39.4193 42.532 39.4193 42.532C39.4193 42.532 0.1034 36.3995 5.51068 60.1605C10.918 83.9214 44.0342 62.8113 44.0342 62.8113C44.0342 62.8113 37.223 98.0189 61.5212 92.3905C85.8193 86.7621 64.6623 54.6893 64.6623 54.6893C64.6623 54.6893 98.2362 58.6152 93.5517 38.0306C88.8673 17.4459 58.8054 38.0414 58.8054 38.0414C58.8054 38.0414 62.7621 -0.620365 40.4826 5.69789Z"
            stroke="#A99E95"
            strokeWidth="10"
          />
        </g>
      </svg>
    );
  };

  const [cardSize, setCardSize] = useState<
    { width: number; height: number } | undefined
  >();
  const [scaleRatio, setScaleRatio] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      if (
        isShown &&
        imageCardRef.current &&
        imageCardRef.current.offsetWidth > 0 &&
        !cardSize
      ) {
        setCardSize({
          width: imageCardRef.current.offsetWidth,
          height: imageCardRef.current.getBoundingClientRect().height,
        });
      }
    }, 100);
  }, [imageCardRef.current, isShown]);

  useEffect(() => {
    setTimeout(() => {
      if (isShown && containerRef.current) {
        setScaleRatio(Math.min(1, containerRef.current.offsetWidth / 400));
      }
    }, 300);
  }, [containerRef.current, isShown]);

  return (
    <Stack ref={containerRef} sx={{ width: "100%" }}>
      <Stack
        sx={{
          ...((cardSize?.height || 0) > 0 &&
            scaleRatio > 0 && {
              height: `${(cardSize?.height || 0) * scaleRatio}px`,
              overflow: "hidden",
            }),
          mb: "8px",
          opacity: scaleRatio > 0 ? 1 : 0,
        }}
      >
        <Stack
          ref={imageCardRef}
          sx={{
            position: "relative",
            width: "400px",
            p: "32px",
            backgroundColor: "secondary.deactivated",
            ...(scaleRatio > 0 && {
              transform: `scale(${scaleRatio})`,
              transformOrigin: "0 0",
            }),
          }}
          alignItems={"center"}
        >
          <Box
            sx={{
              position: "absolute",
              left: "16px",
              bottom: "32px",
              zIndex: 0,
            }}
          >
            <FlowerImage1 />
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: "13px",
              bottom: "12px",
              zIndex: 0,
            }}
          >
            <FlowerImage2 />
          </Box>
          <Stack
            sx={{
              position: "relative",
              px: "32px",
              py: "40px",
              width: "100%",
              backgroundColor: "white",
              // boxShadow: "8px 8px 0px rgba(0, 0, 0, 0.1)",
              filter: "drop-shadow(8px 8px 0px rgba(0, 0, 0, 0.1))",
              borderRadius: "4px",
              mb: "30px",
              zIndex: 1,
            }}
            spacing="24px"
          >
            <CustomTypography sx={{ fontSize: "24px", whiteSpace: "nowrap" }}>
              나의 이번 주 디스턴싱
            </CustomTypography>
            <Stack spacing="12px">
              <CustomTypography
                sx={{
                  fontSize: "14px",
                  color: "secondary.solid",
                }}
              >
                최근 1주일 활동
              </CustomTypography>
              <Stack direction="row" justifyContent={"space-between"}>
                {getLast7Days().map((date, index) => (
                  <Stack key={date} spacing={"4px"} alignItems={"center"}>
                    <CustomTypography
                      sx={{ height: "20px", fontSize: "14px", opacity: 0.6 }}
                    >
                      {format(new Date(date), "M/d")}
                    </CustomTypography>
                    <Status
                      isChecked={detailResult?.result.taskDoneDateList.includes(
                        date
                      )}
                      checkedBackgroundColor="secondary.solid"
                    />
                  </Stack>
                ))}
              </Stack>
            </Stack>
            <Stack spacing="12px">
              <CustomTypography
                sx={{
                  fontSize: "14px",
                  color: "secondary.solid",
                }}
              >
                꼭 기억할 내용
              </CustomTypography>

              {detailResult?.result &&
                detailResult?.result.recapList.map(
                  (each: string, index: number) => (
                    <Stack direction="row" spacing="8px">
                      <CustomTypography sx={{ fontSize: "20px", opacity: 0.6 }}>
                        {index + 1}
                      </CustomTypography>
                      <CustomTypography sx={{ fontSize: "20px" }}>
                        {each}
                      </CustomTypography>
                    </Stack>
                  )
                )}
            </Stack>
          </Stack>
        </Stack>
        <Box sx={{ width: "100px", zIndex: 1 }}>
          <img src={logo} alt="logo" />
        </Box>
      </Stack>
      <Button
        sx={{ width: "fit-content" }}
        onClick={() => {
          handleDownload();
        }}
      >
        {"사진첩에 저장하기"}
      </Button>
    </Stack>
  );
}
