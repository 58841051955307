import { useState } from "react";
import { useEffect } from "react";
import { TaskType } from "api";
import { useRecoilState, useRecoilValue } from "recoil";
import { taskListAtom } from "recoil/taskListAtom";
import { userAtom } from "recoil/userAtom";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import useIsInappWebview from "hooks/useIsInappWebview";
import { isMobile } from "react-device-detect";
import AppInstallModal from "./AppInstallModal";
import { isDev } from "config";
import { deepLinkData } from "data/deepLinkData";
import { menuKeyType } from "./NestedList";
import ActivityList from "./ActivityList";
import { sendMessage } from "utils/webview";

export const freeTaskList = [
  "0-0-A",
  "0-1", "0-2"
];
export const onboardingTaskId = "0-0-A";

export default function LearningActivityList({
  data,
  dataUpdatedAt,
}: {
  data: TaskType[];
  dataUpdatedAt?: number;
}) {
  const { isNotStarted } = useSubscriptionStatus();
  const isInappWebview = useIsInappWebview();

  const [taskListState, setTaskListState] = useRecoilState(taskListAtom);

  const userState = useRecoilValue(userAtom);

  const isCoach = userState?.accessToken && userState?.role !== "patient";

  const [listData, setListData] = useState<
    | {
        listTitle: string;
        taskList: TaskType[];
        menuKey: menuKeyType;
        initiallyOpen?: boolean;
      }[]
    | undefined
  >();
  const [showGoMarketQr, setShowGoMarkeyQr] = useState(false);

  const [isFirstTaskOpen, setIsFirstTaskOpen] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setTaskListState(
        !isCoach && isNotStarted
          ? data.map((each) => ({
              ...each,
              isLocked: freeTaskList.includes(each.taskId) ? false : true,
            }))
          : data
      );

      const onboardingActivity = data.find(
        (element) => element.taskId === onboardingTaskId
      );

      //온보딩 안마친 경우
      if (onboardingActivity && !onboardingActivity.isDone) {
        sendMessage(
          JSON.stringify({
            event: "chat_placeholder",
            params: {
              type: "before_onboarding",
            },
          })
        );
        if (!isCoach) {
          if (isInappWebview) {
            if (!isFirstTaskOpen) {
              // openOnboardingTask();
              setIsFirstTaskOpen(true);
            }
          } else {
            // setShowGoMarkeyQr(true);
          }
        }
      } else {
        sendMessage(
          JSON.stringify({
            event: "chat_placeholder",
            params: {
              type: "payment",
            },
          })
        );
      }
    }
  }, [data, dataUpdatedAt]);

  useEffect(() => {
    if (taskListState && taskListState.length > 0) {
      const todoList = taskListState.filter((element) => element.isOpen);
      const doneList = taskListState.filter((element) => !element.isOpen);
      setListData([
        ...(todoList.length > 0
          ? [
              {
                listTitle: "오늘의 활동",
                taskList: todoList,
                menuKey: "taskList_open" as menuKeyType,
                initiallyOpen: true,
              },
            ]
          : []),
        ...(doneList.length > 0
          ? [
              {
                listTitle: "마친 활동",
                taskList: doneList,
                menuKey: "taskList_close" as menuKeyType,
              },
            ]
          : []),
      ]);
    }
  }, [taskListState]);

  return (
    <>
      {showGoMarketQr && <AppInstallModal />}

      <ActivityList
        title={"배움활동지"}
        isInitialLoading={!data}
        listData={listData}
      />
    </>
  );
}
