import { Box, Button, Stack } from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import { taskApi } from "api";
import { useMutation } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { ProgramContentType } from "data/BlockComponent";
import PlainTextarea from "./textarea/PlainTextarea";
import { ElementType } from "data/CellComponent";
import * as _ from "lodash";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { theme } from "styles/theme";
import useOpenTask from "../../../../hooks/useOpenTask";
import useSubscriptionStatus from "../../../../hooks/useSubscriptionStatus";
import { sendMessage } from "../../../../utils/webview";
import useIsInappWebview from "../../../../hooks/useIsInappWebview";
import OptionalModal from "../../../common/OptionalModal";

export default function FeedbackCell({
  taskKey,
  savedFeedback,
  blockIndex,
  setData,
  lineIndex,
  cellIndex,
  editorKey,
}: {
  taskKey: string;
  savedFeedback?: string;
  blockIndex: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  editorKey?: string;
}) {
  const userState = useRecoilValue(userAtom);
  const isCoach = userState?.role !== "patient";
  const { isSubscribing } = useSubscriptionStatus();

  const [feedback, setFeedback] = useState<string>(savedFeedback || "");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openPayment = useOpenTask(`payment`);
  const isInappWebview = useIsInappWebview();

  const { t: t_common } = useTranslation("translation", {
    keyPrefix: "task.activity.common",
  });

  const submitFeedbackQueryFn = useCallback(
    () => taskApi.submitFeedback(taskKey, feedback),
    [taskKey, feedback]
  );

  const { mutate: submitFeedback } = useMutation(submitFeedbackQueryFn, {
    onSuccess: () => {
      setFeedback("");
      handleChange("", true);
    },
  });

  const handleChangeTemp = (value: string) => {
    setFeedback(value);
  };
  const handleChange = (value: string, autoOpenNext?: boolean) => {
    setData((data) => {
      if (data && data[blockIndex] && data[blockIndex].lines) {
        const originalCurrentContent = (data[blockIndex].lines[lineIndex][
          cellIndex
        ].content || {}) as ElementType;
        const originalValue = originalCurrentContent.value || "";

        if (!(originalValue === value)) {
          const data_temp = _.cloneDeep(data);
          const currentContent =
            data_temp[blockIndex].lines[lineIndex][cellIndex].content || {};

          data_temp[blockIndex].lines[lineIndex][cellIndex].content = {
            ...currentContent,
            value: value,
            htmlString: `<p>${value}</p>`,
          };
          if (autoOpenNext) {
            data_temp[blockIndex].autoOpenNext = true;
          }

          return data_temp;
        }
        return data;
      }
      return data;
    });
  };

  const debouncedSaveText = debounce(handleChange, 500);

  useEffect(() => {
    debouncedSaveText(feedback);

    // 컴포넌트가 언마운트될 때 디바운스 함수를 취소합니다.
    return () => debouncedSaveText.cancel();
  }, [feedback, debouncedSaveText]);

  return (
    <Stack direction={"row"} spacing={1}>
      <Box sx={{ flex: 1, minWidth: "0px" }}>
        {/* minWidth:0 빼면 모바일에서 넘침 */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            px: "10px",
            py: "12px",
            borderRadius: "6px",
            boxShadow: isFocused
              ? `inset 0 0 0 1.5px ${theme.vars.palette.primary.solid}`
              : `inset 0 0 0 1px ${theme.vars.palette.divider}`,
          }}
        >
          <PlainTextarea
            placeholder={t_common("소감 placeholder")}
            initialValue={savedFeedback}
            handleChange={handleChangeTemp}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            editorKey={editorKey}
          />
        </Box>
      </Box>

      <Button
        disabled={isCoach || feedback?.length === 0}
        sx={{ px: "10px" }}
        onClick={() => {
          if (!isSubscribing) {
            setIsModalOpen(true);
          } else {
            submitFeedback();
          }
        }}
      >
        <Box
          sx={{
            width: "24px",
            height: "24px", //크기 지정 안하면 resize 어쩌구 에러
          }}
        >
          <span className="material-symbols-rounded">send</span>
        </Box>
      </Button>
      <OptionalModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        title={"구독하고 메시지를 보내보세요"}
        subtitle={"코치 선생님과 함께 프로그램을 진행해보세요."}
        content={
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              // flexDirection: { xs: "row", sm: "center" },
              justifyContent: 'space-between'
            }}
          >

            <Button
              disabled={false}
              variant="soft"
              size="lg"
              color={"primary"}
              onClick={(e) => {
                e.stopPropagation();
                setFeedback("");
                setIsModalOpen(false);
              }}
              sx={{
                width: "100%",
              }}
              loading={false}
            >
              {"보내지 않기"}
            </Button>
            <Button
              disabled={false}
              variant="solid"
              size="lg"
              color={"primary"}
              onClick={(e) => {
                e.stopPropagation();
                if (isInappWebview) {
                  sendMessage(JSON.stringify({ event: "open_payment" })); // 앱이 웹뷰 열도록
                } else {
                  openPayment(); // 페이지 이동
                }
              }}
              sx={{
                width: "100%",
              }}
              loading={false}
            >
              {"더 알아보기"}
            </Button>
          </Box>
        }
      />
    </Stack>
  );
}