import { Stack, Textarea } from "@mui/joy";
import {
  conceptApi,
  CONCEPT_DOMAIN,
  CONCEPT_NOTE_DETAIL_ENDPOINT,
} from "api/conceptApi";
import Link from "components/common/Link";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function NewReference({
  conceptType,
  setIsAdded,
}: {
  conceptType: string;
  setIsAdded: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const patientState = useRecoilValue(patientAtom);

  const [newReference, setNewReference] = useState<string>("");

  const addReferenceQueryFn = useCallback(
    () =>
      conceptApi.addReference(
        `${patientState?.patientId}`,
        conceptType,
        newReference.trim()
      ),
    [patientState?.patientId, conceptType, newReference]
  );

  const { mutate: addReference } = useMutation(addReferenceQueryFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        [
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_DETAIL_ENDPOINT,
          patientState?.patientId,
          "개념화노트",
        ],
        { exact: true }
      );
      setNewReference("");
      setIsAdded(false);
    },
  });
  useEffect(() => {
    textareaRef.current?.focus();
  }, []);

  return (
    <Stack direction="row" sx={{ width: "100%" }}>
      <Textarea
        autoFocus
        value={newReference}
        onChange={(e) => {
          let newValue = e.target.value;
          setNewReference(newValue);
        }}
        onBlur={() => {
          if (newReference.trim().length === 0) {
            setIsAdded(false);
          }
        }}
        minRows={1}
        sx={{
          opacity: 0.7,
          flex: 1,
          fontSize: "12px",
          padding: 0,
          minHeight: "24px",
          backgroundColor: "transparent",
          borderWidth: 0,
          boxShadow: "none",
          "--joy-focus-thickness": "0px",
          "--variant-borderWidth": "0px",
          "--Textarea-gap": "0px",
        }}
      ></Textarea>
      {newReference?.trim().length > 0 && (
        <Link
          text={"저장"}
          textColor="primary.solid"
          opacity={1}
          onClick={addReference}
        />
      )}
    </Stack>
  );
}
