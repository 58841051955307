import { translation_selfCheck } from "../selfCheck";
export const translation_102_2_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "마지막 평가로부터 2주가 지났네요.",
      typography_1_0_0:
        "무기력으로부터 얼마만큼 벗어나고 있는지 간접적으로나마 함께 확인해봐요.",

      ...translation_selfCheck.ko.v1,
    },
  },
};
