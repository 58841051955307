import { Stack } from "@mui/joy";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  conceptApi,
  CONCEPT_DOMAIN,
  CONCEPT_NOTE_DETAIL_ENDPOINT,
} from "api/conceptApi";

import ButtonWithModal from "components/common/ButtonWithModal";
import * as _ from "lodash";
import { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import SelectThoughtRecord from "./SelectThoughtRecord";

export default function AddNewAutomaticThoughtButton({
  isForValue,
}: {
  isForValue: boolean;
}) {
  const queryClient = useQueryClient();
  const patientState = useRecoilValue(patientAtom);
  const [thoughtRecordKey, setThoughtRecordKey] = useState<string>();

  const addAutomaticThoughtQueryFn = useCallback(
    () =>
      conceptApi.addAutomaticThought(
        `${patientState?.patientId}`,
        `${thoughtRecordKey}`,
        isForValue
      ),
    [patientState?.patientId, thoughtRecordKey, isForValue]
  );

  const { mutate: addAutomaticThought } = useMutation(
    addAutomaticThoughtQueryFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_DETAIL_ENDPOINT,
        ]);
      },
    }
  );

  return (
    <ButtonWithModal
      buttonFullWidth
      buttonText={"새로운 자동적 사고 추가하기"}
      title={"어떤 기록에 대한 자동적 사고인가요?"}
      content={
        <Stack sx={{ width: "100%" }} alignItems={"center"} spacing={1}>
          <SelectThoughtRecord setThoughtRecordKey={setThoughtRecordKey} />
        </Stack>
      }
      actionText={"추가하기"}
      actionButtonDisabled={!thoughtRecordKey}
      action={addAutomaticThought}
      isWide
    />
  );
}
