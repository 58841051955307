import { useEffect, useState } from "react";
import { ProgramType } from "data/programData";
import * as _ from "lodash";
import { ProgramContentType } from "data/BlockComponent";
import { useRecoilValue } from "recoil";
import { taskListAtom } from "recoil/taskListAtom";
import { Content_2_2_T } from "data/task/v1/2-2-T";
import { setDataWithEditorKeyAndTranslationVersion } from "./useGetBlankTaskData";
import { deviceAtom } from "recoil/deviceAtom";
import useIsInappWebview from "./useIsInappWebview";
import { ThoughtRecordTemplate } from "data/task/v1/thoughtRecord";
import { MeditationType } from "data/task/v1/5-7-T2";

export default function useGetBlankThoughtRecordData({
  trk,
  isFirst,
  isFromMeditationRecord,
}: {
  trk?: string;
  isFirst?: boolean;
  isFromMeditationRecord?: boolean;
}) {
  const taskId = "2-2-T";
  const [data, setData] = useState<ProgramType>();
  const deviceState = useRecoilValue(deviceAtom);
  const taskListState = useRecoilValue(taskListAtom);
  const isInappWebview = useIsInappWebview();
  const isPushNotificationGranted =
    deviceState.pushNotificationStatus === "granted" || !isInappWebview;

  const isPositiveAvailable = taskListState.some(
    (element) => element.taskId === "7-1-A" && element.isDone
  );
  const isSituationUpgraded = taskListState.some(
    (element) => element.taskId === "9-4-A" && element.isDone
  );
  const isEmotionUpgraded = taskListState.some(
    (element) => element.taskId === "9-6-A" && element.isDone
  );
  const isPhysicalResponseUpgraded = taskListState.some(
    (element) => element.taskId === "9-8-A" && element.isDone
  );
  const isBehaviorUpgraded = taskListState.some(
    (element) => element.taskId === "9-10-A" && element.isDone
  );
  const isWillingnessAvailable = taskListState.some(
    (element) => element.taskId === "9-13-A" && element.isDone
  );

  function ifTaskDone(taskId: string) {
    return taskListState.find(
      (element) => element.taskId === taskId && element.isDone
    );
  }

  const meditationList = [
    ...(ifTaskDone("8-8-A") ? ["grounding"] : []),
    ...(ifTaskDone("8-8-A") ? ["relaxation"] : []),
    ...(ifTaskDone("8-9-A") ? ["breath"] : []),
    ...(ifTaskDone("9-13-A") ? ["willingness"] : []),
  ] as MeditationType[];

  const thoughtRecordTaskData: ProgramType = {
    taskId: taskId,
    content: Content_2_2_T(isPushNotificationGranted),
  };

  useEffect(() => {
    let rawData = _.cloneDeep(thoughtRecordTaskData);

    if (trk && rawData) {
      if (!isFirst) {
        rawData.content = _.cloneDeep(
          ThoughtRecordTemplate({
            isPositiveAvailable,
            isSituationUpgraded,
            isEmotionUpgraded,
            isPhysicalResponseUpgraded,
            isBehaviorUpgraded,
            isWillingnessAvailable,
            isFromMeditationRecord,
            meditationList,
            includeLastBlock: true,
          })
        );
      }

      (rawData.content as ProgramContentType[])[0].lines.push([
        {
          type: "temp",
          content: {
            id: `thoughtRecordKey`,
            value: trk,
          },
        },
      ]);

      setData(setDataWithEditorKeyAndTranslationVersion(rawData));
    }
  }, [trk]);

  return data;
}
