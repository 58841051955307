import { useRecoilValue } from "recoil";
import { permanentDataAtom } from "recoil/permanentDataAtom";

export default function useGetDeviceInfo() {
  const permanantDataState = useRecoilValue(permanentDataAtom);

  const homeIndicatorHeight = permanantDataState?.homeIndicatorHeight || 20;
  const appVersion = permanantDataState?.appVersion || "1.0.0";
  return {
    homeIndicatorHeight,
    appVersion,
  };
}
