import * as React from "react";
import MenuButton from "@mui/joy/MenuButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import { Typography } from "@mui/joy";

export default function CoachFilter({
  coachList,
  selectedCoach,
  setSelectedCoach,
}: {
  coachList: string[];
  selectedCoach?: string;
  setSelectedCoach: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const createHandleClose = (nickname: string) => () => {
    setSelectedCoach(nickname === "전체 보기" ? undefined : nickname);
  };

  return (
    <Dropdown sx={{ height: "100%" }}>
      <MenuButton sx={{ width: "100%", height: "100%", minHeight: 0 }}>
        {selectedCoach ? `${selectedCoach} 코치` : "전체 코치"}
      </MenuButton>
      <Menu>
        {[...coachList, "전체 보기"].map((nickname, index) => (
          <MenuItem
            key={nickname}
            selected={selectedCoach === nickname}
            onClick={createHandleClose(nickname)}
          >
            <Typography sx={{ mx: "auto" }}>{nickname}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
}
