// import { axiosInstance, type CustomResponseFormat } from "api/instance";

import { axiosInstance, CustomResponseFormat } from "./instance";

export const TOOL_DOMAIN = "tool";
export const TOOL_LOGOUT_ENDPOINT = "logout";

export const TOOL_THOUGHTRECORD_DOMAIN = "tool/thought-record";

export const TOOL_THOUGHTRECORD_HISTORY_ENDPOINT = "history/detail";
export const TOOL_THOUGHTRECORD_LIST_BY_DATE_ENDPOINT = "list-by-date";
export const TOOL_THOUGHTRECORD_SUBMIT_ENDPOINT = "submit";
export const TOOL_THOUGHTRECORD_DETAIL_ENDPOINT = "detail";
export const TOOL_THOUGHTRECORD_CONVERSATION_DETAIL_ENDPOINT =
  "conversation/detail";
export const TOOL_THOUGHTRECORD_CONVERSATION_CONTINUE_ENDPOINT =
  "conversation/continue";
export const TOOL_THOUGHTRECORD_CONVERSATION_MESSAGE_DETAIL_ENDPOINT =
  "conversation/message/detail";
export const TOOL_THOUGHTRECORD_CONVERSATION_COMPLETE_ENDPOINT =
  "conversation/complete";

export const TOOL_THOUGHTRECORD_TRAP_LIST_ENDPOINT = "thought-trap/list";

export const TOOL_THOUGHTRECORD_TRAP_CHECK_ENDPOINT = "thought-trap/check";

export type ABCStatusType =
  | "in_progress_isolation"
  | "in_progress_finding_thought_trap"
  | "completed";

export interface ThoughtRecordType {
  thoughtRecordId: string;
  date: string;
  situation: string;
  thoughtList: string[];
  emotionList: string[];
  behaviorList: string[];
  physicalReactionList: string[];
  // isCompleted: boolean;
  thoughtTrapList?: {
    thoughtIndex: number;
    thoughtTrap: string;
  }[];
  isProcessing: boolean;
  status: ABCStatusType;
}

export type ConversationGroupType = "isolation" | "finding_thought_trap";
export interface ConversationMessageType {
  messageId: string;
  role: string;
  message: string;
  isIgnored: boolean;
  group: ConversationGroupType;
  isLast: boolean;
}

export interface ConversationType {
  messageList: ConversationMessageType[];
}

export interface ThoughtRecordItemType {
  thoughtRecordId: string;
  title: string;
  // isCompleted: boolean;
  status: ABCStatusType;
}

export interface HistoryItemType {
  date: string;
  completedCount: number;
  totalCount: number;
}
export interface ThoughtTrapItemType {
  thoughtTrapId: string;
  thoughtRecordId: string;
  thoughtTrap: string;
  thought: string;
  date: string;
  isNew: boolean;
}

export interface ThoughtTrapChipType {
  countThoughtTrapFound: number;
  isNewThoughtTrapFound: boolean;
}

export const toolApi = {
  logout: () =>
    axiosInstance.get<CustomResponseFormat>(
      `${TOOL_DOMAIN}/${TOOL_LOGOUT_ENDPOINT}`
    ),
  getHistory: () =>
    axiosInstance.get<
      CustomResponseFormat<
        {
          countByDate: HistoryItemType[];
        } & ThoughtTrapChipType
      >
    >(`${TOOL_THOUGHTRECORD_DOMAIN}/${TOOL_THOUGHTRECORD_HISTORY_ENDPOINT}`),
  getListByDate: (date: string) =>
    axiosInstance.get<CustomResponseFormat<ThoughtRecordItemType[]>>(
      `${TOOL_THOUGHTRECORD_DOMAIN}/${TOOL_THOUGHTRECORD_LIST_BY_DATE_ENDPOINT}`,
      { params: { date: date } }
    ),
  submitThoughtRecord: (content: string, date: string) =>
    axiosInstance.post<CustomResponseFormat<{ thoughtRecordId: string }>>(
      `${TOOL_THOUGHTRECORD_DOMAIN}/${TOOL_THOUGHTRECORD_SUBMIT_ENDPOINT}`,
      { content: content, date: date }
    ),

  getABC: (tr_id: string) =>
    axiosInstance.get<CustomResponseFormat<ThoughtRecordType>>(
      `${TOOL_THOUGHTRECORD_DOMAIN}/${TOOL_THOUGHTRECORD_DETAIL_ENDPOINT}`,
      { params: { tr_id: tr_id } }
    ),

  getConversationDetail: (tr_id: string) =>
    axiosInstance.get<CustomResponseFormat<ConversationType>>(
      `${TOOL_THOUGHTRECORD_DOMAIN}/${TOOL_THOUGHTRECORD_CONVERSATION_DETAIL_ENDPOINT}`,
      { params: { tr_id: tr_id } }
    ),

  continueConversation: (tr_id: string, message: string) =>
    axiosInstance.post<CustomResponseFormat<{ nextMessageId: string }>>(
      `${TOOL_THOUGHTRECORD_DOMAIN}/${TOOL_THOUGHTRECORD_CONVERSATION_CONTINUE_ENDPOINT}`,
      { message: message },
      { params: { tr_id: tr_id } }
    ),

  getConversationMessageDetail: (tr_id: string, msg_id: string) =>
    axiosInstance.get<CustomResponseFormat<ConversationMessageType>>(
      `${TOOL_THOUGHTRECORD_DOMAIN}/${TOOL_THOUGHTRECORD_CONVERSATION_MESSAGE_DETAIL_ENDPOINT}`,
      { params: { tr_id: tr_id, msg_id: msg_id } }
    ),

  completeConversation: (tr_id: string, message: string) =>
    axiosInstance.post<CustomResponseFormat>(
      `${TOOL_THOUGHTRECORD_DOMAIN}/${TOOL_THOUGHTRECORD_CONVERSATION_COMPLETE_ENDPOINT}`,
      { message: message },
      { params: { tr_id: tr_id } }
    ),

  getThoughtTrapList: () =>
    axiosInstance.get<CustomResponseFormat<ThoughtTrapItemType[]>>(
      `${TOOL_THOUGHTRECORD_DOMAIN}/${TOOL_THOUGHTRECORD_TRAP_LIST_ENDPOINT}`
    ),
  checkThoughtTrap: (tt_id: string) =>
    axiosInstance.get<CustomResponseFormat>(
      `${TOOL_THOUGHTRECORD_DOMAIN}/${TOOL_THOUGHTRECORD_TRAP_CHECK_ENDPOINT}`,
      { params: { tt_id: tt_id } }
    ),
};
