import { useCallback, useEffect } from "react";
import useFetch from "hooks/useFetch";
import mixpanel from "mixpanel-browser";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { setAuthHeaders, taskApi } from "api";
import { userAtom } from "recoil/userAtom";
import { programDataAtom } from "recoil/programDataAtom";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { sendMessage } from "utils/webview";
import { taskSectionAtom } from "recoil/taskSectionAtom";

export default function useLogin({
  url,
  requestBody,
}: {
  url: string;
  requestBody: string;
}) {
  let navigate = useNavigate();

  const [loginResult, login] = useFetch({
    url: url,
    requestBody: requestBody,
  });

  const setPatientState = useSetRecoilState(patientAtom);
  const setUser = useSetRecoilState(userAtom);
  const programDataState = useRecoilValue(programDataAtom);
  const resetTaskSection = useResetRecoilState(taskSectionAtom);

  const saveDataQueryFn = useCallback(
    () =>
      taskApi.saveData(
        `0-0-A:${loginResult?.result.userId}`,
        programDataState && programDataState["0-0-A"]
      ),
    [loginResult, programDataState]
  );

  const { mutate: saveActivityData } = useMutation(saveDataQueryFn, {
    onSuccess: () => {
      setUser(loginResult?.result);
      setPatientState({
        patientId: `${loginResult?.result.userId}`,
        nickname: loginResult?.result.nickname,
      });
      navigate("/", { replace: true });
    },
  });

  useEffect(() => {
    if (loginResult) {
      resetTaskSection();
      const result = loginResult.result;

      mixpanel.identify(result.trackId);

      setAuthHeaders(result.accessToken);
      sendMessage(
        JSON.stringify({
          event: "value",
          params: {
            accessToken: result.accessToken,
          },
        })
      );

      //0-0-A 세션 저장
      if (
        programDataState &&
        programDataState["0-0-A"] &&
        result.isFirstLogin &&
        result.role === "patient" &&
        result.userId
      ) {
        saveActivityData();
      } else {
        setUser(result);
        if (result.role === "patient" && result.userId) {
          setPatientState({
            patientId: `${result.userId}`,
            nickname: result.nickname,
          });
        }
        navigate("/", { replace: true });
      }
    }
  }, [loginResult]);

  return login;
}
