export const translation_8_8_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "이제부터 앞서 찾은 자동적 사고를 다루기 시작할 건데요.",
      typography_1_0_0: "하지만 종종 이 과정을 힘들어하는 분들이 있어요.",
      typography_2_0_0: "나를 괴롭게 하는 생각을 직면하는 과정이기 때문이에요.",
      typography_3_0_0:
        "간혹 이 생각을 마주하는 게 힘들어 미루게 되기도 한답니다.",
      typography_4_0_0: `그래서 오늘은 부정적인 생각에 대한 감정과 신체 반응을 다스리는 도구를 소개하려고 해요.`,
      typography_5_0_0: "감정 다스리기",
      typography_6_0_0:
        "가끔 아주 큰 감정 때문에 생각을 돌아볼 여유가 없을 때도 있을 거예요.",
      typography_7_0_0:
        "이런 순간에는 '심호흡 그라운딩'이라는 방법을 사용할 거랍니다.",
      typography_8_0_0: "심호흡 그라운딩은 두 가지로 구성되어 있어요.",
      typography_9_0_0: "첫 번째는 심호흡이에요.",
      typography_10_0_0:
        "심호흡은 부교감신경을 활성화하여 이완된 상태를 유도하기 위해 실시하는데요.",
      typography_11_0_0:
        "중요한 것 중 하나는 가슴이 부풀도록 호흡하는 흉식 호흡이 아니라 배가 부풀도록 하는 복식 호흡을 해야 한다는 것이에요.",
      typography_12_0_0: "두 번째 중요한 건 들숨과 날숨의 비율인데요.",
      typography_13_0_0: "들숨과 날숨은 1:2의 비율로 진행이 되어야 해요.",
      typography_14_0_0:
        "연구에 따르면 1:2로 진행했을 때 부교감신경이 가장 잘 활성화되어 심박, 혈압, 호흡 속도가 가장 많이 낮아진다고 해요.",
      typography_15_0_0: "다음으로 중요한 건 호흡의 템포예요.",
      typography_16_0_0: "호흡은 느린 템포로 진행되어야 한답니다.",
      typography_17_0_0:
        "앞으로 우리는 들숨 4초, 날숨 8초로 1분에 5번 심호흡할 거예요.",
      typography_18_0_0: "마지막으로 중요한 건 호흡의 방법이에요.",
      typography_19_0_0:
        "우선 들숨은 코로 빠르게 마셔야 해요. 4초 동안 배가 적당히 부풀도록 들이마시는 거예요.",
      typography_20_0_0:
        "날숨은 입으로 천천히 내뱉어야 해요. 8초 동안 내뱉어야 하니 들숨보다는 천천히 뱉어야 한답니다.",
      typography_21_0_0:
        "입 모양을 조절해서 공기가 나가는 정도를 조절해보는 게 도움 될 거에요.",
      typography_22_0_0:
        "다만 들숨을 내뱉을 때에는 호흡을 남기지 말고 끝까지 다 내뱉어주세요.",
      typography_23_0_0:
        "정리하면, 심호흡은 복식호흡으로, 코로 4초간 들이마시고, 입으로 8초간 내쉬는 거예요.",
      typography_24_0_0:
        "이렇게 7-8분 정도 심호흡을 한 이후에는 그라운딩을 통해 마무리할 텐데요.",
      typography_25_0_0:
        "그라운딩은 마음챙김에 기반해 ‘지금 여기'로 생각의 초점을 가져오는 연습을 뜻해요.",
      typography_26_0_0:
        "우리가 땅에 단단히 발을 딛고 서 있는 것처럼(grounding), 현재의 순간에 집중하고 안정감을 찾는 것이랍니다.",
      typography_27_0_0: "그라운딩에서는 오감을 활용하게 되는데요.",
      typography_28_0_0: `그라운딩을 할 때에는 가이드에 따라서 지금 이 순간, 이 공간, 현재에만 집중하려고 노력해보세요.`,
      typography_29_0_0: "신체 반응 다스리기",
      typography_30_0_0:
        "때론 부정적인 감정이 강력한 신체 반응으로 나타날 때도 있답니다.",
      typography_31_0_0:
        "심장이 미칠 듯이 뛰거나 두통이 심하거나 근육통이 있거나 온몸이 납덩이처럼 무거운 것이지요.",
      typography_32_0_0:
        "이런 순간에는 '근육이완법'이라는 방법을 사용할 거예요.",
      typography_33_0_0: `이 방법은 몸의 긴장을 풀고 스트레스를 완화하는 데에 효과가 있다고 증명된 방법인데요.`,
      typography_34_0_0: `몸의 각 근육을 순서대로 긴장시켰다가 이완하는 방법을 통해 이루어져요.`,
      typography_35_0_0: `먼저 특정 근육을 몇 초 동안 강하게 수축시킨 다음, 천천히 이완하면서 그 차이를 느껴보는 것이죠.`,
      typography_36_0_0: `근육이완법을 실시할 때에도 지금 이 순간에 집중하려고 노력해보세요.`,
      typography_37_0_0: `복잡한 생각은 잠시 미뤄두고 몸에서 어떤 감각이 느껴지는지, 수축된다는 건, 이완된다는 건 어떤 느낌인지 세심하게 관찰하는 거예요.`,
      typography_38_0_0: `힘들 때 사용하기`,
      typography_39_0_0: `자, 이제부터 <명상훈련지>에서 '심호흡 그라운딩'과 '근육이완법'을 사용할 수 있을 거예요.`,
      typography_40_0_0: `감정이 힘들 땐 심호흡 그라운딩을, 신체 반응이 힘들 땐 근육이완법을 활용해보세요.`,
      typography_41_0_0: `일상 속에서든, 디스턴싱을 연습할 때든, 언제든 내가 원할 때 사용하면 된답니다.`,
    },
  },
};
