export const translation_9_3_A = {
  en: {},
  ko: {
    v1: {
      typography_0: `생각은 자동적이에요. 마치 팝콘처럼 튀어 오르죠.`,
      typography_1: `우리는 그중 한 가지 팝콘을 자동적 사고로 선정하여 살펴봤답니다.`,
      typography_2: `하지만 우리는 그 자동적 사고를 더 나은 생각으로 바꾸려고 노력하진 않았답니다.`,
      typography_3: `부정적인 생각을 없애고, 통제하고, 다른 생각으로 대체하려고 애쓰지 않았죠.`,
      typography_4: `그저 “이런 생각함정이 있구나" 정도로 살펴봤을 뿐이에요.`,
      typography_5: `그 이유는 마음속에 떠오르는 부정적인 생각을 어떻게든 해결하려는 시도가 효과적이지 않기 때문이에요.`,
      typography_6: `지난 활동에서 알아본 것처럼요.`,
      typography_7: `마음의 문제 해결적인 본능은 외부 세상에서는 효과적이지만, 우리 내면을 향할 때에는 효과적이지 않답니다.`,
      typography_8: `왜 그런 걸까요? 크게 두 가지 이유가 있답니다.`,
      typography_9: `1. 오히려 강해진다`,
      typography_10: `한 가지 재밌는 실험을 해볼게요.`,
      typography_11: `잠시 아래 사진을 감상해볼게요.`,
      typography_12: `핑크색 코끼리네요.`,
      typography_13: `자, 이제 잠깐 30초 동안 눈을 감고 공상 시간을 가져볼 텐데요.`,
      typography_14: `절대로 핑크색 코끼리를 떠올리지 마시길 바랍니다.`,
      typography_15: `한번 해볼까요? 30초 동안. 핑크색 코끼리를 떠올리지 않는 겁니다. 절대로요.`,
      typography_16: `자, 어땠나요? 아마 쉽지 않았을 거예요.`,
      typography_17: `우리 뇌는 떠올려야 하는 것과 떠올리지 않아야 하는 것을 구분하지 못합니다.`,
      typography_18: `따라서 어떤 생각을 없애려고 할수록 오히려 그 생각은 더욱 상기되기 마련이에요.`,
      typography_19: `결국 그 생각은 더 큰 힘을 얻게 되고, 우리는 또 그 생각을 없애려고 애쓰게 된답니다.`,
      typography_20: `악순환이죠. 결국 부정적인 생각과 감정을 교정하고, 제거하고, 외면하려는 시도는 상황을 악화시킬 뿐이에요.`,
      typography_21: `마음에 떠오르는 것들을 경험하지 않고 회피하려는 시도는 오히려 문제를 악화시킬 뿐이죠.`,
      typography_22: `회피할수록 강해진다는 것을 기억하세요.`,
      typography_23: `2. 시간이 흐르며 누적된다`,
      typography_24: `부정적인 내적 경험을 회피하려는 시도는 누적되어 더 큰 문제를 불러일으켜요.`,
      typography_25: `앞서 말한 것처럼 경험을 회피하고 강해지고 다시 회피하는 것을 반복하다가 삶의 의제 자체가 “우울 줄이기”, “불안 줄이기”, “나쁜 생각 줄이기”로 바뀐다는 것이죠.`,
      typography_26: `10년 넘게 우울증을 앓았던 사람들은 우울과의 심리적인 전쟁을 쉽사리 놓치 못해요.`,
      typography_27: `그게 스스로의 인생을 대변하기 때문이에요.`,
      typography_28: `그것은 나의 자아와도 같아요.`,
      typography_29: `"나는 지난 10년간 우울증과 싸워왔어요. 그 과정에서 많은 것들을 놓쳐버렸죠."`,
      typography_30: `"소중한 인연들에 집중하지 못했어요. 가고 싶었던 여행도 제대로 간 적이 없었죠."`,
      typography_31: `부정적인 생각과 감정을 통제하고 피하려는 시도는 나에게 새로운 괴로움을 안겨준 것을 넘어, 삶을 허비하게 만들며 또 다른 괴로움을 불러일으켜요.`,
      typography_32: `“벌써 시간이 이렇게 지났네... 그동안 난 뭘 한 걸까?”`,
      typography_33: `그다음은 어떻게 흘러가냐고요?`,
      typography_34: `“진짜 큰일이네. 이제 진짜 마음을 고쳐먹자. 새해부터는 부정적으로 생각하지 말고, 나약한 마음 품지 말고, 진짜 새로운 사람이 되자. 생각을 고쳐먹자. 나쁜 생각을 없애자.”`,
      typography_35: `이런, 어디서 본 것 같은 이야기이지 않나요?`,
      typography_36: `강해진 고통은 누적되어 더 큰 고통을 만들게 돼요.`,
      typography_37: `텅 빈 마음은 목표가 아니다`,
      typography_38: `마음속에 떠오르는 경험, 설령 그것이 부정적인 생각과 감정일지라도, 그 경험을 회피하는 건 효과적이지 않아요.`,
      typography_39: `그럼에도 불구하고 우리는 늘 어떤 이상을 좇곤 해요.`,
      typography_40: `맑고 깨끗한 텅 빈 마음,`,
      typography_41: `긍정적인 것들로만 가득 찬 마음,`,
      typography_42: `올바르고 건강한 생각으로만 가득 찬 마음 같은 것들 말이에요.`,
      typography_43: `하지만 그런 것들은 사실 허상과도 같답니다.`,
      typography_44: `삶에서 모든 고통들이 사라질 순 없어요.`,
      typography_45: `마음속의 부정적인 생각을 말끔히 없앨 순 없답니다.`,
      typography_46: `부정적인 생각을 아주 긍정적인 생각으로 교정해 버릴 수도 없어요.`,
      typography_47: `마음속에 그런 생각이 떠오르는 건 피할 수 없는 일이에요.`,
      typography_48: `애초에 마음속 팝콘 기계는 우리 말을 듣지 않는걸요.`,
      typography_49: `삶을 살아가다 보면 다양한 역경을 마주할 수밖에 없어요.`,
      typography_50: `그렇다면 때론 원치 않는 팝콘도 튀어나오기 마련입니다.`,
      typography_51: `고통은 피할 수 없어요.`,
      typography_52: `하지만 일평생 그 팝콘을 제거하려고 애쓰지 않을 순 있어요.`,
      typography_53: `마음의 문제 해결적인 본능에 순응하지 않을 순 있답니다.`,
      typography_54: `애초에 되지 않는 일을 하려고 노력하다가, 오히려 부정적인 감정만 더 되새김질하고,`,
      typography_55: `또다시 커진 감정을 없애려고 노력하며 악순환의 굴레에 빠지지 않을 순 있어요.`,
      typography_56: `그저 생각을 하나의 심리적 사건으로 바라보고,`,
      typography_57: `그것이 마음속에 떠올랐다는 사실을 기꺼이 인정하고,`,
      typography_58: `거리를 둔 상태로, 다시 내가 원하는 선택을 해나갈 수 있답니다.`,
      typography_59: `고통은 불가피하지만 괴로움은 선택이에요.`,
      typography_60: `기꺼이 경험하기`,
      typography_61: `디스턴싱이 제시하는 대안은 ‘기꺼이 경험하기'랍니다.`,
      typography_62: `마음속에 떠오르는 생각과 감정들을 하나의 심리적 사건으로 바라보고, 기꺼이 경험하는 것이죠.`,
      typography_63: `없애려고, 통제하려고, 다른 것으로 대체하려고 하지 않고, 인정하고 오히려 기꺼이 경험해보는 거예요.`,
      typography_64: `기꺼이 경험하기를 설명하는 좋은 비유가 있어요.`,
      typography_65: `당신이 늪에 빠졌다고 생각해보세요.`,
      typography_66: `당신은 이내 당황하기 시작해요.`,
      typography_67: `“어떡하지?” 발이 서서히 늪에 빨려 들어가면 패닉이 찾아와요`,
      typography_68: `당신은 늪에서 탈출하기 위해 허우적거리죠.`,
      typography_69: `하지만 한쪽 다리를 들면 당신의 몸무게는 다른 한쪽 다리에 온전히 가해져요.`,
      typography_70: `그 결과 당신은 늪에 더 빨려 들어가요. 더 큰 패닉이 찾아오죠.`,
      typography_71: `어떻게든 늪을 벗어나야 해요. 이러다간 큰일이 날 것 같아요.`,
      typography_72: `당신은 어떻게든 몸부림쳐봐요.`,
      typography_73: `하지만 그럴 때마다 한쪽으로 가중된 몸무게는 우리를 점점 더 늪에 빠져들게 만드네요.`,
      typography_74: `어디서 많이 본 모습이지 않나요?`,
      typography_75: `우리는 찾아온 불안감을 다스리려고 애쓰다가 공황을 마주해요.`,
      typography_76: `머릿속을 침투하는 생각들을 억지로 떨쳐내려고 할수록 그 강도와 빈도는 더 강해지죠.`,
      typography_77: `초라한 자아를 부정하기 위해 노력할수록 우울감은 깊어져만 가요.`,
      typography_78: `사랑을 갈구하려고 할수록 얄밉게도 상대는 멀어져만 갑니다.`,
      typography_79: `삶이 왜 이 지경이 되었는지, 나는 왜 이렇게 우울한 건지, 그 이유를 찾기 위해 생각을 곱씹어보지만 나의 삶은 점점 더 우울하게 흘러가죠.`,
      typography_80: `자, 늪에 빠지고 있네요. 어떻게 해야 할까요?`,
      typography_81: `방법은 하나뿐이에요. 늪을 향해 온몸을 던지는 것이죠.`,
      typography_82: `“미친 거 아니야?” 오, 아니요. 정말 그 방법밖에 없답니다.`,
      typography_83: `늪에 빠졌다는 사실을 인정하고 최대한 온몸을 늪에 넓게 접촉시켜 몸무게를 분산시켜야 해요.`,
      typography_84: `물론 늪은 축축하고 끈적하죠.`,
      typography_85: `하지만 그 외 다른 방법은 없어요.`,
      typography_86: `만약 우리가 마음속에 떠오르는 부정적인 생각과 감정에 현명하게 대처할 수 있는 유일한 방법이 이와 같다면 어떨까요?`,
      typography_87: `생각을 하나의 심리적 사건으로 바라보고 부정적인 생각조차 억지로 마주하지 않고 기꺼이 경험할 수 있다면 어떨까요?`,
      typography_88: `생각과의 관계를 새로 맺기 위해서는 기꺼이 경험해야 해요.`,
      typography_89: `억지로 마주하는 것과의 차이`,
      typography_90: `우리는 ‘억지로 마주하기’보다는 ‘기꺼이 경험’해야 해요.`,
      typography_91: `무슨 차이가 있는 걸까요?`,
      typography_92: `‘억지로 마주하기'는 본질적으로 마음속에 특정한 생각이 떠오른다는 것을 부정하는 것이에요.`,
      typography_93: `‘기꺼이 경험하기'는 마음속에는 어떤 생각이든 자동적으로 떠오를 수 있다는 사실을 받아들이는 것이에요.`,
      typography_94: `‘억지로 마주하기'는 싫지만 어떻게든 악으로 깡으로 버티는 것이에요.`,
      typography_95: `‘기꺼이 경험하기'는 그것조차 하나의 심리적 사건일 뿐이며 애써 회피하려고 할 필요가 없다는 것을 이해하고 받아들이는 것이에요.`,
      typography_96: `‘억지로 마주하기'는 마음속에 떠오른 어떤 생각에 괴로워하는 것이에요.`,
      typography_97: `‘기꺼이 경험하기'는 마음속에 떠오른 고통이 나의 반응 없이는 실체를 가질 수 없다는 사실을 이해하고 여유를 가지는 것이에요.`,
      typography_98: `우리가 부정적인 생각을 대할 땐 ‘기꺼이 경험하기'의 자세로 마주할 필요가 있답니다.`,
      typography_99: `무엇도 기대하지 말아요`,
      typography_100: `기꺼이 경험하기는 어떤 목적이나 결과를 기대하는 것이 아니에요.`,
      typography_101: `“아, 불안해. 아, 맞아. 디스턴싱에서 기꺼이 경험하라고 했지. 기꺼이 경험하면 불안이 사라질 거야.”`,
      typography_102: `이것은 기꺼이 경험하기로 포장된 회피예요.`,
      typography_103: `또한 기꺼이 경험하기는 어떤 상태에 도달하려고 애쓰는 것도 아니에요.`,
      typography_104: `기꺼이 경험하기는 마음속의 모든 근심과 걱정이 사라진 상태를 약속하지 않아요.`,
      typography_105: `기꺼이 경험하기는 지금 이 순간에 머물며,`,
      typography_106: `마음속에서 벌어지는 그 모든 일들, 생각, 감정, 감각, 충동을`,
      typography_107: `그저 하나의 심리적 사건으로 바라보며 관찰하는 것이에요.`,
      typography_108: `그것이 심리적 사건이라면 거부할 필요도 없답니다.`,
      typography_109: `우리는 더위를 피하곤 하지만 그 온도를 우리 몸속에서 없애버리려고 애쓰지 않아요.`,
      typography_110: `우리는 소음을 피하곤 하지만 들려오는 소리 자체를 머릿속에서 없애버리려고 애쓰지도 않죠.`,
      typography_111: `우리는 끔찍한 장면을 보기 싫지만 눈을 통해 들어오는 심상 자체를 제거하려고 애쓰지도 않는답니다.`,
      typography_112: `우리는 그것들이 모두 우리의 뇌를 스쳤다 지나가는 하나의 심리적 경험임을 잘 알고 있어요.`,
      typography_113: `생각 또한 마찬가지예요.`,
      typography_114: `거부하려고 애쓰지 않고 기꺼이 경험하며 관찰해보면, 수년간 나를 괴롭혔던 생각조차 실은 빈 깡통이었다는 것을 알게 됩니다.`,
      typography_115: `자, 이제 <생각기록지>에 ‘기꺼이 경험하기'를 적용해 볼 거예요.`,
      typography_116: `하나씩 알려드릴 테니 호기심을 가지고 따라오시면 된답니다.`,
    },
  },
};
