import { useEffect, useState } from "react";
import { Divider, List, Stack, Typography } from "@mui/joy";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import AddNewSegmentationNoteButton from "./AddNewSegmentationNoteButton";
import useIsInappWebview from "hooks/useIsInappWebview";
import { SegmentationNoteType } from "api/segmentationNoteApi";
import SegmentationNoteListItem from "./SegmentationNoteListItem";

export default function SegmentationNoteList({
  segmentationNoteList,
  currentSegmentationNote,
  setCurrentSegmentationNote,
}: {
  segmentationNoteList?: SegmentationNoteType[];
  currentSegmentationNote?: SegmentationNoteType;
  setCurrentSegmentationNote: React.Dispatch<
    React.SetStateAction<SegmentationNoteType | undefined>
  >;
}) {
  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";
  // const { isTaskContainerSmall } = useRecoilValue(layoutAtom);
  const isInAppWebview = useIsInappWebview();
  const isTaskContainerSmall = isInAppWebview;
  const [listData, setListData] = useState<SegmentationNoteType[] | undefined>(
    segmentationNoteList
  );

  const [currentListData, setCurrentListData] = useState<
    SegmentationNoteType[] | undefined
  >(segmentationNoteList);

  useEffect(() => {
    setListData(segmentationNoteList);
  }, [segmentationNoteList]);

  useEffect(() => {
    setCurrentListData(listData);
  }, [listData]);

  return (
    <Stack
      direction={isTaskContainerSmall ? "row-reverse" : "column"}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        flexShrink: 0,
        width: isTaskContainerSmall ? "100%" : "300px",
        height: isTaskContainerSmall ? "fit-content" : "100%",
        // overflow: "scroll",
        transition: "transform 0.4s, width 0.4s",
        scrollPaddingBlockStart: "20px",
        ...(isTaskContainerSmall ? { pb: "20px" } : { pl: "20px" }),
      }}
      className="scrollbar-hide"
    >
      <Stack
        direction={isTaskContainerSmall ? "row" : "column"}
        justifyContent={"center"}
        alignItems={isTaskContainerSmall ? "center" : "flex-start"}
        spacing={isTaskContainerSmall ? "8px" : "16px"}
        divider={
          <Divider
            orientation={isTaskContainerSmall ? "vertical" : "horizontal"}
          />
        }
        sx={{
          width: isTaskContainerSmall ? "auto" : "100%",
          ...(isTaskContainerSmall
            ? {
                px: "20px",
                height: "100%",
              }
            : {
                flexShrink: 0,
                zIndex: 10,
                position: "sticky",
                pt: "20px",
                top: 0,
                pb: "16px",
              }),
        }}
      >
        <Stack
          sx={{ flexShrink: 0, width: isTaskContainerSmall ? "50px" : "100%" }}
        >
          <AddNewSegmentationNoteButton
            disabled={
              isCoach ||
              ((listData || []).length === 1 &&
                !listData?.every((element) => element.lastDoneAt))
            }
          />
        </Stack>
      </Stack>

      <List
        orientation={isTaskContainerSmall ? "horizontal" : "vertical"}
        sx={{
          overflow: "scroll",
          width: "100%",
          "--ListItem-paddingY": 0,
          "--ListItem-paddingX": 0,
        }}
      >
        {currentListData && currentListData.length > 0 ? (
          currentListData.map((segmentationNote, index) => {
            return (
              <SegmentationNoteListItem
                key={segmentationNote.segmentationNoteKey}
                width={isTaskContainerSmall ? "50px" : "100%"}
                direction={isTaskContainerSmall ? "row" : "column"}
                isFirstRecordOfMonth={
                  index === 0 ||
                  (index - 1 >= 0 &&
                    dayjs(currentListData[index - 1].date).get("month") !==
                      dayjs(currentListData[index].date).get("month"))
                }
                segmentationNote={segmentationNote}
                isSelected={
                  currentSegmentationNote?.segmentationNoteKey ===
                  segmentationNote.segmentationNoteKey
                }
                setCurrentSegmentation={setCurrentSegmentationNote}
                shouldBeDone={!isCoach && segmentationNote.shouldBeDone}
              />
            );
          })
        ) : (
          <>
            <Typography
              sx={{
                width: "100%",
                fontSize: "14px",
                opacity: 0.6,
                textAlign: "center",
              }}
            >
              {"아직 분리한 내역이 없어요."}
            </Typography>
          </>
        )}
        {/* <Box
          sx={{ height: "1500px", width: "100px", backgroundColor: "#000000" }}
        /> */}
      </List>
    </Stack>
  );
}
