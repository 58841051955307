import { translation_4_5_A } from "./4-5-A";
export const translation_4_5_6_A = {
  en: {
    v1: {
      ...translation_4_5_A.en.v1.start,
      typography_0_0_0: "Stepping back from Rumination",
      typography_1_0_0: "Stepping back from Rumination involves three steps:",
      typography_1_3_0:
        "Realize when you're caught in the cycle of rumination.",
      typography_1_5_0:
        "Reflect on whether this rumination is actually solving any problems or just influencing your emotions.",
      typography_1_7_0:
        "Create some space from the rumination and choose a response that truly reflects your values.",
      typography_2_0_0:
        "Let's tackle this one step at a time. First, let’s revisit the automatic thoughts.",
      typography_3_0_0:
        "Alright, let's figure out if we're stuck in a cycle of overthinking. Think about these questions:",
      typography_4_0_0:
        "First up, am I diving into past or future scenarios with a clear goal about a particular issue? Or am I just wandering aimlessly through fuzzy memories or future possibilities without any real direction?",
      typography_5_0_0:
        "Have I been looping these thoughts for over three minutes?",
      typography_6_0_0:
        "If you’re wandering in your thoughts without a clear goal and repeating them for more than three minutes, chances are, that’s classic overthinking.",
      typography_7_0_0:
        "Once we spot this pattern, it’s our cue to step back. Now, consider these:",
      typography_8_0_0:
        "Has rehashing these thoughts brought any useful solutions to my real-world issues?",
      typography_9_0_0:
        "Did I feel even a tiny bit better after pondering over these thoughts?",
      typography_10_0_0:
        "If you’re shaking your head to these questions, then we’re likely trapped in the unhelpful cycle of overthinking.",
      typography_11_0_0:
        "We’ve talked before about why this isn’t a great strategy. There's no need to dwell on why that’s important – that could lead us back into the overthinking loop.",
      typography_12_0_0: "Let’s break out of it.",
      typography_13_0_0:
        "Firstly, let's bring our focus back to the present moment. Close your eyes for a sec and take deep, purposeful breaths for 30 seconds.",
      typography_14_0_0:
        "Remember, the key is to stay in the now. Concentrate entirely on how it feels as the air fills your lungs and your chest rises and falls.",
      typography_15_0_0: "Ready to give it a try for 30 seconds?",
      typography_16_0_0:
        "Awesome. Stay in the present, and without getting caught up in those circling thoughts, think about this:",
      typographyFromData_17_0_0: `If I decide not to engage with the Rumination "$A$", how would I rather use that energy and time?`,
      typographyFromData_18_0_0: `Now, try rewriting "$A$" from a perspective of distancing. Here's an example to guide you.`,
      typography_18_4_0: "If only I hadn't done that...",
      typography_18_6_0: "I realize I'm dwelling on what's already happened.",
      typography_18_10_0: "What if this happens? How can I stop it?",
      typography_18_12_0:
        "I see I'm worrying about things that might never happen.",
      typography_19_0_0:
        "Read the rewritten Rumination again. Close your eyes for about 10 seconds, take deep breaths, and imagine the distance between you and your thoughts. It really helps to visualize it!",
      ...translation_4_5_A.en.v1.end,
    },
  },
  ko: {
    v1: {
      ...translation_4_5_A.ko.v1.start,
      typography_0_0_0: "반추로부터 거리두기",
      typography_1_0_0: "반추로부터 거리두기는 다음과 같은 세 단계로 진행돼요.",
      typography_1_3_0: "마음속에 반추가 떠오른다는 것을 알아차린다.",
      typography_1_5_0:
        "반추가 실제 문제를 해결해주고 있는지, 혹은 나의 감정에 도움을 주고 있는지 파악한다.",
      typography_1_7_0:
        "반추로부터 거리를 두고 다시 나의 가치에 맞는 반응을 선택한다.",
      typography_2_0_0:
        "하나씩 해볼게요. 자, 우선 아래 자동적 사고를 다시 읽어보세요.",
      typography_3_0_0:
        "먼저 우리가 반추 생감함정에 빠졌는지 명확히 확인해볼게요. 아래 질문에 답해보세요.",
      typography_4_0_0:
        "우선 나는 어떠한 문제에 대해 명확한 목표를 가지고 과거나 미래를 탐색하고 있나요? 아니면 뚜렷한 목표 없이 어렴풋한 과거나 미래를 헤매고 있나요?",
      typography_5_0_0: "나는 그러한 생각을 3분 이상 되풀이하고 있나요?",
      typography_6_0_0:
        "만약 목표 없이 어렴풋한 생각과 감정 사이를 헤매며 과거나 미래를 탐색하고 있고, 그러한 생각을 3분 이상 되풀이하고 있다면 반추라고 이야기할 수 있어요.",
      typography_7_0_0:
        "반추를 발견했으면 이제 그것과 거리를 둘 차례예요. 아래 질문에 답해보세요.",
      typography_8_0_0:
        "그렇게 생각을 반복했더니 현실의 문제를 해결할 수 있는 좋은 방법이 떠올랐나요?",
      typography_9_0_0:
        "그렇게 생각을 곱씹었더니 기분이 '조금이라도' 더 좋아졌나요?",
      typography_10_0_0:
        "위 두 질문 중 하나라도 '그렇다'고 답하지 못하면, 우리는 분명 도움이 되지 않는 생각 중 하나인 반추 생각함정에 빠진 거예요.",
      typography_11_0_0:
        "반추가 왜 도움이 되지 않는지는 앞서 자세히 설명했어요. 이제 지금부터는 그것이 왜 중요한지를 따져 물을 필요가 없어요. 그러다가 또다시 반추에 빠질 수 있거든요.",
      typography_12_0_0: "자, 그러면 반추에서 빠져나올게요.",
      typography_13_0_0:
        "우선 생각을 지금 이 순간으로 가져올게요. 잠시 눈을 감고 30초만 크게 심호흡을 해 볼 텐데요.",
      typography_14_0_0:
        "이때 중요한 건 생각을 과거나 미래에 두지 말고, 숨을 들이쉬고 내쉴 때 가슴이 코로 공기가 이동하며 가슴이 부푸는 '느낌'에만 온전히 집중해보세요.",
      typography_15_0_0: "자, 30초 동안 진행해볼까요?",
      typography_16_0_0:
        "좋아요. 마음속에 떠오른 반추에 반응하지 않고 현재에 머무르는 데에 집중하며 아래 질문에 답해보세요.",
      typographyFromData_17_0_0:
        '만약 내가 "$A$"(이)라는 반추에 반응하지 않기로 선택할 수 있다면, 나는 그 에너지와 시간을 어느 곳에 사용하고 싶나요?',
      typographyFromData_18_0_0:
        '마음속에 떠오른 "$A$"을(를) 거리두기를 통해 바라본 표현으로 다시 바꿔 적어보세요. 아래와 같은 예시를 참고해보세요.',
      typography_18_4_0: "그때 내가 그러지 않았더라면...",
      typography_18_6_0: "나는 과거의 기억을 더듬으며 반추하고 있다.",
      typography_18_10_0:
        "근데 이런 일이 벌어지면 어떻게 해야 하지? 그런 일이 벌어지지 않으려면 뭘 해야 하지...?",
      typography_18_12_0: "나는 미래의 걱정을 더듬으며 반추하고 있다.",
      typography_19_0_0:
        "거리두기를 통해 바꿔 적어본 반추를 다시 읽어보세요. 잠시 눈을 감고 약 10초 동안 심호흡을 하며 생각과 나 사이의 거리감을 상상해 보면 더 좋아요.",
      ...translation_4_5_A.ko.v1.end,
    },
  },
};
