import { useEffect, useState } from "react";
import { MeditationItemType } from "api/homeApi";
import { menuKeyType } from "./NestedList";
import ActivityList from "./ActivityList";

export default function MeditationList({
  data,
}: {
  data: MeditationItemType[];
}) {
  const [listData, setListData] = useState<
    | {
        listTitle: string;
        meditationList: MeditationItemType[];
        menuKey: menuKeyType;
        initiallyOpen?: boolean;
      }[]
    | undefined
  >();

  useEffect(() => {
    if (data) {
      const openList = data.filter((element) => element.isOpen);
      const closeList = data.filter((element) => !element.isOpen);
      setListData([
        ...(openList.length > 0
          ? [
              {
                listTitle: "답변할 훈련",
                meditationList: openList,
                menuKey: "meditationList_open" as menuKeyType,
                initiallyOpen: true,
              },
            ]
          : []),
        ...(closeList.length > 0
          ? [
              {
                listTitle: "최근 훈련",
                meditationList: closeList,
                menuKey: "meditationList_close" as menuKeyType,
              },
            ]
          : []),
      ]);
    }
  }, [data]);

  return (
    <ActivityList
      title={"명상훈련지"}
      isInitialLoading={!data}
      listData={listData}
    />
  );
}
