import { useEffect, useState } from "react";
import { ProgramType } from "data/programData";
import * as _ from "lodash";
import useFetch from "./useFetch";
import { setDataWithEditorKeyAndTranslationVersion } from "./useGetBlankTaskData";
import useGetBlankMeditationRecordData from "./useGetBlankMeditationRecordData";
import { getLastShownIndex } from "logic/logics";

export default function useGetMeditationRecordData({
  mrk,
  isFirst,
}: {
  mrk?: string;
  isFirst?: boolean;
}) {
  const [data, setData] = useState<ProgramType>();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const blankData = useGetBlankMeditationRecordData({
    mrk: mrk,
    isFirst: isFirst,
  });

  const [fetchedData, getData] = useFetch({
    url: "/meditation-record/data/load",
    params: { mrk: mrk },
  });

  useEffect(() => {
    if (mrk && isFirst !== undefined) {
      getData();
      setIsFetching(true);
    }
  }, [mrk]);

  useEffect(() => {
    if (fetchedData) {
      const totalData = (fetchedData?.result || {}).data;
      if (
        totalData &&
        totalData.content.length > 0 &&
        !(getLastShownIndex(totalData.content) === 0)
      ) {
        console.log("FETCH: ", totalData);
        setData(setDataWithEditorKeyAndTranslationVersion(totalData));
      } else {
        console.log("RAW: ", blankData);
        setData(blankData);
      }
    }
  }, [fetchedData]);

  useEffect(() => {
    if (data) {
      setIsFetching(false);
    }
  }, [data]);

  return { data, refetch: getData, isFetching };
}
