export const translation_4_3_1_A = {
  en: {
    v1: {
      typography_0_0_0: "Do you remember how distancing works?",
      typography_1_0_0:
        "It's based on realizing that our thoughts are just thoughts, not who we are.",
      typography_2_0_0: "First, we notice thoughts popping up in our minds.",
      typography_3_0_0:
        "Second, we create some space from these thoughts, and don't react too much.",
      typography_4_0_0:
        "Third, we fill this space with choices that reflect our values.",
      typography_5_0_0: `Let's look a bit more at the idea, "thoughts are just thoughts."`,
      typography_6_0_0: "Thoughts are just thoughts",
      typography_7_0_0: "They're not the absolute truth or facts set in stone.",
      typography_8_0_0:
        "But it's tough to accept this because we're usually so close to our thoughts.",
      typography_9_0_0: "Let's try something fun.",
      typography_10_0_0:
        "Can you pick up something with writing on it? A notebook, a phone, anything. What did you pick?",
      typography_11_0_0:
        "Now, bring it really close to your face and try to read it.",
      typography_12_0_0: "How's that going? Probably not too well.",
      typography_13_0_0:
        "Next, hold it away from your face and try reading again.",
      typography_14_0_0: "How's that going?",
      typography_15_0_0: "Better this time, right?",
      typography_16_0_0:
        "It's the same with our thoughts. When they're too close, we can't see them clearly.",
      typography_17_0_0:
        "The problem is we often take these close-up thoughts as solid facts.",
      typography_18_0_0: "Inaccurate thoughts",
      typography_19_0_0:
        "Unlike what we might believe, our thoughts can often be off the mark.",
      typography_20_0_0: "We tend to connect everything to ourselves,",
      typography_21_0_0: "make quick decisions with little info,",
      typography_22_0_0: "and brace for bad things in the future.",
      typography_23_0_0:
        "It's kind of inevitable. For most of human history, thinking this way helped us survive.",
      typography_24_0_0:
        "Having these inaccurate thoughts is almost like an instinct.",
      typography_25_0_0: "When inaccurate thoughts meet beliefs",
      typography_26_0_0:
        "The issue is when this instinct for inaccurate thoughts meets our beliefs, it quickly creates negative thoughts.",
      typography_27_0_0:
        "We call these types of thoughts 'inaccurate thinking traps.'",
      typography_28_0_0:
        "There are three kinds: Extra Blame, Little Evidence, and Negative Bias.",
      typography_29_0_0: `For example, think of someone who believes, "I'm not enough."`,
      typography_30_0_0: `What inaccurate thought might pop up in their mind?`,
      typography_31_0_0: `When something goes wrong, they might think, "It's all my fault" (Extra Blame).`,
      typography_32_0_0: `If a colleague reacts normally, they might think, "Don't they like what I did?" (Little Evidence).`,
      typography_33_0_0: `Or even, "Is everything going to fail?" (Negative Bias).`,
      typography_34_0_0:
        "Strong beliefs combined with our instinct for inaccurate thoughts can create these traps.",
      typography_35_0_0: "Stepping back from inaccurate thoughts",
      typography_36_0_0:
        "The way to distance yourself from these thinking traps is simple.",
      typography_37_0_0: "Just step back and check if the thought is accurate.",
      typography_38_0_0: "You don’t need to avoid or change it.",
      typography_39_0_0:
        "By just observing from a distance, you can stop reacting automatically to these thoughts.",
      typography_40_0_0:
        "Then, even if inaccurate thoughts come up, they won't affect you.",
      typography_41_0_0:
        "By tackling thoughts systematically, you'll find yourself distancing from them.",
      typography_42_0_0:
        "Now, how about we learn more about these inaccurate thinking traps?",
    },
  },
  ko: {
    v1: {
      typography_0_0_0: "거리두기가 어떤 원리로 진행되는지 기억하시나요?",
      typography_1_0_0:
        "생각은 생각일 뿐 '나' 자신이 아니라는 것에 대한 이해를 토대로",
      typography_2_0_0: "마음속에 떠오르는 생각을 알아차리는 것이 첫째,",
      typography_3_0_0:
        "그렇게 포착한 생각과 거리를 두며, 그것에 과하게 반응하지 않는 것이 둘째,",
      typography_4_0_0:
        "그리고 그렇게 확보된 공간에 나의 가치에 맞는 선택들로 채워 넣는 것이 셋째였어요.",
      typography_5_0_0:
        "우리는 여기서 '생각은 생각일 뿐이다'라는 말에 대해 조금 더 살펴보죠.",
      typography_6_0_0: "생각은 생각일 뿐이다",
      typography_7_0_0:
        "생각은 생각일 뿐이에요. 절대적인 진리도, 결코 변치 않는 사실도 아니죠.",
      typography_8_0_0:
        "하지만 이를 받아들이는 건 쉬운 일이 아니에요. 보통의 우리는 생각과 거리가 매우 가깝기 때문이에요.",
      typography_9_0_0: "재밌는 활동을 하나 해볼게요.",
      typography_10_0_0:
        "글자가 적힌 물건을 손으로 들어보시겠어요? 노트, 휴대폰, 글자가 적힌 것이라면 뭐든 괜찮아요. 무엇을 들었나요?",
      typography_11_0_0:
        "자, 이제 그 물건을 나의 얼굴 바로 앞까지 가깝게 가져가 본 후, 물건에 적힌 글자를 읽으려고 해보세요.",
      typography_12_0_0: "어떤가요? 잘 읽히나요?",
      typography_13_0_0:
        "아마 그렇지 않았을 거예요. 이번엔 물건을 얼굴로부터 제법 거리를 둔 상태에서 글자를 읽어보세요.",
      typography_14_0_0: "이번엔 어떤가요? 잘 읽히나요?",
      typography_15_0_0: "아마 더 명확하게 읽을 수 있었을 거예요.",
      typography_16_0_0:
        "우리 생각도 이와 마찬가지랍니다. 생각과 거리가 가까울 때 우리는 그 생각을 정확하게 바라볼 수 없어요.",
      typography_17_0_0:
        "문제는 우리가 그렇게 받아들인 생각을 찰떡 같이 사실로 믿는다는 거예요.",
      typography_18_0_0: "정확하지 않은 생각",
      typography_19_0_0: "우리의 믿음과 달리 생각은 정확하지 않을 때가 많아요.",
      typography_20_0_0:
        "우리는 항상 모든 일들을 자신에게 연관시켜 의미를 부여하고,",
      typography_21_0_0: "부족한 정보만으로도 빠르게 판단하여 결론을 내리고,",
      typography_22_0_0:
        "미래에 나쁜 일이 일어날 것을 예상하며 그에 대비하려고 해요.",
      typography_23_0_0:
        "어쩔 수 없는 일이랍니다. 인류가 생존해온 대부분의 세월 동안에는 그렇게 하는 것이 자신의 생존에 유리했기 때문이죠.",
      typography_24_0_0: "우리가 정확하지 않은 생각을 하는 건 본능과도 같아요.",
      typography_25_0_0: "정확하지 않은 생각이 믿음과 만나면",
      typography_26_0_0:
        "문제는 정확하지 않은 생각을 하는 본능이 우리의 믿음과 만나면 빠르게 부정적인 생각을 만들어낸다는 점이에요.",
      typography_27_0_0:
        "그러한 유형의 생각들을 우리는 '정확하지 않은 생각' 유형의 생각함정이라고 부른답니다.",
      typography_28_0_0:
        '이 유형의 생각함정에는 "책임 과다", "근거 부족", "부정 편향", 세 가지가 있답니다.',
      typography_29_0_0:
        '예를 들어볼까요? "나는 부족하다"라고 굳게 믿고 있는 사람을 생각해보세요.',
      typography_30_0_0:
        "이런 사람의 마음속에는 어떤 정확하지 않은 생각이 만들어질까요?",
      typography_31_0_0:
        "아마 어떠한 일이 잘 되지 않았을 때 '모든 것이 내가 부족했기 때문이다'는 생각이 떠오를 거예요(책임 과다).",
      typography_32_0_0:
        "그 일을 함께 진행했던 동료가 평범한 반응을 보이면 '내가 한 게 마음에 안 드나?'라는 생각이 떠오르기도 쉽겠죠(근거 부족).",
      typography_33_0_0:
        "어쩌면 '이렇게 가다가는 결국 아무것도 안 되고 실패하는 게 아닐까?'라는 생각도 떠오를 수 있을 거예요(부정 편향).",
      typography_34_0_0:
        "이처럼 한 사람이 가지고 있는 강력한 믿음은 정확하지 않은 생각을 하는 본능과 합쳐져 생각함정을 만들어내곤 해요.",
      typography_35_0_0: "정확하지 않은 생각으로부터 거리두기",
      typography_36_0_0:
        "정확하지 않은 유형의 생각함정으로부터 거리를 두는 방법은 간단해요.",
      typography_37_0_0:
        "그것이 정확한 생각인지, 한 걸음 떨어져서 살펴보는 것이죠.",
      typography_38_0_0:
        "억지로 생각을 피하려거나 바꾸려고 하지 않아도 괜찮아요.",
      typography_39_0_0:
        "거리를 두고 생각이 정확한지 바라보는 습관만으로도 마음속에 떠오른 생각에 자동적으로 반응하지 않을 수 있기 때문이죠.",
      typography_40_0_0:
        "그렇다면 마음속에 아무리 정확하지 않은 생각이 떠오른다고 하더라도 우리는 그 생각으로부터 영향 받지 않을 수 있을 거예요.",
      typography_41_0_0:
        "체계적으로 생각을 공략하다 보면 어느샌가 생각으로부터 거리를 두고 있는 나 자신을 발견할 수 있을 거예요.",
      typography_42_0_0:
        "자, 그러면 이제 정확하지 않은 생각 유형의 생각함정에 대해 더 자세히 알아보도록 할까요?",
    },
  },
};
