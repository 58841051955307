export const seqPostfix = {
  1: { ko: "첫", en: "first" },
  2: { ko: "두", en: "second" },
  3: { ko: "세", en: "third" },
  4: { ko: "네", en: "fourth" },
  5: { ko: "다섯", en: "fifth" },
  6: { ko: "여섯", en: "sixth" },
  7: { ko: "일곱", en: "seventh" },
  8: { ko: "여덟", en: "Eighth" },
  9: { ko: "아홉", en: "Ninth" },
  10: { ko: "열", en: "first" },
  11: { ko: "열한", en: "first" },
  12: { ko: "열두", en: "second" },
  13: { ko: "열세", en: "third" },
  14: { ko: "열네", en: "fourth" },
  15: { ko: "열다섯", en: "fifth" },
  16: { ko: "열여섯", en: "sixth" },
  17: { ko: "열일곱", en: "seventh" },
  18: { ko: "열여덟", en: "Eighth" },
  19: { ko: "열아홉", en: "" },
  20: { ko: "스무", en: "" },
  21: { ko: "스물한", en: "" },
  22: { ko: "스물두", en: "" },
  23: { ko: "스물세", en: "" },
  24: { ko: "스물네", en: "" },
  25: { ko: "스물다섯", en: "" },
  26: { ko: "스물여섯", en: "" },
  27: { ko: "스물일곱", en: "" },
  28: { ko: "스물여덟", en: "" },
  29: { ko: "스물아홉", en: "" },
  30: { ko: "서른", en: "" },
};

export const seqPostfix_ko = {
  1: "첫",
  2: "두",
  3: "세",
  4: "네",
  5: "다섯",
  6: "여섯",
  7: "일곱",
  8: "여덟",
  9: "아홉",
  10: "열",
  11: "열한",
  12: "열두",
  13: "열세",
  14: "열네",
  15: "열다섯",
  16: "열여섯",
  17: "열일곱",
  18: "열여덟",
  19: "열아홉",
  20: "스무",
  21: "스물한",
  22: "스물두",
  23: "스물세",
  24: "스물네",
  25: "스물다섯",
  26: "스물여섯",
  27: "스물일곱",
  28: "스물여덟",
  29: "스물아홉",
  30: "서른",
};
