import { Box, Stack } from "@mui/joy";
import HorizontalCalendar from "components/tool/thought-record/HorizontalCalender";
import RecordList from "components/tool/thought-record/RecordList";
import AddRecordButton from "components/tool/thought-record/AddRecordButton";
import { useEffect, useState, Suspense } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useRecoilState } from "recoil";
import { thoughtRecordToolAtom } from "recoil/thoughtRecordToolAtom";

export default function ThoughtRecordArchive() {
  dayjs.locale("ko"); // 한글 로케일을 설정
  const today = dayjs();

  const [isFirstRecord, setIsFirstRecord] = useState<boolean>(false);

  const [thoughtRecordToolState, setThoughtRecordToolState] = useRecoilState(
    thoughtRecordToolAtom
  );

  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    thoughtRecordToolState?.date || today
  );

  useEffect(() => {
    if (selectedDate) {
      setThoughtRecordToolState((state) => ({ ...state, date: selectedDate }));
    }
  }, [selectedDate]);

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "#F4F0E8",
        pt: "20px",
      }}
    >
      <Box sx={{ mb: "24px" }}>
        <HorizontalCalendar
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setIsFirstRecord={setIsFirstRecord}
        />
      </Box>
      <Suspense fallback={<div></div>}>
        <Stack sx={{ flex: 1, pb: "136px", overflow: "scroll" }}>
          <RecordList date={selectedDate.format("YYYY-MM-DD")} />
        </Stack>
      </Suspense>

      <AddRecordButton date={selectedDate} usePopup={isFirstRecord} />
    </Stack>
  );
}
