export const translation_7_5_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "지금부터 나의 가치에 맞는 행동, 즉 ‘가치 행동'을 하나씩 해나갈 거예요.",
      typography_1_0_0:
        "가치 행동은 내가 선택한 삶의 방향과 일치하는 행동을 뜻해요.",
      typography_2_0_0: `예를 들어, "훌륭한 학생들을 양성하기"가 가치인 교육자를 생각해볼게요.`,
      typography_3_0_0: `그 사람에게는 “도서관 가기", “교육학 공부하기", “멘토 선생님 만나기" 등이 중요한 가치 행동일 거예요.`,
      typography_4_0_0: `"사람들에게 친절을 베풀기"이 가치라면`,
      typography_5_0_0: `누군가 갈등이 있을 때 “먼저 웃으면서 대화하기"도 중요한 가치 행동이 될 수 있어요.`,
      typography_6_0_0:
        "우리는 앞으로 가치 행동을 하나씩 계획하며 실천해 나갈 텐데요.",
      typography_7_0_0: "가치 행동을 할 때에는 다음 네 가지를 꼭 기억해주세요.",
      typography_8_0_0: "첫째, 행동은 생각이 아니라 ‘나'가 하는 것이다",
      typography_9_0_0:
        "행동을 해나갈 때에는 부정적인 생각이나 의심, 회의감이 들 때도 많을 거예요.",
      typography_10_0_0: `그저 “그러고 싶은 기분이 들지 않는다"라는 이유로 행동하지 않고 싶은 순간도 많겠죠.`,
      typography_11_0_0: `하지만 그럴 때마다 ‘생각과 거리두기’가 디스턴싱의 중요한 목표 중 하나라는 것을 기억하세요.`,
      typography_12_0_0:
        "그런 부정적인 생각과 감정들도 결국 하나의 심리적 사건에 불과하답니다.",
      typography_13_0_0: `행동은 생각이 아니라 ‘나'가 하는 것이에요.`,
      typography_14_0_0: `마음속에 떠오르는 부정적인 생각과 거리를 두고, 그것을 인지한 상태에서`,
      typography_15_0_0:
        "나는 ‘나의 가치'에 따라 행동할 수 있다는 점을 기억하세요.",
      typography_16_0_0: "둘째, 행동은 단계적으로 강화해야 한다",
      typography_17_0_0:
        "행동은 단계적으로 계획하여 하나씩 성공 경험을 쌓는 게 중요해요.",
      typography_18_0_0: `과하다 싶을 정도로 점진적이어도 좋답니다.`,
      typography_19_0_0: `"몸과 마음을 건강하게 가꾸기"가 가치여서 운동을 계획하는 사람을 생각해볼까요?`,
      typography_20_0_0: "오늘은 운동센터에 전화만 해보아도 좋아요.",
      typography_21_0_0: "내일은 운동복만 미리 꺼내두어도 좋아요.",
      typography_22_0_0:
        "그다음 날은 문 앞으로 나가 1층까지 나갔다 오기만 해도 좋답니다.",
      typography_23_0_0: "결과가 중요한 게 아니랍니다.",
      typography_24_0_0: "가치는 방향이니까요.",
      typography_25_0_0: `우리는 매 순간 그 방향을 향해 서 있을 뿐이랍니다.`,
      typography_26_0_0: `사소해보이지만 단계적으로 해나가다보면 큰 변화를 만들어낼 수 있을 거예요.`,
      typography_27_0_0: "셋째, 회피는 가치 행동이 아니다",
      typography_28_0_0: `많은 사람들은 가치 행동을 회피로 오해하곤 해요.`,
      typography_29_0_0: `“나는 재밌는 것들을 많이 보면서 살고 싶어요"라고 이야기하며 유튜브 영상 보기를 계획하기도 하죠.`,
      typography_30_0_0: `하지만 자세히 살펴보면 그런 행동은 지금 당장 느끼는 지루함, 부정적인 감정, 미래에 대한 불안감 등을 해소하기 위해 하는 ‘회피 행동’이에요.`,
      typography_31_0_0: `회피는 가치가 아니랍니다.`,
      typography_32_0_0: `설령 그것이 나에게 즐거움을 가져주더라도요.`,
      typography_33_0_0: `그러니 내가 진정으로 원하는 모습에 맞닿아 있어 즐거움과 동시에 성취감도 주는 행동들을 찾아보는 게 좋아요.`,
      typography_34_0_0: `넷째, 버티기는 가치 행동이 아니다`,
      typography_35_0_0: `이 악물고 버티며 “언젠가 큰 보상이 있겠지"하는 것 또한 가치 행동이 아니랍니다.`,
      typography_36_0_0: `사실은 어떤 목표나 결과를 위해 원치 않는 것을 억지로 하고 있는 것이지요.`,
      typography_37_0_0: `가치는 방향이기 때문에, 가치 행동을 하는 우리는 매 순간 원하는 선택을 해나갈 수 있어야 한답니다.`,
      typography_38_0_0: `물론 그 과정에서 부정적인 생각과 감정을 만날 수도 있을 거예요.`,
      typography_39_0_0: `하지만 우리는 그 또한 거리를 두며 기꺼이 경험하기로 하고, 나의 가치에 맞는 선택을 해나갈 수 있답니다.`,
      typography_40_0_0: `가치는 내가 선택하는 그 순간부터 달성되는 것이니까요.`,
      typography_41_0_0: `여정의 시작`,
      typography_42_0_0: `자, 이제부터 가치 행동을 짜보려고 해요.`,
      typography_43_0_0: `마지막으로 한 가지만 꼭 기억해주세요.`,
      typography_44_0_0: `기적을 기대하지 마시길 바라요..`,
      typography_45_0_0: `그 방향으로 가는 길은 일직선 도로가 아니에요.`,
      typography_46_0_0: `가치를 향해 나아가다 보면 전혀 다른 방향으로 가고 있을 때도 있을 거랍니다.`,
      typography_47_0_0: `우리 모두는 인간이기 때문에 늘 성공할 수 있는 것도 아니고, 늘 올바른 길로만 갈 수 있는 것도 아니랍니다.`,
      typography_48_0_0: `세상은 우리가 통제할 수 없는 것들로 가득 채워져 있죠.`,
      typography_49_0_0: `하지만 상관없답니다.`,
      typography_50_0_0: `우리가 매 순간 나의 가치에 맞는 방향으로 나아가고 있다는 사실이 중요해요.`,
      typography_51_0_0: `기적을 기대하는 마음,`,
      typography_52_0_0: `현실이 그렇게까지 완벽하지 않다는 마음,`,
      typography_53_0_0: `부정적인 생각과 감정이 온전히 지워지지 않았다는 마음,`,
      typography_54_0_0: `그 모든 생각과 감정들은 우리가 거리를 두어야 할 하나의 심리적 사건이랍니다.`,
      typography_55_0_0: `그것을 알아차렸다면 우리는 생각과 거리를 두고 다시 나의 가치에 맞는 선택을 해나가면 그뿐이랍니다.`,
      typography_56_0_0: `디스턴싱에서는 목적지와 여정이 다르지 않아요.`,
      typography_57_0_0: `자, 그러면 이제 가치 행동을 계획해볼까요?`,
    },
  },
};
