import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import useOpenThoughtRecord from "./useOpenThoughtRecord";
import { generateThoughtRecordKey } from "logic/logics";

export default function useOpenNewThoughtRecord() {
  const patientState = useRecoilValue(patientAtom);
  const newThoughtRecordKey = generateThoughtRecordKey(
    patientState?.patientId || ""
  );
  const openNewThoughtRecord = useOpenThoughtRecord(newThoughtRecordKey);

  return openNewThoughtRecord;
}
