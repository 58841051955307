export const translation_meditation = {
  en: {},
  ko: {
    v1: {
      notification_0: "한번 연습해볼까요? 지금 5분 정도 시간을 낼 수 있나요?",
      notification_0_1:
        "한번 연습해볼까요? 지금 10분 정도 시간을 낼 수 있나요?",

      notification_1: "그럼 이따 잊지 않고 연습할 수 있게끔 알려드릴게요.",
      notification_2: "알림을 허용해주세요.",
      notification_3: "언제 할까요?",
      notification_4: "원하는 시간을 선택해주세요.",
      notification_5: "좋아요. 시간이 되면 아래 화살표를 눌러 더 진행해보세요.",

      meditation_instruction_0:
        "1. 먼저 조용하고 집중할 수 있는 장소를 찾으세요. 회사 쉬는 시간, 방 안, 침대 위, 어디든 괜찮아요.",
      meditation_instruction_1:
        "2. 편한 자세를 취하세요. 편하게 이완될 수 있는 자세면 어떤 자세든 괜찮아요. 단, 누워서 진행할 경우엔 나도 모르게 잠들 수도 있어요.",
      meditation_instruction_2:
        "3. 준비됐다면 음성 안내에 따라 훈련을 시작하세요.",

      clarification_0:
        "생각의 강 훈련을 하면서 어려운 점이 있으셨나요? 궁금한 점이 있다면 아래 질문에 하나씩 답해주세요.",
      clarification_1: "훈련을 하면서 졸렸나요?",
      clarification_1_0: "졸리셨나요? 훈련 초반에는 누구나 졸릴 수 있어요. ",
      clarification_1_1:
        "저녁이나 밤에 하면 졸음이 올 수 있으니 가급적 늦은 시간에 하지 않고, 마음이 차분해지고 집중하기가 쉬운 시간에 해보도록 하세요. ",
      clarification_1_2:
        "편하게 누울 수 있는 공간이 있다면 누워서 진행해도 좋고, 바르게 앉아 척추를 세우고 진행해도 좋습니다.",
      clarification_1_3:
        "각각의 신체에서 어떤 감각이 느껴지는지 알아차려 보세요. 그리고 그 감각이 긍정적이든 부정적이든 거부하거나 통제하려고 하지 말고 그것들을 기꺼이 느껴보세요.",

      clarification_2: "훈련을 하면서 잡념이 떠오르셨나요?",
      clarification_2_0:
        "잡념이 떠오르셨나요? 생각은 마치 팝콘 기계 속 팝콘과 같아서 어떤 팝콘이 튀어 오를지 의식적으로 선택할 수 없어요. ",
      clarification_2_1:
        "생각의 팝콘들이 떠오르면 이를 떨쳐내려고 지나치게 애쓰기보다는, 있는 그대로 인정하고 내려놓아보세요.",
      clarification_2_2:
        "생각을 피하려고 하지 않고 온전히 느끼다 보면 자연스레 생각과 거리를 둘 수 있게 될 거예요.",

      clarification_3: "훈련을 하면서 아무 생각이 안 나셨나요?",
      clarification_3_0:
        "아무 생각이 안 나셨나요? 그럴 때는 호흡을 차분하게 해보세요. 나의 신체감각을 관찰하고 기꺼이 경험하는 것부터 연습을 해보아도 좋아요.",
      clarification_3_1:
        "그리고 마음속에 떠오르는 것이 무엇이든지 간에 저절로 떠오르고 저절로 가라앉는 생각과 감정을 물끄러미 바라보세요.",
      clarification_3_2:
        "나를 사로잡는 생각이나 감정을 하나 떠올려서 시냇물에 떠내려가는 나뭇잎에 올려놓아보세요.",
      clarification_3_3:
        "그리고 시냇물 옆으로 자리를 옮겨서 나뭇잎이 떠내려가는 모습을 바라보세요. 연습을 여러 번 하다 보면 익숙해지실 거예요.",

      clarification_4: "훈련을 하면서 부정적인 감정이 다시 느껴지셨나요?",
      clarification_4_0:
        "부정적인 감정이 다시 올라오셨나요? 그럴 때는 부정적인 감정을 밀어내고 없애려고 억지로 애쓸 필요는 없답니다.",
      clarification_4_1:
        "고통은 불가피하지만 괴로움은 선택이라고 했었죠. 부정적인 감정이 떠오르면 그것을 한번 물끄러미 바라보고 있는 그대로 관찰해보세요.",
      clarification_4_2:
        "그 상태 그대로 바라보고 기꺼이 인정하면 우리는 고통에 과하게 반응하지 않은 채 괴로움에서 벗어날 수 있어요.",
      clarification_4_3:
        "처음에는 어려우실 수 있어요. 앞으로 계속 연습하다 보면 가능해질 거예요.",

      clarification_5: "훈련을 하면서 머리나 몸이 아프셨나요?",
      "네, 아팠어요": "네, 아팠어요",
      clarification_5_0:
        "머리나 그 외에 신체 어느 곳이 아프신가요? 평소 아팠던 부분이라면 더욱 그러실 수 있어요.",
      clarification_5_1:
        "그럴 때는 편하게 누울 수 있는 공간에서 누워서 진행해도 좋고, 바르게 앉아 척추를 세우고 진행해도 좋습니다. ",
      clarification_5_2:
        "각각의 신체에서 어떤 감각이 느껴지는지 알아차려 보세요. 그리고 그 감각이 긍정적이든 부정적이든 거부하거나 통제하려고 하지 말고 그것들을 기꺼이 느껴보세요.",
      clarification_5_3:
        "가만히 자세를 유지한 채 편안하게 존재하는 몸의 감각을 있는 그대로 느껴보시기 바랍니다.",
    },
    v2: {
      meditation_available_5min:
        "한번 연습해볼까요? 지금 5분 정도 시간을 낼 수 있나요?",
      meditation_available_10min:
        "한번 연습해볼까요? 지금 10분 정도 시간을 낼 수 있나요?",
      meditation_available:
        "지금 조용하고 편안한 공간에서 시간을 낼 수 있나요?",
      notification_preview:
        "그럼 이따 잊지 않고 연습할 수 있게끔 알려드릴게요.",
      notification_request: "알림을 허용해주세요.",
      notification_day_once: "언제 할까요?",
      notification_time_once: "원하는 시간을 선택해주세요.",
      notification_set_once:
        "좋아요. 시간이 되면 아래 화살표를 눌러 더 진행해보세요.",
      notification_day_weekly:
        "일주일 중 무슨 요일에 하면 좋을까요? 원하는 요일을 모두 선택해주세요.",
      notification_time_weekly:
        "하루 중 언제하면 좋을까요? 원하는 시각을 모두 선택해주세요.",
      notification_set_weekly:
        "좋아요. 앞으로 위의 시각에 알림을 보내드릴게요.",

      meditation_instruction_0:
        "1. 먼저 조용하고 집중할 수 있는 장소를 찾으세요.",
      meditation_instruction_1:
        "2. 편하게 이완될 수 있는 자세를 취하세요. 단, 누워서 진행할 경우엔 나도 모르게 잠들 수도 있어요.",
      meditation_instruction_2:
        "3. 준비됐다면 음성 안내에 따라 훈련을 시작하세요.",

      meditation_thought_0: "잘하셨어요. 무엇을 느꼈는지 적어볼까요?",

      meditation_wrapup_0: "좋아요. 오늘의 명상 훈련은 여기서 마무리할게요.",
    },
  },
};
