import {
  automaticThoughtIdType,
  ConceptKeyType,
  ConceptKeyType_automaticThought,
  ConceptKeyType_automaticThoughtForValue,
  ConceptKeyType_copingStrategy,
  ConceptKeyType_coreBelief,
  CoreBeliefKeyType,
  IntermediateBeliefKeyType,
  ReferenceKeyType,
} from "api/conceptApi";
import {
  Instruction,
  InstructionWithButtonGroup,
  InstructionWithContent,
  InstructionWithTextarea,
} from "../BlockComponent";
import {
  Audio,
  ButtonGroupMultipleChoice,
  ConceptNote,
  FeedbackLine,
  Image,
  LineWithSolidLabel,
  SolidLabelLine,
  TypographyFromConceptNoteLine,
  TypographyFromDashboardLine,
  TypographyFromDataLine,
  TypographyLineWithValue,
} from "../CellComponent";

export const LastCardWithTextarea = [
  InstructionWithTextarea("common:lastCard1", { optional: true, id: "소감" }),
  Instruction("common:lastCard2"),
];

export const LastCard = [
  // InstructionWithTextarea("common:lastCard1", { optional: true, id: "소감" }),
  InstructionWithContent(
    "common:lastCard1",
    [FeedbackLine()],
    {
      blockId: "feedback",
    },
    true
  ),
  Instruction("common:lastCard2"),
];
export const LastCard_simple = [Instruction("common:lastCard2")];

export const CoachActionBlock = [
  InstructionWithButtonGroup(
    `common:coachNextActionCard`,
    [
      {
        translationKey: "common:coachNextAction_option1",
      },
      {
        translationKey: "common:coachNextAction_option2",
      },
      // {
      //   translationKey: "common:coachNextAction_option3",
      // },
      {
        translationKey: "common:coachNextAction_option4",
      },
    ],
    { coach: true, selectedIndex: 0 },
    { hideIfPatient: true, isHidden: true }
  ),
];

export const AutomaticThought = ({
  id,
  isNotConfirmed,
  isPositive,
}: {
  id: automaticThoughtIdType;
  isNotConfirmed?: boolean;
  isPositive?: boolean;
}) => {
  const key = `automaticThought${
    isPositive ? "ForValue" : ""
  }${id}` as ConceptKeyType_automaticThought;
  return [
    ...LineWithSolidLabel(
      "상황",
      isNotConfirmed
        ? TypographyFromConceptNoteLine(key, "situation", { id: "상황" })
        : TypographyFromDashboardLine(key, "situation", { id: "상황" })
    ),
    ...LineWithSolidLabel(
      "자동적 사고",
      isNotConfirmed
        ? TypographyFromConceptNoteLine(key, "thought", { id: "생각" })
        : TypographyFromDashboardLine(key, "thought", {
            id: "생각",
          })
    ),
    ...LineWithSolidLabel(
      "감정",
      isNotConfirmed
        ? TypographyFromConceptNoteLine(key, "emotion", {
            id: "감정",
          })
        : TypographyFromDashboardLine(key, "emotion", { id: "감정" })
    ),
    ...LineWithSolidLabel(
      "신체 반응",
      isNotConfirmed
        ? TypographyFromConceptNoteLine(key, "physicalResponse", {
            id: "신체",
          })
        : TypographyFromDashboardLine(key, "physicalResponse", { id: "신체" })
    ),
    ...LineWithSolidLabel(
      "행동",
      isNotConfirmed
        ? TypographyFromConceptNoteLine(key, "behavior", { id: "행동" })
        : TypographyFromDashboardLine(key, "behavior", { id: "행동" })
    ),
  ];
};

export const Value = ({
  id,
  isNotConfirmed,
}: {
  id: automaticThoughtIdType;
  isNotConfirmed?: boolean;
}) => {
  const key =
    `automaticThoughtForValue${id}` as ConceptKeyType_automaticThoughtForValue;

  return [
    ...LineWithSolidLabel(
      "영역",
      isNotConfirmed
        ? TypographyFromConceptNoteLine(key, "valueDomain", { id: "가치 영역" })
        : TypographyFromDashboardLine(key, "valueDomain", { id: "가치 영역" })
    ),
    ...LineWithSolidLabel(
      "가치",
      isNotConfirmed
        ? TypographyFromConceptNoteLine(key, "value", { id: "가치" })
        : TypographyFromDashboardLine(key, "value", { id: "가치" })
    ),
  ];
};

export const IsolatedAutomaticThoughtTemplate = ({
  situation,
  thoughtList,
  emotionList,
  physicalReactionList,
  behaviorList,
}: {
  situation?: string;
  thoughtList?: string[];
  emotionList?: string[];
  physicalReactionList?: string[];
  behaviorList?: string[];
}) => {
  return [
    SolidLabelLine("상황"),
    TypographyLineWithValue(situation || "아직 구체화되지 않았어요"),
    SolidLabelLine("생각"),
    TypographyLineWithValue(
      thoughtList?.join(",") || "아직 구체화되지 않았어요"
    ),
    SolidLabelLine("감정"),
    TypographyLineWithValue(
      emotionList?.join(",") || "아직 구체화되지 않았어요"
    ),
    SolidLabelLine("신체 반응"),
    TypographyLineWithValue(
      physicalReactionList?.join(",") || "아직 구체화되지 않았어요"
    ),
    SolidLabelLine("행동"),
    TypographyLineWithValue(
      behaviorList?.join(",") || "아직 구체화되지 않았어요"
    ),
  ];
};

export const CopingStrategyTemplate = ({ isRaw }: { isRaw?: boolean } = {}) => {
  const key = "copingStrategy" as ConceptKeyType;
  return [
    SolidLabelLine("굴복"),
    isRaw
      ? TypographyFromConceptNoteLine(key, "surrender", { id: "굴복" })
      : TypographyFromDashboardLine(key, "surrender", { id: "굴복" }),
    SolidLabelLine("과잉보상"),
    isRaw
      ? TypographyFromConceptNoteLine(key, "overcompensation", {
          id: "과잉보상",
        })
      : TypographyFromDashboardLine(key, "overcompensation", {
          id: "과잉보상",
        }),
    SolidLabelLine("회피"),
    isRaw
      ? TypographyFromConceptNoteLine(key, "avoidance", { id: "회피" })
      : TypographyFromDashboardLine(key, "avoidance", { id: "회피" }),
  ];
};

export const IntermediateBelief = ({
  isNotConfirmed,
  intermediateBeliefKeyList,
}: {
  isNotConfirmed?: boolean;
  intermediateBeliefKeyList?: IntermediateBeliefKeyType[];
} = {}) => {
  const key = "intermediateBelief" as ConceptKeyType;

  return [
    ...(intermediateBeliefKeyList?.includes("rules")
      ? [
          SolidLabelLine("규칙"),
          isNotConfirmed
            ? TypographyFromConceptNoteLine(key, "rules", { id: "규칙" })
            : TypographyFromDashboardLine(key, "rules"),
        ]
      : []),
    ...(intermediateBeliefKeyList?.includes("attitudes")
      ? [
          SolidLabelLine("태도"),
          isNotConfirmed
            ? TypographyFromConceptNoteLine(key, "attitudes", { id: "태도" })
            : TypographyFromDashboardLine(key, "attitudes"),
        ]
      : []),
    ...(intermediateBeliefKeyList?.includes("assumptions")
      ? [
          SolidLabelLine("가정"),
          isNotConfirmed
            ? TypographyFromConceptNoteLine(key, "assumptions", { id: "가정" })
            : TypographyFromDashboardLine(key, "assumptions"),
        ]
      : []),
  ];
};

export const CoreBelief = ({
  isNotConfirmed,
  coreBeliefKeyList,
}: {
  isNotConfirmed?: boolean;
  coreBeliefKeyList?: CoreBeliefKeyType[];
} = {}) => {
  const key = "coreBelief" as ConceptKeyType;

  return [
    ...(coreBeliefKeyList?.includes("coreBelief1")
      ? [
          SolidLabelLine("스스로에 대해"),
          isNotConfirmed
            ? TypographyFromConceptNoteLine(key, "coreBelief1", {
                id: "핵심 믿음 1",
              })
            : TypographyFromDashboardLine(key, "coreBelief1"),
        ]
      : []),
    ...(coreBeliefKeyList?.includes("coreBelief2")
      ? [
          SolidLabelLine("다른 사람에 대해"),
          isNotConfirmed
            ? TypographyFromConceptNoteLine(key, "coreBelief2", {
                id: "핵심 믿음 2",
              })
            : TypographyFromDashboardLine(key, "coreBelief2"),
        ]
      : []),
    ...(coreBeliefKeyList?.includes("coreBelief3")
      ? [
          SolidLabelLine("이 세상에 대해"),
          isNotConfirmed
            ? TypographyFromConceptNoteLine(key, "coreBelief3", {
                id: "핵심 믿음 3",
              })
            : TypographyFromDashboardLine(key, "coreBelief3"),
        ]
      : []),
  ];
};

export const CoreExperience = ({
  isNotConfirmed,
}: { isNotConfirmed?: boolean } = {}) => {
  const key = "coreExperience" as ConceptKeyType;
  return [
    SolidLabelLine("핵심 경험"),
    isNotConfirmed
      ? TypographyFromConceptNoteLine(key, undefined, { id: "핵심 경험" })
      : TypographyFromDashboardLine(key, undefined),
  ];
};

export const ConceptItem = ({
  conceptKey,
  isNotConfirmed,
}: {
  conceptKey: ConceptKeyType;
  isNotConfirmed?: boolean;
}) => {
  return [ConceptNote(conceptKey, isNotConfirmed)];
};

export const MyWorryLines = () => {
  return LineWithSolidLabel(
    "나의 고민",
    // TypographyFromDataLine({ A: { id: "고민", taskId: "1-1-A" } }, "common:$A$")
    TypographyFromDataLine({ A: { id: "고민", taskId: "2-2-T" } }, "common:$A$")
  );
};

export const MyConcernLine = () => {
  return LineWithSolidLabel(
    "고민",
    TypographyFromConceptNoteLine("concern", undefined)
  );
};

export const MyGoalLine = () => {
  return LineWithSolidLabel(
    "목표",
    TypographyFromConceptNoteLine("goal", undefined)
  );
};

export const MyAutomaticThoughtLines = () => {
  return LineWithSolidLabel(
    "자동적 사고",
    TypographyFromDataLine({ A: { id: "생각" } }, "common:$A$")
  );
};

export const NotificationTimeBlocksOnce = [
  InstructionWithButtonGroup(
    "notification_day_once",
    [
      {
        text: "오늘",
      },
      {
        text: "내일",
      },
    ],
    { id: "notification_date" },
    { blockId: "알림 허용" }
  ),
  InstructionWithButtonGroup(
    "notification_time_once",
    [
      {
        text: "오전 7시",
      },
      {
        text: "오전 10시",
      },
      {
        text: "오후 1시",
      },
      {
        text: "오후 4시",
      },
      {
        text: "오후 7시",
      },
      {
        text: "오후 10시",
      },
    ],
    { id: "notification_time" }
  ),
];
const NotificationTimeBlocksWeekly = [
  InstructionWithContent(
    "notification_day_weekly",
    [
      [
        ButtonGroupMultipleChoice(
          [
            {
              text: "월요일",
            },
            {
              text: "화요일",
            },
            {
              text: "수요일",
            },
            {
              text: "목요일",
            },
            {
              text: "금요일",
            },
            {
              text: "토요일",
            },
            {
              text: "일요일",
            },
          ],
          { id: "notification_date" }
        ),
      ],
    ],
    { blockId: "알림 허용" }
  ),
  InstructionWithContent("notification_time_weekly", [
    [
      ButtonGroupMultipleChoice(
        [
          {
            text: "오전 7시",
          },
          {
            text: "오전 10시",
          },
          {
            text: "오후 1시",
          },
          {
            text: "오후 4시",
          },
          {
            text: "오후 7시",
          },
          {
            text: "오후 10시",
          },
        ],
        { id: "notification_time" }
      ),
    ],
  ]),
  Instruction(`notification_set_weekly`),
];

export const NotificationBlocksWeekly = (
  isPushNotificationGranted: boolean
) => [
  ...(!isPushNotificationGranted
    ? [
        InstructionWithContent("notification_request", [
          [
            {
              type: "pushNotification",
              content: { showFromIdIfDenied: "알림 미허용" },
            },
          ],
        ]),
        ...NotificationTimeBlocksWeekly,
      ]
    : [...NotificationTimeBlocksWeekly]),

  // Instruction(`notification_set_weekly`, {
  //   blockId: "알림 미허용",
  // }),
];

export const NotificationBlocksOnce = (
  isPushNotificationGranted: boolean,
  randomId: string,
  firstInstruction?: string
) => {
  return [
    InstructionWithButtonGroup(firstInstruction || `meditation_available`, [
      {
        text: "가능해요",
        showFromId: `가능_${randomId}`,
        hideFromId: `불가능_${randomId}`,
      },
      {
        text: "지금은 안돼요",
        showFromId: `불가능_${randomId}`,
        hideFromId: `가능_${randomId}`,
      },
    ]),
    Instruction("notification_preview", { blockId: `불가능_${randomId}` }),
    ...(!isPushNotificationGranted
      ? [
          InstructionWithContent("notification_request", [
            [
              {
                type: "pushNotification",
                content: { showFromIdIfDenied: `알림 미허용_${randomId}` },
              },
            ],
          ]),
          ...NotificationTimeBlocksOnce,
        ]
      : [...NotificationTimeBlocksOnce]),
    Instruction("notification_set_once", {
      blockId: `알림 미허용_${randomId}`,
    }),
  ];
};

export const MeditationInstructionBlocks = (
  imageSrc: string,
  audioUrl: string,
  randomId: string,
  withoutWrapup?: boolean
) => [
  Instruction(`meditation_instruction_0`, { blockId: `가능_${randomId}` }),
  Instruction(`meditation_instruction_1`),
  InstructionWithContent("meditation_instruction_2", [
    Image(imageSrc),
    Audio(audioUrl),
  ]),
  ...(!withoutWrapup
    ? [Instruction(`meditation_wrapup_0`, { nextBlockId: "feedback" })]
    : []),
];

export const NotificationOnceAndInstructionBlocks = (
  isPushNotificationGranted: boolean,
  imageSrc: string,
  audioUrl: string,
  withoutWrapup?: boolean,
  firstInstruction?: string
) => {
  const randomId = Math.random();

  return [
    ...NotificationBlocksOnce(
      isPushNotificationGranted,
      `${randomId}`,
      firstInstruction
    ),
    ...MeditationInstructionBlocks(
      imageSrc,
      audioUrl,
      `${randomId}`,
      withoutWrapup
    ),
  ];
};
