export const translation_6_2 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "주의는 '나'의 의도입니다.",
      typography_1_0_0:
        "주의는 우리 마음속에 떠오른 다양한 정보 중 특정 정보를 우리의 의식에 포착되도록 하는 것입니다.",
      typography_2_0_0:
        "주의는 마음속의 어떤 것은 밝게 비추지만, 다른 것들은 어둠 속에 남겨두는 손전등과도 같습니다.",
      typography_3_0_0: "알아차림",
      typography_4_0_0:
        "우리가 어떤한 자극에 선택적으로 주의를 기울이고 그 대상을 인지하는 것을 '알아차림(awareness)'이라고 합니다.",
      typography_5_0_0:
        "이 알아차림은 '나'의 입장에서 마음속에 떠오르는 꾸러미의 개별 요소를 명료하게 자각하는 것입니다.",
      typography_6_0_0: "자신이 특정한 생각을 끊임없이 곱씹고 있다는 것,",
      typography_7_0_0: "어떠한 감정에 과도하게 반응하고 있다는 사실,",
      typography_8_0_0:
        "신체가 어떤 모습을 하고 있고, 어떤 감각을 느끼고 있는지,",
      typography_9_0_0: "반사적인 행동과 그 행동을 하기 전의 충동까지,",
      typography_10_0_0: "이 모든 것을 알아차릴 수 있습니다.",
      typography_11_0_0: "훈련의 필요성",
      typography_12_0_0: "알아차림은 섬세한 주의력에 의해 발달됩니다.",
      typography_13_0_0: "때문에 주의력을 훈련하는 것이 중요합니다.",
      typography_14_0_0:
        "조금 더 구체적으로 이야기 하면, 우리는 선택적 주의(selective attention) 능력을 함양해야 합니다.",
      typography_15_0_0:
        "의도와 목적성을 갖고 주의를 특정한 자극에 고정해두는 것이죠.",
      typography_16_0_0: "주의 통제의 오해",
      typography_17_0_0:
        "주의력을 통제한다는 것은, 우리가 보고 싶은 것만을 보고, 다른 것들은 외면한다는 뜻이 아닙니다.",
      typography_18_0_0:
        "불편한 감정을 피하고, 즐거운 경험에만 주의를 기울인다는 것이 아닙니다.",
      typography_19_0_0:
        "우리는 주의를 기울이는 대상을 통제하는 것이 아니라, 우리의 주의력을 통제하는 것입니다.",
      typography_20_0_0: "이 미묘한 지점을 잘 이해해야 합니다.",
      typography_21_0_0: "마음속에는 그 어떤 팝콘도 튀어오를 수 있습니다.",
      typography_22_0_0:
        "하루종일 나를 괴롭히는 어마무시한 팝콘이 튀어오를 수도 있죠.",
      typography_23_0_0:
        "주의력을 통제한다는 것은 이 어마무시한 팝콘을 외면한다는 것이 아닙니다.",
      typography_24_0_0:
        "오히려 그 팝콘에 주의력을 정확히 두고, 그것이 어떠한 꾸러미로 구성되어있는지 분리해내겠다는 뜻입니다.",
      typography_25_0_0: "훈련의 시작",
      typography_26_0_0: "지금부터 주의력 훈련을 시작하겠습니다.",
      typography_27_0_0:
        "이 훈련은 지금 이 시점부터 일평생 꾸준히 해나가야 합니다.",
      typography_28_0_0:
        "당신이 유연하고 단단한 내면을 갖기를 포기하는 날이 온다면 그때는 그만두셔도 좋습니다.",
    },
  },
};
