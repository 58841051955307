import { useCallback, useEffect, useState } from "react";
import { ProgramContentType } from "data/BlockComponent";
import TextareaCell from "./TextareaCell";
import { useMutation } from "@tanstack/react-query";
import { Stack } from "@mui/joy";
import SavePresetWithReasonButton from "components/ai/SavePresetWithReasonButton";
import { aiApi } from "api/aiApi";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import * as _ from "lodash";
import { setProgramContentData } from "logic/logics";

export default function AiCommentCell({
  taskKey,
  thoughtRecordKey,
  initialValue,
  aiCommentValue,
  isCoach,
  blockIndex,
  setData,
  lineIndex,
  cellIndex,
  editorKey,
  aiCommentKey,
}: {
  taskKey: string;
  thoughtRecordKey?: string;
  initialValue?: string;
  aiCommentValue?: string;
  isCoach?: boolean;
  blockIndex: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  editorKey?: string;
  aiCommentKey: string;
}) {
  const patientState = useRecoilValue(patientAtom);
  const [modificationReason, setModificationReason] = useState<string>("");
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);

  useEffect(() => {
    if (aiCommentValue === initialValue) {
      setShowSaveButton(false);
    } else {
      setShowSaveButton(true);
    }
  }, [aiCommentValue, initialValue]);

  const updateCommentQueryFn = useCallback(
    () =>
      aiApi.updateComment(
        `${patientState?.patientId}`,
        taskKey,
        {
          aiCommentKey: aiCommentKey,
          comment: initialValue || "",
          reason: modificationReason,
        },
        thoughtRecordKey
      ),
    [
      patientState?.patientId,
      taskKey,
      aiCommentKey,
      initialValue,
      modificationReason,
      thoughtRecordKey,
    ]
  );

  const { mutate: updateComment } = useMutation(updateCommentQueryFn, {
    onSuccess: () => {
      setModificationReason("");
      setShowSaveButton(false);

      setProgramContentData({
        setData,
        blockIndex,
        lineIndex,
        cellIndex,
        newlyAddedData: { aiCommentValue: initialValue },
      });
    },
  });

  return (
    <Stack spacing={0.5} alignItems="end">
      <TextareaCell
        initialHtmlString={`<p>${initialValue}</p>`}
        initialValue={initialValue}
        isCoachField
        isCoach={isCoach}
        setData={setData}
        lineIndex={lineIndex}
        cellIndex={cellIndex}
        blockIndex={blockIndex}
        editorKey={editorKey}
      />
      {showSaveButton && (
        <SavePresetWithReasonButton
          initialValue={aiCommentValue}
          value={initialValue}
          updateAction={updateComment}
          modificationReason={modificationReason}
          setModificationReason={setModificationReason}
        />
      )}
    </Stack>
  );
}
