export const translation_5_3_A = {
  en: {
    v1: {
      typography_0_0_0:
        "Now, let's focus on our automatic thoughts during the Stream of Thoughts exercise.",
      typography_1_0_0:
        "Let's explore the automatic thoughts we're dealing with.",
      typography_2_0_0:
        "It's simple: imagine these thoughts floating down the Stream of Thoughts.",
      typography_3_0_0: "Keep these points in mind as we proceed:",
      typography_4_0_0:
        "First, understand that the thoughts arising in your mind aren't you. Imagine sitting by a stream, watching leaves drift by.",
      typography_5_0_0:
        "Second, when you encounter negative thoughts, don't try to avoid or push them away. Instead, feel them as they are and stay with that feeling for a moment.",
      typography_6_0_0:
        "Third, realize that you can't stop unhelpful or inaccurate thoughts from popping up, but you can recognize them and choose not to react.",
      typography_7_0_0: "Ready to start? This will take about 15 minutes.",
      typography_8_0_0:
        "1. Find a quiet place where you can concentrate. During a break at work, in your room, or on your bed - anywhere is fine.",
      typography_9_0_0:
        "2. Get comfortable. Any relaxed posture is good. Just be aware you might fall asleep if you're lying down.",
      typography_10_0_0:
        "3. Once ready, start the training following the audio guide.",
      typography_11_0_0:
        "How was the training? Please write down any thoughts or challenges you encountered.",
      typographyFromData_12_0_0:
        "You previously rated the impact of this thought as $A$. How much is it affecting you now?",
      typography_13_0_0: "Alright, that concludes our activity.",
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "자, 지금부터는 생각의 강 훈련을 나의 자동적 사고에 초점을 맞춰 진행해 볼 거예요.",
      typography_1_0_0: "함께 찾아 다루고 있는 자동적 사고를 살펴볼까요?",
      typography_2_0_0:
        "방법은 간단해요. 생각의 강에 위의 자동적 사고 패턴에 적힌 생각들이 떠내려온다고 상상하면 된답니다.",
      typography_3_0_0: "다음을 기억하며 진행해보도록 해요.",
      typography_4_0_0:
        "첫째, 마음속에 떠오르는 생각은 '나'가 아니라는 사실을 인지하고, 시냇물 옆에 앉아 떠내려오는 나뭇잎을 바라보자.",
      typography_5_0_0:
        "둘째, 부정적인 생각을 마주하면 이를 피하거나 몰아내려고 애쓰지 말고, 오히려 있는 그대로 느끼며 잠시 그 느낌에 머물러보자.",
      typography_6_0_0:
        "셋째, 정확하지 않거나 도움이 되지 않는 생각이 마음속에 떠오르는 건 막을 수 없지만, 그것이 무엇인지 알아차린 후 그에 반응하진 않을 수 있다는 점을 인지하자.",
      typography_7_0_0: "자, 그러면 시작해볼까요? 15분 정도 걸릴 거예요.",
      typography_8_0_0:
        "1. 먼저 조용하고 집중할 수 있는 장소를 찾으세요. 회사 쉬는 시간, 방 안, 침대 위, 어디든 괜찮아요.",
      typography_9_0_0:
        "2. 편한 자세를 취하세요. 편하게 이완될 수 있는 자세면 어떤 자세든 괜찮아요. 단, 누워서 진행할 경우엔 나도 모르게 잠들 수도 있어요.",
      typography_10_0_0: "3. 준비됐다면 음성 안내에 따라 훈련을 시작하세요.",
      typography_11_0_0:
        "훈련은 어땠나요? 느낀 점이나 어려웠던 점은 없었는지 적어주세요.",
      typographyFromData_12_0_0:
        "이전에 이 생각에 영향받는 정도를 $A$점이라고 하셨어요. 지금 이 생각은 나에게 얼마나 영향을 주고 있나요?",
      typography_13_0_0: "좋아요. 이번 활동은 여기서 마무리할게요.",
    },
  },
};
