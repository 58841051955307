export const translation_1_2_A = {
  en: {
    v1: {
      네: "Yes",
      아니요: "No",
      typography_0_0_0:
        "Let's put together a program that fits your concerns and goals.",
      typography_1_0_0: "But first, let's quickly get how the program works.",

      typography_2_0_0: "Getting Distancing down",
      typography_3_0_0: "Distancing is pretty straightforward.",
      typography_4_0_0:
        "All mental hang-ups come from not keeping enough space between us and our thoughts.",
      typography_5_0_0: "Meaning, if thoughts get too close,",
      typography_6_0_0: "we start thinking they're the same as 'us'.",
      typography_7_0_0:
        "Then, we end up reacting to negative thoughts without much control, feeling stuck.",
      typography_8_0_0:
        "This big idea has been checked out by lots of smart therapy methods and studies from big-name doctors and researchers.",

      typography_9_0_0: "My program",
      typography_10_0_0: `Reflecting on our talk:`,
      typography_11_0_0: `How's it looking? Was our chat put together nicely?`,
      typography_12_0_0: "Where do you feel this isn't quite right?",
      typography_13_0_0:
        "Okay, let's stop here for today. I'll review and get back to you soon.",
      typography_14_0_0:
        "Fantastic. We're going to move forward with a program tailored to these concerns and goals.",
      typography_15_0_0:
        "We’re going to zero in on those repeating thoughts that keep troubling you.",
      typography_16_0_0:
        "Next up, we’ll practice stepping back to look at these thoughts without letting them take over.",
      typography_17_0_0:
        "Then, it’s learning time - we’ll get into mindfulness to change how we deal with thoughts.",
      typography_18_0_0: `After that, we dive into understanding why we think the way we do and where it all comes from.`,
      typography_19_0_0:
        "Knowing this helps us get why we’ve reacted certain ways in the past.",
      typography_20_0_0:
        "Finally, we’ll work on changing our actions and reactions.",
      typography_21_0_0:
        "At the end, you’ll be better equipped to live the life you’re aiming for.",
      typography_22_0_0:
        "We’ll walk this path together for the next three months, keeping an eye on how things go.",
      typography_23_0_0:
        "Your personalized program is always available in <My Progress>.",

      typography_24_0_0: "Change time",
      typography_25_0_0:
        "We'll take a peek at what kind of thoughts pop up when things get tough,",
      typography_26_0_0:
        "and try learning and practicing how to deal with these thoughts in a new way.",
      typography_27_0_0: "Facing up to our thoughts isn’t always easy.",
      typography_28_0_0:
        "It’s normal to not want to deal with it, especially when it feels uncomfortable.",
      typography_29_0_0: "But keep in mind, that’s all part of changing.",
      typography_30_0_0:
        "People who keep at it, even if they drag their feet sometimes, end up making real change.",
      typography_31_0_0:
        "Got any worries or careful thoughts before we jump into the program?",
      typography_31_0_0_1: "What's on your mind?",
      typography_32_0_0:
        "Great. We’ll keep going with the program, keeping today’s chat in mind.",
    },
  },
  ko: {
    v1: {
      네: "네",
      아니요: "아니요",
      typography_0_0_0:
        "이번 활동에서는 나의 고민과 목표에 맞도록 프로그램을 구성해볼게요.",
      typography_1_0_0:
        "그전에 먼저 프로그램의 원리에 대해 간단히 이해하고 넘어가 볼게요.",

      typography_2_0_0: "디스턴싱 이해하기",
      typography_3_0_0: "디스턴싱의 원리는 간단해요.",
      typography_4_0_0:
        "모든 마음의 문제는 '생각과의 거리두기(distancing)'가 잘되지 않아 발생한다는 것인데요.",
      typography_5_0_0: "다시 말해, 생각과의 거리가 너무 가까우면",
      typography_6_0_0: "생각을 '나' 자신과 동일시하게 되고",
      typography_7_0_0:
        "결국 부정적인 생각에 반사적이고 강하게 반응하며 속수무책으로 당할 수 밖에 없다는 것이죠.",
      typography_8_0_0:
        "이 핵심적인 원리는 수많은 심리치료 기법과 저명한 정신과 의사와 학자들의 연구를 통해 검증된 바 있습니다.",

      typography_9_0_0: "나의 프로그램",
      typography_10_0_0: `앞선 활동에서 함께 이야기 나눈 내용을 정리해 보면 다음과 같아요.`,
      typography_11_0_0: `어떤가요? 함께 나눈 얘기가 잘 정리가 되었나요?`,
      typography_12_0_0: "어떤 부분에서 그렇지 않다고 생각하시나요?",
      typography_13_0_0:
        "좋아요, 그럼 오늘은 여기까지 하도록 해요. 제가 확인 후 다시 말씀드릴게요.",
      typography_14_0_0:
        "좋아요. 앞으로 이 고민과 목표에 맞게 $A$님의 프로그램을 진행해 나갈 거예요.",
      typography_15_0_0:
        "우린 먼저 자주 반복되며 나를 괴롭히는 생각을 파악하는 데에 집중할 거예요.",
      typography_16_0_0:
        "그 뒤 그 생각을 공략하여 객관적으로 따져보는 보는 연습을 하게 될 거랍니다.",
      typography_17_0_0:
        "이어서 마음챙김 훈련을 통해 생각과 관계 맺는 법을 배울 거고요.",
      typography_18_0_0: `이후에는 나의 인지 체계와 그것이 만들어진 배경을 알아나갈 거예요.`,
      typography_19_0_0:
        "이를 통해 내가 특정 상황에서 왜 그렇게 반응해 왔는지 이해할 수 있을 거예요.",
      typography_20_0_0:
        "이후에는 행동 변화를 만들어내는 작업을 시작할 거에요.",
      typography_21_0_0:
        "이 모든 과정이 끝나면 내가 원하는 삶을 만들어낼 수 있는 힘이 마련될 거랍니다.",
      typography_22_0_0:
        "앞으로 3개월 동안 이 과정을 밟아나가며 꾸준히 효과를 추적할 거예요.",
      typography_23_0_0:
        "$A$님에게 맞게 구성된 프로그램은 <진행 상황>에서 언제든 확인할 수 있답니다.",

      typography_24_0_0: "변화의 과정",
      typography_25_0_0:
        "앞으로 우리는 어려움을 겪을 때마다 어떤 생각이 작용하고 있는지 살펴보고",
      typography_26_0_0:
        "그러한 생각과 다시 관계 맺는 방법을 배우고 훈련해 볼 거예요.",
      typography_27_0_0: "생각을 마주하는 일이 쉬운 일은 아닐 거예요.",
      typography_28_0_0:
        "불편한 감정을 마주할 때면 하기 싫어지고 미루게 되기도 해요.",
      typography_29_0_0: "하지만 그것 또한 변화를 위한 하나의 과정이랍니다.",
      typography_30_0_0:
        "조금 미루고 늦어지더라도 꾸준히 진행한 분들은 결국 유의미한 변화를 만들어내고 있다는 점을 꼭 기억해주세요.",
      typography_31_0_0:
        "자, 프로그램을 본격적으로 시작하기에 앞서 혹시 염려되거나 조심스러운 부분도 있을까요?",
      typography_31_0_0_1: "어떤 점이 걱정되시나요?",
      typography_32_0_0:
        "좋아요. 그러면 오늘 이야기한 것에 맞춰 앞으로 프로그램을 진행해볼게요.",
    },
  },
};
