export const translation_103_1_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "안에서 밖으로",
      typography_1_0_0:
        "흔히 사람들은 내부에서 어떤 충동을 느낄 때까지 행동을 미루거나, 아니면 적어도 어떤 의향이 느껴져야 특정한 일을 하게 돼요.",
      typography_2_0_0: "어떤 사람은 영화가 보고 싶기 때문에 극장에 가고,",
      typography_3_0_0:
        "어떤 사람은 아무것도 하고 싶지 않아서 텔레비전을 볼 것이며,",
      typography_4_0_0:
        "또 어떤 사람은 모험을 즐기고 싶은 생각이 들기 때문에 등산을 갈 테죠.",
      typography_5_0_0:
        "이러한 행동들은 행동을 유발하는 동기가 내부로부터 나왔기 때문에 ‘안에서부터 밖으로(inside-out)’ 유발된 행동이라고 지칭해요.",
      typography_6_0_0: "밖에서 안으로",
      typography_7_0_0:
        "하지만 우리의 대부분 일상은 매일 아침 직장에 가거나, 가족을 돌보거나, 집안일을 하는 등 선택의 여지가 거의 없는 활동들로 구성되어 있어요.",
      typography_8_0_0:
        "그 일을 하고 싶은지의 여부와 상관없이 일상적인 일들을 수행하죠.",
      typography_9_0_0:
        "가령 춥고 어두운 아침에 옷을 차려입고 직장에 가고 싶은 동기를 전혀 느끼지 못할 수도 있지만 꼭 필요한 일이나 직장에서의 일들은 결국 하게 되죠.",
      typography_10_0_0: "여기에서 사람은 종종 흥미와 성취감을 느끼기도 해요.",
      typography_11_0_0:
        "이와 같은 행동을 ‘밖에서부터 안으로(outside-in)’ 유발된 행동이라고 지칭하며, 이런 행동 뒤에는 감정이 따라온답니다.",
      typography_12_0_0: "밖에서 안으로 행동하기",
      typography_13_0_0:
        "디스턴싱에서는 밖에서 안으로 행동하는 것이 무기력에서 탈출하는 핵심이라고 가정해요.",
      typography_14_0_0:
        "행동하기 위한 동기가 부여될 때까지 기다리면, 끝없는 악순환 속에 남아 있게 된다는 것이지요.",
      typography_15_0_0:
        "디스턴싱은 행동할 만큼 기분이 나아지는 것을 기다리는 대신 활동을 시작하도록 요구해요.",
      typography_16_0_0:
        "물론 당신은 지금 당장 이 무기력한 기분이 나아지기를 원한다는 걸 잘 알고 있어요.",
      typography_17_0_0:
        "디스턴싱도 정확하게 동일한 목적을 갖고 있어요. 다만 방향이 반대일 뿐이죠.",
      typography_18_0_0:
        "디스턴싱은 기분에 따라 이끌려 가기보다는 정해진 목표와 계획에 따라 행동하기를 요구한답니다.",
      typography_19_0_0:
        "그 과정을 반복하다 보면 궁극적으로 충족감이 돌아오며, 무기력을 벗어나는 선순환이 조금씩 자리 잡게 될 거예요.",
      typography_20_0_0: "하나씩 실천하기",
      typography_21_0_0:
        "따라서 핵심은 ‘안에서부터 밖으로'가 아닌 ‘밖에서부터 안으로' 유발된 행동을 하는 거예요.",
      typography_22_0_0: "우리는 그 과정을 ‘활동 계획'이라고 부른답니다.",
      typography_23_0_0:
        "앞으로 우리는 프로그램이 끝날 때까지 이 활동 계획을 반복할 거예요.",
      typography_24_0_0: "활동 계획의 목표는 한 가지랍니다.",
      typography_25_0_0:
        "당신의 삶에서 ‘긍정 강화 행동'을 높이는 거예요. 이 활동은 당신을 다시 긍정적인 보상이 원천이 가득한 삶으로 되돌려줄 거예요.",
      typography_26_0_0: "물론 그 과정이 쉽진 않을 거랍니다.",
      typography_27_0_0: "그래서 아주 쉬운 활동부터 어려운 활동으로,",
      typography_28_0_0:
        "또 단순히 기분이 좋아지는 활동부터 나에게 성취감을 주는 활동까지 차근히 시도해 볼 거예요.",
      typography_29_0_0:
        "또한 그 과정에서 분명 계획한 활동을 실패하는 순간도 있을 거랍니다.",
      typography_30_0_0: "하지만 이 실패조차 가치 있을 거예요.",
      typography_31_0_0:
        "그 실패를 분석하여 계획한 활동을 해낼 수 있는 조건을 만들어낼 테니까요.",
      typography_32_0_0:
        "활동 계획은 주로 <활동기록지>에서 발견된 ‘긍정 강화 행동'을 더 폭넓게 해 보는 형태로 진행될 텐데요.",
      typography_33_0_0:
        "자, 그렇다고 <활동기록지>가 충분히 쌓일 때까지 기다릴 순 없겠죠? 변화는 지금 당장 시작되어야 하니까요.",
      typography_34_0_0:
        "첫 시작은 보편적으로 사람들에게 ‘긍정 강화'를 준다고 알려진 다양한 활동 리스트에서 골라 시작해볼 거예요.",
      typography_35_0_0:
        "이후에는 <활동기록지>에서 찾은 나만의 ‘긍정 강화 행동'도 실행해 볼 거랍니다.",
      typography_36_0_0: "그러면 첫 번째 활동을 계획해볼까요?",
    },
  },
};
