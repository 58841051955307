import { Button } from "@mui/joy";
import * as _ from "lodash";
import { useRecoilValue } from "recoil";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { layoutAtom } from "recoil/layoutAtom";
import { debounce } from "lodash";
import useOpenNewMeditationRecord from "hooks/useOpenNewMeditationRecord";

export default function AddNewRecordButton({
  disabled,
}: {
  disabled: boolean;
}) {
  const { isTaskContainerSmall } = useRecoilValue(layoutAtom);

  const openNewThoughtRecord = useOpenNewMeditationRecord();

  const debouncedOnClick = debounce(() => {
    openNewThoughtRecord();
  }, 500); // 1000ms = 1초

  return (
    <Button
      fullWidth
      onClick={debouncedOnClick}
      size={isTaskContainerSmall ? "sm" : "md"}
      variant="soft"
      sx={{
        minWidth: 0,
        paddingInline: 0,
        ...(isTaskContainerSmall && { "--Button-gap": 0 }),
      }}
      disabled={disabled}
      startDecorator={<AddRoundedIcon />}
    >
      {!isTaskContainerSmall && "훈련하기"}
    </Button>
  );
}
