export const translation_2_2 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "생각이 자동적이라는 사실을 아주 직관적으로 이해할 수 있는 비유를 하나 알려드릴게요.",
      typography_1_0_0: "생각은 마치 팝콘과 같습니다.",
      typography_2_0_0: "팝콘은 팝콘 기계 속에서 불규칙하게 튀어오릅니다.",
      typography_3_0_0: "우리는 마음속에 모두 팝콘 기계를 지니고 있습니다.",
      typography_4_0_0: "생각은 정확히 우리 마음속에서 이와 같이 작동합니다.",
      typography_5_0_0: "그저 마음속에서 불규칙하게 떠오를 뿐입니다.",
      typography_6_0_0: "생각의 특성",
      typography_7_0_0: "생각은 정말이지 팝콘 기계 속의 팝콘과 같습니다.",
      typography_8_0_0: "바닥에는 수많은 옥수수 알갱이가 깔려있습니다.",
      typography_9_0_0:
        "우리는 어느 옥수수 알갱이가 팝콘이 되어 튀어오를지, 또 어느 방향으로 날라갈지 전혀 예측할 수 없습니다.",
      typography_10_0_0:
        "이렇듯 팝콘 비유를 떠올리면, 우리는 생각의 특성을 더 잘 이해할 수 있습니다.",
      typography_11_0_0:
        "임의적이고, 즉각적이고, 예측 불가능하며 정교하지도 않죠.",
      typography_12_0_0:
        "인지치료의 선구자들은 그러한 생각을 '자동적 사고(Automatic thoughts)'라고 부르기도 했습니다.",
      typography_13_0_0: "문제 해결의 비밀",
      typography_14_0_0:
        "하지만 학술적 용어는 중요치 않습니다. 지식만으로는 문제가 해결되지 않거든요.",
      typography_15_0_0: "중요한 것은 배움을 나의 일상에서 실천하는 것입니다.",
      typography_16_0_0:
        "그러기 위해서는 생각이 자동적이라는 사실을 진심으로 받아들여야 합니다.",
      typography_17_0_0:
        "생각이 자동적이라는 사실을 받아들이지 못하는 사람들은 심리적 문제로 괴로워질 수밖에 없습니다.",
      typography_18_0_0:
        "내가 만든 생각이니, 내가 마음대로 할 수 있다고 믿습니다.",
      typography_19_0_0:
        "모든 것은 생각하기에 달렸다며 우울하고 불안한 마음과 싸우곤 합니다.",
      typography_20_0_0: "마음속에 떠오른 생각에 아주 즉각적으로 반응합니다.",
      typography_21_0_0: "그치만 어쩌죠?",
      typography_22_0_0:
        "우리 인생 평생에 걸쳐 팝콘은 계속 튀어오릅니다. 임의적이고, 즉각적이고, 예측 불가능하기에 막을 수 없습니다.",
      typography_23_0_0:
        "하지만 그들은 튀어오르는 모든 팝콘들에 일일이 반응하며 인생을 허비합니다.",
      typography_24_0_0: "잘못된 방법으로 문제를 해결하려 하면서 말이죠.",
      typography_25_0_0:
        "모두가 그런 것은 아닙니다. 몇몇 사람들은 마음 문제를 해결하는 비법을 알고 있었습니다.",
      typography_26_0_0:
        "석가모니와 같은 뛰어난 종교 지도자들, 비트겐슈타인과 같은 위대한 철학자들, 그리고 스티브 잡스와 같은 위대한 사업가들이 그랬습니다.",
      typography_27_0_0: "바로 디스턴싱의 원리죠.",
      typography_28_0_0: "또 튀어오르는 팝콘",
      typography_29_0_0: "여러분들이 이 사실을 믿지 않아도 어쩔 수 없습니다.",
      typography_30_0_0:
        '"무슨 말도 안되는 소리를 하고 있어. 생각은 내가 하는 거래도"',
      typography_31_0_0:
        "그러한 생각이 마음속에서 팝콘처럼 튀어올랐을 뿐입니다.",
      typography_32_0_0:
        "튀어오르는 팝콘 위에 올라타 팝콘이 튀어올랐다는 사실을 모르고 있을 뿐이죠.",
      typography_33_0_0:
        "마치 지구에 두 발을 딛고서는 지구가 돌고 있다는 것을 전혀 체감하지 못하는 것처럼요.",
      typography_34_0_0:
        "우리는 팝콘에서 내려와, 팝콘 기계 밖에서 튀어오르는 팝콘들을 관찰해야 합니다.",
      typography_35_0_0:
        "이것이 변화의 시작이라는 점을 꼭 기억하시기 바랍니다.",
    },
  },
};
