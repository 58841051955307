import LoginButtonTemplate from "./LoginButtonTemplate";
import useGoogleLogin from "hooks/useGoogleLogin";
import useMixpanelWeb from "hooks/useMixpanelWeb";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";

export default function GoogleLoginButton({
  render,
}: {
  render?: (props: {
    onClick: (e?: any) => void;
    disabled?: boolean;
  }) => JSX.Element;
}) {
  const handleGoogleLogin = useGoogleLogin();
  const user = useRecoilValue(userAtom);

  const setMixPanel = useMixpanelWeb({
    eventName: "구글 로그인",
  });

  return (
    <LoginButtonTemplate
      loginType="google"
      action={() => {
        setMixPanel();
        handleGoogleLogin();
      }}
      isLoggedIn={!!user?.accessToken}
    />
  );
}
