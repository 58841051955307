import * as React from "react";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import TransferList from "components/task-list/TransferList";

export default function EditTaskListButton() {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="neutral"
        onClick={() => {
          setOpen(true);
        }}
        // startDecorator={<span className="material-symbols-outlined">edit</span>}
        startDecorator={
          <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_633_1306)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.4746 5C8.26547 5 6.47461 6.79086 6.47461 9V21C6.47461 23.2091 8.26547 25 10.4746 25H22.9746C23.803 25 24.4746 24.3284 24.4746 23.5C24.4746 22.6716 23.803 22 22.9746 22H10.9746C10.1462 22 9.47461 21.3284 9.47461 20.5C9.47461 19.6716 10.1462 19 10.9746 19H22.9746C23.803 19 24.4746 18.3284 24.4746 17.5V7C24.4746 5.89543 23.5792 5 22.4746 5H10.4746Z"
                fill={"currentColor"}
              />
            </g>
            <defs>
              <clipPath id="clip0_633_1306">
                <rect
                  width="30"
                  height="30"
                  fill="white"
                  transform="translate(0.474609)"
                />
              </clipPath>
            </defs>
          </svg>
        }
      >
        목록 수정하기
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={{ width: "max-content" }}
        >
          <TransferList closeModal={() => setOpen(false)} />
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
