import {
  CodeResponse,
  useGoogleLogin as useGoogleLogin2,
} from "@react-oauth/google";
import axios from "axios";
import { GOOGLE_CLIENTID, SECRET_GOOGLE_CLIENTID } from "config";
import useGetEventFromApp from "hooks/useGetEventFromApp";
import useIsInappWebview from "hooks/useIsInappWebview";
import qs from "qs";
import { useEffect, useState } from "react";
import { sendMessage } from "utils/webview";
import useLogin from "./useLogin";

export default function useGoogleLogin() {
  const isInappWebview = useIsInappWebview();
  const clientId = GOOGLE_CLIENTID;
  const secret_clientId = SECRET_GOOGLE_CLIENTID;
  const origin = window.location.origin;

  const [idToken, setIdToken] = useState<string>();

  const handleGoogleLoginApp = () => {
    sendMessage(JSON.stringify({ event: "google_login" }));
  };

  const parsedMessageFromApp = useGetEventFromApp();

  useEffect(() => {
    if (parsedMessageFromApp?.event === "google_idToken") {
      const idToken = parsedMessageFromApp.params.value;
      setIdToken(idToken);
    }
  }, [parsedMessageFromApp]);

  const login = useLogin({
    url: `/user/login`,
    requestBody: JSON.stringify({
      idToken: idToken,
      type: "google",
    }),
  });

  useEffect(() => {
    if (idToken) {
      login();
    }
  }, [idToken]);

  const getIdToken = async (
    code: string,
    clientId: string,
    secret_clientId: string
  ) => {
    try {
      const response = await axios.post(
        `https://oauth2.googleapis.com/token`,
        qs.stringify({
          code: code,
          client_id: clientId,
          client_secret: secret_clientId,
          redirect_uri: origin,
          grant_type: "authorization_code",
        })
      );
      if (response) {
        const idToken = response.data.id_token;
        setIdToken(idToken);
      }
    } catch (error) {
      console.error("result", error);
    }
  };

  const getCredentialCustom = (codeResponse: CodeResponse) => {
    getIdToken(codeResponse.code, clientId, secret_clientId);
  };

  const handleGoogleLoginWeb = useGoogleLogin2({
    onSuccess: (codeResponse) => getCredentialCustom(codeResponse),
    flow: "auth-code",
    ux_mode: "popup",
    redirect_uri: origin,
  });

  return isInappWebview ? handleGoogleLoginApp : handleGoogleLoginWeb;
}
