import { Stack } from "@mui/joy";
import { ReferenceLinkType } from "api/conceptApi";
import Link from "components/common/Link";
import ReferenceLink from "components/conceptNote/ReferenceLink";
import { useState } from "react";
import { motion } from "framer-motion";
import NewReference from "./NewReference";

export default function ReferenceList({
  conceptType,
  referenceList,
}: {
  conceptType: string;
  referenceList?: ReferenceLinkType[];
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAdded, setIsAdded] = useState<boolean>(false);

  return (
    <Stack spacing={0.5} sx={{ width: "100%" }}>
      <Stack sx={{ flex: 1 }} alignItems="start">
        <Link
          text={`근거 ${isOpen ? "닫기" : "보기"}`}
          opacity={1}
          onClick={() => {
            setIsOpen((isOpen) => !isOpen);
          }}
          endDecorator={
            <span
              style={{ fontSize: "inherit" }}
              className="material-symbols-rounded"
            >
              {isOpen ? "expand_less" : "expand_more"}
            </span>
          }
        />
      </Stack>

      <motion.div
        initial={false}
        animate={{
          height: isOpen ? "fit-content" : 0,
          opacity: isOpen ? 1 : 0,
          pointerEvents: isOpen ? "auto" : "none",
        }}
      >
        <Stack sx={{ width: "100%" }} alignItems="start">
          {referenceList?.map(({ reference, thoughtRecordKey }, index) => (
            <ReferenceLink
              key={`${conceptType}_reference_${index}`}
              reference={reference}
              thoughtRecordKey={thoughtRecordKey}
            />
          ))}

          {!isAdded ? (
            <Link
              text={""}
              opacity={0.7}
              onClick={() => {
                setIsAdded(true);
              }}
              startDecorator={
                <span
                  style={{ fontSize: "16px", height: "24px" }}
                  className="material-symbols-rounded"
                >
                  add
                </span>
              }
            />
          ) : (
            <NewReference conceptType={conceptType} setIsAdded={setIsAdded} />
          )}
        </Stack>
      </motion.div>
    </Stack>
  );
}
