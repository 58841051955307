import useIsInappWebview from "hooks/useIsInappWebview";
import { useEffect } from "react";
import { sendMessage } from "utils/webview";
import { BUILD_TARGET } from "../config";

export default function useKakaoLogin() {
  const isInappWebview = useIsInappWebview();
  const handleKakaoLoginApp = () => {
    sendMessage(JSON.stringify({ event: "kakao_login" }));
  };

  const { Kakao } = window;

  useEffect(() => {
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(process.env.REACT_APP_KAKAO_API_KEY);
    }
  }, [Kakao]);

  const handleKakaoLoginWeb = () => {
    const origin = window.location.origin;
    Kakao.Auth.authorize({
      redirectUri: `${origin}/oauth/kakao`,
      scope:
        BUILD_TARGET === "real"
          ? "account_email,openid,profile_nickname,phone_number,profile_image,plusfriends"
          : "account_email,openid,profile_nickname,phone_number,profile_image",
    });
  };

  return isInappWebview ? handleKakaoLoginApp : handleKakaoLoginWeb;
}
