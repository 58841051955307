import { Box, Stack, Typography } from "@mui/joy";
import React from "react";
import { useNavigate } from "react-router-dom";

const HomeIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 9L8.5 3L15 9"
        stroke="#222222"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 7.5V14H13V7.5"
        stroke="#222222"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 10.5C7 9.67157 7.67157 9 8.5 9V9C9.32843 9 10 9.67157 10 10.5V14H7V10.5Z"
        stroke="#222222"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default function Breadcrumb({
  items,
}: {
  items: {
    iconType?: "home";
    text?: string;
    icon?: React.ReactNode;
    stepBack: number;
  }[];
}) {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      alignItems={"center"}
      spacing="4px"
      sx={{ opacity: "0.6" }}
    >
      {items.map(({ iconType, text, stepBack }, index) => (
        <React.Fragment key={`breadcrumb_${text}`}>
          <Box
            sx={{
              cursor: "pointer",
              "&:hover": {
                opacity: 0.5,
              },
            }}
          >
            {iconType === "home" ? (
              <div
                onClick={() => {
                  if (stepBack > 0) {
                    navigate(-stepBack);
                  }
                }}
              >
                <HomeIcon />
              </div>
            ) : (
              <Typography
                sx={{ fontSize: "14px", fontWeight: 500, color: "#222222" }}
                onClick={() => {
                  if (stepBack > 0) {
                    navigate(-stepBack);
                  }
                }}
              >
                {text}
              </Typography>
            )}
          </Box>

          {index < items.length - 1 && (
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500, color: "#222222" }}
            >
              /
            </Typography>
          )}
        </React.Fragment>
      ))}
    </Stack>
  );
}
