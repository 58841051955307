import { translation_selfCheck } from "../selfCheck";

export const translation_2_3 = {
  en: {},
  ko: {
    v1: {
      typography_9_0_0:
        "앞으로 2주에 한 번씩 부정적인 반응이 얼마나 줄고 있는지 트래킹해 나갈 거예요.",
      typography_10_0_0:
        "그 감소 정도를 '우울', '불안', '스트레스', 이렇게 세 카테고리로 관찰해 나갈 겁니다.",
      typography_14_0_0:
        "물론 이 세 카테고리가 서로 명확한 경계로 나눠지는 것은 아니지만, 우리 현대인들의 일상과 가장 밀접한 대표적인 부정적 반응이라 보시면 돼요.",
      typography_15_0_0: "이제 첫 번째 관찰을 해볼 건데요.",
      typography_16_0_0:
        "그전에 꼭 하나 알아두셔야 할 것이 있어 잠시 살펴보고 갈게요.",
      typography_17_0_0: "거리두기는 하나의 능력이에요",
      typography_18_0_0:
        "앞으로 2주마다 할 검사는 특정 정신질환이 있고 없음을 이야기하지 않아요.",
      typography_19_0_0:
        "우리는 질환의 유무가 아닌, 거리두기라는 능력의 많고 적음을 이야기할 거예요.",
      typography_20_0_0:
        "누군가는 거리두기를 잘 할 줄 알아 우울감에 덜 영향받을 수 있고, 그렇지 않은 사람은 보다 더 영향을 받을 뿐이죠.",
      typography_21_0_0:
        "마치 다리 근육이 발달한 사람은 남들보다 빨리 달릴 수 있고, 그렇지 않은 사람은 조금 느리게 달리는 것처럼요.",
      typography_22_0_0:
        "그렇기 때문에 앞으로 우리가 체크할 우울, 불안, 스트레스는 정신질환이 있고 없고를 말하는 것이 아님을 기억해주세요.",
      typography_23_0_0:
        "거리두기 능력을 얼마나 함양해 나가고 있는지 측정해 나가는 것이랍니다.",
      typography_24_0_0: "그럼 시작해보죠. 한 2분 정도 걸릴 거예요.",
      ...translation_selfCheck.ko.v1,
    },
  },
};