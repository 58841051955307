import { Stack, Textarea, Typography } from "@mui/joy";
import { ConversationMessageType } from "api2/toolApi";
import Button from "./Button";
import * as _ from "lodash";
import ButtonWithPopup from "./ButtonWithPopup";
import { Lottie } from "components/common/Lottie";
import Loading from "static/lottie/tool/thought-record/conversation_loading.json";

export default function MessageList({
  title,
  titleStartDecorator,
  messageList,
  userMessage,
  setUserMessage,
  isShowUserTextarea,
  continueAction,
  completeAction,
  isCompleted,
  isLoading,
  canStop,
}: {
  title: string;
  titleStartDecorator: React.ReactNode;
  messageList?: ConversationMessageType[];
  userMessage: string;
  setUserMessage: React.Dispatch<React.SetStateAction<string>>;
  isShowUserTextarea: boolean;
  continueAction: () => void;
  completeAction: () => void;
  isCompleted: boolean;
  isLoading: boolean;
  canStop: boolean;
}) {
  return (
    <Stack spacing="16px">
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 900,
          color: "white",
        }}
        startDecorator={titleStartDecorator}
      >
        {title}
      </Typography>
      {messageList &&
        messageList.map(({ role, message, messageId }, index) => (
          <Typography
            key={`message_${messageId}`}
            sx={{
              fontSize: "16px",
              color: role === "assistant" ? "#87E59B" : "white",
            }}
          >
            {message}
          </Typography>
        ))}
      {!isCompleted &&
        (isLoading ? (
          <>
            <Lottie
              src={Loading}
              containerWidth={"32px"}
              originalColor="#FFFFFF"
              newColor="#87E59B"
            />
          </>
        ) : (
          <Stack spacing="16px">
            {isShowUserTextarea && (
              <Textarea
                placeholder={"자유롭게 적어주세요"}
                value={userMessage}
                onChange={(e) => {
                  let newValue = e.target.value.replace("\n", "");
                  setUserMessage(newValue);
                }}
                minRows={1}
                sx={{
                  width: "100%",
                  fontSize: "16px",
                  color: "white",
                  padding: 0,
                  backgroundColor: "transparent",
                  borderWidth: 0,
                  boxShadow: "none",
                  "--joy-focus-thickness": "0px",
                  "--variant-borderWidth": "0px",
                  "--Textarea-gap": "0px",
                  minWidth: 0,
                  minHeight: "24px",
                }}
              ></Textarea>
            )}
            <Stack direction="row" spacing="8px">
              {isShowUserTextarea && (
                <Button
                  size="sm"
                  buttonText="계속하기"
                  onClick={continueAction}
                  color="gradient-green"
                  disabled={userMessage.length === 0}
                />
              )}
              {canStop && (
                <ButtonWithPopup
                  size="sm"
                  buttonText="마치기"
                  color="transparent"
                  action={completeAction}
                  actionText={"마치기"}
                  isDanger
                  cancelText={"취소"}
                  title={"여기까지 할까요?"}
                  subtitle={"마친 기록은 더 문답할 수 없어요."}
                />
              )}
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
}
