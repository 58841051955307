import { useCallback, useEffect, useState } from "react";
import { generateRecordKey } from "logic/logics";
import { useMutation } from "@tanstack/react-query";
import * as _ from "lodash";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { ProgramType } from "data/programData";
import useGetBlankMeditationRecordData from "./useGetBlankMeditationRecordData";
import { meditationRecordApi } from "api/meditationRecordApi";

export default function useSaveMeditationRecordWithRawContent({
  type,
}: {
  type: string;
}) {
  const patientState = useRecoilValue(patientAtom);

  const [meditationRecordKey] = useState<string>(
    generateRecordKey(patientState?.patientId || "", "meditation")
  );

  const blankData: ProgramType = useGetBlankMeditationRecordData({
    mrk: meditationRecordKey,
    isFromThoughtRecord: true,
  }) as ProgramType;

  const [data, setData] = useState<ProgramType>(blankData);

  useEffect(() => {
    if (blankData && blankData.content) {
      const data_temp = _.cloneDeep(blankData);
      const copingOrRoutineBlock = blankData.content.findIndex((element) =>
        element.lines
          .flat()
          .find((element) => element.content.id === "coping_or_routine")
      );
      if (
        copingOrRoutineBlock > -1 &&
        data_temp.content[copingOrRoutineBlock] &&
        data_temp.content[copingOrRoutineBlock].lines
      ) {
        const lineIndex = blankData.content[
          copingOrRoutineBlock
        ].lines.findIndex((element) =>
          element
            .flat()
            .some((element) => element.content.id === "coping_or_routine")
        );
        const cellIndex = 0;
        if (
          data_temp.content[copingOrRoutineBlock].lines[lineIndex] &&
          data_temp.content[copingOrRoutineBlock].lines[lineIndex][cellIndex]
        ) {
          const originalContent =
            data_temp.content[copingOrRoutineBlock].lines[lineIndex][cellIndex]
              .content;
          data_temp.content[copingOrRoutineBlock].lines[lineIndex][
            cellIndex
          ].content = {
            ...originalContent,
            ...{
              selectedIndex: 0,
              value: "지금 느끼는 괴로움을 다스리러 왔어요",
            },
          };
        }
      }

      [{ id: "coping" }].forEach(({ id }) => {
        const blockIndex = blankData.content.findIndex((element) =>
          element.lines.flat().find((element) => element.content.id === id)
        );
        if (
          blockIndex > -1 &&
          data_temp.content[blockIndex] &&
          data_temp.content[blockIndex].lines
        ) {
          const lineIndex = blankData.content[blockIndex].lines.findIndex(
            (element) =>
              element.flat().some((element) => element.content.id === id)
          );
          const cellIndex = 0;
          if (
            data_temp.content[blockIndex].lines[lineIndex] &&
            data_temp.content[blockIndex].lines[lineIndex][cellIndex]
          ) {
            const currentContent =
              data_temp.content[blockIndex].lines[lineIndex][cellIndex].content;
            data_temp.content[blockIndex].lines[lineIndex][cellIndex].content =
              {
                ...currentContent,
                ...{ value: type },
              };
            for (let i = 0; i <= blockIndex; i++) {
              data_temp.content[i].isShown = true;
            }
          }
        }
      });

      setData(data_temp);
    }
  }, [type]);

  const saveQueryFn = useCallback(
    () =>
      meditationRecordApi.saveData(
        `${meditationRecordKey}`,
        `${patientState?.patientId}`,
        data
      ),
    [data]
  );

  const { mutate: saveMeditationRecordData } = useMutation(saveQueryFn, {
    onSuccess: () => {},
  });

  const save = () => {
    if (type && type.length > 0) {
      saveMeditationRecordData();
    }
  };

  return save;
}
