export const translation_9_6_A = {
  en: {},
  ko: {
    v1: {
      typography_0: `다음으로 ‘기꺼이 경험하기’를 적용해 볼 부분은 감정이에요.`,
      typography_1: `많은 분들이 감정을 자세히 관찰하는 데에 어려움을 겪는데요.`,
      typography_2: `감정을 자세히 관찰하기 어려운 가장 큰 이유는 감정이 강렬하기 때문이에요.`,
      typography_3: `그렇기 때문에 그것을 마주하며 자세히 관찰하기보다는 ‘추상적인 느낌'으로 얼버무릴 때가 많답니다.`,
      typography_4: `주로 “우울함", “짜증남", “불안함", “행복함" 정도로 이야기하곤 하시죠.`,
      typography_5: `하지만 우리는 ‘기꺼이 경험하기'를 연습하고 있어요.`,
      typography_6: `그러니 지금부터는 감정을 더 자세히 관찰해볼 거랍니다.`,
      typography_7: `물론 그 과정에서 힘든 순간이 있다면 앞서 배웠던 ‘심호흡 그라운딩', ‘근육이완법', ‘호흡 명상’을 활용해보면 좋답니다.`,
      typography_8: `감정을 잘 관찰하기 위해서는 무슨 감정이 있는지 더 잘 알아야겠죠?`,
      typography_9: `앞으로는 <생각기록지>에서 분노, 혐오감, 부러움, 두려움, 행복, 질투, 사랑, 슬픔, 수치감, 죄책감, 총 10개 영역에서 감정 단어를 제공할 거예요.`,
      typography_10: `각 영역에는 세분화된 감정 단어들이 포함되어 있을 텐데요.`,
      typography_11: `상황과 생각까지 적은 후, 그 상황에서 그런 생각이 떠오른 자신을 관찰했을 때 어떤 감정이 느껴지는지 선택해보면 된답니다.`,
      typography_12: `이렇게까지 감정을 세세하게 선택하려면, 역시나 불가피하게 감정을 기꺼이 경험하며 관찰할 수밖에 없답니다.`,
      typography_13: `꼭 기억하세요.`,
      typography_14: `감정을 없애려고 애쓰지 않고,`,
      typography_15: `감정을 억지로 버티려고 하지 않고,`,
      typography_16: `어떠한 목적이나 결과를 바라지 않고 기꺼이 경험하며 새로운 관계를 맺으면,`,
      typography_17: `고통은 피할 수 없지만 괴로움은 선택할 수 있답니다.`,
      typography_18: `처음엔 쉽진 않겠지만, 적극적인 관찰자가 되어 스스로를 바라보다 보면 나도 몰랐던 새로운 경험들을 마주할 수 있을 거예요.`,
      typography_19: `심지어는 그 과정이 재밌어지는 순간도 있을 거랍니다.`,
      typography_20: `자, 그러면 오늘 배운 내용을 토대로 기록을 작성해볼까요?`,
    },
  },
};
