import { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import {
  dashboardApi,
  DashboardType,
  DASHBOARD_DETAIL_ENDPOINT,
  DASHBOARD_DOMAIN,
} from "api/dashboardApi";
import { useQuery } from "@tanstack/react-query";
import ThoughtMap from "components/thoughtmap/ThoughtMap";
import { ConceptKeyType } from "api/conceptApi";

export default function ThoughtmapCell({
  from,
  to,
  fixedValue,
}: {
  from?: ConceptKeyType;
  to?: ConceptKeyType;
  fixedValue?: { [key in ConceptKeyType]?: string };
}) {
  const patientState = useRecoilValue(patientAtom);

  const queryFn = useCallback(
    () => dashboardApi.load(`${patientState?.patientId}`),
    [patientState]
  );
  const [dashboardData, setDashboardData] = useState<DashboardType>();

  useQuery([DASHBOARD_DOMAIN, DASHBOARD_DETAIL_ENDPOINT], queryFn, {
    enabled: !!patientState?.patientId,
    onSuccess: (data) => {
      setDashboardData(data.result);
    },
  });

  return dashboardData?.concept ? (
    <ThoughtMap
      data={dashboardData?.concept}
      from={from}
      to={to}
      fixedValue={fixedValue}
    />
  ) : (
    <></>
  );
}
