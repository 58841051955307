import { Stack, Typography } from "@mui/joy";
import dayjs from "dayjs";

const UndoneIcon = () => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66797 8.54736L8.76226 1.45307"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M8.76172 8.54736L1.66742 1.45307"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

const DoneIcon = () => {
  return (
    <svg
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4077 1.90587L4.31363 9.00017L1.31363 6.00017"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default function WeekTracking({
  goalDayOfWeekList,
  historyList,
}: {
  goalDayOfWeekList?: number[];
  historyList?: { date: string; isDone: boolean }[];
}) {
  function getDayOfWeek(number: number) {
    const dayIndex = (number - 0) % 7;

    // 요일 이름을 가져옵니다.
    return dayjs().day(dayIndex).format("dd");
  }

  const todayDayIndex = dayjs().day();
  const weekData = Object.fromEntries(
    historyList?.map(({ date, isDone }) => [dayjs(date).day(), isDone]) || [[]]
  );

  return (
    <Stack direction="row" spacing="space-between" sx={{ width: "100%" }}>
      {Array.from({ length: 7 }, (v, i) => i + 1).map((dayNumber) => (
        <Stack
          key={`dayNumber_${dayNumber}`}
          sx={{ flex: 1 }}
          spacing="9px"
          justifyContent={"center"}
          alignItems="center"
        >
          <Typography
            sx={{
              fontSize: "12px",
              ...(goalDayOfWeekList?.includes(dayNumber)
                ? {
                    color: "white",
                    fontWeight: 700,
                    px: "4px",
                    backgroundColor:
                      dayNumber < todayDayIndex && !weekData[dayNumber]
                        ? "#9CA9AE"
                        : "primary.solid",
                    borderRadius: "4px",
                  }
                : { opacity: 0.3 }),
            }}
          >
            {dayNumber === todayDayIndex ? "오늘" : getDayOfWeek(dayNumber)}
          </Typography>

          <Stack
            justifyContent={"center"}
            alignItems="center"
            sx={{
              width: "28px",
              height: "28px",
              borderRadius: "50%",
              ...(goalDayOfWeekList?.includes(dayNumber)
                ? {
                    ...(dayNumber < todayDayIndex
                      ? !weekData[dayNumber]
                        ? { backgroundColor: "#9CA9AE" }
                        : { backgroundColor: "primary.solid" }
                      : {
                          backgroundColor: "white",
                          borderWidth: "2px",
                          borderColor: "primary.solid",
                          ...(dayNumber === todayDayIndex &&
                            weekData[dayNumber] && {
                              backgroundColor: "primary.solid",
                            }),
                        }),

                    fontWeight: 700,
                    px: "4px",
                  }
                : { backgroundColor: "#E4EAED" }),
            }}
          >
            {goalDayOfWeekList?.includes(dayNumber) &&
              dayNumber <= todayDayIndex &&
              (!weekData[dayNumber] ? <UndoneIcon /> : <DoneIcon />)}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
