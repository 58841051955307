import { Content_101_1_3_T_fromTask } from "data/task/v1/101-1-3-T";
import { getTaskIdFromTaskKey } from "logic/logics";
import getBlocksFromSuccessfulActivity from "./getBlocksFromSuccessfulActivity";

export default async function get_succeededActivityRecord_RawData(
  taskKey: string
) {
  try {
    const response = await getBlocksFromSuccessfulActivity(taskKey);

    // 비동기 작업이 완료된 후에 결과를 반환
    console.log("from task:", response);

    return {
      taskId: `101-1-3-T`,
      content: Content_101_1_3_T_fromTask(
        getTaskIdFromTaskKey(taskKey),
        response || []
      ),
    };
  } catch (error) {
    // 에러 핸들링
    console.error("Error in get_6_5_A_RawData:", error);
    throw error; // 또는 에러에 대한 처리를 진행할 수 있음
  }
}
