import { Instruction } from "../../BlockComponent";

import { LastCard } from "../common";

export const Content_9_6_A = [
  Instruction(`typography_0`),
  Instruction("typography_1"),
  Instruction(`typography_2`),
  Instruction(`typography_3`),
  Instruction(`typography_4`),
  Instruction(`typography_5`),
  Instruction(`typography_6`),
  Instruction(`typography_7`),
  Instruction(`typography_8`),
  Instruction(`typography_9`),
  Instruction(`typography_10`),
  Instruction(`typography_11`),
  Instruction(`typography_12`),
  Instruction(`typography_13`),
  Instruction(`typography_14`),
  Instruction(`typography_15`),
  Instruction(`typography_16`),
  Instruction(`typography_17`),
  Instruction(`typography_18`),
  Instruction(`typography_19`),
  Instruction(`typography_20`),

  ...LastCard,
];
