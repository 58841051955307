import dayjs from "dayjs";
import "dayjs/locale/ko";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import * as _ from "lodash";

export function arraySum(arr: number[]) {
  return arr.reduce(function add(sum, currValue) {
    return sum + currValue;
  }, 0);
}

export function getTimeDifference(completedAt: string) {
  dayjs.extend(relativeTime, {
    thresholds: [
      { l: "s", r: 59, d: "second" },
      { l: "ss", r: 4, d: "minute" },

      { l: "m", r: 9 },
      { l: "mm", r: 59, d: "minute" },

      { l: "h", r: 1 },
      { l: "hh", r: 23, d: "hour" },

      { l: "d", r: 1 },
      { l: "dd", r: 6, d: "day" },

      { l: "w", r: 1 },
      { l: "ww", r: 4, d: "week" },

      { l: "M", r: 1 },
      { l: "MM", r: 11, d: "month" },
      { l: "y" },
      { l: "yy", d: "year" },
    ],
    rounding: Math.floor,
  });

  dayjs.extend(updateLocale);

  dayjs.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "a few seconds",
      ss: "a few seconds",

      m: "a minute",
      mm: "%d minutes",

      h: "an hour",
      hh: "%d hours",

      d: "a day",
      dd: "%d days",

      w: "a week",
      ww: "%d weeks",

      M: "a month",
      MM: "%d months",
      y: "a year",
      yy: "%d years",
    },
  });

  dayjs.updateLocale("ko", {
    relativeTime: {
      future: "%s",
      past: "%s 전",
      s: "방금",
      ss: "방금",

      m: "1분",
      mm: "%d분",

      h: "%d시간",
      hh: "%d시간",

      d: "%d일",
      dd: "%d일",

      w: "%d주",
      ww: "%d주",

      M: "%d개월",
      MM: "%d개월",
      y: "%d년",
      yy: "%d년",
    },
  });
  return dayjs(completedAt).fromNow();
}

export function getFormettedDate(date: string) {
  return `${dayjs(date).format("MMM D일 ddd요일")}`;
}

export function getRemainingTimeInMilliSeconds({
  endAt,
  startedAt,
  durationHour,
}: {
  endAt?: string;
  startedAt?: string;
  durationHour?: number;
}) {
  const endTime = endAt
    ? dayjs(endAt)
    : dayjs(startedAt).add(dayjs.duration({ hours: durationHour }));
  const now = dayjs();
  return endTime.diff(dayjs(now));
}

export function getRemainingTimeInString({
  endAt,
  startedAt,
  durationHour,
}: {
  endAt?: string;
  startedAt?: string;
  durationHour?: number;
}) {
  const duration = dayjs.duration(
    getRemainingTimeInMilliSeconds({
      endAt: endAt,
      startedAt: startedAt,
      durationHour: durationHour,
    })
  );
  return `${
    parseInt(duration.format("D")) > 0 ? `${duration.format("D")}일 ` : ""
  }${parseInt(duration.format("H")) > 0 ? `${duration.format("H")}시간 ` : ""}${
    parseInt(duration.format("m")) > 0 ? `${duration.format("m")}분 ` : ""
  }`;
}

export function getRemainingMilliSecondsWithStartTime(
  startedAt: string,
  durationHour: number
) {
  const endAt = dayjs(startedAt).add(dayjs.duration({ hours: durationHour }));
  const now = dayjs();
  return endAt.diff(dayjs(now));
}

export function getRemainingTimeWithStartTime(
  startedAt: string,
  durationHour: number
) {
  const duration = dayjs.duration(
    getRemainingMilliSecondsWithStartTime(startedAt, durationHour)
  );
  return `${
    parseInt(duration.format("H")) > 0 ? `${duration.format("H")}시간 ` : ""
  }${
    parseInt(duration.format("m")) > 0 ? `${duration.format("m")}분 ` : ""
  }${duration.format("s")}초`;
}

export function parsePaymentAmount(num: number) {
  let arr = [];
  const string = num.toString();

  for (let i = 0; i <= string.length / 3; i++) {
    arr.unshift(string.slice(-3 * (i + 1), i === 0 ? undefined : -3 * i));
  }
  return arr.filter((element) => element.length > 0).join(",");
}

export function removeDuplicate<T>(array?: T[]): T[] {
  if (!array) return [];
  return array
    .reduce<T[]>((acc, current) => {
      if (!acc.includes(current)) {
        acc.push(current);
      }
      return acc;
    }, [])
    .filter((element) => !!element);
}

// 버전 비교 함수
export const compareVersions = (versionA: string, versionB: string) => {
  const a = versionA.split(".").map(Number);
  const b = versionB.split(".").map(Number);

  for (let i = 0; i < Math.max(a.length, b.length); i++) {
    if (a[i] === undefined || a[i] < b[i]) {
      return -1;
    } else if (a[i] > b[i]) {
      return 1;
    }
  }

  return 0;
};
