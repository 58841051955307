import { Button, Stack, Typography } from "@mui/joy";
import { CheckLottie } from "components/common/CheckLottie";
import { selfCheckData, SelfCheckIdType } from "data/selfCheckData";
import { arraySum } from "logic/logics_common";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { selfCheckAtom } from "recoil/selfCheckAtom";

export default function SelfCheckEnd() {
  let { tool } = useParams();
  const type = (Object.entries(selfCheckData).find(
    ([key, value]) => value.screeningTool === tool
  ) || [])[0];
  const selfCheckState = useRecoilValue(selfCheckAtom);

  const [resultLink, setResultLink] = useState<string>();

  useEffect(() => {
    const scoreList = Object.entries(selfCheckState || [])
      .filter(([key, value]) => key.includes(`${type}`))
      .map(([key, value]) => value.score);
    console.log(scoreList, arraySum(scoreList));

    const score = arraySum(scoreList);
    const resultLink = selfCheckData[type as SelfCheckIdType].result.find(
      (element) => score >= element.range[0] && score <= element.range[1]
    )?.resultLink;

    setResultLink(`${resultLink}?totalScore=${score}`);
  }, [selfCheckState]);

  return (
    <Stack
      sx={{
        width: "100%",
        height: `calc(var(--vh,1vh) * 100)`,
        py: "20px",
        px: "24px",
        maxWidth: "720px",
        mx: "auto",
      }}
      justifyContent="center"
    >
      <Stack
        sx={{ flex: 1 }}
        justifyContent="center"
        alignItems={"center"}
        spacing={2}
      >
        <CheckLottie containerSize={"72px"} />
        <Stack justifyContent="center" alignItems={"center"} spacing={1}>
          <Typography level="h4">{"검사를 마쳤어요"}</Typography>
          <Typography sx={{ opacity: 0.8 }} level="body-md">
            {"검사 결과를 보러 갈까요?"}
          </Typography>
        </Stack>
      </Stack>
      <Button
        size="lg"
        disabled={!resultLink}
        onClick={() => {
          window.open(resultLink, "_blank");
        }}
        sx={{
          borderRadius: "10px",
          backgroundColor: "#232832",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: `#232832`,
            color: "#ffffff",
            opacity: 0.6,
          },
          "&:focus": {
            backgroundColor: "#232832",
          },
          opacity: !resultLink ? 0.3 : 1,
        }}
      >
        결과 보기
      </Button>
    </Stack>
  );
}
