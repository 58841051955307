import * as _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { ProgramContentType } from "data/BlockComponent";
import TextareaCell from "./TextareaCell";
import { useMutation } from "@tanstack/react-query";
import { Stack } from "@mui/joy";
import { activityApi } from "api/activityApi";
import Link from "components/common/Link";

export default function AddActivityPlanCell({
  taskKey,
  ark,
  placeholder,
  initialValue,
  isCoach,
  blockIndex,
  setData,
  lineIndex,
  cellIndex,
  editorKey,
}: {
  taskKey?: string;
  ark?: string;
  placeholder?: string;
  initialValue?: string;
  isCoach?: boolean;
  blockIndex: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  editorKey?: string;
}) {
  const [value, setValue] = useState<string | undefined>(initialValue);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);

  useEffect(() => {
    if (value === initialValue) {
      setShowSaveButton(false);
    } else {
      setShowSaveButton(true);
    }
  }, [value, initialValue]);

  const addActivityPlanQueryFn = useCallback(
    () => activityApi.addPlanCandidate(`${ark}`, `${initialValue}`),
    [ark, initialValue]
  );

  const { mutate: addActivityPlan } = useMutation(addActivityPlanQueryFn, {
    onSuccess: () => {
      setValue(initialValue);
    },
  });

  return (
    <Stack spacing={0.5} alignItems="end">
      <TextareaCell
        placeholder={placeholder}
        initialValue={initialValue}
        isCoachField
        isCoach={isCoach}
        setData={setData}
        lineIndex={lineIndex}
        cellIndex={cellIndex}
        blockIndex={blockIndex}
        editorKey={editorKey}
        // optional
      />
      {showSaveButton && (
        <Link
          text="저장"
          opacity={1}
          textColor="primary.solid"
          onClick={addActivityPlan}
        />
      )}
    </Stack>
  );
}
