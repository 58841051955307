import { Button, Stack, Typography } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { layoutAtom } from "recoil/layoutAtom";
import { isMobile } from "react-device-detect";
import { extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";

function StatisticsIcon() {
  const color = extractHexColor(theme.vars.palette.secondary.solid);
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 9.5V12.7308"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M11 6.5V12.7308"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M15 4.5V12.7308"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path
        d="M17 15.5H3V4.5"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default function OpenStatisticsButton({
  isOpen,
  open,
}: {
  isOpen: boolean;
  open: () => void;
}) {
  const { isTaskContainerSmall } = useRecoilValue(layoutAtom);

  return (
    // <Stack
    //   direction={isTaskContainerSmall ? "column" : "row"}
    //   spacing={isTaskContainerSmall ? "6px" : "10px"}
    //   alignItems="center"
    //   justifyContent={"center"}
    //   sx={{
    //     flexShrink: 0,
    //     cursor: "pointer",
    //     ...(isMobile
    //       ? {
    //           "&:active": {
    //             opacity: 0.5,
    //           },
    //         }
    //       : {
    //           "&:hover": {
    //             opacity: 0.5,
    //           },
    //         }),
    //     ...(isTaskContainerSmall
    //       ? {
    //           width: "50px",
    //           height: "69px",
    //           ...(isOpen && { backgroundColor: "primary.soft" }),
    //         }
    //       : { width: "100%", height: "46px" }),
    //     whiteSpace: "nowrap",
    //     borderRadius: "6px",
    //     ...(!isTaskContainerSmall && {
    //       borderWidth: "1px",
    //       borderColor: "divider",
    //     }),
    //   }}
    //   onClick={open}
    // >
    //   <StatisticsIcon />
    //   <Typography
    //     color="primary"
    //     sx={{
    //       fontSize: isTaskContainerSmall ? "14px" : "16px",
    //       fontWeight: isTaskContainerSmall ? 600 : 700,
    //     }}
    //   >
    //     통계
    //   </Typography>
    // </Stack>
    isTaskContainerSmall ? (
      <Stack
        direction={"column"}
        spacing={"6px"}
        alignItems="center"
        justifyContent={"center"}
        sx={{
          flexShrink: 0,
          cursor: "pointer",
          ...(isMobile
            ? {
                "&:active": {
                  opacity: 0.5,
                },
              }
            : {
                "&:hover": {
                  opacity: 0.5,
                },
              }),

          width: "50px",
          height: "69px",
          ...(isOpen && { backgroundColor: "primary.soft" }),

          whiteSpace: "nowrap",
          borderRadius: "6px",
        }}
        onClick={open}
      >
        <StatisticsIcon />
        <Typography
          sx={{
            color: extractHexColor(theme.vars.palette.secondary.solid),
            fontSize: isTaskContainerSmall ? "14px" : "16px",
            fontWeight: isTaskContainerSmall ? 600 : 700,
          }}
        >
          통계
        </Typography>
      </Stack>
    ) : (
      <Button
        fullWidth
        onClick={open}
        size={"md"}
        variant="outlined"
        color="secondary"
        sx={{
          minWidth: 0,
          paddingInline: 0,
        }}
        startDecorator={<StatisticsIcon />}
      >
        {"통계"}
      </Button>
    )
  );
}
