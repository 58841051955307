import { Box, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { ProgramProgressData2, ProgressNodeData } from "data/programData";
import { useRecoilValue } from "recoil";
import { taskListAtom } from "recoil/taskListAtom";
import { extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";

function ProgressNode({
  dimmed,
  nodeId,
  showTooltip,
  setShowTooltipNodeId,
  deactivatedColor,
  solidColor,
}: {
  dimmed?: boolean;
  nodeId: string;
  showTooltip?: boolean;
  setShowTooltipNodeId: React.Dispatch<React.SetStateAction<string[]>>;
  deactivatedColor: string;
  solidColor: string;
}) {
  const currentModuleData = ProgressNodeData.find(
    (element) => element.nodeId === nodeId
  );

  return (
    <Stack
      justifyContent={"center"}
      alignItems="center"
      sx={{
        position: "relative",
        flexShrink: 0,
        width: "80px",
        height: "80px",
        backgroundColor: dimmed ? deactivatedColor : solidColor,
        borderRadius: "50%",

        ...(!dimmed && {
          boxShadow: `inset 0px -5px 0px rgba(0, 0, 0, 0.1)`,
        }),
      }}
      onClick={() => {
        setShowTooltipNodeId([nodeId]);
      }}
    >
      <img
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "calc(50% - 10px)",
          width: "73px",
        }}
        alt={"empty"}
        src={require(`../../static/image/progress/progress_node_${nodeId}${
          dimmed ? "_dimmed" : ""
        }.png`)}
      />

      {showTooltip && (
        <Stack
          sx={{
            position: "absolute",
            zIndex: 10,
            bottom: "120px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "190px",
            backgroundColor: "white",
            borderRadius: "12px",
            borderWidth: "1px",
            borderColor: "neutral.300",
            p: "16px",
            letterSpacing: "-0.4px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              bottom: "-10px",
              width: "14px",
              height: "10px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                width: "11px",
                height: "1px",
                borderTop: "1px solid white",
                borderLeft: "0.5px solid transparent",
                borderRight: "0.5px solid transparent",
              }}
            />

            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.20543 8.22253L12.9613 0.5H0.89513L5.65098 8.22253C6.23679 9.17378 7.61962 9.17378 8.20543 8.22253Z"
                fill="white"
                stroke={extractHexColor(theme.vars.palette.neutral[300])}
              />
            </svg>
          </Box>
          <Typography sx={{ fontSize: "18px", fontWeight: 700, mb: "6px" }}>
            {currentModuleData?.title}
          </Typography>
          <Typography sx={{ fontSize: "16px" }}>
            {currentModuleData?.subtitle}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

function ProgressbarUnit({
  percentage,
  isBottomRounded,
  width,
  height,
  strokeWidth,
  deactivatedColor,
  solidColor,
}: {
  percentage: number;
  isBottomRounded?: boolean;
  width: number;
  height: number;
  strokeWidth: number;
  deactivatedColor: string;
  solidColor: string;
}) {
  const borderRadius = 100;
  const borderLength =
    2 * (width - 2 * borderRadius) +
    2 * (height - 2 * borderRadius) +
    2 * Math.PI * borderRadius;
  // const coloredLength = (percentage / 100) * (borderLength / 4);
  const coloredLength = 20 + (percentage / 100) * (borderLength / 4 - 30);
  const containerWidth = width + strokeWidth * 2;
  const containerHeight = height + strokeWidth * 2;

  return (
    <Box
      sx={{
        position: "relative",
        width: containerWidth / 2,
        height: containerHeight / 2,
        // boxShadow: `inset 0 0 0 1px #000000`,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          ...(isBottomRounded
            ? { bottom: -strokeWidth / 2, right: -strokeWidth / 2 }
            : { top: -strokeWidth / 2, right: -strokeWidth / 2 }),
        }}
      >
        <svg
          width={containerWidth}
          height={containerHeight}
          viewBox={`0 0 ${containerWidth} ${containerHeight}`}
        >
          <rect
            x={strokeWidth}
            y={strokeWidth}
            width={width}
            height={height}
            rx={borderRadius}
            ry={borderRadius}
            fill="transparent"
            stroke={deactivatedColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        </svg>
      </div>
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          ...(isBottomRounded
            ? { bottom: -strokeWidth / 2, right: -strokeWidth / 2 }
            : { top: -strokeWidth / 2, right: -strokeWidth / 2 }),
        }}
      >
        <svg
          width={containerWidth}
          height={containerHeight}
          viewBox={`0 0 ${containerWidth} ${containerHeight}`}
        >
          <rect
            x={strokeWidth}
            y={strokeWidth}
            width={width}
            height={height}
            rx={borderRadius}
            ry={borderRadius}
            fill="transparent"
            stroke={solidColor}
            strokeWidth={strokeWidth}
            {...(isBottomRounded && {
              strokeDashoffset: -(borderLength / 4),
            })}
            strokeDasharray={`${coloredLength} ${borderLength - coloredLength}`}
            strokeLinecap="round"
          />
        </svg>
      </div>
    </Box>
  );
}

function Progressbar({
  percentageList,
  showTooltipNodeIdList,
  setShowTooltipNodeIdList,
  deactivatedColor,
  solidColor,
}: {
  percentageList: number[];
  showTooltipNodeIdList: string[];
  setShowTooltipNodeIdList: React.Dispatch<React.SetStateAction<string[]>>;
  deactivatedColor: string;
  solidColor: string;
}) {
  const width = Math.min(220, window.innerWidth - 220);
  const height = 695;
  const strokeWidth = 20;
  const containerWidth = width + strokeWidth * 2;
  const containerHeight = height + strokeWidth * 2;

  const sizeData = { width: width, height: height, strokeWidth: strokeWidth };

  const progressNodeList = [
    {
      top: 0,
      left: "50%",
      translate: { x: "-50%", y: `calc(-50% + ${strokeWidth / 2}px)` },
      nodeId: "finding_pattern",
    },
    {
      top: "50%",
      left: 0,
      translate: { x: `calc(-50% + ${strokeWidth / 2}px)`, y: "-50%" },
      nodeId: "creating_space",
    },
    {
      top: "50%",
      right: 0,
      translate: { x: `calc(50% - ${strokeWidth / 2}px)`, y: "-50%" },
      nodeId: "living_by_values",
    },
    {
      bottom: 0,
      left: "50%",
      translate: { x: "-50%", y: `calc(50% - ${strokeWidth / 2}px)` },
      nodeId: "understanding_roots",
    },
    {
      top: `${(1 / 3) * 100}%`,
      left: "50%",
      translate: { x: "-50%", y: `calc(-50% + ${strokeWidth / 2}px)` },
      nodeId: "observing_thoughts",
    },
    {
      top: `${(2 / 3) * 100}%`,
      left: "50%",
      translate: { x: "-50%", y: `calc(-50% + ${strokeWidth / 2}px)` },
      nodeId: "willingly_experience",
    },
  ];
  return (
    <Stack alignItems={"center"}>
      <Stack
        sx={{
          width: containerWidth,
          height: containerHeight,
          position: "relative",
        }}
      >
        <Stack direction={"row"}>
          <Box sx={{ transform: "scaleX(-1)" }}>
            <ProgressbarUnit
              percentage={percentageList[0]}
              deactivatedColor={deactivatedColor}
              solidColor={solidColor}
              {...sizeData}
            />
          </Box>
          <ProgressbarUnit
            percentage={percentageList[5]}
            deactivatedColor={deactivatedColor}
            solidColor={solidColor}
            {...sizeData}
          />
        </Stack>
        <Stack direction={"row"}>
          <Box sx={{ transform: "scaleX(-1)" }}>
            <ProgressbarUnit
              percentage={percentageList[1]}
              deactivatedColor={deactivatedColor}
              solidColor={solidColor}
              isBottomRounded
              {...sizeData}
            />
          </Box>
          <Box>
            <ProgressbarUnit
              percentage={percentageList[6]}
              deactivatedColor={deactivatedColor}
              solidColor={solidColor}
              isBottomRounded
              {...sizeData}
            />
          </Box>
        </Stack>
        {[{ progressIndex: 2 }, { progressIndex: 3 }, { progressIndex: 4 }].map(
          ({ progressIndex }, index) => (
            <Stack
              sx={{
                position: "absolute",
                top: `${(index / 3) * 100}%`,
                left: "50%",
                transform: "translateX(-50%)",
                width: "20px",
                height: `${containerHeight / 3}px`,
                borderRadius: "10px",
                backgroundColor: deactivatedColor,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: `calc(40px + ${
                    (percentageList[progressIndex] / 100) *
                    (containerHeight / 3 - 70)
                  }px)`,
                  borderRadius: "10px",
                  backgroundColor: solidColor,
                  zIndex: 2,
                }}
              ></Box>
            </Stack>
          )
        )}

        {progressNodeList.map(
          ({ top, left, right, bottom, translate, nodeId }, index) => (
            <Box
              sx={{
                position: "absolute",
                zIndex: showTooltipNodeIdList.includes(nodeId) ? 20 : 10,
                top: top,
                left: left,
                right: right,
                bottom: bottom,
                transform: `translate(${translate.x}, ${translate.y})`,
              }}
            >
              <ProgressNode
                dimmed={
                  !ProgramProgressData2.filter(
                    (element) => element.startNodeId === nodeId
                  )
                    .map((each) => each.progressNumber)
                    .some(
                      (progressNumber) => percentageList[progressNumber - 1] > 0
                    )
                }
                nodeId={nodeId}
                showTooltip={showTooltipNodeIdList.includes(nodeId)}
                setShowTooltipNodeId={setShowTooltipNodeIdList}
                deactivatedColor={deactivatedColor}
                solidColor={solidColor}
              />
            </Box>
          )
        )}
      </Stack>
      <Stack
        sx={{
          position: "relative",
          zIndex: 1,
          width: "20px",
          height: "280px",
          borderRadius: "10px",
          backgroundColor: deactivatedColor,
        }}
      >
        {/* 맨밑 */}
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: `calc(30px + ${(percentageList[7] / 100) * 140}px)`,
            borderRadius: "10px",
            backgroundColor: solidColor,
            zIndex: 2,
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: `translateX(-50%)`,
            zIndex: 10,
          }}
        >
          <ProgressNode
            dimmed={percentageList[7] !== 100}
            nodeId={"consistent_practice"}
            showTooltip={showTooltipNodeIdList.includes("consistent_practice")}
            setShowTooltipNodeId={setShowTooltipNodeIdList}
            deactivatedColor={deactivatedColor}
            solidColor={solidColor}
          />
          {percentageList[7] === 100 && (
            <Box
              sx={{
                position: "absolute",
                top: "70px",
                left: "50%",
                transform: `translateX(-50%)`,
                width: "20px",
                height: `80px`,
                background: `linear-gradient(180deg, ${solidColor} 0%, ${"#ffffff"} 100%)`,
                zIndex: 2,
              }}
            />
          )}
        </Box>
      </Stack>
    </Stack>
  );
}
export default function Progress() {
  const taskListState = useRecoilValue(taskListAtom);

  function getPercentage(taskIdPrefixSetList?: string[]) {
    return (
      (((taskIdPrefixSetList || []).findLastIndex((each) =>
        taskListState.some(
          (element) => element.taskId.startsWith(each) && element.isDone
        )
      ) +
        1) /
        (taskIdPrefixSetList || []).length) *
      100
    );
  }
  const percentageList = ProgramProgressData2.map(
    ({ progressNumber, taskIdPrefixList, nextProgressNumber }) => {
      const nextProgressTaskIdPrefixList = ProgramProgressData2.find(
        (element) => element.progressNumber === nextProgressNumber
      )?.taskIdPrefixList;
      if (getPercentage(nextProgressTaskIdPrefixList) > 0) {
        return 100;
      } else {
        return Math.min(getPercentage(taskIdPrefixList), 99);
      }
    }
  );
  const [showTooltipNodeIdList, setShowTooltipNodeIdList] = useState<string[]>(
    []
  );
  useEffect(() => {
    percentageList.forEach((percentage, index) => {
      if (percentage > 0 && percentage < 100) {
        setShowTooltipNodeIdList((nodeId) => [
          ...nodeId,
          ProgramProgressData2[index].startNodeId,
        ]);
      }
    });
  }, []);

  const deactivatedColor = extractHexColor(
    theme.vars.palette.primary.deactivated
  );
  const solidColor = extractHexColor(theme.vars.palette.primary.solid);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography sx={{ fontSize: "20px", fontWeight: 700, mb: "50px" }}>
        프로그램 진행
      </Typography>
      <Stack
        sx={{
          width: "100%",
          pt: showTooltipNodeIdList.includes("finding_pattern")
            ? "150px"
            : "40px",
        }}
        alignItems="center"
      >
        <Progressbar
          percentageList={percentageList}
          showTooltipNodeIdList={showTooltipNodeIdList}
          setShowTooltipNodeIdList={setShowTooltipNodeIdList}
          deactivatedColor={deactivatedColor}
          solidColor={solidColor}
        />
      </Stack>
    </Box>
  );
}
