import { axiosInstance, CustomResponseFormat } from "api";
import { automaticThoughtIdType } from "api/conceptApi";
import { ProgramContentType } from "data/BlockComponent";
import { CellType } from "data/CellComponent";
import { Content_4_9_A } from "data/task/v1/4-9-A";

export default async function get_4_9_A_RawData(
  taskId: string,
  patientId: string
) {
  const automaticThoughtId = taskId
    .split("_")[1]
    .split("#")[0] as automaticThoughtIdType;
  const experimentStep = parseInt(taskId.split("#")[1]);
  try {
    const response = (await axiosInstance.get("/task/data/load", {
      params: { tk: `4-8-A_${automaticThoughtId}:${patientId}` },
    })) as CustomResponseFormat;
    if (response.code === 0) {
      const result = response.result;
      const contentData = result.data.content as ProgramContentType[];
      const cellList: CellType[][][] = contentData?.map(
        (each) => each.lines
      ) as CellType[][][];
      const experiment = cellList
        .flat(2)
        .filter(
          (element) =>
            (element as CellType)?.content.id?.startsWith("step_") &&
            (element as CellType)?.content.value
        );

      return {
        taskId: taskId,
        content: Content_4_9_A(
          automaticThoughtId,
          experiment.length,
          experimentStep
        ),
      };
    } else {
    }
  } catch (error) {
    // 에러 핸들링
    console.error("Error in get_4_9_A_RawData:", error);
    throw error; // 또는 에러에 대한 처리를 진행할 수 있음
  }
}
