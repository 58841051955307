import { translation_meditation } from "./meditation";

export const translation_9_12_A = {
  en: {},
  ko: {
    v1: {
      typography_0: `지금까지 ‘기꺼이 경험하기'를 <생각기록지>에 적용하는 연습을 해보았는데요.`,
      typography_1: `이번에는 ‘기꺼이 경험하기'를 명상에 적용하는 연습을 해보도록 할게요.`,
      typography_2: `우리는 지금까지 두 가지 종류의 명상을 배웠어요.`,
      typography_3: `하나는 생각과 거리를 둘 수 있는 힘을 기르는 명상이었죠.`,
      typography_4: `<생각의 강>, <생각의 하늘>, <생각의 공장>이 그 연습이었어요.`,
      typography_5: `다른 하나는 부정적인 경험을 할 때 사용할 수 있는 도구들이었어요.`,
      typography_6: `<심호흡 그라운딩>, <근육이완법>, <호흡 명상>이 그 도구들이었죠.`,
      typography_7: `두 번째 종류의 명상은 순간 압도되는 감정과 신체 반응, 그리고 생각에서 벗어나는 데에 활용했었는데요.`,
      typography_8: `사실 반복해서 설명해 드렸듯 이것을 ‘없애고’ ,’제거하고', ‘바꾸는 것’ 자체가 우리의 주목적은 아니랍니다.`,
      typography_9: `이러한 도구들은 당장의 압도하는 부정적인 경험을 다스리기 위한 것이었죠.`,
      typography_10: `이들을 다스려 약간의 평정을 되찾았다면, 다음 단계는 그 또한 기꺼이 경험해보는 것이에요.`,
      typography_11: `중요한 건 그 부정적인 경험이 나의 몸과 마음에서는 어떻게 경험되는지 기꺼이 느껴보며 관찰하는 것이랍니다.`,
      typography_12: `그러기 위해서는 우선 ‘몸'의 감각에 집중하는 연습을 먼저 해 볼 필요가 있어요.`,
      typography_13: `오늘은 그 워밍업으로 나의 몸을 느껴보는 간단한 연습을 해보려고 해요.`,
      typography_14: `가벼운 명상 훈련으로 10분 정도가 소요돼요.`,

      ...translation_meditation.ko.v2,

      typography_15: `어땠나요?`,
      typography_16: `처음엔 쉽지 않을 수도 있답니다. 괜찮아요.`,
      typography_17: `앞으로는 부정적인 경험을 할 때마다 이를 몸으로 느껴보는 훈련을 해 볼 테니까요.`,
      typography_18: `다음 활동에서는 그 훈련에 대해 조금 더 자세히 이야기 나눠볼게요.`,
    },
  },
};
