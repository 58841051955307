import { useState } from "react";
import { Stack, Textarea, Typography } from "@mui/joy";
import { useRecoilValue } from "recoil";
import * as _ from "lodash";
import Link from "components/common/Link";
import ButtonWithOptionalModal from "components/common/ButtonWithOptionalModal";
import { userAtom } from "recoil/userAtom";
import { isDev } from "config";

export default function SaveConceptNoteWithReasonButton({
  type,
  aiValue,
  value,
  isRequireModificationReason,
  modificationReason,
  setModificationReason,
  updateAction,
}: {
  type: "textarea" | "select";
  aiValue?: string;
  value?: string;
  isRequireModificationReason?: boolean;
  modificationReason: string;
  setModificationReason: React.Dispatch<React.SetStateAction<string>>;
  updateAction: () => void;
}) {
  const userState = useRecoilValue(userAtom);
  const isManual =
    userState?.role === "coach" && userState.isCoachAiSupportDisabled;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const resetModalState = () => {
    setOpenModal(false);
  };

  return (
    <ButtonWithOptionalModal
      openModal={openModal}
      buttonText=""
      onClose={resetModalState}
      render={<Link text="저장" opacity={1} textColor="primary.solid" />}
      onClick={() => {
        if (isRequireModificationReason && !isManual) {
          setOpenModal(true);
        } else {
          updateAction();
        }
      }}
      content={
        <>
          <Stack>
            {(value || "").length > 0 && (
              <>
                <Typography sx={{ opacity: 0.5 }} level="body-xs">
                  AI가 추천한 문구
                </Typography>
                <Typography level="body-md" sx={{ mb: "10px" }}>
                  {aiValue}
                </Typography>
              </>
            )}
            <Typography sx={{ opacity: 0.5 }} level="body-xs">
              코치가 수정한 문구
            </Typography>
            <Typography level="body-md">
              {type === "select" && value?.length === 0
                ? "잘 모르겠어요"
                : value}
            </Typography>
          </Stack>
          <Textarea
            value={modificationReason}
            onChange={(e) => {
              setModificationReason(e.target.value);
            }}
            sx={{
              borderColor: "background.level3",
              borderWidth: "1px",
              "--joy-focus-thickness": "0px",
              boxShadow: "0px 0px 0px transparent",
            }}
            placeholder={"(수정 이유를 적어주세요)"}
          ></Textarea>
        </>
      }
      actionText={"저장하기"}
      action={() => {
        updateAction();
        resetModalState();
      }}
    />
  );
}
