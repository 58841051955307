import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { generateSegmentationNoteKey } from "logic/logics";
import useOpenSegmentationNote from "./useOpenSegmentationNote";

export default function useOpenNewSegmentationNote() {
  const patientState = useRecoilValue(patientAtom);
  const newSegmentationNoteKey = generateSegmentationNoteKey(
    patientState?.patientId || ""
  );
  return useOpenSegmentationNote(newSegmentationNoteKey);
}