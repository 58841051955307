import Stack from "@mui/joy/Stack";
import CloseButton from "./CloseButton";

export default function TransparentHeader() {
  return (
    <Stack
      direction={"column"}
      sx={{
        width: "100%",
        position: "fixed",
        px: 0,
        height: "var(--StickyHeader-height)",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent="end"
        sx={{
          width: "100%",
          flex: 1,
        }}
      >
        <CloseButton />
      </Stack>
    </Stack>
  );
}
