import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const PAYMENT_DOMAIN = "payment";
export const PAYMENT_ORDER_DETAIL_ENDPOINT = "order/detail";
export const PAYMENT_STEPPAY_REQUEST_ENDPOINT = "steppay/request";
export const PAYMENT_STEPPAY_CONFIRM_ENDPOINT = "steppay/confirm";

export const PAYMENT_MANAGEMENT_DETAIL_ENDPOINT = "management/detail";

interface Offer {
  identifier: string;
  keyIdentifier: string;
  nonce: string;
  signature: string;
  timestamp: number;
}

export interface PaymentOrderType {
  customerName: string;
  customerId: string;
  orderId: string;
  orderName: string;
  price: number;
  productId: string;
  offerId: string;
  offer: Offer;
  firstMonthOfferLinkIos?: string;
  bannerType: number;
  paymentProvider: "in_app" | "steppay";
  // steppayOrderCode?: string;
}
export interface SteppayType {
  steppayOrderCode: string;
}
export interface ManagementDetailType {
  steppaySessionKey: string;
  steppayStoreUuid: string;
}
export interface SteppayConfirmType {
  orderId: string;
  orderCode: String;
}

export const paymentApi = {
  getOrderDetail: () =>
    axiosInstance.get<CustomResponseFormat<PaymentOrderType>>(
      `${PAYMENT_DOMAIN}/${PAYMENT_ORDER_DETAIL_ENDPOINT}`
    ),
  requestSteppay: () =>
    axiosInstance.get<CustomResponseFormat<SteppayType>>(
      `${PAYMENT_DOMAIN}/${PAYMENT_STEPPAY_REQUEST_ENDPOINT}`
    ),

  confirmSteppay: (body: SteppayConfirmType) =>
    axiosInstance.post<CustomResponseFormat>(
      `${PAYMENT_DOMAIN}/${PAYMENT_STEPPAY_CONFIRM_ENDPOINT}`,
      body
    ),

  getManagementDetail: () =>
    axiosInstance.get<CustomResponseFormat<ManagementDetailType>>(
      `${PAYMENT_DOMAIN}/${PAYMENT_MANAGEMENT_DETAIL_ENDPOINT}`
    ),
};
