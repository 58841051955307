import { Header1, Instruction, InstructionWithImage } from '../../BlockComponent'
import { LastCard } from "../common";

export const Content_5_2 = [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  Instruction("typography_2_0_0"),
  InstructionWithImage("typography_3_0_0", "1"),
  InstructionWithImage("typography_4_0_0", "2"),
  Header1("typography_5_0_0"),
  Instruction("typography_6_0_0"),
  Instruction("typography_7_0_0"),
  Instruction("typography_8_0_0"),
  Instruction("typography_9_0_0"),
  Instruction("typography_10_0_0"),
  Instruction("typography_11_0_0"),
  Instruction("typography_12_0_0"),
  Instruction(`typography_13_0_0`),
  Instruction("typography_14_0_0"),
  InstructionWithImage("typography_15_0_0", "3"),
  Instruction("typography_16_0_0"),
  Instruction("typography_17_0_0"),
  Instruction("typography_18_0_0"),
  Instruction("typography_19_0_0"),

  ...LastCard,
];