import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const SELFDISTANCING_DOMAIN = "self-distancing";

export const SELFDISTANCING_THOUGHTRECORD_SUBMIT_ENDPOINT =
  "thought-record/submit";
export const SELFDISTANCING_CONVERSATION_DONE_ENDPOINT = "conversation/done";
export const SELFDISTANCING_MEDITATION_DONE_ENDPOINT = "meditation/done";
export const SELFDISTANCING_THOUGHT_DETAIL_ENDPOINT = "thought/detail";

export interface ThoughtDetailType {
  automaticThought: string;
  alternativeThought: string;
}

export interface SelfDistancingThoughtRecordData {
  emotion: string;
  situation: string;
  thought: string;
}

export const selfDistancingApi = {
  submitThoughtRecord: (
    uuid: string,
    thoughtRecord: SelfDistancingThoughtRecordData
  ) =>
    axiosInstance.post<void>(
      `${SELFDISTANCING_DOMAIN}/${SELFDISTANCING_THOUGHTRECORD_SUBMIT_ENDPOINT}`,
      thoughtRecord,
      { params: { uuid: uuid } }
    ),
  conversationDone: (uuid: string, thread_id: string) =>
    axiosInstance.get<void>(
      `${SELFDISTANCING_DOMAIN}/${SELFDISTANCING_CONVERSATION_DONE_ENDPOINT}`,
      { params: { uuid: uuid, thread_id: thread_id } }
    ),
  meditationDone: (uuid: string, impression: string) =>
    axiosInstance.post<void>(
      `${SELFDISTANCING_DOMAIN}/${SELFDISTANCING_MEDITATION_DONE_ENDPOINT}`,
      { impression: impression },
      { params: { uuid: uuid } }
    ),
  getThoughtDetail: (uuid: string, thread_id: string) =>
    axiosInstance.get<CustomResponseFormat<ThoughtDetailType>>(
      `${SELFDISTANCING_DOMAIN}/${SELFDISTANCING_THOUGHT_DETAIL_ENDPOINT}`,
      { params: { uuid: uuid, thread_id: thread_id } }
    ),
};
