import { useEffect, useState } from "react";
import { ThoughtRecordItemType } from "api/homeApi";
import { menuKeyType } from "./NestedList";
import ActivityList from "./ActivityList";

export default function ThoughtRecordList({
  data,
}: {
  data: ThoughtRecordItemType[];
}) {
  const [listData, setListData] = useState<
    | {
        listTitle: string;
        thoughtRecordList: ThoughtRecordItemType[];
        menuKey: menuKeyType;
        initiallyOpen?: boolean;
      }[]
    | undefined
  >();

  useEffect(() => {
    if (data) {
      const openList = data.filter((element) => element.isOpen);
      const closeList = data.filter((element) => !element.isOpen);
      setListData([
        ...(openList.length > 0
          ? [
              {
                listTitle: "답변할 기록",
                thoughtRecordList: openList,
                menuKey: "thoughtRecordList_open" as menuKeyType,
                initiallyOpen: true,
              },
            ]
          : []),
        ...(closeList.length > 0
          ? [
              {
                listTitle: "최근 기록",
                thoughtRecordList: closeList,
                menuKey: "thoughtRecordList_close" as menuKeyType,
              },
            ]
          : []),
      ]);
    }
  }, [data]);

  return (
    <ActivityList
      title={"생각기록지"}
      isInitialLoading={!data}
      listData={listData}
    />
  );
}
