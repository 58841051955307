import { Stack } from "@mui/joy";
import React from "react";
import FullPageContainer from "./FullPageContainer";

export default function FullPageWithCenterContent({
  content,
}: {
  content: React.ReactNode;
}) {
  return (
    <FullPageContainer hasFooter>
      <Stack
        flexGrow={1}
        justifyContent="center"
        sx={{ width: "100%", maxWidth: "500px", mx: "auto", px: "20px" }}
      >
        {content}
      </Stack>
    </FullPageContainer>
  );
}
