import useDetectIOS from "./useDetectIOS";

export default function useLinkData() {
  const { isIOS } = useDetectIOS();

  const subscriptionCancelLink = isIOS
    ? "https://support.apple.com/ko-kr/118428"
    : "https://support.google.com/googleplay/answer/7018481?hl=ko&co=GENIE.Platform%3DAndroid";

  return {
    subscriptionCancelLink,
  };
}
