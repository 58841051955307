import { automaticThoughtIdType } from "api/conceptApi";
import {
  DimmedLabelLine,
  DividerLine,
  LineWithSolidLabel,
  SolidLabelLine,
  TextareaLine,
  TypographyFromDataLine,
  TypographyLine,
} from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithDataWithContent,
  InstructionWithDataWithTextarea,
  InstructionWithTextarea,
} from "../../BlockComponent";
import {
  Content_4_5_A_end,
  Content_4_5_A_end_type2,
  Content_4_5_A_start,
} from "./4-5-A";
import { AutomaticThought } from "../common";

export const Content_4_5_6_A = (automaticThoughtId: automaticThoughtIdType) => [
  ...Content_4_5_A_start(automaticThoughtId),
  Header1(`typography_0_0_0`),

  InstructionWithContent(
    `typography_1_0_0`,
    [`typography_1_3_0`, `typography_1_5_0`, `typography_1_7_0`].flatMap(
      (each, index) => [
        ...LineWithSolidLabel(`단계`, TypographyLine(each), {
          labelIndex: index + 1,
        }),
      ]
    )
  ),

  InstructionWithContent(
    `typography_2_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),
  Instruction(`typography_3_0_0`),
  InstructionWithTextarea(`typography_4_0_0`),
  InstructionWithTextarea(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),
  Instruction(`typography_7_0_0`),
  InstructionWithTextarea(`typography_8_0_0`),
  InstructionWithTextarea(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),
  Instruction(`typography_12_0_0`),
  Instruction(`typography_13_0_0`),
  Instruction(`typography_14_0_0`),
  Instruction(`typography_15_0_0`),
  Instruction(`typography_16_0_0`),
  InstructionWithDataWithTextarea(
    { A: { id: "생각" } },
    `typographyFromData_17_0_0`
  ),

  InstructionWithDataWithContent(
    { A: { id: "생각" } },
    `typographyFromData_18_0_0`,
    [
      ...[
        [`typography_18_4_0`, `typography_18_6_0`],
        [`typography_18_10_0`, `typography_18_12_0`],
      ].flatMap((each, index) => [
        SolidLabelLine(`예시`, { labelIndex: index + 1 }),
        DimmedLabelLine(`마음속에 떠오른 반추`),
        TypographyLine(each[0]),
        DimmedLabelLine(`거리두기를 통해 바라본 반추`),
        TypographyLine(each[1]),
      ]),
      SolidLabelLine(`나의 반추`),
      DimmedLabelLine(`마음속에 떠오른 반추`),
      TypographyFromDataLine({ A: { id: "생각" } }, "$A$"),
      DimmedLabelLine(`거리두기를 통해 바라본 반추`),
      TextareaLine(),
    ]
  ),

  Instruction(`typography_19_0_0`),

  // ...Content_4_5_A_end_type2(automaticThoughtId, 6),
  ...Content_4_5_A_end(automaticThoughtId, 6),
];
