import { translation_4_5_A } from "./4-5-A";
export const translation_4_5_1_A = {
  en: {
    v1: {
      "통제 가능": "Controllable",
      "통제 불가능": "Uncontrollable",
      ...translation_4_5_A.en.v1.start,
      typography_0_0_0: "Exploring factors",
      typography_1_0_0: "Here's what happened initially.",
      typography_2_0_0:
        "And these are the thoughts that came to my mind in that situation.",
      typographyFromData_3_0_0:
        'So, I believed I contributed to the situation of "$A$" in the following way.',
      typographyFromData_4_0_0:
        "Alright. Now, let's think about other possible factors that could have influenced this situation.",
      typography_5_0_0: "For example, imagine someone else in this situation.",
      typography_5_2_0:
        "I took the lead on a fresh project at work. We pushed hard for half a year, but it didn't pan out in the end.",
      typography_6_0_0:
        "They might have had automatic thoughts popping up like popcorn..",
      typography_6_2_0:
        "It went south because I didn't analyze things right. If I were a better team leader, we could've dodged this outcome.",
      typography_7_0_0:
        "And they might think the cause lies with them for these reasons.",
      typography_7_2_0: "My analysis might not have hit the mark.",
      typography_8_0_0:
        "Of course, they might be partly right, but it's never 100% just because of that, right? What other factors might have contributed to the situation?",
      typography_9_0_0:
        "'The involvement of others' might have played a part. Like, 'the marketing team's support was somewhat lacking' could be a significant factor.",
      typography_10_0_0:
        "'External events' could also have influenced things. In this case, 'a competitor launching a similar project first' might be another contributing factor.",
      typography_11_0_0:
        "'Environmental factors' are important too. 'The economy not being favorable for new projects' could partly explain the situation.",
      typography_12_0_0:
        "And don't forget about 'luck'. Sometimes, luck plays a big role. For instance, 'just by chance, another company started a similar project at the same time' could be a crucial factor.",
      typography_13_0_0: "Now, let's apply this to our own situation.",
      typography_14_0_0:
        'What other factors might have contributed to the situation of "$A$"?',
      typography_15_0_0:
        "We've already considered 'my' factors, so let's jot down others unrelated to 'me'. Anything goes - other people's involvement, external events, environmental factors, luck, etc.",
      typography_16_0_0:
        "Thinking of as many factors as possible can really help us.",
      typographyFromData_17_0_0: `So, let's list the various factors that might have contributed to the situation of "$A$" from a distanced perspective.`,
      typography_18_0_0: "Evaluating contributions",
      typography_19_0_0:
        "Okay. Now let's assess how much each factor contributed to our situation.",
      typographyFromData_20_0_0:
        'If we think of the whole situation as 100%, jot down how much each factor contributed to the situation of "$A$".',
      typography_20_32_0: "Contribution sum must equal 100%.",
      typography_21_0_0:
        "Next, let's examine how much control we had over these factors.",
      typography_22_0_0: `'Controllable factors' are ones we could have managed (e.g., my factors, my mistakes).`,
      typography_23_0_0: `'Uncontrollable factors' are beyond our control (e.g., environmental factors out of our reach, luck, others' mistakes or contributions).`,
      typography_24_0_0:
        "Now, decide whether each factor is 'controllable' or 'uncontrollable'.",
      typography_25_0_0:
        "Great job. Now, let's take a deep breath and move on to the next part.",
      typography_26_0_0: "Stepping back and reflecting",
      typography_27_0_0:
        "Carefully look at the chart and distinguish between what was controllable and what wasn't.",
      typography_28_0_0: "Then, rethink your experience.",
      typographyFromData_29_0_0:
        "It turns out that uncontrollable factors accounted for $A$% of the situation. What do you think about your automatic thoughts after seeing this?",
      typographyFromData_30_0_0:
        "We couldn't have done anything about the uncontrollable parts. Can you accept that $A$% of the situation was out of your hands?",
      typography_31_0_0:
        "Alright. Now, let's consider the contributions again.",
      typography_32_0_0:
        "Examine the chart to see how much each factor contributed.",
      typography_33_0_0: "Then, rethink your experience.",
      typographyFromData_34_0_0:
        "It seems my contribution to the situation was about $A$%. What do you think about your automatic thoughts after seeing this?",
      typographyFromData_35_0_0:
        "  The remaining $eval(100-A)$% were factors beyond my responsibility. Can you accept that $eval(100-A)$% of the situation had nothing to do with me?",

      ...translation_4_5_A.en.v1.end,
    },
  },
  ko: {
    v1: {
      "통제 가능": "통제 가능",
      "통제 불가능": "통제 불가능",
      ...translation_4_5_A.ko.v1.start,
      typography_0_0_0: "요인 살펴보기",
      typography_1_0_0: "우선 다음과 같은 상황이 벌어졌던 거네요.",
      typography_2_0_0:
        "그리고 나는 이 상황에서 다음과 같은 생각이 떠올랐고요.",
      typographyFromData_3_0_0:
        '즉, 나는 "$A$"에 다음과 같은 원인을 제공했다고 생각하네요.',
      typographyFromData_4_0_0:
        "좋아요. 이제 이러한 상황이 발생하는 데에 영향을 줄 수도 있는 다른 요인들을 떠올려볼 거예요.",
      typography_5_0_0:
        "예를 들어볼게요. 아래와 같은 상황을 겪은 사람이 있다고 생각해보세요.",
      typography_5_2_0:
        "회사에서 신규 프로젝트를 발의하여 6개월간 진행했지만 실패하고 말았다.",
      typography_6_0_0:
        "그 사람의 마음에는 다음과 같은 자동적 사고가 떠올랐어요. 팝콘처럼 '탁'하고 튀어올랐죠.",
      typography_6_2_0:
        "내가 분석을 잘못했기 때문에 이런 일이 벌어졌다. 내가 더 유능한 팀장이었으면 이런 일이 벌어지지 않았을 것이다.",
      typography_7_0_0:
        "그 사람은 다음과 같은 이유로 원인이 자신에게 있다고 생각해요.",
      typography_7_2_0: "내가 분석을 잘못했음.",
      typography_8_0_0:
        "물론 어느 정도는 맞는 말일 수도 있을 거예요. 하지만 모든 일이 그렇듯 100% 그것 때문이라고는 할 수 없겠죠. 위와 같은 상황이 벌어지는 데에 기여한 다른 요인은 없을까요?",
      typography_9_0_0:
        "'다른 사람들의 관여'가 영향을 미쳤을 수도 있을 거예요. 예를 들면 '마케팅 팀의 지원도 부족하긴 했다\"라는 것도 중요한 요인이 될 수 있을 거예요.",
      typography_10_0_0:
        "'외부 사건'이 영향을 미칠 수도 있겠죠. 여기서는 \"경쟁사가 비슷한 프로젝트를 먼저 출시해서 앞서 나가고 있었다\"라는 것도 위 상황을 설명하는 다른 요인이 될 수 있겠네요.",
      typography_11_0_0:
        "'환경적 요인'도 중요할 거예요. \"경기가 좋지 않아서 새로운 프로젝트가 성공하기는 쉽지 않았다\"라는 것도 위 상황을 어느 정도는 설명할 수 있을 거예요.",
      typography_12_0_0:
        "'운'도 중요하답니다. 때로는 운이 많은 것을 결정하기도 하니까요. 예를 들면 \"하필 다른 기업에서 동시에 동일한 프로젝트를 시작했다\"라는 것도 중요한 요인이 되겠죠.",
      typography_13_0_0: "자, 그럼 이제 우리의 경우를 살펴볼게요.",
      typography_14_0_0:
        '"$A$"와 같은 상황이 벌어지는 데에 기여할 수 있는 다른 요인들을 찾아볼까요?',
      typography_15_0_0:
        "'나'의 요인은 이미 떠올렸으니, '나'와 관련되지 않은 다른 요인들을 적어보세요. 다른 사람들의 관여, 외부 사건, 환경적 요인, 운, 뭐든 좋아요.",
      typography_16_0_0:
        "다른 요인들은 최대한 많이 떠올리는 게 나에게 도움이 된답니다.",
      typographyFromData_17_0_0:
        '자, 그러면 생각으로부터 거리를 둔 상태에서 "$A$"에 기여한 다양한 요인들을 적어볼까요?',
      typography_18_0_0: "기여분 평가하기",
      typography_19_0_0:
        "좋아요. 이제 각각의 요인이 나의 상황에 기여한 정도를 평가해보죠.",
      typographyFromData_20_0_0:
        '전체를 100%라 했을 때 각각의 요인이 "$A$"에 얼마나 기여했는지 적어보세요.',
      typography_20_32_0: "기여도의 합은 100%가 되어야 합니다.",
      typography_21_0_0:
        "이번에는 각각의 요인들이 얼마나 우리의 통제안에 있었는지 살펴볼게요.",
      typography_22_0_0:
        '통제 가능한 요인이란 "내가 통제할 수 있었던 요인"이에요. (예: 나의 요인, 나의 실수 등)',
      typography_23_0_0:
        '통제 불가능한 요인이란 "내가 통제할 수 없었던 요인"이랍니다. (예: 통제할 수 없었던 환경적 요인, 운, 다른 사람의 실수 및 기여 등)',
      typography_24_0_0:
        '자, 그럼 아래에서 각각의 요인들이 "통제 가능한 요인"인지, "통제 불가능한 요인"인지 선택해보세요.',
      typography_25_0_0:
        "좋아요. 고생 많았어요. 자, 이 생각을 다시 살펴볼게요. 잠시 심호흡을 크게 한 번 하고 다음으로 넘어가볼까요?",
      typography_26_0_0: "거리두고 살펴보기",
      typography_27_0_0:
        "아래 도표에서 통제 가능한 것과 불가능한 것을 찬찬히 살펴보세요.",
      typography_28_0_0: "그리고 나의 경험을 다시 바라보세요.",
      typographyFromData_29_0_0:
        "위와 같은 상황이 발생하는 데에는 통제 불가능한 요인이 $A$%였네요. 이 결과를 보고 나의 자동적 사고를 다시 살펴보면 어떤 생각이 드나요?",
      typographyFromData_30_0_0:
        "통제 불가능한 부분까지 우리가 어떻게 할 순 없었을 거예요. 위와 같은 상황이 발생하는 데에 $A$%는 내가 어떻게 할 수 없는 부분이었다는 사실을 받아들일 수 있을까요?",
      typography_31_0_0: "좋아요. 그러면 이번엔 기여도를 살펴볼게요.",
      typography_32_0_0: "아래 도표에서 각 요인의 기여도를 찬찬히 살펴보세요.",
      typography_33_0_0: "그리고 나의 경험을 다시 바라보세요.",
      typographyFromData_34_0_0:
        "위와 같은 상황이 발생하는 데에는 나의 기여는 $A$% 정도인 것 같네요. 이 결과를 보고 나의 자동적 사고를 다시 살펴보면 어떤 생각이 드나요?",
      typographyFromData_35_0_0:
        "나머지 $eval(100-A)$%는 나의 책임이라고 볼 수 없는 다른 요인이라고 할 수 있겠어요. 위와 같은 상황이 발생하는 데에 $eval(100-A)$%는 나와 무관했다는 사실을 받아들일 수 있을까요?",

      ...translation_4_5_A.ko.v1.end,
    },
  },
};
