import { Box, Typography } from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";
import Link from "components/common/Link";
import React from "react";

export function ThoughtMapText({
  text,
  color,
  dimmed,
  sx,
  startDecorator,
  hasLink,
  action,
}: {
  text: string;
  color?: string;
  dimmed?: boolean;
  sx?: SxProps;
  startDecorator?: React.ReactNode;
  hasLink?: boolean;
  action?: () => void;
}) {
  return (
    <Box
      sx={{
        height: "100%",
        fontWeight: 500,
        opacity: dimmed && false ? 0.5 : 1,

        ...sx,
      }}
    >
      <Typography
        startDecorator={startDecorator}
        textColor={color || "text.positive"}
        sx={{
          ...(hasLink && {
            textDecoration: "underline",
            cursor: "pointer",
          }),
        }}
        onClick={action}
      >
        {text}
      </Typography>
    </Box>
  );
}
