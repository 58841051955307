import { useEffect, useState } from "react";
import { ProgramType } from "data/programData";
import * as _ from "lodash";
import useFetch from "./useFetch";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import useGetBlankTaskData, {
  setDataWithEditorKeyAndTranslationVersion,
} from "./useGetBlankTaskData";
import { getLastShownIndex } from "logic/logics";
import { appLog } from "utils/webview";

export default function useGetTaskData({ taskKey }: { taskKey: string }) {
  const [data, setData] = useState<ProgramType>();
  const user = useRecoilValue(userAtom);

  // useEffect(() => {
  //   console.log("GET TASK DATA", taskKey);
  // }, [taskKey]);

  const blankTaskData = useGetBlankTaskData({ taskKey: taskKey });

  const [fetchedData, getData] = useFetch({
    url: "/task/data/load",
    params: { tk: taskKey },
  });

  useEffect(() => {
    if (blankTaskData) {
      if (user?.accessToken && !taskKey.includes("Tool")) {
        getData();
      } else {
        setData(blankTaskData);
      }
    }
  }, [blankTaskData]);

  useEffect(() => {
    if (fetchedData) {
      const totalData = fetchedData?.result.data;
      if (
        totalData &&
        totalData.content.length > 0 &&
        !(
          taskKey.split("-")[0] === "104" &&
          getLastShownIndex(totalData.content) === 0
        )
      ) {
        console.log("FETCH: ", totalData);
        setData(setDataWithEditorKeyAndTranslationVersion(totalData));
      } else {
        setData(blankTaskData);
      }
    }
  }, [fetchedData]);

  return {
    data,
    refetch: () => {
      if (user?.accessToken && !taskKey.includes("Tool")) {
        getData();
      }
    },
  };
}
