import {
  BarElement,
  BarElementProps,
  BarPlot,
  ChartsXAxis,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { theme } from "styles/theme";
import { Box, Stack, Typography } from "@mui/joy";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { extractHexColor } from "styles/hexOpacity";

export default function BarChart({
  id,
  data,
  maxScore,
  placeholder,
  abnormalScore = 0,
  xLabel,
  renderIfFocus,
  showYRange,
  showXAxis,
  showMarkValue,
}: {
  id: string;
  data: number[];
  maxScore: number;
  placeholder?: string;
  abnormalScore?: number;
  xLabel?: string[];
  renderIfFocus?: React.ReactNode[];
  showYRange?: boolean;
  showXAxis?: boolean;
  showMarkValue?: boolean;
}) {
  const normalColor = "#E4EAED";
  const highlightColor = theme.vars.palette.primary.solid;

  const CustomBar = ({
    color,
    props,
    value,
    showMarkValue,
  }: {
    color?: string;
    props: BarElementProps;
    value: number;
    showMarkValue?: boolean;
  }) => {
    const unitWidth = 40;

    const [position, setPosition] = useState<{
      x: number;
      y: number;
      width: number;
    }>();
    useEffect(() => {
      // setTimeout(() => {
      setPosition({
        x: (props?.style as any)?.x?.animation.to,
        y: (props?.style as any)?.y?.animation.to,
        width: (props?.style as any)?.width?.animation.to,
      });
      // }, 0);
    }, []);

    const customStyle = {
      stroke: color,
      fill: color,
      rx: 1,
      ...(position && {
        x: position.x + (position.width - unitWidth) / 2,
      }),
      marginX: "20px",
      width: unitWidth,
    };
    const valueTextColor = extractHexColor(theme.vars.palette.common.black);
    return position ? (
      <>
        <BarElement {...props} style={{ ...props.style, ...customStyle }} />

        {showMarkValue && (
          <text
            {...(customStyle.x && {
              x: (customStyle.x + unitWidth / 2) as number,
            })}
            y={(position.y - 10) as number}
            textAnchor="middle"
            fill={valueTextColor}
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            {value}
          </text>
        )}
      </>
    ) : (
      <></>
    );
  };

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const [selectedMark, setSelectedMark] = useState<{
    index: number;
    positionX: number;
    positionY: number;
  }>();
  const renderIfFocusWidth = 200;

  const [chartContainer, setChartContainer] = useState<{
    offsetLeft: number;
    offsetWidth: number;
  }>();

  const marginY = 20;
  const chartHeight = 200;
  const xLabelTranslateY = 20;

  //그래프 최신부분부터 보여주기
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollWidth - scrollRef.current.offsetWidth,
        behavior: "auto",
      });
    }
  }, [scrollRef, data]);

  const unitWidth = Math.max(
    60,
    (chartContainerRef.current?.offsetWidth || 0) / (data.length || 1)
  );

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: chartHeight + xLabelTranslateY,
        overflowX: "hidden", // 가로 스크롤 허용
        overflowY: "hidden", // 세로 스크롤 숨김
      }}
      ref={chartContainerRef}
    >
      <Box
        sx={{
          position: "absolute",
          width: "1px",
          height: `${chartHeight}px`,
          backgroundColor: "text.positive",
          opacity: 0.1,
        }}
      />
      {abnormalScore > 0 && abnormalScore < 100 && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "1px",
            top: `${
              marginY +
              (chartHeight - (marginY + xLabelTranslateY)) *
                (1 - abnormalScore / maxScore)
            }px`,
            backgroundColor: "text.positive",
            opacity: 0.1,
          }}
        />
      )}
      {showXAxis && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "1px",
            top: `${marginY + (chartHeight - marginY)}px`,
            backgroundColor: "text.positive",
            opacity: 0.1,
          }}
        />
      )}

      {showYRange &&
        [0, maxScore].map((each, index) => (
          <Typography
            key={`scoreLabel${each}`}
            level="body-xs"
            sx={{
              position: "absolute",
              ...(index === 1
                ? { top: `${marginY}px`, transform: "translateY(-50%)" }
                : {
                    bottom: `${marginY + xLabelTranslateY}px`,
                    transform: "translateY(50%)",
                  }),
              right: 0,
              opacity: 0.5,
            }}
          >
            {each}
          </Typography>
        ))}
      {data.length > 0 ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflowX: "auto", // 가로 스크롤 허용
            overflowY: "hidden", // 세로 스크롤 숨김
            whiteSpace: "nowrap", // 요소가 줄바꿈되지 않도록 설정
          }}
          ref={scrollRef}
        >
          <Box
            sx={{
              width: `${data.length * unitWidth}px`,
              height: "100%",
              display: "inline-block",
            }}
          >
            <ResponsiveChartContainer
              margin={{
                top: marginY,
                left: 40,
                right: 40,
                bottom: marginY + xLabelTranslateY,
              }}
              series={[{ data: data, label: "uv", type: "bar" }]}
              xAxis={[
                {
                  scaleType: "band",
                  data: xLabel || data.map((each, index) => `${index}`),
                  id: "bottom-axis-id",
                },
              ]}
              yAxis={[
                {
                  min: 0,
                  max: maxScore,
                  scaleType: "linear",
                  data: data,
                  id: "right-axis-id",
                },
                {
                  scaleType: "point",
                  data: data.map((each) => ""),
                  id: "left-axis-id",
                },
              ]}
              disableAxisListener
            >
              <BarPlot
                // skipAnimation
                slots={{
                  bar: (props) => (
                    <CustomBar
                      props={props}
                      color={
                        props.ownerState.dataIndex === data.length - 1
                          ? highlightColor
                          : normalColor
                      }
                      value={data[props.ownerState.dataIndex]}
                      showMarkValue={showMarkValue}
                    />
                  ),
                }}
              />
              <ChartsXAxis
                position="bottom"
                axisId="bottom-axis-id"
                disableLine
                disableTicks
                tickLabelStyle={{
                  opacity: xLabel ? 0.5 : 0,
                  transform: `translateY(${marginY}px)`,
                }}
              />
            </ResponsiveChartContainer>
          </Box>

          {selectedMark && renderIfFocus && (
            <>
              <Box
                sx={{
                  position: "absolute",
                  left: `${selectedMark.positionX - 5}px`,
                  top: `${selectedMark.positionY}px`,
                  color: "background.level2",
                  fontSize: "15px",
                }}
              >
                ▲
              </Box>
              <div
                style={{
                  position: "absolute",
                  left: `${Math.max(
                    10,
                    Math.min(
                      selectedMark.positionX - renderIfFocusWidth / 2,
                      (chartContainer?.offsetLeft || 0) +
                        (chartContainer?.offsetWidth || 0) -
                        renderIfFocusWidth -
                        10
                    )
                  )}px`,
                  top: `${selectedMark.positionY + 15}px`,
                }}
              >
                <Box
                  sx={{
                    width: `${renderIfFocusWidth}px`,
                    py: "8px",
                    px: "16px",
                    backgroundColor: "background.level2",
                    borderRadius: "4px",
                  }}
                >
                  {renderIfFocus[selectedMark.index]}
                </Box>
              </div>
            </>
          )}
        </Box>
      ) : (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            pb: "20px",
          }}
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: "14px", opacity: 0.3, fontWeight: 500 }}>
            {placeholder}
          </Typography>
        </Stack>
      )}
    </div>
  );
}
