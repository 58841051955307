export const translation_0_3 = {
  en: {
    v1: {
      typography_0_0_0:
        "Welcome to Distancing! Why not tap the arrow below to get started?",
      typography_1_0_0: "Great job. How did you find out about Distancing?",
      buttongroup_1_2_0_option0: "Twitter",
      buttongroup_1_2_0_option1: "",
      buttongroup_1_2_0_option2: "Instagram",
      buttongroup_1_2_0_option3: "Friend's recommendation",
      buttongroup_1_2_0_option4: "Others",
      typography_2_0_0:
        "I see. Many people come here facing various challenges, big and small.",
      typography_3_0_0:
        "Those who have journeyed through Distancing experienced about a 50% reduction in symptoms in just 7 weeks.",
      typography_4_0_0:
        "Distancing is a safe and effective cognitive therapy program.",
      typography_5_0_0: "The principle of the program",
      typography_6_0_0: "The principle behind Distancing is simple.",
      typography_7_0_0:
        "All mental challenges stem from not being able to 'distance' ourselves from our thoughts.",
      typography_8_0_0: "In other words, if we're too close to our thoughts,",
      typography_9_0_0: "We start identifying them as 'ourselves',",
      typography_10_0_0:
        "Leading to an involuntary and strong reaction to negative thoughts, leaving us feeling helpless.",
      typography_11_0_0:
        "This key principle has been validated through numerous psychotherapy techniques and research by renowned psychiatrists and scholars.",
      typography_12_0_0: "How the program works",
      typography_13_0_0:
        "Our goal is to build the mental strength to distance ourselves from our thoughts.",
      typography_14_0_0:
        "If we can grow this strength and put some distance between ourselves and our thoughts, we'll be able to actively respond to whatever pops into our minds.",
      typography_15_0_0:
        "Then, we won't be troubled by mental struggles anymore.",
      typography_16_0_0:
        "We won't burden you with complex explanations or mere education.",
      typography_17_0_0:
        "Instead, you'll practically apply concepts and methods to your situations through daily activities.",
      typography_18_0_0: "The program takes about 3 months to complete.",
      typography_19_0_0:
        "During your time with Distancing, each of you will be assigned a personal coach.",
      typography_20_0_0:
        "All our coaches are experts in cognitive science and psychotherapy, qualified to guide you through the program.",
      typography_21_0_0:
        "Your coach will tailor daily activities specifically for you.",
      typography_22_0_0:
        "You can check your next activity in the sidebar on the left.",
      typography_23_0_0:
        "Rest assured, everything you do in the program is strictly confidential and won't be shared with anyone other than your coach.",
      typography_24_0_0:
        "One more thing, you can use Distancing not only on your mobile but also on your computer. Feel free to use Distancing at any time, in any place, and in any way you prefer.",
      typography_25_0_0:
        "If you want to use Distancing on a computer, please visit the following address: https://distancing.im",
      typography_26_0_0: "Now, shall we meet your coach? Please log in.",
      typography_27_0_0:
        "Excellent. Please write down the nickname you'd like the coach to call you.",
      typographyFromData_28_0_0:
        "Alright, $A$, you'll soon be assigned a coach who will personally greet you as $A$.",
      typography_29_0_0:
        "Don't forget to allow notifications to receive messages from your coach.",
      typography_30_0_0: "See you soon.",
    },
    v2: {
      트위터: "Twitter",
      네이버: "",
      인스타그램: "Instagram",
      "지인 추천": "Friend's recommendation",
      기타: "Others",
      "무기력에서 벗어나고 싶어요": "Overcoming apathy",
      "스스로를 사랑하고 싶어요": "Self-love",
      "걱정을 멈추고 싶어요": "Stopping worries",
      "두려움을 이겨내고 싶어요": "Facing fears",
      "인간관계에서 편안해지고 싶어요": "Easier friendships",
      "일과 공부에 집중하고 싶어요": "Focusing better",
      typography_0_0_0:
        "Hey there! Welcome aboard Distancing. Ready to get started? Just tap the arrow below.",
      typography_1_0_0:
        "Nice one! Distancing is a 3-month journey into cognitive therapy.",
      typography_2_0_0:
        "Throughout this journey, you'll have your very own cognitive therapy coach working with you.",
      typography_3_0_0:
        "So, what brought you to Distancing? Around here, people often find themselves chasing goals like:",
      typography_4_0_0:
        "Cool. Your coach will take a look at your goals and personalize the program just for you.",
      typography_5_0_0: `I get it. You might be wondering, "Can I really make a change? Will I be good at this?"`,
      typography_6_0_0:
        "But while you're with us at Distancing, try to trust yourself. Believe that you can change.",
      typography_7_0_0:
        "Just like many others who've been through Distancing, you'll soon see your belief turn into reality.",
      typography_8_0_0:
        "Distancing costs $59.99 a month, and there's a 7-day free trial to get a feel for things.",
      typography_9_0_0:
        "Now, ready to meet your coach? Let's log in and get started.",
      typography_10_0_0:
        "Awesome. What nickname would you like your coach to use for you?",
      typography_11_0_0:
        "Got it, $A$. Your coach will reach out to you as $A$.",
      typography_12_0_0:
        "Make sure to enable notifications to hear from your coach.",
      typography_13_0_0:
        "Before we wrap up, just curious - how did you find out about Distancing?",
      typography_14_0_0: "See you soon.",
    },
    v3: {
      트위터: "Twitter",
      네이버: "",
      인스타그램: "Instagram",
      "지인 추천": "Friend's recommendation",
      기타: "Others",
      "무기력에서 벗어나고 싶어요": "Overcoming apathy",
      "우울한 기분에서 벗어나고 싶어요": "Relieve depressive mood",
      "자책을 멈추고 싶어요": "Stop self-blame",
      "나를 사랑하고 받아들이고 싶어요": "Embrace myself",
      "과거의 기억에서 빠져나오고 싶어요": "Move on from the past",
      "삶의 의미와 목적을 되찾고 싶어요": "Find life's meaning and purpose",
      typography_0_0_0:
        "Hey there! Welcome aboard Distancing. Ready to get started? Just tap the arrow below.",
      typography_1_0_0:
        "Nice one! Distancing is a 3-month journey into cognitive therapy.",
      typography_1_0_0_1:
        "Distancing specifically targets early-stage depression.",
      typography_1_0_0_2:
        "Depression is known to become more chronic as time passes after its onset.",
      typography_1_0_0_3:
        "Therefore, it is crucial to manage it well from the early stages when you start feeling depressed.",
      typography_1_0_0_4:
        "Distancing intervenes early to help you return to a stable life.",
      typography_1_0_0_5:
        "In the process, people who have participated in the program for 4 weeks have reduced their depression by about 53%.",
      typography_2_0_0:
        "Throughout this journey, you'll have your very own cognitive therapy coach working with you.",
      typography_2_0_0_1:
        "Throughout this journey, you'll have your very own AI cognitive therapy coach working with you.",
      typography_3_0_0:
        "So, what brought you to Distancing? Around here, people often find themselves chasing goals like:",
      typography_4_0_0:
        "Cool. Your coach will take a look at your goals and personalize the program just for you.",

      typography_5_0_0: `I get it. You might be wondering, "Can I really make a change? Will I be good at this?"`,
      typography_6_0_0:
        "But while you're with us at Distancing, try to trust yourself. Believe that you can change.",
      typography_7_0_0:
        "Just like many others who've been through Distancing, you'll soon see your belief turn into reality.",
      typography_8_0_0:
        "Distancing costs $59.99 a month, and there's a 7-day free trial to get a feel for things.",
      typography_9_0_0:
        "Now, ready to meet your coach? Let's log in and get started.",
      typography_10_0_0:
        "Awesome. What nickname would you like your coach to use for you?",
      typography_11_0_0:
        "Got it, $A$. Your coach will reach out to you as $A$.",
      typography_12_0_0:
        "Make sure to enable notifications to hear from your coach.",
      typography_13_0_0:
        "Before we wrap up, just curious - how did you find out about Distancing?",
      typography_14_0_0: "See you soon.",
    },
    v4: {
      트위터: "Twitter",
      네이버: "",
      인스타그램: "Instagram",
      "지인 추천": "Friend's recommendation",
      기타: "Others",
      "무기력에서 벗어나고 싶어요": "Overcoming apathy",
      "스스로를 사랑하고 싶어요": "Self-love",
      "걱정을 멈추고 싶어요": "Stopping worries",
      "두려움을 이겨내고 싶어요": "Facing fears",
      "인간관계에서 편안해지고 싶어요": "Easier friendships",
      "일과 공부에 집중하고 싶어요": "Focusing better",
      typography_0_0_0:
        "Hey there! Welcome aboard Distancing. Ready to get started? Just tap the arrow below.",
      typography_1_0_0:
        "Nice one! Distancing is a 3-month journey into cognitive therapy.",
      typography_2_0_0:
        "Throughout this journey, you'll have your very own cognitive therapy coach working with you.",
      typography_3_0_0:
        "So, what brought you to Distancing? Around here, people often find themselves chasing goals like:",
      typography_4_0_0:
        "Cool. Your coach will take a look at your goals and personalize the program just for you.",
      typography_5_0_0: `I get it. You might be wondering, "Can I really make a change? Will I be good at this?"`,
      typography_6_0_0:
        "But while you're with us at Distancing, try to trust yourself. Believe that you can change.",
      typography_7_0_0:
        "Just like many others who've been through Distancing, you'll soon see your belief turn into reality.",
      typography_8_0_0:
        "Distancing costs $59.99 a month, and there's a 7-day free trial to get a feel for things.",
      typography_9_0_0:
        "Now, ready to meet your coach? Let's log in and get started.",
      typography_10_0_0:
        "Awesome. What nickname would you like your coach to use for you?",
      typography_11_0_0:
        "Got it, $A$. Your coach will reach out to you as $A$.",
      typography_12_0_0:
        "Make sure to enable notifications to hear from your coach.",
      typography_13_0_0:
        "Before we wrap up, just curious - how did you find out about Distancing?",
      typography_14_0_0: "See you soon.",
    },
    v5: {
      typography_0_0_0:
        "Welcome to Distancing! Why not tap the arrow below to get started?",
      typography_1_0_0: "Great job. How did you find out about Distancing?",
      buttongroup_1_2_0_option0: "Twitter",
      buttongroup_1_2_0_option1: "",
      buttongroup_1_2_0_option2: "Instagram",
      buttongroup_1_2_0_option3: "Friend's recommendation",
      buttongroup_1_2_0_option4: "Others",
      typography_2_0_0:
        "I see. Many people come here facing various challenges, big and small.",
      typography_3_0_0:
        "Those who have journeyed through Distancing experienced about a 50% reduction in symptoms in just 7 weeks.",
      typography_4_0_0:
        "Distancing is a safe and effective cognitive therapy program.",
      typography_5_0_0: "The principle of the program",
      typography_6_0_0: "The principle behind Distancing is simple.",
      typography_7_0_0:
        "All mental challenges stem from not being able to 'distance' ourselves from our thoughts.",
      typography_8_0_0: "In other words, if we're too close to our thoughts,",
      typography_9_0_0: "We start identifying them as 'ourselves',",
      typography_10_0_0:
        "Leading to an involuntary and strong reaction to negative thoughts, leaving us feeling helpless.",
      typography_11_0_0:
        "This key principle has been validated through numerous psychotherapy techniques and research by renowned psychiatrists and scholars.",
      typography_12_0_0: "How the program works",
      typography_13_0_0:
        "Our goal is to build the mental strength to distance ourselves from our thoughts.",
      typography_14_0_0:
        "If we can grow this strength and put some distance between ourselves and our thoughts, we'll be able to actively respond to whatever pops into our minds.",
      typography_15_0_0:
        "Then, we won't be troubled by mental struggles anymore.",
      typography_16_0_0:
        "We won't burden you with complex explanations or mere education.",
      typography_17_0_0:
        "Instead, you'll practically apply concepts and methods to your situations through daily activities.",
      typography_18_0_0: "The program takes about 3 months to complete.",
      typography_19_0_0:
        "During your time with Distancing, each of you will be assigned a personal coach.",
      typography_20_0_0:
        "All our coaches are experts in cognitive science and psychotherapy, qualified to guide you through the program.",
      typography_21_0_0:
        "Your coach will tailor daily activities specifically for you.",
      typography_22_0_0:
        "You can check your next activity in the sidebar on the left.",
      typography_23_0_0:
        "Rest assured, everything you do in the program is strictly confidential and won't be shared with anyone other than your coach.",
      typography_24_0_0:
        "One more thing, you can use Distancing not only on your mobile but also on your computer. Feel free to use Distancing at any time, in any place, and in any way you prefer.",
      typography_25_0_0:
        "If you want to use Distancing on a computer, please visit the following address: https://distancing.im",
      typography_26_0_0: "Now, shall we meet your coach? Please log in.",
      typography_27_0_0:
        "Excellent. Please write down the nickname you'd like the coach to call you.",
      typographyFromData_28_0_0:
        "Alright, $A$, you'll soon be assigned a coach who will personally greet you as $A$.",
      typography_29_0_0:
        "Don't forget to allow notifications to receive messages from your coach.",
      typography_30_0_0: "See you soon.",
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "안녕하세요. 디스턴싱에 오신 것을 환영합니다. 아래 화살표를 눌러볼까요?",
      typography_1_0_0: "잘하셨어요. 디스턴싱은 어떻게 알고 오셨나요?",
      buttongroup_1_2_0_option0: "트위터",
      buttongroup_1_2_0_option1: "네이버",
      buttongroup_1_2_0_option2: "인스타그램",
      buttongroup_1_2_0_option3: "지인 추천",
      buttongroup_1_2_0_option4: "기타",
      typography_2_0_0:
        "그렇군요. 많은 분들이 크고 작은 여러 어려움으로 이곳에 도착하시는데요.",
      typography_3_0_0:
        "지금껏 디스턴싱을 거쳐 간 사람들은 7주만에 약 50%의 증상 경감을 경험했을 만큼",
      typography_4_0_0:
        "디스턴싱은 안전하고 효과적인 인지치료 프로그램이랍니다.",
      typography_5_0_0: "프로그램의 원리",
      typography_6_0_0: "디스턴싱의 원리는 간단해요.",
      typography_7_0_0:
        "모든 마음의 문제는 '생각과의 거리두기(distancing)'가 잘되지 않아 발생한다는 것인데요.",
      typography_8_0_0: "다시 말해, 생각과의 거리가 너무 가까우면",
      typography_9_0_0: "생각을 '나' 자신과 동일시하게 되고",
      typography_10_0_0:
        "결국 부정적인 생각에 반사적이고 강하게 반응하며 속수무책으로 당할 수 밖에 없다는 것이죠.",
      typography_11_0_0:
        "이 핵심적인 원리는 수많은 심리치료 기법과 저명한 정신과 의사와 학자들의 연구를 통해 검증된 바 있습니다.",
      typography_12_0_0: "프로그램의 진행 방식",
      typography_13_0_0:
        "우리는 앞으로 생각과 거리를 둘 수 있는 마음의 힘을 기르는 것을 목표로 할 거예요.",
      typography_14_0_0:
        "이 힘이 길러져서 생각과의 거리를 둘 수 있다면, 우리는 떠오르는 생각에 대해 능동적으로 반응할 수 있습니다.",
      typography_15_0_0: "그러면 더 이상 마음의 문제로 괴로워하지 않게 되겠죠.",
      typography_16_0_0: "앞으로 어려운 설명과 단순 교육은 하지 않을 거예요.",
      typography_17_0_0:
        "매일 이런 활동을 통해 실제 나의 상황에 적용해 보며 개념과 방법을 체험적으로 익혀나갈 거랍니다.",
      typography_18_0_0: "프로그램을 마치기까지는 약 3개월 정도 걸릴 거예요.",
      typography_19_0_0:
        "그리고 디스턴싱을 사용하는 동안 여러분 모두는 코치 선생님을 한 분씩 배정받게 되는데요.",
      typography_20_0_0:
        "모두 인지과학과 심리치료에 정통하며, 프로그램을 이끌 자격을 갖추신 최고의 전문가들이랍니다.",
      typography_21_0_0:
        "코치 선생님은 매일 여러분 개개인에게 맞게 활동을 넣어줄 거예요.",
      typography_22_0_0:
        "좌측의 사이드바에서 다음에 해야 할 활동을 확인할 수 있어요.",
      typography_23_0_0:
        "프로그램을 진행하며 쌓인 내용은 코치 선생님 외 그 누구에게도 일절 공개되지 않으니 걱정하지 않으셔도 돼요.",
      typography_24_0_0:
        "그리고 디스턴싱은 모바일뿐만 아니라 컴퓨터로도 이용할 수 있답니다. 원하는 시간, 장소, 방식으로 자유롭게 이용하시면 되어요.",
      typography_25_0_0:
        "컴퓨터로 이용하고 싶을 땐 다음 주소를 방문해 주세요: https://distancing.im ",
      typography_26_0_0:
        "자 그럼, 이제 코치 선생님을 만나러 가볼까요? 로그인을 해주세요.",
      typography_27_0_0:
        "좋습니다. 코치 선생님이 불러드릴 닉네임을 적어주세요.",
      typographyFromData_28_0_0:
        "네 $A$님, 곧 선생님이 배정되고, 선생님이 직접 $A$님께 인사드릴 거예요.",
      typography_29_0_0:
        "코치 선생님의 메시지를 받으려면 알림을 꼭 허용해주셔야 해요.",
      typography_30_0_0: "그럼 곧 다시 만나요.",
    },
    v2: {
      트위터: "트위터",
      네이버: "네이버",
      인스타그램: "인스타그램",
      "지인 추천": "지인 추천",
      기타: "기타",
      "무기력에서 벗어나고 싶어요": "무기력에서 벗어나고 싶어요",
      "스스로를 사랑하고 싶어요": "스스로를 사랑하고 싶어요",
      "걱정을 멈추고 싶어요": "걱정을 멈추고 싶어요",
      "두려움을 이겨내고 싶어요": "두려움을 이겨내고 싶어요",
      "인간관계에서 편안해지고 싶어요": "인간관계에서 편안해지고 싶어요",
      "일과 공부에 집중하고 싶어요": "일과 공부에 집중하고 싶어요",
      typography_0_0_0:
        "안녕하세요. 디스턴싱에 오신 것을 환영해요. 아래 화살표를 눌러볼까요?",
      typography_1_0_0:
        "잘하셨어요. 디스턴싱은 약 3개월 분량의 인지치료 프로그램이에요.",
      typography_2_0_0:
        "프로그램을 진행하는 동안 모두 인지치료 전문 코치를 1명씩 배정받아 함께하게 되죠.",
      typography_3_0_0:
        "혹시 어떤 바람을 갖고 디스턴싱을 찾아오게 되었나요? 디스턴싱에서는 보통 이런 목표를 이룰 수 있거든요.",
      typography_4_0_0:
        "좋아요. 코치 선생님이 확인하시고 그에 맞게 프로그램을 진행해주실 거랍니다.",
      typography_5_0_0:
        "알아요. 지금 이 순간에는 “내가 변할 수 있을까? 내가 잘 해낼 수 있을까?” 이런 마음이 든다는 걸요.",
      typography_6_0_0:
        "하지만 디스턴싱과 함께하는 순간만큼은 스스로 믿어보세요. 나도 변할 수 있다고요.",
      typography_7_0_0:
        "디스턴싱을 거쳐간 다른 많은 사람들처럼, 어느새 그 믿음이 현실이 되어있을 거예요.",
      typography_8_0_0:
        "디스턴싱은 월 8만 원에 이용하실 수 있어요. 7일의 무료 체험이 주어지니 그동안 마음껏 사용해보세요.",
      typography_9_0_0:
        "자 그럼, 이제 코치 선생님을 만나러 가볼까요? 로그인을 해주세요.",
      typography_10_0_0:
        "좋습니다. 코치 선생님이 불러드릴 닉네임을 적어주세요.",
      typography_11_0_0: "네 $A$님, 곧 선생님이 직접 $A$님께 인사드릴 거예요.",
      typography_12_0_0:
        "코치 선생님의 메시지를 받으려면 알림을 꼭 허용해주셔야 해요.",
      typography_13_0_0:
        "마지막으로 하나만 여쭤요. 디스턴싱은 어떻게 알고 오셨나요?",
      typography_14_0_0: "그럼 곧 다시 만나요.",
    },
    v3: {
      트위터: "트위터",
      네이버: "네이버",
      인스타그램: "인스타그램",
      "지인 추천": "지인 추천",
      기타: "기타",
      "무기력에서 벗어나고 싶어요": "무기력에서 벗어나고 싶어요",
      "우울한 기분에서 벗어나고 싶어요": "우울한 기분에서 벗어나고 싶어요",
      "자책을 멈추고 싶어요": "자책을 멈추고 싶어요",
      "나를 사랑하고 받아들이고 싶어요": "나를 사랑하고 받아들이고 싶어요",
      "과거의 기억에서 빠져나오고 싶어요": "과거의 기억에서 빠져나오고 싶어요",
      "삶의 의미와 목적을 되찾고 싶어요": "삶의 의미와 목적을 되찾고 싶어요",
      typography_0_0_0:
        "안녕하세요. 디스턴싱에 오신 것을 환영해요. 아래 화살표를 눌러볼까요?",
      typography_1_0_0:
        "잘하셨어요. 디스턴싱은 약 3개월 분량의 인지치료 프로그램이에요.",
      typography_1_0_0_1: "디스턴싱은 특히 초기 단계의 우울증을 공략해요.",
      typography_1_0_0_2:
        "우울증은 발생 후 점차 만성적인 경과를 밟는 것으로 알려져 있는데요.",
      typography_1_0_0_3:
        "따라서 우울감을 느끼기 시작한 초기에 잘 관리하는 것이 중요해요.",
      typography_1_0_0_4:
        "디스턴싱은 초기에 빠르게 개입하여 다시 안정적인 삶으로 회복할 수 있도록 돕는답니다.",
      typography_1_0_0_5:
        "그 과정에서 4주 동안 프로그램을 참여한 사람들은 약 53% 정도 우울감을 낮추고 있어요.",
      typography_2_0_0:
        "그러한 변화를 돕기 위해 디스턴싱에서는 나만의 인지치료 전문 코치가 배정되는데요.",
      typography_2_0_0_1:
        "그러한 변화를 돕기 위해 디스턴싱에서는 나만의 인지치료 전문 AI 코치가 배정되는데요.",
      typography_3_0_0:
        "혹시 어떤 바람을 갖고 디스턴싱을 찾아오게 되었나요? 디스턴싱에서는 보통 이런 목표를 이룰 수 있거든요.",
      typography_4_0_0:
        "좋아요. 코치 선생님이 확인하고 그에 맞게 프로그램을 진행해주실 거랍니다.",
      typography_4_0_0_1:
        "좋아요. 코치가 확인하고 그에 맞게 프로그램을 진행해줄 거랍니다.",
      typography_5_0_0:
        "알아요. 지금 이 순간에는 “내가 변할 수 있을까? 내가 잘 해낼 수 있을까?” 이런 마음이 든다는 걸요.",
      typography_6_0_0:
        "하지만 디스턴싱과 함께하는 순간만큼은 스스로 믿어보세요. 나도 변할 수 있다고요.",
      typography_7_0_0:
        "디스턴싱을 거쳐간 다른 많은 사람들처럼, 어느새 그 믿음이 현실이 되어있을 거예요.",
      typography_8_0_0:
        "디스턴싱은 월 8만 원에 이용하실 수 있어요. 7일의 무료 체험이 주어지니 그동안 마음껏 사용해보세요.",
      typography_9_0_0:
        "자 그럼, 이제 코치 선생님을 만나러 가볼까요? 로그인을 해주세요.",
      typography_10_0_0:
        "좋습니다. 코치 선생님이 불러드릴 닉네임을 적어주세요.",
      typography_10_0_0_1: "좋습니다. 코치가 불러드릴 닉네임을 적어주세요.",
      typography_11_0_0: "네 $A$님, 곧 선생님이 직접 $A$님께 인사드릴 거예요.",
      typography_12_0_0:
        "코치 선생님의 메시지를 받으려면 알림을 꼭 허용해주셔야 해요.",
      typography_12_0_0_1:
        "코치의 메시지를 받으려면 알림을 꼭 허용해주셔야 해요.",
      typography_13_0_0:
        "마지막으로 하나만 여쭤요. 디스턴싱은 어떻게 알고 오셨나요?",
      typography_14_0_0: "그럼 곧 다시 만나요.",
    },
    v4: {
      트위터: "트위터",
      네이버: "네이버",
      인스타그램: "인스타그램",
      "지인 추천": "지인 추천",
      기타: "기타",
      "무기력에서 벗어나고 싶어요": "무기력에서 벗어나고 싶어요",
      "스스로를 사랑하고 싶어요": "스스로를 사랑하고 싶어요",
      "걱정을 멈추고 싶어요": "걱정을 멈추고 싶어요",
      "두려움을 이겨내고 싶어요": "두려움을 이겨내고 싶어요",
      "인간관계에서 편안해지고 싶어요": "인간관계에서 편안해지고 싶어요",
      "일과 공부에 집중하고 싶어요": "일과 공부에 집중하고 싶어요",
      typography_0_0_0:
        "안녕하세요. 디스턴싱에 오신 것을 환영해요. 아래 화살표를 눌러볼까요?",
      typography_1_0_0:
        "잘하셨어요. 디스턴싱은 약 3개월 분량의 인지치료 프로그램이에요.",
      typography_2_0_0:
        "프로그램을 진행하는 동안 모두 인지치료 전문 코치를 1명씩 배정받아 함께하게 되죠.",
      typography_3_0_0:
        "혹시 어떤 바람을 갖고 디스턴싱을 찾아오게 되었나요? 디스턴싱에서는 보통 이런 목표를 이룰 수 있거든요.",
      typography_4_0_0:
        "좋아요. 코치 선생님이 확인하시고 그에 맞게 프로그램을 진행해주실 거랍니다.",
      typography_5_0_0:
        "알아요. 지금 이 순간에는 “내가 변할 수 있을까? 내가 잘 해낼 수 있을까?” 이런 마음이 든다는 걸요.",
      typography_6_0_0:
        "하지만 디스턴싱과 함께하는 순간만큼은 스스로 믿어보세요. 나도 변할 수 있다고요.",
      typography_7_0_0:
        "디스턴싱을 거쳐간 다른 많은 사람들처럼, 어느새 그 믿음이 현실이 되어있을 거예요.",
      typography_8_0_0:
        "디스턴싱은 월 8만 원에 이용하실 수 있어요. 7일의 무료 체험이 주어지니 그동안 마음껏 사용해보세요.",
      typography_9_0_0:
        "자 그럼, 이제 코치 선생님을 만나러 가볼까요? 로그인을 해주세요.",
      typography_10_0_0:
        "좋습니다. 코치 선생님이 불러드릴 닉네임을 적어주세요.",
      typography_11_0_0: "네 $A$님, 곧 선생님이 직접 $A$님께 인사드릴 거예요.",
      typography_12_0_0:
        "코치 선생님의 메시지를 받으려면 알림을 꼭 허용해주셔야 해요.",
      typography_13_0_0:
        "마지막으로 하나만 여쭤요. 디스턴싱은 어떻게 알고 오셨나요?",
      typography_14_0_0: "그럼 곧 다시 만나요.",
    },
    v5: {
      typography_0_0_0:
        "안녕하세요. 디스턴싱에 오신 것을 환영합니다. 아래 화살표를 눌러볼까요?",
      typography_1_0_0: "잘하셨어요. 디스턴싱은 어떻게 알고 오셨나요?",
      buttongroup_1_2_0_option0: "트위터",
      buttongroup_1_2_0_option1: "네이버",
      buttongroup_1_2_0_option2: "인스타그램",
      buttongroup_1_2_0_option3: "지인 추천",
      buttongroup_1_2_0_option4: "기타",
      typography_2_0_0:
        "그렇군요. 많은 분들이 크고 작은 여러 어려움으로 이곳에 도착하시는데요.",
      typography_3_0_0:
        "지금껏 디스턴싱을 거쳐 간 사람들은 7주만에 약 50%의 증상 경감을 경험했을 만큼",
      typography_4_0_0:
        "디스턴싱은 안전하고 효과적인 인지치료 프로그램이랍니다.",
      typography_5_0_0: "프로그램의 원리",
      typography_6_0_0: "디스턴싱의 원리는 간단해요.",
      typography_7_0_0:
        "모든 마음의 문제는 '생각과의 거리두기(distancing)'가 잘되지 않아 발생한다는 것인데요.",
      typography_8_0_0: "다시 말해, 생각과의 거리가 너무 가까우면",
      typography_9_0_0: "생각을 '나' 자신과 동일시하게 되고",
      typography_10_0_0:
        "결국 부정적인 생각에 반사적이고 강하게 반응하며 속수무책으로 당할 수 밖에 없다는 것이죠.",
      typography_11_0_0:
        "이 핵심적인 원리는 수많은 심리치료 기법과 저명한 정신과 의사와 학자들의 연구를 통해 검증된 바 있습니다.",
      typography_12_0_0: "프로그램의 진행 방식",
      typography_13_0_0:
        "우리는 앞으로 생각과 거리를 둘 수 있는 마음의 힘을 기르는 것을 목표로 할 거예요.",
      typography_14_0_0:
        "이 힘이 길러져서 생각과의 거리를 둘 수 있다면, 우리는 떠오르는 생각에 대해 능동적으로 반응할 수 있습니다.",
      typography_15_0_0: "그러면 더 이상 마음의 문제로 괴로워하지 않게 되겠죠.",
      typography_16_0_0: "앞으로 어려운 설명과 단순 교육은 하지 않을 거예요.",
      typography_17_0_0:
        "매일 이런 활동을 통해 실제 나의 상황에 적용해 보며 개념과 방법을 체험적으로 익혀나갈 거랍니다.",
      typography_18_0_0: "프로그램을 마치기까지는 약 3개월 정도 걸릴 거예요.",
      typography_19_0_0:
        "그리고 디스턴싱을 사용하는 동안 여러분 모두는 코치 선생님을 한 분씩 배정받게 되는데요.",
      typography_20_0_0:
        "모두 인지과학과 심리치료에 정통하며, 프로그램을 이끌 자격을 갖추신 최고의 전문가들이랍니다.",
      typography_21_0_0:
        "코치 선생님은 매일 여러분 개개인에게 맞게 활동을 넣어줄 거예요.",
      typography_22_0_0:
        "좌측의 사이드바에서 다음에 해야 할 활동을 확인할 수 있어요.",
      typography_23_0_0:
        "프로그램을 진행하며 쌓인 내용은 코치 선생님 외 그 누구에게도 일절 공개되지 않으니 걱정하지 않으셔도 돼요.",
      typography_24_0_0:
        "그리고 디스턴싱은 모바일뿐만 아니라 컴퓨터로도 이용할 수 있답니다. 원하는 시간, 장소, 방식으로 자유롭게 이용하시면 되어요.",
      typography_25_0_0:
        "컴퓨터로 이용하고 싶을 땐 다음 주소를 방문해 주세요: https://distancing.im ",
      typography_26_0_0:
        "자 그럼, 이제 코치 선생님을 만나러 가볼까요? 로그인을 해주세요.",
      typography_27_0_0:
        "좋습니다. 코치 선생님이 불러드릴 닉네임을 적어주세요.",
      typographyFromData_28_0_0:
        "네 $A$님, 곧 선생님이 배정되고, 선생님이 직접 $A$님께 인사드릴 거예요.",
      typography_29_0_0:
        "코치 선생님의 메시지를 받으려면 알림을 꼭 허용해주셔야 해요.",
      typography_30_0_0: "그럼 곧 다시 만나요.",
    },
    v6: {
      트위터: "트위터",
      스레드: "스레드",
      네이버: "네이버",
      "네이버카페/블로그": "네이버카페/블로그",
      인스타그램: "인스타그램",
      "지인 추천": "지인 추천",
      기타: "기타",
      typography_0_0_0:
        "안녕하세요. 디스턴싱에 오신 것을 환영해요. 아래 화살표를 눌러볼까요?",
      typography_1_0_0:
        "잘했어요. 디스턴싱은 인지심리학에 기반한 멘탈 테라피 프로그램이에요.",
      typography_2_0_0:
        "특히 생각과 거리를 두고, 다시 내가 원하는 삶의 가치를 향해 나아갈 수 있도록 도와주죠.",
      typography_3_0_0:
        "아마 다양한 문제로 괴로워하다가 여기까지 도착하셨을 거예요.",
      typography_4_0_0:
        "고단한 직장 생활, 어려운 인간관계, 거듭되는 실패 등 저마다 다른 이유가 있겠죠.",
      typography_5_0_0:
        "이유가 무엇이든 언제부터인가 삶은 이 문제를 해결하는 것이 전부인 양 흘러가고 있을 거예요.",
      typography_6_0_0: "삶에서 활기차고 흥미로운 일들은 점점 사라져가고요.",
      typography_7_0_0:
        '“인생에 재밌는 일이 하나도 없어. 언제부터 이렇게 꼬여버린 걸까"라면서요.',
      typography_8_0_0:
        "그렇담 잘 찾아오셨어요. 우리가 당신을 변화시켜 드릴 거예요.",
      typography_9_0_0: "과학적으로 검증된 모든 방법을 동원해서요.",
      typography_10_0_0:
        "시작하기에 앞서 한 가지 재밌는 사실을 하나 알려드릴게요.",
      typography_11_0_0:
        "지금 여기까지 읽은 사람들은 4주 뒤 우울감 또는 불안감을 호전시킬 확률이 50% 증가해요.",
      typography_12_0_0: "이미 중요한 단계를 넘은 셈이죠.",
      typography_13_0_0:
        "알아요. 마음이 힘들 땐 이 글을 읽는 것조차 벅차고 힘들다는 사실을요.",
      typography_14_0_0:
        "하지만 이 단계만 넘어도 우울감이나 불안감을 호전시킬 확률이 50%나 증가한다는 사실을 잊지 마세요.",
      typography_15_0_0: "보이진 않겠지만 변화는 벌써 시작되고 있답니다.",
      typography_16_0_0:
        "디스턴싱이 지향하는 건 단 하나, 오직 ‘생각과 거리두기'예요.",
      typography_17_0_0:
        "위대한 학자들은 자기 생각을 마치 다른 사람의 생각인 양 관찰하는 것이 문제 해결의 열쇠라는 사실을 밝혀냈어요.",
      typography_18_0_0: "마음속에 떠오르는 생각에 좌지우지되는 것이 아니라",
      typography_19_0_0:
        "생각과 거리를 두고 그로부터 영향을 덜 받을 수 있다고 말이죠.",
      typography_20_0_0:
        "우리는 당신이 깊은 구덩이에서 빠져나와 다시 적극적으로 삶을 누릴 수 있도록 도울 거예요.",
      typography_21_0_0:
        "디스턴싱은 그러한 변화를 촉진하기 위해 설계되어 있답니다.",
      typography_22_0_0:
        "물론 쉬운 일은 아니랍니다. 그렇기 때문에 훈련받은 전문 코치 선생님이 모든 과정을 도와줄 거예요.",
      typography_23_0_0:
        "자, 그러면 그 변화가 어떻게 만들어지는지 조금 더 자세히 살펴볼까요?",
      typography_24_0_0:
        "아차, 우리가 당신을 뭐라고 불러드리면 될까요? 원하는 닉네임을 적어주세요.",
      typography_25_0_0:
        "좋아요. 마지막으로 하나만 여쭤요. 디스턴싱은 어디서 처음 알게 되셨나요?",
      typography_26_0_0:
        "좋습니다. 그러면 이제 본격적으로 건강하고 가치있는 삶으로의 여정을 시작해볼까요?",
    },
  },
};
