import { useEffect, useState } from "react";
import DefaultPage from "../container/DefaultPage";
import { CircularProgress, Stack } from "@mui/joy";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import useLogin from "hooks/useLogin";

export default function EmailAuthResultPage() {
  const [result, setResult] = useState<any>();

  const login = useLogin({
    url: `/user/firebase-email/login`,
    requestBody: JSON.stringify(result),
  });

  useEffect(() => {
    if (result) {
      login();
    }
  }, [result]);

  useEffect(() => {
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      } else {
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            const params = new URLSearchParams(window.location.search);
            const user = result.user;
            setResult({
              uid: user.uid,
              email: user.email,
              apiKey: params.get("apiKey"),
              refreshToken: (user as any).stsTokenManager.refreshToken,
              accessToken: (user as any).stsTokenManager.accessToken,
            });

            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn");
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
          })
          .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
      }
    }
  }, []);

  return (
    <DefaultPage
      content={
        <Stack alignItems={"center"}>
          <CircularProgress color="neutral" />
        </Stack>
      }
    />
  );
}
