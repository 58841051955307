import { useEffect, useState } from "react";
import { ProgramType } from "data/programData";
import * as _ from "lodash";
import { ProgramContentType } from "data/BlockComponent";
import { useRecoilValue } from "recoil";
import { taskListAtom } from "recoil/taskListAtom";
import { setDataWithEditorKeyAndTranslationVersion } from "./useGetBlankTaskData";
import { deviceAtom } from "recoil/deviceAtom";
import useIsInappWebview from "./useIsInappWebview";
import { Content_5_4 } from "../data/task/v2/5-4";
import { SegmentationNoteTemplate } from "../data/task/v2/segmentationNote";

export default function useGetBlankSegmentationNoteData({
  snk,
  isFirst,
}: {
  snk?: string;
  isFirst?: boolean;
}) {
  const taskId = "5-4";
  const [data, setData] = useState<ProgramType>();
  const deviceState = useRecoilValue(deviceAtom);
  const taskListState = useRecoilValue(taskListAtom);
  const isInappWebview = useIsInappWebview();
  const isPushNotificationGranted =
    deviceState.pushNotificationStatus === "granted" || !isInappWebview;

  const isThoughtTrapAvailable = taskListState.some(
    (element) => element.taskId === "9-4-A" && element.isDone // TODO 생각함정 리스트 여기서 넣어주기
  );

  const segmentationNoteTaskData: ProgramType = {
    taskId: taskId,
    content: Content_5_4(isPushNotificationGranted),
  };

  useEffect(() => {
    let rawData = _.cloneDeep(segmentationNoteTaskData);

    if (snk && rawData) {
      if (!isFirst) {
        rawData.content = _.cloneDeep(
          SegmentationNoteTemplate({
            isThoughtTrapAvailable: isThoughtTrapAvailable,
            includeLastBlock: true,
          })
        );
      }

      (rawData.content as ProgramContentType[])[0].lines.push([
        {
          type: "temp",
          content: {
            id: `segmentationNoteKey`,
            value: snk,
          },
        },
      ]);

      setData(setDataWithEditorKeyAndTranslationVersion(rawData));
    }
  }, [snk]);

  return data;
}