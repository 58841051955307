import { Stack } from "@mui/joy";
import Link from "components/common/Link";
import useOpenThoughtRecord from "hooks/useOpenThoughtRecord";

export default function ReferenceLink({
  reference,
  thoughtRecordKey,
}: {
  reference: string;
  thoughtRecordKey?: string;
}) {
  const openThoughtRecord = useOpenThoughtRecord(thoughtRecordKey || "");

  return (
    <Stack direction="row" alignItems={"center"}>
      <Link
        text={reference}
        customSx={{
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "1",
          WebkitBoxOrient: "vertical",
          textAlign: "start",
        }}
        opacity={0.7}
        onClick={() => {
          if (thoughtRecordKey) {
            openThoughtRecord();
          }
        }}
      />
      {thoughtRecordKey && (
        <span
          style={{ fontSize: "12px", opacity: 0.7 }}
          className="material-symbols-rounded"
        >
          north_east
        </span>
      )}
    </Stack>
  );
}
