import { atom } from "recoil";

export interface PermanentDataType {
  isHighlghtIndicatorClicked?: boolean;
  isWebview?: boolean;
  homeIndicatorHeight?: number;
  appVersion?: string;
  audioCurrentTime?: number;
}

export const permanentDataAtom = atom<PermanentDataType | undefined>({
  key: "permanentData",
  default: { homeIndicatorHeight: 20 },
  effects: [
    ({ setSelf, onSet }) => {
      const savedData = localStorage.getItem("permanentData");
      // setSelf: atom 값을 설정 혹은 재설정
      if (savedData) setSelf(JSON.parse(savedData));

      // atom이 변화가 감지될 때 작동, Storage에 데이터 저장
      // setSelf에 의해서는 작동하지 않음
      onSet((newValue, _, isReset) => {
        isReset
          ? localStorage.removeItem("permanentData")
          : localStorage.setItem("permanentData", JSON.stringify(newValue));
      });
    },
  ],
});
