export const translation_101_2_2_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "이번 활동에서는 앞서 나눈 이야기들을 종합해서 나의 악순환 패턴을 정리해 볼게요.",
      typography_1_0_0:
        "우선 앞서 나눈 이야기를 토대로 나의 무기력 패턴을 정리해 보면 다음과 같을 거예요.",
      typography_2_0_0: "내가 처한 상황 또는 내가 겪은 사건이에요.",
      typography_3_0_0: "이로 인해 나는 이런 행동을 반복해 오고 있어요.",
      typography_4_0_0:
        "위의 행동은 내게 다음과 같은 단기적인 이점을 주고 있죠.",
      typography_5_0_0: "하지만 장기적인 관점에서는 다음과 같은 단점이 있어요.",
      typography_6_0_0:
        "이 패턴에서 중요한 점은 내가 우울과 무기력을 낮추기 위해 했던 행동들이 결과적으로는 우울과 무기력을 심화시키고 있다는 점이에요.",
      typography_7_0_0: "부정 강화 행동이죠.",
      typography_8_0_0:
        "부정 강화 행동은 단기적인 측면에서 분명 내가 느끼는 부정적인 감정이나 생각을 감소시켜 줬죠.",
      typography_9_0_0:
        "그런 이점이 있기 때문에 나는 그 행동을 더 자주 지속하게 됩니다.",
      typography_10_0_0:
        "하지만 장기적인 관점에서 보았을 때 그 행동들은 오히려 우울과 무기력을 심화시키고 있어요.",
      typography_11_0_0:
        "그러면 심화된 우울과 무기력에 대처하기 위해 더 애쓰게 돼요.",
      typography_12_0_0: "하지만 결과는 악순환일 뿐이에요.",
      typography_13_0_0: "그림으로 한눈에 정리해 보면 다음과 같을 거예요.",
      typography_14_0_0:
        "이렇게 보면 당신이 무기력과 우울감에 빠진 건 당연한 일인 것 같기도 합니다.",
      typography_15_0_0:
        "당신이 잘못하거나 부족해서가 아니라, 그저 삶의 과정에서 어느 순간 이 악순환의 고리에 빠져든 것뿐이죠.",
      typography_16_0_0:
        "누구든 이 악순환에 빠져들면 당신처럼 느끼고, 행동하고, 무기력에 빠질 수밖에 없을 거예요.",
      typography_17_0_0:
        "당신에게 주어졌던 일의 사건들을 고려해 보면 당신이 느끼는 무기력과 우울감은 충분히 이해할 수 있을 거예요.",
      typography_18_0_0:
        "자, 이제 우리는 앞으로 일상 속 활동들을 기록하며 이 악순환 패턴이 반복되는지 확인해 볼 거예요.",
      typography_19_0_0:
        "그리고 악순환 패턴에 대해 어떻게 대처하고 반응해야 할지도 알아볼 거랍니다.",
      typography_20_0_0:
        "또한 삶을 선순환 패턴으로 되돌리기 위한 행동들도 늘려나갈 거예요.",
      typography_21_0_0:
        "잠깐, 그런데 선순환 패턴은 구체적으로 어떤 모습인지 상상이 되나요?",
      typography_22_0_0:
        "아마 그렇지 않을 거예요. 무엇이 내가 원하는 방향인지조차 모르는 사람들도 많거든요.",
      typography_23_0_0:
        "다음 시간에는 우리가 함께 만들어 나갈 선순환 패턴에 대해 알아볼게요.",
    },
  },
};
