import { axiosInstance } from "api/instance";

export const SELFCHECK_DOMAIN = "self-check";

export const SELFCHECK_SCORE_SAVE_ENDPOINT = "score/save";

export interface SelfCheckScoreType {
  depressionScore: number;
  anxietyScore: number;
  stressScore: number;
  burnoutScore: number;
}

export const selfCheckApi = {
  save: (body: SelfCheckScoreType) =>
    axiosInstance.post<void>(
      `${SELFCHECK_DOMAIN}/${SELFCHECK_SCORE_SAVE_ENDPOINT}`,
      body
    ),
};
