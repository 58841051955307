import { useEffect, useState } from "react";
import { ProgramType } from "data/programData";
import useFetch from "./useFetch";
import { setDataWithEditorKeyAndTranslationVersion } from "./useGetBlankTaskData";
import useGetBlankSegmentationNoteData from "./useGetBlankSegmentationNoteData";

export default function useGetSegmentationNoteData({
  snk,
  isFirst,
}: {
  snk?: string;
  isFirst?: boolean;
}) {
  const [data, setData] = useState<ProgramType>();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const blankSegmentationNoteData = useGetBlankSegmentationNoteData({
    snk: snk,
    isFirst: isFirst,
  });

  const [fetchedData, getData] = useFetch({
    url: "/segmentation-note/data/load",
    params: { snk: snk },
  });

  useEffect(() => {
    if (snk && isFirst !== undefined) {
      getData();
      setIsFetching(true);
    }
  }, [snk]);

  useEffect(() => {
    if (fetchedData) {
      const totalData = (fetchedData?.result || {}).data;
      if (totalData && totalData.content.length > 0) {
        console.log("FETCH: ", totalData);
        setData(setDataWithEditorKeyAndTranslationVersion(totalData));
      } else {
        console.log("RAW: ", blankSegmentationNoteData);
        setData(blankSegmentationNoteData);
      }
    }
  }, [fetchedData]);

  useEffect(() => {
    if (data) {
      setIsFetching(false);
    }
  }, [data]);

  return { data, refetch: getData, isFetching };
}