import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const NOTIFICATION_DOMAIN = "notification";

export const NOTIFICATION_HISTORY_ENDPOINT = "history/detail";
export const NOTIFICATION_SEND_ENDPOINT = "send";
export const NOTIFICATION_DONE_ENDPOINT = "done";

export interface NotificationHistoryType {
  taskOpenTodaySendCount: number;
  taskOpenLastSendDateTime: string;
  presetMessage: string;
}

export const notificationApi = {
  getHistory: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<NotificationHistoryType>>(
      `${NOTIFICATION_DOMAIN}/${NOTIFICATION_HISTORY_ENDPOINT}`,
      { params: { pid: pid } }
    ),
  send: (pid: string, message: string) =>
    axiosInstance.post<void>(
      `${NOTIFICATION_DOMAIN}/${NOTIFICATION_SEND_ENDPOINT}`,
      {
        type: "task_open",
        message: message,
      },
      { params: { pid: pid } }
    ),
  done: (pid: string) =>
    axiosInstance.post<void>(
      `${NOTIFICATION_DOMAIN}/${NOTIFICATION_DONE_ENDPOINT}`,
      {
        type: "remind",
      },
      { params: { pid: pid } }
    ),
};
