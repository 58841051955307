import { translation_4_5_A } from "./4-5-A";
export const translation_4_5_5_A = {
  en: {
    v1: {
      ...translation_4_5_A.en.v1.start,
      typography_0_0_0: "Stepping back from Conceptualization",
      typography_1_0_0:
        "Stepping back from Conceptualization involves three steps:",
      typography_1_3_0:
        "Recognize when conceptualizations pop up in your mind.",
      typography_1_5_0:
        "Understand how these conceptualizations justify themselves and suppress change.",
      typography_1_7_0:
        "Distance yourself from these conceptualizations and choose responses that align with your values.",
      typography_2_0_0:
        "Let's tackle this one step at a time. First, let’s revisit the automatic thoughts.",
      typography_3_0_0:
        "Shall we clarify the conceptualized parts in my record?",
      typographyFromData_4_0_0: `We'll write more specifically about the reasons behind thoughts like "$A$" and feelings like "$B$". `,
      typography_5_0_0:
        "Looking back at my life, what events led me to have such thoughts and feelings?",
      typographyFromData_6_0_0:
        'Does someone who has followed a life path like mine always think thoughts like "$A$" regardless of when, where, or who they are with?',
      typography_7_0_0:
        "Probably not. These are just parts of our lives, not the complete story about us.",
      typography_8_0_0:
        "Remember, the stories we conceptualize don't always apply to us, not now, not ever.",
      typography_9_0_0:
        "Sure, there were reasons for these thoughts and feelings, and they were valid at the time.",
      typography_10_0_0:
        "But right now, in this moment, conceptualization is just another thought that comes to mind.",
      typography_11_0_0:
        "If we focus on the present moment, we don’t have to fit our future lives into these thought patterns.",
      typographyFromData_12_0_0:
        'What impact might accepting a story like "$A$" as absolute truth have on us?',
      typographyFromData_13_0_0:
        'If I accept "$A$" as is, what aspects of my diverse self might I miss out on?',
      typographyFromData_14_0_0:
        'If I accept "$A$" as is, what could my future life look like?',
      typographyFromData_15_0_0:
        'Now, imagine feeling something like "$A$". If I believe "$B$" to be absolute truth, could I possibly present that conceptualized story as the reason for my current feelings?',
      typographyFromData_16_0_0:
        'If I justify my feelings with thoughts like "$A$", what changes might occur in my life?',
      typographyFromData_17_0_0:
        'Do reasons like "$A$" help me break free from negative thoughts and feelings?',
      typographyFromData_18_0_0:
        'If I choose not to react to the conceptualization "$A$", where would I rather spend that energy and time?',
      typographyFromData_19_0_0:
        'If I choose not to react to the conceptualization "$A$", where would I rather spend that energy and time?',
      typography_19_4_0:
        "I've been diagnosed with depression. That's just who I am. My life will always be this way.",
      typography_19_6_0:
        "I am conceptualizing a life as 'a person with depression'.",
      typography_19_10_0:
        "My parents fought a lot and divorced when I was young. It hurt me deeply. Now, I'm always craving love. I have an emotional deficiency.",
      typography_19_12_0:
        "I am creating a story related to 'my parents' conflict'.",
      typography_20_0_0:
        "Read the rewritten Conceptualization again. Close your eyes for about 10 seconds, take deep breaths, and imagine the distance between you and your thoughts. It really helps to visualize it!",
      ...translation_4_5_A.en.v1.end,
    },
  },
  ko: {
    v1: {
      ...translation_4_5_A.ko.v1.start,
      typography_0_0_0: "개념화로부터 거리두기",
      typography_1_0_0:
        "개념화로부터 거리두기는 다음과 같은 세 단계로 진행돼요.",
      typography_1_3_0: "마음속에 개념화가 떠오른다는 것을 알아차린다.",
      typography_1_5_0:
        "개념화가 어떻게 스스로를 정당화하며 변화를 억압하고 있는지 파악한다.",
      typography_1_7_0:
        "개념화로부터 거리를 두고 다시 나의 가치에 맞는 반응을 선택한다.",
      typography_2_0_0:
        "하나씩 해볼게요. 자, 우선 아래 자동적 사고를 다시 읽어보세요.",
      typography_3_0_0:
        "우선 나의 기록에서 개념화가 되어있는 부분을 명확하게 해볼까요?",
      typographyFromData_4_0_0:
        '"$A$"와(과) 같은 생각과 "$B$"와(과) 같은 감정을 가지게 된 연유를 조금 더 구체적으로 적어볼게요.',
      typography_5_0_0:
        "지난 삶을 돌아보았을 때 내가 위와 같은 생각과 감정을 가지게 된 계기가 있을까요?",
      typographyFromData_6_0_0:
        '위와 같은 삶의 경로를 밟은 사람은 언제나/어디서나/누구에게나/항상/변함없이 "$A$"와(과) 같은 생각을 하나요?',
      typography_7_0_0:
        "아마 그렇진 않을 거예요. 그건 우리 삶의 한 부분이지, 나에 대한 전체를 이야기해 주는 건 아니니까요.",
      typography_8_0_0:
        "자, 개념화된 이야기가 항상, 언제나, 앞으로도 변함없이 나에게 적용되지 않는다는 걸 명심하세요.",
      typography_9_0_0:
        "물론 내가 위와 같은 생각과 감정을 가지게 된 이유는 있었고, 그 또한 분명 당시의 경험을 돌아보면 타당하고 당연한 것이었어요.",
      typography_10_0_0:
        "하지만 지금 이 순간에서는 개념화 역시 마음속에 떠오르는 하나의 생각이 될 수 있답니다.",
      typography_11_0_0:
        "지금 이 순간으로 생각의 초점을 돌린다면 앞으로의 삶을 반드시 그 생각의 틀에 맞출 필요는 없을 거예요.",
      typographyFromData_12_0_0:
        '그러면 "$A$"와(과) 같은 이야기를 절대적 사실로 받아들이면 어떠한 영향이 있는지 파악해볼까요?',
      typographyFromData_13_0_0:
        '"$A$"(을)를 그대로 받아들이면, 나는 나의 또 다른 모습 중 어떤 것들을 놓치고 살게 될까요?',
      typographyFromData_14_0_0:
        '"$A$"(을)를 그대로 받아들이면, 이후의 삶은 어떤 모습으로 흘러갈 가능성이 있을까요?',
      typographyFromData_15_0_0:
        '이번엔 마음속에 "$A$"와(과) 같은 기분이 느껴졌다고 생각해보죠. 이때 "$B$"을(를) 절대적 사실로 믿는다면, 나는 그 개념화된 이야기를 지금 느끼는 기분의 이유로 제시할 가능성이 있나요?',
      typographyFromData_16_0_0:
        '만약 내가 느끼는 감정에 대해 "$A$와(과) 같은 이유를 제시하고 있다면, 나의 삶에는 어떤 변화가 있을 것 같나요?',
      typographyFromData_17_0_0:
        '"$A$"와(과) 같은 이유는 내가 부정적인 생각과 감정에서 빠져나오는 데에 도움이 되나요?',
      typographyFromData_18_0_0:
        '만약 내가 "$A$"(이)라는 개념화에 반응하지 않기로 선택할 수 있다면, 나는 그 에너지와 시간을 어느 곳에 사용하고 싶나요?',
      typographyFromData_19_0_0:
        '마음속에 떠오른 "$A$"을(를) 거리두기를 통해 바라본 표현으로 다시 바꿔 적어보세요. 아래와 같은 예시를 참고해보세요.',
      typography_19_4_0:
        "난 우울증을 진단받았어. 우울증 환자인 거지. 앞으로의 삶도 그럴 거야.",
      typography_19_6_0: "나는 '우울증 환자의 삶'이라는 개념화를 하고 있다.",
      typography_19_10_0:
        "어릴 적 부모님이 자주 싸우고 이혼한 건 너무 큰 상처였어. 그것 때문에 나는 항상 사랑을 갈구하게 돼. 나는 애정결핍을 가지게 된 거야.",
      typography_19_12_0:
        "나는 '부모님의 갈등'과 관련된 개념화된 이야기를 만들고 있다.",
      typography_20_0_0:
        "거리두기를 통해 바꿔 적어본 개념화를 다시 읽어보세요. 잠시 눈을 감고 약 10초 동안 심호흡을 하며 생각과 나 사이의 거리감을 상상해 보면 더 좋아요.",
      ...translation_4_5_A.ko.v1.end,
    },
  },
};
