import { useRecoilState } from "recoil";
import { layoutAtom } from "recoil/layoutAtom";

export default function useSidebarToggle({
  direction = "left",
}: {
  direction?: "left" | "right";
}) {
  const [{ isLeftSideBarOpen, isRightSideBarOpen }, setLayoutState] =
    useRecoilState(layoutAtom);
  const isLeft = direction === "left";
  const key = isLeft ? "isLeftSideBarOpen" : "isRightSideBarOpen";

  const isSidebarOpen = isLeft ? isLeftSideBarOpen : isRightSideBarOpen;
  const closeSidebar = () => {
    setLayoutState((state) => ({ ...state, [key]: false }));
  };
  const openSidebar = () => {
    setLayoutState((state) => ({ ...state, [key]: true }));
  };
  return { closeSidebar, openSidebar };
}
