import { Stack, Typography } from "@mui/joy";
import { findSubstringIndices } from "logic/logics";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";

const CheckIcon = () => {
  return (
    <Stack
      sx={{
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        backgroundColor: "primary.solid",
      }}
      justifyContent="center"
      alignItems={"center"}
    >
      <svg
        width="11"
        height="8"
        viewBox="0 0 11 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.36365 4.38959L3.70131 6.72725L9.15586 1.27271"
          stroke="white"
          strokeWidth="2.36364"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Stack>
  );
};

export default function Keypoints() {
  const userState = useRecoilValue(userAtom);
  const isAiCoachProduct = userState?.isAiCoachProduct;

  return (
    <Stack
      spacing={"10px"}
      sx={{
        borderRadius: "12px",
        px: "20px",
        py: "25px",
        backgroundColor: "primary.soft",
      }}
    >
      {[
        {
          text: "최신 인지과학 기반 맞춤 프로그램",
          boldText: "최신 인지과학 기반",
        },
        {
          text: `매일 전문 ${isAiCoachProduct ? "AI 코치" : "코치"}의 피드백`,
          boldText: isAiCoachProduct ? "매일 전문 AI 코치" : "매일 전문 코치",
        },
        {
          text: "실용적인 관리 도구",
          boldText: "실용적인 관리 도구",
        },
      ].map(({ text, boldText }, index) => (
        <Stack
          key={`keypoint${index}`}
          direction="row"
          alignItems={"center"}
          spacing="12px"
        >
          <CheckIcon />
          <Typography level="body-md">
            <div>
              {[
                text.substring(
                  0,
                  findSubstringIndices(text, boldText).startIndex
                ),
                boldText,
                text.substring(
                  findSubstringIndices(text, boldText).endIndex + 1
                ),
              ].map((each) =>
                each === boldText ? (
                  <b key={each}>{each}</b>
                ) : (
                  <span key={each}>{each}</span>
                )
              )}
            </div>
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}
