export const translation_1_2 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "정말 우리가 의지를 갖고 생각하는 존재인지 알아보기 위해, 한 가지 사고 실험을 해보도록 할게요.",
      typography_1_0_0: "짚신벌레",
      typography_2_0_0: `머리속에서 짚신벌레를 떠올려 보세요.`,
      typography_3_0_0:
        "짚신벌레가 무엇인지 모른다면, 그냥 물 속에서 헤엄치는 단 하나의 세포로 이루어진 생명체를 상상하면 됩니다.",
      typography_4_0_0: "짚신벌레는 물 위를 유유히 헤엄치고 있습니다.",
      typography_5_0_0: "이 방향으로 갔다, 저 방향으로 갔다하면서요.",
      typography_6_0_0:
        "자, 짚신벌레는 자신의 의지대로 자유롭게 행동하고 있는 걸까요?",
      typography_7_0_0:
        "짚신벌레에게는 화학주성(chemotaxis)라는 특성이 있습니다.",
      typography_8_0_0: `특정한 화학 물질을 향해 이동하는 성질이죠.`,
      typography_9_0_0:
        "짚신벌레는 그저 특정한 화학 물질을 찾아 이동할 뿐입니다.",
      typography_9_1_0:
        "때문에 과학자들은 짚신벌레가 물 위에서 어떠한 경로로 이동할지 아주 쉽게 예측할 수 있습니다.",
      typography_10_0_0:
        "심지어는 그 화학 물질로 짚신벌레를 특정한 방향으로 이동하도록 조종할 수도 있죠.",
      typography_11_0_0:
        "어떤가요? 짚신벌레는 아마도 자신의 의지대로 행동하는 생명체는 아닌 것 같습니다.",
      typography_12_0_0:
        "짚신벌레는 그저 외부 자극에 따라 움직이는 생명체일 뿐입니다.",
      typography_13_0_0: "개미",
      typography_14_0_0: "이번엔 개미를 떠올려 봅시다.",
      typography_15_0_0: "개미는 자유롭게 행동하는 생명체일까요?",
      typography_16_0_0:
        "아시다시피, 개미는 페로몬이라는 화학 물질을 사용하여 소통하고 이동합니다.",
      typography_17_0_0:
        "개미가 페로몬을 따라 이동하는 방식은 짚신벌레의 경우보다 훨씬 복잡합니다.",
      typography_18_0_0:
        "하지만 과학자들은 개미가 페로몬을 따라 이동하는 방식을 수학적 계산을 통해 모델링할 수 있습니다.",
      typography_19_0_0:
        "즉, 우리는 개미 또한 어떻게 이동하고 행동할지 거의 정확하게 예측할 수 있다는 뜻이죠.",
      typography_20_0_0:
        "개미도 역시 외부 자극에 따라 움직이는 생명체일 뿐입니다.",
      typography_21_0_0: "강아지, 그리고 인간",
      typography_22_0_0: "그렇다면 강아지는 어떨까요?",
      typography_23_0_0: "아니 조금 더 확장해서, 우리 인간은 어떨까요?",
      typography_24_0_0: "외계인이 있다고 가정해봅시다.",
      typography_25_0_0: "이 외계인은 매우 진보한 생명체입니다.",
      typography_26_0_0:
        "우리가 짚신벌레에 대한 모든 정보를 알고 있는 것처럼, 이 외계인은 인간에 대한 모든 정보를 알고 있습니다.",
      typography_27_0_0:
        "인간의 유전자, 신경 전달 물질, 더 나아가 이것들이 외부 환경에 따라 어떻게 작동하는지 그 모든 정보에 대해 알고 있습니다.",
      typography_28_0_0:
        "그렇다면 이 외계인의 입장에서, 인간은 자유롭게 생각하고 행동하는 생명체일까요?",
      typography_29_0_0: "아마 아닐 겁니다.",
      typography_30_0_0:
        "외계인의 입장에서 인간은 그저 외부 자극에 따라 생각하고 행동하는 생명체일 뿐입니다.",
      typography_31_0_0: "우리가 어떻게 느끼는지와는 상관이 없습니다.",
      typography_32_0_0:
        "아마 짚신벌레도, 개미도, 강아지도, 그들 스스로는 의지를 갖고 행동하고 있다고 믿을 테니까요.",
      typography_33_0_0:
        '자 다시 강조하겠습니다. "생각하는 나"는 착각일 뿐입니다.',
      typography_34_0_0: "아직 이 개념이 완벽히 와닿지 않을 수 있습니다.",
      typography_35_0_0:
        "하지만 괜찮습니다. 이제 막 우린 디스턴싱 훈련을 시작했으니까요.",
      typography_36_0_0:
        "프로그램을 진행하며 자연스럽게 이 개념을 몸소 터득해 나게 될 겁니다.",
      typography_37_0_0:
        "다음 활동에서는 마지막으로 이 개념에 대해 논리적 탐구를 거치고, 앞으로의 방향성에 대해 이야기해보는 시간을 가져볼게요.",
    },
  },
};
