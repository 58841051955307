export const translation6_97_A_common = {
  en: {
    v1: {
      part1: {
        part1_typography_0_0_0: "We identified the following core beliefs.",
        part1_typography_1_0_0:
          "Shall we recall? When do you think was the first moment you had such thoughts? Or when did these thoughts start to settle in your heart? Feel free to write at length if it's a long story.",
      },
    },
  },
  ko: {
    v1: {
      part1: {
        part1_typography_0_0_0: "우리는 아래와 같은 핵심 믿음을 찾았어요.",
        part1_typography_1_0_0:
          "한번 기억을 되짚어볼까요? 위와 같은 생각을 가지게 된 가장 최초의 순간은 언제인 것 같나요? 혹은 언제부터 조금씩 그러한 생각들이 내 마음속에 자리 잡았던 것 같나요? 긴 이야기라면 얼마든지 길게 작성해주셔도 좋아요.",
      },
    },
  },
};

export const translation_6_97_A = {
  en: {
    v1: {
      typography_0_0_0:
        "In our last activity, we looked into what my core beliefs are.",
      typography_1_0_0: "Shall we take another look?",
      typography_2_0_0:
        "So, when did these core beliefs start taking root in my heart?",
      typography_3_0_0:
        "Earlier, I explained that 'core experiences' play a crucial role in forming certain beliefs.",
      typography_4_0_0:
        "Especially, core experiences often develop from repeated experiences accumulated since childhood.",
      typography_5_0_0:
        "Of course, it doesn't have to be only during childhood. Such beliefs can form during adolescence or adulthood too, either through repeated painful experiences or even from a single most painful experience.",
      typography_6_0_0: `Shall we look at an example?`,
      typography_7_0_0:
        "Jordi grew up with parents who disciplined with high standards from a young age.",
      typography_8_0_0:
        "Jordi always felt the direct or implicit pressure from his parents to outperform others. That's a core experience.",
      typography_9_0_0: `As a result, Jordi harbored the belief, "I am always lacking," in his heart. That's a core belief.`,
      typography_10_0_0: "Here's another example.",
      typography_11_0_0:
        "James, after retiring, started a startup and ran it for 5 years.",
      typography_12_0_0: `But then, a recession hit. James couldn't escape its impact.`,
      typography_13_0_0:
        "Eventually, James's business failed. That's a core experience.",
      typography_14_0_0: `In James's mind, beliefs like "I am a failure" and "I am incompetent" took hold.`,
      typography_15_0_0: `Now, do you understand how core experiences shape core beliefs?`,
      typography_16_0_0: "Let's now uncover my story.",
      typography_17_0_0: "My experience",
      ...translation6_97_A_common.en.v1.part1,
      typography_18_0_0:
        "Alright. Let's first review the above story and then proceed further. You've done well in this activity too!",
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "지난 활동에서는 내가 가진 핵심 믿음이 무엇인지 살펴보았어요.",
      typography_1_0_0: "다시 한 번 살펴볼까요?",
      typography_2_0_0:
        "자, 그렇다면 이 핵심 믿음은 대체 언제부터 내 마음속에 자리잡게 되었던 걸까요?",
      typography_3_0_0:
        "앞서 '핵심 경험'이 특정한 믿음을 형성하는 데에 결정적인 역할을 한다고 설명했어요.",
      typography_4_0_0:
        "특히 핵심 경험은 주로 어릴 적부터 반복적으로 누적되어온 경험에 의해 형성되는 경우가 많아요.",
      typography_5_0_0:
        "물론 꼭 어린 시절이 아닐 수도 있어요. 청소년기, 성인기에도 특정한 아픈 경험이 반복되거나, 혹은 가장 고통스러운 단 한 번의 경험으로도 형성될 수 있죠.",
      typography_6_0_0: `예를 들어볼까요?`,
      typography_7_0_0:
        "조르디는 어릴 적부터 높은 기준을 가지고 훈육하는 부모님 밑에서 자랐어요.",
      typography_8_0_0:
        "조르디는 항상 남들보다 더 잘해야 한다는 부모님의 직접적인 혹은 암묵적인 압박을 느껴왔죠. 핵심 경험이에요.",
      typography_9_0_0: `그렇다 보니 조르디의 마음속에는 "나는 늘 부족하다"라는 생각이 자리잡았죠. 핵심 믿음이에요.`,
      typography_10_0_0: "또 다른 예시도 있어요.",
      typography_11_0_0:
        "제임스는 퇴직 후 스타트업을 창업하여 5년 동안 사업체를 운영해왔어요.",
      typography_12_0_0: `하지만 그러다가 불경기가 찾아왔죠. 제임스도 그 흐름을 피해갈 순 없었어요.`,
      typography_13_0_0:
        "결국 제임스의 사업체도 망하고 말았죠. 핵심 경험이에요.",
      typography_14_0_0: `이제는 아무것도 남아있지 않다고 생각하는 제임스의 마음속에는 "나는 실패자다", "나는 무능하다"와 같은 핵심 믿음이 자리잡았어요.`,
      typography_15_0_0: `자, 이처럼 핵심 경험이 어떻게 핵심 믿음을 만들게 되는지 이해가 되었나요?`,
      typography_16_0_0: "이번엔 나의 이야기를 살펴볼게요.",
      typography_17_0_0: "나의 경험",
      ...translation6_97_A_common.ko.v1.part1,
      typography_18_0_0:
        "좋아요. 우선은 위 이야기를 한번 살펴본 후에 조금 더 진행해보도록 할게요. 이번 활동도 고생 많았어요!",
    },
  },
};
