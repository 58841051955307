export const translation_8_10_A = {
  en: {},
  ko: {
    v1: {
      typography_1: `앞서 생각은 ‘나'가 아니고 그저 마음속에 자동적으로 떠오르기 때문에`,
      typography_2: `생각과 거리를 둘 수 있다면 그 생각에 강하게 반응하지 않을 수 있다고 했는데요.`,
      typography_3: `사실 이건 쉬운 일이 아니랍니다.`,
      typography_4: `생각은 아주 강력한 상징적인 효과를 지니기 때문이에요.`,
      typography_5: "지금 여기 실재하는 레몬",
      typography_6:
        "한 가지 재미있는 활동을 해볼게요. 과일 '레몬'을 떠올려보시겠어요?",
      typography_7: "자, '레몬'이라는 단어를 읽고,",
      typography_8:
        "레몬의 색, 질감, 냄새 등, 레몬의 속성을 몇 가지 적어볼까요?",
      typography_9:
        "좋아요. 이번엔 레몬을 한 입 크게 베어 먹었다고 생각해볼게요. 맛이 느껴지나요? 그렇다면 무슨 맛처럼 느껴지는지 적어보세요.",
      typography_10:
        "레몬을 베어 먹는 상상을 했을 때 입 안에서 침이 고이진 않았나요? 그러한 상상을 할 때 나의 몸은 어떻게 반응했나요?",
      typography_11: "좋아요. 지금 입 안에 레몬이 있는 건 아니에요.",
      typography_12:
        "하지만 그럼에도 여러분은 아마도 그 맛을 느낄 수 있었을 겁니다. 그 상상에 반응해 침샘에서 침이 분비되는 경험을 했을 거예요.",
      typography_13:
        "이는 인간의 언어가 매우 상징적인 효과를 지니고 있기 때문이에요.",
      typography_14:
        "우리는 신맛이 있는 어떤 과일을 직접 먹지 않았지만, '레몬'이라는 언어가 가지고 있는 상징적 효과에 반응하게 되죠.",
      typography_15:
        "우리의 마음은 이처럼 아주 빠르게 어떠한 언어적 표현에 대해 상징적 의미를 부과하는 경향을 지녔어요. 그것이 지금 여기에 마치 실재하는 것처럼 말이죠.",
      typography_16: "껍데기가 되어버린 레몬",
      typography_17: "잠시 이 상징적인 효과를 일시적으로 줄여보도록 할게요.",
      typography_18:
        "또 다른 연습을 하나 더 해 볼 텐데요. 바보 같아 보이는 연습이지만 충분히 검증된 중요한 연습이니 진지하게 임해보시길 바랄게요.",
      typography_19: "자, 우선 방해받지 않을 조용한 장소를 찾아보세요.",
      typography_20:
        "이제 스톱워치를 켜고, '레몬'이라는 단어를 30초 동안, '빨리', '크게 소리 내어' 말해볼 거예요.",
      typography_21:
        "금세 지루해질 수도 있지만, 집중력을 놓지 말고, 딱 30초만 지속해보세요. 자, 시작해볼까요?",
      typography_22: "완료했나요? 꼭 실제로 해 본 후 넘어가셔야 해요.",
      typography_23:
        "자, '레몬'이라는 단어를 다시 살펴보세요. 무슨 일이 벌어지나요?",
      typography_24:
        "이전처럼 동일한 맛이 느껴졌나요? 침샘에서 침이 흘러나왔나요?",
      typography_25:
        "아마 그렇지는 않았을 겁니다. 30초 동안 정말로 반복했다면, 스스로가 허무맹랑한 단어를 무의미하고 지루한 표정으로 내뱉고 있었다는 걸 알 수 있을 거예요.",
      typography_26:
        "이 연습을 하는 동안 '레몬'이라는 단어의 상징적 효과는 일시적으로 사라지기 시작해요.",
      typography_27:
        "'레몬'이라는 단어는 상징적인 의미를 잃고 껍데기가 되어버렸네요.",
      typography_28:
        "하지만 우리가 그 상징 속에서 헤엄을 치고 있는 동안에는 '레몬'이라는 단어가 그저 소리뿐일 수 있음을 알아차리기란 매우 어려운 일이죠.",
      typography_29: "침샘을 자극하는 나의 생각",
      typography_30: "최근에 느꼈던 부정적인 경험을 떠올려 적어보세요.",
      typography_31:
        "아마 과거의 한 상황과 그때 했던 생각과 행동을 떠올리고는 부정적인 감정을 느끼기 시작했을 거예요.",
      typography_32: "그런데 이상하지 않나요?",
      typography_33:
        "여기서 벌어진 일이라고는 기껏해야 화면 위에 검은색 픽셀로 찍혀진 글자를 바라보고 있다는 것이 전부랍니다. 그 외 다른 일은 없었어요.",
      typography_34:
        "하지만 우리는 언어적으로 묘사된 이 화면 위의 픽셀에 상징적으로 반응하기 때문에, 기록을 읽기만 해도 어떤 반응이 유발되는 것이죠.",
      typography_35:
        "이러한 과정을 거쳐 우리의 생각들은 지금 이 순간에 실재하는 것처럼 생생하게 경험될 수 있어요.",
      typography_36:
        "이미 소멸해서 없어진 과거도, 무수한 가능성을 가진 여러 가능성 중 하나인 미래도, 현재에 존재하는 일이 되어버리는 거죠.",
      typography_37: "적어도 우리의 마음속에서는요.",
      typography_38:
        "이번엔 위에 떠올린 경험에서 자신에게 품었던 부정적인 생각을 한 가지 단어로 만들어보세요. 짧으면 짧을수록 좋아요. (예: 바보, 불안, 우울, 무기력 등)",
      typography_39: "이 단어는 나에게 얼마나 영향을 주고 있나요? ",
      typography_40:
        "다시 앞에서 했던 것과 똑같은 과정을 해보죠. 역시나 바보 같아 보이지만, 이는 매우 중요한 연습이니 진지하게 임해보시길 바라요.",
      typography_41: "자, 우선 방해받지 않을 조용한 장소를 찾아보세요.",
      typography_42:
        "이제 스톱워치를 켜고, '$A$'(이)라는 단어를 30초 동안, '빨리', '크게 소리 내어' 말해볼 거예요.",
      typography_43:
        "금세 지루해질 수도 있지만, 집중력을 놓지 말고, 딱 30초만 지속해보세요. 자, 시작해볼까요?",
      typography_44: "완료했나요? 꼭 실제로 해 본 후 넘어가셔야 해요.",
      typography_45:
        "위 과정을 진행하는 동안에는 '$A$'(이)라는 단어가 여러분에게 동일한 정서적 영향을 미쳤나요?",
      typography_46: "지금 이 단어는 나에게 얼마나 영향을 주고 있나요?",
      typography_47:
        "아마 레몬의 예와 비슷하게 여전히 우리는 그 생각의 의미를 알고 있지만, 그 생각으로 인한 정서적인 효과는 줄어들었음을 알 수 있었을 거예요.",

      typography_48: "레몬 맛 팝콘과 거리두기",
      typography_49: "이처럼 생각은 상징적인 효과를 지니기 때문에,",
      typography_50:
        "생각은 내가 능동적으로 하는 것이 아니고, 또한 그 생각은 마음속에 자동적으로 떠오른다는 사실은 우리에게 아주 큰 위협으로 다가와요.",
      typography_51:
        "이러한 과정을 통해 이미 잊혀지고 지나간 과거도 지금 이 순간의 우울이 될 수 있고,",
      typography_52:
        "아직 다가오지, 어쩌면 영영 발생하지 않았을 먼 미래의 사건도 지금 이 순간의 불안이 될 수 있답니다.",
      typography_53: "우리는 그러한 생각들에 아주 강하게 영향받아요.",
      typography_54:
        "우리는 머릿속에서 끊임없이 상상의 세상을 실제로 만들어간다는 사실을 알아차리지 못한 채 그러한 세상과 상호작용하며 살아가요.",
      typography_55:
        "마음이 흔들릴 때 생각과 거리두기가 필요한 것도 이러한 이유 때문입니다.",
      typography_56: "자, 정리하면 생각은 마음속에서 팝콘처럼 튀어올라요.",
      typography_57:
        "그 팝콘들은 마치 레몬처럼 상징적인 의미를 통해 강한 생각과 감정을 불러일으키죠.",
      typography_58: "심지어 그 대상이 현재 눈앞에 없다고 할지라도요.",
      typography_59: `이에 대한 유일한 대처는 ‘생각과 새로운 관계 맺기'랍니다.`,
      typography_60: `즉, 생각을 하나의 심리적 사건으로 바라보는 연습을 통해 생각으로부터 받는 영향을 줄이고, 나의 의지에 맞게 행동해나갈 수 있도록 하는 것이죠.`,
      typography_61: `디스턴싱에서 연습하고 있는 생각 기록, 명상의 목적은 모두 “생각을 하나의 심리적 사건으로 바라보기"라는 점을 꼭 기억하세요.`,
    },
  },
};
