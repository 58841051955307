import { Header1, Instruction, InstructionWithImage } from "../../BlockComponent";

import { LastCard } from "../common";

export const Content_9_4_A = [
  InstructionWithImage(`typography_0`, "1"),
  Instruction("typography_1"),
  Instruction(`typography_2`),
  Instruction(`typography_3`),
  Instruction(`typography_4`),
  Instruction(`typography_5`),

  Header1(`typography_6`),
  Instruction(`typography_7`),
  Instruction(`typography_8`),
  Instruction(`typography_9`),
  Instruction(`typography_10`),
  Instruction(`typography_11`),
  Instruction(`typography_12`),
  Instruction(`typography_13`),
  Instruction(`typography_14`),
  Instruction(`typography_15`),
  Instruction(`typography_16`),
  Instruction(`typography_17`),
  Instruction(`typography_18`),
  Instruction(`typography_19`),
  Instruction(`typography_20`),
  Instruction(`typography_21`),
  Instruction(`typography_22`),

  Header1(`typography_23`),
  Instruction(`typography_24`),
  Instruction(`typography_25`),
  Instruction(`typography_26`),
  Instruction(`typography_27`),
  Instruction(`typography_28`),
  Instruction(`typography_29`),
  Instruction(`typography_30`),
  Instruction(`typography_31`),
  Instruction(`typography_32`),
  Instruction(`typography_33`),
  Instruction(`typography_34`),
  Instruction(`typography_35`),
  Instruction(`typography_36`),
  Instruction(`typography_37`),
  Instruction(`typography_38`),
  Instruction(`typography_39`),
  Instruction(`typography_40`),
  Instruction(`typography_41`),

  Header1(`typography_42`),
  Instruction(`typography_43`),
  Instruction(`typography_44`),
  Instruction(`typography_45`),
  Instruction(`typography_46`),
  Instruction(`typography_47`),
  InstructionWithImage(`typography_48`, "2"),
  Instruction(`typography_49`),
  Instruction(`typography_50`),
  Instruction(`typography_51`),
  Instruction(`typography_52`),
  Instruction(`typography_53`),
  InstructionWithImage(`typography_54`, "3"),
  Instruction(`typography_55`),

  Header1(`typography_56`),
  Instruction(`typography_57`),
  Instruction(`typography_58`),
  Instruction(`typography_59`),
  Instruction(`typography_60`),
  Instruction(`typography_61`),
  Instruction(`typography_62`),
  Instruction(`typography_63`),
  Instruction(`typography_64`),
  Instruction(`typography_65`),

  ...LastCard,
];
