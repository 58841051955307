import { automaticThoughtIdType } from "api/conceptApi";
import {
  DateSelect,
  DividerLine,
  LineWithSolidLabel,
  SolidLabelLine,
  TextareaLine,
  TimeSelect,
  TypographyFromDataLine,
  TypographyLine,
} from "data/CellComponent";
import dayjs from "dayjs";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithContent,
  InstructionWithDataWithTextarea,
  InstructionWithTextarea,
} from "../../BlockComponent";
import { LastCard } from "../common";

const today = dayjs().format("YYYY-MM-DD");
const todayWithTime = dayjs().format("HH:00");

export const Content_4_9_A = (
  automaticThoughtId: automaticThoughtIdType,
  numberOfExperiment: number,
  experimentStep: number
) => [
  InstructionWithDataWithContent(
    { A: { id: "제목", taskId: `4-8-A_${automaticThoughtId}` } },
    `typography_0_0_0`,
    [
      ...LineWithSolidLabel(
        "실험 제목",
        TypographyFromDataLine(
          { A: { id: "제목", taskId: `4-8-A_${automaticThoughtId}` } },
          "$A$",
          { id: "제목" }
        )
      ),

      ...LineWithSolidLabel(
        "실험 가설",
        TypographyFromDataLine(
          { A: { id: "가설", taskId: `4-8-A_${automaticThoughtId}` } },
          "$A$",
          { id: "가설" }
        )
      ),

      ...LineWithSolidLabel(
        "실험 내용",
        TypographyFromDataLine(
          { A: { id: "실험행동", taskId: `4-8-A_${automaticThoughtId}` } },
          "$A$"
        )
      ),

      ...[
        SolidLabelLine(`가설이 맞다면`),
        ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
          TypographyFromDataLine(
            {
              A: {
                id: `가설 일치 예상 ${each}`,
                taskId: `4-8-A_${automaticThoughtId}`,
              },
            },
            "$A$",
            { id: `가설 일치 예상 ${each}` }
          ),
        ]),
      ],

      ...[
        SolidLabelLine(`가설이 틀렸다면`),
        ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
          TypographyFromDataLine(
            {
              A: {
                id: `가설 기각 예상 ${each}`,
                taskId: `4-8-A_${automaticThoughtId}`,
              },
            },
            "$A$",
            { id: `가설 기각 예상 ${each}` }
          ),
        ]),
      ],
    ]
  ),
  InstructionWithContent("typography_1_0_0", [
    ...Array.from({ length: numberOfExperiment }, (v, i) => i + 1).flatMap(
      (each) => [
        SolidLabelLine(`단계`, { prefixLabelIndex: each }),
        TypographyFromDataLine(
          {
            A: {
              id: `step_${each}`,
              taskId: `4-8-A_${automaticThoughtId}`,
            },
          },
          "$A$",
          {
            ...(each === experimentStep && {
              id: `실험내용`,
            }),
          }
        ),
      ]
    ),
  ]),
  InstructionWithData(
    {
      A: {
        id: `실험내용`,
      },
    },
    `typography_2_0_0`
  ),
  InstructionWithDataWithContent(
    {
      A: {
        id: `실험내용`,
      },
    },
    `typography_3_0_0`,
    [
      ...LineWithSolidLabel("날짜", [DateSelect({ id: "date", value: today })]),
      ...LineWithSolidLabel("시각", [
        TimeSelect({ id: "time", value: todayWithTime }),
      ]),
    ]
  ),

  Header1("typography_4_0_0"),
  Instruction("typography_5_0_0"),
  InstructionWithDataWithContent(
    { A: { id: `제목` } },
    `typography_6_0_0`,
    LineWithSolidLabel(
      "안전행동",
      TypographyFromDataLine(
        { A: { id: "안전행동", taskId: `4-8-A_${automaticThoughtId}` } },
        "$A$",
        { id: "안전행동" }
      )
    )
  ),
  Instruction("typography_7_0_0"),
  Instruction("typography_8_0_0"),
  InstructionWithContent("typography_9_0_0", [
    ...LineWithSolidLabel("안전행동", TypographyLine("typography_9_1_0")),
    ...LineWithSolidLabel("대처방안", TypographyLine("typography_9_2_0")),
  ]),
  InstructionWithContent("typography_10_0_0", [
    ...LineWithSolidLabel(
      "안전행동",
      TypographyFromDataLine({ A: { id: "안전행동" } }, "$A$")
    ),
    ...LineWithSolidLabel(
      "대처방안",
      TextareaLine({ id: "안전행동 대처방안" })
    ),
  ]),
  Instruction("typography_11_0_0"),
  Instruction("typography_12_0_0"),
  Instruction("typography_13_0_0"),

  InstructionWithDataWithTextarea(
    {
      A: {
        id: `실험내용`,
      },
    },
    "typography_14_0_0",
    { id: "어려움" }
  ),
  InstructionWithTextarea("typography_15_0_0", { id: "어려움 대처방안" }),

  Header1("typography_16_0_0"),
  InstructionWithContent(`typography_17_0_0`, [
    ...LineWithSolidLabel(
      "실험 제목",
      TypographyFromDataLine({ A: { id: "제목" } }, "$A$")
    ),

    ...LineWithSolidLabel(
      "실험 가설",
      TypographyFromDataLine({ A: { id: "가설" } }, "$A$")
    ),

    SolidLabelLine(`단계 실험 내용`, { prefixLabelIndex: experimentStep }),
    TypographyFromDataLine(
      {
        A: {
          id: `실험내용`,
        },
      },
      "$A$"
    ),

    SolidLabelLine(`언제`),
    TypographyFromDataLine(
      { A: { id: "date" }, B: { id: "time" } },
      "$A$ $B$",
      { id: "언제" }
    ),

    ...[
      SolidLabelLine(`가설이 맞다면`),
      ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
        TypographyFromDataLine(
          {
            A: {
              id: `가설 일치 예상 ${each}`,
            },
          },
          "$A$"
        ),
      ]),
    ],

    ...[
      SolidLabelLine(`가설이 틀렸다면`),
      ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
        TypographyFromDataLine(
          {
            A: {
              id: `가설 기각 예상 ${each}`,
            },
          },
          "$A$"
        ),
      ]),
    ],

    ...LineWithSolidLabel(
      "안전행동",
      TypographyFromDataLine({ A: { id: "안전행동" } }, "$A$")
    ),
    ...LineWithSolidLabel(
      "대처방안",
      TypographyFromDataLine({ A: { id: "안전행동 대처방안" } }, "$A$")
    ),

    ...LineWithSolidLabel(
      "어려움",
      TypographyFromDataLine({ A: { id: "어려움" } }, "$A$")
    ),
    ...LineWithSolidLabel(
      "대처방안",
      TypographyFromDataLine({ A: { id: "어려움 대처방안" } }, "$A$")
    ),
  ]),

  InstructionWithData(
    {
      A: {
        id: `실험내용`,
      },
    },
    `typography_18_0_0`
  ),
  ...LastCard,
];
