import { translation_meditation } from "./meditation";

export const translation_5_2_A = {
  en: {
    v1: {
      typography_0_0_0:
        "How was your last session with the Stream of Thoughts?",
      typography_1_0_0: "Short, but probably challenging, right?",
      typography_2_0_0:
        "During the Stream of Thoughts, you might confront some tough thoughts.",
      typography_3_0_0: "You could get deeply absorbed in these thoughts,",
      typography_4_0_0:
        "or maybe you try hard to think of something else to avoid them.",
      typography_5_0_0: "Here's a key point to remember:",
      typography_6_0_0:
        "Thoughts are like popcorn in a machine - you can't control them as you wish.",
      typography_7_0_0:
        "So, in the stream of thoughts, you can't control which thoughts will drift by.",
      typography_8_0_0:
        "But, when people face thoughts they don't want, they often try to shake them off or overcome them.",
      typography_9_0_0: "Sadly, it usually doesn't work.",
      typography_10_0_0: "Pain and suffering",
      typography_11_0_0: "Let's try an interesting activity.",
      typography_12_0_0: "It's important, so please don't just skip it.",
      typography_13_0_0: "First, why not turn on the stopwatch on your phone?",
      typography_14_0_0:
        "Then, press start on the stopwatch, take a deep breath, and hold it for as long as you can.",
      typography_15_0_0: "How many seconds could you manage?",
      typography_16_0_0:
        "Good. Let's set that record aside for now and switch to a different topic.",
      typography_17_0_0:
        "The mind works like a popcorn machine, but people often don't get this.",
      typography_18_0_0:
        "They tend to think of thoughts as something they do freely and actively.",
      typography_19_0_0:
        "So, when negative thoughts arise, they try to push them away.",
      typography_20_0_0:
        "In this process, some avoid negative thoughts entirely, striving for only positive ones.",
      typography_21_0_0:
        "Others feel uncomfortable knowing they have negative thoughts and try to find a way out.",
      typography_22_0_0:
        "But like trying not to remember something makes it more persistent, the more you try to escape negative thoughts, the more space they take up in your life.",
      typography_23_0_0:
        "And this reduces the time for living a life aligned with your values.",
      typography_24_0_0:
        "Soon, life becomes defined by overcoming sadness or anxiety, and it goes round and round like a hamster wheel.",
      typography_25_0_0:
        "Now, let's see how long you can hold your breath again.",
      typography_26_0_0:
        "But this time, don't try to avoid the pain you feel; fully experience it.",
      typography_27_0_0:
        "Follow each of my questions, feeling everything as you go.",
      typography_28_0_0: "Shall we prepare the stopwatch again?",
      typography_29_0_0:
        "Take a deep breath, and hold it for as long as possible. When you start the stopwatch, click the arrow to move on.",
      typography_30_0_0:
        "Where does the urge to breathe begin and end in your body? Once you've figured it out, move on.",
      typography_31_0_0:
        "Can you allow these sensations in your body without resistance? Answer this question before moving on.",
      typography_32_0_0:
        "What thoughts are arising in your mind now? Once you've identified them, move on.",
      typography_33_0_0:
        "Are you feeling any emotions besides the urge to breathe? Identify these emotions before moving on.",
      typography_34_0_0:
        "What thoughts come to mind just before you feel like giving up on holding your breath? Once identified, move on.",
      typography_35_0_0:
        "Try to hold on until the end. How long could you last this time?",
      typography_36_0_0:
        "In many cases, willingly experiencing this pain allows you to hold your breath longer.",
      typography_37_0_0:
        "In the first breath-holding exercise, you might have felt overwhelmed by the growing desire to breathe.",
      typography_38_0_0:
        'You might have started with thoughts like "Why am I doing this?" and as it got harder, "Ah, it’s tough now...", "I really want to breathe..."',
      typography_39_0_0:
        "But if you tried to willingly experience the discomfort in the second attempt, you probably found the same pain to be less tormenting.",
      typography_40_0_0: "What if our minds worked the same way?",
      typography_41_0_0:
        "Just like the 'breath-holding process' was inevitable, negative thoughts arising in our mind are unavoidable.",
      typography_42_0_0: "This is unavoidable 'pain.'",
      typography_43_0_0:
        "But if we try to push away or eliminate these thoughts, our 'suffering' only grows.",
      typography_44_0_0:
        "There's an important difference here: pain is not the same as suffering.",
      typography_45_0_0: "Keep this phrase in mind:",
      typography_46_0_0: '"Pain is inevitable, but suffering is a choice."',
      typography_47_0_0:
        "People often wonder, 'Why is it just me who feels this hard?'",
      typography_48_0_0: "Here's a secret.",
      typography_49_0_0: "Everyone experiences pain.",
      typography_50_0_0: "It's tough for me, and it's tough for you.",
      typography_51_0_0: "Throughout life, we all lose loved ones,",
      typography_52_0_0: "say goodbye to our parents,",
      typography_53_0_0: "sometimes even our children,",
      typography_54_0_0: "feel humiliation and shame at work,",
      typography_55_0_0:
        "and go through moments when we must give up on complicated relationships.",
      typography_56_0_0: "But not everyone feels suffering from this pain.",
      typography_57_0_0:
        "In life's various processes, we can't just eliminate the negative thoughts that arise in our minds. It's impossible.",
      typography_58_0_0:
        "But whether we spend a lot of our time in suffering, trying to eliminate or avoid these thoughts, is our choice.",
      typography_59_0_0: "Some, even amidst pain, don't overreact to it.",
      typography_60_0_0:
        "They willingly acknowledge that such thoughts have come to their minds,",
      typography_61_0_0:
        "create a distance from these thoughts, and then move towards the life they desire.",
      typography_62_0_0: "We believe you can do this too.",
      typography_63_0_0: "Again, Stream of Thoughts",
      typography_64_0_0: "Let's go back to the Stream of Thoughts training.",
      typography_65_0_0:
        "This time, if negative thoughts or emotions appear during the exercise, don't reject them.",
      typography_66_0_0:
        "Instead, willingly feel and confront them, and watch the leaves flowing down the stream.",
      typography_67_0_0:
        "Try to willingly experience what feelings and emotions these negative thoughts stir in your body.",
      typography_68_0_0:
        "1. Find a quiet place where you can concentrate. During a break at work, in your room, or on your bed - anywhere is fine.",
      typography_69_0_0:
        "2. Get comfortable. Any relaxed posture is good. Just be aware you might fall asleep if you're lying down.",
      typography_70_0_0:
        "3. Once ready, start the training following the audio guide.",
      typography_71_0_0:
        "How was the training? Please write down any thoughts or challenges you encountered.",
      typography_72_0_0:
        "Today's Takeaway: Embracing the leaves with negative thoughts helps us escape from distress.",
      typography_73_0_0: "Alright, that wraps up our activity for today.",
    },
  },
  ko: {
    v1: {
      typography_0_0_0: "지난 생각의 강 훈련은 어땠나요?",
      typography_1_0_0: "짧은 훈련이지만 아마 쉽진 않았을 테죠.",
      typography_2_0_0:
        "생각의 강 훈련을 하다보면 종종 마주하기 힘든 생각을 만날 때도 있을 거예요.",
      typography_3_0_0:
        "그런 생각을 마주할 때면 정신없이 강물 속으로 들어가 골똘히 생각에 빠지기도 하죠.",
      typography_4_0_0:
        "어쩌면 그런 생각이 떠오르지 않기를 바라면서 애써 다른 생각을 하려고 노력할지도 몰라요.",
      typography_5_0_0: "여기서 한 가지 중요한 점을 다시 강조하고 갈게요.",
      typography_6_0_0:
        "생각은 마치 팝콘 기계 속 팝콘과 같아 내가 마음대로 어떻게 할 수 없어요.",
      typography_7_0_0:
        "따라서 생각의 강에서 어떤 생각이 떠내려올지는 내가 마음대로 제어할 수 없답니다.",
      typography_8_0_0:
        "하지만 사람들은 흔히 자신이 원치 않는 생각을 마주하면 어떻게든 그것을 떨쳐내거나 이겨내려고 해요.",
      typography_9_0_0: "아쉽게도 별 소용은 없지만요.",
      typography_10_0_0: "고통과 괴로움",
      typography_11_0_0: "한 가지 재미있는 활동을 해볼게요.",
      typography_12_0_0:
        "중요한 활동이니 그냥 넘어가지 말고 꼭 직접 해보셔야 해요.",
      typography_13_0_0: "우선 휴대전화의 스톱워치를 켜볼까요?",
      typography_14_0_0:
        "그리고 스톱워치의 시작 버튼을 누르고, 숨을 깊이 들이마신 후 되도록 오래 숨을 참아보세요.",
      typography_15_0_0: "몇 초나 버틸 수 있었나요?",
      typography_16_0_0:
        "좋아요. 위 기록은 덮어두고 잠시 다른 이야기를 해볼게요.",
      typography_17_0_0:
        "마음은 꼭 팝콘 기계와 같이 작동하지만, 사람들은 이를 쉽게 이해하지 못해요.",
      typography_18_0_0:
        "사람들은 오히려 생각이란 내가 자유롭게 능동적으로 하는 것이라고 생각하곤 하죠.",
      typography_19_0_0:
        "그래서 부정적인 생각이 떠오르면 어떻게든 이를 마음속에서 밀어내려고 해요.",
      typography_20_0_0:
        "그 과정에서 누군가는 부정적인 생각은 마주하지 않은 채 애써 긍정적인 생각만 하려고 노력하죠.",
      typography_21_0_0:
        "또 다른 누군가는 부정적인 생각이 내 마음속에 있다는 사실을 불편해하며 어떻게든 그것으로부터 벗어날 궁리를 하죠.",
      typography_22_0_0:
        "하지만 기억하지 말라고 하면 더 자주 생각나는 것처럼, 부정적인 생각과 벗어나려 할수록 오히려 그 생각이 삶에서 차지하는 비중은 더 커져만 가요.",
      typography_23_0_0:
        "그리고 그 과정에서 나의 가치에 맞는 삶으로 나아가는 시간을 갈수록 줄어만 들죠.",
      typography_24_0_0:
        "어느새 나의 삶은 '우울함이나 불안함을 이겨내는 것'으로 정의되었고, 그렇게 삶은 쳇바퀴 돌듯 돌아가게 돼요.",
      typography_25_0_0:
        "자, 이번에는 다시 숨을 얼마나 오래 참을 수 있는지 알아볼 텐데요.",
      typography_26_0_0:
        "하지만 이번에는 숨을 참으며 느껴지는 '고통'을 피하려고 하지 말고 온전히 느껴볼 거예요.",
      typography_27_0_0:
        "제가 드리는 질문을 하나씩 파악하며 느껴지는 것들을 그대로 받아들이며 진행해보는 거예요.",
      typography_28_0_0: "자, 그럼 다시 스톱워치를 준비해볼까요?",
      typography_29_0_0:
        "스톱워치의 시작 버튼을 누르고, 숨을 깊이 들이마신 후 되도록 오래 숨을 참아보세요. 스톱워치를 시작했으면 화살표를 눌러 다음으로 넘어가주세요.",
      typography_30_0_0:
        "자, 숨을 쉬고 싶은 충동이 어디에서 시작하고 어디에서 끝나나요? 파악했으면 다음으로 넘어가주세요.",
      typography_31_0_0:
        "느낌을 거부하지 않고 우리 몸에 그대로 허용할 수 있겠나요? 이 질문에 대한 답이 내려졌으면 다음으로 넘어가주세요.",
      typography_32_0_0:
        "우리 마음엔 어떤 생각이 떠오르고 있나요? 떠오르는 생각들을 파악했으면 다음으로 넘어가주세요.",
      typography_33_0_0:
        "숨을 쉬고 싶은 충동 외에 다른 감정들도 느껴지나요? 느껴지는 감정들을 파악했으면 다음으로 넘어가주세요.",
      typography_34_0_0:
        "숨 참기를 포기하기 직전에 어떤 생각이 떠오르고 있나요? 파악했으면 다음으로 넘어가주세요.",
      typography_35_0_0: "마지막까지 버텨보세요. 몇 초나 버틸 수 있었나요?",
      typography_36_0_0:
        "많은 경우에 이렇게 느껴지는 고통을 기꺼이 느끼려고 하는 경우에 오히려 더 오래 숨을 참곤 해요.",
      typography_37_0_0:
        "첫 번째 숨 참기 연습에서는 아마 조금씩 커지는 숨 쉬고 싶은 욕구에 압도되었을 거예요.",
      typography_38_0_0:
        '"이건 왜 하는 거지"라는 생각으로 시작해서 중반 정도가 지나면 "아, 이제 힘든데...", "숨 쉬고 싶은걸..." 이런 생각을 했겠죠.',
      typography_39_0_0:
        "하지만 만약 여러분이 두 번째 숨 참기 연습에서 기꺼이 느끼려고 노력했다면, 같은 고통이었지만 확실히 덜 괴로울 수 있다는 걸 발견했을 거예요.",
      typography_40_0_0: "만약 우리의 마음도 이와 같다면 어떨까요?",
      typography_41_0_0:
        "앞선 활동에서 '숨을 참는 과정'이 피할 수 없었던 것처럼, 우리 마음속에 부정적인 생각이 떠오르는 건 피할 수 없는 일이에요.",
      typography_42_0_0: "이건 피할 수 없는 '고통'이죠.",
      typography_43_0_0:
        "하지만 마음속에 떠오르는 부정적인 생각을 밀어내고 없애려고 애쓰다 보면 '괴로움'만 커지게 돼요.",
      typography_44_0_0:
        "여기에 중요한 차이가 있어요. 고통은 괴로움과 동의어가 아니라는 점이죠.",
      typography_45_0_0: "아래 표현을 마음속에 담아두세요.",
      typography_46_0_0: '"고통은 불가피하지만 괴로움은 선택이다."',
      typography_47_0_0:
        "사람들은 '왜 다들 나만 이렇게 힘든 걸까?' 생각하곤 해요.",
      typography_48_0_0: "한 가지 비밀을 알려드릴게요.",
      typography_49_0_0: "모두가 '고통'스럽습니다.",
      typography_50_0_0: "나도 힘들고, 너도 힘듭니다.",
      typography_51_0_0: "삶의 과정에서 우리 모두 사랑하는 사람을 잃기도 하고",
      typography_52_0_0: "부모님을 떠나보내기도 하고",
      typography_53_0_0: "때론 자식을 더 먼저 떠나보내기도 하고",
      typography_54_0_0: "직장에서 모멸감과 수치심을 느끼기도 하고",
      typography_55_0_0:
        "엉켜버릴 대로 엉킨 인간관계를 포기해야 할 순간도 겪어요.",
      typography_56_0_0:
        "하지만 모두가 그 고통에 대해 '괴로움'을 느끼는 건 아닐 거예요.",
      typography_57_0_0:
        "삶의 다양한 과정에서 마음속에 떠오르는 부정적인 생각을 없애버릴 수는 없어요. 불가능하죠.",
      typography_58_0_0:
        "다만 그 생각을 없애거나 피하는 데에 삶의 많은 시간을 소요하며 괴로워할지는 우리 선택에 달렸어요.",
      typography_59_0_0:
        "누군가는 고통 속에서도 고통에 과하게 반응하지 않은 채",
      typography_60_0_0:
        "자신의 마음속에 그러한 생각이 떠올랐다는 걸 오히려 기꺼이 인정한 상태로",
      typography_61_0_0:
        "생각과 거리를 둔 후, 다시 자신이 원하는 삶을 향해 나아가고 있답니다.",
      typography_62_0_0: "우리는 당신도 그렇게 될 수 있다고 믿습니다.",
      typography_63_0_0: "다시 생각의 강",
      typography_64_0_0: "자, 다시 생각의 강 훈련을 할 텐데요.",
      typography_65_0_0:
        "이번에는 훈련 도중, 부정적인 생각이나 감정이 나타나면 그것을 거부하지 말고 있는 그대로 기꺼이 느끼고 마주하며 강의 아랫목으로 흘러가는 나뭇잎을 바라보세요.",
      typography_66_0_0:
        "오히려 그런 부정적인 생각을 떠올리는 나는 어떤 기분과 감정이 나의 몸에서 어떻게 느껴지는지 기꺼이 느껴보는 거예요.",
      typography_67_0_0: "한번 연습해볼까요? 10분 정도 걸릴 거예요.",
      ...translation_meditation.ko.v1,
      typography_68_0_0:
        "1. 먼저 조용하고 집중할 수 있는 장소를 찾으세요. 회사 쉬는 시간, 방 안, 침대 위, 어디든 괜찮아요.",
      typography_69_0_0:
        "2. 편한 자세를 취하세요. 편하게 이완될 수 있는 자세면 어떤 자세든 괜찮아요. 단, 누워서 진행할 경우엔 나도 모르게 잠들 수도 있어요.",
      typography_70_0_0: "3. 준비됐다면 음성 안내에 따라 훈련을 시작하세요.",
      typography_71_0_0:
        "훈련은 어땠나요? 느낀 점이나 어려웠던 점은 없었는지 적어주세요.",
      typography_72_0_0:
        "오늘은 한 가지만 기억하세요. 부정적인 생각이 담겨있는 나뭇잎을 기꺼이 인정하고 바라볼 때 오히려 우리는 괴로움에서 벗어날 수 있다.",
      typography_73_0_0: "좋아요. 이번 활동은 여기서 마무리할게요.",
    },
  },
};
