import { useNavigate } from "react-router-dom";
import useIsInappWebview from "./useIsInappWebview";
import { sendMessage } from "utils/webview";

export default function useGoBack() {
  const navigate = useNavigate();
  const isInappWebview = useIsInappWebview();
  const goBack = () => {
    if (isInappWebview) {
      sendMessage(
        JSON.stringify({
          event: "navigate_back",
        })
      );
    } else {
      navigate(-1);
    }
  };
  return goBack;
}
