import useDetectIOS from "hooks/useDetectIOS";
import useIsInappWebview from "hooks/useIsInappWebview";
import useMixpanelWeb from "hooks/useMixpanelWeb";
import AppleLogin from "react-apple-login";
import LoginButtonTemplate from "./LoginButtonTemplate";
import useAppleLogin, {
  apple_cliendId,
  apple_redirectUrl,
} from "../../hooks/useAppleLogin";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";

export default function AppleLoginButton({
  render,
}: {
  render?: (props: {
    onClick: (e?: any) => void;
    disabled?: boolean;
  }) => JSX.Element;
}) {
  const isInappWebview = useIsInappWebview();
  const { isIOS } = useDetectIOS();

  const handleAppleLogin = useAppleLogin();

  const setMixPanel = useMixpanelWeb({
    eventName: "애플 로그인",
  });

  const user = useRecoilValue(userAtom);

  return !isInappWebview || !isIOS ? (
    <AppleLogin
      clientId={apple_cliendId}
      redirectURI={apple_redirectUrl}
      responseType="code id_token"
      responseMode="fragment"
      // responseMode="form_post"
      // scope="name email"
      render={
        render
          ? render
          : (props) => (
              <LoginButtonTemplate
                loginType="apple"
                action={() => {
                  setMixPanel();
                  props.onClick();
                }}
                isLoggedIn={!!user?.accessToken}
              />
            )
      }
    />
  ) : (
    <LoginButtonTemplate
      loginType="apple"
      action={handleAppleLogin}
      isLoggedIn={!!user?.accessToken}
    />
  );
}
