export const translation_6_1 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "지금까지 우린 생각을 생각으로 바라보기 위해 분리하는 연습을 해보았습니다.",
      typography_1_0_0:
        "<분리 노트>를 활용해 나의 경험을 가지고도 분리를 해보았나요?",
      typography_2_0_0: "디스턴싱은 이해만 가지고는 되지 않습니다.",
      typography_3_0_0:
        "꼭 직접 실천하며 꾸준히 일상에 적용하려는 노력을 해주세요.",
      typography_4_0_0:
        "아직 <분리 노트>를 한 번도 작성하지 않았다면, 꼭 작성 후에 다음으로 넘어가시길 권합니다.",
      typography_5_0_0: "쉽지 않은 분리",
      typography_6_0_0: "직접 나의 경험에 대해 분리하기를 해보니 어떠셨나요?",
      typography_7_0_0:
        "분리하기를 처음 시도하는 사람들은 다음과 같은 이야기를 하곤 합니다.",
      typography_8_0_0: '"특별하게 어떤 생각을 한 건 아닌데..."',
      typography_9_0_0: '"무슨 감정을 느꼈다고 해야 하지. 그냥 싫었던 건데..."',
      typography_10_0_0: '"감각? 별로 특별한 게 느껴진 건 없는데..."',
      typography_11_0_0: '"특별히 행동한 건 없어."',
      typography_12_0_0: "하지만 이것은 사실이 아닙니다.",
      typography_13_0_0:
        "각 개별 요소는 무조건 존재합니다. 그저 우리가 명료하게 집중하지 못해 알아차리지 못했을 뿐이죠.",
      typography_14_0_0:
        "이 단계에 익숙해진 사람들은 자신의 마음속에 그토록 다양한 생각, 감정, 감각, 행동/충동이 떠올랐다는 것을 깨닫고 놀라곤 합니다.",
      typography_15_0_0: "산만한 주의",
      typography_16_0_0:
        "우리는 왜 이렇게 각각의 요소들을 섬세하게 인지하기 힘든 걸까요?",
      typography_17_0_0:
        "본디 우리의 의식이라고 하는 것이 산만하기 때문입니다.",
      typography_18_0_0: "우린 원래 그렇게 태어났습니다.",
      typography_19_0_0: "우린 차분한 집중력을 가지고 있지 않습니다.",
      typography_20_0_0:
        "우린 다양한 요소를 정신없이 옮겨 다니는 뇌를 갖도록 진화해왔습니다.",
      typography_21_0_0: "지금 여러분도 그 진화의 산물입니다.",
      typography_22_0_0: "주의력 테스트",
      typography_23_0_0:
        "우리의 주의가 원래 산만하다는 사실을 한 번 스스로 느껴보는 시간을 갖도록 해보겠습니다.",
      typography_24_0_0: "잠시 눈을 감고 호흡에만 집중해보세요.",
      typography_25_0_0:
        "코와 입을 통해 숨이 드나드는 것만 가만히 관찰해보세요.",
      typography_26_0_0:
        "다른 생각을 하느냐 정신을 빼앗기지 말고, 그저 호흡이 어떤지만 관찰해보는 겁니다.",
      typography_27_0_0:
        "들숨과 날숨의 한 사이클이 끝날 때보다 숫제를 세 볼겁니다.",
      typography_28_0_0: "들숨-날숨-1, 들숨-날숨-2, 들숨-날숨-3 이렇게 말이죠.",
      typography_29_0_0:
        "목표는 10까지 세는 것입니다. 하지만 규칙이 하나 있습니다.",
      typography_30_0_0:
        "다른 생각에 주의를 빼앗겨 호흡이 아닌 다른 생각이 조금이라도 들었다면 다시 0으로 돌아오는 것입니다.",
      typography_31_0_0:
        "시간 제한은 없습니다. 오기를 갖고 할 수 있는 데까지 해보셔도 좋습니다.",
      typography_32_0_0:
        "그럼 시작해보세요. 성공이든 실패든 그만하길 원하시면 눈을 뜨고 다음으로 넘어가주세요.",
      typography_33_0_0: "어땠나요? 10까지 셀 수 있었나요?",
      typography_34_0_0: "대부분이 호흡을 10까지 관찰하지 못합니다.",
      typography_35_0_0: "우리는 그 과정에서 주의를 다른 곳에 뺏기고 맙니다.",
      typography_36_0_0: "어떤 대단한 생각에 주의를 뺏기는 것도 아닙니다.",
      typography_37_0_0: '"3개 셌다, 7개 남았네."',
      typography_38_0_0: '"못 센다고? 내가 해볼게."',
      typography_39_0_0:
        '심지어는 "이제 3을 세야지."라고 생각하는 자신을 발견하기도 합니다.',
      typography_40_0_0:
        "마음속에 다른 팝콘이 튀어오르고, 나의 주의는 나도 모르게 그쪽으로 따라갑니다.",
      typography_41_0_0:
        "이건 아주 자연적인 경향입니다. 인간은 원래 그렇습니다.",
      typography_42_0_0: "주의력 통제하기",
      typography_43_0_0:
        "이런, 분리를 잘하려면 개별 요소에 주의를 잘 할 수 있어야 하는데",
      typography_44_0_0:
        "인간이 원래 주의가 산만한 존재라니, 어떻게 하란 말일까요?",
      typography_45_0_0:
        "방법은 있습니다. 주의는 분명 우리가 통제할 수 있습니다.",
      typography_46_0_0: "주의는 '나'의 의도입니다.",
      typography_47_0_0: "다음 활동에서는 이 이야기를 조금 더 나눠보도록 하죠.",
    },
  },
};
