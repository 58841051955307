import { Box } from "@mui/joy";
import { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/paths";
import { addAlpha } from "styles/hexOpacity";
import ButtonWithPopup from "./ButtonWithPopup";

export default function AddRecordButton({
  date,
  usePopup,
}: {
  date: Dayjs;
  usePopup: boolean;
}) {
  const navigate = useNavigate();

  const goRecording = () => {
    navigate(
      `${PATH.thoughtRecordTool_recording}?date=${date.format(
        "YYYY-MM-DD"
      )}&isFirstRecord=${usePopup}`
    );
  };

  return (
    <ButtonWithPopup
      title={"마음속 이야기를 마구 꺼내주세요"}
      subtitle={
        "정리되지 않은 얘기도 좋아요. 과정을 따라가다보면 저절로 정리가 되어갈 거예요."
      }
      action={goRecording}
      cancelText={"취소"}
      actionText={"시작하기"}
      usePopup={usePopup}
      render={
        <Box
          sx={{
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            backgroundColor: "#000000",
            position: "absolute",
            bottom: "36px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              opacity: 0.5,
            },
            filter: `drop-shadow(0px 20px 40px ${addAlpha("#222222", 0.2)})`,
          }}
        >
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 13H24"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M13 2L13 24"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>
        </Box>
      }
    ></ButtonWithPopup>
  );
}
