import { axiosInstance, CustomResponseFormat } from "api2/instance";
import { useCallback, useMemo, useState } from "react";

interface ErrorResponse {
  status: number;
  error: string;
  debugMessage?: string;
}

type UseFetchType = [
  any | undefined,
  () => Promise<void>,
  ErrorResponse | undefined
];

export default function useFetch2(request: {
  url: string;
  requestBody?: string;
  params?: { [key: string]: unknown };
}): UseFetchType {
  const [value, setValue] = useState<any>();
  const params = useMemo(() => request.params || {}, [request.params]);
  const [errorData, setErrorData] = useState<ErrorResponse>();

  const fetchData = useCallback(async () => {
    try {
      // const response = !request.requestBody
      //   ? await axios2(thoughtRecordToolState?.accessToken).get(request.url, {
      //       params: params,
      //     })
      //   : await axios2(thoughtRecordToolState?.accessToken).post(
      //       request.url,
      //       request.requestBody,

      //       {
      //         params: params,
      //       }
      //     );
      const response: CustomResponseFormat = !request.requestBody
        ? await axiosInstance.get(request.url, {
            params: params,
          })
        : await axiosInstance.post(
            request.url,
            request.requestBody,

            {
              params: params,
            }
          );

      if (response?.code === 0) {
        const data = response.result;
        console.log("useFetch2", request, data);
        setValue(data || {});
      } else {
        console.log(response.data);
      }
    } catch (error: any) {
      console.error("error", error);
      setErrorData(error);
      setValue(undefined);
    }
  }, [request, params]);

  return [value, fetchData, errorData];
}
