export const translation_6_98_A = {
  en: {
    v1: {
      네: "Yes",
      아니요: "No",
      typography_0_0_0:
        "Now, let's begin to draw a thought map, one step at a time.",
      typography_1_0_0:
        "We'll explore which coping strategies the three automatic thoughts we identified belong to.",
      typography_2_0_0:
        "Also, we'll understand how these strategies originate from core beliefs,",
      typography_3_0_0:
        "and how these core beliefs are rooted in our key experiences.",
      typography_4_0_0:
        "Once we grasp these coping strategies, core beliefs, and key experiences, we'll spend some time understanding the entire thought map.",
      typography_5_0_0:
        "After that, we'll practice distancing ourselves from this thought map.",
      typography_6_0_0: "So, let's start with coping strategies.",
      typography_7_0_0: "The 3 strategies",
      typography_8_0_0:
        "Coping strategies are responses to the threats posed by our core beliefs.",
      typography_9_0_0:
        "These core beliefs take root in our minds through key experiences,",
      typography_10_0_0: "often troubling us.",
      typography_11_0_0:
        "Subconsciously, we develop defense mechanisms against these core beliefs",
      typography_12_0_0: "- these are our coping strategies.",
      typography_13_0_0:
        "There are mainly three types: surrender, overcompensation, and avoidance.",
      typography_14_0_0: "Let's delve into each one.",
      typography_15_0_0: "(1) Surrender",
      typography_16_0_0:
        "People often surrender to their intense core beliefs.",
      typography_17_0_0:
        "Surrender means complying with the content of these beliefs.",
      typography_18_0_0: `For instance, those surrendering to the belief "I am worthless" may live feeling incapable and defeated.`,
      typography_19_0_0: `Those who surrender to "The world is hopeless and getting worse" might give up on productive and enjoyable activities, living life in a state of lethargy.`,
      typography_20_0_0: "While it may not seem like a coping strategy,",
      typography_21_0_0: "surrender can be quite effective.",
      typography_22_0_0:
        "Believing these thoughts can spare us from further agony and despair.",
      typography_23_0_0:
        "However, surrendering to negative beliefs only reinforces negative thoughts and feelings,",
      typography_24_0_0: "making the belief stronger.",
      typography_25_0_0: `(2) Overcompensation`,
      typography_26_0_0:
        "Alternatively, people try to prove their core beliefs wrong by doing the exact opposite.",
      typography_27_0_0:
        "This reaction, called 'overcompensation,' attempts to show the falsity of a core belief.",
      typography_28_0_0: `Those overcompensating for "I am worthless" might live by the principle of "always having to do more."`,
      typography_29_0_0:
        "This can lead to a compulsive level of perfectionism.",
      typography_30_0_0:
        "While this principle can be a driving force in life, it often goes overboard.",
      typography_31_0_0:
        "Failing to meet these high standards leads to self-criticism, reinforcing the original belief of worthlessness.",
      typography_32_0_0: `Another example is those overcompensating for "I am unlovable" by becoming overly attached in relationships,`,
      typography_33_0_0: "constantly seeking affirmation and love.",
      typography_34_0_0:
        "Yet, this excessive effort often strains relationships, reinforcing the belief of being unlovable.",
      typography_35_0_0:
        "Ultimately, overcompensation can be more draining than helpful.",
      typography_36_0_0: `(3) Avoidance`,
      typography_37_0_0:
        "Then, there are those who act as if their core beliefs don't exist, avoiding confrontation with them.",
      typography_38_0_0:
        "This strategy, 'avoidance,' involves diverting attention from or expelling these beliefs from our minds.",
      typography_39_0_0: `People avoiding the belief "I am incompetent" might steer clear of challenging tasks to not confront this belief.`,
      typography_40_0_0:
        "In the short term, this may seem fine, but over time, realizing they've achieved nothing reinforces the belief of incompetence.",
      typography_41_0_0: `For example, those avoiding "Life is meaningless" might do nothing, blocking out opportunities to find meaning.`,
      typography_42_0_0:
        "Eventually, this leads to a life spent in bed, reinforcing the belief of life's meaninglessness.",
      typography_43_0_0:
        "While avoidance might seem like a solution, it only strengthens the core belief.",
      typography_44_0_0: "Healthy strategies",
      typography_45_0_0:
        "This doesn't mean all coping strategies are ineffective or unhelpful.",
      typography_46_0_0:
        "They can be wise and rational, and at times, beneficial choices",
      typography_47_0_0: "- especially when the core belief was formed.",
      typography_48_0_0:
        "But over time, they may become ineffective in the current moment.",
      typography_49_0_0: "Sometimes the coping startegies are helpful.",
      typography_50_0_0: `For instance, the perfectionism born from overcompensation can be quite helpful.`,
      typography_51_0_0:
        "It drives progress, yields high performance, and prevents mistakes.",
      typography_52_0_0:
        "However, immature coping strategies can crumble like a tower built on sand.",
      typography_53_0_0:
        "So, it might seem to work well, but when a problem arises, it quickly collapses.",
      typography_54_0_0: `Even with the same goal of "doing things perfectly," the outcome can vary greatly depending on the origin of this thought.`,
      typography_55_0_0:
        "Therefore, understanding our coping strategies within the thought map is crucial.",
      typography_56_0_0: "Thoughts, popcorn, and me",
      typography_57_0_0:
        "Remember how we learned that our minds work like popcorn machines when starting Distancing?",
      typography_58_0_0: `Pop, pop. The popcorn machine pops kernels unpredictably.`,
      typography_59_0_0:
        "In the machine, there are both white and black popcorns.",
      typography_60_0_0:
        "Let's metaphorically consider the troubling thoughts as black popcorn.",
      typography_61_0_0: "While we might not like black popcorn,",
      typography_62_0_0:
        "some of us focus solely on it, living our lives in surrender to these thoughts (surrender).",
      typography_63_0_0:
        "Others spend most of their lives trying to prove that these black popcorns aren't really black (overcompensation).",
      typography_64_0_0:
        "Yet others deny the existence of black popcorn in the machine, trying not to look at or hide them (avoidance).",
      typography_65_0_0:
        "But as we've learned, we can't control our thoughts at will. The popcorn just pops randomly.",
      typography_66_0_0: `Thus, black popcorn is inevitably part of what pops up.`,
      typography_67_0_0: "Pain is inevitable in life,",
      typography_68_0_0: `but suffering is optional.`,
      typography_69_0_0:
        "Once we understand what coping strategy our black popcorn represents, distancing from these thoughts becomes easier.",
      typography_70_0_0:
        "By acknowledging these thoughts in our minds without surrender, overcompensation, or avoidance, we can experience them willingly.",
      typography_71_0_0:
        "Understanding our coping strategies is important, isn't it?",
      typography_72_0_0: "Exploring coping strategy",
      typography_73_0_0: "Now, let's reflect on my coping strategies",
      typography_74_0_0: `I've gathered coping strategies from the stories we've shared in the past.`,
      typography_75_0_0: `Consider how I might be surrendering, overcompensating, or avoiding.`,
      typography_76_0_0: `If there are inaccuracies, feel free to correct them. Let's take a look.`,
      typography_77_0_0: `Do you recognize any of these strategies in yourself?`,
      typography_78_0_0: `Where do you feel it's lacking?`,
      typography_79_0_0: `Okay, let's stop here for today. I'll review and get back to you soon.`,
      typography_80_0_0: `Okay. Among the coping strategies mentioned above, which one do you think troubles me the most?`,
      typography_81_0_0: `Alright. In the next activity, we'll examine the relationship between coping strategies and automatic thoughts.`,
    },
  },
  ko: {
    v1: {
      네: "네",
      아니요: "아니요",
      typography_0_0_0: "이제부터 생각 지도를 하나씩 그려나가볼 텐데요.",
      typography_1_0_0:
        "함께 찾은 세 가지 자동적 사고는 어떠한 대처 전략에 속하는지,",
      typography_2_0_0:
        "그리고 그러한 대처 전략은 어떠한 핵심 믿음으로부터 발생되었는지,",
      typography_3_0_0:
        "핵심 믿음은 어떠한 핵심 경험을 통해 우리 마음속에 자리잡게 되었는지, 하나씩 알아볼 거예요.",
      typography_4_0_0:
        "그렇게 대처 전략, 핵심 믿음, 핵심 경험을 파악하고 나면 전체 생각 지도를 이해하는 시간을 가질 거랍니다.",
      typography_5_0_0:
        "이후로는 그러한 생각 지도로부터 거리를 두는 연습을 해 볼 거예요.",
      typography_6_0_0: "자, 그러면 대처 전략부터 시작해보죠.",
      typography_7_0_0: "세 가지 전략",
      typography_8_0_0:
        "앞서 '핵심 믿음의 위협에 대처하기 위해 보이는 반응'을 대처 전략이라고 했어요.",
      typography_9_0_0:
        "핵심 믿음은 핵심 경험을 통해 우리 마음속에 자리 잡게 되는데요.",
      typography_10_0_0: "그 내용은 주로 나를 괴롭히는 것들일 때가 많아요.",
      typography_11_0_0:
        "그래서 우리는 무의식 중에 핵심 믿음에 대한 일종의 방어 기제를 발휘하게 되는데요.",
      typography_12_0_0: "그 방어 기제가 바로 '대처 전략'이랍니다.",
      typography_13_0_0:
        "대처 전략에는 크게 세 가지가 있어요: 굴복, 과잉보상, 회피",
      typography_14_0_0: "하나씩 자세히 알아볼게요.",
      typography_15_0_0: "(1) 굴복",
      typography_16_0_0:
        "핵심 믿음은 너무도 강렬한 생각이라 사람들은 종종 그러한 생각에 그대로 굴복하곤 해요.",
      typography_17_0_0:
        "'굴복'이란 핵심 믿음이 말하고 있는 내용에 그대로 순응하는 것을 뜻하는데요.",
      typography_18_0_0: `"나는 무가치한 사람이야"라는 믿음에 굴복하는 사람들은 실제 자신은 아무것도 할 수 없는 사람이라고 믿으며 좌절하고, 위축된 채 삶을 살아가게 되죠.`,
      typography_19_0_0: `"세상은 희망 없고 갈수록 나쁘게 흘러갈 것이다"라는 생각에 굴복하는 사람은, 모든 생산적이고 즐거운 일들을 포기한 채 무기력하게 살아가요.`,
      typography_20_0_0:
        "굴복하는 게 어떻게 대처를 하는 거냐고 생각하실 수도 있을 거예요.",
      typography_21_0_0: "하지만 굴복은 사실 아주 효과적인 대처 전략이랍니다.",
      typography_22_0_0:
        "그 생각을 그대로 믿어버리면, 아프고 괴롭고 희망은 없어보일지언정, 그 과정에서 더 이상 고뇌하고 괴로워하진 않을 수 있으니까요.",
      typography_23_0_0:
        "하지만 당연하게도 부정적인 믿음에 굴복하는 건 부정적인 생각과 감정을 끊임없이 상기시킬 뿐이에요.",
      typography_24_0_0:
        "결국 '굴복'을 통해 부정적인 믿음은 더욱 강해져만 가죠.",
      typography_25_0_0: `(2) 과잉보상`,
      typography_26_0_0:
        "한편 사람들은 자신의 마음속에 떠오른 핵심 믿음이 옳지 않다는 것을 증명해 보이기 위해 반대 방향으로 노력하곤 해요.",
      typography_27_0_0:
        "이처럼 핵심 믿음이 틀렸음을 보이기 위해 그 생각과 정반대로 반응하는 것을 '과잉보상'이라고 해요.",
      typography_28_0_0: `"나는 무가치한 사람이야"라는 믿음에 과잉보상하는 사람들은 그것이 아님을 증명하고자 "항상 더 열심히 해야 한다"라는 원칙을 세우고 삶을 살아가곤 해요.`,
      typography_29_0_0:
        "그러한 사람들은 종종 강박적인 수준의 완벽주의를 보이기도 하는데요.",
      typography_30_0_0:
        "이러한 원칙은 삶이 원동력이 될 수 있겠으나, '과잉' 보상이라는 이름에서 알 수 있듯, 보통 과하게 작동한답니다.",
      typography_31_0_0: `그래서 조금이라도 열심히 하지 못하는 순간을 마주하게 될 때면 스스로를 비난하고 자책하고, 결국엔 다시 "나는 무가치한 사람이야"라는 믿음은 더욱 강해져만 가죠.`,
      typography_32_0_0: `한 가지 예시만 더 살펴볼까요? "나는 사랑 받을 수 없다"라는 믿음에 과잉보상하는 사람들은 그것이 아님을 증명하고자 인간관계에 집착하곤 해요.`,
      typography_33_0_0:
        "상대에게 지나치게 친절을 베풀거나 상대의 반응을 주시하며 안정과 사랑을 확인하는 거죠.",
      typography_34_0_0: `하지만 역시 그러한 과잉보상은 과해지기 마련이어서, 불편한 인간관계를 만들며 상대와 멀어지고 결국 "나는 사랑 받을 수 없다"는 믿음은 더욱 강해져 버려요.`,
      typography_35_0_0:
        "이처럼 과잉보상은 그다지 도움은 되지 않고, 궁극적으로는 우리를 더 갉아먹어요.",
      typography_36_0_0: `(3) 회피`,
      typography_37_0_0:
        "한편 사람들은 핵심 믿음이 존재하지 않는 것처럼 여기며 마주하기를 피하기도 해요.",
      typography_38_0_0:
        "이처럼 핵심 믿음을 외면하기 위해 주의를 딴 곳으로 돌려버리거나 마음에서 몰아내는 대처 전략을 '회피'라고 하는데요.",
      typography_39_0_0: `"나는 잘하는 게 없다"라는 믿음을 회피하는 사람들은 애초에 어려운 과제를 하지 않음으로써 그 생각을 마주하지 않으려 하곤 해요.`,
      typography_40_0_0: `잠시 동안은 괜찮을 거예요. 하지만 이러한 회피가 계속 쌓이다 시간이 흘러 뒤를 돌아보면, 결국 아무것도 해낸 게 없다는 사실에 결국 다시 "나는 잘하는 게 없다"라는 믿음은 더욱 강해져만 가죠.`,
      typography_41_0_0: `한 가지 예시만 더 살펴볼까요? "삶은 아무런 의미가 없다"라는 믿음을 회피하는 사람들은 애초에 아무것도 하지 않음으로써 그 생각을 마주하려는 것을 피하곤 해요.`,
      typography_42_0_0:
        "하지만 점차 활동 반경이 줄어들면서 의미를 찾을 기회들도 모두 차단하죠.",
      typography_43_0_0: `결국 하루 종일 무기력하게 침대에만 누워 있게 되며 결국 "삶은 아무런 의미가 없다"라는 믿음은 더욱 강해져만 가게 된답니다.`,
      typography_44_0_0: "건강한 전략",
      typography_45_0_0:
        "대처 전략이 다 의미 없고 아무런 도움도 되지 않는 방법이라는 걸 말하고자 하는 게 아니에요.",
      typography_46_0_0:
        "대처 전략은 충분히 현명하고, 합리적이었고, 또 어떻게 보면 나에게 도움이 되는 선택이었어요.",
      typography_47_0_0: "특히 핵심 믿음이 만들어졌던 그 순간에는요.",
      typography_48_0_0:
        "단지 시간이 지나면서 지금 이 순간에는 대처 전략이 효과적이지 않게 되었을 뿐이죠.",
      typography_49_0_0: "대처 전략이 충분히 도움이 될 때도 많아요.",
      typography_50_0_0: `예를 들면 과잉보상으로 만들어낸 완벽주의는 제법 도움이 될 때도 많죠.`,
      typography_51_0_0: `완벽주의는 일을 진행시키고, 높은 성과를 만들어내고, 실수를 하지 않도록 도와주니까요.`,
      typography_52_0_0:
        "하지만 성숙하지 않은 대처 전략은 마치 모래로 쌓아올린 탑과 같아요.",
      typography_53_0_0:
        "그래서 잘 작동하는 것 같다가도 언젠가 문제가 발생하면 빠르게 와르르 무너지죠.",
      typography_54_0_0:
        "동일하게 '완벽하게 일을 하자'라는 생각을 가지고 있더라도, 그 생각이 어디에서 기원했는지에 따라 결과는 아주 달라질 수도 있답니다.",
      typography_55_0_0:
        "따라서 생각 지도 속에서 나의 대처 전략을 잘 이해하는 것이 매우 중요해요.",
      typography_56_0_0: "생각과 팝콘과 나",
      typography_57_0_0: `디스턴싱을 시작하며 우리의 마음은 정확히 팝콘 기계처럼 동작한다고 배웠어요. 기억나시나요?`,
      typography_58_0_0: `탁, 탁. 팝콘 기계는 불규칙적으로 팝콘을 만들고 있네요.`,
      typography_59_0_0:
        "팝콘 기계 속에는 하얀 팝콘도 있고, 때론 검은 팝콘도 있습니다.",
      typography_60_0_0:
        "편의상 우리를 힘들게 하는 생각을 검은 팝콘이라고 해볼게요.",
      typography_61_0_0: "검은 팝콘은 그다지 우리 마음에 들지는 않겠죠.",
      typography_62_0_0:
        "이때 누군가는 검은 팝콘을 잔뜩 쌓아두고 그것만 바라보며 삶을 살아가곤 합니다(굴복).",
      typography_63_0_0:
        "또 누군가는 검은 팝콘이 사실은 검은색이 아니라는 것을 증명하기 위해 애쓰며 삶의 대부분을 보내곤 합니다(과잉보상).",
      typography_64_0_0:
        "또 다른 누군가는 팝콘 기계 속에 검은 팝콘이 있다는 사실을 부정하며 애써 그것을 바라보지 않으려고, 또는 어떻게든 그걸 보이지 않도록 하려고 애씁니다(회피).",
      typography_65_0_0:
        "하지만 앞서 배웠듯, 우린 스스로의 의지대로 생각할 수 없어요. 팝콘은 무작위로 튀어 오를 뿐이죠.",
      typography_66_0_0: `그렇기에 팝콘 기계에서 튀어 오르는 팝콘 중엔 어쩔 수 없이 검은 팝콘이 포함되어 있을 수밖에 없답니다.`,
      typography_67_0_0: "우리 삶에서 '고통은 불가피'하니까요.",
      typography_68_0_0: `하지만 '괴로움은 선택'할 수 있답니다.`,
      typography_69_0_0:
        "마음속에 떠오른 검은 팝콘이 어떤 대처 전략의 산물인지 알게 되면, 우리는 그 생각으로부터 거리를 두는 일이 훨씬 수월해지기 때문이에요.",
      typography_70_0_0:
        "검은 팝콘에 굴복하지 않고, 과잉보상하지 않고, 애써 외면하며 회피하지 않고, 그 생각이 내 마음속에 있다는 사실을 기꺼이 인정하고 경험하면서 말이죠.",
      typography_71_0_0:
        "그렇다면 우리는 어떤 대처 전략을 사용하고 있는지 이해하는 것이 중요하겠죠?",
      typography_72_0_0: "대처 전략 살펴보기",
      typography_73_0_0: "자, 이제 나의 대처 전략을 살펴볼 텐데요.",
      typography_74_0_0:
        "함께 나눴던 과거의 이야기들 중 대처 전략을 모아봤어요.",
      typography_75_0_0:
        "우선 내가 어떻게 굴복, 과잉보상, 또는 회피하고 있는지 살펴보세요.",
      typography_76_0_0:
        "정확하지 않은 부분이 있다면 수정하면 되니 걱정하지 마세요. 자, 그럼 살펴볼까요?",
      typography_77_0_0:
        "어떤가요? 나는 위와 같은 대처 전략을 가지고 있는 것 같나요?",
      typography_78_0_0: "어떤 부분에서 부족하다고 생각하시나요?",
      typography_79_0_0:
        "좋아요, 그럼 오늘은 여기까지 하도록 해요. 제가 확인 후 다시 말씀드릴게요.",
      typography_80_0_0:
        "좋아요. 위와 같은 대처 전략 중 나를 가장 괴롭히는 방식은 무엇이라고 생각하시나요?",
      typography_81_0_0:
        "좋아요. 그러면 다음 활동에서는 대처 전략과 자동적 사고 사이의 관계를 살펴볼게요.",
    },
  },
};
