import { useEffect, useState } from "react";
import { ProgramType } from "data/programData";
import * as _ from "lodash";
import { getTaskIdFromTaskKey } from "logic/logics";
import { ProgramContentType } from "data/BlockComponent";
import { TextareaType } from "data/CellComponent";
import { useRecoilValue } from "recoil";
import { programDataAtom } from "recoil/programDataAtom";
import { ko } from "translation/ko";
import useGetProgramData from "./useGetProgramData";
import { patientAtom } from "recoil/patientAtom";
import get_6_5_A_RawData from "./get_6_5_A_RawData";
import get_6_9_A_RawData from "./get_6_9_A_RawData";
import { v4 as uuidv4 } from "uuid";
import get_104_0_A_RawData from "./get_104_0_A_RawData";
import { deviceAtom } from "recoil/deviceAtom";
import get_104_1_A_RawData from "./get_104_1_A_RawData";
import get_104_2_A_RawData from "./get_104_2_A_RawData";
import get_4_9_A_RawData from "./get_4_9_A_RawData";
import get_4_11_A_RawData from "./get_4_11_A_RawData";
import get_6_11_A_RawData from "./get_6_11_A_RawData";
import useIsInappWebview from "./useIsInappWebview";

export function getLatestTranslationVersion(taskId: string) {
  if ((ko as any).task.activity && (ko as any).task.activity[taskId]) {
    const keys = Object.keys((ko as any).task.activity[taskId]);
    return keys[keys.length - 1];
  }
  return;
}

export function setEditorKey(data: ProgramType) {
  (data.content as ProgramContentType[]).forEach((each) =>
    each.lines.forEach((line) =>
      line.forEach((cell) => {
        if (
          (cell.type === "textarea" ||
            cell.type === "typographyFromConceptNote" ||
            cell.type === "percentage" ||
            cell.type === "date" ||
            cell.type === "time" ||
            cell.type === "feedback") &&
          !(cell.content as TextareaType).editorKey
        ) {
          (cell.content as TextareaType).editorKey = uuidv4();
        }
      })
    )
  );
  return data;
}

export function setTranslationVersion(data: ProgramType) {
  if (!(data as ProgramType)["translationVersion"]) {
    (data as ProgramType)["translationVersion"] = getLatestTranslationVersion(
      data.taskId
    );
  }
  return data;
}

export function setFirstBlockShown(data: ProgramType) {
  const updatedData = {
    ...data,
    content: [
      {
        ...data.content[0],
        isShown: true,
      },
      ...data.content.slice(1),
    ],
  };

  return updatedData;
}

export function setDataWithEditorKeyAndTranslationVersion(data: ProgramType) {
  return setEditorKey(setFirstBlockShown(setTranslationVersion(data)));
}

type GetterParams = {
  taskId: string;
  patientId: string;
  isPushNotificationGranted: boolean;
};

const taskDataGetters: Record<string, Function> = {
  "4-9-A": ({ taskId, patientId }: GetterParams) =>
    get_4_9_A_RawData(taskId, patientId),
  "4-11-A": ({ taskId, patientId }: GetterParams) =>
    get_4_11_A_RawData(taskId, patientId),
  "6-5-A": ({ patientId }: GetterParams) => get_6_5_A_RawData(patientId),
  "6-9-A": ({ patientId }: GetterParams) => get_6_9_A_RawData(patientId),
  "6-11-A": ({ patientId }: GetterParams) => get_6_11_A_RawData(patientId),
  "104-0-A": ({ taskId, patientId }: GetterParams) => {
    const activityId = parseInt(taskId.split("#")[1] || "1");
    get_104_0_A_RawData(activityId, patientId);
  },
  "104-1-A": ({ taskId, patientId, isPushNotificationGranted }: GetterParams) =>
    get_104_1_A_RawData(taskId, patientId, isPushNotificationGranted),
  "104-2-A": ({ taskId, patientId }: GetterParams) =>
    get_104_2_A_RawData(taskId, patientId),
};

export default function useGetBlankTaskData({ taskKey }: { taskKey: string }) {
  const [data, setData] = useState<ProgramType>();
  const patientState = useRecoilValue(patientAtom);
  const programDataState = useRecoilValue(programDataAtom);
  const deviceState = useRecoilValue(deviceAtom);
  const isInappWebview = useIsInappWebview();
  const isPushNotificationGranted =
    deviceState.pushNotificationStatus === "granted" || !isInappWebview;
  const ProgramData = useGetProgramData(getTaskIdFromTaskKey(taskKey));

  const getDefaultRawData = () => {
    return (programDataState?.[taskKey] ||
      _.cloneDeep(ProgramData)) as ProgramType;
  };

  async function getRawData(taskKey: string) {
    const taskId = getTaskIdFromTaskKey(taskKey);
    const patientId = `${patientState?.patientId}`;

    const matchingKey = Object.keys(taskDataGetters).find((key) =>
      taskId.startsWith(key)
    );

    const getter = matchingKey ? taskDataGetters[matchingKey] : undefined;
    if (getter) {
      return await getter(taskId, patientId, isPushNotificationGranted);
    }

    return getDefaultRawData();
  }

  useEffect(() => {
    getRawData(taskKey).then((rawData) => {
      if (rawData) {
        console.log("RAW: ", rawData);
        setData(setDataWithEditorKeyAndTranslationVersion(rawData));
      }
    });
  }, []);

  return data;
}
