import { CircularProgress, Stack } from "@mui/joy";
import SteppaySubscriptionManagerModule from "./SteppaySubscriptionManagerModule";
import { useEffect } from "react";
import useFetch from "hooks/useFetch";

export default function SteppaySubscriptionManager() {
  const [managementDetail, getManagementDetail] = useFetch({
    url: "/payment/management/detail",
  });

  useEffect(() => {
    getManagementDetail();
  }, []);

  return managementDetail?.result?.steppaySessionKey &&
    managementDetail?.result?.steppayStoreUuid ? (
    <Stack sx={{ mx: "auto", overflow: "scroll" }}>
      <SteppaySubscriptionManagerModule
        sessionKey={managementDetail?.result?.steppaySessionKey}
        storeUuid={managementDetail?.result?.steppayStoreUuid}
      />
    </Stack>
  ) : (
    <Stack
      alignItems={"center"}
      justifyContent="center"
      sx={{ height: "100%", width: "100%" }}
    >
      <CircularProgress color="neutral" />
    </Stack>
  );
}
