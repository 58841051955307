import { Button } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { sendMessage } from "utils/webview";

export default function RequestStoreReviewButton({
  disabled,
}: {
  disabled: boolean;
}) {
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  return (
    <Button
      onClick={() => {
        sendMessage(
          JSON.stringify({
            event: "open_review",
          })
        );
      }}
      disabled={disabled}
    >
      {t_ui("리뷰 남기기")}
    </Button>
  );
}
