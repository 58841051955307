export function addAlpha(hex, alpha) {
  return `${hex}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, 0)}`;
}

// export function extractHexColor(cssVar) {
//   // 정규 표현식을 사용하여 기본값(#xxxxxx)을 추출
//   const regex = /var\(--[\w-]+,\s*(#[0-9a-fA-F]{6})\)/;
//   const match = cssVar.match(regex);

//   // 매치된 결과가 있으면 첫 번째 캡처 그룹(#xxxxxx)을 반환
//   if (match) {
//     return match[1];
//   }

//   // 매치된 결과가 없으면 null을 반환
//   return null;
// }

export function extractHexColor(cssVar) {
  // 정규 표현식을 사용하여 기본값(#xxxxxx 또는 #xxxxxxxx)을 추출
  const regex = /var\(--[\w-]+,\s*(#[0-9a-fA-F]{6}(?:[0-9a-fA-F]{2})?)\)/;
  const match = cssVar.match(regex);

  // 매치된 결과가 있으면 첫 번째 캡처 그룹(#xxxxxx 또는 #xxxxxxxx)을 반환
  if (match) {
    return match[1];
  }

  // 매치된 결과가 없으면 null을 반환
  return null;
}

export function hexToRgba(hex, alpha = 1) {
  // #이 있으면 제거
  hex = hex.replace(/^#/, "");

  // hex 길이에 따라 RGB 값 추출
  let r, g, b, a;
  if (hex.length === 6) {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
    a = Math.round(alpha * 255);
  } else if (hex.length === 8) {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
    a = parseInt(hex.substring(6, 8), 16);
  } else {
    throw new Error("Invalid hex color format");
  }

  return `rgba(${r}, ${g}, ${b}, ${a / 255})`;
}
