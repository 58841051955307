import { Stack, Typography } from "@mui/joy";
import { SelectButtonCircle } from "components/common/SelectButtonCircle";
import SelfCheckProgressBar from "components/selfCheck/SelfCheckProgressBar";
import {
  selfCheckData,
  SelfCheckId,
  SelfCheckIdType,
} from "data/selfCheckData";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selfCheckAtom } from "recoil/selfCheckAtom";

export interface SelfCheckOptionType {
  id: SelfCheckId;
  text: string;
  score: number;
  evaluationKey: string;
}

export default function SelfCheck() {
  const navigate = useNavigate();
  let { tool, id } = useParams();
  const pageId = parseInt(id || "");

  const goNext = () => {
    navigate(`/self-check/${tool}/${pageId + 1}`);
  };
  const goEnd = () => {
    navigate(`/self-check/${tool}/end`);
  };

  const type = (Object.entries(selfCheckData).find(
    ([key, value]) => value.screeningTool === tool
  ) || [])[0];

  const questionList = [
    ...[
      // "anxiety",
      // "self-esteem",
      // "stress",
      // "burnout",
      // "ADHD",
      // "insomnia",
      // "job-satisfaction",
      type,
    ].flatMap((each) => selfCheckData[each as SelfCheckIdType].questionList),
  ];

  const { t: t_selfCheck } = useTranslation("translation", {
    keyPrefix: "selfCheck",
  });

  const { t: t_common } = useTranslation("translation", {
    keyPrefix: "task.activity.common",
  });

  const [selfCheckState, setSelfCheckState] = useRecoilState(selfCheckAtom);
  const handleClick = (option: SelfCheckOptionType) => {
    setSelfCheckState((selfCheckState) => ({
      ...selfCheckState,
      [option.id]: { score: option.score, evaluationKey: option.evaluationKey },
    }));
    setTimeout(() => {
      if (pageId < questionList.length - 1) {
        goNext();
      } else {
        goEnd();
      }
    }, 400);
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        py: "20px",
        px: "24px",
        maxWidth: "720px",
        mx: "auto",
      }}
      justifyContent="center"
    >
      <SelfCheckProgressBar progress={(pageId / questionList.length) * 100} />
      <Stack
        sx={{ flex: 1 }}
        justifyContent="center"
        alignItems={"center"}
        spacing={2}
      >
        <Typography level="h4" sx={{ color: "#000000" }}>
          {t_selfCheck(questionList[pageId].questionTranslationKey)}
        </Typography>
        <Stack
          direction="row"
          sx={{ width: "100%", px: "20px" }}
          justifyContent={"space-between"}
        >
          {Object.entries(questionList[pageId].evaluation).map(
            ([evaluationKey, score]) => (
              <SelectButtonCircle
                selected={
                  (selfCheckState &&
                    selfCheckState[
                      questionList[pageId].questionTranslationKey
                    ] &&
                    selfCheckState[questionList[pageId].questionTranslationKey]
                      .evaluationKey === evaluationKey) ||
                  false
                }
                disable={false}
                option={{
                  id: questionList[pageId].questionTranslationKey,
                  text: t_common(evaluationKey),
                  score: score,
                  evaluationKey: evaluationKey,
                }}
                handleClick={handleClick}
              />
            )
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
