import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const PATIENT_DOMAIN = "patient";

export const PATIENT_LIST_ENDPOINT = "list";
export const PATIENT_NICKNAME_UPDATE_ENDPOINT = "nickname/update";
export const PATIENT_REMOVE_ENDPOINT = "remove";
export const PATIENT_HOLD_ENDPOINT = "hold";

export interface PatientType {
  patientId: string;
  nickname?: string;
  isTaskUpdateRequired?: boolean;
  isRemindRequired?: boolean;
  isRiskCheckRequired?: boolean;
  dayNumber?: number;
  doneAt?: string;
  profileImageUrl?: string;
  coachName?: string;
  isHold?: boolean;
  registeredAt?: string;
  isChatAvailable?: boolean;
  unreadMessageCount?: number;
  locale?: string;
  hasPraised?: boolean;
  isNew?: boolean;
  isHumanCheckRequired?: boolean;
  nextAutoTaskUpdateDateTime?: string;
  isInAutoProcessingStage?: boolean;
  participationType?: string;
  isBehavioralActivationProgram?: boolean;
  programVersion?: string
}

export const patientApi = {
  getPatientList: () =>
    axiosInstance.get<CustomResponseFormat<PatientType[]>>(
      `${PATIENT_DOMAIN}/${PATIENT_LIST_ENDPOINT}`
    ),
  updateNickname: (pid: string, nickname: string) =>
    axiosInstance.post<void>(
      `${PATIENT_DOMAIN}/${PATIENT_NICKNAME_UPDATE_ENDPOINT}`,
      {
        nickname: nickname,
      },
      { params: { pid: pid } }
    ),
  remove: (pid: string) =>
    axiosInstance.get<void>(`${PATIENT_DOMAIN}/${PATIENT_REMOVE_ENDPOINT}`, {
      params: { pid: pid },
    }),
  hold: (pid: string, holdDays: number) =>
    axiosInstance.post<void>(
      `${PATIENT_DOMAIN}/${PATIENT_HOLD_ENDPOINT}`,
      { holdDays: holdDays },
      { params: { pid: pid } }
    ),
};
