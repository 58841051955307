export const translation_7_3_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "이제부터 나의 가치를 구체화해보려고 해요.",
      typography_1_0_0:
        "나의 가치를 찾기 위해서는 ‘가치'라는 게 정확히 무엇인지 이해할 필요가 있답니다.",
      typography_2_0_0: `가치는 “내가 선택한 삶의 방향"이에요.`,
      typography_3_0_0: "이 말이 무슨 뜻인지 하나씩 살펴볼게요.",
      typography_4_0_0: "가치는 선택이다",
      typography_5_0_0: "가치는 선택이에요.",
      typography_6_0_0:
        "누가 나에게 강요하는 것도, 나에게 그저 주어지는 것도 아니에요.",
      typography_7_0_0:
        "추론을 하며 몇 가지 후보 중 ‘가장 좋아보이는' 것을 고르는 것도 아니에요.",
      typography_8_0_0: "가치는 그저 내가 원하는 걸 선택하는 것이랍니다.",
      typography_9_0_0:
        "많은 사람들이 삶에서 중요한 가치가 무엇인지 물으면 타인이, 혹은 사회가 요구하는 가치를 말하곤 해요.",
      typography_10_0_0: `“저는 좋은 대학교에 가는 게 중요해요.”`,
      typography_11_0_0: `“저는 좋은 직장을 가지는 게 중요해요.”`,
      typography_12_0_0:
        "하지만 조금 더 자세히 파고들어보면 그 이면에는 남들에게 인정받고 싶은 욕구가 숨어있답니다.",
      typography_13_0_0: `“저는 좋은 대학교에 가는 게 중요해요. (그래야 사람들이 저를 인정해주거든요)"`,
      typography_14_0_0: `“저는 좋은 직장을 가지는 게 중요해요. (그래야 배우자가 저를 사랑해주거든요)"`,
      typography_15_0_0: "남을 만족시키기 위한 것이지요.",
      typography_16_0_0: "가치는 그런 게 아니에요.",
      typography_17_0_0:
        "가치는 내가 무엇을 중시하며 삶을 살아갈지 오롯이 스스로가 선택하는 것이랍니다.",
      typography_18_0_0: `그러니 가치를 구체화할 때는 이 가치가 "진정으로 나를 위한 것인지, 내가 원하는 것이 맞는지" 살펴보세요.`,
      typography_19_0_0:
        "만약 나의 약한 자아를 감추거나, 다른 누군가를 만족시키기 위한 거라면 과감하게 포기해도 괜찮아요.",
      typography_20_0_0:
        "조급해할 할 필요는 없답니다. 생각과 거리를 두는 연습을 하다보면 가치는 드러나기 마련이니까요.",
      typography_21_0_0: "가치는 방향이다",
      typography_22_0_0: "가치는 방향이에요.",
      typography_23_0_0: "어떤 목표나 결과가 아니죠.",
      typography_24_0_0:
        "많은 사람들이 각자의 가치를 어떤 목표나 결과로 이야기하곤 해요.",
      typography_25_0_0: `“저는 돈을 10억을 버는 거요."`,
      typography_26_0_0: `“저는 지금 만나고 있는 사람과 결혼하는 것이요."`,
      typography_27_0_0: "하지만 가치는 어떤 목표 지점이 아니에요.",
      typography_28_0_0: `그저 매 순간 어디로 향해 있을지에 대한 방향이랍니다.`,
      typography_29_0_0:
        "그렇기 때문에 가치는 결코 완벽하게 달성될 수는 없어요.",
      typography_30_0_0: `“사람들에게 사랑을 베풀기"`,
      typography_31_0_0: `“팀원들을 잘 이끌기"`,
      typography_32_0_0: `“아이에게 자상하고 따뜻하게 대하기"`,
      typography_33_0_0: `“사회를 더 나은 세상으로 만들기"`,
      typography_34_0_0: `이런 가치들은 달성할 수 있는 결과나 목표가 아니에요.`,
      typography_35_0_0: `오히려 삶에서 어떤 것들을 중요시하며 살아갈지 알려주는 나침반에 더 가깝죠.`,
      typography_36_0_0: `그렇기 때문에 나아갈 방향을 선택하는 지금 이 순간부터 변화를 만들어 낼 수 있답니다.`,
      typography_37_0_0: `나의 가치 찾기`,
      typography_38_0_0: `자, 지금부터 나의 가치를 찾아볼 텐데요.`,
      typography_39_0_0: `그동안 작성했던 긍정 기록을 활용할 거예요.`,
      typography_40_0_0: `만약 구체화한 가치가 진정으로 내가 원하는 선택이 아니거나`,
      typography_41_0_0: `방향이 아닌 구체적인 목표나 결과를 가리키고 있다면 과감하게 폐기해도 좋아요.`,
      typography_42_0_0: `하지만 나의 선택이고 내가 나아갈 방향이라면 너무 깊게 고민하지 않아도 된답니다.`,
      typography_43_0_0: `앞으로 얼마든지 더 많은 가치를 찾아나갈 거니까요.`,
      typography_44_0_0: `그리고 삶의 시기에 따라 가치는 얼마든지 변할 수도 있어요.`,
      typography_45_0_0: `그저 지금 이 순간의 나의 가치를 발견하는 데에 집중해보세요.`,
      typography_46_0_0: `자, 그러면 이제 나의 가치를 찾으러 가볼까요?`,
    },
  },
};
