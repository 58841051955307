import {
  Instruction,
  InstructionWithContent,
  InstructionWithTextarea,
  InstructionWithDataWithContent,
  InstructionWithButtonGroup,
} from "../../BlockComponent";
import {
  Audio,
  ButtonGroup,
  ButtonGroupMultipleChoice,
  Image,
  Percentage,
  TextareaLine,
} from "../../CellComponent";
import { LastCard, LastCard_simple } from "../common";
import dayjs from "dayjs";

const NotificationTimeBlocks = [
  InstructionWithContent(
    "initial_typography_9_0_0",
    [
      [
        ButtonGroupMultipleChoice(
          [
            {
              translationKey: "월요일",
            },
            {
              translationKey: "화요일",
            },
            {
              translationKey: "수요일",
            },
            {
              translationKey: "목요일",
            },
            {
              translationKey: "금요일",
            },
            {
              translationKey: "토요일",
            },
            {
              translationKey: "일요일",
            },
          ],
          { id: "notification_date" }
        ),
      ],
    ],
    { blockId: "알림 허용" }
  ),
  InstructionWithContent("initial_typography_10_0_0", [
    [
      ButtonGroupMultipleChoice(
        [
          {
            translationKey: "오전 7시",
          },
          {
            translationKey: "오전 10시",
          },
          {
            translationKey: "오후 1시",
          },
          {
            translationKey: "오후 4시",
          },
          {
            translationKey: "오후 7시",
          },
          {
            translationKey: "오후 10시",
          },
        ],
        { id: "notification_time" }
      ),
    ],
  ]),
];

const today = dayjs().format("YYYY-MM-DD");

export const Content_5_7_T = (isPushNotificationGranted: boolean) => [
  Instruction("initial_typography_0_0_0"),
  Instruction("initial_typography_1_0_0"),
  Instruction("initial_typography_2_0_0"),
  Instruction("initial_typography_3_0_0"),
  Instruction("initial_typography_4_0_0"),
  Instruction("initial_typography_5_0_0"),
  Instruction("initial_typography_6_0_0"),
  Instruction("initial_typography_7_0_0"),

  ...(!isPushNotificationGranted
    ? [
        InstructionWithContent("initial_typography_8_0_0", [
          [
            {
              type: "pushNotification",
              content: { showFromIdIfDenied: "알림 미허용" },
            },
          ],
        ]),
        ...NotificationTimeBlocks,
      ]
    : [...NotificationTimeBlocks]),

  Instruction(`initial_typography_11_0_0`, {
    blockId: "알림 미허용",
  }),
  ...MeditationTemplate,

  ...LastCard,
];

export const MeditationTemplate = [
  InstructionWithContent("typography_0_0_0", [
    TextareaLine(),
    // [DateSelect({ id: "date", value: today })],
    [
      {
        type: "temp",
        content: {
          id: `date`,
          value: today,
        },
      },
    ],
  ]),
  InstructionWithContent("typography_1_0_0", [
    [Percentage({ id: "영향받는 정도" })],
  ]),
  InstructionWithButtonGroup("typography_2_0_0", [
    {
      translationKey: "5분",
      showFromId: "오디오_5분",
      hideFromId: "오디오_10분",
    },
    {
      translationKey: "10분",
      showFromId: "오디오_10분",
      hideFromId: "오디오_5분",
    },
  ]),
  InstructionWithContent(
    "typography_3_0_0",
    [Image("0"), Audio("stream_of_thoughts_5min")],
    {
      blockId: "오디오_5분",
      nextBlockId: "마무리",
    }
  ),
  InstructionWithContent(
    "typography_3_0_0",
    [Image("0"), Audio("observing_mind_10min")],
    {
      blockId: "오디오_10분",
    }
  ),
  InstructionWithTextarea(
    "typography_4_0_0",
    { id: "소감" },
    { blockId: "마무리" }
  ),
  InstructionWithDataWithContent(
    { A: { id: "영향받는 정도" } },
    "typography_5_0_0",
    [[Percentage({ id: "영향받는 정도2" })]]
  ),
  Instruction("typography_6_0_0"),
  Instruction("typography_7_0_0"),
  ...LastCard_simple,
];
