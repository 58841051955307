import { ButtonGroup, Percentage, ReferenceLine } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithButtonGroup,
  InstructionWithContent,
  InstructionWithTextarea,
} from "../../BlockComponent";

import { ConceptItem, LastCard } from "../common";

export const Content_6_3_A = [
  Instruction(`typography_0_0_0`),
  InstructionWithContent(`typography_1_0_0`, [
    ReferenceLine({ referenceKey: "intermediateBeliefReferenceList" }),
  ]),

  Header1(`typography_2_0_0`),
  InstructionWithContent(
    `typography_3_0_0`,
    // IntermediateBelief({
    //   isNotConfirmed: true,
    //   intermediateBeliefKeyList: intermediateBeliefKeyList,
    // }),
    ConceptItem({ conceptKey: "intermediateBelief", isNotConfirmed: true })
  ),

  InstructionWithButtonGroup(`typography_4_0_0`, [
    {
      translationKey: "네",
      showFromId: "동의",
      hideFromId: "비동의",
    },
    {
      translationKey: "아니요",
      showFromId: "비동의",
      hideFromId: "동의",
    },
  ]),
  InstructionWithTextarea(`typography_5_0_0`, undefined, {
    blockId: "비동의",
  }),
  Instruction(`typography_6_0_0`, {
    noArrow: true,
    isEnd: true,
    isHidden: true,
  }),
  Instruction(`typography_7_0_0`, { blockId: "동의" }),
  Instruction(`typography_8_0_0`),
  InstructionWithTextarea(`typography_9_0_0`),
  InstructionWithContent("typography_10_0_0", [
    [Percentage({ id: "영향받는 정도_intermediateBelief" })],
  ]),
  InstructionWithTextarea(`typography_11_0_0`),

  Instruction(`typography_13`),
  Instruction(`typography_14`),
  Instruction(`typography_15`),
  Instruction(`typography_16`),

  ...LastCard,
];
