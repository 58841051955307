import Button, { ButtonPropsColorOverrides } from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import {
  Box,
  ColorPaletteProp,
  ListItemButton,
  Typography,
  VariantProp,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { usePrevious } from "@uidotdev/usehooks";
import { useRecoilValue } from "recoil";
import { permanentDataAtom } from "recoil/permanentDataAtom";
import { addAlpha } from "styles/hexOpacity";
import { sendMessage } from "utils/webview";
import useFetch from "hooks/useFetch";

export default function ButtonWithModal({
  variant,
  size,
  color,
  startDecorator,
  buttonText,
  title,
  subtitle,
  textAlign,
  content,
  disabled,
  onClick,
  disableModalOpen,
  action,
  fetchActionUrl,
  cancelAction,
  actionText,
  cancelText,
  isListItemButton,
  onClose,
  actionButtonColor,
  actionButtonFullWidth,
  buttonFullWidth,
  actionButtonDisabled,
  clickWithEnter,
  setClickWithEnter,
  render,
  loading,
  actionAfterFetch,
  bottomIfMobile,
  isWide,
}: {
  variant?: VariantProp;
  size?: "sm" | "lg";
  color?: "primary" | "danger" | "white";
  startDecorator?: React.ReactNode;
  buttonText: string;
  title?: string;
  subtitle?: string;
  textAlign?: "center";
  content?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  disableModalOpen?: boolean;
  action?: () => void;
  fetchActionUrl?: string;
  cancelAction?: () => void;
  actionText?: string;
  cancelText?: string;
  isListItemButton?: boolean;
  onClose?: () => void;
  actionButtonColor?: ColorPaletteProp;
  actionButtonFullWidth?: boolean;
  buttonFullWidth?: boolean;
  actionButtonDisabled?: boolean;
  clickWithEnter?: boolean;
  setClickWithEnter?: React.Dispatch<React.SetStateAction<boolean>>;
  render?: React.ReactNode;
  loading?: boolean;
  actionAfterFetch?: () => void;
  bottomIfMobile?: boolean;
  isWide?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [isActionButtonLoading, setIsActionButtonLoading] = useState(false);
  const previousOpen = usePrevious(open);

  const permanantDataState = useRecoilValue(permanentDataAtom);

  const [fetchData, fetch] = useFetch({
    url: fetchActionUrl || "",
  });

  useEffect(() => {
    if (fetchData) {
      setIsActionButtonLoading(false);
      setOpen(false);
      sendModalEventToApp("close");
      if (actionAfterFetch) {
        actionAfterFetch();
      }
    }
  }, [fetchData]);

  const [backdropOpacity, setBackdropOpacity] = useState(0);

  useEffect(() => {
    if (previousOpen && !open) {
      if (onClose) {
        onClose();
      }
    }
  }, [open]);

  function sendModalEventToApp(status: "open" | "close") {
    sendMessage(
      JSON.stringify({
        event: "modal",
        params: {
          status: status,
        },
      })
    );
  }

  useEffect(() => {
    return () => {
      sendModalEventToApp("close");
    };
  }, []);

  const handleClick = () => {
    if (!disableModalOpen) {
      sendModalEventToApp("open");
    }

    setTimeout(() => {
      if (onClick) {
        onClick();
      }
      if (!disableModalOpen) {
        setOpen(true);
      }
      setTimeout(() => {
        setBackdropOpacity(1);
      }, 10);
    }, 100);
  };

  useEffect(() => {
    if (clickWithEnter && !disabled) {
      handleClick();
      if (setClickWithEnter) {
        setClickWithEnter(false);
      }
    }
  }, [clickWithEnter]);

  return (
    <React.Fragment>
      {render ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
        >
          {render}
        </div>
      ) : isListItemButton ? (
        <ListItemButton
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
          className="flex justify-center"
        >
          <Typography>{buttonText}</Typography>
        </ListItemButton>
      ) : (
        <Button
          disabled={disabled}
          variant={variant}
          size={size}
          color={color}
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
          startDecorator={startDecorator}
          loading={loading}
          sx={{ ...(buttonFullWidth && { width: "100%" }) }}
        >
          {buttonText}
        </Button>
      )}
      <Modal
        open={open}
        onClose={(e, r) => {
          if (r === "backdropClick") {
            (e as MouseEvent).stopPropagation();
          }

          sendModalEventToApp("close");

          setTimeout(() => {
            setBackdropOpacity(0);
            setOpen(false);
          }, 100);
        }}
        sx={{
          "&>.MuiModal-backdrop": {
            backdropFilter: "none",
            backgroundColor: addAlpha("#000000", 0.3),
            opacity: backdropOpacity,
            transition: "all 0.3s",
          },
        }}
      >
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            ...(isWide && {
              width: "100%",
              [theme.breakpoints.up("sm")]: {
                maxWidth: "600px",
              },
            }),
            [theme.breakpoints.only("xs")]: bottomIfMobile
              ? {
                  top: "unset",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  borderRadius: 0,
                  transform: "none",
                  maxWidth: "unset",
                  paddingBottom: `${permanantDataState?.homeIndicatorHeight}px`,
                }
              : {},
          })}
          onClick={(e) => {
            // e.preventDefault();
            e.stopPropagation();
          }}
        >
          {title && (
            <Typography
              id="nested-modal-title"
              level="h2"
              sx={{ textAlign: textAlign }}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography id="nested-modal-description" textColor="text.tertiary">
              {subtitle}
            </Typography>
          )}
          {content}
          {actionText && (
            <Box
              sx={{
                mt: 1,
                display: "flex",
                gap: 1,
                flexDirection: { xs: "column", sm: "row-reverse" },
              }}
            >
              {actionText && (
                <Button
                  disabled={actionButtonDisabled}
                  variant="solid"
                  size="lg"
                  color={actionButtonColor || "primary"}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (fetchActionUrl) {
                      fetch();
                      setIsActionButtonLoading(true);
                    } else {
                      if (action) {
                        action();
                      }
                      setOpen(false);
                      sendModalEventToApp("close");
                    }
                  }}
                  sx={{
                    width: actionButtonFullWidth ? "100%" : "auto",
                  }}
                  loading={isActionButtonLoading}
                >
                  {actionText}
                </Button>
              )}
              {cancelText && (
                <Button
                  variant="plain"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (cancelAction) {
                      cancelAction();
                    }
                    setOpen(false);
                  }}
                >
                  {cancelText}
                </Button>
              )}
            </Box>
          )}
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
