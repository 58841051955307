import { useCallback, useState } from "react";
import { Stack, Typography } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { useQuery } from "@tanstack/react-query";
import ContentTemplate from "../content/ContentTemplate";
import {
  MeditationAnalysisType,
  meditationRecordApi,
  MEDITATION_RECORD_ANALYSIS_DETAIL_ENDPOINT,
  MEDITATION_RECORD_DOMAIN,
} from "api/meditationRecordApi";
import LineChart from "components/common/LineChart";
import BarChart from "components/common/BarChart";
import WeekTracking from "./WeekTracking";

const Container = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Stack
      sx={{
        borderRadius: "12px",
        backgroundColor: "white",
        borderWidth: "1px",
        borderColor: "divider",
        p: "20px",
      }}
      spacing={"20px"}
    >
      <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
        {title}
      </Typography>

      {children}
    </Stack>
  );
};

export default function Statistics() {
  const patientState = useRecoilValue(patientAtom);

  const [data, setData] = useState<MeditationAnalysisType | undefined>();

  const getAnalysisQueryFn = useCallback(
    () => meditationRecordApi.getAnalysisDetail(`${patientState?.patientId}`),
    [patientState]
  );

  useQuery(
    [MEDITATION_RECORD_DOMAIN, MEDITATION_RECORD_ANALYSIS_DETAIL_ENDPOINT],
    getAnalysisQueryFn,
    {
      enabled: !!patientState?.patientId,
      onSuccess: (data) => {
        console.log(data.result);
        setData(data.result);
      },
    }
  );

  const mock = [10, 20, 30, 40, 50, 60, 80, 100, 150, 180];
  function roundUpToNearestTen(num: number) {
    return (Math.ceil(num / 10) + (num % 10 > 0 ? 0 : 1)) * 10;
  }

  return (
    <ContentTemplate>
      <Stack spacing={"16px"} sx={{}}>
        <Container title={"이번 주 훈련 목표"}>
          <WeekTracking
            goalDayOfWeekList={data?.goalDayOfWeekList}
            historyList={data?.historyList}
          />
        </Container>
        <Container title={"누적 훈련 시간 (분)"}>
          <LineChart
            id={"cumulative"}
            maxScore={roundUpToNearestTen(
              Math.max(
                ...(data?.cumulativeByWeekNumber.map((each) => each.minutes) ||
                  [])
              )
            )}
            placeholder={"명상 훈련을 마쳐주세요."}
            data={
              data?.cumulativeByWeekNumber.map((each) => each.minutes) || []
            }
            xLabel={data?.cumulativeByWeekNumber.map(
              (each) => `w${each.weekNumber}`
            )}
            {...((data?.cumulativeByWeekNumber || []).length > 0 && {
              showYRange: true,
            })}
            showMarkValue
            showXAxis
          />
        </Container>
        <Container title={"1회 평균 훈련 시간 (분)"}>
          <BarChart
            id={"average"}
            placeholder={"명상 훈련을 마쳐주세요."}
            maxScore={roundUpToNearestTen(
              Math.max(
                ...(data?.averageByWeekNumber.map((each) => each.minutes) || [])
              )
            )}
            data={data?.averageByWeekNumber.map((each) => each.minutes) || []}
            xLabel={data?.averageByWeekNumber.map(
              (each) => `w${each.weekNumber}`
            )}
            {...((data?.averageByWeekNumber || []).length > 0 && {
              showYRange: true,
            })}
            showMarkValue
            showXAxis
          />
        </Container>
      </Stack>
    </ContentTemplate>
  );
}
