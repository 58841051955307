import { Header1, Instruction, InstructionWithImage } from "../../BlockComponent";

import { LastCard, NotificationBlocksWeekly } from "../common";

export const Content_8_6_A = (isPushNotificationGranted: boolean) => [
  Instruction(`typography_0_0_0`),
  Instruction("typography_1_0_0"),
  InstructionWithImage(`typography_2_0_0`, "1"),
  Instruction(`typography_3_0_0`),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),
  InstructionWithImage(`typography_7_0_0`, "2"),
  Instruction(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),

  ...NotificationBlocksWeekly(isPushNotificationGranted),

  Instruction(`typography_13_0_0`, { blockId: "알림 미허용" }),

  Header1(`typography_14_0_0`),
  Instruction(`typography_15_0_0`),
  Instruction(`typography_16_0_0`),
  Instruction(`typography_17_0_0`),
  Instruction(`typography_18_0_0`),
  Instruction(`typography_19_0_0`),
  Instruction(`typography_20_0_0`),
  Instruction(`typography_21_0_0`),
  Instruction(`typography_22_0_0`),
  Instruction(`typography_23_0_0`),
  Instruction(`typography_24_0_0`),

  ...LastCard,
];
