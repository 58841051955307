import { translation_meditation } from "./meditation";

export const translation_8_2_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "지금부터 우리가 연습해야 할 건 생각을 하나의 심리적 사건으로 바라보는 거예요.",
      typography_1_0_0:
        "마음속에 떠오르는 생각을 팝콘 기계에서 튀어 오르는 팝콘처럼 바라보는 것이랍니다.",
      typography_2_0_0:
        "생각이 나 자신이라고 믿고 생각을 끊임없이 곱씹거나 파고들 필요도 없어요.",
      typography_3_0_0:
        "그저 마음속에 그러한 생각이 떠오른다는 것을 알아차린 후 그대로 흘려보낸 뒤,",
      typography_4_0_0: `다시 또 어떤 생각들이 떠오르는지 바라보면 된답니다.`,
      typography_5_0_0: "실제로 해보면서 익혀나가는 것이 중요한데요.",
      typography_6_0_0:
        "우린 앞으로 다음과 같은 이미지를 떠올리며 연습할 거예요.",
      typography_7_0_0: "생각의 강",
      typography_8_0_0: "마음속에 시냇물이 흐르고 있다고 상상해보세요.",
      typography_9_0_0: "우거진 숲 사이로 흐르는 시냇물이에요.",
      typography_10_0_0: "생각의 강이죠.",
      typography_11_0_0: "시냇물은 위에서 아래로 고요히 흐릅니다.",
      typography_12_0_0: "시냇물 위에는 나뭇잎이 놓여있어요.",
      typography_13_0_0:
        "나뭇잎은 물을 따라 시냇물의 높은 곳에서 낮은 곳으로 떠내려갑니다.",
      typography_14_0_0: "시냇물은 나의 마음이에요.",
      typography_15_0_0: "나뭇잎은 나의 생각이에요.",
      typography_16_0_0: "나는 시냇물 옆에 앉아 있어요.",
      typography_17_0_0:
        "나는 그저 한 발짝 떨어져서 마음을 관찰하고 있을 뿐이에요.",
      typography_18_0_0: "생각은 그저 마음속에 떠올라요.",
      typography_19_0_0: "나는 그 생각을 알아차리기만 하면 됩니다.",
      typography_20_0_0:
        "어떠한 생각이 마음속에 떠오르면 그저 ‘생각이구나'하고 바라보면 돼요.",
      typography_21_0_0:
        "나뭇잎 위에 놓여있는 생각을 따라 강 아래쪽으로 뛰어갈 필요도 없고,",
      typography_22_0_0:
        "시냇물 안에 들어가서 나뭇잎을 만지작거리며 소란을 피울 필요도 없어요.",
      typography_23_0_0: "그저 시냇물 옆에 앉아서 생각을 바라보기만 하면 돼요.",
      typography_24_0_0:
        "나의 위치는 시냇물 속이 아니라는 것을 기억하시길 바랍니다.",
      typography_25_0_0:
        "아마 직접 연습을 해보면 이 일이 좀처럼 쉽지 않다는 것을 발견하게 될 거예요.",
      typography_26_0_0:
        "분명 나도 모르는 사이에 특정한 나뭇잎을 끊임없이 따라가고 있거나,",
      typography_27_0_0: "어떤 나뭇잎을 찾아 헤매거나",
      typography_28_0_0: `특정한 나뭇잎을 없애려고 시냇물 속에서 첨벙대고 있을 거예요.`,
      typography_29_0_0: "괜찮아요. 그게 자연스러운 우리의 본능이니까요.",
      typography_30_0_0:
        "만약 어느 순간 자신이 시냇물 옆에 앉아 있지 않다는 걸 알아차린다면,",
      typography_31_0_0: "다시 나의 자리로 돌아오면 돼요.",
      typography_32_0_0:
        "우리가 해야 할 일은 시냇물 옆에 앉아서 생각을 하나의 심리적 사건으로 바라보다가",
      typography_33_0_0: `제자리를 벗어난 걸 알아차린 순간,`,
      typography_34_0_0: `즉, 정신없이 어떤 생각에 빠져있는 걸 알아차린 순간,`,
      typography_35_0_0: `다시 제자리로 돌아오는 걸 반복하는 것뿐이에요.`,
      typography_36_0_0: `매일 꾸준히 연습하기`,
      typography_37_0_0: `자, 앞으로 이렇게 생각을 바라보는 연습을 꾸준히 해 볼 텐데요.`,
      typography_38_0_0: `앞으로 4회에 걸쳐 자세한 설명을 조금씩 덧붙이며 직접 도와드릴 거예요.`,
      typography_39_0_0: `그 과정에서 궁금한 게 있다면 적극적으로 여쭤보셔도 좋아요.`,
      typography_40_0_0: `이 연습은 목적과 원리를 잘 이해하고 진행하는 게 중요하답니다.`,
      typography_41_0_0: `첫 번째 연습`,
      typography_42_0_0: `우선 오늘은 첫 번째 연습으로 딱 5분만 진행해볼 건데요.`,
      ...translation_meditation.ko.v2,
    },
  },
};
