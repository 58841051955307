import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { actionCodeSettings } from "utils/auth_email_link_actioncode_settings";

import ButtonWithModal from "components/common/ButtonWithModal";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { benefitApi } from "api/benefitApi";
import ButtonWithOptionalModal from "components/common/ButtonWithOptionalModal";
import { getApp, initializeApp } from "firebase/app";

export default function EmailAuthButton({
  email,
  disabled,
  // isEmailValid,
  setIsEmailSent,
}: {
  email: string;
  disabled?: boolean;
  // isEmailValid?: boolean;
  setIsEmailSent: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

  const sendEmail = () => {
    setLoading(true);
    const app = getApp();
    const auth = getAuth(app);
    // const auth = getAuth();
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        setIsEmailSent(true);

        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);
      });
  };
  const [loading, setLoading] = useState<boolean>(false);

  const { mutate: verify } = useMutation(() => benefitApi.verify(email), {
    onSuccess: (data) => {
      if (data.result?.isVerified) {
        setIsEmailValid(true);
        sendEmail();
      } else {
        setIsEmailValid(false);
      }
    },
  });
  return (
    <>
      {/* <ButtonWithModal
        size="lg"
        startDecorator={undefined}
        disabled={disabled}
        buttonText={"이메일 인증"}
        // title={"메일 주소를 다시 확인해주세요."}
        // subtitle={"디스턴싱과 함께하는 학교/기관이 아닌 것 같아요."}
        title={"혜택을 받을 수 있는 이메일이 아니에요."}
        subtitle={"관련 문의는 support@orwellhealth.org 로 보내주세요."}
        // onClick={isEmailValid ? sendEmail : undefined}
        onClick={verify}
        disableModalOpen={isEmailValid}
        loading={loading}
      /> */}
      <ButtonWithOptionalModal
        size="lg"
        startDecorator={undefined}
        disabled={disabled}
        onClick={verify}
        openModal={!isEmailValid}
        onClose={() => {
          setIsEmailValid(true);
        }}
        buttonText="이메일 인증"
        title={"혜택을 받을 수 있는 이메일이 아니에요."}
        subtitle={"관련 문의는 support@orwellhealth.org 로 보내주세요."}
        loading={loading}
      />
    </>
  );
}
