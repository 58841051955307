import { compareVersions } from "logic/logics_common";
import { useState } from "react";
import useGetDeviceInfo from "./useGetDeviceInfo";
import useIsInappWebview from "./useIsInappWebview";

export default function useIsLatest() {
  const isInappWebview = useIsInappWebview();
  const { appVersion } = useGetDeviceInfo();

  const requiredVersion = "1.0.29";
  const [isLatest] = useState(
    !isInappWebview || compareVersions(appVersion, requiredVersion) >= 0
  );
  return isLatest;
}
