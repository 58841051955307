export const translation_4_2 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "자, 생각과 거리를 멀리할 수 있다면 어떠한 것들이 가능해지는지 알아보도록 하죠.",
      typography_1_0_0:
        "역시나 이해를 돕기 위해 한 가지 간단한 실험과 함께 할 겁니다.",
      typography_2_0_0:
        "거듭 강조하듯 직접 해보는 것이 중요하니, 꼭 해보시길 바랍니다.",
      typography_3_0_0: "거리가 가까울 때",
      typography_4_0_0: "휴대폰을 들어보세요.",
      typography_5_0_0: "아무 화면이나 켜진 상태로요.",
      typography_6_0_0:
        "그리고 이제 휴대폰을 눈 바로 앞까지 가져간 후, 화면에 적혀있는 글자를 읽어보세요.",
      typography_7_0_0: "지금 해보시고 다음으로 넘어가주세요.",
      typography_8_0_0: "어떤가요? 잘 읽혔나요?",
      typography_9_0_0: "아마 잘 안 보였을 겁니다. 한 번만 더 해볼게요.",
      typography_10_0_0:
        "이번엔 최선을 다해 두 눈을 크게 뜨고 글자를 읽으려고 노력해보세요.",
      typography_11_0_0:
        "우리가 생각을 교정하거나 대체하려고 애쓰듯, 정말로 최선을 다해서요.",
      typography_12_0_0: "꼭 해보시고 다음으로 넘어가주세요.",
      typography_13_0_0: "어떤가요? 잘 읽혔나요?",
      typography_14_0_0: "아마 아닐 겁니다.",
      typography_15_0_0:
        "액정의 밝은 빛 때문에 오히려 눈만 아프고 눈물이 찔끔 났을지도 모르죠.",
      typography_16_0_0: "거리가 멀 때",
      typography_17_0_0:
        "이번에는 휴대폰을 충분히 눈에서 멀어지게 한 후 글자를 읽어봅시다.",
      typography_18_0_0: "이번엔 어떤가요?",
      typography_19_0_0: "아마 편안하게 글자가 잘 읽혔을 것입니다.",
      typography_20_0_0: "거리를 둬야하는 이유",
      typography_21_0_0:
        "우리는 생각에 대해서도 정확히 똑같은 방법으로 접근할 겁니다.",
      typography_22_0_0: "문제는 휴대폰이 아닙니다.",
      typography_23_0_0:
        "휴대폰을 바꿔서 해결할 문제도, 휴대폰을 없애서 해결할 문제도 아닙니다.",
      typography_24_0_0: "문제는 휴대폰과 거리가 가까운 것입니다.",
      typography_25_0_0:
        "우리가 해야할 것은 휴대폰과 눈 사이에 충분한 거리를 만드는 것입니다.",
      typography_26_0_0:
        "그저 거리를 두고 바라보면, 우리는 그 내용을 선명하게 알아차릴 수 있고 (글자도 읽을 수 있고)",
      typography_27_0_0:
        "동시에 그로부터 받는 영향에서 자유로워질 수 있습니다. (눈도 아프지 않죠.)",
      typography_28_0_0:
        "이것이 생각과 거리를 두며 충분한 공간을 확보해야 하는 이유입니다.",
      typography_29_0_0:
        "다음 활동에서는 생각과 관계를 다시 맺는다는 것이 무엇인지 조금 더 자세히 살펴보도록 합시다.",
    },
  },
};
