import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types';
// import { hasTouch } from 'detect-touch';
import { motion } from "framer-motion";
import { SelfCheckOptionType } from "pages/self-check/SelfCheck";

export function SelectButtonCircle({
  selected,
  option,
  disable,
  handleClick,
}: {
  selected: boolean;
  option: SelfCheckOptionType;
  disable: boolean;
  handleClick: (option: SelfCheckOptionType) => void;
}) {
  const [buttonDown, setButtonDown] = useState(false);

  // useEffect(() => {
  //   // console.log(hasTouch);
  //   if (!hasTouch) {
  //     window.addEventListener('mouseup', mouseUp);
  //     return () => {
  //       window.addEventListener('mouseup', mouseUp);
  //     };
  //   }
  // });

  return (
    <div
      className={`flex flex-col items-center w-[47px] relative`}
      onMouseDown={(e) => {
        // if (!hasTouch) {
        setButtonDown(true);
        // }
      }}
      onClick={(e) => {
        // if (!hasTouch && !disable) {
        handleClick(option);
        // }
      }}
      onTouchStart={(e) => {
        // if (!buttonDown && !disable) {
        console.log("touchstart");
        setButtonDown(true);
        // }
      }}
      onTouchEnd={(e) => {
        console.log("touchend");
        setButtonDown(false);
        if (
          document
            .elementsFromPoint(
              e.changedTouches[0].clientX,
              e.changedTouches[0].clientY
            )
            .includes(e.currentTarget) &&
          !disable
        ) {
          handleClick(option);
        }
      }}
    >
      <motion.div
        animate={{ scale: buttonDown && !disable ? 0.95 : 1 }}
        transition={{ duration: 0.2 }}
      >
        <div
          className={`w-[47px] h-[47px] rounded-[47px] 
                mb-[10px] flex items-center flex justify-center items-center
                ring-[1px] ring-inset ring-[#00000005]
            ${
              selected
                ? `font-bold text-[#2C4BEC] bg-[#E9F9F8]`
                : `font-normal text-[#4E4F53] bg-[#F3F5F8]`
            }`}
        >
          <div
            className={`w-[25px] h-[25px] rounded-[25px] ${"bg-[#E1E4EB]"} `}
          >
            <div
              className={`w-full h-full rounded-[25px] ${"bg-[#298E88]"} ${
                selected ? "scale-100" : "scale-0"
              } transition-all`}
            />
          </div>
        </div>
      </motion.div>
      <div className="select-none text-[15px] text-[#4E4F53] leading-[21px]  whitespace-nowrap ">
        {option.text}
      </div>
    </div>
  );
}
