import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import { replaceColor } from "lottie-colorify";
export function Lottie(props) {
  const { containerWidth, src, originalColor, newColor } = props;
  const container = useRef();

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData:
        originalColor && newColor
          ? replaceColor(originalColor, newColor, src)
          : src,
    });
    return () => instance.destroy();
  }, []);

  return <div style={{ width: containerWidth }} ref={container}></div>;
}
