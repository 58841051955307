import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { generateRecordKey } from "logic/logics";
import useOpenMeditationRecord from "./useOpenMeditationRecord";

export default function useOpenNewMeditationRecord() {
  const patientState = useRecoilValue(patientAtom);
  const newMeditationRecordKey = generateRecordKey(
    patientState?.patientId || "",
    "meditation"
  );
  const openNewMeditationRecord = useOpenMeditationRecord(
    newMeditationRecordKey
  );

  return openNewMeditationRecord;
}
