import { useQueryClient } from "@tanstack/react-query";
import {
  HOME_DOMAIN,
  HOME_MEDITATION_LIST_ENDPOINT,
  HOME_TASK_LIST_ENDPOINT,
  HOME_THOUGHTRECORD_LIST_ENDPOINT,
} from "api/homeApi";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";

export default function useRefetchTaskList() {
  const queryClient = useQueryClient();
  const patientState = useRecoilValue(patientAtom);

  const refetchTaskList = () => {
    console.log("[TEST] refetch task");
    queryClient.invalidateQueries([
      HOME_DOMAIN,
      HOME_TASK_LIST_ENDPOINT,
      patientState?.patientId,
    ]);
    queryClient.invalidateQueries([
      HOME_DOMAIN,
      HOME_THOUGHTRECORD_LIST_ENDPOINT,
      patientState?.patientId,
    ]);
    queryClient.invalidateQueries([
      HOME_DOMAIN,
      HOME_MEDITATION_LIST_ENDPOINT,
      patientState?.patientId,
    ]);
  };

  return refetchTaskList;
}
