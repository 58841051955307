import { Audio, ButtonGroup, Image } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithButtonGroup,
  InstructionWithContent,
  InstructionWithImage,
} from "../../BlockComponent";

import { LastCard, NotificationOnceAndInstructionBlocks } from "../common";

export const ClarificationBlocks = [
  Instruction("clarification_0"),
  InstructionWithButtonGroup(`clarification_1`, [
    {
      text: "네, 졸렸어요",
      showFromId: "clarification1_Y",
      hideFromId: "clarification2",
    },
    {
      text: "아니요, 괜찮았어요",
      showFromId: "clarification2",
      hideFromId: "clarification1_Y",
    },
  ]),
  Instruction("clarification_1_0", { blockId: "clarification1_Y" }),
  Instruction("clarification_1_1"),
  Instruction("clarification_1_2"),
  Instruction("clarification_1_3"),

  InstructionWithButtonGroup(
    `clarification_2`,
    [
      {
        text: "네, 떠올랐어요",
        showFromId: "clarification2_Y",
        hideFromId: "clarification3",
      },
      {
        text: "아니요, 괜찮았어요",
        showFromId: "clarification3",
        hideFromId: "clarification2_Y",
      },
    ],
    undefined,
    { blockId: "clarification2" }
  ),
  Instruction("clarification_2_0", { blockId: "clarification2_Y" }),
  Instruction("clarification_2_1"),
  Instruction("clarification_2_2"),

  InstructionWithButtonGroup(
    `clarification_3`,
    [
      {
        text: "네, 안 났어요",
        showFromId: "clarification3_Y",
        hideFromId: "clarification4",
      },
      {
        text: "아니요, 괜찮았어요",
        showFromId: "clarification4",
        hideFromId: "clarification3_Y",
      },
    ],
    undefined,
    { blockId: "clarification3" }
  ),
  Instruction("clarification_3_0", { blockId: "clarification3_Y" }),
  Instruction("clarification_3_1"),
  Instruction("clarification_3_2"),
  Instruction("clarification_3_3"),
  InstructionWithButtonGroup(
    `clarification_4`,
    [
      {
        text: "네, 느껴졌어요",
        showFromId: "clarification4_Y",
        hideFromId: "clarification5",
      },
      {
        text: "아니요, 괜찮았어요",
        showFromId: "clarification5",
        hideFromId: "clarification4_Y",
      },
    ],
    undefined,
    { blockId: "clarification4" }
  ),
  Instruction("clarification_4_0", { blockId: "clarification4_Y" }),
  Instruction("clarification_4_1"),
  Instruction("clarification_4_2"),
  Instruction("clarification_4_3"),
  InstructionWithButtonGroup(
    `clarification_5`,
    [
      {
        text: "네, 아팠어요",
        showFromId: "clarification5_Y",
        hideFromId: "마무리",
      },
      {
        text: "아니요, 괜찮았어요",
        showFromId: "마무리",
        hideFromId: "clarification5_Y",
      },
    ],
    undefined,
    { blockId: "clarification5" }
  ),
  Instruction("clarification_5_0", { blockId: "clarification5_Y" }),
  Instruction("clarification_5_1"),
  Instruction("clarification_5_2"),
  Instruction("clarification_5_3"),
];

export const Content_5_1_A = (isPushNotificationGranted: boolean) => [
  Instruction(`typography_0_0_0`),
  Instruction("typography_1_0_0"),
  Instruction(`typography_2_0_0`),
  Instruction(`typography_3_0_0`),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  Header1(`typography_6_0_0`),
  Instruction(`typography_7_0_0`),
  Instruction(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),
  Instruction(`typography_12_0_0`),
  Instruction(`typography_13_0_0`),
  Instruction(`typography_14_0_0`),
  Header1(`typography_15_0_0`),
  Instruction(`typography_16_0_0`),
  Instruction(`typography_17_0_0`),
  Instruction(`typography_18_0_0`),
  Instruction(`typography_19_0_0`),
  Instruction(`typography_20_0_0`),
  InstructionWithImage(`typography_21_0_0`, "0"),
  InstructionWithImage(`typography_22_0_0`, "1"),
  Instruction(`typography_23_0_0`),
  Instruction(`typography_24_0_0`),
  Instruction(`typography_25_0_0`),
  InstructionWithImage(`typography_26_0_0`, "2"),
  Instruction(`typography_27_0_0`),
  Instruction(`typography_28_0_0`),
  Instruction(`typography_29_0_0`),
  InstructionWithImage(`typography_30_0_0`, "3"),
  Instruction(`typography_31_0_0`),
  Instruction(`typography_32_0_0`),
  Instruction(`typography_33_0_0`),

  Header1(`typography_34_0_0`),
  Instruction(`typography_35_0_0`),
  Instruction(`typography_36_0_0`),
  Instruction(`typography_37_0_0`),
  Instruction(`typography_38_0_0`),
  Instruction(`typography_39_0_0`),
  Instruction(`typography_40_0_0`),
  Instruction(`typography_41_0_0`),
  Instruction(`typography_42_0_0`),
  Instruction(`typography_43_0_0`),
  Instruction(`typography_44_0_0`),

  Header1(`typography_45_0_0`),
  Instruction(`typography_46_0_0`),
  Instruction(`typography_47_0_0`),
  Instruction(`typography_48_0_0`),
  Instruction(`typography_49_0_0`),
  Instruction(`typography_50_0_0`),
  Instruction(`typography_51_0_0`),
  Instruction(`typography_52_0_0`),
  InstructionWithImage(`typography_53_0_0`, "4"),
  InstructionWithImage(`typography_54_0_0`, "5"),
  InstructionWithImage(`typography_55_0_0`, "6"),
  InstructionWithImage(`typography_56_0_0`, "7"),
  Instruction(`typography_57_0_0`),
  Instruction(`typography_58_0_0`),
  Instruction(`typography_59_0_0`),
  Instruction(`typography_60_0_0`),
  Instruction(`typography_61_0_0`),
  Instruction(`typography_62_0_0`),
  Instruction(`typography_63_0_0`),
  Instruction(`typography_64_0_0`),
  Instruction(`typography_65_0_0`),
  Instruction(`typography_66_0_0`),
  Instruction(`typography_67_0_0`),
  Instruction(`typography_68_0_0`),
  Instruction(`typography_69_0_0`),
  Instruction(`typography_70_0_0`),
  Instruction(`typography_71_0_0`),
  InstructionWithImage(`typography_72_0_0`, "8"),
  InstructionWithImage(`typography_73_0_0`, "9"),
  Instruction(`typography_74_0_0`),
  ...NotificationOnceAndInstructionBlocks(
    isPushNotificationGranted,
    "10",
    "stream_of_thoughts_5min",
    true,
    "meditation_available_5min"
  ),
  // Instruction(`typography_76_0_0`, { blockId: "가능" }),
  // Instruction(`typography_77_0_0`),
  // InstructionWithContent("typography_78_0_0", [
  //   Image("10"),
  //   Audio("stream_of_thoughts_5min"),
  // ]),
  // InstructionWithTextarea(`typography_79_0_0`),
  ...ClarificationBlocks,
  Instruction(`typography_80_0_0`, { blockId: "마무리" }),
  Instruction(`typography_81_0_0`),

  ...LastCard,
];
