import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import useFetch from "hooks/useFetch";

export default function PaymentFail() {
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const orderId = searchParams.get("orderId");
  const code = searchParams.get("code");
  const message = searchParams.get("message");

  const [paymentFailData, paymentFail] = useFetch({
    url: `/payment/fail`,
    requestBody: JSON.stringify({
      orderId: orderId,
      code: code,
      message: message,
    }),
  });

  useEffect(() => {
    if (orderId) {
      paymentFail();
    }
  }, [orderId]);

  useEffect(() => {
    if (paymentFailData) {
      navigate("/");
    }
  }, [paymentFailData]);

  return (
    <div>
      <h1>결제 실패</h1>
      <div>{`사유: ${message}`}</div>
    </div>
  );
}
