import {
  AiCommentLine,
  AITypographyLine,
  ButtonGroup,
  CellType,
  CoachTextareaLine,
  DataMappingType,
  Date,
  DividerLine,
  Image,
  OptionType,
  Percentage,
  PercentageType,
  PresetLine,
  PresetType,
  SingleSelectionType,
  TextareaLine,
  TextareaType,
  Typography,
  TypographyFromData,
  TypographyFromDataLine,
  TypographyLine,
} from "./CellComponent";
import { EvaluationType } from "./selfCheckData";
import { v4 as uuidv4 } from "uuid";
import {
  THOUGHTRECORD_CONVERSATION_CONTINUE_ENDPOINT,
  THOUGHTRECORD_DOMAIN,
} from "api/thoughtRecordApi";

export interface StructureType {
  blockId?: string;
  noBorder?: boolean;
  noArrow?: boolean;
  isEnd?: boolean;
  isShown?: boolean;
  isHidden?: boolean;
  nextBlockId?: string;
  indentation?: boolean;
  hideIfPatient?: boolean;
  hideIfCoach?: boolean;
  removable?: boolean;
  disableGoNext?: boolean;
  isHighlight?: boolean;
  isAlwaysHidden?: boolean;
  autoOpenNext?: boolean;
}
export interface ProgramContentType extends StructureType {
  lines: CellType[][];
}

export function Header1(
  translationKey: string,
  arg?: StructureType
): ProgramContentType {
  return {
    lines: [[Typography(translationKey, { level: "h3" })]],
    noBorder: true,
    noArrow: true,
    ...(arg && arg),
  };
}
export function Header1WithData(
  dataMapping: DataMappingType,
  translationKey: string,
  arg?: StructureType
): ProgramContentType {
  return {
    lines: [[TypographyFromData(dataMapping, translationKey, { level: "h3" })]],
    noBorder: true,
    noArrow: true,
    ...(arg && arg),
  };
}

export function DateHeader(
  value: string,
  arg?: StructureType
): ProgramContentType {
  return {
    lines: [[Date(value)]],
    noBorder: true,
    noArrow: true,
    ...(arg && arg),
  };
}

export function Instruction(
  translationKey: string,
  arg?: StructureType
): ProgramContentType {
  return {
    lines: [TypographyLine(translationKey)],
    ...(arg && arg),
  };
}

export function Content(
  content: CellType[][],
  arg?: StructureType
): ProgramContentType {
  return {
    lines: [...content],
    ...(arg && arg),
  };
}

export function InstructionWithContent(
  translationKey: string,
  content: CellType[][],
  arg?: StructureType,
  noDividerLine?: boolean
): ProgramContentType {
  return {
    lines: [
      TypographyLine(translationKey),
      ...(noDividerLine ? [] : [DividerLine()]),
      ...content,
    ],
    ...(arg && arg),
  };
}

export function AIInstruction(
  messageId?: string,
  arg?: StructureType
): ProgramContentType {
  return {
    lines: [AITypographyLine(messageId)],
    ...(arg && arg),
  };
}
export function AIInstructionWithTextarea(
  messageId?: string,
  textareaArg?: TextareaType,
  arg?: StructureType
): ProgramContentType {
  return {
    lines: [
      AITypographyLine(messageId),
      DividerLine(),
      TextareaLine({
        ...(textareaArg && textareaArg),
        queryKey: [
          THOUGHTRECORD_DOMAIN,
          THOUGHTRECORD_CONVERSATION_CONTINUE_ENDPOINT,
        ],
      }),
    ],
    ...(arg && arg),
  };
}

export function InstructionWithImage(
  translationKey: string,
  url: string | string[]
): ProgramContentType {
  return InstructionWithContent(
    translationKey,
    typeof url === "string"
      ? [Image(url)]
      : [...url.map((each) => Image(each))],
    undefined,
    true
  );
}

export function InstructionWithTextarea(
  translationKey: string,
  textareaArg?: TextareaType,
  arg?: StructureType
): ProgramContentType {
  return InstructionWithContent(
    translationKey,
    [TextareaLine({ ...(textareaArg && textareaArg) })],
    arg,
    true
  );
}
export function InstructionWithCoachTextarea(
  translationKey: string,
  textareaArg?: TextareaType,
  arg?: StructureType
): ProgramContentType {
  return InstructionWithContent(
    translationKey,
    [CoachTextareaLine({ ...(textareaArg && textareaArg) })],
    arg,
    true
  );
}
export function InstructionWithButtonGroup(
  translationKey: string,
  options: OptionType[],
  buttonGroupArg?: SingleSelectionType,
  arg?: StructureType
): ProgramContentType {
  return InstructionWithContent(
    translationKey,
    [[ButtonGroup(options, buttonGroupArg)]],

    arg,
    true
  );
}
export function InstructionWithPercentage(
  translationKey: string,
  percentageArg?: PercentageType,
  arg?: StructureType
): ProgramContentType {
  return InstructionWithContent(
    translationKey,
    [[Percentage(percentageArg)]],
    arg,
    true
  );
}

export function InstructionWithPreset(
  translationKey: string,
  presetArg?: PresetType,
  arg?: StructureType
): ProgramContentType {
  return InstructionWithContent(
    translationKey,
    [PresetLine({ ...(presetArg && presetArg) })],
    arg
  );
}

export function InstructionWithData(
  dataMapping: DataMappingType,
  translationKey: string,
  arg?: StructureType
): ProgramContentType {
  return {
    lines: [TypographyFromDataLine(dataMapping, translationKey)],
    ...(arg && arg),
  };
}

export function InstructionWithDataWithContent(
  dataMapping: DataMappingType,
  translationKey: string,
  content: CellType[][],
  arg?: StructureType,
  noDividerLine?: boolean
): ProgramContentType {
  return {
    lines: [
      TypographyFromDataLine(dataMapping, translationKey),
      ...(noDividerLine ? [] : [DividerLine()]),
      ...content,
    ],
    ...(arg && arg),
  };
}
export function InstructionWithTempContent(
  translationKey: string,
  content: CellType[][],
  arg?: StructureType
): ProgramContentType {
  return {
    lines: [TypographyLine(translationKey), ...content],
    ...(arg && arg),
  };
}
export function InstructionWithDataWithTempContent(
  dataMapping: DataMappingType,
  translationKey: string,
  content: CellType[][],
  arg?: StructureType
): ProgramContentType {
  return {
    lines: [TypographyFromDataLine(dataMapping, translationKey), ...content],
    ...(arg && arg),
  };
}

export function InstructionWithDataWithTextarea(
  dataMapping: DataMappingType,
  translationKey: string,
  textareaArg?: TextareaType,
  arg?: StructureType
): ProgramContentType {
  return InstructionWithDataWithContent(
    dataMapping,
    translationKey,
    [TextareaLine({ ...(textareaArg && textareaArg) })],
    arg,
    true
  );
}

export function InstructionWithDataWithPercentage(
  dataMapping: DataMappingType,
  translationKey: string,
  percentageArg?: PercentageType,
  arg?: StructureType
): ProgramContentType {
  return InstructionWithDataWithContent(
    dataMapping,
    translationKey,
    [[Percentage(percentageArg)]],
    arg,
    true
  );
}

export function InstructionWithDataWithButtonGroup(
  dataMapping: DataMappingType,
  translationKey: string,
  options: OptionType[],
  buttonGroupArg?: SingleSelectionType,
  arg?: StructureType
): ProgramContentType {
  return InstructionWithDataWithContent(
    dataMapping,
    translationKey,
    [[ButtonGroup(options, buttonGroupArg)]],
    arg,
    true
  );
}

export function InstructionWithDataWithPreset(
  dataMapping: DataMappingType,
  translationKey: string,
  presetArg?: PresetType,
  arg?: StructureType
): ProgramContentType {
  return InstructionWithDataWithContent(
    dataMapping,
    translationKey,
    [PresetLine({ ...(presetArg && presetArg) })],
    arg
  );
}

export function ThoughtTrap(
  title: string,
  explanation: string,
  example: string
): ProgramContentType {
  return {
    lines: [
      TypographyLine(title, { level: "title-lg" }),
      TypographyLine(explanation),
      TypographyLine(example, { color: "primary.solid" }),
    ],
  };
}

export function CommentWithTextareaInput(
  questionId?: string,
  answerId?: string,
  arg?: StructureType
): ProgramContentType {
  return {
    lines: [
      CoachTextareaLine({
        editorKey: uuidv4(),
        ...(questionId && { id: questionId }),
      }),
      TextareaLine({
        ...(answerId && { id: answerId }),
      }),
    ],
    indentation: true,
    // noArrow: true,

    isShown: true,
    removable: true,
    ...(arg && arg),
  };
}
export function AiCommentWithTextareaInput(
  aiCommentKey: string,
  comment: string,
  arg?: StructureType
): ProgramContentType {
  return {
    lines: [
      AiCommentLine(aiCommentKey, comment, {
        editorKey: uuidv4(),
        value: comment,
      }),
      TextareaLine({ editorKey: uuidv4() }),
    ],
    indentation: true,
    noArrow: true,
    isShown: true,
    removable: true,
    ...(arg && arg),
  };
}

export function OptionalCard(): ProgramContentType {
  return {
    lines: [],
    removable: true,
  };
}

export function SelfCheckCard(
  selfCheckId: string,
  questionTranslationKey: string,
  evaluation: EvaluationType,
  arg?: StructureType
): ProgramContentType {
  return {
    lines: [
      TypographyLine(questionTranslationKey),
      [
        {
          type: "selfCheck",
          content: { evaluation: evaluation, selfCheckId: selfCheckId },
        },
      ],
    ],
    ...(arg && arg),
  };
}
