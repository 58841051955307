import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { tokenAtom } from "recoil/tokenAtom";
import { PATH } from "routes/paths";
import useFetch2 from "./useFetch2";

export default function useLogoutWeb({ url }: { url: string }) {
  const navigate = useNavigate();
  const [fetchResponse, useLogoutWeb] = useFetch2({
    url: url,
  });

  const setTokenState = useSetRecoilState(tokenAtom);

  useEffect(() => {
    if (fetchResponse) {
      console.log("쿠키 삭제됨!");
      // navigate(PATH.thoughtRecordTool_login);
      setTokenState((state) => ({ ...state, accessToken: undefined }));
    }
  }, [fetchResponse]);

  return useLogoutWeb;
}
