export const translation_selfCheck = {
  en: {
    v1: {
      selfcheck_guide: "In the last two weeks, I've:",
      depression_1:
        "Felt a lack of interest or pleasure in work or leisure activities.",
      depression_2: "Felt down, depressed, or hopeless.",
      depression_3:
        "Had trouble falling asleep, staying asleep, or slept too much.",
      depression_4: "Felt tired or had little energy.",
      depression_5: "Had poor appetite or overate.",
      depression_6:
        "Felt bad about myself. Felt like a failure or disappointed myself and my family.",
      depression_7: "Found it hard to concentrate on reading or watching TV.",
      depression_8:
        "Moved or spoken so slowly that others could have noticed. Or the opposite - been so fidgety or restless that I have been moving around a lot more than usual.",
      depression_9:
        "Thought that I would be better off dead or hurting myself in some way.",
      anxiety_1: "Felt nervous, anxious, or on edge.",
      anxiety_2: "Not been able to stop or control worrying.",
      anxiety_3: "Worried too much about different things.",
      anxiety_4: "Had trouble relaxing.",
      anxiety_5: "Been so restless that it is hard to sit still.",
      anxiety_6: "Become easily annoyed or irritable.",
      anxiety_7: "Been afraid that something awful might happen.",
      stress_1: "Felt upset because of unexpected events.",
      stress_2: "Felt I couldn't control important things in my life.",
      stress_3: "Felt nervous or stressed.",
      stress_4:
        "Felt confident about my ability to handle my personal problems.",
      stress_5: "Felt that things were going my way.",
      stress_6: "Felt unable to cope with all the things that I had to do.",
      stress_7: "Felt I could control irritations in my life.",
      stress_8: "Felt proud of what I've accomplished.",
      stress_9: "Felt angry because of things that were outside of my control.",
      stress_10:
        "Felt that difficulties were piling up so high that I could not overcome them.",
      selfcheck_finish:
        "Great. I'll transfer the results to <My Progress> for you. You can check the trends there.",
    },
  },
  ko: {
    v1: {
      selfcheck_guide: "나는 지난 2주 동안",
      depression_1: "일이나 여가 활동에 흥미가 없고 기쁘지 않아요.",
      depression_2: "기분이 가라앉거나, 우울하거나, 희망이 없어요.",
      depression_3: "잠들기 어렵거나, 자주 잠에 깨거나, 잠을 너무 많이 자요.",
      depression_4: "피곤하거나 기운이 없어요.",
      depression_5: "입맛이 없거나 과식을 해요.",
      depression_6:
        "나에게 부정적이에요. 실패자 같고, 나와 내 가족을 실망시켜요.",
      depression_7: "신문이나 TV 등을 볼 때 집중이 어려워요.",
      depression_8:
        "말과 행동이 너무 느려 다른 사람이 알아차릴 수 있어요. 또는 반대로, 너무 안절부절못해 평소보다 훨씬 많이 움직여요.",
      depression_9:
        "내가 죽는 게 낫다고 생각해요. 또는 스스로를 해칠 것 같아요.",
      anxiety_1: "초조하거나, 불안하거나, 조마조마해요.",
      anxiety_2: "걱정을 조절하거나 멈출 수 없어요.",
      anxiety_3: "여러 가지에 대해 너무 많이 걱정해요.",
      anxiety_4: "편하게 있기 어려워요.",
      anxiety_5: "너무 안절부절못해서 가만히 있기 힘들어요.",
      anxiety_6: "쉽게 짜증이 나거나 화가 나요.",
      anxiety_7: "끔찍한 일이 일어날 것처럼 두려워요.",
      stress_1: "예상치 않은 일 때문에 속상해요.",
      stress_2: "중요한 일을 통제할 수 없다고 느껴요.",
      stress_3: "초조하거나 스트레스 받는다고 느껴요.",
      stress_4: "개인적인 문제를 잘 처리할 수 있다는 자신감을 느껴요.",
      stress_5: "일이 뜻대로 진행된다고 느껴요.",
      stress_6: "해야 할 모든 일에 잘 대처할 수 없다고 느껴요.",
      stress_7: "삶에서 짜증을 조절할 수 있어요.",
      stress_8: "일을 잘 해냈다고 느껴요.",
      stress_9: "내 능력 밖의 일로 화가 나요.",
      stress_10: "어려운 일이 너무 많이 쌓여 극복할 수 없다고 느껴요.",

      "self-esteem_1": "나도 다른 사람처럼 가치있는 사람이라 생각해요.",
      "self-esteem_2": "좋은 성품을 지녔다고 생각해요.",
      "self-esteem_3": "대체적으로 실패한 사람이라 생각해요.",
      "self-esteem_4": "다른 사람만큼 일을 잘 할 수 있어요.",
      "self-esteem_5": "자랑할 것이 별로 없어요.",
      "self-esteem_6": "내게 긍정적인 태도를 가지고 있어요.",
      "self-esteem_7": "나에게 대체적으로 만족해요.",
      "self-esteem_8": "지금보다 나를 좀 더 존중할 수 있다면 좋겠어요.",
      "self-esteem_9": "가끔 내가 쓸모없는 사람이라 생각해요.",
      "self-esteem_10": "때때로 내가 좋지 않은 사람이라는 생각이 들어요.",

      burnout_1: "직장에서 정신적으로 지쳐요.",
      burnout_2: "퇴근 후 기운을 회복하기 어려워요.",
      burnout_3: "직장에서 체력적으로 지쳐요.",
      burnout_4: "일에 대한 열정을 찾으려 고군분투해요.",
      burnout_5: "내 업무가 정말 싫어요.",
      burnout_6: "내 업무가 다른 사람에게 의미가 있을지 냉소적이에요.",
      burnout_7: "직장에서 한 가지 일에 집중하기 힘들어요.",
      burnout_8: "일할 때 집중하기 힘들어요.",
      burnout_9: "직장에서 다른 일에 신경 쓰다가 실수하곤 해요.",
      burnout_10: "직장에서 내 감정을 다스릴 수 없어요.",
      burnout_11: "직장에서 나의 감정적인 반응을 인지하지 못해요.",
      burnout_12: "직장에서 뜻하지 않게 과하게 반응해요.",

      ADHD_1: "일의 어려운 부분을 끝내고도 마무리 짓지 못해 곤란해요.",
      ADHD_2: "체계가 필요한 일을 순서대로 진행하기 어려워요.",
      ADHD_3: "약속이나 할 일을 잊어 곤란해요.",
      ADHD_4: "골치 아픈 일을 피하거나 미뤄요.",
      ADHD_5: "오래 앉아 있을 때, 손을 만지작거리거나 발을 꼼지락거려요.",
      ADHD_6: "모터가 달린 것처럼 과하게 활동적이거나 멈출 수 없어요.",

      insomnia_1: "누웠는데도 잠들기 어려워요.",
      insomnia_2: "잠자다 자꾸 깨서 어려워요.",
      insomnia_3: "아침에 너무 일찍 깨어 다시 잠들기 어려워요.",
      insomnia_4: "현재의 수면 패턴에 만족해요.",
      insomnia_5:
        "불면증이 일상을 방해하고 있어요. (주간 피로, 업무와 가사 능력, 집중력, 기억력, 기분 등)",
      insomnia_6:
        "내 삶이 불면증으로 손상되었다는 것을 다른 사람이 알아차릴 수 있어요.",
      insomnia_7: "현재 불면증을 걱정하고 있어요.",

      "job-satisfaction_1": "현재 내가 수행하고 있는 업무에 흥미를 느껴요.",
      "job-satisfaction_2":
        "현재 내가 수행하고 있는 업무는 내 능력에 적당해요.",
      "job-satisfaction_3": "나의 현 직무를 다른 사람들이 중요하게 여겨요.",
      "job-satisfaction_4": "내가 수행하고 있는 업무는 나의 적성에 맞아요.",
      "job-satisfaction_5":
        "도움이 필요할 때 동료들의 지원을 기대할 수 있어요.",
      "job-satisfaction_6": "나는 동료와의 관계가 편안해요.",
      "job-satisfaction_7": "동료들은 나를 신뢰해요.",
      "job-satisfaction_8": "일을 통해 내가 성장하고 있다는 느낌을 받아요.",
      "job-satisfaction_9": "집에서 쉬는 동안에도 업무를 걱정해요.",
      "job-satisfaction_10":
        "나의 목표와 비전을 생각할 시간을 거의 갖지 못해요.",

      selfcheck_finish:
        "좋아요. 제가 검사 결과를 <진행 상황>에 옮겨둘게요. 그곳에서 추이를 확인해보도록 해요.",
    },
  },
};
