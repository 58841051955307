import { Typography } from "@mui/joy";

import { sendMessage } from "utils/webview";
import useIsInappWebview from "hooks/useIsInappWebview";
import React from "react";
import { SxProps } from "@mui/joy/styles/types";

export default function Link({
  text,
  url,
  level,
  opacity,
  textColor,
  bold,
  startDecorator,
  endDecorator,
  noUnderline,
  onClick,
  disabled,
  customSx,
  linkOpenType = "modal",
}: {
  text: string;
  url?: string;
  level?: "body-sm";
  opacity?: number;
  textColor?: string;
  bold?: boolean;
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  noUnderline?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  customSx?: SxProps;
  linkOpenType?: "modal" | "link";
}) {
  const isInappWebview = useIsInappWebview();

  return (
    <Typography
      textColor={textColor}
      level={level || "body-xs"}
      textAlign={"center"}
      sx={{
        opacity: opacity || 0.5,
        ...(!noUnderline && { textDecoration: undefined }),

        ...(!disabled && { cursor: "pointer" }),
        fontWeight: bold ? 500 : 400,
        ...customSx,
      }}
      startDecorator={startDecorator}
      endDecorator={endDecorator}
      onClick={() => {
        if (url) {
          if (isInappWebview) {
            sendMessage(
              JSON.stringify({
                event: linkOpenType === "modal" ? "open_modal" : "open_link",
                params: {
                  url: url,
                },
              })
            );
          } else {
            window.open(url);
          }
        }
        if (onClick && !disabled) {
          onClick();
        }
      }}
    >
      {text}
    </Typography>
  );
}
