import { Button } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { layoutAtom } from "recoil/layoutAtom";

export default function SidebarOpenButton({
  size,
  disabled,
}: {
  size?: "lg";
  disabled?: boolean;
}) {
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  const [layoutState, setLayoutState] = useRecoilState(layoutAtom);
  return !layoutState.isLeftSideBarOpen ? (
    <Button
      onClick={() => {
        setLayoutState((state) => ({
          ...state,
          isLeftSideBarOpen: true,
        }));
      }}
      size={size}
      disabled={disabled}
    >
      {t_ui("사이드바 열기")}
    </Button>
  ) : (
    <></>
  );
}
