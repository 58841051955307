import { Percentage } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithTextarea,
  InstructionWithImage,
  InstructionWithTextarea,
} from "../../BlockComponent";

import { LastCard } from "../common";

export const Content_4_3_2_A = [
  Instruction(`typography_0_0_0`),
  Instruction(`typography_1_0_0`),
  InstructionWithImage(`typography_2_0_0`, "0"),
  InstructionWithImage(`typography_3_0_0`, "1"),
  InstructionWithImage(`typography_4_0_0`, "2"),
  Instruction(`typography_5_0_0`),

  Header1(`typography_6_0_0`),
  Instruction(`typography_7_0_0`),
  InstructionWithTextarea(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),
  InstructionWithTextarea(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),
  Instruction(`typography_12_0_0`),
  Instruction(`typography_13_0_0`),
  Instruction(`typography_14_0_0`),
  InstructionWithImage(`typography_15_0_0`, "3"),

  Header1(`typography_16_0_0`),
  Instruction(`typography_17_0_0`),
  InstructionWithImage(`typography_18_0_0`, "4"),
  Instruction(`typography_19_0_0`),
  InstructionWithTextarea(`typography_20_0_0`),
  InstructionWithTextarea(`typography_21_0_0`),
  InstructionWithTextarea(`typography_22_0_0`),
  Instruction(`typography_23_0_0`),
  Instruction(`typography_24_0_0`),
  Instruction(`typography_25_0_0`),
  Instruction(`typography_26_0_0`),
  Instruction(`typography_27_0_0`),

  Header1(`typography_28_0_0`),
  Instruction(`typography_29_0_0`),
  Instruction(`typography_30_0_0`),

  Instruction(`typography_31_0_0`),
  Instruction(`typography_32_0_0`),
  Instruction(`typography_33_0_0`),
  Instruction(`typography_34_0_0`),
  Instruction(`typography_35_0_0`),
  InstructionWithTextarea(`typography_36_0_0`),
  Instruction(`typography_37_0_0`),
  Instruction(`typography_38_0_0`),
  Instruction(`typography_39_0_0`),
  Instruction(`typography_40_0_0`),

  Header1(`typography_41_0_0`),
  InstructionWithTextarea(`typography_42_0_0`),
  Instruction(`typography_43_0_0`),
  Instruction(`typography_44_0_0`),
  Instruction(`typography_45_0_0`),
  Instruction(`typography_46_0_0`),
  Instruction(`typography_47_0_0`),
  Instruction(`typography_48_0_0`),
  Instruction(`typography_49_0_0`),
  InstructionWithTextarea(`typography_50_0_0`, { id: "단어" }),
  InstructionWithContent("typography_51_0_0", [
    [Percentage({ id: "영향받는 정도_temp" })],
  ]),
  Instruction(`typography_52_0_0`),
  Instruction(`typography_53_0_0`),
  InstructionWithData({ A: { id: "단어" } }, `typographyFromData_54_0_0`),
  Instruction(`typography_55_0_0`),
  Instruction(`typography_56_0_0`),
  InstructionWithDataWithTextarea(
    { A: { id: "단어" } },
    `typographyFromData_57_0_0`
  ),
  InstructionWithContent("typography_58_0_0", [
    [Percentage({ id: "영향받는 정도_temp" })],
  ]),
  Instruction(`typography_59_0_0`),

  Header1(`typography_60_0_0`),
  Instruction(`typography_61_0_0`),
  Instruction(`typography_62_0_0`),
  Instruction(`typography_63_0_0`),
  Instruction(`typography_64_0_0`),
  Instruction(`typography_65_0_0`),
  Instruction(`typography_66_0_0`),

  Instruction(`typography_67_0_0`),

  Instruction(`typography_68_0_0`),
  Instruction(`typography_69_0_0`),
  Instruction(`typography_70_0_0`),

  ...LastCard,
];
