export const translation_1_1 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "우리 마음에 관해 가장 중요한 비밀을 하나 알려드리며 본격적으로 프로그램을 시작해보려 합니다.",
      typography_1_0_0: "생각하는 '나'는 착각입니다.",
      typography_2_0_0: `"지금 이게 무슨 뚱딴지 같은 소리야?"`,
      typography_3_0_0:
        "아마 이 이야기를 듣는 여러분들은 이렇게 반응하고 계실 겁니다.",
      typography_4_0_0:
        "지금 내가 스스로 생각을 하며 이 프로그램을 진행하고 있는데 말이죠.",
      typography_6_0_0:
        "또 원한다면 나는 내 의지대로 프로그램을 그만 진행할 수도 있는데 말입니다.",
      typography_7_0_0:
        "이는 어떠한 모순도 없는 명백한 사실인 것처럼 느껴집니다.",
      typography_8_0_0: `"생각하는 나" = 괴로움의 시작`,
      typography_9_0_0:
        "하지만 이것이 우리가 괴로워지는 가장 첫 번째 수순이라는 걸 명심하셔야 합니다.",
      typography_10_0_0:
        "우리가 능동적으로 생각하고 있다는 믿음은 필연적으로 심리적인 괴로움을 조장합니다.",
      typography_11_0_0: '"나는 왜 더 좋게 생각할 수 없지?"',
      typography_12_0_0: '"나는 왜 이런 부정적인 생각만 하는 거지?"',
      typography_13_0_0: '"더 긍적적인 생각을 하고 싶은데, 어떻게 해야하지?"',
      typography_14_0_0:
        "스스로가 능동적으로 생각하는 존재라고 믿기에, 우리는 위의 질문을 스스로에게 끊임없이 던지곤 하죠.",
      typography_15_0_0:
        "생각을 원하는 방향대로 하지 못한 자신에게 화살을 돌리고 괴로워하면서요.",
      typography_16_0_0:
        "생각은 내가 하는 거니까요. 그러한 생각을 하거나 하지 못한 내 탓이지요.",
      typography_17_0_0: "하지만 이는 사실이 아닙니다.",
      typography_18_0_0: "놀랍게도, 생각은 내가 하는 것이 아닙니다.",
      typography_19_0_0: "새로운 사실 받아들이기",
      typography_20_0_0:
        '프로그램을 진행하며 디스턴싱을 훈련하기에 앞서, 우리는 "생각하는 나"가 착각이라는 사실부터 받아들여야 합니다.',
      typography_21_0_0:
        "생각은 내가 하는 것이 아니라, 그저 마음속에 나타날 뿐입니다.",
      typography_22_0_0: "이 개념은 아주 중요합니다.",
      typography_23_0_0:
        "당장은 여러분들이 이 개념을 받아들이기 어렵다는 것을 잘 알고 있습니다.",
      typography_24_0_0:
        "그래도 괜찮습니다. 하나씩 알아보고 경험해 나가다보면 익숙해질 거라서요.",
      typography_25_0_0:
        "다음 활동에서는 정말 우리가 능동적으로 생각하는 존재인지 알아보는 시간을 가져볼게요.",
    },
  },
};
