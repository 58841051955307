import { InstructionWithContent, ProgramContentType } from "../../BlockComponent";
import { reflectGoalTemplate } from "./0-5-A";
import { LastCard, MyGoalLine } from "../common";

export const Content_0_6_A: ProgramContentType[] = [
  InstructionWithContent("typography_0_0_0", MyGoalLine()),
  ...reflectGoalTemplate,

  ...LastCard,
];
