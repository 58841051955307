import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { memo } from "react";
import { useEffect } from "react";
import { SvgIcon } from "@mui/joy";
import { PatientType } from "api/patientApi";
import { patientAtom } from "recoil/patientAtom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { usePrevious } from "@uidotdev/usehooks";
import * as _ from "lodash";
import { layoutAtom } from "recoil/layoutAtom";
import PatientItem from "./PatientItem";
import {
  alwaysOpenPatientListMenyKey,
  menuSelector,
  patientListMenuKey,
} from "recoil/menuAtom";
import { SidebarMenuType } from "components/sidebar/SideBar";

function PatientSubList({
  menuKey,
  title,
  patientList,
  selected,
  setSelected,
  setCurrentMenu,
}: {
  menuKey: patientListMenuKey;
  title: string;
  patientList: PatientType[];
  selected?: string;
  setSelected: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCurrentMenu?: React.Dispatch<React.SetStateAction<SidebarMenuType>>;
}) {
  const [patientState, setPatientState] = useRecoilState(patientAtom);
  const previousPatientState = usePrevious(patientState);
  const setLayoutState = useSetRecoilState(layoutAtom);

  const [isOpen, setIsOpen] = useRecoilState(menuSelector(menuKey));

  useEffect(() => {
    if (alwaysOpenPatientListMenyKey.includes(menuKey)) {
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    if (patientState?.patientId !== previousPatientState?.patientId) {
      setLayoutState((state) => ({ ...state, isRightSideBarOpen: true }));
    }
  }, [patientState]);

  return (
    <ListItem nested sx={{ py: "14px" }}>
      <ListItemButton
        onClick={() => {
          setIsOpen((isOpen) => !isOpen);
        }}
      >
        <ListItemContent>
          <Typography level={"body-xs"}>
            {title}
            <Typography level={"body-xs"} sx={{ opacity: 0.5, pl: 1 }}>
              {patientList.length}
            </Typography>
          </Typography>
        </ListItemContent>
        <SvgIcon sx={{ color: "primary.solid" }}>
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </SvgIcon>
      </ListItemButton>
      {isOpen && (
        <List
          sx={{
            "--List-gap": "0px",
          }}
        >
          {patientList?.map((patient) => (
            <PatientItem
              key={`${title}_${patient.patientId}`}
              title={title}
              patient={patient}
              selected={selected}
              setSelected={setSelected}
              setCurrentMenu={setCurrentMenu}
            />
          ))}
        </List>
      )}
    </ListItem>
  );
}

export default memo(PatientSubList);
