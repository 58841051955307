export const translation_4_4_6_A = {
  en: {
    v1: {
      typography_0_0_0:
        "Sometimes, our minds take these purposeless trips, either revisiting the past or fretting about the future.",
      typography_1_0_0:
        "Think about when you’re driving or on public transport. What’s on your mind?",
      typography_2_0_0:
        "We often get stuck in thoughts about what’s already happened or what might happen.",
      typography_3_0_0:
        "It's okay to think about the past or future sometimes.",
      typography_4_0_0:
        "But the problem is when we get stuck in these thoughts and they start bringing us down.",
      typography_5_0_0: "It's like always having a sour lemon in your mind.",
      typography_6_0_0:
        "Dwelling in the past or future, obsessing over things like past events, missed chances, or our flaws, is what we call 'Rumination'.",
      typography_7_0_0:
        "It's like cows chewing their cud, going over and over the same old thoughts.",
      typography_8_0_0: "Alright, let's take a closer look at Rumination.",
      typography_9_0_0: "Understanding Rumination",
      typography_10_0_0: "Rumination is a 'Unhelpful Thought' trap.",
      typography_11_0_0:
        "It makes us obsess over past mistakes, lost opportunities, personal downsides, with thoughts like:",
      typography_12_0_0: '"If only I hadn’t done that back then..."',
      typography_13_0_0:
        '"If only I were better at this, life would be so different now."',
      typography_14_0_0: "Or worrying about a bleak future.",
      typography_15_0_0:
        '"What if this bad thing happens? How can I prevent it?"',
      typography_16_0_0: '"This could go terribly wrong..."',
      typography_17_0_0:
        "Rumination traps us in the world inside our head, stuck in either the past or future.",
      typography_18_0_0:
        "If it gets too intense, we can end up feeling like prisoners in our own mind, trapped in a cycle of sadness and anxiety.",
      typography_19_0_0:
        '"I feel like I’m a prisoner in the jail of my own mind."',
      typography_20_0_0:
        "In that mental jail, there’s no end to these thoughts. We just go round and round without any real conclusion,",
      typography_21_0_0:
        "only feeling worse each time. It's like that lemon in our mind making our thoughts sour over and over.",
      typography_22_0_0: "But why isn’t Rumination helpful?",
      typography_23_0_0: "The downside of Rumination",
      typography_24_0_0: "At first glance, Rumination might seem smart.",
      typography_25_0_0:
        "It looks like we're reflecting on the past or preparing for the future.",
      typography_26_0_0:
        "It’s natural to analyze and prepare for negative thoughts and feelings.",
      typography_27_0_0:
        "But studies show that while Rumination seems like problem-solving, it really doesn’t bring any benefits.",
      typography_28_0_0:
        "Think about your day. When you’re resting, driving, moving around, before sleep, or eating,",
      typography_29_0_0:
        "how often do you find your thoughts drifting to the past or the future? Can you put a percentage on it?",
      typography_30_0_0:
        "How often do these thoughts actually bring you some valuable insight?",
      typography_31_0_0:
        "You might realize that even though many thoughts are about the past or future, they rarely lead to meaningful insights.",
      typography_32_0_0:
        "Research suggests that people feeling down or anxious believe that Rumination helps them understand their struggles.",
      typography_33_0_0:
        "They think they're uncovering the root of their unhappiness.",
      typography_34_0_0: `"I think I know why I'm struggling at work."`,
      typography_35_0_0: `"My depression is because my mom didn't show me enough love when I was little. Probably because I grew up with my grandma."`,
      typography_36_0_0:
        "But these thoughts mostly just focus on life’s negatives without any real insights.",
      typography_37_0_0: "The reason is simple.",
      typography_38_0_0:
        "The issues are either in the past and already happened, or in the future and haven't happened yet. We can't solve them right away.",
      typography_39_0_0:
        "So, Rumination looks like it’s solving problems, but it’s a misguided attempt to solve unsolvable issues.",
      typography_40_0_0: "A fun example is here.",
      typography_41_0_0:
        "Imagine you parked your car downtown and found a flat tire.",
      typography_42_0_0: "What would you do?",
      typography_43_0_0:
        "Most likely, you’d fix the tire or check what caused the puncture.",
      typography_44_0_0:
        "Now, think about something you really enjoy doing. Write it down. Anything you like. (For example, going to a concert, having dinner with someone special)",
      typography_45_0_0: "What if you were downtown to do that fun thing,",
      typography_46_0_0:
        "and it starts in 10 minutes, but you find your tire flat? What now? Would you still be fixing the tire?",
      typography_47_0_0:
        "Does it make sense to miss out on something important just to figure out why the tire went flat?",
      typography_48_0_0:
        "Rumination is like that. It seems like solving a problem, but it doesn’t help with what really matters.",
      typography_49_0_0:
        "And the real issue is that the thoughts we ruminate on are usually much more negative and troubling than just a flat tire.",
      typography_50_0_0: "Rumination leads to self-criticism",
      typography_51_0_0:
        "or believing in negative stories about ourselves, as if they define who we are.",
      typography_52_0_0:
        "In the end, we spend so much time and energy thinking about the past or worrying about the future",
      typography_53_0_0:
        "that we lose the energy to live in the present. And we find ourselves stuck in the swamp of sadness and worry.",
      typography_54_0_0: "Distancing from Rumination",
      typography_55_0_0:
        "Getting out of this Rumination trap is simpler than you think.",
      typography_56_0_0:
        "Before you get too deep into these popcorn-like thoughts,",
      typography_57_0_0: "notice that they've popped into your mind,",
      typography_58_0_0: "and take a step back to look at them objectively.",
      typography_59_0_0:
        "Check if you're just going over vague thoughts for too long.",
      typography_60_0_0:
        "Ask yourself if this way of thinking is really helping you.",
      typography_61_0_0: "If it's not, it's time to step out of Rumination.",
      typography_62_0_0:
        "Remember, these thoughts are just events in your mind, not who you are.",
      typography_63_0_0:
        "Once you step back from Rumination, you can make choices that align with what you truly value.",
      typography_64_0_0:
        "So, how about we find those Rumination patterns in our minds and practice stepping back from them?",
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "우리의 마음은 종종 목적 없는 여행을 떠날 때가 있어요. 과거나 미래로 말이죠.",
      typography_1_0_0:
        "운전을 하거나 대중교통 탈 때를 떠올려보세요. 어떤 생각을 하고 계셨나요?",
      typography_2_0_0:
        "우리의 생각은 대부분 과거 또는 미래에 머무르고 있답니다.",
      typography_3_0_0:
        "그것 자체가 나쁜 건 아니에요. 과거를 돌아봐야 하기도 하고, 미래를 생각하고 대비해야 하기도 하니까요.",
      typography_4_0_0:
        "하지만 문제는 종종 우리가 매우 비효율적인 방식으로 생각을 곱씹으며 부정적인 감정을 만들어낸다는 거예요.",
      typography_5_0_0:
        "마치 레몬을 보면 침샘에 침이 고이는 것처럼요. 늘 마음속에 레몬을 들고 지내는 거죠.",
      typography_6_0_0:
        "이처럼 과거나 미래에 머무르며 특정한 일, 기억, 기회, 단점, 의미 등을 곱씹는 걸 '반추'라고 해요.",
      typography_7_0_0:
        "마치 소가 음식물을 되새김질하듯 생각을 계속해서 곱씹는 것이죠.",
      typography_8_0_0: "자, 그러면 반추에 대해서 하나씩 살펴볼게요.",
      typography_9_0_0: "반추 이해하기",
      typography_10_0_0:
        "반추는 '도움이 되지 않는 생각'에 해당하는 생각함정이에요.",
      typography_11_0_0:
        "반추를 할 때에는 과거의 잘못이나 놓쳐버린 기회, 개인적인 단점 등을 계속해서 곱씹게 돼요.",
      typography_12_0_0: '"그때 내가 그러지 않았더라면..."',
      typography_13_0_0:
        '"나의 이 부분만 개선되면 참 좋을 텐데. 그랬더라면 지금쯤..."',
      typography_14_0_0:
        "또는 좋게 예상되지 않는 미래를 피하는 방법을 숙고하기도 하죠.",
      typography_15_0_0:
        '"근데 이런 일이 벌어지면 어떻게 해야 하지? 그런 일이 벌어지지 않으려면 뭘 해야 하지...?"',
      typography_16_0_0: '"이럴 수도 있겠네... 그러면 끔찍할 거야..."',
      typography_17_0_0:
        "이러한 반추는 스스로를 '내 머릿속에서 구축된 세상', 특히 '미래나 과거에 머물러 있는 세상'에서 살도록 만들어요.",
      typography_18_0_0:
        "그 과정이 심해져 우울증이 찾아오면 고통스러운 감옥에 수감된 것처럼 느끼기도 하죠.",
      typography_19_0_0:
        '"나는 정말이지 머릿속이라는 감옥 속에 갇혀버린 죄수 같아요."',
      typography_20_0_0:
        "그러한 감옥 속에서는 끝맺음 없이 결론에 이르지 못한 채 그저 똑같은 생각만 반복하게 될 뿐이에요.",
      typography_21_0_0:
        "그 과정에서 우리는 반복해서 부정적인 감정을 느끼죠. 침샘에서 침이 흘러나오도록 하는 레몬처럼요.",
      typography_22_0_0: "그렇다면 반추는 왜 효과적이지 않은 걸까요?",
      typography_23_0_0: "효과적이지 않은 반추",
      typography_24_0_0: "반추는 얼핏 보기에는 효과적인 전략인 것처럼 보여요.",
      typography_25_0_0:
        "과거를 되돌아보고 반성하는 것처럼 보이기도 하고, 미래를 계획하며 대비하는 것처럼 보이기도 하니까요.",
      typography_26_0_0:
        "물론 부정적인 생각과 감정을 분석하고 그에 대해 대비하기 위해 노력하는 건 자연스러운 일이에요. 잘못이 아니죠.",
      typography_27_0_0:
        "하지만 많은 연구들은 반추가 겉보기엔 문제해결과 유사한 모습을 보이지만, 실상은 그로부터 어떠한 이점도 얻지 못한다는 걸 밝혀냈어요.",
      typography_28_0_0:
        "간단히 상상해볼까요? 당신의 하루를 돌아보세요. 휴식을 취할 때, 운전할 때, 이동할 때, 자기 전, 밥을 먹을 때 다양한 순간들을 떠올려보세요.",
      typography_29_0_0:
        "그 시간 중에서 당신이 과거나 미래의 일들에 대해 생각하는 정도는 몇 % 정도 되는 것 같나요?",
      typography_30_0_0:
        "그렇다면 그렇게 습관처럼 갖는 시간 동안 귀중한 깨달음을 얻은 적이 얼마나 자주 있었나요?",
      typography_31_0_0:
        "아마 생각이 과거나 미래에 머무르고 있는 비율은 매우 높지만, 그 시간 중 귀중한 깨달음을 얻은 적은 별로 없다는 걸 알 수 있었을 거예요.",
      typography_32_0_0:
        "연구에 따르면 우울함이나 불안감을 겪는 사람들은 반추를 통해 자기 투쟁의 본질에 대해 귀중한 통찰을 얻는다고 믿는다고 해요.",
      typography_33_0_0: "우울의 원인을 찾을 수 있을 것만 같은 착각이 들죠.",
      typography_34_0_0: '"직장에서 내가 왜 이 모양인지 이제 알 것 같아."',
      typography_35_0_0:
        '"내가 지금 이렇게 우울한 건 어렸을 때 엄마가 나를 사랑해주지 않았기 때문이지. 아마 할머니와 자라서 그럴 거야."',
      typography_36_0_0:
        "하지만 이러한 생각들은 대부분 별다른 깨달음이 없이 인생의 부정적인 부분에만 초점을 맞추도록 해요.",
      typography_37_0_0: "깨달음이 없는 이유는 간단하답니다.",
      typography_38_0_0:
        "문제는 과거에 존재하여 이미 일어났거나, 또는 미래에 존재하여 아직 일어나지 않았기 때문에 당장 해결할 수 없기 때문이에요.",
      typography_39_0_0:
        "따라서 반추는 문제 해결의 외양을 띠고 있지만 해결할 수 없는 문제를 해결하려는 잘못된 시도인 것이죠.",
      typography_40_0_0: "재밌는 예를 들어볼까요?",
      typography_41_0_0:
        "자, 다음과 같은 상황을 상상해보세요. 당신은 차를 몰다가 시내에 주차를 했습니다. 그런데 타이어에 펑크가 났네요.",
      typography_42_0_0: "이 상황에서 어떻게 하겠나요?",
      typography_43_0_0:
        "좋아요. 아마 대부분 타이어를 수리하려고 했을 거예요. 혹은 어떻게 하다가 타이어가 펑크가 났는지 알기 위해 블랙박스를 돌려볼 수도 있겠죠.",
      typography_44_0_0:
        "이제 당신이 아주 좋아하는 활동을 아래에 하나 적어보시길 바랍니다. 무엇이든 괜찮습니다. (예: 사랑하는 딸의 연주회 가기, 연인과 근사한 식당에서 저녁 먹기 등)",
      typography_45_0_0:
        "이번엔 만약 당신이 위에 적은 활동을 하기 위해 시내에 도착해 주차를 한 것이라고 생각해볼까요?",
      typography_46_0_0:
        "위 활동은 정해진 시간에 시작하기로 되어 있어서 10분 뒤면 시작합니다. 그런데 타이어에 펑크가 난 걸 발견한 거죠. 이제 당신은 어떻게 하겠나요? 여전히 차를 살펴보며 타이어를 수리할 건가요?",
      typography_47_0_0:
        "당신에게 중요한 활동을 미루면서까지 왜 타이어 바람이 빠졌는지 고민하는 것이 마땅한가요?",
      typography_48_0_0:
        "반추는 위와 같이 흘러갑니다. 마치 문제 해결처럼 보이지만 정작 중요한 것들에 대해서는 아무런 도움을 주지 못하죠.",
      typography_49_0_0:
        "문제는 '타이어에 바람 빠진 이유 찾기'보다 우리가 곱씹고 있는 게 훨씬 더 부정적이고 괴로운 생각들이라는 것이에요.",
      typography_50_0_0:
        "반추에 빠지면 자기비판과 같은 부정적인 평가를 하게 돼요.",
      typography_51_0_0:
        "혹은 만들어낸 일련의 부정적인 이야기를 굳게 믿게 되죠. 그것이 마치 자신의 정체성인 것처럼요.",
      typography_52_0_0:
        "결국 너무 많은 시간과 에너지를 과거에 대해 생각하고 미래에 사로잡혀 있는 데에 사용하게 되어",
      typography_53_0_0:
        "지금 이 순간에 활동할 에너지까지 뺏겨 버리게 되고, 우리는 꼼짝 없이 우울과 불안의 늪에 빠져버립니다.",
      typography_54_0_0: "반추로부터 거리두기",
      typography_55_0_0: "반추 생각함정을 벗어나는 방법은 생각보다 간단해요.",
      typography_56_0_0:
        "팝콘처럼 마음속에 떠오른 생각에 강하게 매몰되기 전에,",
      typography_57_0_0: "그러한 생각이 마음속에 떠올랐음을 인지하고,",
      typography_58_0_0:
        "한 걸음 떨어져서 그 생각이 어떠한 생각인지 객관적으로 살펴보는 것이죠.",
      typography_59_0_0:
        "일정 시간 이상 어렴풋한 생각들을 그저 곱씹고만 있는 것은 아닌지 파악하는 거죠.",
      typography_60_0_0:
        "그리고 그 방식의 생각이 정말 나에게 도움이 되는지 살펴보는 거예요.",
      typography_61_0_0:
        "만약 그것이 나에게 그다지 도움이 되지 않는다면 반추에서 빨리 빠져나와야 해요.",
      typography_62_0_0:
        "그건 그저 마음속에 떠오른 하나의 심리적 사건일 뿐, 그 생각 자체가 나 자신은 아니라는 걸 인지하면서요.",
      typography_63_0_0:
        "반추로부터 빠져나온 후에는 다시 나의 가치에 맞는 방향으로 나아가는 선택을 하면 된답니다.",
      typography_64_0_0:
        "자, 그러면 내 마음속에 떠오른 반추를 찾고, 그로부터 거리두기를 연습해 볼까요?",
    },
    v2: {
      typography_0:
        "우리는 종종 멍해지거나 지루해져서 현재 순간으로부터 벗어나고 ‘머릿속’ 어딘가로 달아나곤 해요.",
      typography_1: "사실 이런 습관은 마음의 본능과도 같답니다.",
      typography_2:
        "운전을 하거나 대중교통 탈 때를 떠올려보세요. 어떤 생각을 하고 계셨나요?",
      typography_3:
        "우리의 생각은 대부분 머릿속 어딘가를 정신없이 헤매고 있어요.",
      typography_4:
        "그것 자체가 나쁜 건 아니에요. 생각을 해야 교훈을 얻고, 과거를 분석하고, 미래를 대비하니까요.",
      typography_5:
        "하지만 문제는 종종 우리가 매우 비효율적인 방식으로 생각을 곱씹으며 부정적인 감정을 만들어낸다는 거예요.",
      typography_6:
        "마치 레몬을 보면 침샘에 침이 고이는 것처럼요. 늘 마음속에 레몬을 들고 지내는 거죠.",
      typography_7:
        "이처럼 특정한 일, 기억, 기회, 단점, 의미 등을 곱씹는 걸 '반추'라고 해요.",
      typography_8:
        "마치 소가 음식물을 되새김질하듯 생각을 계속해서 곱씹는 것이죠.",
      typography_9: "자, 그러면 반추에 대해서 하나씩 살펴볼게요.",

      typography_10: "반추 이해하기",
      typography_11:
        "반추의 내용은 다양해요. 과거의 잘못이나 놓쳐버린 기회, 개인적인 단점을 곱씹기도 하죠.",
      typography_12: "“그때 내가 그러지 않았더라면… 지금 상황이 바뀌었을까?”",
      typography_13:
        "“나의 이 부분만 개선되면 참 좋을 텐데… 그랬더라면 지금쯤…”",
      typography_14:
        "좋게 예상되지 않는 미래를 피하는 방법에 대해 생각하기도 합니다.",
      typography_15: "“이런 일이 벌어지면 어떻게 해야 하지?”",
      typography_16: "“그런 일이 벌어지지 않도록 하려면 무엇을 해야 하는 거지?",
      typography_17: "“이런 일이 벌어질 거야. 그러면 어쩌지? 끔찍할 것 같아.”",
      typography_18: `때론 어떤 대상이나 사건의 의미를 곱씹기도 합니다.`,
      typography_19: `“나는 왜 이런 거지?”`,
      typography_20: `“이런 일이 왜 벌어진 거지?”`,
      typography_21: `“다른 사람들은 행복한 것 같은데… 왜 나만 이런 거지?”`,
      typography_22:
        "이러한 반추는 스스로를 '내 머릿속에서 구축된 세상'에서 살도록 만들어요.",
      typography_23:
        "그 과정이 심해져 우울증이 찾아오면 고통스러운 감옥에 수감된 것처럼 느끼기도 하죠.",
      typography_24:
        '"나는 정말이지 머릿속이라는 감옥 속에 갇혀버린 죄수 같아요."',
      typography_25:
        "그러한 감옥 속에서는 끝맺음 없이 결론에 이르지 못한 채 그저 똑같은 생각만 반복하게 될 뿐이에요.",
      typography_26:
        "그 과정에서 우리는 반복해서 부정적인 감정을 느끼죠. 침샘에서 침이 흘러나오도록 하는 레몬처럼요.",

      typography_27: "효과적이지 않은 반추",
      typography_28: "반추는 얼핏 보기에는 효과적인 전략인 것처럼 보여요.",
      typography_29:
        "과거를 되돌아보고 반성하는 것처럼 보이기도 하고, 미래를 계획하며 대비하는 것처럼 보이기도 하니까요.",
      typography_30:
        "물론 부정적인 생각과 감정을 분석하고 그에 대해 대비하기 위해 노력하는 건 자연스러운 일이에요. 잘못이 아니죠.",
      typography_31: `문제는 반추에서는 생각의 방향이 종종 해결할 수 없는 문제를 해결하는 데에 초점이 맞춰져있다는 것이에요.`,
      typography_32: `사람들은 살아가며 종종 많은 심리적인 고통을 마주하기도 한답니다.`,
      typography_33: `우울이나 불안한 생각이 마음속에 떠오르는 것 자체는 피할 수가 없어요.`,
      typography_34: `우울이나 불안의 원인이나 의미는 불투명할 때도 많답니다.`,
      typography_35: `하지만 반추에 빠지면 끊임없는 걱정 속에서 불안함을 없애려고 하기도 하고`,
      typography_36: `끊임없는 사색과 의미 탐색을 통해 자신의 상황과 저조한 기분을 이해하여 이를 바꾸고, 없애고, 차단하려고 해요.`,
      typography_37: `해결할 수 없는 문제를 해결하고자 애쓰는 것이지요.`,

      typography_38: `문제 해결을 가장한 '거리 가까워지기'`,
      typography_39:
        "많은 연구들은 반추가 겉보기엔 문제해결과 유사한 모습을 보이지만, 실상은 그로부터 어떠한 이점도 얻지 못한다는 걸 밝혀냈어요.",
      typography_40:
        "우울함이나 불안감을 겪는 사람들은 반추를 통해 현재 마주하고 있는 괴로움의 본질에 대해 귀중한 통찰을 얻는다고 믿죠.",
      typography_41:
        "이 지긋지긋한 악순환의 원인을 찾을 수 있을 것만 같은 생각이 들어요.",
      typography_42: '"직장에서 내가 왜 이 모양인지 이제 알 것 같아."',
      typography_43:
        '"내가 지금 이렇게 우울한 건 어렸을 때 엄마가 나를 사랑해주지 않았기 때문이지. 아마 할머니와 자라서 그럴 거야."',
      typography_44:
        "하지만 이러한 생각들은 대부분 별다른 깨달음이 없이 인생의 부정적인 부분에만 초점을 맞추도록 해요.",
      typography_45: "깨달음이 없는 이유는 간단해요.",
      typography_46:
        "문제는 과거에 존재하여 이미 일어났거나, 또는 미래에 존재하여 아직 일어나지 않았기 때문에 당장 해결할 수 없기 때문이에요.",
      typography_47: `지금 당장에 내가 할 수 있는 것, 지금 당장 내가 삶 속에서 향유할 수 있는 것들이 아니기 때문이죠.`,

      typography_48: `펑크가 난 자동차`,
      typography_49:
        "자, 다음과 같은 상황을 상상해보세요. 당신은 차를 몰다가 시내에 주차를 했습니다. 그런데 타이어에 펑크가 났네요.",
      typography_50: "이 상황에서 어떻게 하겠나요?",
      typography_51:
        "좋아요. 아마 대부분 타이어를 수리하려고 했을 거예요. 혹은 어떻게 하다가 타이어가 펑크가 났는지 알기 위해 블랙박스를 돌려볼 수도 있겠죠.",
      typography_52:
        "이제 당신이 아주 좋아하는 활동을 아래에 하나 적어보시길 바랍니다. 무엇이든 괜찮습니다. (예: 사랑하는 딸의 연주회 가기, 연인과 근사한 식당에서 저녁 먹기 등)",
      typography_53:
        "이번엔 만약 당신이 위에 적은 활동을 하기 위해 시내에 도착해 주차를 한 것이라고 생각해볼까요?",
      typography_54:
        "위 활동은 정해진 시간에 시작하기로 되어 있어서 10분 뒤면 시작합니다. 그런데 타이어에 펑크가 난 걸 발견한 거죠.",
      typography_55: `이제 당신은 어떻게 하겠나요? 여전히 차를 살펴보며 타이어를 수리할 건가요?`,
      typography_56:
        "당신에게 중요한 활동을 미루면서까지 왜 타이어 바람이 빠졌는지 고민하는 것이 마땅한가요?",
      typography_57:
        "반추는 위와 같이 흘러갑니다. 마치 문제 해결처럼 보이지만 정작 중요한 것들에 대해서는 아무런 도움을 주지 못하죠.",
      typography_58: "이러한 반추는 우리의 오래된 정신적 습관입니다.",
      typography_59: "마음은 아주 오랜 정신적 흔적이나 자국 위를 달립니다.",

      typography_60: "반추로부터 거리두기",
      typography_61: "반추 생각함정을 벗어나는 방법은 생각보다 간단해요.",
      typography_62: "팝콘처럼 마음속에 떠오른 생각에 강하게 매몰되기 전에,",
      typography_63: "그러한 생각이 마음속에 떠올랐음을 인지하고,",
      typography_64:
        "한 걸음 떨어져서 그 생각이 어떠한 생각인지 객관적으로 살펴보는 것이죠.",
      typography_65:
        "일정 시간 이상 어렴풋한 생각들을 그저 곱씹고만 있는 것은 아닌지 파악하는 거죠.",
      typography_66:
        "그리고 그 방식의 생각이 정말 나에게 도움이 되는지 살펴보는 거예요.",
      typography_67:
        "만약 그것이 나에게 그다지 도움이 되지 않는다면 반추에서 빨리 빠져나와야 해요.",
      typography_68:
        "그건 그저 마음속에 떠오른 하나의 심리적 사건일 뿐, 그 생각 자체가 나 자신은 아니라는 걸 인지하면서요.",
      typography_69: `반추로부터 빠져나온 후에는 다시 나의 가치에 맞는 방향으로 나아가는 선택을 하면 된답니다.`,
      typography_70: `지금 우리 앞에는 삶에서 해나가야 할 것들이 놓여있어요.`,
      typography_71: `지금 이 순간 향유할 수 있는 행복들이 있답니다.`,
      typography_72:
        "지금은 타이어에 펑크가 난 자동차에 앉아서 상황의 의미, 과거, 미래를 파헤칠 것이 아니라 내가 중시하는 것들을 향해 움직여야 할 때예요.",
      typography_73:
        "자, 그러면 내 마음속에 떠오른 반추를 찾고, 그로부터 거리두기를 연습해 볼까요?",
    },
  },
};
