import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const WORKFLOW_DOMAIN = "workflow";

export const WORKFLOW_DETAIL_ENDPOINT = "detail";
export const WORKFLOW_HUMAN_CHECK_DETAIL_ENDPOINT =
  "human-check-required/detail";
export const WORKFLOW_WORK_COMPLETE_ENDPOINT = "work/complete";
export const WORKFLOW_WORK_PRESET_COMPLETE_ENDPOINT =
  "work/preset-task/complete";
export const WORKFLOW_WORK_TOOMANYTURNS_COMPLETE_ENDPOINT =
  "work/too-many-turns-task/complete";
export const WORKFLOW_WORK_DISAGREE_COMPLETE_ENDPOINT =
  "work/disagree-task/complete";

export type WorkStatusType =
  | "ready"
  | "processing"
  | "completed"
  | "human_check_required";
export type WorkIdType =
  | "start_update"
  | "check_chat"
  | "comment_task"
  | "update_concept_note"
  | "confirm_concept_note"
  | "comment_thought_record"
  | "update_task"
  | "preset_task"
  | "send_task_update_message";

interface WorkType {
  workId: WorkIdType;
  seq: number;
  status: WorkStatusType;
}
export interface HumanCheckTaskType {
  taskKey: string;
  isCompleted: boolean;
}

interface HumanCheckDetailType {
  presetTaskList?: HumanCheckTaskType[];
  tooManyTurnsTaskList?: HumanCheckTaskType[];
  disagreeTaskList?: HumanCheckTaskType[];
}

export interface WorkflowType {
  workflowId: string;
  workList: WorkType[];
  isInProgress: boolean;
}

export const workflowApi = {
  getDetail: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<WorkflowType>>(
      `${WORKFLOW_DOMAIN}/${WORKFLOW_DETAIL_ENDPOINT}`,
      { params: { pid: pid } }
    ),
  getHumanCheckDetail: (pid: string, wf_id: string, w_id: string) =>
    axiosInstance.get<CustomResponseFormat<HumanCheckDetailType>>(
      `${WORKFLOW_DOMAIN}/${WORKFLOW_HUMAN_CHECK_DETAIL_ENDPOINT}`,
      { params: { pid: pid, wf_id: wf_id, w_id: w_id } }
    ),

  complete: (pid: string, wf_id: string, workId: string) =>
    axiosInstance.post<void>(
      `${WORKFLOW_DOMAIN}/${WORKFLOW_WORK_COMPLETE_ENDPOINT}`,
      { workId: workId },
      { params: { pid: pid, wf_id: wf_id } }
    ),
  presetTaskComplete: (pid: string, wf_id: string, taskKey: string) =>
    axiosInstance.post<void>(
      `${WORKFLOW_DOMAIN}/${WORKFLOW_WORK_PRESET_COMPLETE_ENDPOINT}`,
      { taskKey: taskKey },
      { params: { pid: pid, wf_id: wf_id } }
    ),
  tooManyTurnsTaskComplete: (pid: string, wf_id: string, taskKey: string) =>
    axiosInstance.post<void>(
      `${WORKFLOW_DOMAIN}/${WORKFLOW_WORK_TOOMANYTURNS_COMPLETE_ENDPOINT}`,
      { taskKey: taskKey },
      { params: { pid: pid, wf_id: wf_id } }
    ),
  disagreeTaskComplete: (pid: string, wf_id: string, taskKey: string) =>
    axiosInstance.post<void>(
      `${WORKFLOW_DOMAIN}/${WORKFLOW_WORK_DISAGREE_COMPLETE_ENDPOINT}`,
      { taskKey: taskKey },
      { params: { pid: pid, wf_id: wf_id } }
    ),
};
