import { TypographyLine } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
} from "../../BlockComponent";

import { LastCard } from "../common";

export const Content_6_10_A = [
  Instruction(`typography_0`),
  Instruction("typography_1"),
  Instruction(`typography_2`),
  Instruction(`typography_3`),
  Instruction(`typography_4`),

  Header1(`typography_5`),
  Instruction(`typography_6`),
  Instruction(`typography_7`),
  Instruction(`typography_8`),
  Instruction(`typography_9`),
  Instruction(`typography_10`),
  Instruction(`typography_11`),
  Instruction(`typography_12`),
  Instruction(`typography_13`),
  Instruction(`typography_14`),
  Instruction(`typography_15`),
  Instruction(`typography_16`),
  Instruction(`typography_17`),
  Instruction(`typography_18`),
  Instruction(`typography_19`),

  Header1(`typography_20`),
  Instruction(`typography_21`),
  Instruction(`typography_22`),
  Instruction(`typography_23`),
  Instruction(`typography_24`),
  Instruction(`typography_25`),
  InstructionWithContent("typography_26", [TypographyLine(`typography_26_1`)]),
  InstructionWithContent("typography_27", [TypographyLine(`typography_27_1`)]),
  Instruction(`typography_28`),
  Instruction(`typography_29`),
  Instruction(`typography_30`),
  Instruction(`typography_31`),
  Instruction(`typography_32`),
  Instruction(`typography_33`),
  Instruction(`typography_34`),
  Instruction(`typography_35`),

  Header1(`typography_36`),
  Instruction(`typography_37`),
  Instruction(`typography_38`),
  Instruction(`typography_39`),
  Instruction(`typography_40`),
  InstructionWithContent("typography_41", [TypographyLine(`typography_41_1`)]),
  InstructionWithContent("typography_42", [TypographyLine(`typography_42_1`)]),
  Instruction(`typography_43`),
  Instruction(`typography_44`),
  Instruction(`typography_45`),
  Instruction(`typography_46`),
  Instruction(`typography_47`),

  Header1(`typography_48`),
  Instruction(`typography_49`),
  Instruction(`typography_50`),
  Instruction(`typography_51`),
  Instruction(`typography_52`),
  Instruction(`typography_53`),
  InstructionWithContent("typography_54", [TypographyLine(`typography_54_1`)]),
  InstructionWithContent("typography_55", [TypographyLine(`typography_55_1`)]),
  Instruction(`typography_56`),
  Instruction(`typography_57`),
  Instruction(`typography_58`),
  Instruction(`typography_59`),

  Header1(`typography_60`),
  Instruction(`typography_61`),
  Instruction(`typography_62`),
  Instruction(`typography_63`),
  Instruction(`typography_64`),
  Instruction(`typography_65`),
  Instruction(`typography_66`),

  ...LastCard,
];
