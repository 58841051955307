import Typography from "@mui/joy/Typography";
import { useEffect } from "react";
import { Stack } from "@mui/joy";
import { useResetRecoilState } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { userAtom } from "recoil/userAtom";
import NonDismissableModal from "components/common/NonDismisssableModal";
import { deepLinkData } from "data/deepLinkData";
import { useTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import useFetch from "hooks/useFetch";

export default function AppInstallModal() {
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  const [logoutData, logout] = useFetch({
    url: "/user/logout",
  });
  const navigate = useNavigate();
  const resetUser = useResetRecoilState(userAtom);
  const resetPid = useResetRecoilState(patientAtom);

  const initializeUser = () => {
    resetUser();
    resetPid();
    mixpanel.reset();
  };
  useEffect(() => {
    if (logoutData) {
      initializeUser();
      navigate("/login");
    }
  }, [logoutData]);

  return (
    <NonDismissableModal
      title={t_ui("앱에서 진행해주세요")}
      textAlign="center"
      content={
        <Stack alignItems={"center"} spacing={1}>
          <img
            src={deepLinkData.install.qr}
            alt={"empty"}
            className="w-[100px] "
          />
          <Typography
            sx={{
              opacity: 0.5,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              logout();
            }}
          >
            {t_ui("다른 계정으로 로그인하기")}
          </Typography>
        </Stack>
      }
    />
  );
}
