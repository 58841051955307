import { seqPostfix_ko } from "data/prefixData";

export const translation_104_0_A = {
  en: {},
  ko: {
    v1: {
      ...seqPostfix_ko,
      typography_0_0_0: "자, 지금부터 $N$ 번째 활동 계획을 시작해 봐요.",
      typography_1_0_0: "활동 선택하기",
      typography_2_0_0: "먼저 어떤 활동을 계획해서 실천할 건지 골라볼게요.",
      typography_3_0_0:
        "특히 긍정 강화 행동, 즉, 내 삶에서 긍정적인 보상을 주는 활동을 선택해 볼 거예요.",
      typography_4_0_0:
        "어려워 보여도 걱정하지 마세요. 할 수 있는 것들부터 단계적으로 실천할 거니까요. 그저 내가 원하는 활동을 선택해보세요.",
      typography_5_0_0:
        "나의 <활동기록지>에서 발견한 긍정 강화 행동이에요. 무엇을 계획해서 해볼까요?",
      typography_6_0_0:
        "보여드릴 활동들은 보편적으로 사람들에게 작지만 중요한 즐거움을 주는 긍정 강화 행동들인데요.",
      typography_7_0_0:
        "그중에서도 가볍게 시작할 수 있는 것들을 먼저 추려봤어요.",
      typography_8_0_0: "내가 가장 해보고 싶은 활동은 무엇인가요?",
      typography_9_0_0: "단계 만들기",
      typography_10_0_0: "좋아요. 이제 이 활동을 실천해 볼 텐데요.",
      typography_11_0_0:
        "처음부터 완벽하게, 많은 것을 해내려고 하는 건 오히려 역효과를 불러일으킬 수 있답니다.",
      typography_12_0_0:
        "무기력을 극복하기 위해서는 단계적으로 쪼개서 시시해 보일지라도 간단한 활동을 먼저 진행하는 게 좋답니다.",
      typography_13_0_0:
        "예를 들어 ‘달리기 하기'를 골랐다면, 다음과 같이 단계를 나눠볼 수 있을 거예요.",
      typography_13_1_0: "운동화와 운동복 구비하기",
      typography_13_2_0: "운동복과 운동화 입고 집 밖으로 나갔다가만 돌아오기",
      typography_13_3_0: "집 주변 산책로 걷고 돌아오기",
      typography_13_4_0: "10분 동안 집 주변 달리기",
      typography_13_5_0: "30분 동안 다른 지역까지 달리기",
      typography_13_0_0_1:
        '중요한 건 이 단계가 한 가지 활동을 할 때의 "순서"대로 분리하는 게 아니라는 것이에요.',

      typography_13_0_0_2:
        "예를 들어, 아래와 같이 그 활동을 진행할 때의 각 과정을 순서대로 나눠서 단계를 만드시는 분이 있어요.",
      typography_13_1_0_2: "운동화 신기",
      typography_13_2_0_2: "운동복 입기",
      typography_13_3_0_2: "준비 운동하기",
      typography_13_4_0_2: "10분 동안 달리기",
      typography_13_5_0_2: "마무리 운동하고 귀가",

      typography_13_0_0_3: "하지만 이는 단계를 잘못 설정한 것이에요.",
      typography_13_0_0_4:
        "우리는 각 단계를 다른 날짜 또는 다른 시간대에 하나씩 진행할 거랍니다.",
      typography_13_0_0_5:
        "오늘은 1단계만, 내일은 2단계만, 이런 식으로 난이도를 점차 높여가면서요.",
      typography_13_0_0_6: "이제 내가 선택한 활동의 단계를 만들어 볼 텐데요.",
      typography_14_0_0:
        "그러니 각 단계를 하나씩 독립적으로 진행할 수 있도록 계획해 보세요.",
      typography_15_0_0:
        "쉬운 것부터 어려운 것까지 5단계의 작은 활동들을 만들어보세요.",
      typography_16_0_0:
        "명심하세요. 시시해 보이는 것도 좋으니 간단한 활동을 먼저 해 보는 거예요.",
      typography_17_0_0:
        "그러니 활동을 최대한 쉬운 활동부터 단계적으로 분할해보세요.",
      typography_18_0_0:
        "‘안 입는 옷 정리하기'라는 활동을 선택했다면 ‘옷장 둘러보고 어떤 옷들이 있는지 확인하기'도 충분히 좋은 시작점이랍니다.",
      typography_19_0_0: "자, 그러면 내가 선택한 '$A$'의 단계를 만들어 볼까요?",
      typography_20_0_0:
        "좋아요. 이제 1단계부터 조금 더 자세한 계획을 짜볼게요.",
      typography_21_0_0: "다음 활동에서 만나요.",
    },
  },
};
