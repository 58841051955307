import { Box } from "@mui/joy";
import TaskHeader from "../header/TaskHeader";
import { useRecoilState, useRecoilValue } from "recoil";
import { paymentOrderAtom } from "recoil/paymentOrderAtom";
import PaymentContent from "./PaymentContent";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import { useQuery } from "@tanstack/react-query";
import {
  PAYMENT_DOMAIN,
  PAYMENT_ORDER_DETAIL_ENDPOINT,
  paymentApi,
} from "api/paymentApi";
import React, { useEffect } from "react";
import { userAtom } from "recoil/userAtom";
import useIsInappWebview from "hooks/useIsInappWebview";
import TransparentHeader from "../header/TransparentHeader";
import PaymentContentOnly3Month from "./PaymentContentOnly3Month";

export default function Payment() {
  const { hasFreeTrialUsed } = useSubscriptionStatus();
  const [paymentOrderState, setPaymentOrderState] =
    useRecoilState(paymentOrderAtom);
  const bannerType = paymentOrderState?.bannerType;
  const is3Month = bannerType === 5;
  const user = useRecoilValue(userAtom);
  const isInappWebview = useIsInappWebview();

  const { refetch: getPaymentOrder } = useQuery(
    [PAYMENT_DOMAIN, PAYMENT_ORDER_DETAIL_ENDPOINT],
    () => paymentApi.getOrderDetail(),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data && data.result) {
          setPaymentOrderState(data.result);
        }
      },
    }
  );

  useEffect(() => {
    if (user?.accessToken) {
      getPaymentOrder();
    } else {
      setPaymentOrderState((state) => ({
        ...state,
        paymentProvider: "in_app",
        bannerType: 5,
        productId: "orwellhealth.distancing.subscription10",
      }));
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
        }}
      >
        {isInappWebview ? (
          <TransparentHeader />
        ) : (
          <TaskHeader taskKey={"payment"} />
        )}
      </Box>
      {paymentOrderState && (
        <div className="w-full h-full overflow-x-hidden">
          {((hasFreeTrialUsed !== undefined && !!bannerType) ||
            !isInappWebview) &&
            (is3Month ? <PaymentContentOnly3Month /> : <PaymentContent />)}
        </div>
      )}
    </>
  );
}