import {
  DateSelect,
  LineWithSolidLabel,
  Percentage,
  TextareaLine,
} from "data/CellComponent";
import dayjs from "dayjs";
import { Instruction, InstructionWithContent } from "../../BlockComponent";

const today = dayjs().format("YYYY-MM-DD");

export const SegmentationNoteTemplate = ({
  isThoughtTrapAvailable,
  includeLastBlock,
}: {
  isThoughtTrapAvailable?: boolean;
  includeLastBlock?: boolean;
}) => [
  InstructionWithContent(`template_impact`, [
    ...LineWithSolidLabel("영향 정도", [
      Percentage({
        id: `percentageThought`,
      }),
    ]),
  ]),
  InstructionWithContent(
    `template_when`,
    LineWithSolidLabel("날짜", [DateSelect({ id: "date", value: today })])
  ),

  InstructionWithContent(`template_situation`, [
    ...LineWithSolidLabel("상황", TextareaLine({ id: "situation" })),
  ]),
  InstructionWithContent(`template_thought`, [
    ...LineWithSolidLabel("생각", TextareaLine({ id: "thought" })),
  ]),
  ...[
    InstructionWithContent(
      `template_emotion`,
      LineWithSolidLabel("감정", TextareaLine({ id: "emotion" }))
    ),
  ],
  InstructionWithContent(
    `template_sensation`,

    [
      ...LineWithSolidLabel(
        "감각",
        TextareaLine({ id: "sensation" })
      ),
    ],
    { blockId: "sensation" }
  ),
  InstructionWithContent(
    `template_behavior`,

    [...LineWithSolidLabel("행동/충동", TextareaLine({ id: "behavior" }))],

    { blockId: "template_end" }
  ),

  ...(includeLastBlock ? SegmentationNoteLastCard : []),
];

export const SegmentationNoteLastCard = [
  Instruction("last_typography_0", { blockId: "segmentationNote_last" }),
];