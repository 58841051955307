import { Typography } from "@mui/joy";

export default function FreeTrialInstruction() {
  return (
    <Typography level="body-md" sx={{ fontWeight: 700 }}>
      7일 이내 취소 시 &nbsp;
      <Typography
        level="body-md"
        sx={{ fontWeight: 700, color: "primary.solid" }}
      >
        100% 무료
      </Typography>
    </Typography>
  );
}
