export const translation_8_1_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "앞서 생각은 마치 팝콘과 같다고 했어요.",
      typography_1_0_0: "생각은 우리가 의지를 가지고 직접 ‘하는 게' 아니에요.",
      typography_2_0_0:
        "지난 경험과 현재의 환경에 따라 불규칙적으로 마음속에 떠오를 뿐이에요.",
      typography_3_0_0: "생각은 ‘하나의 심리적 사건’일 뿐이랍니다.",
      typography_4_0_0: `디스턴싱은 “생각은 하나의 심리적 사건이다"라는 관점을 가슴에 새기기 위한 여정이에요.`,
      typography_5_0_0: "중요한 이야기니 조금 더 자세히 살펴볼게요.",
      typography_6_0_0: "감정은 ‘나'가 아니다",
      typography_7_0_0: "우리는 삶을 살아가며 다양한 감정과 감각을 느껴요.",
      typography_8_0_0: "기쁜 일이 있으면 즐겁고 신나는 기분을 느끼죠.",
      typography_9_0_0: "하지만 그렇다고 기쁨과 즐거움이 나 자신은 아니에요.",
      typography_10_0_0:
        "그저 마음속에 떠올랐다가 우리에게 행복감을 주고 또 소멸되는 하나의 현상일 뿐이에요.",
      typography_11_0_0:
        "마찬가지로 힘든 일이 있으면 우울하고 불안한 감정을 느끼죠.",
      typography_12_0_0: "하지만 역시 우울과 불안이 나 자신은 아니에요.",
      typography_13_0_0: "이처럼 감정은 마음속에 느껴지는 하나의 현상이랍니다.",
      typography_14_0_0: "감각은 ‘나'가 아니다",
      typography_15_0_0: "비슷하게 우리는 삶을 살아가며 다양한 감각을 느껴요.",
      typography_16_0_0:
        "컨디션이 좋은 날, 아침에 일어나면 몸이 아주 가벼운 것을 느끼죠.",
      typography_17_0_0:
        "하지만 그렇다고 그 가벼운 느낌 자체가 나 자신은 아니에요.",
      typography_18_0_0:
        "그러한 느낌은 그저 오늘 나의 마음속에 떠오르는 하나의 감각일 뿐이죠.",
      typography_19_0_0:
        "아침에 일어나서 몸이 가볍다고 하여 ‘몸이 가벼움' 자체로 스스로를 정의하는 사람은 없답니다.",
      typography_20_0_0: "생각도 ‘나'가 아니다",
      typography_21_0_0: "생각도 정확하게 이와 동일해요.",
      typography_22_0_0:
        "생각은 그저 마음속에 떠오르는 하나의 심리적 사건일 뿐입니다.",
      typography_23_0_0:
        "자동적이고 무작위적으로 마음속에 떠오르는 하나의 현상일 뿐이지요.",
      typography_24_0_0:
        "오늘은 이런 생각이 떠오를 수 있어요. 하지만 그 생각이 나 자신은 아니랍니다.",
      typography_25_0_0:
        "내일은 저런 생각이 떠오를 수 있어요. 하지만 역시나 그 생각이 나 자신은 아니랍니다.",
      typography_26_0_0:
        "생각은 단지 생각일 뿐, 그 생각들은 ‘나 자신'이 아니랍니다.",
      typography_27_0_0: "생각과 다시 관계 맺기",
      typography_28_0_0: `하지만 생각을 팝콘처럼, 하나의 심리적 사건처럼 바라보는 건 쉽지 않아요.`,
      typography_29_0_0:
        "우리는 아주 자유롭게 생각하고 있다고 배우고, 믿고, 또 그렇게 느껴왔기 때문이에요.",
      typography_30_0_0:
        "하지만 이미 수많은 연구들이 생각에 대한 우리의 오래된 관념을 포기하고 생각과 새로운 관계를 맺는다면,",
      typography_31_0_0:
        "더 유연하고 평온한 심리적 상태를 유지할 수 있다는 것을 증명해 나가고 있어요.",
      typography_32_0_0: "자, 그러면 이제 그 방법을 알아볼까요?",
    },
  },
};
