export const translation_4_11_A = {
  en: {},
  ko: {
    v1: {
      "$A$, $B$": "$A$, $B$",
      "$A$ → $B$": "$A$ → $B$",
      실행했어요: "실행했어요",
      "실행하지 못했어요": "실행하지 못했어요",
      일치했어요: "일치했어요",
      "일치하지 않았어요": "일치하지 않았어요",
      typography_0_0_0: `드디어 모든 단계의 행동 실험을 모두 마쳤네요 🙌.`,
      typography_1_0_0: `지금까지 우리가 함께 설계한 행동 실험은 다음과 같아요.  `,
      typography_2_0_0: `행동 실험의 결과는 이렇게 나타났네요.`,
      typography_3_0_0: `행동 실험을 하기 전에 느꼈던 불안이나 걱정이 기억나시나요? `,
      typography_4_0_0: `무엇을 하기 전에 들었던 걱정이 아무것도 아닌 것처럼 느껴지고, 예상이 꼭 맞지 않을 수 있다는 것을 직접 체험해 보니 어떤가요? 이게 바로 거리두기의 힘이랍니다.`,
      typography_5_0_0: `어쩌면 생각보다 큰 감흥이 없다고 말씀하실지도 모르겠어요. `,
      typography_6_0_0: `한 번의 실험으로 우리의 생각이 완전히 바뀌기는 당연히 어렵습니다. 마치 첫 숟갈에 배부르기 어려운 것처럼요. `,
      typography_7_0_0: `하지만 가랑비에 옷이 젖어가듯이 행동 실험이나 대안 행동을 반복하다 보면 새로운 건강한 생각이 자리를 잡고 힘을 키워나가는 경험을 하게 되실 거예요. `,
      typography_8_0_0: `그러다 보면 새로운 자신감을 느끼고 스스로를 긍정적으로 바라볼 수 있을 거라 믿어요.`,
      typography_9_0_0: `고생 많으셨습니다 👏 조금 더 힘을 내어 다음의 활동으로 넘어가 볼까요?`,
    },
  },
};
