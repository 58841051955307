import { useEffect, useState } from "react";
import { SegmentationNoteItemType } from "api/homeApi";
import { menuKeyType } from "./NestedList";
import ActivityList from "./ActivityList";

export default function SegmentationList({
  data,
}: {
  data: SegmentationNoteItemType[];
}) {
  const [listData, setListData] = useState<
    | {
        listTitle: string;
        segmentationNoteList: SegmentationNoteItemType[];
        menuKey: menuKeyType;
        initiallyOpen?: boolean;
      }[]
    | undefined
  >();

  useEffect(() => {
    if (data) {
      const openList = data.filter(
        (element) => !element.isDummy && element.isOpen
      );
      const closeList = data.filter(
        (element) => !element.isDummy && !element.isOpen
      );
      setListData([
        ...(openList.length > 0
          ? [
              {
                listTitle: "답변할 분리",
                segmentationNoteList: openList,
                menuKey: "segmentationNoteList_open" as menuKeyType,
                initiallyOpen: true,
              },
            ]
          : []),
        ...(closeList.length > 0
          ? [
              {
                listTitle: "최근 분리",
                segmentationNoteList: closeList,
                menuKey: "segmentationNoteList_close" as menuKeyType,
              },
            ]
          : []),
      ]);
    }
  }, [data]);

  return (
    <ActivityList
      title={"분리 노트"}
      isInitialLoading={!data}
      listData={listData}
    />
  );
}