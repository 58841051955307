import { Stack, Typography } from "@mui/joy";
import { SxProps, TypographySystem } from "@mui/joy/styles/types";
import { findSubstringIndices } from "logic/logics";

export default function TitleWithSubtitle({
  title,
  titleHighlightedText,
  subtitle,
  sx,
  titleLevel,
  type,
}: {
  title?: string;
  titleHighlightedText?: string;
  subtitle?: string;
  sx?: SxProps;
  // titleLevel?: "h1" | "h2" | "h3";
  titleLevel?: keyof TypographySystem;
  type?: "neutral";
}) {
  return (
    <Stack spacing={titleLevel === "h2" ? "12px" : "8px"} sx={sx}>
      {title && (
        <Typography>
          {(titleHighlightedText
            ? [
                title.substring(
                  0,
                  findSubstringIndices(title, titleHighlightedText).startIndex
                ),
                titleHighlightedText,
                title.substring(
                  findSubstringIndices(title, titleHighlightedText).endIndex + 1
                ),
              ]
            : [title]
          ).map((each) =>
            each === titleHighlightedText ? (
              <Typography
                level={titleLevel || "h4"}
                sx={{
                  wordBreak: "keep-all",
                  overflowWrap: "break-word",
                  fontWeight: 800,
                  fontSize: 22,
                  color: "primary.solid",
                }}
              >
                {each}
              </Typography>
            ) : (
              <Typography
                level={titleLevel || "h4"}
                sx={{
                  wordBreak: "keep-all",
                  overflowWrap: "break-word",
                  fontWeight: 800,
                  fontSize: 22,
                }}
              >
                {each}
              </Typography>
            )
          )}
        </Typography>
      )}
      {subtitle && (
        <Typography level={"body-md"} sx={{ opacity: 0.5 }}>
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
}