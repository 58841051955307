import { translation_meditation } from "./meditation";

export const translation_8_4_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "두 번째 연습은 어땠나요?",
      typography_1_0_0:
        "아마 호흡에 초점을 두고 연습했을 때 조금 더 쉬웠을 수도 있을 거랍니다.",
      typography_2_0_0:
        "정신없이 생각을 따라가고 있는 걸 알아차렸을 때 다시 돌아올 곳이 분명하니까요.",
      typography_3_0_0:
        "하지만 단순히 ‘주의를 호흡으로 돌리는 것'이 목표는 아니랍니다.",
      typography_4_0_0: `중요한 건 우리가 마음속에 떠오르는 생각을 한 발짝 거리두고 바라볼 수 있다는 것이에요.`,
      typography_5_0_0: "마음속에 그런 공간을 마련할 수 있다면,",
      typography_6_0_0: "우리는 그 모든 생각에 즉각적으로 반응하지 않으면서도,",
      typography_7_0_0:
        "삶을 우리가 원하는 방향으로 이끌어나갈 자유를 얻을 수 있답니다.",
      typography_8_0_0: "잘한 명상, 못한 명상",
      typography_9_0_0: `“오늘도 ‘잘’ 못했어요”`,
      typography_10_0_0: `생각의 강 연습을 하다 보면 많은 분들이 “잘하지 못했다"라는 이야기를 하곤 해요.`,
      typography_11_0_0: `무엇이 그랬냐고 물어보면 “연습 내내 생각에만 빠져있었다”라고 말하며 침울해하죠.`,
      typography_12_0_0: "중요한 소식이 하나 있어요.",
      typography_13_0_0: "이 연습에는 잘하고, 못하고가 없답니다.",
      typography_14_0_0: "우리의 마음은 자동적이라고 말했어요.",
      typography_15_0_0:
        "마음속에 어떤 생각이 피어오르는 건 우리가 제어할 수 없답니다.",
      typography_16_0_0:
        "우리가 그 생각들에 정신이 팔리는 것도 아주 자연스러운 일이에요.",
      typography_17_0_0:
        "그러니 이 연습의 목표는 마음을 아주 맑고 깨끗하게 만드는 게 아니랍니다.",
      typography_18_0_0: "그런 일은 불가능해요.",
      typography_19_0_0:
        "그보다 중요한 건 우리 마음이 그렇게 자동적이라는 사실을 깨닫는 과정 그 자체랍니다.",
      typography_20_0_0: "그러니 10분 동안 연습하며 생각에 빠져있다가",
      typography_21_0_0: `마지막에 “아차, 내가 또 생각에 빠져있구나”라고 생각했다고 해서 문제 될 건 없답니다.`,
      typography_22_0_0:
        "연습의 초반부든, 중간이든, 마지막이든, 우리가 또 마음속 팝콘들에 정신이 팔려있었다는 걸 알아차렸단 사실이 중요하답니다.",
      typography_23_0_0:
        "이 연습에서 중요한 건 어떤 이상적인 상태, 결과가 아니라 과정이에요. 여정 그 자체가 중요하답니다.",
      typography_24_0_0:
        "그러니 만약 생각에 팔려있었다는 걸 알아차리는 순간 자책하며 괴로워한다면,",
      typography_25_0_0:
        "명상 훈련의 목적을 완전히 잘못 이해하고 있는 것이랍니다.",
      typography_26_0_0: "명상 훈련에 잘하고 못하고는 없답니다.",
      typography_27_0_0: "세 번째 연습",
      typography_28_0_0: `자, 오늘도 한 차례 더 연습해 볼 텐데요.`,
      typography_29_0_0:
        "이번에는 위에서 말한 부분을 잘 되새기면서 연습해보세요.",
      typography_30_0_0:
        "알아차리는 그 순간에 괴로워하거나 자책하지 말고, 그것 자체가 깨달음의 순간이라고 여기고 다시 묵묵히 나의 자리로 돌아오는 거예요.",
      typography_31_0_0: "오늘은 15분 정도 연습을 해봐요.",
      ...translation_meditation.ko.v2,
    },
  },
};
