import { Stack, Typography } from "@mui/joy";
import { memo } from "react";
import { useRecoilValue } from "recoil";
import {
  conceptKeyMapping,
  ConceptKeyType,
  CoreBeliefKeyType,
  IntermediateBeliefKeyType,
} from "api/conceptApi";
import * as _ from "lodash";
import { conceptAtom } from "recoil/conceptAtom";
import { conceptNoteAtom } from "recoil/conceptNoteAtom";

function ConceptItemCell({
  taskKey,
  conceptKey,
  isNotConfirmed,
}: {
  taskKey: string;
  conceptKey: ConceptKeyType;
  isNotConfirmed?: boolean;
}) {
  const conceptState = useRecoilValue(conceptAtom);
  const conceptNoteState = useRecoilValue(conceptNoteAtom);
  const data = isNotConfirmed ? conceptNoteState : conceptState;
  return data ? (
    <Stack spacing={"8px"}>
      {Object.entries(data[conceptKey] || {})
        .filter(([key, value]) => (value as string)?.trim().length > 0)
        .map(([key, value]) => (
          <Stack key={`${key}`} spacing={"8px"}>
            <Typography
              level="title-sm"
              sx={{
                color: "primary.solid",
                fontWeight: 700,
              }}
            >
              {
                conceptKeyMapping[
                  key as CoreBeliefKeyType | IntermediateBeliefKeyType
                ]
              }
            </Typography>
            <Typography level="body-md">{(value as string) || ""}</Typography>
          </Stack>
        ))}
    </Stack>
  ) : (
    <></>
  );
}

export default memo(ConceptItemCell);
