import Link from "components/common/Link";
import useOpenTask from "hooks/useOpenTask";
import useGetTaskTitleFromTaskKey from "hooks/useGetTaskTitleFromTaskKey";

export default function TaskLink({ taskKey }: { taskKey: string }) {
  const openTask = useOpenTask(taskKey);

  const taskTitle = useGetTaskTitleFromTaskKey(taskKey);

  return (
    <Link
      text={taskTitle}
      opacity={0.7}
      onClick={openTask}
      endDecorator={
        <span
          style={{ fontSize: "inherit" }}
          className="material-symbols-rounded"
        >
          north_east
        </span>
      }
    />
  );
}
