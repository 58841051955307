export interface EvaluationType {
  [key: string]: number;
}
const evaluation1: EvaluationType = {
  //전혀/며칠간/7일이상/거의매일
  selfcheck_evaluation1_1: 0,
  selfcheck_evaluation1_2: 1,
  selfcheck_evaluation1_3: 2,
  selfcheck_evaluation1_4: 3,
};
const evaluation2: EvaluationType = {
  //전혀/거의아님/가끔/자주/매우자주
  selfcheck_evaluation2_1: 0,
  selfcheck_evaluation2_2: 1,
  selfcheck_evaluation2_3: 2,
  selfcheck_evaluation2_4: 3,
  selfcheck_evaluation2_5: 4,
};
const evaluation2_reverse: EvaluationType = {
  selfcheck_evaluation2_1: 4,
  selfcheck_evaluation2_2: 3,
  selfcheck_evaluation2_3: 2,
  selfcheck_evaluation2_4: 1,
  selfcheck_evaluation2_5: 0,
};

const evaluation3: EvaluationType = {
  //매우 아님/아님/그럼/매우 그럼
  selfcheck_evaluation3_1: 0,
  selfcheck_evaluation3_2: 1,
  selfcheck_evaluation3_3: 2,
  selfcheck_evaluation3_4: 3,
};
const evaluation3_reverse: EvaluationType = {
  selfcheck_evaluation3_1: 3,
  selfcheck_evaluation3_2: 2,
  selfcheck_evaluation3_3: 1,
  selfcheck_evaluation3_4: 0,
};

const evaluation4: EvaluationType = {
  //전혀/거의아님/가끔/자주/힝싱
  selfcheck_evaluation4_1: 1,
  selfcheck_evaluation4_2: 2,
  selfcheck_evaluation4_3: 3,
  selfcheck_evaluation4_4: 4,
  selfcheck_evaluation4_5: 5,
};

const evaluation5_1: EvaluationType = {
  //전혀/거의아님/가끔/자주/매우자주
  selfcheck_evaluation5_1: 0,
  selfcheck_evaluation5_2: 0,
  selfcheck_evaluation5_3: 1,
  selfcheck_evaluation5_4: 1,
  selfcheck_evaluation5_5: 1,
};

const evaluation5_2: EvaluationType = {
  //전혀/거의아님/가끔/자주/매우자주
  selfcheck_evaluation5_1: 0,
  selfcheck_evaluation5_2: 0,
  selfcheck_evaluation5_3: 0,
  selfcheck_evaluation5_4: 1,
  selfcheck_evaluation5_5: 1,
};

const evaluation6: EvaluationType = {
  //전혀아님/아님/보통/그럼/매우그럼
  selfcheck_evaluation6_1: 0,
  selfcheck_evaluation6_2: 1,
  selfcheck_evaluation6_3: 2,
  selfcheck_evaluation6_4: 3,
  selfcheck_evaluation6_5: 4,
};
const evaluation6_reverse: EvaluationType = {
  //전혀아님/아님/보통/그럼/매우그럼
  selfcheck_evaluation6_1: 4,
  selfcheck_evaluation6_2: 3,
  selfcheck_evaluation6_3: 2,
  selfcheck_evaluation6_4: 2,
  selfcheck_evaluation6_5: 0,
};

function Question(
  questionTranslationKey: SelfCheckId,
  evaluation: EvaluationType
) {
  return {
    questionTranslationKey: questionTranslationKey,
    evaluation: evaluation,
  };
}

export type SelfCheckIdType =
  | "depression"
  | "anxiety"
  | "stress"
  | "self-esteem"
  | "burnout"
  | "ADHD"
  | "insomnia"
  | "job-satisfaction";

export type SelfCheckId = `${SelfCheckIdType}_${number}`;

export type SelfCheckDataType = {
  [key in SelfCheckIdType]: {
    title: string;
    screeningTool: string;
    questionList: {
      questionTranslationKey: SelfCheckId;
      evaluation: EvaluationType;
    }[];
    result: { label: string; range: number[]; resultLink?: string }[];
    abnormalScore: number;
  };
};

export const selfCheckData: SelfCheckDataType = {
  depression: {
    title: "우울",
    screeningTool: "phq-9",
    questionList: [
      Question("depression_1", evaluation1),
      Question("depression_2", evaluation1),
      Question("depression_3", evaluation1),
      Question("depression_4", evaluation1),
      Question("depression_5", evaluation1),
      Question("depression_6", evaluation1),
      Question("depression_7", evaluation1),
      Question("depression_8", evaluation1),
      Question("depression_9", evaluation1),
    ],
    result: [
      {
        label: "정상",
        range: [0, 4],
        resultLink: "https://orwell.distancing.im/phq-9-test-result-normal",
      },
      {
        label: "가벼움",
        range: [5, 9],
        resultLink: "https://orwell.distancing.im/phq-9-test-result-mild",
      },
      {
        label: "중간",
        range: [10, 14],
        resultLink: "https://orwell.distancing.im/phq-9-test-result-moderate",
      },
      {
        label: "심함",
        range: [15, 19],
        resultLink: "https://orwell.distancing.im/phq-9-test-result-severe",
      },
      {
        label: "극심함",
        range: [20, 29],
        resultLink: "https://orwell.distancing.im/phq-9-test-result-extreme",
      },
    ],
    abnormalScore: 10,
  },
  anxiety: {
    title: "불안",
    screeningTool: "gad-7",
    questionList: [
      Question("anxiety_1", evaluation1),
      Question("anxiety_2", evaluation1),
      Question("anxiety_3", evaluation1),
      Question("anxiety_4", evaluation1),
      Question("anxiety_5", evaluation1),
      Question("anxiety_6", evaluation1),
      Question("anxiety_7", evaluation1),
    ],
    result: [
      {
        label: "정상",
        range: [0, 4],
        resultLink: "https://orwell.distancing.im/gad-7-test-result-normal",
      },
      {
        label: "가벼움",
        range: [5, 9],
        resultLink: "https://orwell.distancing.im/gad-7-test-result-mild",
      },
      {
        label: "중간",
        range: [10, 14],
        resultLink: "https://orwell.distancing.im/gad-7-test-result-moderate",
      },
      {
        label: "심함",
        range: [15, 21],
        resultLink: "https://orwell.distancing.im/gad-7-test-result-severe",
      },
    ],
    abnormalScore: 10,
  },
  stress: {
    title: "스트레스",
    screeningTool: "pss",
    questionList: [
      Question("stress_1", evaluation2),
      Question("stress_2", evaluation2),
      Question("stress_3", evaluation2),
      Question("stress_4", evaluation2_reverse),
      Question("stress_5", evaluation2_reverse),
      Question("stress_6", evaluation2),
      Question("stress_7", evaluation2_reverse),
      Question("stress_8", evaluation2_reverse),
      Question("stress_9", evaluation2),
      Question("stress_10", evaluation2),
    ],
    result: [
      {
        label: "정상",
        range: [0, 12],
        resultLink: "https://orwell.distancing.im/pss-test-result-normal",
      },
      {
        label: "가벼움",
        range: [13, 15],
        resultLink: "https://orwell.distancing.im/pss-test-result-mild",
      },
      {
        label: "중간",
        range: [16, 18],
        resultLink: "https://orwell.distancing.im/pss-test-result-moderate",
      },
      {
        label: "심함",
        range: [19, 40],
        resultLink: "https://orwell.distancing.im/pss-test-result-severe",
      },
    ],
    abnormalScore: 16,
  },
  "self-esteem": {
    title: "자존감",
    screeningTool: "rses",
    questionList: [
      Question("self-esteem_1", evaluation3),
      Question("self-esteem_2", evaluation3),
      Question("self-esteem_3", evaluation3_reverse),
      Question("self-esteem_4", evaluation3),
      Question("self-esteem_5", evaluation3_reverse),
      Question("self-esteem_6", evaluation3),
      Question("self-esteem_7", evaluation3),
      Question("self-esteem_8", evaluation3_reverse),
      Question("self-esteem_9", evaluation3_reverse),
      Question("self-esteem_10", evaluation3_reverse),
    ],
    result: [
      {
        label: "낮음",
        range: [0, 14],
        resultLink: "https://orwell.distancing.im/rses-test-result-low",
      },
      {
        label: "평균",
        range: [15, 25],
        resultLink: "https://orwell.distancing.im/rses-test-result-normal",
      },
      {
        label: "평균 이상",
        range: [26, 30],
        resultLink: "https://orwell.distancing.im/rses-test-result-high",
      },
    ],
    abnormalScore: 0,
  },
  burnout: {
    title: "번아웃",
    screeningTool: "bat",
    questionList: [
      Question("burnout_1", evaluation4),
      Question("burnout_2", evaluation4),
      Question("burnout_3", evaluation4),
      Question("burnout_4", evaluation4),
      Question("burnout_5", evaluation4),
      Question("burnout_6", evaluation4),
      Question("burnout_7", evaluation4),
      Question("burnout_8", evaluation4),
      Question("burnout_9", evaluation4),
      Question("burnout_10", evaluation4),
      Question("burnout_11", evaluation4),
      Question("burnout_12", evaluation4),
    ],
    result: [
      {
        label: "위험 없음",
        range: [0, 30],
        resultLink: "https://orwell.distancing.im/bat-test-result-low",
      },
      {
        label: "중간 정도",
        range: [31, 35],
        resultLink: "https://orwell.distancing.im/bat-test-result-average",
      },
      {
        label: "높은 위험",
        range: [36, 60],
        resultLink: "https://orwell.distancing.im/bat-test-result-high",
      },
    ],
    abnormalScore: 0,
  },
  ADHD: {
    title: "ADHD",
    screeningTool: "asrs",
    questionList: [
      Question("ADHD_1", evaluation5_1),
      Question("ADHD_2", evaluation5_1),
      Question("ADHD_3", evaluation5_1),
      Question("ADHD_4", evaluation5_2),
      Question("ADHD_5", evaluation5_2),
      Question("ADHD_6", evaluation5_2),
    ],
    result: [
      {
        label: "가능성 적음",
        range: [0, 3],
        resultLink: "https://orwell.distancing.im/asrs-test-result-non-exists",
      },
      {
        label: "가능성 있음",
        range: [4, 6],
        resultLink: "https://orwell.distancing.im/asrs-test-result-exists",
      },
    ],
    abnormalScore: 0,
  },
  insomnia: {
    title: "불면증",
    screeningTool: "insomnia",
    questionList: [
      Question("insomnia_1", evaluation6),
      Question("insomnia_2", evaluation6),
      Question("insomnia_3", evaluation6),
      Question("insomnia_4", evaluation6_reverse),
      Question("insomnia_5", evaluation6),
      Question("insomnia_6", evaluation6),
      Question("insomnia_7", evaluation6),
    ],
    result: [
      {
        label: "정상",
        range: [0, 7],
        resultLink: "https://orwell.distancing.im/insomnia-test-result-normal",
      },
      {
        label: "가벼움",
        range: [8, 14],
        resultLink: "https://orwell.distancing.im/insomnia-test-result-mild",
      },
      {
        label: "중간 정도",
        range: [15, 21],
        resultLink:
          "https://orwell.distancing.im/insomnia-test-result-moderate",
      },
      {
        label: "심함",
        range: [22, 28],
        resultLink: "https://orwell.distancing.im/insomnia-test-result-severe",
      },
    ],
    abnormalScore: 0,
  },
  "job-satisfaction": {
    title: "직무 만족도",
    screeningTool: "자체 척도",
    questionList: [
      Question("job-satisfaction_1", evaluation6),
      Question("job-satisfaction_2", evaluation6),
      Question("job-satisfaction_3", evaluation6),
      Question("job-satisfaction_4", evaluation6),
      Question("job-satisfaction_5", evaluation6),
      Question("job-satisfaction_6", evaluation6),
      Question("job-satisfaction_7", evaluation6),
      Question("job-satisfaction_8", evaluation6),
      Question("job-satisfaction_9", evaluation6_reverse),
      Question("job-satisfaction_10", evaluation6_reverse),
    ],
    result: [],
    abnormalScore: 0,
  },
};
