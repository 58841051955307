import {
  Header1,
  Instruction,
  InstructionWithImage,
  InstructionWithTextarea,
} from "../../BlockComponent";
import { LastCard } from "../common";

export const Content_1_3_A = [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  Instruction("typography_2_0_0"),
  Instruction("typography_3_0_0"),
  Instruction("typography_4_0_0"),

  Header1("typography_5_0_0"),
  InstructionWithImage("typography_6_0_0", "0"),
  Instruction("typography_7_0_0"),
  Instruction("typography_8_0_0"),

  InstructionWithImage("typography_9_0_0", "1"),
  Instruction("typography_10_0_0"),

  InstructionWithImage("typography_11_0_0", "2"),
  Instruction("typography_12_0_0"),
  Instruction("typography_13_0_0"),
  Instruction("typography_14_0_0"),

  InstructionWithImage("typography_15_0_0", "3"),
  Instruction("typography_16_0_0"),
  Instruction("typography_17_0_0"),
  Instruction("typography_18_0_0"),

  InstructionWithImage("typography_19_0_0", "4"),
  Instruction("typography_20_0_0"),
  Instruction("typography_21_0_0"),
  Instruction("typography_22_0_0"),
  InstructionWithImage("typography_23_0_0", "5"),
  Instruction("typography_24_0_0"),
  Instruction("typography_25_0_0"),

  Header1("typography_26_0_0"),
  Instruction("typography_27_0_0"),
  InstructionWithImage("typography_28_0_0", "6"),
  Instruction("typography_29_0_0"),
  Instruction("typography_30_0_0"),
  InstructionWithTextarea("typography_31_0_0"),
  Instruction("typography_32_0_0"),
  Instruction("typography_33_0_0"),
  Instruction("typography_34_0_0"),
  InstructionWithImage("typography_35_0_0", "7"),
  InstructionWithImage("typography_36_0_0", "8"),
  Instruction("typography_37_0_0"),
  Instruction("typography_38_0_0"),
  Instruction("typography_39_0_0"),

  Header1("typography_40_0_0"),
  Instruction("typography_41_0_0"),
  Instruction("typography_42_0_0"),
  InstructionWithImage("typography_43_0_0", "9"),
  InstructionWithImage("typography_44_0_0", "10"),
  Instruction("typography_45_0_0"),
  InstructionWithImage("typography_46_0_0", "11"),
  Instruction("typography_47_0_0"),
  InstructionWithImage("typography_48_0_0", "12"),
  Instruction("typography_49_0_0"),
  Instruction("typography_50_0_0"),
  Instruction("typography_51_0_0"),
  Instruction("typography_52_0_0"),
  Instruction("typography_53_0_0"),
  Instruction("typography_54_0_0"),
  Instruction("typography_55_0_0"),
  Instruction("typography_56_0_0"),
  ...LastCard,
];
