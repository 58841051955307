import { selfCheckData } from "data/selfCheckData";
import {
  Header1,
  Instruction,
  ProgramContentType,
  SelfCheckCard,
} from "../../BlockComponent";
import { LastCard } from "../common";

export const selfCheckTemplate = [
  Header1("selfcheck_guide"),
  ...selfCheckData.depression.questionList.map(
    ({ questionTranslationKey, evaluation }, index) =>
      SelfCheckCard("depression", questionTranslationKey, evaluation)
  ),
  ...selfCheckData.anxiety.questionList.map(
    ({ questionTranslationKey, evaluation }, index) =>
      SelfCheckCard("anxiety", questionTranslationKey, evaluation)
  ),
  ...selfCheckData.stress.questionList.map(
    ({ questionTranslationKey, evaluation }, index) =>
      SelfCheckCard("stress", questionTranslationKey, evaluation)
  ),
];

export const Content_2_3: ProgramContentType[] = [
  Instruction(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),
  Instruction(`typography_14_0_0`),
  Instruction(`typography_15_0_0`),
  Instruction(`typography_16_0_0`),
  Header1(`typography_17_0_0`),
  Instruction(`typography_18_0_0`),
  Instruction(`typography_19_0_0`),
  Instruction(`typography_20_0_0`),
  Instruction(`typography_21_0_0`),
  Instruction(`typography_22_0_0`),
  Instruction(`typography_23_0_0`),
  Instruction(`typography_24_0_0`),

  ...selfCheckTemplate,
  Instruction("selfcheck_finish"),
  ...LastCard,
];