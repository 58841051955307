import * as _ from "lodash";
import { useRecoilState, useResetRecoilState } from "recoil";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import { currentTaskSectionIndexAtom } from "recoil/currentTaskSectionIndexAtom";
import { taskDataAtom } from "recoil/taskDataAtom";
import useGoBack from "./useGoBack";
import useIsLatest from "./useIsLatest";

export default function useRemoveTab({
  taskKey,
  sectionIndex,
}: {
  taskKey: string;
  sectionIndex?: number;
}) {
  const isLatest = useIsLatest();
  const [taskSectionState, setTaskSectionState] =
    useRecoilState(taskSectionAtom);
  const [currentTaskSectionIndex, setCurrentTaskSectionIndex] = useRecoilState(
    currentTaskSectionIndexAtom
  );

  const taskSectionIndex =
    sectionIndex !== undefined ? sectionIndex : currentTaskSectionIndex;
  const { openTaskKeyList, currentTaskKey } =
    (taskSectionState || [])[taskSectionIndex] || {};

  const resetTempTaskData = useResetRecoilState(taskDataAtom);

  const removeTab = () => {
    if (currentTaskKey === taskKey && openTaskKeyList.length === 1) {
      //현재 section에 탭이 하나인 경우
      setCurrentTaskSectionIndex(Math.max(0, taskSectionIndex - 1));

      setTaskSectionState((taskSectionState) => {
        if (taskSectionState) {
          const copy = _.cloneDeep(taskSectionState);
          copy.splice(taskSectionIndex, 1);
          return copy;
        }
        return taskSectionState;
      });
    } else {
      if (openTaskKeyList?.length > 0) {
        const currentIdx = openTaskKeyList.findIndex(
          (element) => element === currentTaskKey
        );

        setTaskSectionState((taskSectionState) => {
          if (taskSectionState) {
            const copy = _.cloneDeep(taskSectionState);
            copy[taskSectionIndex] = {
              openTaskKeyList: openTaskKeyList.filter(
                (element) => element !== taskKey
              ),
              currentTaskKey:
                currentTaskKey === taskKey
                  ? currentIdx < openTaskKeyList.length - 1
                    ? openTaskKeyList[currentIdx + 1]
                    : openTaskKeyList[currentIdx - 1]
                  : currentTaskKey,
            };
            return copy;
          }
          return taskSectionState;
        });
      }
    }
    resetTempTaskData();
  };

  const goBack = useGoBack();

  return { removeTab: isLatest ? goBack : removeTab };
}
