export const translation_6_3 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "이제 주의력 훈련을 해봅시다.",
      typography_1_0_0: "방법은 간단합니다.",
      typography_2_0_0: "눈을 감고 호흡에 집중하는 겁니다.",
      typography_3_0_0: "숨이 코와 입을 통해 드나드는 모습을 관찰합니다.",
      typography_4_0_0:
        "들숨과 날숨이 오갈 때 나의 가슴과 배가 부풀었다가 다시 작아지는 모습을 관찰합니다.",
      typography_5_0_0: "이전에 했던 10까지 숫자세기와 같습니다.",
      typography_6_0_0:
        "허나 다른 점은 숫자는 세지 않을 겁니다. 어차피 몇 세지 못하고 금방 주의를 다른 곳에 빼앗길 거니까요.",
      typography_7_0_0:
        "거듭 강조하지만, 주의를 다른 곳에 빼앗기는 것은 너무 자연스러운 현상입니다.",
      typography_8_0_0: "우리가 해야 할 것은 단 하나, ",
      typography_9_0_0:
        "그렇게 주의를 다른 곳에 빼앗겼다면 그 순간을 알아차리고 다시 호흡으로 주의를 돌리는 겁니다.",
      typography_10_0_0:
        "주의가 백 번 흐트러졌으면, 다시 백 번이고 옮겨두면 됩니다.",
      typography_11_0_0:
        "횟수가 중요한 것도, 얼마나 집중하느냐도 중요한 것이 아닙니다.",
      typography_12_0_0: "자책하거나 좌절하거나 스트레스 받을 필요가 없습니다.",
      typography_13_0_0:
        "주의가 흐트러졌음을 알아차리고, 다시 주의를 내가 원하는 곳으로 돌려놓는 그 과정이 중요한 것입니다.",
      typography_14_0_0: "우선 오늘은 첫 번째 연습으로 1분만 진행해볼 건데요.",
      typography_15_0_0: "지금 조용하고 편안한 공간에서 시간을 낼 수 있나요?",
      typography_16_0_0: "주의력 훈련을 시작하겠습니다.",
      typography_17_0_0:
        "주의를 호흡에 두세요. 시간이 다 되면 진동이나 소리로 알려드릴게요.",
      typography_18_0_0:
        "고생하셨습니다. 어떠했는지 코치 선생님과 소감을 나누어도 좋을 것 같네요.",
      typography_19_0_0: "더 나아가기",
      typography_20_0_0:
        "앞으로 <주의통제 훈련소>에서 주의력 훈련을 꾸준히 할 수 있습니다.",
      typography_21_0_0:
        "처음에는 5분도 버거울 수 있지만, 점차 더 하고 싶은 마음이 들기 시작할 겁니다.",
      typography_22_0_0:
        "그때가 되면 시간을 10분, 15분, 20분으로 점차 늘려 나가보세요.",
      typography_23_0_0: "내가 느끼는 것은 '나'가 아님을,",
      typography_24_0_0:
        "'나'는 나의 마음속에서 떠오르는 것을 느끼고 관찰하는 주체라는 사실을 꼭 기억하고 훈련이 임해주세요.",
    },
  },
};
