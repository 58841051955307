import { useMutation, useQueryClient } from "@tanstack/react-query";
import { automaticThoughtApi } from "api/automaticThoughtApi";
import { automaticThoughtIdType, conceptApi } from "api/conceptApi";
import { DASHBOARD_DETAIL_ENDPOINT, DASHBOARD_DOMAIN } from "api/dashboardApi";
import { ProgramContentType } from "data/BlockComponent";
import { getReferenceData } from "logic/logics";
import { useCallback } from "react";

export default function useSubmitImpactScore({
  taskKey,
  data,
  automaticThoughtId,
}: {
  taskKey: string;
  data: ProgramContentType[];
  automaticThoughtId?: automaticThoughtIdType;
}) {
  const queryClient = useQueryClient();
  const impaceScore = getReferenceData(data || [], "영향받는 정도")?.value;
  const impaceScore_intermediateBelief = getReferenceData(
    data || [],
    "영향받는 정도_intermediateBelief"
  )?.value;
  const impaceScore_coreBelief = getReferenceData(
    data || [],
    "영향받는 정도_coreBelief"
  )?.value;

  const submitImpactScoreQueryFn_automaticThought = useCallback(
    () =>
      automaticThoughtApi.submitImpactScore({
        automaticThoughtSeq: parseInt(automaticThoughtId || "1"),
        impactScore: parseInt(impaceScore || ""),
        taskKey: taskKey,
      }),
    [data]
  );

  const { mutate: submitImpactScore_automaticThought } = useMutation(
    submitImpactScoreQueryFn_automaticThought,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          DASHBOARD_DOMAIN,
          DASHBOARD_DETAIL_ENDPOINT,
        ]);
      },
    }
  );

  const submitImpactScoreQueryFn_concept = useCallback(
    () =>
      conceptApi.submitImpactScore({
        ...(impaceScore_intermediateBelief && {
          intermediateBeliefImpactScore: parseInt(
            impaceScore_intermediateBelief
          ),
        }),
        ...(impaceScore_coreBelief && {
          coreBeliefImpactScore: parseInt(impaceScore_coreBelief),
        }),
      }),
    [data]
  );

  const { mutate: submitImpactScore_concept } = useMutation(
    submitImpactScoreQueryFn_concept,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          DASHBOARD_DOMAIN,
          DASHBOARD_DETAIL_ENDPOINT,
        ]);
      },
    }
  );

  const submit = () => {
    if (impaceScore && automaticThoughtId) {
      submitImpactScore_automaticThought();
    } else if (impaceScore_intermediateBelief || impaceScore_coreBelief) {
      submitImpactScore_concept();
    }
  };

  return submit;
}
