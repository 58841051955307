import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useColorScheme } from "@mui/joy";
import { useColorScheme as useMaterialColorTheme } from "@mui/material/styles";

export default function ThoughtRecordContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const { setMode } = useColorScheme();
  const { setMode: setMaterialMode } = useMaterialColorTheme();

  useEffect(() => {
    setMode("light");
    setMaterialMode("light");
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "#F4F0E8";
    const setThemeColor = (color: string) => {
      let themeColorMetaTag = document.querySelector(
        'meta[name="theme-color"]'
      );
      if (!themeColorMetaTag) {
        themeColorMetaTag = document.createElement("meta");
        themeColorMetaTag.setAttribute("name", "theme-color");
        document.head.appendChild(themeColorMetaTag);
      }
      themeColorMetaTag.setAttribute("content", color);
    };

    setThemeColor("#F4F0E8");

    return () => setThemeColor("#E2E6E8");
  }, []);

  const [viewportSize, setViewportSize] = useState(
    window.visualViewport && window.visualViewport.height
  );

  useEffect(() => {
    window.visualViewport &&
      window.visualViewport.addEventListener("resize", handleResize);
    return () => {
      window.visualViewport &&
        window.visualViewport.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setViewportSize(window.visualViewport && window.visualViewport.height);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [window.scrollY]);

  return (
    <motion.div
      animate={{
        height: `${viewportSize}px`,
      }}
      transition={{ duration: 0.2, delay: 0 }}
      className={`w-screen`}
      style={{
        width: "100vw",
        height: `${viewportSize}px`,
        position: "fixed",
        top: 0,
        backgroundColor: "#F4F0E8",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </motion.div>
  );
}
