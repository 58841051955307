export const translation_4_3_2_A = {
  en: {
    v1: {
      typography_0_0_0: "Do you remember how distancing works?",
      typography_1_0_0:
        "It's based on realizing that our thoughts are just thoughts, not who we are.",
      typography_2_0_0: "First, we notice thoughts popping up in our minds.",
      typography_3_0_0:
        "Second, we create some space from these thoughts, and don't react too much.",
      typography_4_0_0:
        "Third, we fill this space with choices that reflect our values.",
      typography_5_0_0: "Let's think more about 'thoughts are not me'.",
      typography_6_0_0: "Thoughts are not me",
      typography_7_0_0:
        'Imagine a thought: "Steve Jobs practiced distancing his whole life."',
      typography_8_0_0:
        "Does this thought feel like you? Or more like just a fact in your mind?",
      typography_9_0_0:
        'Now, think: "Why did I mess up again? Am I just silly?"',
      typography_10_0_0:
        "Does this feel like you? How's it different from the first thought?",
      typography_11_0_0:
        "Most people feel the second thought is closer to themselves.",
      typography_12_0_0: "Some thoughts feel more like part of us,",
      typography_13_0_0:
        "and can make distancing hard, causing negative feelings.",
      typography_14_0_0: "We call these 'unhelpful thinking traps'.",
      typography_15_0_0:
        "There are three kinds: Judgment, Conceptualization, and Rumination.",
      typography_16_0_0: "The lemon right here",
      typography_17_0_0:
        "How do thoughts we can't separate from ourselves bother us?",
      typography_18_0_0: "Let's try something fun. Picture a lemon.",
      typography_19_0_0: "Read the word 'lemon',",
      typography_20_0_0:
        "and imagine its color, texture, smell. Write down a few things about it.",
      typography_21_0_0:
        "Now, imagine biting into a big slice of lemon. Taste it? Write down what it's like.",
      typography_22_0_0:
        "Did you drool thinking about the lemon? How did your body react?",
      typography_23_0_0: "You don't actually have a lemon,",
      typography_24_0_0: "but you might taste it and your mouth might water.",
      typography_25_0_0: "This shows how powerful language can be.",
      typography_26_0_0:
        "Even though we didn't eat anything sour, the word 'lemon' affects us.",
      typography_27_0_0:
        "Our minds quickly give meanings to words, making them seem real.",
      typography_28_0_0: "The lemon as just a word",
      typography_29_0_0: "Let's reduce this effect for a bit.",
      typography_30_0_0:
        "We'll do an exercise that seems silly but is important.",
      typography_31_0_0: "Find a quiet place.",
      typography_32_0_0:
        "Start a timer and say 'lemon' loudly and quickly for 30 seconds.",
      typography_33_0_0: "Stay focused for 30 seconds. Ready? Go.",
      typography_34_0_0: "Done? Be sure to try it.",
      typography_35_0_0: "Now, think of 'lemon' again. What happens?",
      typography_36_0_0: "Do you taste it? Does your mouth water?",
      typography_37_0_0:
        "Probably not. Saying 'lemon' over and over makes it just a sound.",
      typography_38_0_0:
        "During this, the word 'lemon' loses its special meaning.",
      typography_39_0_0: `The word "lemon" has lost its symbolic meaning and only the peel remains.`,
      typography_40_0_0:
        "It's hard to notice this when we're used to giving words meanings.",
      typography_41_0_0: "Thoughts that make us drool",
      typography_42_0_0: "Write about a recent bad experience.",
      typography_43_0_0:
        "You probably felt bad remembering a past event, your thoughts, and actions.",
      typography_44_0_0: "Strange, right?",
      typography_45_0_0: "You're just looking at words on a screen,",
      typography_46_0_0: "but they make us feel things.",
      typography_47_0_0: "This shows how we react to words and their meanings.",
      typography_48_0_0:
        "So, past and future events can feel real in the present,",
      typography_49_0_0: "at least in our minds.",
      typography_50_0_0:
        "Now, turn your negative thought into one word. Shorter is better. (Example: silly, worried, sad, lazy)",
      typography_51_0_0: "How much does this word affect you?",
      typography_52_0_0:
        "Let's do the same exercise. It might seem silly, but it's helpful.",
      typography_53_0_0: "Find a quiet spot.",
      typographyFromData_54_0_0:
        "Start a timer and say '$A$' loudly and quickly for 30 seconds.",
      typography_55_0_0: "Stay focused for 30 seconds. Ready? Go.",
      typography_56_0_0: "Done? Make sure you try it.",
      typographyFromData_57_0_0: "Did the word '$A$' affect you the same?",
      typography_58_0_0: "How much does it affect you now? ",
      typography_59_0_0:
        "Like the lemon, we know the meaning, but the feeling lessens.",
      typography_60_0_0: "Stepping back from unhelpful thoughts",
      typography_61_0_0:
        "What if we saw thoughts as just thoughts, like the word 'lemon'?",
      typography_62_0_0:
        "If we distance ourselves from thoughts, we can lessen their impact.",
      typography_63_0_0:
        "We can choose how to react to the thoughts that keep coming.",
      typography_64_0_0:
        "Like 'lemon' is just a word, thoughts are just events in our minds.",
      typography_65_0_0: "But it's not always easy to apply this,",
      typography_66_0_0: "especially with unhelpful thinking traps.",
      typography_67_0_0:
        "These traps can limit our thinking and create bad feelings and actions.",
      typography_68_0_0:
        "We'll look at our unhelpful thoughts, understand why they're unhelpful, and how they bother us.",
      typography_69_0_0:
        "When these thoughts come up, we'll learn to notice them and step back.",
      typography_70_0_0: "Let's check out our unhelpful thoughts, okay?",
    },
  },
  ko: {
    v1: {
      typography_0_0_0: "거리두기가 어떤 원리로 진행되는지 기억하시나요?",
      typography_1_0_0:
        "생각은 생각일 뿐 '나' 자신이 아니라는 것에 대한 이해를 토대로",
      typography_2_0_0: "마음속에 떠오르는 생각을 알아차리는 것이 첫째,",
      typography_3_0_0:
        "그렇게 포착한 생각과 거리를 두며, 그것에 과하게 반응하지 않는 것이 둘째,",
      typography_4_0_0:
        "그리고 그렇게 확보된 공간에 나의 가치에 맞는 선택들로 채워 넣는 것이 셋째였어요.",
      typography_5_0_0:
        "우리는 여기서 '생각은 나 자신이 아니다'라는 말에 대해 조금 더 살펴보죠.",
      typography_6_0_0: "생각은 나 자신이 아니다",
      typography_7_0_0:
        '만약 여러분의 마음속에 "스티브 잡스는 평생에 걸쳐 거리두기를 연습했다"라는 생각이 떠올랐다고 가정해보세요.',
      typography_8_0_0:
        "그 생각은 여러분 자신과 동일하게 느껴지나요? 아니면 나와 밀접하게 관련이 있기보다는 그냥 마음속에 떠오른 어떠한 '사실'처럼 느껴지나요?",
      typography_9_0_0:
        '좋아요. 이번에는 여러분의 마음속에 "나는 왜 또 실수를 했을까? 난 왜 이렇게 멍청하지?"라는 생각이 떠올랐다고 가정해보세요.',
      typography_10_0_0:
        "그 생각은 여러분 자신과 동일하게 느껴지나요? 앞서 살펴보았던 다른 생각과 비교해보면 어떤가요?",
      typography_11_0_0:
        "이 질문에서 대부분의 사람들이 두 번째 생각이 조금 더 자신과 밀접한 관련이 있다고 이야기해요.",
      typography_12_0_0:
        "이처럼 생각 중에는 '나 자신'과 더 동일시하기 쉬운 생각들이 있는데요.",
      typography_13_0_0:
        "이런 생각들은 종종 우리가 생각으로부터 거리를 두는 것을 방해하고 부정적인 감정을 불러일으키곤 해요.",
      typography_14_0_0:
        "그러한 유형의 생각들을 우리는 '도움이 되지 않는 생각' 유형의 생각함정이라고 부른답니다.",
      typography_15_0_0:
        '이 유형의 생각함정에는 "평가", "개념화", "반추", 세 가지가 있답니다.',
      typography_16_0_0: "지금 여기 실재하는 레몬",
      typography_17_0_0:
        "좀처럼 우리 자신과 분리하기 어려운 생각들은 어떻게 우리를 괴롭히는 걸까요?",
      typography_18_0_0:
        "한 가지 재미있는 활동을 해볼게요. 과일 '레몬'을 떠올려보시겠어요?",
      typography_19_0_0: "자, '레몬'이라는 단어를 읽고,",
      typography_20_0_0:
        "레몬의 색, 질감, 냄새 등, 레몬의 속성을 몇 가지 적어볼까요?",
      typography_21_0_0:
        "좋아요. 이번엔 레몬을 한 입 크게 베어 먹었다고 생각해볼게요. 맛이 느껴지나요? 그렇다면 무슨 맛처럼 느껴지는지 적어보세요.",
      typography_22_0_0:
        "레몬을 베어 먹는 상상을 했을 때 입 안에서 침이 고이진 않았나요? 그러한 상상을 할 때 나의 몸은 어떻게 반응했나요?",
      typography_23_0_0: "좋아요. 지금 입 안에 레몬이 있는 건 아니에요.",
      typography_24_0_0:
        "하지만 그럼에도 여러분은 아마도 그 맛을 느낄 수 있었을 겁니다. 그 상상에 반응해 침샘에서 침이 분비되는 경험을 했을 거예요.",
      typography_25_0_0:
        "이는 인간의 언어가 매우 상징적인 효과를 지니고 있기 때문이에요.",
      typography_26_0_0:
        "우리는 신맛이 있는 어떤 과일을 직접 먹지 않았지만, '레몬'이라는 언어가 가지고 있는 상징적 효과에 반응하게 되죠.",
      typography_27_0_0:
        "우리의 마음은 이처럼 아주 빠르게 어떠한 언어적 표현에 대해 상징적 의미를 부과하는 경향을 지녔어요. 그것이 지금 여기에 마치 실재하는 것처럼 말이죠.",
      typography_28_0_0: "껍데기가 되어버린 레몬",
      typography_29_0_0:
        "잠시 이 상징적인 효과를 일시적으로 줄여보도록 할게요.",
      typography_30_0_0:
        "또 다른 연습을 하나 더 해 볼 텐데요. 바보 같아 보이는 연습이지만 충분히 검증된 중요한 연습이니 진지하게 임해보시길 바랄게요.",
      typography_31_0_0: "자, 우선 방해받지 않을 조용한 장소를 찾아보세요.",
      typography_32_0_0:
        "이제 스톱워치를 켜고, '레몬'이라는 단어를 30초 동안, '빨리', '크게 소리 내어' 말해볼 거예요.",
      typography_33_0_0:
        "금세 지루해질 수도 있지만, 집중력을 놓지 말고, 딱 30초만 지속해보세요. 자, 시작해볼까요?",
      typography_34_0_0: "완료했나요? 꼭 실제로 해 본 후 넘어가셔야 해요.",
      typography_35_0_0:
        "자, '레몬'이라는 단어를 다시 살펴보세요. 무슨 일이 벌어지나요?",
      typography_36_0_0:
        "이전처럼 동일한 맛이 느껴졌나요? 침샘에서 침이 흘러나왔나요?",
      typography_37_0_0:
        "아마 그렇지는 않았을 겁니다. 30초 동안 정말로 반복했다면, 스스로가 허무맹랑한 단어를 무의미하고 지루한 표정으로 내뱉고 있었다는 걸 알 수 있을 거예요.",
      typography_38_0_0:
        "이 연습을 하는 동안 '레몬'이라는 단어의 상징적 효과는 일시적으로 사라지기 시작해요.",
      typography_39_0_0:
        "'레몬'이라는 단어는 상징적인 의미를 잃고 껍데기가 되어버렸네요.",
      typography_40_0_0:
        "하지만 우리가 그 상징 속에서 헤엄을 치고 있는 동안에는 '레몬'이라는 단어가 그저 소리뿐일 수 있음을 알아차리기란 매우 어려운 일이죠.",
      typography_41_0_0: "침샘을 자극하는 나의 생각",
      typography_42_0_0: "최근에 느꼈던 부정적인 경험을 떠올려 적어보세요.",
      typography_43_0_0:
        "아마 과거의 한 상황과 그때 했던 생각과 행동을 떠올리고는 부정적인 감정을 느끼기 시작했을 거예요.",
      typography_44_0_0: "그런데 이상하지 않나요?",
      typography_45_0_0:
        "여기서 벌어진 일이라고는 기껏해야 화면 위에 검은색 픽셀로 찍혀진 글자를 바라보고 있다는 것이 전부랍니다. 그 외 다른 일은 없었어요.",
      typography_46_0_0:
        "하지만 우리는 언어적으로 묘사된 이 화면 위의 픽셀에 상징적으로 반응하기 때문에, 기록을 읽기만 해도 어떤 반응이 유발되는 것이죠.",
      typography_47_0_0:
        "이러한 과정을 거쳐 우리의 생각들은 지금 이 순간에 실재하는 것처럼 생생하게 경험될 수 있어요.",
      typography_48_0_0:
        "이미 소멸해서 없어진 과거도, 무수한 가능성을 가진 여러 가능성 중 하나인 미래도, 현재에 존재하는 일이 되어버리는 거죠.",
      typography_49_0_0: "적어도 우리의 마음속에서는요.",
      typography_50_0_0:
        "이번엔 위에 떠올린 경험에서 자신에게 품었던 부정적인 생각을 한 가지 단어로 만들어보세요. 짧으면 짧을수록 좋아요. (예: 바보, 불안, 우울, 무기력 등)",
      typography_51_0_0: "이 단어는 나에게 얼마나 영향을 주고 있나요? ",
      typography_52_0_0:
        "다시 앞에서 했던 것과 똑같은 과정을 해보죠. 역시나 바보 같아 보이지만, 이는 매우 중요한 연습이니 진지하게 임해보시길 바라요.",
      typography_53_0_0: "자, 우선 방해받지 않을 조용한 장소를 찾아보세요.",
      typographyFromData_54_0_0:
        "이제 스톱워치를 켜고, '$A$'(이)라는 단어를 30초 동안, '빨리', '크게 소리 내어' 말해볼 거예요.",
      typography_55_0_0:
        "금세 지루해질 수도 있지만, 집중력을 놓지 말고, 딱 30초만 지속해보세요. 자, 시작해볼까요?",
      typography_56_0_0: "완료했나요? 꼭 실제로 해 본 후 넘어가셔야 해요.",
      typographyFromData_57_0_0:
        "위 과정을 진행하는 동안에는 '$A$'(이)라는 단어가 여러분에게 동일한 정서적 영향을 미쳤나요?",
      typography_58_0_0: "지금 이 단어는 나에게 얼마나 영향을 주고 있나요?",
      typography_59_0_0:
        "아마 레몬의 예와 비슷하게 여전히 우리는 그 생각의 의미를 알고 있지만, 그 생각으로 인한 정서적인 효과는 줄어들었음을 알 수 있었을 거예요.",
      typography_60_0_0: "도움이 되지 않는 생각으로부터 거리두기",
      typography_61_0_0:
        "만약 레몬을 그저 단어로 바라보았던 것처럼, 우리가 생각으로부터 거리를 두고 생각을 그저 생각으로 바라볼 수 있다면 어떨까요?",
      typography_62_0_0:
        "생각으로부터 거리를 둘 수 있다면 우리는 그 생각으로부터 받는 영향을 줄일 수 있어요.",
      typography_63_0_0:
        "그리고 마음속에 끊임없이 떠오르는 다양한 생각 중 어디에 반응할지 선택할 수 있죠.",
      typography_64_0_0:
        "레몬이라는 말이 그저 하나의 말일 뿐인 것처럼, 생각도 그저 마음속에 떠오른 하나의 심리적 사건일 뿐이에요.",
      typography_65_0_0:
        "문제는 이것을 이해하고 적용시키는 것이 마냥 쉽지만은 않다는 거예요.",
      typography_66_0_0:
        "특히 앞서 이야기했던 '도움이 되지 않는 생각'에 해당하는 생각함정들은 더욱이 그렇죠.",
      typography_67_0_0:
        "도움이 되지 않는 생각에 해당하는 생각함정들은 우리가 사고하는 방식을 제한해 부정적인 감정과 행동을 만들어낸답니다.",
      typography_68_0_0:
        "우리는 앞으로 내가 가진 도움이 되지 않는 생각을 살펴보고, 그것이 왜 도움이 되지 않으며 어떻게 우리를 괴롭히는지 이해해 볼 거랍니다.",
      typography_69_0_0:
        "일상에서 나에게 도움이 되지 않는 생각이 마음속에 떠오르는 순간, 그것을 인지하고 그로부터 거리를 둘 기회가 생길 거랍니다.",
      typography_70_0_0:
        "자, 그러면 내가 가진 도움이 되지 않는 생각을 살펴볼까요?",
    },
  },
};
