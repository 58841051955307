import { translation_reflectGoal } from "./reflectGoal";

export const translation_0_6_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "우린 현재 다음의 목표를 향해 나아가고 있어요.",

      ...translation_reflectGoal.ko.v1,
    },
  },
};
