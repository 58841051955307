import { Box, Stack } from "@mui/joy";
import { ThoughtmapDownArrow } from "./ThoughtMap";
import ThoughtMapCard from "./ThoughtMapCard";
import { ThoughtMapText } from "./ThoughtMapText";

export default function BeliefTemplete({
  title,
  data,
  placeholder,
  hasConnectionLine,
}: {
  title: string;
  data: { label: string; value: string }[];
  placeholder: string;
  hasConnectionLine?: boolean;
}) {
  return (
    <>
      {hasConnectionLine && <ThoughtmapDownArrow />}
      <ThoughtMapCard
        label={title}
        content={
          data && data.length > 0 ? (
            <Stack spacing={1}>
              {data.map(({ label, value }, index) => (
                <Stack key={`${label}`} spacing={"8px"}>
                  <ThoughtMapText text={label} dimmed color="primary.solid" />
                  <ThoughtMapText text={value} />
                </Stack>
              ))}
            </Stack>
          ) : (
            <ThoughtMapText text={placeholder} dimmed />
          )
        }
        dimmed={data.length === 0}
      />
    </>
  );
}
