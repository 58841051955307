import { Box, Stack, Typography } from "@mui/joy";
import { addAlpha } from "styles/hexOpacity";
import { Lottie } from "components/common/Lottie";
import Loading from "static/lottie/tool/thought-record/loading.json";

export default function Button({
  buttonText,
  startDecorator,
  endDecorator,
  onClick,
  size = "md",
  color = "black",
  disabled,
  isFullWidth,
  hasShadow,
  loading,
  isRound,
}: {
  buttonText: string;
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  onClick: () => void;
  size?: "sm" | "md";
  color?:
    | "black"
    | "gradient-green"
    | "transparent"
    | "white"
    | "gradient-orange";
  disabled?: boolean;
  isFullWidth?: boolean;
  hasShadow?: boolean;
  loading?: boolean;
  isRound?: boolean;
}) {
  return (
    <Stack
      sx={{
        ...(color === "black" && { backgroundColor: "#222222" }),
        ...(color === "white" && {
          backgroundColor: "white",
          boxShadow: `inset 0 0 0 1px ${addAlpha("#000000", 0.1)}`,
        }),
        ...(color === "gradient-green" && {
          background: "linear-gradient(96.03deg, #BCEEB1 0%, #51DC84 100%)",
        }),
        ...(color === "gradient-orange" && {
          background: "linear-gradient(135deg, #FCA15E 0%, #FD4808 100%)",
        }),
        ...(color === "transparent" && {
          background: "transparent",
          boxShadow: `inset 0 0 0 1px ${addAlpha("#ffffff", 0.3)}`,
        }),
        ...(size === "md" && { px: "24px", py: "16px", borderRadius: "16px" }),
        ...(size === "sm" && {
          px: "12px",
          ...(isRound && { pl: "14px" }),
          py: "8px",
          borderRadius: isRound ? "65px" : "8px",
        }),
        width: isFullWidth ? "100%" : "fit-content",
        cursor: "pointer",
        "&:hover": {
          opacity: 0.5,
        },
        ...((disabled || loading) && { opacity: 0.2, pointerEvents: "none" }),
        ...(hasShadow && {
          filter: `drop-shadow(0px 20px 40px ${addAlpha("#222222", 0.2)})`,
        }),
      }}
      onClick={onClick}
      justifyContent="center"
    >
      {loading ? (
        <Lottie src={Loading} containerWidth={"32px"} />
      ) : (
        <Typography
          sx={{
            ...(size === "md" && { fontSize: "16px", lineHeight: "18px" }),
            ...(size === "sm" && { fontSize: "14px" }),

            ...(color === "black" && { color: "white" }),
            ...(color === "gradient-green" && {
              color: "#222222",
            }),
            ...((color === "transparent" || color === "gradient-orange") && {
              color: "white",
            }),
            fontWeight: 600,
            mx: "auto",
          }}
          startDecorator={startDecorator}
          endDecorator={endDecorator}
        >
          {buttonText}
        </Typography>
      )}
    </Stack>
  );
}
