import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  conceptApi,
  CONCEPT_DOMAIN,
  CONCEPT_NOTE_DETAIL_ENDPOINT,
} from "api/conceptApi";

import ButtonWithModal from "components/common/ButtonWithModal";
import Link from "components/common/Link";
import * as _ from "lodash";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";

export default function DeleteAutomaticThoughtButton({
  automaticThoughtKey,
}: {
  automaticThoughtKey: string;
}) {
  const queryClient = useQueryClient();
  const patientState = useRecoilValue(patientAtom);

  const deleteAutomaticThoughtQueryFn = useCallback(
    () =>
      conceptApi.deleteAutomaticThought(
        `${patientState?.patientId}`,
        `${automaticThoughtKey}`
      ),
    [patientState?.patientId, automaticThoughtKey]
  );

  const { mutate: deleteAutomaticThought } = useMutation(
    deleteAutomaticThoughtQueryFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          CONCEPT_DOMAIN,
          CONCEPT_NOTE_DETAIL_ENDPOINT,
        ]);
      },
    }
  );

  return (
    <ButtonWithModal
      buttonText={""}
      size="lg"
      title={"이 자동적 사고를 삭제할까요?"}
      render={<Link text="삭제" opacity={1} textColor="primary.solid" />}
      actionButtonColor={"danger"}
      actionText={"삭제하기"}
      action={deleteAutomaticThought}
    />
  );
}
