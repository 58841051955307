import { useEffect, useState } from "react";
import { ProgramType } from "data/programData";
import * as _ from "lodash";
import { ProgramContentType } from "data/BlockComponent";
import useGetProgramData from "./useGetProgramData";
import { setDataWithEditorKeyAndTranslationVersion } from "./useGetBlankTaskData";
import get_succeededActivityRecord_RawData from "./get_succeededActivityRecord_RawData";

export default function useGetBlankActivityRecordData({
  ark,
  taskKey,
}: {
  ark?: string;
  taskKey?: string;
}) {
  const taskId = "101-1-3-T";
  const [data, setData] = useState<ProgramType>();

  const activityRecordTaskData = useGetProgramData(taskId);
  async function getRawData(taskKey?: string) {
    if (taskKey) {
      const rawData = await get_succeededActivityRecord_RawData(taskKey);
      return rawData;
    } else {
      const rawData = _.cloneDeep(activityRecordTaskData);
      return rawData as ProgramType;
    }
  }
  useEffect(() => {
    getRawData(taskKey).then((rawData) => {
      if (rawData) {
        (rawData.content as ProgramContentType[])[0].lines.push([
          {
            type: "temp",
            content: {
              id: `activityRecordKey`,
              value: ark,
            },
          },
        ]);
        setData(setDataWithEditorKeyAndTranslationVersion(rawData));
      }
    });
  }, [ark]);

  return data;
}
