import Qr_goMarket from "static/Qr_goMarket.png";
import Qr_goPayment from "static/Qr_goPayment.png";

export const deepLinkData = {
  install: {
    url: "https://distancing.onelink.me/DeUR/5kirgjpp",
    qr: Qr_goMarket,
  },
  payment: {
    url: "https://distancing.onelink.me/DeUR/i2640w1c",
    qr: Qr_goPayment,
  },
};
