export const translation_5_2 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "자, 우리는 생각과 관계를 다시 맺기 위해, 생각을 생각으로 바라봐야 합니다.",
      typography_1_0_0:
        "생각에서 보는 것(Look from thought)이 아닌, 생각을 보아야(Look at thought) 합니다.",
      typography_2_0_0:
        "그러려면 생각과 거리를 두어 충분한 공간이 확보되어야 합니다.",
      typography_3_0_0:
        "하지만 마음속에서 생각이 꾸러미로 떠오르는 바람에, 우린 어떤 것이 생각인지 분간해낼 수 없습니다.",
      typography_4_0_0:
        "따라서 우리는 꾸러미를 각각의 개별 요소로 해체하여 바라보는 능력을 길러야 합니다.",
      typography_5_0_0: "분리하기",
      typography_6_0_0:
        "이처럼 부정적인 내적 경험을 개별 요소로 해체하여 분석하는 과정을 '분리하기(segmentation)'라고 부릅니다.",
      typography_7_0_0: "분리하기는 내면을 다루는 가장 기본적인 능력입니다.",
      typography_8_0_0: "방법은 간단합니다.",
      typography_9_0_0: "떠오른 내적 경험이 부정적이든, 긍정적이든,",
      typography_10_0_0: "앞으로 강렬한 심리적 경험을 마주하게 되는 순간이면",
      typography_11_0_0: "꾸러미를 개별 요소들로 분리해보는 것입니다.",
      typography_12_0_0:
        "이 개별 요소들에는 앞서 언급한 생각, 감정, 감각, 행동 외에 한 가지 요소가 더 포함됩니다.",
      typography_13_0_0: "바로 상황인데요.",
      typography_14_0_0:
        "사실 우리 내면에서 벌어지는 일들은 거의 대부분 특정 상황에 반응해서 생겨납니다.",
      typography_15_0_0: "객관적인 상황'에 '주관적으로 반응'하는 것이죠.",
      typography_16_0_0:
        "이 '주관적 반응'에는 생각, 감정, 감각, 행동이 포함되는 것이고요.",
      typography_17_0_0:
        "따라서 꾸러미를 해체하기 위해서는 객관적인 상황과 주관적인 반응을 잘 분리해야 합니다.",
      typography_18_0_0:
        "앞으로 우리는 분리하기 연습을 꾸준히 해나갈 것인데요.",
      typography_19_0_0: "그 첫 번째 연습을 지금 시작하려 합니다.",
    },
  },
};
