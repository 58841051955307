import { useEffect, useState } from "react";
import { Divider, List, Stack, Typography } from "@mui/joy";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { SentimentType, ThoughtRecordType } from "api/thoughtRecordApi";
import ThoughtRecordListItem from "./ThoughtRecordListItem";
import AddNewThoughtRecordButton from "./AddNewThoughtRecordButton";
import ThoughtRecordListTabs from "./ThoughtRecordListTabs";
import { taskListAtom } from "recoil/taskListAtom";
import useIsInappWebview from "hooks/useIsInappWebview";

export default function ThoughtRecordList({
  thoughtRecordList,
  currentThoughtRecord,
  setCurrentThoughtRecord,
  hasAiCommentTrkList,
}: {
  thoughtRecordList?: ThoughtRecordType[];
  currentThoughtRecord?: ThoughtRecordType;
  setCurrentThoughtRecord: React.Dispatch<
    React.SetStateAction<ThoughtRecordType | undefined>
  >;
  hasAiCommentTrkList: string[];
}) {
  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";
  // const { isTaskContainerSmall } = useRecoilValue(layoutAtom);
  const isInappWebview = useIsInappWebview();
  const isTaskContainerSmall = isInappWebview;
  const [listData, setListData] = useState<ThoughtRecordType[] | undefined>(
    thoughtRecordList
  );

  const [existTabList, setExistTabList] = useState<SentimentType[]>([
    "negative",
    "positive",
  ]);
  const [selectedTab, setSelectedTab] = useState<SentimentType>("negative");
  useEffect(() => {
    if (currentThoughtRecord?.sentiment) {
      setSelectedTab(currentThoughtRecord.sentiment);
    }
  }, [currentThoughtRecord]);

  const [currentTabListData, setCurrentTabListData] = useState<
    ThoughtRecordType[] | undefined
  >(thoughtRecordList?.filter((element) => element.sentiment === selectedTab));

  const taskListState = useRecoilValue(taskListAtom);
  const isPositiveAvailable = taskListState.some(
    (element) => ["7-1-A"].includes(element.taskId) && element.lastDoneAt
  );
  useEffect(() => {
    setListData(thoughtRecordList);
  }, [thoughtRecordList]);

  useEffect(() => {
    if (hasAiCommentTrkList && hasAiCommentTrkList.length > 0) {
      setListData((listData) =>
        (listData || []).map((each) => ({
          ...each,
          hasAiRecommendation: hasAiCommentTrkList.includes(
            each.thoughtRecordKey
          ),
        }))
      );
    }
  }, [hasAiCommentTrkList]);

  useEffect(() => {
    setCurrentTabListData(
      listData?.filter(
        (element) => !element.sentiment || element.sentiment === selectedTab
      )
    );
  }, [selectedTab, listData]);

  return (
    <Stack
      alignItems="center"
      sx={{
        flexShrink: 0,
        width: isTaskContainerSmall ? "100%" : "300px",
        height: isTaskContainerSmall ? "fit-content" : "100%",
        // overflow: "scroll",
        transition: "transform 0.4s, width 0.4s",
        scrollPaddingBlockStart: "20px",
        ...(isTaskContainerSmall ? { pb: "20px" } : { pl: "20px" }),
      }}
      className="scrollbar-hide"
    >
      <Stack
        direction={isTaskContainerSmall ? "row" : "column"}
        justifyContent={"center"}
        spacing={isTaskContainerSmall ? "8px" : "16px"}
        divider={
          <Divider
            orientation={isTaskContainerSmall ? "vertical" : "horizontal"}
          />
        }
        sx={{
          width: "100%",

          ...(isTaskContainerSmall
            ? {
                px: "20px",
                height: "36px",
                mb: "16px",
              }
            : {
                flexShrink: 0,
                zIndex: 10,
                position: "sticky",
                pt: "20px",
                top: 0,
                pb: "16px",
              }),
        }}
      >
        <Stack
          sx={{ flexShrink: 0, width: isTaskContainerSmall ? "50px" : "100%" }}
        >
          <AddNewThoughtRecordButton
            disabled={
              isCoach ||
              ((listData || []).length === 1 &&
                !listData?.every((element) => element.lastDoneAt))
            }
          />
        </Stack>
        {existTabList && existTabList.length > 1 && (
          <ThoughtRecordListTabs
            selectedTab={selectedTab}
            data={[
              {
                id: "negative",
                label: "부정",
                onClick: () => {
                  setSelectedTab("negative");
                },
              },
              {
                id: "positive",
                label: "긍정",
                onClick: () => {
                  setSelectedTab("positive");
                },
              },
            ]}
          />
        )}
      </Stack>

      <List
        orientation={isTaskContainerSmall ? "horizontal" : "vertical"}
        sx={{
          overflow: "scroll",
          width: "100%",
          "--ListItem-paddingY": 0,
          "--ListItem-paddingX": 0,
        }}
      >
        {currentTabListData && currentTabListData.length > 0 ? (
          currentTabListData.map((thoughtRecord, index) => {
            return (
              <ThoughtRecordListItem
                key={thoughtRecord.thoughtRecordKey}
                width={isTaskContainerSmall ? "50px" : "100%"}
                direction={isTaskContainerSmall ? "row" : "column"}
                isFirstRecordOfMonth={
                  index === 0 ||
                  (index - 1 >= 0 &&
                    dayjs(currentTabListData[index - 1].date).get("month") !==
                      dayjs(currentTabListData[index].date).get("month"))
                }
                thoughtRecord={thoughtRecord}
                isSelected={
                  currentThoughtRecord?.thoughtRecordKey ===
                  thoughtRecord.thoughtRecordKey
                }
                setCurrentThoughtRecord={setCurrentThoughtRecord}
                shouldBeDone={!isCoach && thoughtRecord.shouldBeDone}
              />
            );
          })
        ) : (
          <>
            <Typography
              sx={{
                width: "100%",
                fontSize: "14px",
                opacity: 0.6,
                textAlign: "center",
              }}
            >
              {selectedTab === "positive"
                ? isPositiveAvailable
                  ? "작성한 기록이 없어요."
                  : "아직 배우지 않았어요. 부정 기록에 집중해주세요."
                : "작성한 기록이 없어요."}
            </Typography>
          </>
        )}
        {/* <Box
          sx={{ height: "1500px", width: "100px", backgroundColor: "#000000" }}
        /> */}
      </List>
    </Stack>
  );
}
