import React from "react";
import { Box, Button, Stack, Typography } from "@mui/joy";
import TitleWithSubtitle from "components/common/TitleWithSubtitle";
import ProgressBar from "components/common/ProgressBar";
import FullPageContainer from "../container/FullPageContainer";
import { theme } from "styles/theme";
import { extractHexColor } from "styles/hexOpacity";
import guarantee_mark from "../../static/image/onboarding/guarantee_mark.png";
import TypographyWithHighlight from "../../components/common/TypographyWithHighlight";

export default function FullPageWithTitleAndButton({
  title,
  buttonDisabled,
  buttonClickAction,
  progress = 0,
}: {
  title: string;
  buttonDisabled?: boolean;
  buttonClickAction: () => void;
  progress?: number;
}) {
  const backgroundColor = extractHexColor(theme.vars.palette.background.level1);

  return (
    <FullPageContainer backgroundColor={backgroundColor}>
      <Stack
        direction={"column"}
        alignItems="center"
        justifyContent={"center"}
        spacing={2}
        sx={{
          maxWidth: "400px",
          mx: "auto",
          height: "100%",
        }}
      >
        <Stack sx={{ width: "100%", pb: "20px", px: "20px" }}>
          <ProgressBar progress={progress} />
        </Stack>

        <Stack
          direction="column"
          justifyContent={"center"}
          sx={{ flex: 0.2, pb: "20px" }}
        >
          <img src={guarantee_mark} alt={"onboarding"} width={150} />
        </Stack>

        <Stack sx={{ px: "20px", position: "relative", zIndex: 100 }}>
          <TitleWithSubtitle title={title} sx={{ textAlign: "center" }} />
          <Box
            sx={{
              position: "absolute",
              bottom: -20,
              width: "100%",
              height: "20px",
              background: `linear-gradient(0deg, ${backgroundColor}00 0%, ${backgroundColor} 100%)`,
            }}
          />
        </Stack>
        <Stack
          sx={{
            pt: "20px",
            width: "100%",
            flex: 1,
            overflow: "auto",
            px: "20px",
            position: "relative",
            textAlign: "center",
          }}
        >
          <TypographyWithHighlight
            text={
              "디스턴싱은 효과가 검증된 프로그램입니다.\n" +
              "7주만 이용해도 우울, 불안, 스트레스를\n" +
              "각각 34%, 40%, 23% 낮출 수 있습니다.\n\n" +
              "3개월 이용 후에도 결과에 만족하지 않으신다면\n" +
              "지불하신 비용 100% 전액을 돌려드리겠습니다.\n\n" +
              "support@orwellhealth.org로 “환불 요청”\n" +
              "네 글자만 보내주세요."
            }
            highlightedText={"지불하신 비용 100% 전액"}
            customSx={{ color: "#525B66", fontWeight: 500, fontSize: 16 }}
            highlightedCustomSx={{ fontWeight: 700, color: "black" }}
          />
        </Stack>
        <Stack
          sx={{
            px: "20px",
            width: "100%",
            position: "relative",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: -20,
              width: "calc(100% - 40px)",
              height: "20px",
              background: `linear-gradient(180deg, ${backgroundColor}00 0%, ${backgroundColor} 100%)`,
            }}
          />
          <Typography
            fontWeight={700}
            fontSize={16}
            sx={{
              pb: "20px",
            }}
          >
            {
              "시도해서 잃을 것은 없습니다.\n시도하지 않으면 변화의 기회를 잃을 뿐입니다."
            }
          </Typography>
          <Button
            size="lg"
            sx={{ width: "100%" }}
            disabled={buttonDisabled}
            onClick={buttonClickAction}
          >
            무료 활동지 1개 해보기
          </Button>
        </Stack>
      </Stack>
    </FullPageContainer>
  );
}
