import { Box, Stack, SvgIcon, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import CustomCard from "components/common/CustomCard";
import { BehavioralActivationProgramProgressData } from "data/programData";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import { useRecoilState, useRecoilValue } from "recoil";
import { taskListAtom } from "recoil/taskListAtom";
import { TaskType } from "api";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { menuSelector } from "recoil/menuAtom";

function Status({ status }: { status: "done" | "ongoing" | "remaining" }) {
  return (
    <Box
      sx={{
        width: "40px",
        height: "40px",
        p: "4px",
        flexShrink: 0,
      }}
    >
      <Stack
        justifyContent={"center"}
        alignItems="center"
        sx={{
          width: "100%",
          height: "100%",
          p: "4px",
          backgroundColor: status === "remaining" ? "primary.soft" : undefined,
          borderRadius: "50%",
          borderColor: "primary.solid",
          borderWidth: status !== "remaining" ? "2px" : 0,
        }}
      >
        {status !== "remaining" &&
          (status === "ongoing" ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "primary.solid",
                borderRadius: "50%",
              }}
            ></Box>
          ) : (
            <>
              <svg
                width="12"
                height="11"
                viewBox="0 0 12 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.7L5.16667 9.5L11 1.5"
                  stroke="#479683"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </>
          ))}
      </Stack>
    </Box>
  );
}

function Description({
  title,
  subtitle,
  showInProgressText,
}: {
  title: string;
  subtitle: string;
  showInProgressText: boolean;
}) {
  return (
    <Stack direction={"column"}>
      {showInProgressText && (
        <Typography color="primary" sx={{ mb: "8px" }}>
          {"진행 중"}
        </Typography>
      )}
      <Typography level="body-md">{title}</Typography>
      <Typography sx={{ opacity: 0.5 }}>{subtitle}</Typography>
    </Stack>
  );
}

function Progressbar({ percentage }: { percentage: number }) {
  return (
    <Stack
      sx={{
        width: "40px",
        height: "24px",
      }}
      alignItems="center"
    >
      <Box
        sx={{
          width: "2px",
          height: "100%",
          borderRadius: "2px",
          backgroundColor: "primary.soft",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: `${percentage}%`,
            borderRadius: "2px",
            backgroundColor: "primary.solid",
          }}
        ></Box>
      </Box>
    </Stack>
  );
}

function getTaskIdPrefixList(taskList: TaskType[]) {
  return taskList
    .filter((element) => element.taskId !== "2-2-T")
    .map((each) => each.taskId.split("-").splice(0, 2).join("-"));
}

export default function BehavioralActivationProgress() {
  const [isProgrssOpen, setIsProgressOpen] = useRecoilState(
    menuSelector("progress")
  );
  const taskListState = useRecoilValue(taskListAtom);

  const [currentModuleNumber, setCurrentModuleNumber] = useState<number>();
  const [taskIdPrefixSetList, setTaskIdPrefixSetList] = useState<string[]>([]);
  useEffect(() => {
    const taskIdPrefixList: string[] = getTaskIdPrefixList(taskListState);
    const taskIdPrefixSetList = taskIdPrefixList.reduce(
      (accumulator: string[], currentValue) => {
        if (!accumulator.includes(currentValue)) {
          accumulator.push(currentValue);
        }
        return accumulator;
      },
      []
    );

    setTaskIdPrefixSetList(taskIdPrefixSetList);
    let currentModuleNumber = 1;
    for (let i = 0; i < taskIdPrefixSetList.length; i++) {
      const taskIdPrefix = taskIdPrefixSetList[i];
      for (let j = 0; j < BehavioralActivationProgramProgressData.length; j++) {
        if (
          BehavioralActivationProgramProgressData[j].taskIdPrefixList?.includes(
            taskIdPrefix
          )
        ) {
          const moduleNumber =
            BehavioralActivationProgramProgressData[j].moduleNumber;
          if (moduleNumber > currentModuleNumber)
            currentModuleNumber = moduleNumber;
          break;
        }
      }
    }

    setCurrentModuleNumber(currentModuleNumber);
  }, [taskListState]);

  return (
    <CustomCard sx={{ py: "16px", px: "20px" }}>
      {currentModuleNumber !== undefined &&
        BehavioralActivationProgramProgressData.map(
          ({ moduleNumber, title, subtitle, taskIdPrefixList }, index) => {
            const status =
              moduleNumber < currentModuleNumber
                ? "done"
                : moduleNumber === currentModuleNumber
                ? "ongoing"
                : "remaining";

            return (
              <motion.div
                key={`M${moduleNumber}`}
                initial={false}
                animate={{
                  height: !(isProgrssOpen || status === "ongoing")
                    ? 0
                    : "fit-content",
                  opacity: !(isProgrssOpen || status === "ongoing") ? 0 : 1,
                }}
              >
                <Stack direction={"column"}>
                  <Stack direction={"row"} spacing={"12px"} alignItems="center">
                    {isProgrssOpen && <Status status={status} />}
                    <Description
                      title={title}
                      subtitle={subtitle}
                      showInProgressText={
                        !isProgrssOpen && status === "ongoing"
                      }
                    />
                  </Stack>
                  {isProgrssOpen &&
                    index <
                      BehavioralActivationProgramProgressData.length - 1 && (
                      <>
                        <Progressbar
                          percentage={
                            status === "done"
                              ? 100
                              : status === "ongoing"
                              ? ((taskIdPrefixList.findLastIndex(
                                  (each) =>
                                    taskIdPrefixSetList.includes(each) &&
                                    taskListState.some(
                                      (element) =>
                                        element.taskId.startsWith(each) &&
                                        element.isDone
                                    )
                                ) +
                                  1) /
                                  taskIdPrefixList.length) *
                                100
                              : 0
                          }
                        />
                      </>
                    )}
                </Stack>
              </motion.div>
            );
          }
        )}
      <Stack
        sx={{
          borderTopWidth: "1px",
          borderColor: "background.level2",
          pt: "16px",
          mt: "16px",
        }}
        alignItems="center"
      >
        <Typography
          onClick={() => setIsProgressOpen((isProgrssOpen) => !isProgrssOpen)}
          sx={{ opacity: 0.5, cursor: "pointer" }}
          endDecorator={
            <SvgIcon>
              {isProgrssOpen ? (
                <ExpandLessRoundedIcon />
              ) : (
                <ExpandMoreRoundedIcon />
              )}
            </SvgIcon>
          }
        >
          {isProgrssOpen ? "접기" : "전체 진도 보기"}
        </Typography>
      </Stack>
    </CustomCard>
  );
}
