import {
  Header1,
  Instruction,
  InstructionWithImage,
  InstructionWithTextarea,
} from "../../BlockComponent";

import { LastCard } from "../common";

export const Content_4_4_6_A = [
  Instruction(`typography_0`),
  Instruction(`typography_1`),
  InstructionWithImage(`typography_2`, "0.png"),
  Instruction(`typography_3`),
  Instruction(`typography_4`),
  Instruction(`typography_5`),
  Instruction(`typography_6`),
  Instruction(`typography_7`),
  Instruction(`typography_8`),
  Instruction(`typography_9`),

  Header1(`typography_10`),
  Instruction(`typography_11`),
  Instruction(`typography_12`),
  Instruction(`typography_13`),
  Instruction(`typography_14`),
  Instruction(`typography_15`),
  Instruction(`typography_16`),
  Instruction(`typography_17`),
  Instruction(`typography_18`),
  Instruction(`typography_19`),
  Instruction(`typography_20`),
  Instruction(`typography_21`),

  Instruction(`typography_22`),
  Instruction(`typography_23`),
  Instruction(`typography_24`),
  Instruction(`typography_25`),
  Instruction(`typography_26`),

  Header1(`typography_27`),
  Instruction(`typography_28`),
  Instruction(`typography_29`),
  Instruction(`typography_30`),

  Instruction(`typography_31`),
  Instruction(`typography_32`),
  Instruction(`typography_33`),
  Instruction(`typography_34`),
  Instruction(`typography_35`),
  Instruction(`typography_36`),
  Instruction(`typography_37`),

  Header1(`typography_38`),
  Instruction(`typography_39`),
  Instruction(`typography_40`),
  Instruction(`typography_41`),
  Instruction(`typography_42`),
  Instruction(`typography_43`),
  Instruction(`typography_44`),
  Instruction(`typography_45`),
  Instruction(`typography_46`),
  Instruction(`typography_47`),

  Header1(`typography_48`),

  InstructionWithImage(`typography_49`, "3.png"),
  InstructionWithTextarea(`typography_50`),
  Instruction(`typography_51`),
  InstructionWithTextarea(`typography_52`),
  Instruction(`typography_53`),
  Instruction(`typography_54`),
  InstructionWithTextarea(`typography_55`),
  InstructionWithTextarea(`typography_56`),
  Instruction(`typography_57`),
  Instruction(`typography_58`),
  Instruction(`typography_59`),

  Header1(`typography_60`),
  Instruction(`typography_61`),
  InstructionWithImage(`typography_62`, "5.png"),
  InstructionWithImage(`typography_63`, "6.png"),
  InstructionWithImage(`typography_64`, "7.png"),
  Instruction(`typography_65`),
  Instruction(`typography_66`),
  Instruction(`typography_67`),
  Instruction(`typography_68`),
  Instruction(`typography_69`),
  Instruction(`typography_70`),
  Instruction(`typography_71`),
  Instruction(`typography_72`),
  Instruction(`typography_73`),

  ...LastCard,
];
