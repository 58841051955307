import { Dayjs } from "dayjs";
import { atom } from "recoil";

export interface ThoughtRecordToolType {
  accessToken?: string;
  date?: Dayjs;
}

export const thoughtRecordToolAtom = atom<ThoughtRecordToolType | undefined>({
  key: "thoughtRecord-tool",
  default: undefined,
});
