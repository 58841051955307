import { Box, Button, Stack } from "@mui/joy";
import { usePrevious } from "@uidotdev/usehooks";
import { HOST_URL } from "config";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "components/tool/self-distancing/ProgressBar";
import { v4 as uuidv4 } from "uuid";
import { Block } from "components/tool/self-distancing/Block";
import { selfDistancingApi } from "api/selfDistancingApi";
import { useMutation } from "@tanstack/react-query";

export default function Distancing({
  uuid,
  setThreadId,
}: {
  uuid: string;
  setThreadId: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const navigate = useNavigate();
  const qnaRefs = useRef<HTMLDivElement[]>([]);

  const [lastShownIndex, setLastShownIndex] = useState<number>(0);
  const [showContinueButton, setShowContinueButton] = useState<boolean>(false);

  const totalNumOfQuestion = 5;

  const [threadId] = useState<string>(uuidv4());

  useEffect(() => {
    console.log("threadId:", threadId);
    setThreadId(threadId);
  }, []);

  const [qnaList, setQnAList] = useState<
    { question: string; answer: string }[]
  >([{ question: "", answer: "" }]);
  const previoudQnAList = usePrevious(qnaList);

  useEffect(() => {
    setQnAList([{ question: "", answer: "" }]);
  }, []);

  const showNextQuestion = () => {
    setLastShownIndex((lastShownIndex) => lastShownIndex + 1);
  };

  function handleAnswerChange(index: number, value: string) {
    setQnAList((qnaList) => {
      return qnaList.map((item, i) => {
        if (i === index) {
          return { ...item, answer: value };
        }
        return item;
      });
    });
  }

  const moveToIndex = (index: number) => {
    qnaRefs.current[index].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const conversationDoneQueryFn = useCallback(
    () => selfDistancingApi.conversationDone(uuid, threadId),
    [uuid, threadId]
  );

  const { mutate: conversationDone } = useMutation(conversationDoneQueryFn, {
    onSuccess: () => {
      navigate("/tool/4");
    },
  });

  useEffect(() => {
    if (previoudQnAList && qnaList.length !== previoudQnAList.length) {
      moveToIndex(lastShownIndex);
    }
    if (qnaList.length > totalNumOfQuestion - 1) {
      if (qnaList[qnaList.length - 1].answer.trim().length > 0) {
        setShowContinueButton(true);
      } else {
        setShowContinueButton(false);
      }
    }
  }, [qnaList]);

  async function fetchStream(url: string, answer: string, question?: string) {
    const params = {
      uuid: uuid,
      thread_id: threadId,
    };

    const urlParams = new URLSearchParams(params);
    const response = await fetch(`${url}?${urlParams.toString()}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question: question,
        answer: answer,
      }),
    });
    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let result = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const data = decoder
          .decode(value)
          .replaceAll("data:", "")
          .replaceAll("\n", "");
        result += data;

        setQnAList((qnaList) => {
          if (lastShownIndex > qnaList.length - 1) {
            return [...qnaList, { question: data, answer: "" }];
          }
          return qnaList.map((item, i) => {
            if (i === lastShownIndex) {
              return { question: item.question + data, answer: "" };
            }
            return item;
          });
        });
      }

      return result;
    }
  }
  const fetchData = async (answer: string, question?: string) => {
    const data = await fetchStream(
      `${HOST_URL}/stream/self-distancing/conversation`,
      answer,
      question
    );
    return data;
  };
  const streamCalled = useRef(false);

  useEffect(() => {
    const emotion = window.localStorage.getItem("emotion");
    const situation = window.localStorage.getItem("situation");
    const thought = window.localStorage.getItem("thought");
    const ABC = Object.entries({
      감정: emotion,
      상황: situation,
      생각: thought,
    })
      .map(([key, value]) => `${key}:${value}`)
      .join("\n");
    if (lastShownIndex < totalNumOfQuestion) {
      if (lastShownIndex === 0) {
        if (streamCalled.current) return;
        fetchData(ABC);
        streamCalled.current = true;
      } else {
        fetchData(
          qnaList[lastShownIndex - 1].answer || "",
          qnaList[lastShownIndex - 1].question
        );
      }
    }
  }, [lastShownIndex]);

  return (
    <Stack
      sx={{
        width: "100%",
      }}
      alignItems="center"
    >
      <Stack sx={{ width: "100%", mb: "50px" }} direction="row" spacing={"3px"}>
        {[
          {
            label: "알아차리기",
            percentage: 100,
          },
          {
            label: "거리두기",
            percentage: ((lastShownIndex + 1) / totalNumOfQuestion) * 100,
          },
          { label: "기꺼이 경험하기", percentage: 0 },
        ].map(({ label, percentage }, index) => (
          <Box sx={{ flex: 1 }}>
            <ProgressBar label={label} percentage={percentage}></ProgressBar>
          </Box>
        ))}
      </Stack>
      <Stack
        direction={"column"}
        justifyContent="start"
        sx={{ width: "100%", pb: "100px" }}
        spacing="50px"
      >
        {qnaList.map(({ question, answer }, index) => (
          <Box sx={{ ...(index > lastShownIndex && { visibility: "hidden" }) }}>
            <Block
              ref={(el) => (qnaRefs.current[index] = el as HTMLDivElement)}
              type="textarea"
              question={question}
              handleChange={(value: string) => handleAnswerChange(index, value)}
              {...(index < totalNumOfQuestion - 1 && {
                onNextButtonClick: showNextQuestion,
              })}
              isNextButtonAvailable={
                lastShownIndex === index && (answer || "").trim().length > 0
              }
            />
          </Box>
        ))}
      </Stack>
      {showContinueButton && (
        <Button
          size="lg"
          sx={{
            backgroundColor: "#232832",
            width: "100%",
            position: "sticky",
            bottom: "20px",
            "&:hover": {
              backgroundColor: `#23283288`,
            },
          }}
          onClick={() => {
            conversationDone();
          }}
        >
          계속 진행하기
        </Button>
      )}
    </Stack>
  );
}
