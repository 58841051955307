import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";

export default function BottomSheet({
  backgrounColor,
  isVisible,
  setIsVisible,
  children,
}: {
  backgrounColor: string;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [{ y }, setY] = useSpring(() => ({ y: window.innerHeight }));

  useEffect(() => {
    setY({ y: isVisible ? 0 : window.innerHeight });
  }, [isVisible, setY]);

  const [windowHeight, setWindowHeight] = useState(
    window.visualViewport?.height
  );

  const handleResize = () => {
    if (window.visualViewport) {
      setWindowHeight(window.visualViewport.height);
      if (
        window.visualViewport.height < window.innerHeight &&
        contentRef.current
      ) {
        setTimeout(() => {
          contentRef.current?.scrollTo({
            top: contentRef.current?.scrollHeight,
            behavior: "smooth",
          });
        }, 0);
      }
    }
  };

  useEffect(() => {
    window.visualViewport &&
      window.visualViewport.addEventListener("resize", handleResize);
    return () => {
      window.visualViewport &&
        window.visualViewport.removeEventListener("resize", handleResize);
    };
  }, [isVisible, setY]);

  const bind = useDrag(
    ({ down, movement: [, my], velocity, direction: [, dy], cancel }) => {
      // if (down && my > window.innerHeight / 2) cancel();

      setY({
        y: down ? my : isVisible ? 0 : window.innerHeight,
        immediate: down,
        config: { tension: 300, friction: 30 },
      });

      if (!down) {
        if (velocity > 0.5 || my > window.innerHeight / 2) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      }
    },
    {
      filterTaps: true,
    }
  );

  return (
    <animated.div
      style={{
        y,
        backgroundColor: backgrounColor,
        overflow: "hidden",
        position: "fixed",
        left: 0,
        bottom: windowHeight ? window.innerHeight - windowHeight : 0,
        width: "100%",
        height: `${windowHeight}px`, // 동적으로 높이 조정
        // height: "100%",
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
        borderRadius: "20px 20px 0 0",
        touchAction: "none",
      }}
    >
      <animated.div {...bind()}>
        <div
          style={{
            width: "50px",
            height: "5px",
            backgroundColor: "white",
            opacity: 0.2,
            borderRadius: "5px",
            margin: "12px auto",
          }}
        ></div>
      </animated.div>
      <div
        ref={contentRef}
        style={{
          paddingTop: "4px",
          height: "100%",
          overflowY: "hidden",
          position: "relative",
        }}
      >
        {/* <div className="w-full h-[500px] bg-white opacity-[0.1]"></div> */}
        {children}
      </div>
    </animated.div>
  );
}
