import { TaskSectionType } from "recoil/taskSectionAtom";
import * as _ from "lodash";
import { SetterOrUpdater } from "recoil";

export function transferTaskToNewSection(
  taskKey: string,
  taskSectionState: TaskSectionType[],
  setTaskSectionState: SetterOrUpdater<TaskSectionType[] | undefined>,
  setCurrentTaskSectionIndex: SetterOrUpdater<number>,
  originalSectionIndex: number,
  newSectionIndex: number,
  newTaskIndex?: number
) {
  if (taskSectionState) {
    const originalTaskSectionState = taskSectionState[originalSectionIndex];
    const originalSectionTaskKey = originalTaskSectionState.currentTaskKey;
    const originalSectionOpenTaskKeyList =
      originalTaskSectionState.openTaskKeyList;

    const originalTaskIndex = originalSectionOpenTaskKeyList.findIndex(
      (element) => element === taskKey
    );

    const copy = _.cloneDeep(taskSectionState);
    copy[originalSectionIndex] = {
      openTaskKeyList: originalSectionOpenTaskKeyList.filter(
        (element) => element !== taskKey
      ),
      currentTaskKey:
        originalSectionTaskKey === taskKey
          ? originalTaskIndex < originalSectionOpenTaskKeyList.length - 1
            ? originalSectionOpenTaskKeyList[originalTaskIndex + 1]
            : originalSectionOpenTaskKeyList[originalTaskIndex - 1]
          : originalSectionTaskKey,
    };
    const insertIndex =
      newTaskIndex !== undefined
        ? newTaskIndex
        : taskSectionState[newSectionIndex].openTaskKeyList.length;
    copy[newSectionIndex] = {
      openTaskKeyList: !copy[newSectionIndex].openTaskKeyList.includes(taskKey)
        ? [
            ...copy[newSectionIndex].openTaskKeyList.slice(0, insertIndex),
            taskKey,
            ...copy[newSectionIndex].openTaskKeyList.slice(insertIndex),
          ]
        : copy[newSectionIndex].openTaskKeyList,
      currentTaskKey: taskKey,
    };
    setCurrentTaskSectionIndex(newSectionIndex);
    setTaskSectionState(copy);
  }
}

export function openTaskInNewTaskSection(
  taskKey: string,
  taskSectionState: TaskSectionType[],
  setTaskSectionState: SetterOrUpdater<TaskSectionType[] | undefined>,
  setCurrentTaskSectionIndex: SetterOrUpdater<number>,
  originalSectionIndex: number,
  newSectionIndex: number
) {
  if (taskSectionState) {
    const originalTaskSectionState = taskSectionState[originalSectionIndex];
    const originalSectionTaskKey = originalTaskSectionState.currentTaskKey;
    const originalSectionOpenTaskKeyList =
      originalTaskSectionState.openTaskKeyList;

    const originalTaskIndex = originalSectionOpenTaskKeyList.findIndex(
      (element) => element === taskKey
    );

    const copy = _.cloneDeep(taskSectionState);
    copy[originalSectionIndex] = {
      openTaskKeyList: originalSectionOpenTaskKeyList.filter(
        (element) => element !== taskKey
      ),
      currentTaskKey:
        originalSectionTaskKey === taskKey
          ? originalTaskIndex < originalSectionOpenTaskKeyList.length - 1
            ? originalSectionOpenTaskKeyList[originalTaskIndex + 1]
            : originalSectionOpenTaskKeyList[originalTaskIndex - 1]
          : originalSectionTaskKey,
    };
    copy.splice(newSectionIndex, 0, {
      openTaskKeyList: [taskKey],
      currentTaskKey: taskKey,
    });
    setCurrentTaskSectionIndex(newSectionIndex);
    setTaskSectionState(copy);
  }
}
