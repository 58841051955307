import { Stack, Typography } from "@mui/joy";
import useMixpanelWeb from "hooks/useMixpanelWeb";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import DefaultPage from "pages/container/DefaultPage";
import AppleLoginButton from "components/user/AppleLoginButton";
import GoogleLoginButton from "components/user/GoogleLoginButton";
import InstitutionStartButton from "components/user/InstitutionStartButton";
import character from "static/image/character/login_character.png";
import { isMobile } from "react-device-detect";

export default function Login() {
  let navigate = useNavigate();
  const user = useRecoilValue(userAtom);

  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  const isOnboarding = searchParams.get("type") === "1"; //신규모객

  useEffect(() => {
    if (!user || !user?.accessToken) {
      if (isOnboarding) {
        setMixPanel_new();
        navigate("/", { replace: true, state: { isOnboarding: true } });
      }
    } else {
      navigate("/", { ...state, replace: true });
    }
  }, [user]);

  useEffect(() => {
    setMixPanel_come();
  }, []);

  const setMixPanel_new = useMixpanelWeb({
    eventName: "0-0-A로 바로 진입",
  });

  const setMixPanel_come = useMixpanelWeb({
    eventName: "로그인 진입",
    params: {
      type: isMobile ? "app" : "web",
      ...(user?.appId && { appId: user?.appId }),
    },
  });

  const imageContainer = useRef<HTMLDivElement>(null);

  return (
    <DefaultPage
      content={
        <Stack
          direction={"column"}
          alignItems="center"
          justifyContent={"center"}
          spacing={"40px"}
          sx={{
            width: "100%",
            height: "100%",
            py: "24px",
          }}
        >
          <Stack spacing={"8px"} sx={{ textAlign: "center" }}>
            <Typography sx={{ fontSize: "32px", fontWeight: "900" }}>
              {`이해하고 훈련하는\n멘탈케어`}
            </Typography>
          </Stack>
          <Stack
            ref={imageContainer}
            alignItems="center"
            justifyContent={"center"}
            sx={{
              width: "100%",
              flex: 1,
              overflow: "hidden",
              position: "relative",
            }}
          >
            <img
              src={character}
              alt="empty"
              style={{
                maxWidth: "300px",
                maxHeight: "100%", // 부모 높이를 넘지 않음
                objectFit: "contain", // 이미지 비율 유지하며 꽉 채움
                position: "absolute", // 부모 기준으로 이미지 배치
              }}
            />
          </Stack>

          <Stack
            spacing={1}
            sx={{
              width: "100%",
              maxWidth: "500px",
            }}
            alignSelf="center"
          >
            <AppleLoginButton />
            <GoogleLoginButton />
            <InstitutionStartButton />
          </Stack>
        </Stack>
      }
    />
  );
}