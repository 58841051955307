import { SelfCheckId } from "data/selfCheckData";
import { atom } from "recoil";

export interface SelfCheckType {
  [key: SelfCheckId]: { score: number; evaluationKey: string };
}

export const selfCheckAtom = atom<SelfCheckType | undefined>({
  key: "selfCheck",
  default: undefined,
  effects: [
    ({ setSelf, onSet }) => {
      const savedData = localStorage.getItem("selfCheck");
      // setSelf: atom 값을 설정 혹은 재설정
      if (savedData) setSelf(JSON.parse(savedData));

      // atom이 변화가 감지될 때 작동, Storage에 데이터 저장
      // setSelf에 의해서는 작동하지 않음
      onSet((newValue, _, isReset) => {
        isReset
          ? localStorage.removeItem("selfCheck")
          : localStorage.setItem("selfCheck", JSON.stringify(newValue));
      });
    },
  ],
});
