import { translation_4_5_A } from "./4-5-A";
export const translation_4_5_2_A = {
  en: {
    v1: {
      // 나: "Me",
      // 타인: "Others",
      // 세상: "The world",
      // 나_additional: "me",
      // 타인_additional: "others in my automatic thought",
      // 세상_additional: "the world around me",
      나: "Myself",
      타인: "My Relationships",
      세상: "The world I Live In",
      나_additional: "myself",
      타인_additional: "my relationships",
      세상_additional: "the world I live in",
      ...translation_4_5_A.en.v1.start,
      typography_0_0_0: "Checking points",
      typography_1_0_0: "First, think about a recent situation.",
      typography_2_0_0:
        "Then, consider the thoughts you had in that situation.",
      typography_3_0_0:
        "Why did those thoughts come to mind? Write down a few evidences for these thoughts.",
      typography_4_0_0:
        "Good job. Our thoughts are often based on our experiences and can seem reasonable.",
      typography_5_0_0:
        "Now, let's practice looking at our thoughts from different angles, focusing on understanding rather than just believing them.",
      typography_6_0_0: "Explaining to an alien",
      typography_7_0_0: "Imagine explaining life on Earth to an alien.",
      typography_8_0_0: "The alien doesn't know anything about our life.",
      typography_9_0_0:
        "We'd need to explain human life in simple, universal terms.",
      typography_10_0_0: "For example, consider this statement.",
      typography_10_2_0: "Someone is considered overweight",
      typography_11_0_0:
        "What would need to be true for this statement to be correct? Maybe things like these.",
      typography_11_3_0: "Their BMI is over 30.",
      typography_11_6_0:
        "They often hear concerns about their weight and body shape from people around them.",
      typography_11_9_0:
        "When shopping for clothes, it's tough to find sizes that fit them well.",
      typography_11_12_0:
        "During meals with others, they tend to eat much more compared to everyone else.",
      typography_11_15_0:
        "They have noticeably more fat in the abdominal area than other parts of their body.",
      typography_12_0_0:
        "Makes sense? When explaining, it's better to be specific rather than use vague terms like 'good' or 'bad.'",
      typography_13_0_0: "What is the statement we're examining about?",
      typography_14_0_0:
        "Now, we'll try explaining a statement. Our statement is as follows.",
      typography_15_0_0:
        "What things must be true for us to say this statement is right?",
      typography_16_0_0:
        "The things don't have to be related; they can be separate.",
      typography_17_0_0:
        "Shall we list them? What must be true for us to say this statement is right?",

      typographyFromData_18_0_0:
        "Great. Do we have reasons to believe these points apply to $A$? Let's write them down.",
      typographyFromData_19_0_0:
        "Now, can we think of reasons why these points might not apply to $A$? Think like a lawyer arguing the other side.",
      typographyFromData_20_0_0:
        "Based on these reasons, how much do these points apply to $A$? Score them (0: Not at all, 100: Absolutely).",
      typographyFromData_20_0_0_0:
        "Based on these reasons, how much do these points apply to $A$? Score them (0: Not at all, 100: Absolutely).",
      typography_20_0_0_1: "First point.",
      typography_20_0_0_2: "Second point.",
      typography_20_0_0_3: "Third point.",
      typography_20_0_0_4: "Fourth point.",
      typography_20_0_0_5: "Fifth point.",
      typography_21_0_0:
        "Let's explore another statement. This time, our statement is:",
      typography_22_0_0:
        "What needs to be true for this statement to be correct?",

      typographyFromData_23_0_0:
        "Great. Do we have reasons to believe these points apply to $A$? Let's write them down.",
      typographyFromData_24_0_0:
        "Now, can we think of reasons why these points might not apply to $A$? Think like a lawyer arguing the other side.",
      typographyFromData_25_0_0:
        "Based on these reasons, how much do these points apply to $A$? Score them (0: Not at all, 100: Absolutely).",
      typographyFromData_25_0_0_0:
        "Based on these reasons, how much do these points apply to $A$? Score them (0: Not at all, 100: Absolutely).",
      typography_25_0_0_1: "First point.",
      typography_25_0_0_2: "Second point.",
      typography_25_0_0_3: "Third point.",
      typography_25_0_0_4: "Fourth point.",
      typography_25_0_0_5: "Fifth point.",
      typography_26_0_0: "Stepping back and reflecting",
      typography_27_0_0: "Alright, let's summarize it like this:",

      typographyFromData_28_0_0: 'How true is "$A$" for $B$?',
      typographyFromData_29_0_0: 'On the flip side, how true is "$A$" for $B$?',
      typography_30_0_0: "Looking at both, what do you think?",
      ...translation_4_5_A.en.v1.end,
    },
  },
  ko: {
    v1: {
      // 나: "나",
      // 타인: "타인",
      // 세상: "세상",
      // 나_additional: "나에게",
      // 타인_additional: "내가 자동적 사고에서 언급한 사람(들)에게",
      // 세상_additional: "내가 살고 있는 이 세상에",
      나: "나 자신",
      타인: "나의 인간관계",
      세상: "내가 사는 세상",
      나_additional: "나 자신에게",
      타인_additional: "나의 인간관계에서",
      세상_additional: "내가 사는 세상에",
      ...translation_4_5_A.ko.v1.start,
      typography_0_0_0: "요소 살펴보기",
      typography_1_0_0: "우선 아래와 같은 상황이 있었네요.",
      typography_2_0_0:
        "그리고 그 상황에서 나는 아래와 같은 생각이 떠올랐던 거고요.",
      typography_3_0_0:
        "그 상황에서 위와 같은 생각이 떠올랐던 근거를 몇 가지 적어볼까요?",
      typography_4_0_0:
        "좋아요. 우리 마음속에 떠올랐던 생각은 당시 상황이나 지금까지 나의 경험에 비추어 보면 충분히 '그럴 수 있다'고 볼 수 있을 거예요.",
      typography_5_0_0:
        "이번엔 '내가 어떠한 생각을 믿는지'에 신경쓰기보다는 편한 마음으로 다양한 관점에서 생각을 바라보는 것을 연습해볼게요.",
      typography_6_0_0: "외계인에게 설명하기",
      typography_7_0_0:
        "한 가지 재미있는 가정을 해보세요. 외계인이 지구에 도착했다고요.",
      typography_8_0_0: "이 외계인은 지구의 삶에 대해 아무것도 모르죠.",
      typography_9_0_0:
        "그래서 우리는 그들에게 인간의 삶과 관련한 다양한 요소들에 대해 보편적인 정의를 내려서 설명을 해줘야 해요.",
      typography_10_0_0:
        "예를 들어볼게요. 아래와 같은 명제가 있다고 가정해볼게요.",
      typography_10_2_0: "어떤 사람이 뚱뚱하다",
      typography_11_0_0:
        "만약 이 명제가 맞다고 설명할 수 있으려면, 어떠한 요소들이 충족되어야 할까요? 아마 다음과 같은 것들이 가능할 거예요.",
      typography_11_3_0: "BMI가 30 이상이다.",
      typography_11_6_0:
        "주변 사람들로부터 체중과 체형에 대한 우려와 염려를 제법 자주 듣는다.",
      typography_11_9_0:
        "옷을 사려고 하면 자신에게 맞는 사이즈를 쉽게 고르기 힘들다.",
      typography_11_12_0:
        "다른 사람과 식사를 할 때 남들과 비교하여 훨씬 더 많은 양을 먹는다.",
      typography_11_15_0: "다른 부위보다 유달리 복부에 살이 더 많다.",
      typography_12_0_0:
        "이해됐나요? 이때 각각의 요소는 추상적인 기준(예: 좋다, 나쁘다, 마음에 들지 않는다 등)보다는 최대한 구체적인 게 좋답니다.",
      typography_13_0_0: "살펴볼 명제는 무엇에 대한 생각인가요?",
      typography_14_0_0:
        "자, 그럼 우리도 외계인에게 무언가를 설명해 볼 텐데요. 우리가 설명할 명제는 다음과 같아요.",
      typography_15_0_0:
        "만약 우리가 위 명제가 맞다고 설명할 수 있으려면, 어떠한 요소들이 충족되어야 할까요?",
      typography_16_0_0:
        "각각의 요소가 관련 있을 필요는 없어요. 독립적이어도 된답니다.",
      typography_17_0_0:
        "자, 적어볼까요? 어떤 요소가 충족되어야 위의 명제가 사실이라고 이야기할 수 있을까요?",

      typographyFromData_18_0_0:
        "좋아요. 이번에는 위 요소들이 $A$도 동일하게 해당된다는 것을 말해주는 근거가 있을까요?",
      typographyFromData_19_0_0:
        "이번에는 위 요소들이 $A$는 해당하지 않는다는 것을 말해주는 근거가 있을까요? 위에 적은 것과는 반대의 입장에서 변호하는 변호사가 되었다고 생각하고 적어보세요.",
      typographyFromData_20_0_0:
        "그렇다면 각각의 근거를 토대로 살펴보았을 때 각 요인들은 $A$ 얼마나 해당하는지 점수로 적어볼까요? (0점: 전혀 해당하지 않음, 100점: 절대적인 사실처럼 정확하게 해당함)",
      typographyFromData_20_0_0_0:
        "그렇다면 각각의 근거를 토대로 살펴보았을 때 각 요인들이 $A$는 얼마나 해당하는지 점수로 적어볼까요? (0점: 전혀 해당하지 않음, 100점: 절대적인 사실처럼 정확하게 해당함)",
      typography_20_0_0_1: "첫 번째 요소예요.",
      typography_20_0_0_2: "두 번째 요소예요.",
      typography_20_0_0_3: "세 번째 요소예요.",
      typography_20_0_0_4: "네 번째 요소예요.",
      typography_20_0_0_5: "다섯 번째 요소예요.",
      typography_21_0_0:
        "좋아요. 이번에는 다른 명제를 한 가지만 더 다뤄볼게요. 이번에 다뤄볼 명제는 다음과 같아요.",
      typography_22_0_0:
        "만약 우리가 이 명제가 맞다고 설명할 수 있으려면, 어떠한 요소들이 충족되어야 할까요?",

      typographyFromData_23_0_0:
        "좋아요. 이번에는 위 요소들이 $A$도 동일하게 해당된다는 것을 말해주는 근거가 있을까요? 아래에 적어볼까요?",
      typographyFromData_24_0_0:
        "이번에는 위 요소들이 $A$는 해당하지 않는다는 것을 말해주는 근거가 있을까요? 위에 적은 것과는 반대의 입장에서 변호하는 변호사가 되었다고 생각하고 적어보세요.",
      typographyFromData_25_0_0:
        "그렇다면 각각의 근거를 토대로 살펴보았을 때 각 요인들은 $A$ 얼마나 해당하는지 점수로 적어볼까요? (0점: 전혀 해당하지 않음, 100점: 절대적인 사실처럼 정확하게 해당함)",
      typographyFromData_25_0_0_0:
        "그렇다면 각각의 근거를 토대로 살펴보았을 때 각 요인들이 $A$ 얼마나 해당하는지 점수로 적어볼까요? (0점: 전혀 해당하지 않음, 100점: 절대적인 사실처럼 정확하게 해당함) ",
      typography_25_0_0_1: "첫 번째 요소예요.",
      typography_25_0_0_2: "두 번째 요소예요.",
      typography_25_0_0_3: "세 번째 요소예요.",
      typography_25_0_0_4: "네 번째 요소예요.",
      typography_25_0_0_5: "다섯 번째 요소예요.",
      typography_26_0_0: "거리두고 살펴보기",
      typography_27_0_0: "좋아요. 위 활동을 요약해보면 아래와 같겠네요.",
      typographyFromData_28_0_0:
        '"$A$"가 $B$에게서 사실인 정도는 다음과 같아요.',
      typographyFromData_29_0_0:
        '반대로, "$A$"가 $B$에게서 사실인 정도는 다음과 같아요.',
      typography_30_0_0:
        "위와 같이 양쪽을 비교하여 살펴보았을 때 어떤 생각이 드나요?",
      ...translation_4_5_A.ko.v1.end,
    },
  },
};
