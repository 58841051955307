import {
  DateSelect,
  LineWithSolidLabel,
  Percentage,
  SolidLabelLine,
  TimeSelect,
  TypographyFromDataLine,
} from "data/CellComponent";
import dayjs from "dayjs";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithDataWithContent,
  InstructionWithDataWithTempContent,
  InstructionWithDataWithTextarea,
} from "../../BlockComponent";
import { LastCard } from "../common";

const today = dayjs().format("YYYY-MM-DD");
const todayWithTime = dayjs().format("HH:00");

export const Content_104_1_A = (
  activityName: string,
  isPushNotificationGranted: boolean
) => [
  Header1(`typography_0_0_0`),
  InstructionWithDataWithTempContent(
    { A: { id: "activity" } },
    `typography_1_0_0`,
    [
      [
        {
          type: "temp",
          content: {
            id: `activity`,
            value: activityName,
          },
        },
      ],
    ]
  ),
  InstructionWithDataWithContent(
    { A: { id: "activity" } },
    `typography_2_0_0`,
    [[Percentage({ id: "예상 난이도", min: 0, max: 10, step: 1 })]]
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "activity" } },
    `typography_3_0_0`,
    { id: "누구와" }
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "activity" } },
    `typography_4_0_0`,
    { id: "무엇을" }
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "activity" } },
    `typography_5_0_0`,
    { id: "어디서" }
  ),
  // InstructionWithDataWithTextarea(
  //   { A: { id: "activity" } },
  //   `typography_6_0_0`,
  //   { id: "언제" }
  // ),
  InstructionWithDataWithContent(
    { A: { id: "activity" } },
    `typography_6_0_0`,
    [
      ...LineWithSolidLabel("날짜", [DateSelect({ id: "date", value: today })]),
      ...LineWithSolidLabel("시각", [
        TimeSelect({ id: "time", value: todayWithTime }),
      ]),
    ]
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "activity" } },
    `typography_7_0_0`,
    { id: "예상 장애물" }
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "activity" } },
    `typography_8_0_0`,
    { id: "장애물 극복법" }
  ),
  Header1("typography_9_0_0"),
  InstructionWithDataWithContent(
    { A: { id: "activity" } },
    "typography_10_0_0",
    [
      ...[
        "예상 난이도",
        "누구와",
        "무엇을",
        "어디서",
        "언제",
        "예상 장애물",
        "장애물 극복법",
      ].flatMap((each) => [
        SolidLabelLine(`${each}`),
        each === "언제"
          ? TypographyFromDataLine(
              { A: { id: "date" }, B: { id: "time" } },
              "$A$ $B$"
            )
          : TypographyFromDataLine({ A: { id: each } }, "$A$"),
      ]),
    ]
  ),
  Instruction("typography_11_0_0"),
  // Instruction("typography_12_0_0"),
  Instruction("typography_13_0_0"),
  ...(!isPushNotificationGranted
    ? [
        InstructionWithContent("typography_14_0_0", [
          [{ type: "pushNotification", content: {} }],
        ]),
      ]
    : []),

  Instruction(`typography_15_0_0`),
  Instruction(`typography_16_0_0`),
  ...LastCard,
];
