export const translation_4_2_A = {
  en: {
    v1: {
      typography_0_0_0: "We're going to tackle automatic thoughts now.",
      typography_1_0_0:
        "Through 'distancing,' we learn that thoughts are not 'us,' and thus, we can stop reacting to every thought that pops into our mind.",
      typography_2_0_0:
        "First, let's prepare by viewing our thoughts as if they are an object separate from us.",
      typography_3_0_0:
        "Let’s examine the automatic thought we've identified together, shall we?",
      typography_4_0_0:
        "Now, picture this: take that thought out of your mind and imagine placing it about 1 meter away from you.",
      typography_5_0_0:
        "Close your eyes and imagine this for just 10 seconds, alright?",
      typography_6_0_0: "Great. I'm going to ask you a few questions now.",
      typography_7_0_0:
        "Remember, there are no right or wrong answers. Just step back, answer, and reflect on the thought you’ve been carrying.",
      typography_8_0_0:
        "If a question seems hard, it's perfectly fine to say 'I'm not sure' and move on.",
      typography_9_0_0:
        "But if you can answer, take your time and think deeply, not just with a few words. This deep thinking is really helpful.",
      typography_10_0_0:
        "One thing to remember: the thoughts that popped into your mind are completely natural, considering your past experiences or the situation.",
      typography_11_0_0:
        "So, no need to feel guilty about having these thoughts or to try to push them away.",
      typography_12_0_0:
        "Just step back from these thoughts and work on building psychological flexibility.",
      typography_13_0_0:
        "It's about viewing your thoughts from different angles for your distancing practice, not about forcefully countering them.",
      typography_14_0_0:
        "This practice will help you become less affected by negative emotions or stress.",
      typography_15_0_0: "Ready to start?",
      typography_16_0_0: "Another Perspective",
      typography_17_0_0:
        "Think of a close friend or family member. Write down their name - nicknames are okay too.",
      typographyFromData_18_0_0: `Imagine if "$A$" came to you, sharing that they're having a thought similar to "$C$" in a situation like "$B$". What would you say to "$A$"?`,
      typographyFromData_19_0_0: `The advice I gave to "$A$" - can I also say it to myself?`,
      typographyFromData_20_0_0: `If thoughts like "$A$" frequently popped into your mind, how would that affect you? What would be the impact?`,
      typography_21_0_0:
        "Great. Now, can you think of someone you usually respect, or consider thoughtful and rational? It could be a family member, friend, acquaintance, or even a character from a book or TV show.",
      typographyFromData_22_0_0: `In a situation similar to "$A$", what kind of thoughts do you think would come to "$B$"'s mind?`,
      typographyFromData_23_0_0: `If thoughts similar to "$A$" frequently came to your mind, how would that affect you? What would be the impact?`,
      typography_24_0_0: "Benefits and Drawbacks",
      typographyFromData_25_0_0: `What are the short-term benefits of reacting to a thought like "$A$"?`,
      typographyFromData_26_0_0: `Conversely, what are the short-term drawbacks of reacting to a thought like "$A$"?`,
      typography_27_0_0:
        "When you compare these short-term benefits and drawbacks, which seems greater? Why do you think that is?",
      typographyFromData_28_0_0: `Now, let's consider the long-term perspective. What are the long-term benefits of reacting to a thought like "$A$"?`,
      typographyFromData_29_0_0: `On the other hand, what are the long-term drawbacks of reacting to a thought like "$A$"?`,
      typography_30_0_0:
        "In the long term, comparing these benefits and drawbacks, which do you think is greater? What leads you to think that way?",
      typography_31_0_0: "Distancing",
      typography_32_0_0:
        "Now, distance yourself and reconsider the automatic thought.",
      typographyFromData_33_0_0: `Earlier, you rated the impact of this thought as $A$. How much is it affecting you now?`,
      typography_34_0_0:
        "Great. Shall we end this activity here? You've done wonderfully.",
    },
  },
  ko: {
    v1: {
      typography_0_0_0: "지금부터 자동적 사고를 다뤄볼 텐데요.",
      typography_1_0_0:
        "우리는 '거리두기'를 통해 생각은 '나'가 아니며, 따라서 마음속에 떠오르는 모든 생각에 일일이 반응하는 것을 멈출 수 있단 사실을 배워나갈 거예요.",
      typography_2_0_0:
        "우선 생각을 제3의 대상처럼 바라보기 위한 준비 운동을 해 볼게요.",
      typography_3_0_0: "우선 함께 찾은 자동적 사고를 살펴볼까요?",
      typography_4_0_0:
        "자, 이제 위와 같은 생각을 내 마음속에서 꺼내 나와 1m 정도 거리가 떨어진 곳에 놓는다고 상상해보세요.",
      typography_5_0_0: "잠시 눈을 감고 10초만 상상해볼까요?",
      typography_6_0_0: "좋아요. 그럼 이제 제가 몇 가지를 여쭤볼게요.",
      typography_7_0_0:
        "정답이 있는 질문은 아니에요. 그저 한 발짝 떨어져 질문에 답하면서 내가 가졌던 생각들을 되돌아보는 시간을 가져봐요.",
      typography_8_0_0:
        "답하기 힘든 질문이라면 '글쎄요'라고 답하고 넘어가도 좋아요.",
      typography_9_0_0:
        "하지만 만약 답할 수 있는 질문이라면 가볍게 생각해서 단어 몇 개만 적고 끝내지 말고, 시간을 가지고 곰곰히 생각해보도록 해요. 그 과정이 우리에게 많은 도움이 될 거랍니다.",
      typography_10_0_0:
        "한 가지 기억할 게 있어요. 우리 마음속에 떠올랐던 생각은 당시 상황이나 지금까지 나의 경험에 비추어보면 충분히 '그럴 수 있다'고 볼 수 있어요. 자연스러운 일이죠.",
      typography_11_0_0:
        "그러니 그런 생각을 했다는 사실에 자책하지도, 혹은 그런 생각을 억지로 밀어내려고 하지 않아도 괜찮아요.",
      typography_12_0_0:
        "그저 생각으로부터 한 걸음 멀어져서 심리적으로 유연성을 기른다고 생각하면 좋을 거예요.",
      typography_13_0_0:
        "억지로 생각을 반박하는 것이 아니라 거리두기 연습을 위해 다른 각도에서도 한번 생각해보는 것이라고요.",
      typography_14_0_0:
        "그 연습이 우리가 불쾌한 감정이나 스트레스 상황으로부터 덜 영향을 받도록 하는 데에 많은 도움이 될 거랍니다.",
      typography_15_0_0: "그럼 시작해볼까요?",
      typography_16_0_0: "다른 입장",
      typography_17_0_0:
        "내가 가장 아끼는 친구나 가족을 떠올리고 아래에 그 이름을 적어보시겠어요? 애칭도 괜찮아요.",
      typographyFromData_18_0_0:
        '"$A$"이(가) "$B$"와(과) 같은 상황에서 "$C$"와(과) 같은 생각이 든다고 나에게 고민을 이야기했어요. 그러면 나는 "$A$"(이)에게 뭐라고 말해줄 수 있을까요?',
      typographyFromData_19_0_0:
        '내가 위에서 "$A$"(이)에게 해주었던 말은 나 스스로에게도 해줄 수 있는 말인가요?',
      typographyFromData_20_0_0:
        '만약 "$A$"와(과) 같은 생각이 나의 마음속에 자주 떠오른다면 어떻게 될까요? 그 생각의 효과는 무엇일까요?',
      typography_21_0_0:
        "좋아요. 이번에는 내가 평소에 존경하거나, 배려 깊고 합리적이라고 생각했던 사람을 떠올려볼 수 있을까요? 가족이나 친구, 지인도 좋고, 떠올리기 힘들다면 책이나 TV속의 인물도 괜찮아요.",
      typographyFromData_22_0_0:
        '"$A$"와(과) 같은 상황에서 "$B$"의 마음속에는 어떤 생각이 떠오를 것 같나요?',
      typographyFromData_23_0_0:
        '만약 "$A$"와(과) 같은 생각이 나의 마음속에 자주 떠오른다면 어떻게 될까요? 그 생각의 효과는 무엇일까요?',
      typography_24_0_0: "이점과 손해",

      typographyFromData_25_0_0:
        '단기적인 측면에서 "$A$"와(과) 같은 생각에 반응하는 것의 이점은 무엇일까요?',
      typographyFromData_26_0_0:
        '단기적인 측면에서 "$A$"와(과) 같은 생각에 반응하는 것의 손해는 무엇일까요?',
      typography_27_0_0:
        "단기적인 측면에서 이점과 손해를 비교해보면 무엇이 더 크다고 할 수 있나요? 어떤 점에서 그렇게 생각하셨나요?",
      typographyFromData_28_0_0:
        '이번엔 장기적인 측면에서 "$A$"와(과) 같은 생각에 반응하는 것의 이점은 무엇일까요?',
      typographyFromData_29_0_0:
        '반대로 장기적인 측면에서 "$A$"와(과) 같은 생각에 반응하는 것의 손해는 무엇일까요?',
      typography_30_0_0:
        "장기적인 측면에서 이점과 손해를 비교해보면 무엇이 더 크다고 할 수 있나요? 어떤 점에서 그렇게 생각하셨나요?",
      typography_31_0_0: "거리두기",
      typography_32_0_0:
        "거리를 두고 아래 자동적 사고를 다시 한번 떠올려보세요.",
      typographyFromData_33_0_0:
        "이전에 이 생각에 영향받는 정도를 $A$점이라고 하셨어요. 지금 이 생각은 나에게 얼마나 영향을 주고 있나요?",
      typography_34_0_0: "좋아요. 이번 활동은 여기까지 할까요? 고생 많았어요.",
    },
  },
};
