import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import axiosRetry from "axios-retry";

export interface ErrorResponse {
  code: number;
  debugMessage: string;
  message: string;
}
const HOST_API = process.env.REACT_APP_API_SERVER_HOST;
const API_KEY = process.env.REACT_APP_API_KEY;

const axiosInstance = (token?: string, locale?: string) => {
  const instance = axios.create({
    withCredentials: true,
    baseURL: HOST_API,
    headers: {
      "X-Auth-Token": token || "",
      "X-Api-Key": API_KEY,
      "X-Lang-Code": locale,
      "content-type": "application/json",
    },
  });

  const onFulfilled = (response: AxiosResponse) => response;

  const onRejected = (error: AxiosError<ErrorResponse>) => {
    if (error.response?.status === 401) {
      window.location.href = `/login`;
    }

    return Promise.reject(error);
  };

  instance.interceptors.response.use(onFulfilled, onRejected);

  return instance;
};

export default axiosInstance;
