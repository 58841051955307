import { Box, Stack } from "@mui/joy";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { chatApi, CHAT_DOMAIN, CHAT_READ_MESSAGE_ENDPOINT } from "api/chatApi";
import { PATIENT_DOMAIN, PATIENT_LIST_ENDPOINT } from "api/patientApi";
import { useCallback, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import CoachingContent from "./CoachingContent";
import TaskHeader from "components/task/header/TaskHeader";

export default function Coaching() {
  const queryClient = useQueryClient();
  const patientState = useRecoilValue(patientAtom);
  const messageReadQueryFn = useCallback(
    () => chatApi.read(`${patientState?.patientId}`),
    [patientState]
  );

  const { refetch: readMessage } = useQuery(
    [CHAT_DOMAIN, CHAT_READ_MESSAGE_ENDPOINT],
    messageReadQueryFn,
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries([PATIENT_DOMAIN, PATIENT_LIST_ENDPOINT]);
      },
    }
  );

  useEffect(() => {
    readMessage();
    return () => {
      readMessage();
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          flex: "none",
          zIndex: 100,
        }}
      >
        <TaskHeader taskKey={"chat"} />
      </Box>

      <Stack sx={{ flex: 1, overflow: "hidden" }}>
        <CoachingContent />
      </Stack>
    </>
  );
}
