import React, { KeyboardEventHandler, useEffect, useState } from "react";
import { Textarea } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { isMobile } from "react-device-detect";
import emojiRegex from "emoji-regex";

export default function PlainTextarea({
  placeholder,
  initialValue,
  handleChange,
  isFocused,
  setIsFocused,
  editorKey,
  emojiDisabled,
  readOnly,
  handleChangeWithoutDebounce,
}: {
  placeholder?: string;
  initialValue?: string;
  handleChange: (value: string, htmlString?: string) => void;
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  editorKey?: string;
  emojiDisabled?: boolean;
  readOnly?: boolean;
  handleChangeWithoutDebounce?: (value: string, htmlString?: string) => void;
}) {
  const user = useRecoilValue(userAtom);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (!isFocused) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (
      !isMobile &&
      (e.code === "Enter" ||
        e.code === "NumpadEnter" ||
        e.key === "Enter" ||
        e.key === "NumpadEnter")
    ) {
    }
  };
  const emoji_regex = emojiRegex();
  const specialChar_regex = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;
  const disabled_regex = /[^a-zA-Z0-9ㄱ-힣]/g;

  return (
    <Textarea
      key={`${editorKey}`}
      placeholder={placeholder}
      value={value || ""}
      onChange={(e) => {
        let newValue = e.target.value;

        if (emojiDisabled) {
          // //이모지 제거
          // newValue = newValue.replace(emoji_regex, "");
          // // 특수문자 제거
          // newValue = newValue.replace(specialChar_regex, "");
          newValue = newValue.replace(disabled_regex, "");
        }

        setValue(newValue);
        handleChange(newValue, `<p>${newValue}</p>`);
      }}
      readOnly={readOnly}
      minRows={1}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
        // if (handleChangeWithoutDebounce) {
        //   handleChangeWithoutDebounce(`${value}`, `<p>${value}</p>`);
        // }
      }}
      onKeyDown={handleKeyDown}
      sx={{
        padding: 0,
        minWidth: 0,
        minHeight: "24px",
        backgroundColor: "transparent",
        borderWidth: 0,
        boxShadow: "none",
        "--joy-focus-thickness": "0px",
        "--variant-borderWidth": "0px",
        "--Textarea-gap": "0px",
      }}
    ></Textarea>
  );
}
