import {
  ButtonGroupMultipleChoice,
  TextareaLine,
  TypographyFromDataLine,
} from "data/CellComponent";
import {
  Instruction,
  InstructionWithContent,
  InstructionWithImage,
} from "../../BlockComponent";
import { LastCard } from "../common";

export const Content_101_2_4_A = [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  InstructionWithImage("typography_2_0_0", "0"),
  Instruction("typography_3_0_0"),
  Instruction("typography_4_0_0"),
  Instruction("typography_5_0_0"),
  InstructionWithImage("typography_6_0_0", "1"),
  Instruction("typography_7_0_0"),
  Instruction("typography_8_0_0"),
  Instruction("typography_9_0_0"),
  Instruction("typography_10_0_0"),
  Instruction("typography_11_0_0"),
  Instruction("typography_12_0_0"),
  Instruction("typography_13_0_0"),
  Instruction("typography_14_0_0"),
  Instruction("typography_15_0_0"),
  Instruction("typography_16_0_0"),

  InstructionWithContent("typography_17_0_0", [
    [
      ButtonGroupMultipleChoice(
        [
          {
            translationKey: "관계 (가족, 친구, 파트너)",
          },
          {
            translationKey: "건강한 몸과 마음",
          },
          {
            translationKey: "자기 계발",
          },
          {
            translationKey: "창의성",
          },
          {
            translationKey: "직업과 경력",
          },
          {
            translationKey: "교육과 학습",
          },
          {
            translationKey: "기타",
          },
        ],
        { id: "가치", requiredSelectionCount: 3 }
      ),
    ],
  ]),
  InstructionWithContent("typography_18_0_0", [
    ...Array.from({ length: 3 }, (v, i) => i).flatMap((each) => [
      TypographyFromDataLine(
        {
          A: {
            id: `가치`,
            index: each,
          },
        },
        "$A$",
        {
          level: "body-sm",
          color: "primary.solid",
        }
      ),
      TextareaLine(),
    ]),
  ]),
  InstructionWithContent("typography_19_0_0", [
    ...Array.from({ length: 3 }, (v, i) => i).flatMap((each) => [
      TypographyFromDataLine(
        {
          A: {
            id: `가치`,
            index: each,
          },
        },
        "$A$",
        {
          level: "body-sm",
          color: "primary.solid",
        }
      ),
      TextareaLine(),
    ]),
  ]),
  InstructionWithContent("typography_20_0_0", [
    ...Array.from({ length: 3 }, (v, i) => i).flatMap((each) => [
      TypographyFromDataLine(
        {
          A: {
            id: `가치`,
            index: each,
          },
        },
        "$A$",
        {
          level: "body-sm",
          color: "primary.solid",
        }
      ),
      TextareaLine(),
    ]),
  ]),
  InstructionWithContent("typography_21_0_0", [
    ...Array.from({ length: 3 }, (v, i) => i).flatMap((each) => [
      TypographyFromDataLine(
        {
          A: {
            id: `가치`,
            index: each,
          },
        },
        "$A$",
        {
          level: "body-sm",
          color: "primary.solid",
        }
      ),
      TextareaLine({ id: `가치 영역 ${each} 행동` }),
    ]),
  ]),
  Instruction("typography_22_0_0"),
  Instruction("typography_23_0_0"),
  Instruction("typography_24_0_0"),
  Instruction("typography_25_0_0"),
  Instruction("typography_26_0_0"),
  Instruction("typography_27_0_0"),
  Instruction("typography_28_0_0"),
  Instruction("typography_29_0_0"),
  Instruction("typography_30_0_0"),
  Instruction("typography_31_0_0"),

  ...LastCard,
];
