export const translation_2_1_A = {
  en: {
    v1: {
      typography_0_0_0:
        "Earlier in <Starting Distancing>, we talked about the importance of distancing yourself from your thoughts.",
      typography_1_0_0:
        "Since the key to solving problems often lies in our thoughts, it's important to identify patterns in the thoughts that arise in problematic situations.",
      typography_2_0_0:
        "We'll gradually explore if there are any common patterns in your thoughts, how these patterns affect you, and work on identifying them together.",
      typography_3_0_0:
        "To find these patterns, we need some data. Moving forward, we'll record situations where problems have occurred.",
      typography_4_0_0:
        "We'll record in three parts: Situation, Thought, Reaction. Let's take a look at each:",
      typography_5_0_0: "First up is 'Situation.'",
      typography_5_3_0:
        "I overheard a few colleagues planning to go out for dinner and drinks.",
      typography_6_0_0: "Next is 'Thought.'",
      typography_6_3_0:
        "I feel like I'm not part of this group. It seems like my colleagues neither like nor respect me.",
      typography_6_5_0: "70",
      typography_7_0_0: "Finally, 'Reaction.'",
      typography_7_3_0: "Irritation, sadness, anger, headache, stomach cramps",
      typography_7_5_0:
        "I stayed seated at my office desk all afternoon without getting up. I didn’t even go to the break room.",
      typography_8_0_0: "How to write situations",
      typography_9_0_0:
        "For the situation, only jot down the objective circumstances at the time. Here’s an example:",
      typography_9_3_0:
        "I overheard a few colleagues planning to go out for dinner and drinks.",
      typography_10_0_0:
        "Exclude your judgments, opinions, and thoughts, and only write about what objectively happened in the situation.",
      typography_11_0_0:
        "Let’s do a quiz. Is the following situation well written?",
      typography_11_3_0:
        "Instead of studying as planned, I was just pathetically scrolling through social media on my phone.",
      buttongroup_11_5_0_option0: "Correctly Written",
      buttongroup_11_5_0_option1: "Incorrectly Written",
      typography_11_6_0:
        "Think again. Does the sentence contain any judgments, opinions, or thoughts?",
      typography_12_0_0: `Yes, you’re right. The above situation is not objective. It includes subjective judgment with words like "pathetically."`,
      typography_13_0_0: `Instead of including judgments, it's better to write objectively, like "Instead of the planned study, I was browsing social media on my phone."`,
      typography_14_0_0: "How to write thoughts",
      typography_15_0_0:
        "For thoughts, write down what you were thinking in that particular situation. Here’s an example:",
      typography_15_3_0:
        "I feel like I'm not part of this group. It seems like my colleagues neither like nor respect me.",
      typography_15_5_0: "70",
      typography_16_0_0: `Instead of a conversational style (e.g., "I felt...," "I thought..."), write your thoughts as if they were a monologue in your head (e.g., "I really don't want this. I want to hide somewhere. Why could I only do that much?...").`,
      typography_17_0_0:
        "Remember, you should only write your thoughts, excluding the situation.",
      typography_18_0_0:
        "Let’s do another quiz. Is the following record of thoughts well written?",
      typography_18_3_0:
        "It all started about a month ago. I've been feeling off since I failed an interview at a company where I wanted to transfer. I couldn't focus on anything after the rejection. My mom tried to comfort me a lot on the day of the rejection notice, but still, it feels like the world has turned its back on me. I can't shake the feeling that nothing I do will turn out right.",
      typography_18_5_0: "70",
      buttongroup_18_7_0_option0: "Correctly Written",
      buttongroup_18_7_0_option1: "Incorrectly Written",
      typography_18_8_0:
        "Think again. Are there elements of the situation creeping into the sentence?",
      typography_19_0_0:
        "Yes, you’re right. The above thoughts include descriptions of the situation, from the interview a month ago to the comfort from mom afterwards.",
      typography_20_0_0:
        "When writing your thoughts, leave out the situation. There's no need to explain these records to anyone. They are for you only, so bravely omit such situational descriptions.",
      typography_21_0_0:
        "Write down your raw, unfiltered thoughts as they were at that moment.",
      typography_22_0_0:
        "Below that, rate how negatively those thoughts impacted you at the time.",
      typography_23_0_0:
        "0 means no impact at all, and 100 means the thoughts significantly shook your life.",
      typography_24_0_0: "How to write reactions",
      typography_25_0_0:
        "Write down the emotions or physical reactions you felt at that moment. Here’s an example:",
      typography_25_3_0: "Irritation, sadness, anger, headache, stomach cramps",
      typography_25_5_0:
        "I stayed seated at my office desk all afternoon without getting up. I didn’t even go to the break room.",
      typography_26_0_0:
        "Write down emotions (anxiety, depression, lethargy, irritation, loneliness, etc.) or",
      typography_27_0_0:
        "physical sensations (head feels heavy, stomach ache, heavy shoulders, eyes feel strained, etc.).",
      typography_28_0_0:
        "Lastly, write down the actual actions you took. Try to record only what you did.",
      typography_29_0_0: "How does it look? Not too difficult, right?",
      typography_30_0_0:
        "Now, in the next activity <Thought Log>, we'll divide your concerns into Situation, Thought, and Reaction and write them down.",
      typography_31_0_0: "See you in the <Thought Log>!",
    },
  },
  ko: {
    v1: {
      "잘 적혔어요": "잘 적혔어요",
      "아니요, 잘못 적혔어요": "아니요, 잘못 적혔어요",
      typography_0_0_0:
        "앞서 <디스턴싱 시작하기>에서 생각과 거리두는 것이 중요하다고 설명했어요.",
      typography_1_0_0:
        "이처럼 문제 해결의 실마리가 생각에 있기 때문에, 문제 상황에서 우리의 머리속에 어떠한 생각이 떠오르는지 패턴을 발견할 필요가 있어요.",
      typography_2_0_0:
        "나의 생각에는 어떤 공통된 패턴이 있는지, 그러한 패턴들은 어떻게 내게 영향을 주는지, 패턴을 찾아나가며 차차 함께 알아볼게요.",
      typography_3_0_0:
        "우선 패턴을 찾기 위해서는 자료들이 필요할 텐데요. 앞으로 문제가 발생했던 상황을 차곡차곡 기록해 나갈 거예요.",
      typography_4_0_0:
        "상황-생각-반응의 세 파트로 나눠서 기록할 건데요. 하나씩 보여드릴게요.",
      typography_5_0_0: "먼저 이건 '상황'이에요.",
      typography_5_3_0:
        "동료 몇 명이 함께 저녁 먹고 술 마시러 간다는 것을 우연찮게 들었음",
      typography_6_0_0: "두 번째로 '생각'이에요.",
      typography_6_3_0:
        "난 이 집단의 일원이 아닌 것 같네. 내 동료들은 나를 좋아하지도 존중하지도 않는 것 같다.",
      typography_6_5_0: "70",
      typography_7_0_0: "마지막으로 '반응'이에요.",
      typography_7_3_0: "짜증, 슬픔, 분노, 머리가 아픔, 배가 싸함",
      typography_7_5_0:
        "오후 동안 사무실 자리에 앉아서 일어나지 않음. 휴게실도 가지 않음.",
      typography_8_0_0: "상황은 어떻게 적나요?",
      typography_9_0_0:
        "상황에는 당시의 객관적인 상황만 적어야 해요. 다음은 예시예요.",
      typography_9_3_0:
        "동료 몇 명이 함께 저녁 먹고 술 마시러 간다는 것을 우연찮게 들었음",
      typography_10_0_0:
        "그 상황에 대한 나의 판단, 의견, 생각 등은 배제한 채 객관적으로 어떤 상황이 있었는지만 적어보는 거예요.",
      typography_11_0_0:
        "여기서 퀴즈를 하나 드려볼게요. 다음의 상황은 잘 적혔을까요?",

      typography_11_3_0:
        "계획한 공부는 하지 않고, 한심하게 휴대폰으로 SNS만 보고 있음",
      buttongroup_11_5_0_option0: "잘 적혔어요",
      buttongroup_11_5_0_option1: "아니요, 잘못 적혔어요",
      typography_11_6_0:
        "다시 한 번 생각해보세요. 적힌 문장에 판단, 의견, 생각은 없나요?",
      typography_12_0_0:
        "네, 정답이에요. 위의 상황은 객관적이지 않아요. <한심하게>라는 주관적인 판단이 들어가 있기 때문이죠.",
      typography_13_0_0:
        "내가 내린 판단을 제외하고 <계획한 공부 대신, 휴대폰으로 SNS을 보고 있음>이라고 객관적인 관점으로 적는 게 좋아요.",
      typography_14_0_0: "생각은 어떻게 적나요?",
      typography_15_0_0:
        "생각엔 당시의 그 상황에서 어떤 생각이 들었는지 적어주세요. 다음은 예시예요.",
      typography_15_3_0:
        "난 이 집단의 일원이 아닌 것 같네. 내 동료들은 나를 좋아하지도 존중하지도 않는 것 같다.",
      typography_15_5_0: "70",
      typography_16_0_0:
        "누군가에게 말하는 형태(예: ~했어요, ~라고 느꼈어요)가 아니라 내가 머릿속에서 했던 생각 그 자체를 독백 형태(예: 진짜 이러고 싶지 않다. 어딘가에 숨고 싶다. 나는 왜 그렇게 밖에 못 했을까...)로 적어주세요.",
      typography_17_0_0:
        "주의해야 할 것은 상황을 배제한 나의 생각만을 적어야 한다는 거예요.",
      typography_18_0_0:
        "여기서 퀴즈를 하나 드려볼게요. 다음의 생각에 대한 기록은 잘 적혔을까요?",
      typography_18_3_0:
        "사실 한 달 전즈음부터 계속되고 있었다. 이직을 원하던 회사의 면접에서 떨어지고 난 뒤, 아무 일도 손에 잡히지 않았다. 불합격 발표 날에 엄마가 괜찮다며 위로를 많이 해줬다. 하지만 그럼에도 여전히 세상은 나에게 등을 돌리고 있는 것 같다. 앞으로 무언가를 해도 잘 안될 것 같다는 생각 뿐이다.",
      typography_18_5_0: "70",
      buttongroup_18_7_0_option0: "잘 적혔어요",
      buttongroup_18_7_0_option1: "아니요, 잘못 적혔어요",
      typography_18_8_0:
        "다시 한 번 생각해보세요. 적힌 문장에 상황이 끼어들어가 있진 않나요?",
      typography_19_0_0:
        "네, 정답이에요. 위의 생각에는 상황 설명도 함께 들어가 있어요. 한 달 전의 면접 이야기부터 그 뒤 엄마의 위로까지 과거의 상황이 설명되어 있죠.",
      typography_20_0_0:
        "생각을 적을 때에는 상황은 걷어내고 적어야 해요. 이 기록을 누군가에게 설명할 필요는 전혀 없답니다. 오로지 자신을 위해 적는 거니 이런 상황 설명은 과감히 덜어내주세요.",
      typography_21_0_0:
        "그 당시 머리 속을 스쳤던 생각만, 날것 그대로 적어주세요.",
      typography_22_0_0:
        "그리고 그 아래 칸에는 당시 그 상황에서 떠올랐던 생각이 나에게 얼마나 부정적인 영향을 주는지 적어보는 거예요.",
      typography_23_0_0:
        "0은 전혀 영향 주지 않음을, 100은 그 생각으로 인해 나의 삶이 흔들릴 정도로 강하게 영향을 주었다는 걸 뜻해요.",
      typography_24_0_0: "반응은 어떻게 적나요?",
      typography_25_0_0:
        "사건이 있었던 당시 느꼈던 감정이나 신체 반응을 적어요. 다음은 예시예요.",
      typography_25_3_0: "짜증, 슬픔, 분노, 머리가 아픔, 배가 싸함",
      typography_25_5_0:
        "오후 동안 사무실 자리에 앉아서 일어나지 않음. 휴게실도 가지 않음.",
      typography_26_0_0: "감정(불안함, 우울함, 무기력함, 짜증, 외로움 등)이나",
      typography_27_0_0:
        "신체 감각(머리가 띵함, 배가 아픔, 어깨가 무거움, 눈이 빠질 것 같음 등)을 적어주시면 돼요",
      typography_28_0_0:
        "마지막으로 실제로 내가 한 행동을 적어요. 최대한 내가 했던 행동만을 적어보세요.",
      typography_29_0_0: "어떤가요? 방법을 아니 크게 어려운 부분은 없으시죠?",
      typography_30_0_0:
        "자, 이제 다음 활동인 <생각기록지>에서 앞서 말씀주셨던 나의 고민이 어떤 상황에서 어떻게 나를 괴롭히는지 구체적으로 적어주세요.",
      typography_31_0_0: "그럼 <생각기록지>에서 만나요!",
    },
    v2: {
      "잘 적혔어요": "잘 적혔어요",
      "아니요, 잘못 적혔어요": "아니요, 잘못 적혔어요",
      "저녁 7시": "저녁 7시",
      "저녁 8시": "저녁 8시",
      "저녁 9시": "저녁 9시",
      "밤 10시": "밤 10시",
      "밤 11시": "밤 11시",
      "밤 12시": "밤 12시",
      typography_0_0_0:
        "앞서 <생각과 거리두기>에서 생각과 거리두는 것이 중요하다고 설명했어요.",
      typography_1_0_0:
        "이처럼 문제 해결의 실마리가 생각에 있기 때문에, 문제 상황에서 우리의 머리속에 어떠한 생각이 떠오르는지 패턴을 발견할 필요가 있어요.",
      typography_2_0_0:
        "나의 생각에는 어떤 공통된 패턴이 있는지, 그러한 패턴들은 어떻게 내게 영향을 주는지, 패턴을 찾아나가며 차차 함께 알아볼게요.",
      typography_3_0_0:
        "우선 패턴을 찾기 위해서는 자료들이 필요할 텐데요. 앞으로 문제가 발생했던 상황을 차곡차곡 기록해 나갈 거예요.",
      typography_4_0_0:
        "상황-생각-반응의 세 파트로 나눠서 기록할 건데요. 하나씩 보여드릴게요.",
      typography_5_0_0: "먼저 이건 '상황'이에요.",
      typography_5_3_0:
        "동료 몇 명이 함께 저녁 먹고 술 마시러 간다는 것을 우연찮게 들었음",
      typography_6_0_0: "두 번째로 '생각'이에요.",
      typography_6_3_0:
        "난 이 집단의 일원이 아닌 것 같네. 내 동료들은 나를 좋아하지도 존중하지도 않는 것 같다.",
      typography_6_5_0: "70",
      typography_7_0_0: "마지막으로 '반응'이에요.",
      typography_7_3_0: "짜증, 슬픔, 분노, 머리가 아픔, 배가 싸함",
      typography_7_3_0_0: "짜증, 슬픔, 분노",
      typography_7_3_0_1: "머리가 아픔, 배가 싸함",
      typography_7_5_0:
        "오후 동안 사무실 자리에 앉아서 일어나지 않음. 휴게실도 가지 않음.",
      typography_8_0_0: "상황은 어떻게 적나요?",
      typography_9_0_0:
        "상황에는 당시의 객관적인 상황만 적어야 해요. 다음은 예시예요.",
      typography_9_3_0:
        "동료 몇 명이 함께 저녁 먹고 술 마시러 간다는 것을 우연찮게 들었음",
      typography_10_0_0:
        "그 상황에 대한 나의 판단, 의견, 생각 등은 배제한 채 객관적으로 어떤 상황이 있었는지만 적어보는 거예요.",
      typography_11_0_0:
        "여기서 퀴즈를 하나 드려볼게요. 다음의 상황은 잘 적혔을까요?",

      typography_11_3_0:
        "계획한 공부는 하지 않고, 한심하게 휴대폰으로 SNS만 보고 있음",

      typography_11_6_0:
        "다시 한 번 생각해보세요. 적힌 문장에 판단, 의견, 생각은 없나요?",
      typography_12_0_0:
        "네, 정답이에요. 위의 상황은 객관적이지 않아요. <한심하게>라는 주관적인 판단이 들어가 있기 때문이죠.",
      typography_13_0_0:
        "내가 내린 판단을 제외하고 <계획한 공부 대신, 휴대폰으로 SNS을 보고 있음>이라고 객관적인 관점으로 적는 게 좋아요.",
      typography_14_0_0: "생각은 어떻게 적나요?",
      typography_15_0_0:
        "생각엔 당시의 그 상황에서 어떤 생각이 들었는지 적어주세요. 다음은 예시예요.",
      typography_15_3_0:
        "난 이 집단의 일원이 아닌 것 같네. 내 동료들은 나를 좋아하지도 존중하지도 않는 것 같다.",
      typography_15_5_0: "70",
      typography_16_0_0:
        "누군가에게 말하는 형태(예: ~했어요, ~라고 느꼈어요)가 아니라 내가 머릿속에서 했던 생각 그 자체를 독백 형태(예: 진짜 이러고 싶지 않다. 어딘가에 숨고 싶다. 나는 왜 그렇게 밖에 못 했을까...)로 적어주세요.",
      typography_17_0_0:
        "주의해야 할 것은 상황을 배제한 나의 생각만을 적어야 한다는 거예요.",
      typography_18_0_0:
        "여기서 퀴즈를 하나 드려볼게요. 다음의 생각에 대한 기록은 잘 적혔을까요?",
      typography_18_3_0:
        "사실 한 달 전즈음부터 계속되고 있었다. 이직을 원하던 회사의 면접에서 떨어지고 난 뒤, 아무 일도 손에 잡히지 않았다. 불합격 발표 날에 엄마가 괜찮다며 위로를 많이 해줬다. 하지만 그럼에도 여전히 세상은 나에게 등을 돌리고 있는 것 같다. 앞으로 무언가를 해도 잘 안될 것 같다는 생각 뿐이다.",
      typography_18_5_0: "70",

      typography_18_8_0:
        "다시 한 번 생각해보세요. 적힌 문장에 상황이 끼어들어가 있진 않나요?",
      typography_19_0_0:
        "네, 정답이에요. 위의 생각에는 상황 설명도 함께 들어가 있어요. 한 달 전의 면접 이야기부터 그 뒤 엄마의 위로까지 과거의 상황이 설명되어 있죠.",
      typography_20_0_0:
        "생각을 적을 때에는 상황은 걷어내고 적어야 해요. 이 기록을 누군가에게 설명할 필요는 전혀 없답니다. 오로지 자신을 위해 적는 거니 이런 상황 설명은 과감히 덜어내주세요.",
      typography_21_0_0:
        "그 당시 머리 속을 스쳤던 생각만, 날것 그대로 적어주세요.",
      typography_22_0_0:
        "그리고 그 아래 칸에는 당시 그 상황에서 떠올랐던 생각이 나에게 얼마나 부정적인 영향을 주는지 적어보는 거예요.",
      typography_23_0_0:
        "0은 전혀 영향 주지 않음을, 100은 그 생각으로 인해 나의 삶이 흔들릴 정도로 강하게 영향을 주었다는 걸 뜻해요.",
      typography_24_0_0: "반응은 어떻게 적나요?",
      typography_25_0_0:
        "사건이 있었던 당시 느꼈던 감정이나 신체 반응을 적어요. 다음은 예시예요.",
      typography_25_3_0: "짜증, 슬픔, 분노, 머리가 아픔, 배가 싸함",
      typography_25_3_0_0: "짜증, 슬픔, 분노함",
      typography_25_3_0_1: "머리가 아픔, 배가 싸함",
      typography_25_5_0:
        "오후 동안 사무실 자리에 앉아서 일어나지 않음. 휴게실도 가지 않음.",
      typography_26_0_0: "감정(불안함, 우울함, 무기력함, 짜증, 외로움 등)이나",
      typography_27_0_0:
        "신체 감각(머리가 띵함, 배가 아픔, 어깨가 무거움, 눈이 빠질 것 같음 등)을 적어주시면 돼요",
      typography_28_0_0:
        "마지막으로 실제로 내가 한 행동을 적어요. 최대한 내가 했던 행동만을 적어보세요.",
      typography_28_0_0_1:
        "만약 즉각적으로 이어진 실제 행동이 없다면, 당시 어떻게 하고 싶었는지 충동을 적어 봐도 좋아요.",
      typography_29_0_0: "어떤가요? 방법을 아니 크게 어려운 부분은 없으시죠?",
      typography_29_0_0_1:
        "걱정마세요. 당분간 상항-생각-반응으로 분리하는 데에 익숙해지기 전까지는 프로그램이 분리를 도와줄 거예요.",
      typography_30_0_0:
        "좋아요. 우리는 앞으로 이 방법으로 기록하며 나의 생각을 마치 다른 사람의 것인 양 거리를 두고 바라보고 분석해 볼 거예요.",
      typography_31_0_0:
        "기본 원칙은 일상 속에서 제법 기분이 안 좋거나 부정적인 생각이 들었을 때 기록하는 것이에요.",
      typography_32_0_0:
        "자주 적을수록 스스로에 대해 더 많은 걸 알게 될 거예요.",
      typography_33_0_0:
        "하지만 정신없이 바쁜 우리의 일상을 생각해보면, 부정적인 일을 겪을 때마다 <생각기록지>를 열고 기록하긴 쉽진 않을텐데요.",
      typography_34_0_0:
        "그럴 때는 하루의 끝에서 <생각기록지>를 열고 오늘 하루 부정적인 순간은 없었는지 떠올려 적어보는 게 도움이 된답니다.",
      typography_35_0_0:
        "하루를 마무리하며 잊지 않고 작성할 수 있도록 푸시 알림으로 알려드릴게요.",
      typography_36_0_0: "알림을 허용해주세요.",
      typography_37_0_0:
        "언제 알려드리면 좋을까요? 원하는 시간을 모두 선택해주세요.",
      typography_38_0_0:
        "자, 이제 다음 활동인 <생각기록지>에서 앞서 말씀주셨던 나의 고민이 어떤 상황에서 어떻게 나를 괴롭히는지 구체적으로 적어주세요.",
      typography_39_0_0: "그럼 <생각기록지>에서 만나요!",
    },
  },
};
