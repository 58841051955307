import {
  workflowApi,
  WORKFLOW_DOMAIN,
  WORKFLOW_HUMAN_CHECK_DETAIL_ENDPOINT,
} from "api/workflowApi";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import HumanCheckTask from "./HumanCheckTask";

export default function DisagreeTask({
  taskKey,
  isCompleted,
  workflowId,
  showDoneButton,
  isLast,
  workCompleteAction,
}: {
  taskKey: string;
  isCompleted?: boolean;
  workflowId: string;
  showDoneButton?: boolean;
  isLast?: boolean;
  workCompleteAction: () => void;
}) {
  const queryClient = useQueryClient();
  const patientState = useRecoilValue(patientAtom);

  const disagreeTaskCompleteQueryFn = useCallback(
    () =>
      workflowApi.disagreeTaskComplete(
        `${patientState?.patientId}`,
        workflowId,
        taskKey
      ),
    [patientState?.patientId, workflowId, taskKey]
  );

  const { mutate: disagreeTaskComplete } = useMutation(
    disagreeTaskCompleteQueryFn,
    {
      onSuccess: () => {
        console.log("COMPLETE DISAGREE TASK");
        queryClient.invalidateQueries([
          WORKFLOW_DOMAIN,
          WORKFLOW_HUMAN_CHECK_DETAIL_ENDPOINT,
        ]);
        if (isLast) {
          workCompleteAction();
        }
      },
    }
  );

  return (
    <HumanCheckTask
      taskKey={taskKey}
      isCompleted={isCompleted}
      showDoneButton={showDoneButton}
      taskCompleteAction={disagreeTaskComplete}
    />
  );
}
