import Typography from "@mui/joy/Typography";
import { memo } from "react";
import { Stack } from "@mui/joy";
import { Status } from "./TaskItemTemplate";
import dayjs from "dayjs";
import useOpenThoughtRecord from "hooks/useOpenThoughtRecord";
import useOpenMeditationRecord from "hooks/useOpenMeditationRecord";
import useOpenSegmentationNote from "../../hooks/useOpenSegmentationNote";

function ToolItem({
  thoughtRecordKey,
  meditationRecordKey,
  segmentationNoteKey,
  title,
  date,
  durationMinutes,
  isOpen,
  isDone,
  isOldVersion,
}: {
  thoughtRecordKey?: string;
  meditationRecordKey?: string;
  segmentationNoteKey?: string;
  title: string;
  date: string;
  durationMinutes?: number;
  isOpen: boolean;
  isDone: boolean;
  isOldVersion?: boolean;
}) {
  const openThoughtRecord = useOpenThoughtRecord(`${thoughtRecordKey}`);
  const openSegmentationNote = useOpenSegmentationNote(
    `${segmentationNoteKey}`
  );
  const openMeditationRecord = useOpenMeditationRecord(
    `${meditationRecordKey}`,
    isOldVersion
  );

  return (
    <Stack
      direction="row"
      alignItems={"center"}
      sx={{
        cursor: "pointer",
        px: "12px",
        py: "8px",
        borderRadius: "12px",
        "&:hover": {
          backgroundColor: "background.level1",
        },
      }}
      onClick={() => {
        if (thoughtRecordKey) {
          openThoughtRecord();
        } else if (meditationRecordKey) {
          openMeditationRecord();
        } else if (segmentationNoteKey) {
          openSegmentationNote();
        }
      }}
    >
      <Stack
        direction="row"
        alignItems={"center"}
        spacing={"8px"}
        sx={{ flex: 1 }}
      >
        <Status isOpen={isOpen} isDone={isDone} />

        <Typography
          sx={{
            flex: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
          }}
          fontWeight={500}
        >
          <Typography
            level="body-md"
            sx={{ flex: 1, opacity: 0.6, mr: "8px" }}
            fontWeight={500}
          >
            {dayjs(date).format("M/D")}
          </Typography>

          <Typography level="body-md" fontWeight={500}>
            {title}
          </Typography>
          {durationMinutes !== undefined && durationMinutes > 0 && (
            <Typography
              sx={{ flex: 1, opacity: 0.6, ml: "4px" }}
              fontWeight={500}
            >
              {`∙${durationMinutes}분`}
            </Typography>
          )}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default memo(ToolItem);