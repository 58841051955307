import {
  DividerLine,
  LineWithSolidLabel,
  SolidLabelLine,
  TextareaLine,
  TypographyFromDataLine,
  TypographyLine,
} from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithTextarea,
} from "../../BlockComponent";

import { AutomaticThought, LastCard } from "../common";

export const Content_9_2_A = [
  InstructionWithContent(`typography_0`, AutomaticThought({ id: "1" })),
  Instruction("typography_1"),
  Instruction(`typography_2`),
  Instruction(`typography_2_1`),

  Header1(`typography_3`),
  Instruction(`typography_4`),
  Instruction(`typography_5`),
  InstructionWithContent("typography_6", [
    ...Array.from({ length: 2 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`예시`, { labelIndex: each }),
      TypographyLine(`typography_6_${each}`),
    ]),
  ]),
  InstructionWithTextarea(`typography_7`, { id: "이해 노력" }),
  Instruction(`typography_8`),
  Instruction(`typography_9`),
  Instruction(`typography_10`),
  Instruction(`typography_11`),
  Instruction(`typography_12`),
  Instruction(`typography_13`),
  InstructionWithContent("typography_14", [
    ...LineWithSolidLabel(
      "나의 노력",
      TypographyFromDataLine({ A: { id: "이해 노력" } }, "$A$")
    ),
    ...LineWithSolidLabel(
      "노력의 결과",
      TextareaLine({ id: "이해 노력 결과" })
    ),
  ]),

  Header1(`typography_15`),
  Instruction(`typography_16`),
  InstructionWithContent("typography_17", [
    ...Array.from({ length: 2 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`예시`, { labelIndex: each }),
      TypographyLine(`typography_17_${each}`),
    ]),
  ]),
  InstructionWithTextarea(`typography_18`, { id: "제거 노력" }),
  Instruction(`typography_19`),
  InstructionWithContent("typography_20", [
    ...LineWithSolidLabel(
      "나의 노력",
      TypographyFromDataLine({ A: { id: "제거 노력" } }, "$A$")
    ),
    ...LineWithSolidLabel(
      "노력의 결과",
      TextareaLine({ id: "제거 노력 결과" })
    ),
  ]),

  Header1(`typography_21`),
  Instruction(`typography_22`),
  Instruction(`typography_23`),
  Instruction(`typography_24`),
  InstructionWithContent("typography_25", [
    ...Array.from({ length: 2 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`예시`, { labelIndex: each }),
      TypographyLine(`typography_25_${each}`),
    ]),
  ]),
  InstructionWithTextarea(`typography_26`, { id: "통제 노력" }),
  Instruction(`typography_27`),
  InstructionWithContent("typography_28", [
    ...LineWithSolidLabel(
      "나의 노력",
      TypographyFromDataLine({ A: { id: "통제 노력" } }, "$A$")
    ),
    ...LineWithSolidLabel(
      "노력의 결과",
      TextareaLine({ id: "통제 노력 결과" })
    ),
  ]),

  Header1(`typography_29`),
  Instruction(`typography_30`),
  Instruction(`typography_31`),
  Instruction(`typography_32`),
  InstructionWithContent("typography_33", [
    ...Array.from({ length: 2 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`예시`, { labelIndex: each }),
      TypographyLine(`typography_33_${each}`),
    ]),
  ]),
  InstructionWithTextarea(`typography_34`, { id: "회피 노력" }),
  Instruction(`typography_35`),
  InstructionWithContent("typography_36", [
    ...LineWithSolidLabel(
      "나의 노력",
      TypographyFromDataLine({ A: { id: "회피 노력" } }, "$A$")
    ),
    ...LineWithSolidLabel(
      "노력의 결과",
      TextareaLine({ id: "회피 노력 결과" })
    ),
  ]),

  Header1(`typography_37`),
  Instruction(`typography_38`),
  ...[
    { instruction: `typography_39`, id: "이해" },
    { instruction: `typography_40`, id: "제거" },
    { instruction: `typography_41`, id: "통제" },
    { instruction: `typography_42`, id: "회피" },
  ].map(({ instruction, id }) =>
    InstructionWithContent(instruction, [
      ...LineWithSolidLabel(
        "나의 노력",
        TypographyFromDataLine({ A: { id: `${id} 노력` } }, "$A$")
      ),
      ...LineWithSolidLabel(
        "노력의 결과",
        TypographyFromDataLine({ A: { id: `${id} 노력 결과` } }, "$A$")
      ),
    ])
  ),
  InstructionWithTextarea(`typography_43`),
  InstructionWithTextarea(`typography_44`),
  InstructionWithTextarea(`typography_45`),
  Instruction(`typography_46`),
  Instruction(`typography_47`),
  Instruction(`typography_48`),
  Instruction(`typography_49`),
  Instruction(`typography_50`),
  Instruction(`typography_51`),
  Instruction(`typography_52`),
  Instruction(`typography_53`),

  ...LastCard,
];
