export const translation_7_8_A = {
  en: {},
  ko: {
    v1: {
      "$A$ $B$": "$A$ $B$",
      typography_0_0_0: `앞서 우리는 이렇게 가치 행동을 계획했어요.`,
      typography_1_0_0: `$A$(을)를 실행한 뒤 다음으로 넘어가주세요. `,
      typography_2_0_0: `계획대로 잘 되었나요?`,
      typography_3_0_0: `계획한 것을 잘 해내셨군요. 축하해요.`,
      typography_4_0_0: `가치 행동을 마치고 난 후 어떻게 느끼고 생각했나요?`,
      typography_5_0_0: `어려운 점은 없었나요?`,
      typography_6_0_0: `앞으로 이 가치 행동을 반복한다면 삶에 어떠한 변화가 찾아올 것 같나요?`,
      typography_7_0_0: `좋아요. 잊지 마세요. 이러한 작은 경험들이 모여서 큰 변화를 만들어 낼 거예요.`,
      typography_8_0_0: `이렇게 한 걸음씩 나아가는 과정이 곧 내 삶을 능동적으로 꾸려나가는 여정이거든요.`,
      typography_9_0_0: `자, 이제 다음 활동으로 나아가봐요.`,
      typography_10_0_0: "계획한 대로 해내는 데에 문제가 있었나 보네요.",
      typography_11_0_0: `괜찮아요. 중요한 건 이렇게 도전하는 것부터가 가치로 나아가는 여정의 일부라는 사실이에요.`,
      typography_12_0_0: `시도하는 삶과 시도조차 하지 않는 삶은 완전히 다른 삶의 이야기를 만들어 낸답니다.`,
      typography_13_0_0: `다음번엔 조금 더 잘 해낼 수 있을 거예요.`,
      typography_14_0_0: "어떤 이유로 가치 행동을 계획대로 마칠 수 없었나요?",
      typography_15_0_0: `좋아요. 고생 많으셨어요.`,
    },
  },
};
