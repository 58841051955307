import { translation_meditation } from "./meditation";

export const translation_8_5_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "세 번째 연습은 어땠나요?",
      typography_1_0_0: "생각에 정신이 팔려있었다고요?",
      typography_2_0_0: "괜찮아요. 그걸 알아차렸다는 점이 중요하니까요.",
      typography_3_0_0:
        "이 연습 이전에는 그것조차 전혀 알아차리지 못했다는 걸 떠올려보세요.",
      typography_4_0_0: `우리는 조금씩 생각과 거리를 두는 중이랍니다.`,
      typography_5_0_0: "오늘은 비유를 두 가지 더 소개해보려고 해요.",
      typography_6_0_0:
        "같은 연습을 하더라도 자신에게 더 잘 맞는 비유가 있답니다.",
      typography_7_0_0:
        "무엇이든 괜찮으니, 나의 머릿속에서 이미지화가 더 잘 되는 것을 선택하면 돼요.",
      typography_8_0_0: "하나씩 소개해볼게요.",
      typography_9_0_0: "생각의 하늘",
      typography_10_0_0: "이번엔 넓고 광활한 하늘을 상상해보세요.",
      typography_11_0_0: "시야를 가득 채울 만큼 넓은 하늘이에요.",
      typography_12_0_0: "나는 들판에 누워 하늘을 바라보고 있어요.",
      typography_13_0_0: "하늘에는 구름이 떠다니네요.",
      typography_14_0_0: "하늘은 나의 마음이고, 구름은 나의 생각이에요.",
      typography_15_0_0: "나는 들판에 누워 하늘을 바라봅니다.",
      typography_16_0_0:
        "나의 위치는 구름 위도, 하늘의 어느 한 곳도 아니라는 것을 명심하세요.",
      typography_17_0_0:
        "나는 그저 들판에 누워 나의 마음을 관찰하고 있을 뿐이에요.",
      typography_18_0_0: "구름은 다양한 모습을 하고 있어요.",
      typography_19_0_0:
        "맑고 깨끗한 뭉게구름도 있고, 때론 어둡고 우중충한 먹구름도 있네요.",
      typography_20_0_0: "어떤 구름이든 그것은 ‘나' 자신이 아니에요.",
      typography_21_0_0: "나는 구름을 그저 ‘생각이구나'하고 바라보면 됩니다.",
      typography_22_0_0:
        "특정한 구름을 쫓아가느라 들판을 뛰어다닐 필요도 없고,",
      typography_23_0_0: "구름 위에 올라가 헤맬 필요도 없어요.",
      typography_24_0_0: "들판에서 본 구름은 실제가 있는 물체처럼 보이지만",
      typography_25_0_0:
        "하늘 위에 올라 구름을 만져보려고 하면 결코 손에 잡히지 않는답니다.",
      typography_26_0_0: "생각은 실제가 아니에요. 하나의 심리적 사건일 뿐이죠.",
      typography_27_0_0:
        "이 과정에서 분명히 나도 모르는 사이 들판을 뛰어다니는 순간이 있을 거예요.",
      typography_28_0_0: `만약 내가 들판에 가만히 누워 구름을 바라보고 있지 않다는 걸 알아차린다면,`,
      typography_29_0_0: "다시 들판에 누워 떠다니는 구름을 바라보세요.",
      typography_30_0_0: "생각의 공장",
      typography_31_0_0:
        "이번엔 넓은 들판 위에 아주 넓은 공장이 있다고 상상해보세요.",
      typography_32_0_0: "메아리가 울리는 거대한 공장이에요.",
      typography_33_0_0: `넓은 공장 안에는 컨베이어벨트가 한 방향으로 이동하고 있어요.`,
      typography_34_0_0: `벨트 위에는 박스들이 놓여있네요.`,
      typography_35_0_0: `박스 안에는 나의 생각이 담겨있습니다.`,
      typography_36_0_0: `나는 컨베이어벨트 옆에 앉아 흘러가는 박스들을 가만히 바라봅니다.`,
      typography_37_0_0: `나의 위치는 벨트 위도, 박스 속도 아닙니다.`,
      typography_38_0_0: `나는 그저 컨베이어벨트 옆에서 나의 마음을 관찰하고 있을 뿐이에요.`,
      typography_39_0_0: `이동하는 박스는 다양해요.`,
      typography_40_0_0: `작은 박스도 있고, 큰 박스도 있고, 정말 큰 박스도 있네요.`,
      typography_41_0_0: `어떤 박스든 그것은 '나' 자신이 아니에요.`,
      typography_42_0_0: `박스를 바라보다 보면 아마 나도 모르는 사이에 벨트 위에 올라타 있거나`,
      typography_43_0_0: `어느새 박스 안에 들어가 허우적거리고 있는 순간을 발견하게 될 겁니다.`,
      typography_44_0_0: `만약 그렇다면, 그 사실을 알아차리고 다시 나의 자리로 돌아오면 됩니다.`,
      typography_45_0_0: `역시나 우리가 해야 할 일은 생각이 ‘나’ 자신이 아니라는 점,`,
      typography_46_0_0: `그 생각들은 우리 마음속에 떠오르는 하나의 심리적 사건이라는 점을 이해하며 내면을 바라보는 것입니다.`,
      typography_47_0_0: `네 번째 연습`,
      typography_48_0_0: `자, 오늘은 ‘생각의 하늘', ‘생각의 공장' 중 하나를 선택해 연습해볼까요?`,
      typography_49_0_0: `어떤 이미지가 더 와닿으시나요?`,
      typography_50_0_0: `오늘은 20분 정도 연습해볼게요.`,
      ...translation_meditation.ko.v2,
    },
  },
};
