import { Box } from "@mui/joy";
import HighlightIndicator from "./HighlightIndicator";
import { ProgramContentType } from "data/BlockComponent";
import { isBlockUserFieldFilled } from "logic/logics";
import { motion } from "framer-motion";
import { useRecoilState } from "recoil";
import { permanentDataAtom } from "recoil/permanentDataAtom";

export default function ProgressBarHighlight({
  taskKey,
  data,
  highlightIndexList,
  highlightColor,
  highlightGradientColor,
  showIndicator,
  onClick,
}: {
  taskKey: string;
  data?: ProgramContentType[];
  highlightIndexList: number[];
  highlightColor?: string;
  highlightGradientColor?: string;
  showIndicator?: boolean;
  onClick: (highlightIndex: number) => void;
}) {
  const [permanentDataState, setPermanentDataState] =
    useRecoilState(permanentDataAtom);

  const highlightWidth = `${100 / (data || []).length}%`;

  function getPercentage(index: number) {
    if (data) {
      return (index / data.length) * 100;
    }
    return 0;
  }

  const showIndicatorAnimation =
    !permanentDataState?.isHighlghtIndicatorClicked;

  return (
    <>
      {highlightIndexList.map((highlightIndex) => (
        <div key={`${taskKey}_highlight_click_${highlightIndex}`}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              left: `${getPercentage(highlightIndex)}%`,
              width: highlightWidth,
              height: "16px",
              backgroundColor: "transparent",
            }}
            onClick={() => {
              onClick(highlightIndex);
              setPermanentDataState((state) => ({
                ...state,
                isHighlghtIndicatorClicked: true,
              }));
            }}
          >
            {showIndicator &&
              data &&
              data[highlightIndex] &&
              !isBlockUserFieldFilled(data[highlightIndex]) && (
                <motion.div
                  style={{
                    position: "absolute",
                    transform: "translateX(-50%)",
                    left: "50%",
                    cursor: "pointer",
                  }}
                  initial={{ top: "10px" }}
                  animate={{
                    top: "15px",
                  }}
                  transition={{
                    duration: showIndicatorAnimation ? 0.5 : 0,
                    repeat: Infinity,
                    repeatType: "reverse",
                  }}
                >
                  <HighlightIndicator />
                </motion.div>
              )}
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              left: `${getPercentage(highlightIndex)}%`,
              width: highlightWidth,
              height: "100%",
              ...(highlightColor && { backgroundColor: highlightColor }),
              ...(highlightGradientColor && {
                background: highlightGradientColor,
              }),
              pointerEvents: "none",
            }}
          ></Box>
        </div>
      ))}
    </>
  );
}
