export const translation_4_4_3_A = {
  en: {
    v1: {
      typography_0_0_0:
        "Negativity tends to pack a bigger punch than positivity.",
      typography_1_0_0:
        "When it comes to grabbing our attention, shaping our memories, influencing our emotions, and guiding our decisions, the negative stuff often overshadows the positive.",
      typography_2_0_0: `This is what experts call "Negative Bias,"`,
      typography_3_0_0:
        "a pretty common phenomenon across various aspects of life. It definitely serves a bigger purpose.",
      typography_4_0_0: "Instinct and Negative Bias",
      typography_5_0_0:
        "Some research points out that negative bias might have evolved as a survival mechanism.",
      typography_6_0_0:
        "The more we focus on negative things, the better we get at dodging danger.",
      typography_7_0_0:
        "Think about your daily routine. How often do you get deeply affected by fun, positive stuff?",
      typography_8_0_0:
        "Now, compare that to your reaction to a loud noise, someone shouting, or a stranger's presence.",
      typography_9_0_0: "We usually react more to such negative things.",
      typography_10_0_0:
        "In modern society, this is still true, and it's not hard to imagine how focusing on certain types of information was more advantageous for survival in ancient times.",
      typography_11_0_0:
        "Understanding how negative bias has become embedded in our minds is not a difficult task.",
      typography_12_0_0: "When Negative Bias overpowers",
      typography_13_0_0:
        "Our natural focus on negative aspects can easily lead us into a thinking trap.",
      typography_14_0_0: "This is the 'Negative Bias' trap,",
      typography_15_0_0:
        "where we fall into patterns of 'inaccurate thoughts.'",
      typography_16_0_0: 'Imagine someone who thinks "The world is unsafe."',
      typography_17_0_0: "They're likely to have constant worries like,",
      typography_18_0_0:
        "“What if something terrible happens?” or “What if I can’t deal with what comes next?”",
      typography_19_0_0:
        'For someone who believes "I am not enough," they might have automatic thoughts like, "What if I fail again? What if I lose my job?"',
      typography_20_0_0:
        "In this way, we often worry like fortune-tellers, predicting negative events in the future.",
      typography_21_0_0: "The spiral of negative predictions",
      typography_22_0_0:
        "At times, these negative predictions can spiral out of control.",
      typography_23_0_0:
        "Panic disorder is a prime example of this. Panic attacks often start with symptoms like shortness of breath or a racing heart.",
      typography_24_0_0: `When you start feeling these sensations, thoughts like, "What if it gets worse?" or "What if I can't control it?" start to emerge.`,
      typography_25_0_0: `These thoughts can quickly escalate to extreme levels.`,
      typography_26_0_0: `Thoughts like, "I might actually die," are common, and feeling like you're going to die is a classic symptom of panic attacks.`,
      typography_27_0_0:
        "In this way, we sometimes predict not just negative events, but even catastrophes that feel like the worst possible scenarios.",
      typography_28_0_0: "Negative predictions creating reality",
      typography_29_0_0:
        "In any thinking trap, negative predictions can lead to a negative reality.",
      typography_30_0_0: "Take someone afraid of rejection on a date.",
      typography_31_0_0: `They might be filled with thoughts like, “What if I screw up?”, “What if I stutter?” or “What if the food isn’t good?”`,
      typography_32_0_0:
        'Among these is also a catastrophic prediction: "I’ll never be able to date anyone."',
      typography_33_0_0:
        "But, of course, with such thoughts dominating your mind, it’s unlikely that the date will go well.",
      typography_34_0_0: "Eventually, the date ends up being a timid affair.",
      typography_35_0_0: `And as these experiences repeat, the prediction "I’ll never be able to date anyone" turns into reality.`,
      typography_36_0_0: "Distancing from Negative Bias",
      typography_37_0_0:
        "Breaking free from the negative bias trap can be more straightforward than you think.",
      typography_38_0_0:
        "Before getting overwhelmed by those popping thoughts,",
      typography_39_0_0: "recognize them as they arise.",
      typography_40_0_0: "Step back and objectively assess these thoughts.",
      typography_41_0_0:
        "How likely is it that these worst-case scenarios will happen?",
      typography_42_0_0:
        "Evaluating the odds can be quite enlightening. Remember, numbers give a clear reference point.",
      typography_43_0_0: `Sure, the probability is never zero, but we're not dealing with outlandish scenarios like alien invasions.`,
      typography_44_0_0:
        "After assessing the odds, consider how you would handle these situations if they did occur and who could help you.",
      typography_45_0_0:
        "Moving forward, we’ll systematically tackle negative bias traps.",
      typography_46_0_0:
        "If you can realistically assess the likelihood of negative events and prepare for them,",
      typography_47_0_0:
        "you'll effectively reduce the anxiety associated with a negative future.",
      typography_48_0_0:
        "So, how about we start looking at the real chances of those negative outcomes you've been worrying about?",
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "다른 모든 요소가 동일하다면, 부정은 항상 긍정보다 강합니다.",
      typography_1_0_0:
        "부정적인 정보는 긍정적인 정보보다 주의, 지각, 기억, 감정, 행동, 동기, 의사 결정 등에 대해 더 많은 영향을 미치죠.",
      typography_2_0_0: "인지심리학에서는 이를 ‘부정 편향’이라고 불러요.",
      typography_3_0_0:
        "부정 편향은 매우 다양한 영역에서 보편적으로 관찰되죠. 이 이상한 능력은 분명 다른 목적을 가진 것이 틀림없어요.",
      typography_4_0_0: "부정 편향은 본능",
      typography_5_0_0:
        "일부 연구들은 부정 편향이 진화 과정에서 나타난 산물이라고 설명해요.",
      typography_6_0_0:
        "우리가 부정적인 정보에 집중할수록 우리는 위험으로부터 더 빠르게, 더 효율적으로 대처할 수 있기 때문이죠.",
      typography_7_0_0:
        "평소 걷는 길을 생각해보세요. 길에서 재밌고 긍정적인 일 때문에 감정적으로 아주 크게 각성되었던 적은 얼마나 많나요?",
      typography_8_0_0:
        "반대로 지나가는 차의 큰 소리, 누군가의 고함, 낯선 사람의 존재에 당신은 얼마나 크게 반응하나요?",
      typography_9_0_0:
        "우리가 부정적인 정보에 더 많이 반응하고 있음은 쉽게 알 수 있을 거예요.",
      typography_10_0_0:
        "현대 사회에서도 그러한데, 원시 사회에서 들판을 걸을 때는 어떤 정보에 집중하는 것이 생존에 더 유리한지 생각해보세요.",
      typography_11_0_0:
        "부정 편향이 어떻게 우리의 마음에 자리 잡았는지 이해하는 건 어려운 일이 아니에요.",
      typography_12_0_0: "왜곡된 부정 편향",
      typography_13_0_0:
        "부정적인 것을 크게 보는 우리의 본능은 부정적인 생각을 만나면 아주 쉽게 생각함정을 만들어내게 돼요.",
      typography_14_0_0: "문자 그대로, '부정 편향'이라는 생각함정이죠.",
      typography_15_0_0:
        "부정 편향은 '정확하지 않은 생각'에 해당하는 생각함정이에요.",
      typography_16_0_0:
        '예를 들어볼게요. "세상은 안전하지 않다"라고 믿고 있는 사람이 있다고 생각해볼게요.',
      typography_17_0_0: "이 사람에게서 부정 편향은 어떻게 발현될까요?",
      typography_18_0_0:
        "아마 “큰일이 나면 어쩌지?”, “무슨 일이 생길까 봐 걱정돼”, “나쁜 일이 벌어지는 건 아니겠지?”와 같은 자동적 사고가 나타날 거예요.",
      typography_19_0_0:
        '반대로 "나는 부족하다"라고 믿고 있는 사람의 경우에는, "이번에도 못 해내면 어떻게 하지? 회사에서 잘리는 게 아닐까?"와 같은 자동적 사고가 나타날 거예요.',
      typography_20_0_0:
        "이처럼 우리는 미래에 부정적인 일이 발생할 것이라고 점쟁이처럼 예언하며 걱정하곤 한답니다.",
      typography_21_0_0: "부정적 예언이 재앙으로",
      typography_22_0_0:
        "때로는 이러한 부정적인 예언이 파국으로 치닫기도 해요.",
      typography_23_0_0:
        "공황장애는 그 대표적인 사례죠. 공황은 흔히 호흡이 가쁘거나 가슴이 두근거리면서 발생하곤 해요.",
      typography_24_0_0:
        "그런 감각이 느껴지면 “아, 더 심해지면 어쩌지?”, “조절이 안 되면 어떻게 하지?”와 같은 생각이 피어나죠.",
      typography_25_0_0:
        "그리고 그 생각은 매우 빠른 속도로 아주 극단적인 생각으로 발전하곤 해요.",
      typography_26_0_0:
        '“이러다간 죽을 것 같아"와 같은 생각으로 말이에요. 죽을 것 같은 느낌은 공황의 대표적인 증상이에요.',
      typography_27_0_0:
        "이처럼 단순히 부정적인 일을 예측하는 것을 넘어 재앙에 가까운 최악의 상황까지도 예상하기도 한답니다.",
      typography_28_0_0: "부정적 예측이 만드는 부정적 현실",
      typography_29_0_0:
        "모든 생각함정이 그렇듯 부정적인 예측은 정말로 부정적인 현실이 되곤 해요.",
      typography_30_0_0:
        "데이트를 나가기 전, 파트너로부터 거절을 당할 것을 두려워하는 사람을 생각해볼까요?",
      typography_31_0_0:
        "그 사람의 머릿속에는 “실수하면 어쩌지”, “말을 더듬을 것 같아”, “음식이 맛 없으면 어떻게 하지”와 같은 부정적인 예언뿐이에요.",
      typography_32_0_0:
        '그중에는 "나는 그 누구와도 만나지 못할 거야”와 같은 재앙적 예언도 있어요.',
      typography_33_0_0:
        "하지만 머릿속에 그러한 생각이 가득하면 당연히 데이트는 성공적으로 진행될 리 없겠죠.",
      typography_34_0_0: "결국 데이트는 위축된 모습으로 끝나고 말아요.",
      typography_35_0_0:
        "그러한 경험이 반복되고 결국 “누구와도 만나지 못할 거야”라는 예측은 현실이 되어버리죠.",
      typography_36_0_0: "부정 편향으로부터 거리두기",
      typography_37_0_0:
        "부정 편향 생각함정을 벗어나는 방법은 생각보다 간단해요.",
      typography_38_0_0:
        "팝콘처럼 마음속에 떠오른 생각에 강하게 매몰되기 전에,",
      typography_39_0_0: "그러한 생각이 마음속에 떠올랐음을 인지하고,",
      typography_40_0_0:
        "한 걸음 떨어져서 그 생각이 어떠한 생각인지 객관적으로 살펴보는 것이죠.",
      typography_41_0_0:
        "부정적인 일과 최악의 사건이 벌어질 확률을 검토하면서요.",
      typography_42_0_0:
        "이는 생각보다 유용해요. 숫자는 거짓말을 하지 않기 때문이죠. 우리 뇌에 아주 명시적인 기준을 만들어주기도 한답니다.",
      typography_43_0_0:
        '물론 그럼에도 확률은 0%가 될 순 없겠죠. 우리는 "외계인이 침공할 거야" 같은 허황된 걱정을 하고 있는 게 아니니까요.',
      typography_44_0_0:
        "따라서 확률을 검토한 후, 만약 그런 일이 정말 발생한다면 어떻게 대처할 수 있을지 나는 누구에게 도움을 청할 수 있는지 등을 함께 살펴보아야 해요.",
      typography_45_0_0:
        "우리는 앞으로 체계적인 방법을 통해 부정 편향 생각함정들을 공략할 건데요.",
      typography_46_0_0:
        "만약 여러분이 부정적인 일이 발생할 확률을 현실적으로 검토할 수 있고, 그에 대한 대처 전략도 마련할 수 있다면,",
      typography_47_0_0:
        "부정적인 미래가 당신을 불안하게 하는 일을 효과적으로 줄일 수 있을 거랍니다.",
      typography_48_0_0:
        "자, 그러면 이제 내가 예상한 부정적인 결말들의 실질적인 가능성을 살펴볼까요?",
    },
  },
};
