export const translation_7_1_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "이제 막 자동적 사고를 찾아서 이와 거리를 둬 보기 시작했을 거예요.",
      typography_1_0_0:
        "좋아요. 이제 시작이랍니다. 앞으로 자동적 사고를 계속 찾아내며 디스턴싱을 연습해 나갈 거예요.",
      typography_2_0_0:
        "그런데 사실 자동적 사고라고 반드시 부정적인 생각인 건 아니랍니다.",
      typography_3_0_0:
        "자동적 사고에는 나를 행복하고 기쁘게 하는 것들도 많아요.",
      typography_4_0_0:
        "우리는 지금부터 <생각기록지>에 긍정적인 기록들도 적어나갈 거예요.",
      typography_5_0_0:
        "그리고 긍정적인 자동적 사고도 하나씩 구체화할 거랍니다.",
      typography_6_0_0:
        "우리가 긍정적인 자동적 사고에도 집중하는 이유는 다음과 같아요.",
      typography_7_0_0: "첫째, 분리하기 연습",
      typography_8_0_0:
        "앞서 <생각기록지>에서 마음속에 떠오르는 생각을 상황-생각-반응으로 나누는 연습을 했을 텐데요.",
      typography_9_0_0:
        "이처럼 생각을 각각의 요소로 분리하는 작업은 생각을 하나의 심리적 사건으로 바라보도록 하는 데에 큰 도움이 되어요.",
      typography_10_0_0:
        "이러한 연습은 반드시 부정적인 생각에 대해서만 진행해야 하는 건 아니랍니다.",
      typography_11_0_0:
        "우리는 슬프고 우울하고 불안한 감정 외에도 기쁘고 행복하고 뿌듯한 감정도 느끼는 존재예요.",
      typography_12_0_0:
        "그렇기에 행복한 감정들 또한 꼼꼼하게 살펴보고 이를 개별 요소들로 분리하는 연습은 디스턴싱을 더 잘 훈련할 수 있게 도와줄 거랍니다.",
      typography_13_0_0: "둘째, 가치 찾기",
      typography_14_0_0:
        "더 중요한 목적도 있답니다. 바로 긍정적인 기록을 통해 ‘가치'를 찾는 것이에요.",
      typography_15_0_0: "내가 삶에서 정말로 중요하게 여기는 가치들 말이에요.",
      typography_16_0_0:
        "많은 사람들이 부정적인 생각과 감정을 다스리기 위해 디스턴싱을 시작하지만 사실 그게 궁극적인 목표는 아니랍니다.",
      typography_17_0_0:
        "더 중요한 건 내가 원하는 가치에 맞는 삶을 사는 것이지요.",
      typography_18_0_0:
        "기록을 하다 보면 잊고 있었던 삶의 행복, 방향성, 목적 등을 되새길 수 있을 거예요.",
      typography_19_0_0:
        "이 가치는 부정적인 생각에서 벗어나 삶의 변화를 만들어내는 데에 중요한 자원이 될 거랍니다.",
      typography_20_0_0: "셋째, 가치를 향한 삶",
      typography_21_0_0: "가치를 찾았다면 실제로 가치를 향해 나아가야겠죠?",
      typography_22_0_0:
        "우리는 앞으로 삶에서 실질적인 변화를 만들어낼 거예요.",
      typography_23_0_0:
        "미뤄왔던 일들, 도전해보지 못했던 것들, 그리고 내가 진정으로 원했던 것들을 하나씩 해나갈 거랍니다.",
      typography_24_0_0:
        "디스턴싱의 최종적인 목적은 단순히 부정적인 생각과 거리를 두는 것을 넘어, 내가 원하는 삶으로 나아가는 힘을 기르는 것이니까요.",
      typography_25_0_0:
        "이때 가치는 그 변화를 위한 중요한 나침반이 될 거랍니다.",
      typography_26_0_0: "시작은 긍정 기록으로부터",
      typography_27_0_0:
        "가치에 대해서는 이후에 조금 더 자세히 살펴볼 기회가 있을 거랍니다.",
      typography_28_0_0: `오늘은 “긍정적인 경험에 대해서도 <생각기록지>로 디스턴싱 연습을 하는 것이 중요하다"라는 점만 기억하면 된답니다.`,
      typography_29_0_0:
        "어떤 경험이든 좋아요. 즐거움을 느꼈을 때, 성취감을 느꼈을 때, 여유와 평온함을 느꼈을 때.",
      typography_30_0_0:
        "나를 행복하고 기쁘게 하는 순간들을 차곡차곡 쌓아보세요.",
      typography_31_0_0: "중요한 보물이 될 거랍니다.",
      typography_31_0_0_1: "중요한 차이",
      typography_31_0_0_2:
        "다만, 한 가지 유의해야 할 게 있어요. 긍정 기록을 적을 때에는 사건의 발단부터 긍정적이었던 경험을 적어주세요.",
      typography_31_0_0_3: `많은 분들이 디스턴싱이 익숙해지며 "부정적인 생각이 떠올랐지만, 이를 잘 다스리고 극복해 냈다"라며 긍정 기록을 적어주시곤 하는데요.`,
      typography_31_0_0_4:
        "이것을 긍정 기록이라고 보기에는 조금 아쉬워요. 긍정 기록이라기보다는 거리두기를 잘한 부정 기록에 가깝거든요.",
      typography_31_0_0_5:
        "때문에 '상황'부터 긍정적이었던 경험을 적는 것이 중요해요. 그래야 삶에서 내가 중요시하는 것이 무엇인지 찾아나갈 수 있답니다.",
      typography_32_0_0: "자, 그러면 지금 바로 긍정 기록을 작성하러 가볼까요?",
    },
  },
};
