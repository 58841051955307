import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import { Box, Stack, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { usePrevious } from "@uidotdev/usehooks";
import { addAlpha } from "styles/hexOpacity";
import Button from "./Button";

function ActionButton({
  text,
  color,
  action,
}: {
  text: string;
  color?: string;
  action: () => void;
}) {
  return (
    <Box
      sx={{
        flex: 1,
        py: "14px",
        "&:hover": {
          opacity: 0.5,
        },
        cursor: "pointer",
      }}
      onClick={action}
    >
      <Typography
        sx={{ color: color || "#222222", fontSize: "16px", fontWeight: 700 }}
      >
        {text}
      </Typography>
    </Box>
  );
}

export default function ButtonWithPopup({
  size,
  buttonText,
  hasShadow,
  disabled,
  loading,
  color,
  title,
  subtitle,
  action,
  actionText,
  isDanger,
  cancelText,
  onClose,
  render,
  usePopup,
}: {
  size?: "sm" | "md";
  buttonText?: string;
  hasShadow?: boolean;
  disabled?: boolean;
  loading?: boolean;
  color?: "black" | "gradient-green" | "transparent";
  title?: string;
  subtitle?: string;
  action: () => void;
  actionText?: string;
  isDanger?: boolean;
  cancelText?: string;
  onClose?: () => void;
  render?: React.ReactNode;
  usePopup?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [isActionButtonLoading, setIsActionButtonLoading] = useState(false);
  const previousOpen = usePrevious(open);

  const [backdropOpacity, setBackdropOpacity] = useState(0);

  useEffect(() => {
    if (previousOpen && !open) {
      if (onClose) {
        onClose();
      }
    }
  }, [open]);

  const handleClick = () => {
    if (usePopup) {
      setOpen(true);
      setBackdropOpacity(1);
    } else {
      action();
    }
  };

  return (
    <React.Fragment>
      {render ? (
        <div
          style={{ ...(disabled && { pointerEvents: "none", opacity: 0.2 }) }}
          onClick={(e) => {
            if (!disabled) {
              e.stopPropagation();
              handleClick();
            }
          }}
        >
          {render}
        </div>
      ) : (
        <Button
          buttonText={buttonText || ""}
          onClick={handleClick}
          size={size}
          color={color}
          disabled={disabled}
          hasShadow={hasShadow}
          loading={loading}
        />
      )}

      <Modal
        open={open}
        onClose={(e, r) => {
          if (r === "backdropClick") {
            (e as MouseEvent).stopPropagation();
          }

          setBackdropOpacity(0);
          setOpen(false);
        }}
        sx={{
          "&>.MuiModal-backdrop": {
            backdropFilter: "none",
            backgroundColor: addAlpha("#000000", 0.3),
            opacity: backdropOpacity,
            transition: "all 0.3s",
          },
        }}
      >
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            width: "100%",
            borderRadius: "20px",
            top: "unset",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
          })}
          onClick={(e) => {
            // e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Stack
            justifyContent={"center"}
            spacing="8px"
            sx={{ textAlign: "center" }}
          >
            {title && (
              <Typography
                sx={{ color: "#222222", fontWeight: 700, fontSize: "20px" }}
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography
                sx={{ color: "#222222", fontWeight: 500, fontSize: "16px" }}
              >
                {subtitle}
              </Typography>
            )}
          </Stack>

          <Stack
            direction="row"
            justifyContent={"center"}
            alignItems="center"
            spacing="8px"
            sx={{ textAlign: "center" }}
          >
            {cancelText && (
              <ActionButton
                text={cancelText}
                color={"#222222"}
                action={() => {
                  setOpen(false);
                }}
              />
            )}
            <Box
              sx={{
                width: "1px",
                height: "32px",
                backgroundColor: "black",
                opacity: 0.1,
              }}
            />
            {actionText && (
              <ActionButton
                text={actionText}
                color={isDanger ? "#FF3B30" : "#04BF0C"}
                action={() => {
                  action();
                  setOpen(false);
                }}
              />
            )}
          </Stack>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
