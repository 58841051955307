import { Button, Stack, Textarea, Typography } from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { isDev } from "config";

const TestSpeechToText: React.FC = () => {
  const [isRecording, setIsRecording] = useState(false);
  const websocketRef = useRef<WebSocket | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const sourceRef = useRef<MediaStreamAudioSourceNode | null>(null);
  const processRef = useRef<ScriptProcessorNode | null>(null);
  const [transcription, setTranscription] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const encoding = isSafari ? "LINEAR16" : "OGG_OPUS";
  const sampleRate = isSafari ? 8000 : 16000;
  const timeSlice = isSafari ? 1000 : 400;

  useEffect(() => {
    const setThemeColor = (color: string) => {
      let themeColorMetaTag = document.querySelector(
        'meta[name="theme-color"]'
      );
      if (!themeColorMetaTag) {
        themeColorMetaTag = document.createElement("meta");
        themeColorMetaTag.setAttribute("name", "theme-color");
        document.head.appendChild(themeColorMetaTag);
      }
      themeColorMetaTag.setAttribute("content", color);
    };

    setThemeColor("#F4F0E8");

    return () => setThemeColor("#E2E6E8");
  }, []);

  useEffect(() => {
    initializeWebSocket();
    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const initializeWebSocket = () => {
    let wsUri = isDev
      ? "wss://dev-api.distancing.im/stream/stt"
      : "wss://api.distancing.im/stream/stt";
    wsUri += `?encoding=${encoding}&sample_rate=${sampleRate}`;
    console.log(wsUri);
    const websocket = new WebSocket(wsUri);
    websocket.binaryType = "blob";

    websocket.onopen = (event) => {
      setStatus(`Connected to WebSocket server.`);
      startRecording();
    };

    websocket.onclose = (event) => {
      setStatus(`Disconnected from WebSocket server. ${event.reason}`);
      setIsRecording(false);
    };

    websocket.onmessage = (evt) => {
      try {
        console.log("Received: " + evt.data);
        const data = JSON.parse(evt.data);

        if (data.isFinal) {
          setTranscription(
            (prev) => prev + ` ${data.alternatives[0].text || ""}`
          );
        }
      } catch (e) {}
    };

    websocket.onerror = (evt) => {
      setStatus(`WebSocket Error: ${evt}`);
    };

    websocketRef.current = websocket;
  };

  const startRecording = () => {
    if (
      !websocketRef.current ||
      websocketRef.current.readyState != WebSocket.OPEN
    ) {
      initializeWebSocket();
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        if (isSafari) {
          audioContextRef.current = new AudioContext({
            sampleRate: sampleRate,
          });
          sourceRef.current =
            audioContextRef.current.createMediaStreamSource(stream);
          processRef.current = audioContextRef.current.createScriptProcessor(
            8192,
            1,
            1
          );

          processRef.current.onaudioprocess = (e: AudioProcessingEvent) => {
            const data = e.inputBuffer.getChannelData(0);
            const raw = Int16Array.from(data, (x) => x * 32767);
            if (
              websocketRef.current &&
              websocketRef.current.readyState === WebSocket.OPEN
            ) {
              websocketRef.current.send(raw);
            }
          };

          sourceRef.current.connect(processRef.current);
          processRef.current.connect(audioContextRef.current.destination);
        } else {
          const mediaRecorder = new MediaRecorder(stream, {
            audioBitsPerSecond: sampleRate,
          });

          mediaRecorder.ondataavailable = (e) => {
            if (
              websocketRef.current &&
              websocketRef.current.readyState === WebSocket.OPEN
            ) {
              websocketRef.current.send(e.data);
            }
          };

          mediaRecorder.onstop = () => {
            if (websocketRef.current) {
              websocketRef.current.send("EOS");
            }
          };

          mediaRecorder.start(timeSlice); // Send data every N ms
          mediaRecorderRef.current = mediaRecorder;
        }
        setIsRecording(true);
      })
      .catch((err) => {
        console.error("The following error occurred: " + err);
      });
  };

  const stopRecording = () => {
    if (isSafari) {
      if (processRef.current) {
        processRef.current.disconnect();
      }
      if (sourceRef.current) {
        sourceRef.current.disconnect();
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    } else {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
    }
    setIsRecording(false);
  };

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          height: "50%",
          px: "40px",
          pb: "36px",
        }}
        spacing="8px"
        alignItems="center"
      >
        <Stack
          sx={{
            width: "100%",
            flex: 1,
          }}
          justifyContent="center"
          alignItems={"center"}
          spacing="20px"
        >
          <Textarea
            placeholder={"음성 -> 텍스트 전환"}
            value={transcription}
            onChange={(e) => setTranscription(e.target.value)}
            autoFocus
            minRows={1}
            sx={{
              width: "100%",
              padding: 0,
              backgroundColor: "transparent",
              borderWidth: 0,
              boxShadow: "none",
              "--joy-focus-thickness": "0px",
              "--variant-borderWidth": "0px",
              "--Textarea-gap": "0px",
              minWidth: 0,
            }}
          />
        </Stack>

        <Stack direction="row" spacing="16px">
          <Button size="sm" onClick={startRecording} disabled={isRecording}>
            시작
          </Button>
          <Button size="sm" onClick={stopRecording} disabled={!isRecording}>
            중지
          </Button>
        </Stack>
        <Typography
          sx={{
            fontSize: "12px",
          }}
        >
          {status}
        </Typography>
      </Stack>
    </>
  );
};

export default TestSpeechToText;
