import { ProgramContentType } from "data/BlockComponent";
import { axiosInstance, CustomResponseFormat } from "api";
import { isBlockUserFieldFilled } from "logic/logics";

function getPart(data: ProgramContentType[]) {
  const startIndex = data.findIndex(
    (element) => element.blockId === `common_start`
  );
  const endIndex = data.findIndex(
    (element) => element.blockId === `common_end`
  );

  return data
    .slice(startIndex, endIndex + 1)
    .filter((element) => isBlockUserFieldFilled(element))
    .map((each) => ({ ...each, isShown: true }));
}

export default async function getBlocksFromSuccessfulActivity(taskKey: string) {
  try {
    const response = (await axiosInstance.get("/task/data/load", {
      params: { tk: `${taskKey}` },
    })) as CustomResponseFormat;
    if (response.code === 0) {
      const data = response.result.data.content;
      return getPart(data);
      // console.log(data);
    } else {
    }
  } catch (error: any) {}
}
