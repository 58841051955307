import { seqPostfix_ko } from "data/prefixData";

export const translation_104_1_A = {
  en: {},
  ko: {
    v1: {
      ...seqPostfix_ko,
      typography_0_0_0: "계획하기",
      typography_1_0_0:
        "좋아요. 이제 $A$(을)를 언제 어떻게 진행할지 계획해 볼게요.",
      typography_2_0_0:
        "$A$(은)는 10점 만점 중 얼마나 어려울 것이라고 생각하세요? (0점: 누워서 떡 먹기, 10점: 최선을 다해야 겨우 달성할 수 있을 정도)",
      typography_3_0_0:
        "$A$(을)를 누구와 함께할까요? 아니면 혼자 하는 활동일까요?",
      typography_4_0_0: "$A$(을)를 할 때에는 정확히 무얼 하는 게 좋을까요?",
      typography_5_0_0: "$A$(은)는 정확히 어디에서 하는 게 좋을까요?",
      typography_6_0_0:
        "$A$(은)는 언제 하는 게 좋을까요? 활동이 완료되면 이 일정 그대로 <활동기록지>에 업로드될 거예요.",
      typography_7_0_0: "$A$(을)를 하는 데에 예상되는 장애물이 있을까요?",
      typography_8_0_0:
        "만약 활동 도중 그 장애물을 만난다면 어떻게 해결할 수 있을까요?",
      typography_9_0_0: "정리하기",
      typography_10_0_0:
        "좋아요. $A$을(를) 해낼 계획을 정리하면 다음과 같겠네요.",
      "$A$ $B$": "$A$ $B$",
      typography_11_0_0: "좋아요. 이제 직접 계획한 활동을 해내어 볼 텐데요.",
      typography_12_0_0:
        "특별히 조정할 부분이 없다면 이제 바로 실천할 거랍니다.",
      typography_13_0_0:
        "계획한 시각 한 시간 전에 알려 드려 잊지 않게 도와드릴게요.",
      typography_14_0_0: "알림을 허용해주세요.",
      typography_15_0_0:
        "잊지 마세요. 해내든 해내지 못하든 모두 우리에게 도움이 될 귀중한 자료이니 일단 실천하는 것 자체가 중요하답니다.",
      typography_16_0_0: "그럼 한번 실천해보시고 다음 활동에서 다시 만나요.",
    },
  },
};
