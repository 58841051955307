import { thinkingTrapResource } from "translation/thinkingTrap";

export const translation_4_5_A = {
  en: {
    v1: {
      start: {
        ...thinkingTrapResource.en,
        start_typography_0_0_0:
          "Let's dive a bit deeper into our thoughts now.",
        start_typography_1_0_0:
          "We'll practice stepping back and examining our thoughts - looking at how they form, whether they're accurate, and if they're really helping us.",
        start_typography_2_0_0:
          "First, let's take a closer look at the automatic thoughts we've identified together.",
        start_typography_3_0_0:
          "Imagine treating these thoughts as if they're a third entity.",
        start_typography_4_0_0:
          "Instead of 'looking from the thought' as if it's part of you,",
        start_typography_5_0_0:
          "think of it as an event in your mind and focus on 'looking at the thought'.",
        start_typography_6_0_0:
          "Close your eyes for a moment and imagine placing your thought 1m away from you. Take it out of your mind, set it down a meter ahead, and then step back. Shall we picture this for 10 seconds?",
        start_typography_7_0_0:
          "Great. Now, let's take another look at how this thought came to be in our mind.",
      },
      end: {
        end_typography_0_0_0: "Wrapping Up",
        end_typography_1_0_0: "Let's revisit our automatic thoughts.",

        end_typographyFromData_2_0_0:
          "When you catch yourself having similar automatic thoughts, say, 'Ah, this is a $A$ trap I've already examined this thought, so I should not react to it.'",

        end_typography_3_0_0:
          "When you notice similar feelings or thoughts surfacing, can you try to distance yourself from them?",
        end_typography_4_0_0:
          "It might not be easy at first, but remember, thoughts are just thoughts, not who you are.",
        end_typography_5_0_0:
          "Thoughts are merely psychological events in our minds.",
        end_typography_6_0_0:
          "While thoughts are part of 'me,' the 'me' that holds these thoughts is much bigger.",
        end_typography_7_0_0:
          "Remember, as a bigger being than individual thoughts, 'I' can choose not to react to every thought that comes up.",
        end_typography_8_0_0:
          "Let's quietly review our automatic thoughts again.",
        end_typographyFromData_9_0_0:
          "You previously rated the impact of this thought as $A$. How much is it affecting you now?",
        end_typographyFromData_10_0_0:
          "When you fill out the <Thought Log> next time, check for any $A$ traps.",
        end_typography_11_0_0:
          "If you find it hard to distance yourself from your thoughts or identify thinking traps, let me know. I'm here to help.",
        end_typography_12_0_0:
          "With consistent practice, you'll gradually find yourself not reacting to these thoughts and creating space between you and them.",
        end_typography_13_0_0:
          "You've done well. Shall we wrap up this activity here?",
      },
    },
  },
  ko: {
    v1: {
      start: {
        ...thinkingTrapResource.ko,
        start_typography_0_0_0: "이제 조금 더 자세히 생각을 다뤄볼게요.",
        start_typography_1_0_0:
          "마음속에 떠오른 생각과 거리를 두고 그 생각이 어떻게 생겼는지, 정확한지, 나에게 도움이 되는지를 살펴보는 연습을 해 볼 거랍니다.",
        start_typography_2_0_0: "우선 함께 발견한 자동적 사고를 먼저 살펴보죠.",
        start_typography_3_0_0:
          "이제 생각을 제3의 대상처럼 대한다고 상상해보세요.",
        start_typography_4_0_0:
          "생각이 마치 나 자신인 것처럼 '생각에서 보기'보다는",
        start_typography_5_0_0:
          "생각은 마음속에 떠오르는 하나의 사건이라고 생각하고 '생각을 보기'에 집중하는 거죠.",
        start_typography_6_0_0:
          "잠시 눈을 감고, 나와 1m 떨어진 곳에 생각을 내려놓는 상상을 해보세요. 마음속에서 생각을 꺼내서, 1m 앞으로 가, 내려놓고 다시 돌아오는 거예요. 10초만 상상해볼까요?",
        start_typography_7_0_0:
          "좋아요. 이제 이 생각이 우리 마음속에 어떻게 만들어졌는지 다시 한번 살펴볼게요.",
      },
      end: {
        end_typography_0_0_0: "정리하기",
        end_typography_1_0_0: "다시 나의 자동적 사고를 살펴보죠.",

        end_typographyFromData_2_0_0: `위와 같은 자동적 사고를 알아차릴 때면 '아, 이건 "$A$" 생각함정이네.'라고 말해보세요.`,
        end_typography_2_0_0_1: `활동을 하기 전에는 "$A$"(이)라고 생각했어요. 활동을 마친 지금은 이 생각을 어떻게 다시 적어볼 수 있을까요?`,
        end_typography_2_0_0_2: `앞으로 비슷한 감정이나 생각이 마음속에 떠오르는 걸 인지하게 되면, 다시 적어본 생각을 떠올리며 이 생각으로부터 거리를 두려고 노력할 수 있을까요?`,
        end_typography_2_0_0_3: `활동을 하기 전에는 "$A$"(이)라고만 생각했어요. 활동을 마친 지금은 이 생각 뒤에 어떤 생각을 덧붙여 볼 수 있을까요? `,
        end_typography_2_0_0_4: `앞으로 비슷한 감정이나 생각이 마음속에 떠오르는 걸 인지하게 되면, 위에 적어본 생각을 떠올리며 이 생각으로부터 거리를 두려고 노력할 수 있을까요?`,
        end_typography_3_0_0:
          "앞으로 비슷한 감정이나 생각이 마음속에 떠오르는 걸 알아차리게 되면 거리를 두고 바라보고자 노력할 수 있을까요?",
        end_typography_3: `이제 중요한 이야기를 전하며 마무리해볼까 해요.`,
        end_typography_3_1: `때론 이렇게 살펴본 생각이 제법 정확할 수도 있답니다.`,
        end_typography_3_2: `또 때론 정말로 정확하지 않을 수도 있어요.`,
        end_typography_3_3: `중요한 건 그 내용이 아니랍니다.`,
        end_typography_3_4: `억지로 생각을 바꾸려고 노력할 필요없어요.`,
        end_typography_3_5: `우리가 이해해야 하는 건 만약 정말로 생각이 그토록 임의적이라면,`,
        end_typography_3_6: `우리가 마음속에 떠오른 그 모든 생각들을 곧이 곧대로 받아들여야 할 필요도 없다는 것이랍니다.`,
        end_typography_3_7: `핵심은 생각과 새로운 관계를 맺는 것이에요.`,

        end_typography_4_0_0:
          "아직 쉽게 받아들여지진 않겠지만, 생각은 생각일 뿐 나 자신이 아니랍니다.",
        end_typography_5_0_0:
          "생각은 마음에서 벌어지는 하나의 심리적 사건일 뿐이죠.",
        end_typography_6_0_0:
          "생각은 '나'의 일부지만, 그 생각을 품고 있는 '나'는 그보다 더 큰 존재랍니다.",
        end_typography_7_0_0:
          "개별 생각보다 더 큰 존재인 '나'는 거리두기를 통해 마음속에 끊임없이 떠오르는 그 모든 생각에 일일이 반응하지 않을 수도 있다는 걸 기억하세요.",
        end_typography_7: "개별 생각보다 더 큰 존재인 '나'는",
        end_typography_8:
          "거리두기를 통해 마음속에 끊임없이 떠오르는 그 모든 생각에 일일이 반응하지 않을 수도 있다는 걸 기억하세요.",
        end_typography_8_0_0: "자, 다시 나의 자동적 사고를 가만히 살펴보죠.",
        end_typographyFromData_9_0_0:
          "이전에 이 생각에 영향받는 정도를 $A$점이라고 하셨어요. 지금 이 생각은 나에게 얼마나 영향을 주고 있나요?",
        end_typographyFromData_10_0_0:
          "앞으로 <생각기록지>를 작성할 때에는 $A$의 생각함정에 빠지지 않았는지 유심히 살펴보세요.",
        end_typography_10:
          "상황-생각-반응으로 분리를 마친 후 생각함정을 인지하고, 생각과 거리를 둘 수 있도록 연습해 보는 거예요.",
        end_typography_11_0_0:
          "만약 아직 스스로 생각과 거리를 두는 게 어렵다면 괜찮아요. 코치 선생님이 도와드릴 거랍니다.",
        end_typography_12_0_0:
          "꾸준히 훈련하다 보면 조금씩 그 생각에 반응하지 않고 생각과 나 사이에 공간을 만들어가는 자신을 발견하게 될 거랍니다.",
        end_typography_13_0_0:
          "고생 많았습니다. 이번 활동은 여기서 마무리할까요?",
      },
    },
  },
};
