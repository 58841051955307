import { isDev } from "config";

export const translation_101_2_1_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "앞서 디스턴싱의 목표가 악순환을 선순환으로 바꾸는 것이라고 했는데요.",
      typography_1_0_0:
        "그렇다면 내 삶에서는 어떤 악순환이 작용하고 있는지 이해할 필요가 있을 거예요.",
      typography_2_0_0: "아래에 몇 가지 질문이 있는데요.",
      typography_3_0_0: "하나씩 살펴보시고 답변해 주시면 돼요.",
      typography_4_0_0:
        "그럼 코치 선생님께서 이렇게 들여쓰기가 된 형태로 추가 질문을 남겨서 그 내용을 구체화할 거랍니다.",
      typography_5_0_0:
        "혹 질문이 너무 불편하거나, 천천히 진행되었으면 좋겠다고 여겨지면 언제든지 편하게 말씀해 주세요.",
      typography_6_0_0:
        "디스턴싱에서 오가는 모든 이야기는 담당 코치 외에는 확인할 수 없으며, 절대 외부로 유출되지 않으니 걱정 않으셔도 돼요.",
      typography_7_0_0:
        "모바일 기기에서의 타이핑이 불편하다면 컴퓨터로 https://distancing.im 으로 접속해서 작성해주세요.",
      typography_8_0_0: "나를 이해하기",
      typography_9_0_0:
        "먼저 지금 내가 겪고 있는 무기력에 대해 이야기해 볼게요. 현재 어떤 문제로 인해 무기력감을 느끼고 계실까요?",
      typography_10_0_0: "말씀주신 문제를 떠올려보면 어떤 생각이 드세요?",
      typography_11_0_0:
        "위와 같은 문제가 발생했을 때 나는 어떻게 대응하며 행동하고 있나요? (예: 할 일 미루기, 침대에 무기력하게 누워있기 등)",
      typography_12_0_0:
        "단기적인 관점에서 나의 행동이 나의 부정적인 감정을 줄여주는 역할을 하였나요? 그렇다면 어떤 식이었을까요?",
      typography_13_0_0:
        "장기적인 관점에서 나의 행동이 오히려 삶에서 긍정적인 보상을 줄이거나 스트레스를 증가시키는 역할을 하였나요? 그렇다면 어떤 식이었을까요?",
      typography_14_0_0:
        "좋아요. 적어주신 내용을 참고해서 프로그램을 진행하도록 할게요.",
    },
  },
};
