import {
  LineWithSolidLabel,
  TypographyFromDataLine,
  TypographyLine,
} from "data/CellComponent";
import {
  Instruction,
  InstructionWithContent,
  InstructionWithImage,
} from "../../BlockComponent";
import { LastCard } from "../common";

export const Content_101_2_5_A = [
  InstructionWithImage("typography_0_0_0", "0"),
  InstructionWithImage("typography_1_0_0", "1"),
  Instruction("typography_2_0_0"),
  Instruction("typography_3_0_0"),
  Instruction("typography_4_0_0"),
  Instruction("typography_5_0_0"),
  Instruction("typography_6_0_0"),
  Instruction("typography_7_0_0"),
  Instruction("typography_8_0_0"),

  InstructionWithContent("typography_9_0_0", [
    ...["상황/사건", "행동", "단기적 이점", "장기적 단점"].flatMap((each) =>
      LineWithSolidLabel(
        each,
        TypographyFromDataLine({ A: { id: each, taskId: "101-2-2-A" } }, "$A$")
      )
    ),
  ]),
  InstructionWithContent("typography_10_0_0", [
    ...LineWithSolidLabel(`상황/사건`, TypographyLine("typography_10_1_0")),
    ...LineWithSolidLabel(
      `행동`,
      TypographyFromDataLine(
        {
          A: { id: "가치 영역 0 행동", taskId: "101-2-4-A" },
          B: { id: "가치 영역 1 행동", taskId: "101-2-4-A" },
          C: { id: "가치 영역 2 행동", taskId: "101-2-4-A" },
        },
        "$A$, $B$, $C$"
      )
    ),
    ...LineWithSolidLabel(`단기적 이점`, TypographyLine("typography_10_3_0")),
    ...LineWithSolidLabel(`장기적 이점`, TypographyLine("typography_10_4_0")),
  ]),
  Instruction("typography_11_0_0"),
  Instruction("typography_12_0_0"),
  Instruction("typography_13_0_0"),
  Instruction("typography_14_0_0"),
  Instruction("typography_15_0_0"),
  Instruction("typography_16_0_0"),
  Instruction("typography_17_0_0"),
  Instruction("typography_18_0_0"),
  Instruction("typography_19_0_0"),
  Instruction("typography_20_0_0"),
  Instruction("typography_21_0_0"),
  Instruction("typography_22_0_0"),

  ...LastCard,
];
