import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  patientApi,
  PATIENT_DOMAIN,
  PATIENT_LIST_ENDPOINT,
  PATIENT_REMOVE_ENDPOINT,
} from "api/patientApi";
import ButtonWithModal from "components/common/ButtonWithModal";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { patientAtom } from "recoil/patientAtom";

export default function PatientRemoveButton({
  patientId,
  onClose,
}: {
  patientId: string;
  onClose: () => void;
}) {
  const [patientState, setPatientState] = useRecoilState(patientAtom);
  const queryClient = useQueryClient();

  const removePatientQueryFn = useCallback(
    () => patientApi.remove(`${patientId}`),
    [patientId]
  );

  const { refetch } = useQuery(
    [PATIENT_DOMAIN, PATIENT_REMOVE_ENDPOINT],
    removePatientQueryFn,
    {
      enabled: false,
      onSuccess: (data) => {
        if (patientState?.patientId === patientId) {
          setPatientState(undefined);
        }
        queryClient.invalidateQueries([PATIENT_DOMAIN, PATIENT_LIST_ENDPOINT]);
      },
    }
  );

  return (
    <ButtonWithModal
      startDecorator={undefined}
      buttonText="권고 중단 완료"
      title={"충분한 대화 후 작별 인사까지 나눴나요?"}
      subtitle={`'확인'을 누르면 내담자 리스트에서 제거되며, 돌이킬 수 없습니다.`}
      cancelText="취소"
      actionText={"확인"}
      action={refetch}
      isListItemButton
      actionButtonColor={"danger"}
      onClose={onClose}
    />
  );
}
