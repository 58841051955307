import { useRecoilState, useRecoilValue } from "recoil";
import { Box, Button, Checkbox, Stack, Textarea, Typography } from "@mui/joy";
import { useCallback, useEffect, useRef, useState } from "react";
import { layoutAtom } from "recoil/layoutAtom";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  chatApi,
  ChatType,
  CHAT_DOMAIN,
  CHAT_MESSAGE_LIST_ENDPOINT,
  CHAT_UPDATE_DETAIL_ENDPOINT,
  UpdateMessageType,
} from "api/chatApi";
import { patientAtom } from "recoil/patientAtom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as _ from "lodash";
import { WORKFLOW_DETAIL_ENDPOINT, WORKFLOW_DOMAIN } from "api/workflowApi";
import { userAtom } from "recoil/userAtom";
import {
  notificationApi,
  NOTIFICATION_DOMAIN,
  NOTIFICATION_HISTORY_ENDPOINT,
} from "api/notificationApi";
import { usePrevious } from "@uidotdev/usehooks";

export default function CoachingContent() {
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const scrollHeight = useRef<number>(0);

  const userState = useRecoilValue(userAtom);
  const isManualUpdate =
    userState?.role !== "patient" && userState?.isCoachAiSupportDisabled;

  const { taskWidth } = useRecoilValue(layoutAtom);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [patientState, setPatientState] = useRecoilState(patientAtom);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [message, setMessage] = useState<string>("");

  const [isSendAvailable, setIsSendAvailable] = useState(false);
  const [messageList, setMessageList] = useState<ChatType[]>();

  const [updateDetailData, setUpdateDetailData] = useState<UpdateMessageType>();

  const [isUpdate, setIsUpdate] = useState(false);
  useEffect(() => {
    if (isUpdate) {
      if (message.trim().length === 0) {
        getPresetMessage();
      }
    }
  }, [isUpdate]);
  const getHistoryQueryFn = useCallback(
    () => notificationApi.getHistory(`${patientState?.patientId}`),
    [patientState]
  );
  const { refetch: getPresetMessage } = useQuery(
    [NOTIFICATION_DOMAIN, NOTIFICATION_HISTORY_ENDPOINT],
    getHistoryQueryFn,
    {
      enabled: false,
      onSuccess: (data) => {
        if (data && data.result) {
          setMessage(data.result.presetMessage);
        }
      },
    }
  );

  const queryClient = useQueryClient();

  const initialize = () => {
    setMessage("");
    setIsUpdate(false);
    setErrorMessage(undefined);
    refetchMessageList();
    setUpdateDetailData(undefined);
  };

  //자동 업데이트
  const getUpdateMessageDetailQueryFn = useCallback(
    () => chatApi.getUpdateMessageDetail(`${patientState?.patientId}`),
    [patientState]
  );

  useQuery(
    [CHAT_DOMAIN, CHAT_UPDATE_DETAIL_ENDPOINT, patientState?.patientId],
    getUpdateMessageDetailQueryFn,
    {
      enabled: !isManualUpdate,
      onSuccess: (data) => {
        console.log("update detail:", data.result);
        if (data.result?.status === "human_check_required") {
          setUpdateDetailData(data.result);
          setErrorMessage(data.result?.evalReason);
          setMessage(data.result?.message || "");
        }
      },
    }
  );

  const sendUpdateMessageQueryFn = useCallback(
    () =>
      chatApi.sendUpdateMessage(
        `${patientState?.patientId}`,
        `${updateDetailData?.taskUpdateMessageId}`,
        message
      ),
    [patientState, updateDetailData, message]
  );

  const { mutate: sendUpdateMessage } = useMutation(sendUpdateMessageQueryFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        WORKFLOW_DOMAIN,
        WORKFLOW_DETAIL_ENDPOINT,
      ]);
      initialize();
      if (patientState) {
        setPatientState({
          ...patientState,
          isHumanCheckRequired: false,
        });
      }
    },
  });

  const [firstMessageId, setFirstMessageId] = useState<number>();
  const pagingUnit = 20;
  function removeDuplicateObjects(array: ChatType[]) {
    const seen = new Set();
    return array.filter((item) => {
      const keyValue = item["messageId"];
      if (seen.has(keyValue)) {
        return false;
      } else {
        seen.add(keyValue);
        return true;
      }
    });
  }

  const getListQueryFn = useCallback(
    () => chatApi.getList(`${patientState?.patientId}`, firstMessageId),
    [patientState, firstMessageId]
  );

  const { refetch: refetchMessageList } = useQuery(
    [CHAT_DOMAIN, CHAT_MESSAGE_LIST_ENDPOINT, patientState?.patientId],
    getListQueryFn,
    {
      enabled: !!patientState?.patientId,
      ...(!firstMessageId && { refetchInterval: 10000 }),
      onSuccess: (data) => {
        if (data && data.result) {
          setMessageList((messageList) =>
            removeDuplicateObjects([
              ...(data.result || []),
              ...(messageList || []),
            ]).sort((a: ChatType, b: ChatType) => {
              return a.messageId - b.messageId;
            })
          );
        }
      },
    }
  );
  const previousMessageList = usePrevious(messageList);
  useEffect(() => {
    if (messageList && messageList.length > 0) {
      console.log("messageList", messageList);
      if (
        previousMessageList &&
        messageList.length !== previousMessageList.length
      ) {
        if (
          messageList[messageList.length - 1].messageId ===
          previousMessageList[previousMessageList.length - 1].messageId
        ) {
          //paging
          chatContainerRef.current?.scrollTo({
            top: chatContainerRef.current.scrollHeight - scrollHeight.current,
            behavior: "auto",
          });
        } else {
          chatContainerRef.current?.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: "auto",
          });
        }
      } else if (!previousMessageList) {
        if (
          chatContainerRef.current &&
          chatContainerRef.current.scrollHeight ===
            chatContainerRef.current.offsetHeight
        ) {
          if (messageList && messageList[0].messageId) {
            setFirstMessageId(messageList[0].messageId);
          }
        }
        chatContainerRef.current?.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: "auto",
        });
      }
    }
  }, [messageList]);

  const sendQueryFn = useCallback(
    () => chatApi.send(`${patientState?.patientId}`, message, isUpdate),
    [patientState, message]
  );

  const { mutate: send } = useMutation(sendQueryFn, {
    onSuccess: () => {
      initialize();
    },
  });

  useEffect(() => {
    if (message.trim().length > 0) {
      setIsSendAvailable(true);
    } else {
      setIsSendAvailable(false);
    }
  }, [message]);

  const sendMessage = () => {
    if (updateDetailData) {
      sendUpdateMessage();
    } else {
      send();
    }
  };

  useEffect(() => {
    const onEnter = (e: KeyboardEvent) => {
      if (
        isSendAvailable &&
        message.trim().length > 0 &&
        !e.shiftKey &&
        (e.code === "Enter" ||
          e.code === "NumpadEnter" ||
          e.key === "Enter" ||
          e.key === "NumpadEnter")
      ) {
        e.preventDefault();
        setFirstMessageId(undefined);
        sendMessage();
      }
    };
    window.addEventListener("keypress", onEnter);

    return () => window.removeEventListener("keypress", onEnter);
  }, [isSendAvailable]);

  useEffect(() => {
    if (firstMessageId) {
      const divElement = chatContainerRef.current;
      if (divElement) {
        scrollHeight.current = divElement.scrollHeight;
      }
      refetchMessageList();
    }
  }, [firstMessageId]);

  const handleScroll = useCallback(() => {
    if (chatContainerRef.current) {
      const scrollTop = chatContainerRef.current.scrollTop;

      if (scrollTop === 0) {
        //스크롤 맨 위
        if (messageList) {
          if (messageList.length % pagingUnit !== 0) {
            setFirstMessageId(undefined);
          } else {
            setFirstMessageId(messageList[0].messageId);
          }
        }
      } else if (
        scrollTop ===
        chatContainerRef.current.scrollHeight -
          chatContainerRef.current.offsetHeight
      ) {
        //스크롤 맨 아래
        setFirstMessageId(undefined);
      }
    }
  }, [messageList]);

  useEffect(() => {
    if (messageList) {
      const divElement = chatContainerRef.current;
      if (divElement) {
        divElement.addEventListener("scroll", handleScroll);
      }
      return () => {
        if (divElement) {
          divElement.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [messageList]);

  const messageListByDate = _.groupBy(
    messageList,
    ({ time }) => time.split("T")[0]
  );
  return (
    <div
      ref={chatContainerRef}
      className={`h-full w-full flex flex-col overflow-y-auto pt-[20px] ${
        isSmall ? "px-[16px]" : "px-[24px]"
      }`}
    >
      <Stack
        direction={"column"}
        spacing={2}
        sx={{
          width: "100%",
          flex: 1,
          paddingBottom: "36px",
          ...(taskWidth === "normal" && { maxWidth: "720px", marginX: "auto" }),
        }}
      >
        {messageListByDate &&
          Object.entries(messageListByDate).map(([date, messageList]) => (
            <Box key={date}>
              <Box
                sx={{
                  width: "fit-content",
                  marginX: "auto",
                  p: "2px 15px",
                  borderRadius: "100px",
                  backgroundColor: "#7c7c7c99",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  level={"body-xs"}
                  fontSize={10}
                  style={{ color: "#ffffff", textAlign: "center" }}
                >
                  {date}&nbsp;
                  {
                    ["일", "월", "화", "수", "목", "금", "토"][
                      new Date(date).getDay()
                    ]
                  }
                </Typography>
              </Box>
              <Stack spacing={1}>
                {messageList?.map(({ isMine, message, time }, index) => (
                  <Box
                    key={`message${index}`}
                    sx={{
                      display: "flex",
                      flexDirection: isMine ? "row" : "row-reverse",
                      gap: "10px",
                      justifyContent: isMine ? "end" : "start",
                      alignItems: "end",
                    }}
                  >
                    <Typography level={"body-xs"} sx={{ opacity: 0.5 }}>
                      {time.split("T")[1].split(":").splice(0, 2).join(":")}
                    </Typography>

                    <Box
                      sx={{
                        maxWidth: "80%",
                        backgroundColor: isMine ? "background.level3" : "white",
                        borderRadius: "10px",
                        px: "12px",
                        py: "8px",
                      }}
                    >
                      <Typography>{message}</Typography>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Box>
          ))}
      </Stack>
      <Box
        className={`w-full flex-none pb-[20px] ${
          taskWidth === "normal" && "max-w-[720px] mx-auto"
        }`}
        sx={{
          pt: "8px",
          backgroundColor: "background.level1",
          position: "sticky",
          bottom: 0,
          zIndex: 100,
        }}
      >
        {isManualUpdate && (
          <Checkbox
            size={"sm"}
            label="업데이트 알리기"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setIsUpdate(event.target.checked);
            }}
            checked={isUpdate}
          />
        )}

        {errorMessage && (
          <Typography
            fontWeight={"500"}
            textColor={"danger.500"}
            sx={{ mt: "4px" }}
          >
            {`[AI CHECK]: ${errorMessage}`}
          </Typography>
        )}
        <Stack
          direction={"row"}
          alignItems="end"
          spacing={1}
          sx={{ mt: "8px" }}
        >
          <Textarea
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            sx={{
              flex: 1,
              borderColor: "background.level3",
              backgroundColor: "background.level3",
              "--joy-focus-thickness": "0px",
            }}
          ></Textarea>
          <Button
            size="sm"
            disabled={message.length === 0}
            sx={{ px: "10px" }}
            onClick={sendMessage}
          >
            <span className="material-symbols-rounded">send</span>
          </Button>
        </Stack>
      </Box>
    </div>
  );
}
