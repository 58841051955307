import { translation_reflectGoal } from "./reflectGoal";

export const translation_0_5_A = {
  en: {},
  ko: {
    v1: {
      "수정 필요": "수정 필요",
      "수정 불필요 또는 수정 완료": "수정 불필요 또는 수정 완료",
      typography_0_0_0:
        "앞으로 2주마다 우리의 목표를 돌아보는 시간을 가질 거예요.",
      typography_1_0_0:
        "목표하는 모습과 얼마나 가까워지고 있는지 주기적으로 살펴보는 거죠.",
      typography_2_0_0:
        "목표가 모호하다면 조금 더 구체화하기도 하고, 비현실적이라면 달성할 수 있는 형태로 수정해 보기도 할 거고요.",
      typography_3_0_0:
        "곧 있을 짧은 미래에, 우리는 스스로에 대한 확신과 믿음이 생겨날 때가 올 거예요.",
      typography_4_0_0:
        "프로그램 없이도 목표하는 삶의 모습을 만들어 나갈 수 있겠다고요.",
      typography_5_0_0:
        "그때가 우리가 함께 해온 이 프로그램을 마무리하는 시점이 될 거랍니다.",
      typography_6_0_0:
        "하지만 그 과정에서 이따금 내 목표를 바라보면, 현재 모습과의 이질감이 크게 느껴질 때도 있을 거예요.",
      typography_7_0_0:
        '"내가 저 목표를 이룰 수 있을까? 언제나처럼 난 제자리일 거야"라면서 프로그램 진행에 회의가 들 수도 있어요.',
      typography_8_0_0:
        "하지만 기억해 주세요. 우리가 만들어낼 변화는 서서히 찾아오겠지만 그 어떤 시련에도 쉽게 흔들리지 않는 아주 튼실한 변화라는 사실을요.",
      typography_9_0_0: "점진적이지만 그만큼 아주 단단한 변화라는 걸요. ",
      typography_10_0_0: "자, 그럼 우리가 세운 목표를 한 번 돌아볼까요?",
      typography_11_0_0: "우린 현재 다음의 목표를 향해 나아가고 있어요.",
      typography_12_0_0: "내담자의 목표를 수정해야 하나요?",

      ...translation_reflectGoal.ko.v1,

      typography_13_0_0:
        "하지만 목표를 조금 더 자세하고 현실성 있게 다듬어볼 필요가 있을 것 같아요.",
      typography_14_0_0: "우리는 머지않아 목표를 정말로 이뤄낼 것이기 때문에",
      typography_15_0_0:
        "그 과정에서 목표한 바에 얼마나 가까워지고 있는지 평가할 수 있어야 하거든요.",
      typography_16_0_0: "코치 선생님은 이런 의견을 주었는데요.",
      typography_17_0_0: "이에 대해 어떻게 생각하시나요?",
      typography_18_0_0:
        "좋아요. 코치 선생님이 살펴보고 목표를 수정할 수 있도록 도와줄 거예요.",
    },
  },
};
