export const PATH = {
  login: "/login",
  redirect: "/oauth/kakao",
  redirect_apple: "/oauth/apple",
  onboarding: "/onboarding/:id",
  onboardingBeforeLogin: "/onboarding/guest/:id",

  home: "/:taskKey?",
  survey: "/survey",
  payment: "/payment",
  paymentSuccess: "/payment/success",
  paymentFail: "/payment/fail",

  selfCheck: "/self-check/:tool/:id",
  selfCheckEnd: "/self-check/:tool/end",

  steppayPaymentSuccess: "/payment/steppay/success",
  steppayPaymentFail: "/payment/steppay/fail",
  steppayManagement: "/payment/management",

  institutionSelect: "/institution-select",
  emailAuth: "/email-auth",
  emailAuthResult: "email-auth/result",

  tool: "/tool/:step",
  meditationTool: "/meditation",
  thoughtRecordTool: "/thought-record",
  thoughtRecordTool_recording: "/thought-record/recording",
  thoughtRecordTool_isolation: "/thought-record/:ttr",
  thoughtRecordTool_thoughtTrap: "/thought-record/thought-trap",
  test: "/test",
  testSpeechToText: "/demo-stt",
};
