export const translation_0_4_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "전 세계 사람 중 20~30%는 일평생 한 번 이상 진단 받을 수준의 우울증을 경험하곤 해요.",
      typography_1_0_0:
        "이처럼 광범위하게 정신건강 문제가 발생하는 건 지구의 모든 동물 중 인간이 유일하답니다.",
      typography_2_0_0: "그렇다면 우리는 왜 이런 문제를 안게 되었을까요?",
      typography_3_0_0:
        "그 이유는 우리가 생각과 나 자신을 아주 강하게 동일시하기 때문이에요.",
      typography_4_0_0: "생각과 나",
      typography_5_0_0:
        "모든 마음의 문제는 '생각과의 거리두기(distancing)'가 잘되지 않아 발생해요.",
      typography_6_0_0: "다시 말해, 생각과의 거리가 너무 가까우면",
      typography_7_0_0: "생각을 '나' 자신과 동일시하게 되고",
      typography_8_0_0:
        "결국 부정적인 생각에 반사적이고 강하게 반응하며 속수무책으로 당할 수밖에 없다는 것이죠.",
      typography_9_0_0:
        "이 핵심적인 원리는 수많은 심리치료 기법과 저명한 정신과 의사와 학자들의 연구를 통해 검증된 바 있습니다.",
      typography_10_0_0:
        "예를 들어볼까요? 민수가 회사에서 해고된 상황을 떠올려봐요.",
      typography_11_0_0:
        "그 상황에서 민수는 패배감을 느껴요. 불안하고 미래를 걱정하죠.",
      typography_12_0_0:
        "이후 민수의 마음속에는 '나는 실패할 거야'와 같은 생각이 떠오르죠.",
      typography_13_0_0:
        "이런 생각에 강하게 영향받은 민수는 이후 취업을 시도할 때에도 자신감을 잃어요.",
      typography_14_0_0: "면접을 볼 때 긴장하고 불안해하죠.",
      typography_15_0_0:
        "이런 걱정 때문에 민수는 더더욱 실수를 많이 하게 돼요.",
      typography_16_0_0: "그리고 당연한 결과로 민수는 면접에서 떨어지고 말죠.",
      typography_17_0_0:
        "면접에서 떨어진 민수의 마음속에는 '나는 실패자'라는 생각이 더욱 강해집니다.",
      typography_18_0_0: "민수의 생각은 이제 정말로 현실이 되었네요.",
      typography_19_0_0: "생각과 거리두고 가치로 나아가기",
      typography_20_0_0:
        "중요한 점은 우리가 마음속에 떠오른 그 모든 생각에 강하게 반응하지 않아도 된다는 점이에요.",
      typography_21_0_0: "생각은 우리 몸의 다른 감각과도 동일해요.",
      typography_22_0_0:
        "그저 마음속에서 느껴진 '하나의 심리적 사건'에 불과하답니다.",
      typography_23_0_0:
        "이 사실을 인지하고 한 걸음 떨어져서 바라보면 우리의 생각에는 아주 빈틈이 많아요.",
      typography_24_0_0: "어떤 생각들은 전혀 정확하지 않을 때도 있고,",
      typography_25_0_0:
        "또 어떤 생각은 아무짝에 쓸모없음에도 끊임없이 반복되어 발생하기도 하죠.",
      typography_26_0_0: "내 마음속에서도 그런 일이 벌어지고 있는 것 같나요?",
      typography_27_0_0:
        "너무 걱정하지 마세요. 우리는 다양한 심리학적 기법을 통해 당신이 생각과 거리를 둘 수 있도록 도와드릴 거예요.",
      typography_28_0_0:
        "생각과 거리를 두게 되면 당장의 기분에 휩쓸리지 않을 여유가 생긴답니다.",
      typography_29_0_0:
        "일단 여유가 생기면 우리는 당신이 삶에서 정말로 중요시 했던 가치가 무엇인지 찾아낼 거예요.",
      typography_30_0_0:
        "그리고 마음속에 떠오른 부정적인 생각에 반응하지 않고, 그 가치를 향한 행동에 전념할 수 있도록 도울 거랍니다.",
      typography_31_0_0: "자, 이제 그 첫걸음을 시작해 볼 텐데요.",
      typography_32_0_0: "그 과정을 도와줄 코치 선생님을 만나볼 거예요.",
      typography_33_0_0:
        "코치 선생님은 내가 생각과 거리를 둘 수 있도록, 생각을 관찰, 계획, 분석하는 과정을 도와줄 거랍니다.",
      typography_34_0_0:
        "코치 선생님의 메시지를 받으려면 알림을 꼭 허용해주셔야 해요.",
      typography_35_0_0: "좋아요. 이제 코치 선생님이 연락을 드릴 거예요.",
    },
  },
};
