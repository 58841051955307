import { IconButton, Stack, Typography } from "@mui/joy";
import ButtonWithModal from "components/common/ButtonWithModal";
import Link from "components/common/Link";
import TypographyWithLinkSubstring from "components/common/TypographyWithLinkSubstring";
import { useTranslation } from "react-i18next";

export default function CxButton({
  type = "icon",
}: {
  type?: "icon" | "link";
}) {
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  return (
    <ButtonWithModal
      buttonText={""}
      size="lg"
      title={t_ui("문의사항은 여기로 보내주세요.")}
      textAlign="center"
      render={
        type === "link" ? (
          <Link
            text={"고객 센터"}
            opacity={0.4}
            customSx={{ fontSize: "14px" }}
          />
        ) : (
          <IconButton size="sm" variant="plain" color="neutral">
            <span className="material-symbols-outlined">help</span>
          </IconButton>
        )
      }
      content={
        <Stack
          alignItems={"center"}
          spacing={1}
          sx={{ width: "100%", color: "text.tertiary" }}
        >
          <a href="mailto:support@orwellhealth.org?subject=문의드립니다&body=닉네임:%0D%0A문의사항:">
            support@orwellhealth.org
          </a>
          <Typography>
            <TypographyWithLinkSubstring
              mainString={"환불 관련 문의는 환불 접수하기를 확인해 주세요."}
              linkSubstrings={[
                {
                  linkString: "환불 접수하기",
                  url: "https://orwellhealth.notion.site/d07b7b28b8e04d858a93641249748b0f",
                },
              ]}
              linkOpenType="link"
            />
          </Typography>
        </Stack>
      }
    />
  );
}
