import { Button, Stack, Typography } from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  riskApi,
  RiskDetailType,
  RISK_DETAIL_ENDPOINT,
  RISK_DOMAIN,
} from "api/riskApi";
import TaskLink from "./TaskLink";
import { Status } from "./workflow/CoachWorkflow";
import { PATIENT_DOMAIN, PATIENT_LIST_ENDPOINT } from "api/patientApi";

function Risk({
  taskKeyList,
  isReportedToMedicalStaff,
}: {
  taskKeyList: string[];
  isReportedToMedicalStaff: boolean;
}) {
  const queryClient = useQueryClient();
  const [patientState, setPatientState] = useRecoilState(patientAtom);

  const reportQueryFn = useCallback(
    () => riskApi.report(`${patientState?.patientId}`),
    [patientState?.patientId]
  );

  const { mutate: report } = useMutation(reportQueryFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        RISK_DOMAIN,
        RISK_DETAIL_ENDPOINT,
        patientState?.patientId,
      ]);
    },
  });

  const skipQueryFn = useCallback(
    () => riskApi.skip(`${patientState?.patientId}`),
    [patientState?.patientId]
  );

  const { mutate: skip } = useMutation(skipQueryFn, {
    onSuccess: () => {
      setPatientState((patientState) =>
        patientState
          ? { ...patientState, isRiskCheckRequired: false }
          : patientState
      );
      queryClient.invalidateQueries([PATIENT_DOMAIN, PATIENT_LIST_ENDPOINT]);
    },
  });

  return (
    <Stack direction={"column"} spacing={1}>
      <Stack direction={"row"} spacing={"12px"} alignItems="start">
        <Status status={"human_check_required"} />
        <Stack direction={"column"} sx={{ flex: 1 }}>
          <Typography>{"위험 감지"}</Typography>

          {taskKeyList?.map((taskKey) => (
            <TaskLink key={`risk_${taskKey}`} taskKey={taskKey} />
          ))}
        </Stack>
      </Stack>
      <Stack sx={{ width: "100%" }} direction={"row"} spacing={"4px"}>
        <Button
          size={"xs"}
          variant="outlined"
          fullWidth
          sx={{ px: "0", flex: 1 }}
          onClick={() => skip()}
        >
          계속 진행시키기
        </Button>

        <Button
          size={"xs"}
          variant={"outlined"}
          disabled={isReportedToMedicalStaff}
          fullWidth
          sx={{ px: "0", flex: 1 }}
          onClick={() => report()}
        >
          {isReportedToMedicalStaff ? "의료진 연결 완료" : "의료진 연결하기"}
        </Button>
      </Stack>
    </Stack>
  );
}

export default function RiskDetection() {
  const patientState = useRecoilValue(patientAtom);

  const [riskDetail, setRiskDetail] = useState<RiskDetailType>();

  const riskDetailQueryFn = useCallback(
    () => riskApi.getDetail(`${patientState?.patientId}`),
    [patientState?.patientId]
  );
  const { refetch: getRiskDetail } = useQuery(
    [RISK_DOMAIN, RISK_DETAIL_ENDPOINT, patientState?.patientId],
    riskDetailQueryFn,
    {
      onSuccess: (data) => {
        if (data && data.result) {
          const risk = data.result;
          console.log("RISK: ", risk);
          setRiskDetail(risk);
        }
      },
    }
  );

  return riskDetail ? (
    <Stack direction={"column"} spacing={1} sx={{ py: "12px" }}>
      <Risk
        taskKeyList={riskDetail.taskKeyList}
        isReportedToMedicalStaff={riskDetail.isReportedToMedicalStaff}
      />
    </Stack>
  ) : (
    <></>
  );
}
