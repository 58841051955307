export const translation_2_4_A = {
  en: {},
  ko: {
    v1: {
      typography_0:
        "우리는 지금부터 부정적인 생각이나 감정을 마주할 때마다 ‘분리하기'를 적용하여 기록을 해나갈 거예요.",
      typography_1:
        "상황-생각-감정-신체 반응-행동, 이렇게 다섯 가지로 나눠서 기록할 건데요. 하나씩 보여드릴게요.",
      typography_2: "먼저 이건 '상황'이에요.",
      typography_2_1:
        "동료 몇 명이 함께 저녁 먹고 술 마시러 간다는 것을 우연찮게 들었음",
      typography_3: "두 번째로 '생각'이에요.",
      typography_3_1:
        "난 이 집단의 일원이 아닌 것 같네. 내 동료들은 나를 좋아하지도 존중하지도 않는 것 같다.",
      typography_3_2: "70",
      typography_4: "세 번째로 '감정'이에요.",
      typography_4_1: "짜증, 슬픔, 분노",
      typography_5: "네 번째로 '신체 반응'이에요.",
      typography_5_1: "머리가 아픔, 배가 싸함",
      typography_6: "마지막으로 '행동'이에요.",
      typography_6_1:
        "오후 동안 사무실 자리에 앉아서 일어나지 않음. 휴게실도 가지 않음.",
      typography_7: "상황은 어떻게 적나요?",
      typography_8:
        "상황에는 당시의 객관적인 상황만 적어야 해요. 다음은 예시예요.",
      typography_8_1:
        "동료 몇 명이 함께 저녁 먹고 술 마시러 간다는 것을 우연찮게 들었음",
      typography_9:
        "그 상황에 대한 나의 판단, 의견, 생각 등은 배제한 채 객관적으로 어떤 상황이 있었는지만 적어보는 거예요.",
      typography_10:
        "여기서 퀴즈를 하나 드려볼게요. 다음의 상황은 잘 적혔을까요?",
      typography_10_1:
        "계획한 공부는 하지 않고, 한심하게 휴대폰으로 SNS만 보고 있음",
      typography_quiz1_incorrect:
        "다시 한 번 생각해보세요. 적힌 문장에 판단, 의견, 생각은 없나요?",
      typography_11: `네, 정답이에요. 위의 상황은 객관적이지 않아요. "한심하게"라는 주관적인 판단이 들어가 있기 때문이죠.`,
      typography_12: `내가 내린 판단을 제외하고 "계획한 공부 대신, 휴대폰으로 SNS을 보고 있음"이라고 객관적인 관점으로 적는 게 좋아요.`,
      typography_13: "생각은 어떻게 적나요?",
      typography_14:
        "생각엔 당시의 그 상황에서 어떤 생각이 들었는지 적어주세요. 다음은 예시예요.",
      typography_14_1:
        "난 이 집단의 일원이 아닌 것 같네. 내 동료들은 나를 좋아하지도 존중하지도 않는 것 같다.",
      typography_14_2: "70",
      typography_15:
        "누군가에게 말하는 형태(예: ~했어요, ~라고 느꼈어요)가 아니라 내가 머릿속에서 했던 생각 그 자체를 독백 형태(예: 진짜 이러고 싶지 않다. 어딘가에 숨고 싶다. 나는 왜 그렇게 밖에 못 했을까...)로 적어주세요.",
      typography_16:
        "주의해야 할 것은 상황을 배제한 나의 생각만을 적어야 한다는 거예요.",
      typography_17:
        "여기서 퀴즈를 하나 드려볼게요. 다음의 생각에 대한 기록은 잘 적혔을까요?",
      typography_17_1:
        "사실 한 달 전즈음부터 계속되고 있었다. 이직을 원하던 회사의 면접에서 떨어지고 난 뒤, 아무 일도 손에 잡히지 않았다. 불합격 발표 날에 엄마가 괜찮다며 위로를 많이 해줬다. 하지만 그럼에도 여전히 세상은 나에게 등을 돌리고 있는 것 같다. 앞으로 무언가를 해도 잘 안될 것 같다는 생각 뿐이다.",
      typography_17_2: "70",
      typography_quiz2_incorrect:
        "다시 한 번 생각해보세요. 적힌 문장에 판단, 의견, 생각은 없나요?",
      typography_18:
        "네, 정답이에요. 위의 생각에는 상황 설명도 함께 들어가 있어요. 한 달 전의 면접 이야기부터 그 뒤 엄마의 위로까지 과거의 상황이 설명되어 있죠.",
      typography_19:
        "생각을 적을 때에는 상황은 걷어내고 적어야 해요. 이 기록을 누군가에게 설명할 필요는 전혀 없답니다. 오로지 자신을 위해 적는 거니 이런 상황 설명은 과감히 덜어내주세요.",
      typography_20: "그 당시 머리 속을 스쳤던 생각만, 날것 그대로 적어주세요.",
      typography_21:
        "그리고 그 아래 칸에는 당시 그 상황에서 떠올랐던 생각이 나에게 얼마나 부정적인 영향을 주는지 적어보는 거예요.",
      typography_22:
        "0은 전혀 영향 주지 않음을, 100은 그 생각으로 인해 나의 삶이 흔들릴 정도로 강하게 영향을 주었다는 걸 뜻해요.",
      typography_23: "감정은 어떻게 적나요?",
      typography_24:
        "사건이 있었던 당시 느꼈던 감정이나 신체 반응을 적어요. 다음은 예시예요.",
      typography_24_1: "짜증, 슬픔, 분노",
      typography_25: "마음속에 무엇이 느껴지는지를 자유롭게 적어보아도 좋아요.",
      typography_26: "신체 반응은 어떻게 적나요?",
      typography_27:
        "신체 반응에는 사건이 있었던 당시 느꼈던 신체 감각을 적어주세요. 다음은 예시예요.",
      typography_27_1: "머리가 아픔, 배가 싸함",
      typography_28:
        "신체 반응은 몸에서 느껴지는 신체 감각, 반사적인 반응 등을 적어보세요.",
      typography_29: "행동은 어떻게 적나요?",
      typography_30:
        "행동에는 그 사건을 겪은 후 어떤 식으로 행동했는지 적어주세요. 다음은 예시예요.",
      typography_30_1:
        "오후 동안 사무실 자리에 앉아서 일어나지 않음. 휴게실도 가지 않음.",
      typography_31:
        "행동을 적을 때는 사건 직후에 이어진 행동을 적어도 괜찮고(예: 크게 소리치며 반응함)",
      typography_32: `그 사건으로 인해 하루종일 이어진 행동을 적어도 좋아요(예: 의기소침해져 하루종일 의견을 제대로 이야기하지 않음).`,
      typography_33: `어떤가요? 방법을 아니 크게 어려운 부분은 없으시죠?`,
      typography_34: `자, 이제 다음 활동인 <생각기록지>에서 최근 나를 괴롭혔던 경험을 떠올려 한번 기록해볼까요?`,
    },
  },
};
