import { Textarea } from "@mui/joy";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  notificationApi,
  NOTIFICATION_DOMAIN,
  NOTIFICATION_HISTORY_ENDPOINT,
} from "api/notificationApi";
import { PATIENT_DOMAIN, PATIENT_LIST_ENDPOINT } from "api/patientApi";
import ButtonWithModal from "components/common/ButtonWithModal";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { taskListAtom } from "recoil/taskListAtom";
import { patientAtom } from "recoil/patientAtom";

export default function NotificationButton() {
  const queryClient = useQueryClient();
  const [patientState, setPatientState] = useRecoilState(patientAtom);

  const [message, setMessage] = useState<string>("");

  const getHistoryQueryFn = useCallback(
    () => notificationApi.getHistory(`${patientState?.patientId}`),
    [patientState]
  );

  const { data: fetchData, refetch: refetchHistory } = useQuery(
    [NOTIFICATION_DOMAIN, NOTIFICATION_HISTORY_ENDPOINT],
    getHistoryQueryFn,
    {
      enabled: !!patientState?.patientId,
      onSuccess: (data) => {
        if (data && data.result) {
          setMessage(data.result.presetMessage);
        }
      },
    }
  );

  useEffect(() => {
    if (patientState?.patientId) refetchHistory();
  }, [patientState]);

  const sendNotificationQueryFn = useCallback(
    () => notificationApi.send(`${patientState?.patientId}`, message),
    [patientState, message]
  );

  const { mutate: sendNotification } = useMutation(sendNotificationQueryFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([PATIENT_DOMAIN, PATIENT_LIST_ENDPOINT]);
      queryClient.invalidateQueries([
        NOTIFICATION_DOMAIN,
        NOTIFICATION_HISTORY_ENDPOINT,
      ]);
      if (patientState) {
        setPatientState({
          ...patientState,
          isTaskUpdateRequired: false,
        });
      }
    },
  });

  const taskListState = useRecoilValue(taskListAtom);
  const hasMissingValue = taskListState.some(
    (element) => element.hasMissingValue
  );

  return (
    <ButtonWithModal
      variant={
        patientState?.isTaskUpdateRequired && !hasMissingValue
          ? "solid"
          : "soft"
      }
      startDecorator={
        <span className="material-symbols-outlined">notifications</span>
      }
      buttonText="업데이트 알리기"
      title={"업데이트 메시지를 보낼까요?"}
      subtitle={`카카오톡으로 메시지를 보내요. 오늘 ${patientState?.nickname}님에게 보낸 업데이트 메시지는 총 ${fetchData?.result?.taskOpenTodaySendCount}회이에요.`}
      content={
        <Textarea
          key={`${patientState?.patientId}`}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          sx={{
            borderColor: "background.level3",
            borderWidth: "1px",
            "--joy-focus-thickness": "0px",
            boxShadow: "0px 0px 0px transparent",
          }}
        ></Textarea>
      }
      actionText={"보내기"}
      actionButtonDisabled={message.trim().length === 0 || false}
      action={sendNotification}
    />
  );
}
