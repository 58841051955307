export const translation_2_1 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        '지금까지 우리는 "생각하는 나"가 착각이라는 것, 생각은 그저 마음속에 나타날 뿐이라는 것을 배웠습니다.',
      typography_1_0_0: "오늘은 생각에 대한 한 가지 사실을 더 배워볼게요.",
      typography_2_0_0: "그것은 바로 생각은 자동적이라는 사실입니다.",
      typography_3_0_0: "간단한 실험",
      typography_4_0_0:
        "생각이 자동적이라는 것을 이해하기 위해 잠시 간단한 실험을 해봅시다.",
      typography_5_0_0:
        "생각의 특성은 결코 앉은 자리에서 머리로만 이해할 수 없습니다.",
      typography_6_0_0:
        "보다 넓은 시각을 갖고 디스턴싱을 체화하려면 직접 경험하는 것이 중요합니다.",
      typography_7_0_0:
        "그렇기 때문에 그냥 넘어가지 말고 꼭 해보시길 바랍니다.",
      typography_8_0_0: "휴대폰을 이용해 30초 타이머를 맞춰볼까요?",
      typography_9_0_0:
        "30초 동안 눈을 감고 그 어떤 생각도 하지 않으려고 노력해보세요.",
      typography_10_0_0: "딱 30초만 아무생각을 하지 않는 것이지요.",
      typography_11_0_0:
        "자, 시작해볼까요? 30초의 타이머가 끝나면 다음으로 진행해주세요.",
      typography_12_0_0:
        "30초 동안 마음속에 어떤 생각이 떠올랐나요? 적어볼까요?",
      typography_13_0_0:
        "자, 어떤 일이 벌어졌나요? 정말로 아무 생각도 하지 않았나요?",
      typography_14_0_0: "머리속은 텅빈 공간처럼 고요했나요?",
      typography_15_0_0: "아마 아닐 겁니다.",
      typography_16_0_0: "곧 있을 중요한 과제에 대해 생각했을지도 모르고,",
      typography_17_0_0: "어쩌면 과거의 기억이 떠올랐을 수도 있을 겁니다.",
      typography_18_0_0:
        '혹은 "이건 대체 왜하는 거지?", "30초가 언제 끝나는 거야"와 같은 생각을 했을 수도 있죠.',
      typography_19_0_0:
        "어떤 생각이 마음속에 떠올랐든, 하나 확실한 게 있습니다.",
      typography_20_0_0: "생각을 멈추는 것은 불가능합니다.",
      typography_21_0_0: "생각은 자동적입니다",
      typography_22_0_0:
        '이는 우리가 앞서 배운 첫 번째 명제 "생각하는 나는 착각이다"를 생각해보면 당연한 결과이기도 합니다.',
      typography_23_0_0:
        "나는 능동적으로 생각하는 존재가 아니기에, 생각을 멈추는 것도 불가능한 것이죠.",
      typography_24_0_0:
        "한편 눈을 감고 있는 동안 생각은 결코 규칙적으로 떠오르지 않았습니다. 무작위로 떠올랐죠.",
      typography_25_0_0:
        "이처럼 생각은 아주 불규칙하며, 무작위적이고, 예측 불가능합니다.",
      typography_26_0_0: "생각의 이러한 특성을 꼭 기억하시기 바랍니다.",
      typography_27_0_0:
        "다음 활동에서는 생각이 자동적이라는 사실을 설명하는 재밌는 비유를 하나 배워보도록 할게요.",
    },
  },
};
