import { Box } from "@mui/joy";

export default function IconButton({
  iconNode,
  onClick,
}: {
  iconNode: React.ReactNode;
  onClick?: () => void;
}) {
  const disabled = !onClick;
  return (
    <Box
      sx={{
        cursor: "pointer",
        ...(disabled && { pointerEvents: "none", opacity: 0.2 }),
      }}
      {...(onClick && { onClick: onClick })}
    >
      {iconNode}
    </Box>
  );
}
