import { Box, Stack, useColorScheme } from "@mui/joy";
import { useColorScheme as useMaterialColorTheme } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "static/logo.png";
import Awareness from "./Awareness";
import { v4 as uuidv4 } from "uuid";
import Distancing from "./Distancing";
import AwarenessWrapup from "./AwarenessWrapup";
import DistancingWrapup from "./DistancingWrapup";
import Acceptance from "./Acceptance";
import Wrapup from "./Wrapup";

export default function Tool() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  let { step } = useParams();
  const [uuid, setUUid] = useState<string>(uuidv4());
  const [threadId, setThreadId] = useState<string>();

  const { setMode } = useColorScheme();
  const { setMode: setMaterialMode } = useMaterialColorTheme();

  useEffect(() => {
    navigate("/tool/1", { replace: true });
    setMode("light");
    setMaterialMode("light");
  }, []);

  useEffect(() => {
    const setThemeColor = (color: string) => {
      let themeColorMetaTag = document.querySelector(
        'meta[name="theme-color"]'
      );
      if (!themeColorMetaTag) {
        themeColorMetaTag = document.createElement("meta");
        themeColorMetaTag.setAttribute("name", "theme-color");
        document.head.appendChild(themeColorMetaTag);
      }
      themeColorMetaTag.setAttribute("content", color);
    };

    // 예제: 컴포넌트가 마운트될 때 노치 색상을 변경
    setThemeColor("#ffffff"); // 파란색

    // 필요한 경우, cleanup 함수를 사용하여 색상을 초기 상태로 되돌릴 수 있음
    return () => setThemeColor("#E2E6E8"); // 기본 색상
  }, []);

  useEffect(() => {
    scrollRef.current?.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [step]);

  return (
    <Box
      ref={scrollRef}
      sx={{
        position: "relative",
        width: "100%",
        overflow: "scroll",
        pt: "20px",
        px: "20px",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          maxWidth: "720px",
          mx: "auto",
        }}
        alignItems="center"
      >
        <img
          src={logo}
          alt={"logo"}
          style={{ width: "120px", marginBottom: "30px" }}
        ></img>
        {step === "1" ? (
          <Awareness uuid={uuid} />
        ) : step === "2" ? (
          <AwarenessWrapup uuid={uuid} />
        ) : step === "3" ? (
          <Distancing uuid={uuid} setThreadId={setThreadId} />
        ) : step === "4" ? (
          threadId && <DistancingWrapup uuid={uuid} threadId={threadId} />
        ) : step === "5" ? (
          <Acceptance uuid={uuid} />
        ) : step === "6" ? (
          <Wrapup uuid={uuid} />
        ) : (
          <></>
        )}
      </Stack>
    </Box>
  );
}
