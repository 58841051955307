import { atom } from "recoil";

export interface DeviceState {
  pushNotificationStatus?: string;
}
export const deviceAtom = atom<DeviceState>({
  key: "device",
  default: {
    pushNotificationStatus: undefined,
  },
});
