import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const CHAT_DOMAIN = "chat";

export const CHAT_MESSAGE_LIST_ENDPOINT = "message/list";
export const CHAT_SEND_MESSAGE_ENDPOINT = "message/send";
export const CHAT_READ_MESSAGE_ENDPOINT = "message/read";

export const CHAT_UPDATE_REQUEST_ENDPOINT = "message/task-update/send-request";
export const CHAT_UPDATE_DETAIL_ENDPOINT = "message/task-update/detail";
export const CHAT_UPDATE_SEND_ENDPOINT = "message/task-update/send";

export interface ChatType {
  isMine: boolean;
  nickname: string;
  message: string;
  time: string;
  messageId: number;
}

export interface UpdateMessageType {
  taskUpdateMessageId: string;
  message: string;
  status: string;
  evalReason?: string;
}

export const chatApi = {
  getList: (pid: string, mid_end_exclusive?: number) =>
    axiosInstance.get<CustomResponseFormat<ChatType[]>>(
      `${CHAT_DOMAIN}/${CHAT_MESSAGE_LIST_ENDPOINT}`,
      { params: { pid: pid, mid_end_exclusive: mid_end_exclusive } }
    ),

  send: (pid: string, message: string, isTaskUpdateMessage: boolean) =>
    axiosInstance.post<void>(
      `${CHAT_DOMAIN}/${CHAT_SEND_MESSAGE_ENDPOINT}`,
      {
        message: message,
        isTaskUpdateMessage: isTaskUpdateMessage,
      },
      { params: { pid: pid } }
    ),
  read: (pid: string) =>
    axiosInstance.get<CustomResponseFormat>(
      `${CHAT_DOMAIN}/${CHAT_READ_MESSAGE_ENDPOINT}`,
      { params: { pid: pid } }
    ),

  requestUpdate: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<UpdateMessageType>>(
      `${CHAT_DOMAIN}/${CHAT_UPDATE_REQUEST_ENDPOINT}`,
      { params: { pid: pid } }
    ),
  getUpdateMessageDetail: (pid: string, tu_mid?: string) =>
    axiosInstance.get<CustomResponseFormat<UpdateMessageType>>(
      `${CHAT_DOMAIN}/${CHAT_UPDATE_DETAIL_ENDPOINT}`,
      { params: { pid: pid, ...(tu_mid && { tu_mid: tu_mid }) } }
    ),
  sendUpdateMessage: (
    pid: string,
    messageId: string,
    message: string,
    reason?: string
  ) =>
    axiosInstance.post<void>(
      `${CHAT_DOMAIN}/${CHAT_UPDATE_SEND_ENDPOINT}`,
      {
        messageId: messageId,
        message: message,
        reason: reason,
      },
      { params: { pid: pid } }
    ),
};
