import { axiosInstance } from "api/instance";

export const AUTOMATICTHOUGHT_DOMAIN = "automatic-thought";

export const AUTOMATICTHOUGHT_SCORE_SUBMIT_ENDPOINT = "impact-score/submit";
export const AUTOMATICTHOUGHT_COPINGSTRATEGY_SUBMIT_ENDPOINT =
  "coping-strategy/submit";

interface ImpactScoreType {
  automaticThoughtSeq: number;
  impactScore: number;
  taskKey: string;
}

interface AutomaticThoughtCopingStrategyType {
  automaticThoughtSeq: number;
  copingStrategy: string;
}

export const automaticThoughtApi = {
  submitImpactScore: (body: ImpactScoreType) =>
    axiosInstance.post<void>(
      `${AUTOMATICTHOUGHT_DOMAIN}/${AUTOMATICTHOUGHT_SCORE_SUBMIT_ENDPOINT}`,
      body
    ),
  submitCopingStrategy: (
    pid: string,
    body: AutomaticThoughtCopingStrategyType
  ) =>
    axiosInstance.post<void>(
      `${AUTOMATICTHOUGHT_DOMAIN}/${AUTOMATICTHOUGHT_COPINGSTRATEGY_SUBMIT_ENDPOINT}`,
      body,
      { params: { pid: pid } }
    ),
};
