import { ProgramType } from "data/programData";
import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const SEGMENTATIONNOTE_DOMAIN = "segmentation-note";

export const SEGMENTATIONNOTE_LOAD_ENDPOINT = "data/load";
export const SEGMENTATIONNOTE_SAVE_ENDPOINT = "data/save";
export const SEGMENTATIONNOTE_DONE_ENDPOINT = "done";
export const SEGMENTATIONNOTE_UNDONE_ENDPOINT = "undone";
export const SEGMENTATIONNOTE_LIST_ENDPOINT = "list";

interface LoadedDataType {
  data: ProgramType;
}

export type SentimentType = "positive" | "negative";

export interface SegmentationNoteType {
  segmentationNoteKey: string;
  isDone?: boolean;
  situation?: string;
  date?: string;
  isHighlight?: boolean;
  hasAiRecommendation?: boolean;
  lastDoneAt?: string;
  sentiment?: SentimentType;
  shouldBeDone?: boolean; // 프론트에서만 씀
}

export const segmentationNoteApi = {
  getData: (snk: string) =>
    axiosInstance.get<CustomResponseFormat<LoadedDataType>>(
      `${SEGMENTATIONNOTE_DOMAIN}/${SEGMENTATIONNOTE_LOAD_ENDPOINT}`,
      { params: { snk: snk } }
    ),
  saveData: (snk: string, pid: string, body?: ProgramType) =>
    axiosInstance.post<void>(
      `${SEGMENTATIONNOTE_DOMAIN}/${SEGMENTATIONNOTE_SAVE_ENDPOINT}`,
      { data: body },
      {
        params: { snk: snk, pid: pid },
      }
    ),
  done: (snk: string) =>
    axiosInstance.post<void>(
      `${SEGMENTATIONNOTE_DOMAIN}/${SEGMENTATIONNOTE_DONE_ENDPOINT}`,
      undefined,
      { params: { snk: snk } }
    ),
  undone: (snk: string, pid: string) =>
    axiosInstance.post<void>(
      `${SEGMENTATIONNOTE_DOMAIN}/${SEGMENTATIONNOTE_UNDONE_ENDPOINT}`,
      undefined,
      { params: { snk: snk, pid: pid } }
    ),

  getList: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<SegmentationNoteType[]>>(
      `${SEGMENTATIONNOTE_DOMAIN}/${SEGMENTATIONNOTE_LIST_ENDPOINT}`,
      { params: { pid: pid } }
    ),
};