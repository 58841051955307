import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import * as _ from "lodash";
import { currentTaskSectionIndexAtom } from "recoil/currentTaskSectionIndexAtom";
import { layoutAtom } from "recoil/layoutAtom";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useIsInappWebview from "./useIsInappWebview";
import { sendMessage } from "utils/webview";
import { userAtom } from "recoil/userAtom";
import useIsLatest from "./useIsLatest";
import { patientAtom } from "recoil/patientAtom";

export default function useOpenTask(
  taskKey: string,
  trk?: string,
  mrk?: string,
  snk?: string
) {
  const isLatest = useIsLatest();
  const navigate = useNavigate();
  const isInappWebview = useIsInappWebview();
  const currentIndex = useRecoilValue(currentTaskSectionIndexAtom);
  const [taskSectionState, setTaskSectionState] =
    useRecoilState(taskSectionAtom);
  const setLayoutState = useSetRecoilState(layoutAtom);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const user = useRecoilValue(userAtom);
  const patientState = useRecoilValue(patientAtom);
  const pid = patientState?.patientId;

  const encodedTaskKey = encodeURIComponent(taskKey);
  const newPathName = `/${encodedTaskKey}`;

  function goTask() {
    if (window.location.pathname === newPathName || !isInappWebview) {
      navigate(
        `${newPathName}${trk ? `?trk=${trk}` : mrk ? `?mrk=${mrk}` : snk ? `?snk=${snk}` : ``}`,
        { replace: window.location.pathname === newPathName }
      );
    } else {
      if (isInappWebview) {
        if (taskKey === "payment") {
          sendMessage(
            JSON.stringify({
              event: "open_modal",
              params: {
                url: `${window.location.origin}/payment`,
                type: "transparentModal",
              },
            })
          );
        } else {
          sendMessage(
            JSON.stringify({
              event: "navigate",
              params: {
                url: `${window.location.origin}${newPathName}?at=${
                  user?.accessToken
                }${pid ? `&pid=${pid}` : ``}${
                  trk ? `&trk=${trk}` : mrk ? `&mrk=${mrk}` : snk? `$snk=${snk}`: ``
                }`,
              },
            })
          );
        }
      }
    }
  }

  function addToTabAndOpen() {
    if (!taskSectionState || taskSectionState.length === 0) {
      setTaskSectionState([
        { openTaskKeyList: [taskKey], currentTaskKey: taskKey },
      ]);
    } else {
      const { openTaskKeyList, currentTaskKey } =
        taskSectionState[currentIndex];

      setTaskSectionState((taskSectionState) => {
        if (taskSectionState) {
          const copy = _.cloneDeep(taskSectionState);
          copy[currentIndex] = {
            ...taskSectionState[currentIndex],
            // openTaskKeyList: !openTaskKeyList.includes(taskKey)
            //   ? openTaskKeyList.concat(taskKey)
            //   : openTaskKeyList,
            openTaskKeyList: [taskKey],
            currentTaskKey: taskKey,
          };
          return copy;
        }
        return taskSectionState;
      });
    }

    if (isSmall) {
      setLayoutState((state) => ({ ...state, isLeftSideBarOpen: false }));
    }
  }

  return isLatest ? goTask : addToTabAndOpen;
}