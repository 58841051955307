import { Sheet, Stack } from "@mui/joy";
import splash from "static/image/common/splash.png";

export default function LogoEmptyPage() {
  return (
    <Sheet
      sx={{
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack>
        <img src={splash} alt={"empty"} />
      </Stack>
    </Sheet>
  );
}
