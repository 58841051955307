import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithTextarea,
} from "../../BlockComponent";

import { ConceptItem, LastCard } from "../common";

export const Content_6_97_A = [
  Instruction(`typography_0_0_0`),
  InstructionWithContent(
    `typography_1_0_0`,
    ConceptItem({ conceptKey: "coreBelief" })
  ),
  Instruction(`typography_2_0_0`),
  Instruction(`typography_3_0_0`),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),
  Instruction(`typography_7_0_0`),
  Instruction(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),
  Instruction(`typography_12_0_0`),
  Instruction(`typography_13_0_0`),
  Instruction(`typography_14_0_0`),
  Instruction(`typography_15_0_0`),
  Instruction(`typography_16_0_0`),

  Header1(`typography_17_0_0`),
  InstructionWithContent(
    `part1_typography_0_0_0`,
    ConceptItem({ conceptKey: "coreBelief" }),
    {
      blockId: "common1_start",
    }
  ),
  InstructionWithTextarea(`part1_typography_1_0_0`, { id: "초기 경험 초안" }),
  Instruction(`typography_18_0_0`, { blockId: "common1_end" }),

  ...LastCard,
];
