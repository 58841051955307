export const translation_5_7_T = {
  en: {},
  ko: {
    v1: {
      "5분": "5분",
      "10분": "10분",
      월요일: "월요일",
      화요일: "화요일",
      수요일: "수요일",
      목요일: "목요일",
      금요일: "금요일",
      토요일: "토요일",
      일요일: "일요일",
      "오전 7시": "오전 7시",
      "오전 10시": "오전 10시",
      "오후 1시": "오후 1시",
      "오후 4시": "오후 4시",
      "오후 7시": "오후 7시",
      "오후 10시": "오후 10시",
      initial_typography_0_0_0: "지금부터는 생각의 강 훈련을 진행해 볼 거예요.",
      initial_typography_1_0_0:
        "우리는 생각과 거리두기 훈련을 통해 부정적인 생각을 효과적으로 다루는 방법을 이해하고 연습했어요.",
      initial_typography_2_0_0:
        "나의 자동적 사고 패턴에 담겨있는 생각, 감정, 신체감각으로부터 거리두는 연습을 꾸준히 하는 것은 무척 중요하답니다.",
      initial_typography_3_0_0:
        "생각과 거리를 두고나면, 다시 자신이 원하는 삶을 향해 나아갈 수 있어요.",
      initial_typography_4_0_0:
        "꾸준한 <명상활동지> 작성을 통해 점차 생각과 거리를 두는 일이 익숙해지고 조금씩 발전해가는 스스로에 대해 더 많은 걸 이해할 수 있을 거예요.",
      initial_typography_5_0_0: "<명상활동지>는 자주 하실수록 좋아요.",
      initial_typography_6_0_0:
        "자주 하실수록 매우 강렬해보이는 생각의 연결고리를 풀어내는데 도움을 얻을 수 있답니다.",
      initial_typography_7_0_0:
        "잊지 않고 하실 수 있도록 푸시 알림으로 알려드릴게요.",
      initial_typography_8_0_0: "알림을 허용해주세요.",
      initial_typography_9_0_0:
        "일주일 중 무슨 요일에 하면 좋을까요? 원하는 요일을 모두 선택해주세요.",
      initial_typography_10_0_0:
        "하루 중 언제 알려드리면 좋을까요? 원하는 시간을 모두 선택해주세요.",
      typography_9_0_0:
        "일주일 중 무슨 요일에 하면 좋을까요? 원하는 요일을 모두 선택해주세요.",
      typography_10_0_0:
        "하루 중 언제 알려드리면 좋을까요? 원하는 시간을 모두 선택해주세요.",
      initial_typography_11_0_0:
        "훈련을 다 마치셨으면 항상 [마치기]를 눌러주시고요.",

      typography_0_0_0: "현재 무엇을 느끼고 있나요?",
      typography_1_0_0: "지금 현재 떠오르는 생각에 얼마나 영향받고 있나요? ",
      typography_2_0_0:
        "지금부터 생각의 강 훈련으로 기꺼이 경험해보려 해요. 어느 정도 시간이 있을까요?",
      typography_3_0_0: "준비됐다면 음성 안내에 따라 훈련을 시작하세요.",
      typography_4_0_0:
        "훈련은 어땠나요? 느낀 점이나 어려웠던 점은 없었는지 적어주세요.",
      typography_5_0_0:
        "좋아요. 생각의 강 훈련을 하고난 지금, 떠오른 생각과 감정에 얼마나 영향받고 있나요? 훈련 전에는 $A$점이라고 하셨어요.",
      typography_6_0_0:
        "좋아요. 혹시 생각의 강 훈련을 하고나서 새롭게 떠오르는 내용이 있다면 그것이 어떤 것이든 상관없이 <생각기록지>에 자유롭게 적어주세요. ",
      typography_7_0_0:
        "생각의 강 훈련은 생각을 하나의 심리적 사건처럼, 즉, 생각을 그저 하나의 팝콘 조각처럼 바라보기 위한 연습이라는 것을 기억해주세요.",

      typography_0: "어떤 명상을 할까요?",
      coping_0: "지금 어떤 점이 가장 힘든가요?",
      coping_grounding_0:
        "지금부터 15분 정도 '심호흡 그라운딩'을 진행하며 감정의 영향권에서 벗어나 볼 거예요.",
      coping_relaxation_0:
        "지금부터 15분 정도 '근육이완법'을 진행하며 전신을 편안히 이완시킬 거예요.",
      coping_breath_0:
        "지금부터 15분 정도 '호흡 명상'을 진행하며 곱씹는 생각에서 빠져나올 거예요.",
      coping_willingness_0:
        "지금부터 10분 정도 '기꺼이 경험하기'를 진행하며 몸과 호흡으로 온전히 느껴볼 거예요.",
      audio_instruction: "준비됐다면 음성 안내에 따라 훈련을 시작하세요.",
      coping_grounding_1:
        "잘 하셨어요. 휘몰아치던 감정이 조금은 가라앉았을 거예요.",
      coping_grounding_2:
        "이제 그 감정을 기꺼이 경험하며 피하거나 거부하지 않고, 호기심을 갖고 관찰해보는 건 어떨까요?",
      coping_relaxation_1:
        "잘 하셨어요. 강렬했던 신체 반응이 조금은 가라앉았을 거예요.",
      coping_relaxation_2:
        "이제 그 신체 반응을 기꺼이 경험하며 피하거나 거부하지 않고, 호기심을 갖고 관찰해보는 건 어떨까요?",
      coping_breath_1: "잘 하셨어요. 곱씹는 생각이 조금은 가라앉았을 거예요.",
      coping_breath_2:
        "이제 그 생각을 기꺼이 경험하며 피하거나 거부하지 않고, 호기심을 갖고 관찰해보는 건 어떨까요?",
      coping_willingness_1:
        "잘 하셨어요. 있는 그대로 받아들이니 오히려 괴로움은 나의 선택이라는 것을 알 수 있었을 거예요.",

      coping_1: "무엇이 힘들었는지 코치 선생님과 함께 살펴보고 싶나요?",
      coping_2:
        "어떤 일이 있었나요? 그때를 떠올리며 마치 슬로우비디오를 보듯 얘기해주세요.",
      coping_2_0: "언제 있었던 일인가요?",
      coping_2_1:
        "어떤 일이 있었나요? 감정과 판단을 배제한 객관적인 상황을 적어주세요.",
      coping_3:
        "좋아요. <생각기록지>에 옮겨두었으니 그곳에서 마저 작성해주세요.",

      thoughtRecord_impact: "나는 그 일에 얼마만큼 영향 받았나요?",

      thoughtRecord_date: "언제 있었던 일인가요?",
      thoughtRecord_situation:
        "[상황] 감정과 판단을 배제한 객관적인 상황을 적어주세요.",
      routine_0: "좋아요. 몇분간 훈련을 진행할까요?",
      routine_1: "어떤 이미지를 떠올리며 훈련할까요?",
      routine_2: `$A$분 동안 명상 훈련을 했네요. 고생 많으셨어요.`,
      routine_3:
        "명상엔 잘하고 못하고가 없으며, 마냥 오래 하는 것이 중요한 게 아니라는 것 기억하시죠?",
      routine_4: "꾸준히 연습할 수 있다면 그것으로도 충분해요.",
      routine_5:
        "궁금하거나 어려웠던 점이 있었다면 적어주세요. 명상 훈련은 원리를 잘 이해하고 진행하는 것이 중요하답니다.",
      wrapup_0: "그럼 이번 훈련은 여기까지 할게요.",
      wrapup_1: "훈련을 마쳤어요. [마치기]를 눌러주세요.",
    },
  },
};
