export const translation_101_1_1_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "우울과 무기력을 극복하는 방법을 발견한 과학자들은 모두 공통으로 한 가지 사실을 발견했어요.",
      typography_1_0_0: "“인간은 수동적으로 반응하는 존재”라는 점이죠.",
      typography_2_0_0:
        "당신이 악순환이 지속되는 방향으로 행동하는 데에는 다 이유가 있답니다.",
      typography_3_0_0:
        "즉, 당신이 특정한 방향으로 행동할 때 그 행동의 결과가 당신에게 나름의 이점이 있었고, 그 결과 그 행동이 더 자주 발생하는 것이지요.",
      typography_4_0_0:
        '이처럼 행동의 결과가 자신에 이점을 주는 방식으로 작동하여 그 행동이 다시 강해지는 현상을 “강화"라고 불러요.',
      typography_5_0_0:
        "행동은 강화의 종류에 따라 두 가지로 분류할 수 있는데요.",
      typography_6_0_0: "하나씩 살펴보죠.",
      typography_7_0_0: "부정 강화 행동",
      typography_8_0_0:
        "부정 강화 행동은 ‘부정적인 상태를 감소시키고자 하는 목적'에 의해 진행되는 행동을 뜻해요.",
      typography_9_0_0:
        "이 행동은 자신의 부정적인 감정을 줄이기 위해 쾌락적 자극을 추구하는 형태로 나타나기도 해요.",
      typography_10_0_0:
        "우울, 불안, 스트레스와 같은 감정으로 인해 폭식을 하거나, 게임에 몰두하거나, 약이나 술에 의존하는 것이 그 예시죠.",
      typography_11_0_0:
        "그러한 자극은 즉각적으로 나의 기분을 좋게 만들어주기 때문에 당장 느끼는 괴로움은 어느 정도 줄어들 수도 있어요.",
      typography_12_0_0:
        "그렇기 때문에 그 행동은 강화되고, 이후에 더 자주 발생하죠.",
      typography_13_0_0:
        "부정 강화 행동은 조금 더 미묘한 형태로 나타나기도 해요.",
      typography_14_0_0:
        "친구를 사귀고 싶지만 거절당할 것이 두려워 모임에 나가지 않고 집에만 머무르는 예시를 생각해 볼까요?",
      typography_15_0_0:
        "이 예시에서 ‘집에만 머무르는 행동'은 나의 두려움이나 걱정을 감소시켜 주는 역할을 해요.",
      typography_16_0_0:
        "역시나 그런 이점이 있기 때문에 그 행동은 강화되고, 이후에 더 자주 발생하죠.",
      typography_17_0_0: "이러한 부정 강화 행동들은 공통점이 있어요.",
      typography_18_0_0:
        "단기적으로는 이점을 주지만 장기적으로는 더 지속하게 만들어 악순환 고리를 만든다는 것이에요.",
      typography_19_0_0:
        "우리는 앞으로 우리 자신의 행동에서 무기력의 악순환을 만드는 ‘부정 강화 행동'들을 찾아내 바꾸는 연습을 할 거예요.",
      typography_20_0_0: "긍정 강화 행동",
      typography_21_0_0:
        "긍정 강화 행동은 ‘긍정적인 보상을 얻고자 하는 목적'에 의해 진행되는 행동을 뜻해요.",
      typography_22_0_0:
        "이 행동의 가장 단순한 형태는 즐거움이나 활력을 주는 행동들이죠.",
      typography_23_0_0:
        "산책하기, 샤워하기, 창문 열고 환기하기 등과 같이 비교적 단순하지만 활력을 주는 행동들이 이에 해당해요.",
      typography_24_0_0:
        "이러한 행동들은 우리에게 즐거움이나 활력을 제공해 주기 때문에, 그 행동은 강화되고, 이후에 더 자주 발생해요.",
      typography_25_0_0:
        "반면에 조금 더 어렵지만 강한 형태의 긍정 강화 행동도 있답니다.",
      typography_26_0_0:
        "자신이 원하는 형태의 삶으로 나아가기 위해 실천하는 행동들이에요.",
      typography_27_0_0:
        "예를 들면 공부, 운동, 사람들과 어울리기, 자기 주장하기, 독서 등 다양한 활동들이 이에 해당하죠.",
      typography_28_0_0:
        "그러한 행동들은 당장에 강력한 보상을 주지는 않아요. 오히려 그 과정이 힘들 수도 있죠.",
      typography_29_0_0:
        "하지만 장기적인 측면에서 큰 성취감과 강력한 보상을 줘요.",
      typography_30_0_0:
        "따라서 이러한 활동들이 성공적으로 진행되면 우리는 삶에 더 적극적으로 참여하게 되고 자신감을 얻게 된답니다.",
      typography_31_0_0:
        "이러한 긍정 강화 행동들은 단기, 장기적으로 삶에서 긍정적인 보상을 느낄 수 있도록 하며 선순환을 만들어낸답니다.",
      typography_32_0_0:
        "행동의 결과가 성취감에 의해 강화가 되니 더 자주, 열심히 행동하고, 그것이 또 좋은 성과들을 만들어내는 것이죠.",
      typography_33_0_0:
        "우리는 앞으로 우리 자신의 행동에서 선순환의 변화를 만드는 ‘긍정 강화 행동'들을 하나씩 실천해 보는 연습을 할 거예요.",
      typography_34_0_0:
        "지금까지 우리가 억제할 행동과 촉진할 행동에 대해 배웠는데요.",
      typography_35_0_0:
        "그렇다면 정말로 우리의 삶에서 이런 행동들이 나타나는지 알아야겠죠?",
      typography_36_0_0: "자 그럼 이제 우리의 행동을 관찰하러 가볼게요!",
    },
  },
};
