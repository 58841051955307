export const translation_1_3 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "아직 우리가 능동적이고 의지대로 생각하고 행동하는 존재라고 믿나요?",
      typography_1_0_0:
        "그렇다면 다음의 질문에 스스로 답변을 해보시기 바랍니다.",
      typography_2_0_0: "생각은 전두엽의 신경 발화로부터 만들어집니다.",
      typography_3_0_0: "이것은 의학이 밝혀낸 명백한 사실입니다.",
      typography_4_0_0:
        "만약 우리가 의지대로 생각하고 행동하는 존재라면 이 신경 발화를 조절할 수 있어야 합니다.",
      typography_5_0_0: "좋습니다. 일단은 그렇다고 해보죠.",
      typography_6_0_0: "그렇다면 이 신경 발화를 조절하는 것은 누구일까요?",
      typography_7_0_0:
        "전두엽의 어떤 신경이 발화되도록 선택하는 것은 누구인가요?",
      typography_8_0_0: "그 선택하는 의지는 어디서 발생하는 걸까요?",
      typography_9_0_0: "또다른 전두엽의 신경 발화인 걸까요?",
      typography_10_0_0: "그럼 또 그 신경 발화를 선택하는 건 누구일까요?",
      typography_11_0_0:
        "맞습니다. 아마 순환 논리에 빠져 명확한 답을 하시기 어려울 것입니다.",
      typography_12_0_0: "실용적이지 않은 설명",
      typography_13_0_0:
        "누군가는 이것을 설명하기 위해 영적이거나 형이상학적인 이야기를 합니다.",
      typography_14_0_0: "영혼이나 의식의 기원을 이야기하면서요.",
      typography_15_0_0:
        "하지만 이러한 설명은 사람들에게 그다지 실용적인 이점을 주지 못합니다.",
      typography_16_0_0:
        "설령 그러한 영적인 탐구가 누군가에게는 실용적이라 하더라도, 그것을 우리 모두가 받아들이기는 어려울 것입니다.",
      typography_17_0_0:
        "디스턴싱 프로그램에서는 보다 우리 모두에게 적용할 수 있는 보편적이고 실용적인 측면에서 마음을 이야기할 것입니다.",
      typography_18_0_0: "변화의 시작",
      typography_19_0_0:
        '그러기 위해서 우리가 받아들여야 할 첫 번째 전제는 "생각하는 나"는 착각이라는 사실입니다.',
      typography_20_0_0: "생각은 내가 능동적으로 해낸 것이 아닙니다.",
      typography_21_0_0:
        "나름의 이유로 인해, 나의 의지와 무관하게 마음속에 떠오른 것일 뿐입니다.",
      typography_22_0_0:
        "이 사실을 진심으로 이해하고 받아들인다면, 우린 많은 심리적 문제를 해결할 수 있습니다.",
      typography_23_0_0: '"생각하는 나"가 착각이라는 사실을 받아들이면,',
      typography_24_0_0: "우린 생각으로부터 한 걸음 물러날 수 있습니다.",
      typography_25_0_0:
        "그리고 우리는 비로소 생각에 대한 생각을 시작할 수 있습니다.",
      typography_26_0_0: "내면과 관계를 다시 맺기",
      typography_27_0_0:
        "지금부터 우리는 디스턴싱을 통해 내면과 다시 관계를 맺는 방법에 대해 살펴볼 겁니다.",
      typography_28_0_0:
        "만약 여러분이 이 과정을 충실히 따라온다면, 삶에 큰 변화가 찾아올 것이라 분명하게 약속할 수 있습니다.",
      typography_29_0_0: "지금은 의심쩍은 마음이 들어도 좋습니다.",
      typography_30_0_0:
        "어쩌겠습니까? 나의 마음속에 그러한 생각이 떠올랐을 뿐인걸요.",
      typography_31_0_0:
        "여러분들이 그런 생각을 한 것이 아닙니다. 그러한 생각이 그저 마음속에 떠오른 것 뿐입니다.",
      typography_32_0_0: "그 사실을 인지하고 다음 단계로 넘어가 봅시다.",
    },
  },
};
