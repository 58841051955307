export const translation_100_1_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "무기력에 빠져버린 사람들은 우울감을 함께 느끼곤 해요.",
      typography_1_0_0:
        "과학기술이 이렇게도 발전한 지금에도 여전히 많은 사람이 우울과 무기력의 늪에서 빠져나오지 못하고 있죠.",
      typography_2_0_0: "무기력은 왜 그렇게 빠져나오기 힘든 걸까요?",
      typography_3_0_0:
        "그 이유는 우울과 무기력이 지독한 악순환의 고리를 형성하기 때문이랍니다.",
      typography_4_0_0: "무기력의 악순환",
      typography_5_0_0:
        "삶을 살아가다 보면 제법 큰 스트레스나 위기의 순간을 한 번쯤은 마주하기 마련이에요.",
      typography_6_0_0:
        "목표 달성의 실패, 인간관계 문제, 소중한 사람과의 이별과 같은 순간들 말이에요.",
      typography_7_0_0:
        "사람들은 흔히 그런 순간에 자신이 겪는 부정적인 감정을 줄이려는 시도를 해요.",
      typography_8_0_0:
        "다시 실패를 마주할 것이 두려워 도전을 꺼리기도 하고, 사람들과의 관계를 줄이기도 하죠.",
      typography_9_0_0: "잠시 동안은 기분을 나아지게 만들지 몰라요.",
      typography_10_0_0:
        "하지만 문제는 이러한 시도가 결과적으로는 우리를 더 우울하게 만들고, 더 스트레스받게 만든다는 것이에요.",
      typography_11_0_0:
        "예를 들어볼까요? 민수가 회사에서 해고된 상황을 떠올려봐요.",
      typography_12_0_0:
        "그 상황에서 민수는 패배감을 느껴요. 불안하고 미래를 걱정하죠.",
      typography_13_0_0:
        "그런 감정이 불편했던 민수는 밖에서 사람들을 만나지 않아요. 회사에 대해 물어볼 것 같기 때문이죠.",
      typography_14_0_0:
        "구직활동도 자꾸 미루게 돼요. 이력서를 제출했는데 거절하는 상황이 닥칠까 무섭기 때문이죠.",
      typography_15_0_0: "그러한 행동들은 단기적으로 부정적인 감정을 줄여줘요.",
      typography_16_0_0:
        "이런 이점 때문에 민수는 더더욱 같은 행동을 많이 하게 된답니다.",
      typography_17_0_0:
        "하지만 장기적으로는 어떨까요? 민수가 사람들을 만나지 않고 구직 활동을 하지 않는 건 장기적으로 상황을 악화시켜요.",
      typography_18_0_0:
        "결국 민수가 느끼는 패배감과 불안, 걱정은 점점 커질 수밖에 없답니다.",
      typography_19_0_0: "이것이 무기력의 악순환이에요.",
      typography_20_0_0: "악순환에서 선순환으로",
      typography_21_0_0:
        "중요한 점은 ‘행동' 측면에서 보았을 때 당신이 느끼는 무기력이나 우울감은 충분히 이해할 수 있다는 것이에요.",
      typography_22_0_0:
        "당신이 무기력한 행동을 지속하는 데에는 명확한 이유가 있죠.",
      typography_23_0_0:
        "그 행동이 당장에는 당신이 느끼는 부정적인 감정을 줄여줄 수 있기 때문이에요.",
      typography_24_0_0:
        "즉, 그건 당신이 마주한 삶에서의 어려움에 대한 자연스러운 대처였죠.",
      typography_25_0_0:
        "지금 느끼는 무기력이나 우울감은 당신의 잘못이 아닙니다. 당신은 오히려 많은 노력을 했었어요.",
      typography_26_0_0:
        "다만 잠깐 발을 잘못 디뎌 그것을 해결하려는 노력이 오히려 무기력을 증폭시키는 악순환에 빠져들고만 것뿐이에요.",
      typography_27_0_0:
        "너무 걱정하지 마세요. 우리가 당신이 무기력에서 빠져나올 수 있도록 도와드릴 거예요.",
      typography_28_0_0:
        "그 방법은 악순환을 돌고 있는 행동 패턴을 다시 선순환 패턴으로 돌리는 것이에요.",
      typography_29_0_0:
        "조금씩 선순환이 돌게 되면 다시 삶에서 활력이 생기고, 목표를 이루기 위한 동력이 회복된다는 걸 느낄 거예요.",
      typography_30_0_0:
        "그때가 되면 다시 내가 원했던 삶의 방향으로 나아가면 된답니다.",
      typography_31_0_0: "자, 이제 그 첫걸음을 시작해 볼 텐데요.",
      typography_31_0_0_1: "자, 이제 그 첫걸음을 시작해 볼게요.",
      typography_32_0_0: "그 과정을 도와줄 코치 선생님을 만나볼 거예요.",
      typography_33_0_0:
        "코치 선생님은 내가 행동 변화를 만들어낼 수 있도록, 행동을 관찰, 계획, 분석하는 과정을 도와줄 거랍니다.",
      typography_34_0_0:
        "코치 선생님의 메시지를 받으려면 알림을 꼭 허용해주셔야 해요.",
      typography_35_0_0: "좋아요. 이제 코치 선생님이 연락을 드릴 거예요.",
    },
  },
};
