import { translation_selfCheck } from "../selfCheck";
export const translation_0_1_A = {
  en: {
    v1: {
      상황: "Situation",
      생각: "Thought",
      반응: "Reaction",
      typography_0_0_0:
        "Filling out the <Thought Log> wasn't too hard, was it?",
      typography_1_0_0: "Let's try a quick quiz.",
      typography_2_0_0: "In a specific",
      typography_2_0_2: "",
      typography_2_1_0: "a certain ",
      select_2_1_1_option2: "Reaction",
      typography_2_1_2: " comes to mind,",
      typography_2_2_0: "and as a result, we have a ",
      typography_2_2_2: "",
      typography_2_3_0:
        "Think again. If it's tough, feel free to revisit the <Practicing Logging Thoughts> and come back.",
      typography_3_0_0:
        "That's right. The three parts were situation-thought-reaction.",
      typography_4_0_0: "Impact in 'reactions'",
      typography_5_0_0:
        "Our program mainly deals with 'thoughts' and practices distancing from them.",
      typography_6_0_0:
        "The positive effects of distancing will directly manifest in 'reactions'.",
      typography_7_0_0:
        "By distancing ourselves from negative thoughts, negative reactions will also decrease.",
      typography_8_0_0: "Keep tracking consistently",
      typography_9_0_0:
        "So, every two weeks, we'll track how much these negative reactions have reduced.",
      typography_10_0_0:
        "We'll observe the reduction under three categories: 'depression', 'anxiety', 'stress'.",
      typography_11_0_0:
        "Reactions like feeling sad (emotion), having a heavy body (physical), and feeling listless (action) will be categorized as 'depression'.",
      typography_12_0_0:
        "Reactions like feeling restless (emotion), having rapid breathing (physical), and being unable to stay still (action) can be categorized as 'anxiety'.",
      typography_13_0_0:
        "And reactions like getting irritated (emotion), having headaches (physical), and being angry (action) will fall under 'stress'.",
      typography_14_0_0:
        "Remember, these categories are not strictly separated, but they represent the most common negative reactions in our daily lives.",
      typography_15_0_0: "Before we start the first observation,",
      typography_16_0_0: "there's something important to know.",
      typography_17_0_0: "Distancing is a skill",
      typography_18_0_0:
        "Distancing doesn't talk about the presence or absence of depression or anxiety disorders.",
      typography_19_0_0:
        "We'll discuss not the presence of a disorder, but the extent of our distancing skills.",
      typography_20_0_0:
        "Someone good at distancing may be less affected by feelings of depression, while others may be more affected.",
      typography_21_0_0:
        "It's like someone with strong leg muscles can run faster than others, while those with weaker muscles run slower.",
      typography_22_0_0:
        "So, remember, when we check for depression, anxiety, and stress, we're not talking about mental disorders.",
      typography_23_0_0:
        "We're measuring how much we've developed the skill of distancing.",
      typography_24_0_0: "Let's get started. It will take about 2 minutes.",
      ...translation_selfCheck.en.v1,
    },
  },
  ko: {
    v1: {
      상황: "상황",
      생각: "생각",
      반응: "반응",
      typography_0_0_0: "<생각기록지> 작성은 어렵지 않으셨죠?",
      typography_1_0_0: "깜짝 퀴즈를 하나 드려볼게요.",
      typography_2_0_0: "우리는 특정",

      typography_2_0_2: " 에서",
      typography_2_1_0: "어떠한 ",
      typography_2_1_2: " 이 떠오르고,",

      typography_2_2_0: "그 결과로",
      typography_2_2_2: " 을 한다 ",

      typography_2_3_0:
        "다시 생각해보세요. 어렵다면 <생각기록지 작성 연습하기>를 다시 살펴보고 와도 좋아요.",
      typography_2_3_0_1:
        "다시 생각해보세요. 어렵다면 <생각기록지>를 다시 살펴보고 와도 좋아요.",
      typography_3_0_0: "맞아요. 세 파트는 상황-생각-반응이었죠.",
      typography_4_0_0: "거리두기의 효과는 '반응'에서",
      typography_5_0_0:
        "우리 프로그램은 '생각'을 주요하게 다루며 그와 거리두는 방법을 연습할 거라 말씀드렸는데요.",
      typography_6_0_0:
        "거리두기의 긍정적인 효과가 직접적으로 나타나는 것은 바로 '반응'일 거예요.",
      typography_7_0_0:
        "마음속에서 떠오르는 부정적인 생각과 거리를 두면, 부정적인 반응도 덩달아 감소하게 될 테니까요.",
      typography_8_0_0: "꾸준히 트래킹해요",
      typography_9_0_0:
        "따라서 앞으로 2주에 한 번씩 부정적인 반응이 얼마나 줄고 있는지 트래킹해 나갈 거예요.",
      typography_10_0_0:
        "그 감소 정도를 '우울', '불안', '스트레스', 이렇게 세 카테고리로 관찰해 나갈 겁니다.",
      typography_11_0_0:
        "슬프고(감정), 몸이 무겁고(신체 반응), 무기력한(행동) 반응은 '우울'로 대표할 수 있고요.",
      typography_12_0_0:
        "초조하고(감정), 호흡이 가빠지고(신체 반응), 안절부절못하는(행동) 반응은 '불안'으로 대표할 수 있거든요.",
      typography_13_0_0:
        "또 짜증이 나고(감정), 머리가 지끈거리고(신체 반응), 화를 내는(행동) 반응은 '스트레스' 카테고리에서 관찰하고요.",
      typography_14_0_0:
        "물론 이 세 카테고리가 서로 명확한 경계로 나눠지는 것은 아니지만, 우리 현대인들의 일상과 가장 밀접한 대표적인 부정적 반응이라 보시면 돼요.",
      typography_15_0_0: "이제 첫 번째 관찰을 해볼 건데요.",
      typography_16_0_0:
        "그전에 꼭 하나 알아두셔야 할 것이 있어 잠시 살펴보고 갈게요.",
      typography_17_0_0: "거리두기는 하나의 능력이에요",
      typography_18_0_0:
        "디스턴싱은 우울증이라는 질환이 있고 없음을, 또 불안장애라는 질환이 있고 없음을 이야기하지 않아요.",
      typography_19_0_0:
        "우리는 질환의 유무가 아닌, 거리두기라는 능력의 많고 적음을 이야기할 거예요.",
      typography_20_0_0:
        "누군가는 거리두기를 잘 할 줄 알아 우울감에 덜 영향받을 수 있고, 그렇지 않은 사람은 보다 더 영향을 받을 뿐이죠.",
      typography_21_0_0:
        "마치 다리 근육이 발달한 사람은 남들보다 빨리 달릴 수 있고, 그렇지 않은 사람은 조금 느리게 달리는 것처럼요.",
      typography_22_0_0:
        "그렇기 때문에 앞으로 우리가 체크할 우울, 불안, 스트레스는 정신질환이 있고 없고를 말하는 것이 아님을 기억해주세요.",
      typography_23_0_0:
        "거리두기 능력을 얼마나 함양해 나가고 있는지 측정해 나가는 것이랍니다.",
      typography_24_0_0: "그럼 시작해보죠. 한 2분 정도 걸릴 거예요.",
      ...translation_selfCheck.ko.v1,
    },
  },
};
