export const translation_0_2 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "재차 강조하지만, 지금의 고단한 삶은 여러분의 책임이 아닙니다.",
      typography_1_0_0:
        "하지만 앞으로의 삶을 이끌고 결정하는 건 여러분 자신만이 할 수 있다는 것을 명심하세요.",
      typography_2_0_0: `여러분들이 지금 왜 그러고 있을 수밖에 없는지 잘 알고 있습니다.`,
      typography_3_0_0:
        "무기력하고 싶어 무기력한 사람은 없습니다. 우울하고 싶어 우울한 사람도 없지요.",
      typography_4_0_0: "모든 행동에는 이유가 있지요.",
      typography_5_0_0:
        "하지만 그 이유에 붙들려 마비된 상태로는 아무런 변화도 만들어 낼 수 없을 겁니다.",
      typography_6_0_0:
        "문제의 원인은 환경일 수 있지만, 변화의 원인은 환경이 될 수 없습니다.",
      typography_7_0_0: "변화의 원인은 늘 여러분 자신이어야 합니다.",
      typography_8_0_0: `지금부터는 디스턴싱이 어떻게 여러분들의 삶을 변화시키는지 간략히 설명해드릴게요.`,
      typography_9_0_0: "무엇을 얻는가?",
      typography_10_0_0:
        "지금껏 수많은 사람들이 디스턴싱을 통해 삶을 새롭게 변화시켰습니다.",
      typography_11_0_0:
        "마음을 열고 프로그램에 적극적으로 참여한다면, 여러분들의 삶에서도 큰 전환점이 찾아올 거라 확신합니다.",
      typography_12_0_0: "오랜 고민과 괴로움에서 벗어날 수도 있고,",
      typography_13_0_0:
        "보다 더 충만하고 행복한 삶을 보낼 수도 있을 것입니다.",
      typography_14_0_0: "마음속 불편한 생각이 떠올라도,",
      typography_15_0_0:
        "내가 원하는 방향으로 삶을 이끌어 나갈 수 있는 진짜 자유를 얻을 수 있을 것입니다.",
      typography_16_0_0: "무엇을 하는가?",
      typography_17_0_0:
        "1. 주의 통제: 원하는 방향으로 주의를 전환하고 집중하는 힘을 기른다.",
      typography_18_0_0:
        "2. 내면 관찰: 의식 속에 떠오르는 생각, 감정, 신체 감각, 행동 방식을 관찰하고 알아차린다.",
      typography_19_0_0:
        "3. '나' 알아차림: 생각과 거리를 두고 '나'에 대한 감각을 되찾는다.",
      typography_20_0_0:
        "4. 기꺼이 경험하기: 부정적인 내적 경험을 회피하지 않고 기꺼이 경험한다.",
      typography_21_0_0:
        "5. 가치에 따라 행동하기: 나의 가치로 주의를 옮기고, 그에 따른 행동을 선택한다.",
      typography_22_0_0: "어떻게 진행되는가?",
      typography_23_0_0:
        "앞으로 매일 이러한 활동지가 주어질 겁니다. 길어도 20분 내로 마칠 수 있는 분량일 거예요.",
      typography_24_0_0:
        "그리고 여러분들에게 코치 선생님이 한 분씩 배정될 텐데요.",
      typography_25_0_0:
        "코치 선생님은 여러분들이 디스턴싱을 더 잘 이해하고 훈련해 나갈 수 있도록 옆에서 도울 겁니다.",
      typography_26_0_0:
        "코치 선생님이 당신을 뭐라고 부르면 좋을까요? 원하는 닉네임을 적어주세요.",
      typography_27_0_0:
        "좋습니다. 그럼 이제 주체적으로 삶을 선택해나갈 수 있는 자유를 얻으러 가보시죠.",
    },
  },
};
