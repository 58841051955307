export const translation_1_3_A = {
  en: {
    v1: {
      typography_0_0_0:
        "To delve into our thoughts, we first need to understand how our mind works.",
      typography_1_0_0: "How do you think?",
      typography_2_0_0: '"I just think, right? What a weird question."',
      typography_3_0_0:
        "You probably believe you're freely thinking whatever you wish.",
      typography_4_0_0: "Let's take a moment to explore this.",
      typography_5_0_0: "Thoughts aren't free",
      typography_6_0_0:
        "Can you think of a paramecium? It skims freely across water.",
      typography_7_0_0:
        "Scientists have learned a great deal about these organisms.",
      typography_8_0_0: "They understand which stimuli paramecia are drawn to.",
      typography_9_0_0:
        "They can even predict a paramecium's path with almost 100% accuracy before it moves.",
      typography_10_0_0:
        "This suggests that paramecia aren't free creatures moving at their own will.",
      typography_11_0_0: "Now, what about ants?",
      typography_12_0_0: "Ants are far more complex than paramecia.",
      typography_13_0_0:
        "They have intricate bodies and sophisticated social structures. While we don't know everything about them,",
      typography_14_0_0:
        "scientists have made significant discoveries, such as the role of pheromones.",
      typography_15_0_0:
        "We can predict and even influence ant movements using pheromones.",
      typography_16_0_0:
        "Like paramecia, ants likely don't move entirely freely of their own will.",
      typography_17_0_0:
        "What about dogs? Are they entirely free in their actions?",
      typography_18_0_0: "And humans? Do we always think as we wish?",
      typography_19_0_0:
        "Imagine aliens exist. Assume they are highly advanced beings.",
      typography_20_0_0: "They know the secrets of the universe.",
      typography_21_0_0:
        "They understand everything about the human body, including how our brains work.",
      typography_22_0_0: "Would aliens think we think freely?",
      typography_23_0_0:
        "Probably not. They might manipulate specific conditions to influence our thoughts.",
      typography_24_0_0: "This isn't to delve into deep philosophy.",
      typography_25_0_0:
        "The point is, we might not be as free in our thoughts as we believe.",
      typography_26_0_0: "Mind, a thought-making machine",
      typography_27_0_0:
        "Let's do an activity to show we're not thinking freely.",
      typography_28_0_0: "Set a 60-second timer on your phone.",
      typography_29_0_0:
        "Close your eyes for 60 seconds and try not to think of anything.",
      typography_30_0_0:
        "Ready? Start. Once 60 seconds are up, proceed to the next part.",
      typography_31_0_0:
        "What thoughts popped into your mind during that time? Shall we write them down?",
      typography_32_0_0:
        "If you paid close attention, you might have noticed countless thoughts constantly emerging in your mind.",
      typography_33_0_0:
        "This is contrary to the belief that we freely and nobly create certain types of thoughts.",
      typography_34_0_0: "Our mind operates like a popcorn machine.",
      typography_35_0_0:
        "Our brain continuously pops thoughts like a popcorn machine. Right now, pop! Pop! The popcorn is jumping.",
      typography_36_0_0:
        "Like popcorn unpredictably jumping in the machine, various thoughts continuously and randomly pop up in our minds.",
      typography_37_0_0:
        "The creation of these popcorn-thoughts isn't up to our will.",
      typography_38_0_0:
        "Popcorn jumps randomly, so we can't consciously choose what will pop next.",
      typography_39_0_0:
        "Similarly, thoughts are just irregular emergences based on past experiences and environmental conditions.",
      typography_40_0_0: "Thoughts aren't 'me'",
      typography_41_0_0: "Let's summarize what we've discussed so far.",
      typography_42_0_0: `"We don't think freely, and our mind randomly creates thoughts."`,
      typography_43_0_0: "So, what if negative thoughts keep popping up?",
      typography_44_0_0:
        "The solution starts with realizing that thoughts aren't equivalent to 'myself.'",
      typography_45_0_0:
        "Recall the activity where we observed the thoughts that came up in 60 seconds.",
      typography_46_0_0:
        "Clearly, I was observing the thoughts randomly popping up in my mind.",
      typography_47_0_0:
        "The 'me' observing these thoughts is distinct from them.",
      typography_48_0_0:
        "However, many people perceive 'thoughts = me' and react unconsciously and automatically to the thoughts that arise.",
      typography_49_0_0:
        "We might get angry engulfed by strong thoughts, or succumb to self-consuming thoughts.",
      typography_50_0_0:
        "Believing that thoughts are ourselves and automatically reacting to arising thoughts is what we call 'being close to our thoughts.'",
      typography_51_0_0:
        "Through 'distancing,' we'll create space between thoughts and ourselves, enhancing our ability to be less affected by negative thoughts.",
      typography_52_0_0:
        "For this, we've learned how our mind operates. To summarize:",
      typography_53_0_0: "We don't think freely,",
      typography_54_0_0:
        "Our mind operates like a popcorn machine constantly creating thoughts,",
      typography_55_0_0:
        "And we unconsciously believe these constantly generated thoughts are ourselves, strongly reacting to them.",
      typography_56_0_0:
        "Let's move on to the next activity. Great job so far.",
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "생각을 살펴보기 위해서는 먼저 마음이 어떻게 작동하는지 알아야 해요.",
      typography_1_0_0: "여러분은 어떻게 생각하고 있나요?",
      typography_2_0_0: '"생각은 그냥 하면 되는 거지. 무슨 뚱딴지같은 소리야."',
      typography_3_0_0:
        "아마 여러분은 스스로 아주 자유롭게 원하는 바를 생각하고 있다고 믿고 있을 텐데요.",
      typography_4_0_0: "잠시 이것에 대해 살펴보죠.",
      typography_5_0_0: "생각은 자유롭지 않다",
      typography_6_0_0:
        "잠시 짚신벌레를 떠올려볼 수 있나요? 짚신벌레는 물 위를 자유롭게 헤엄치고 있어요.",
      typography_7_0_0: "과학자들은 짚신벌레에 대한 거의 모든 것을 발견했어요.",
      typography_8_0_0: "짚신벌레가 어떤 자극을 향해 헤엄치는지 잘 알고 있죠.",
      typography_9_0_0:
        "심지어 짚신벌레가 어디론가 움직이기 전에도 짚신벌레의 이동 경로를 거의 100% 정확하게 예측할 수 있습니다.",
      typography_10_0_0:
        "이렇게 보면 짚신벌레는 자신의 의지로 움직이는 자유로운 생명체는 아닌 것 같네요.",
      typography_11_0_0: "이번엔 개미를 한 번 떠올려볼까요?",
      typography_12_0_0: "개미는 짚신벌레보다는 훨씬 더 복잡한 생명체예요.",
      typography_13_0_0:
        "몸의 구조도 복잡하고, 제법 정교한 사회 체계도 있습니다. 우리는 그들에 대한 모든 것을 다 알진 못해요.",
      typography_14_0_0:
        "하지만 과학자들은 페로몬이라는 걸 발견했습니다. 우린 이를 분석해 개미가 어디로 움직일지 예측할 수 있어요.",
      typography_15_0_0:
        "심지어는 페로몬을 이용하여 그들을 어딘가로 움직이도록 조종할 수도 있죠.",
      typography_16_0_0:
        "짚신벌레에 이어 개미 또한 분명 스스로의 자유로운 생각과 의지대로 움직이는 건 아닌 것 같습니다.",
      typography_17_0_0:
        "자, 그러면 강아지는 어떨까요? 강아지는 온전히 자유롭게 행동하는 생명체인가요?",
      typography_18_0_0:
        "우리 인간은 어떨까요? 우리는 항상 우리의 의지대로 생각하는 것일까요?",
      typography_19_0_0:
        "잠시 외계인이 있다고 생각해보세요. 그들은 매우 고등한 생명체라고 가정해보죠.",
      typography_20_0_0: "그들은 우주의 비밀도 알고 있어요.",
      typography_21_0_0:
        "인간의 몸에서 일어나는 일에 대한 모든 것도 알고 있죠. 우리의 뇌가 어떻게 작동하는지도요.",
      typography_22_0_0:
        "그렇다면 외계인이 보았을 때 우리는 자유롭게 생각하는 생명체일까요?",
      typography_23_0_0:
        "아마 그렇지 않을 겁니다. 어쩌면 특정한 환경을 조성하여 우리가 특정한 생각을 하도록 조종할 수 있을 테죠.",
      typography_24_0_0: "심오한 이야기를 하자는 건 아니에요.",
      typography_25_0_0:
        "요점은 우리가 자유롭게 생각하는 존재가 아닐 수도 있다는 거예요.",

      typography_26_0_0: "마음은 생각을 만들어내는 기계다",
      typography_27_0_0:
        "우리가 자유롭게 생각하고 있지 않다는 걸 알려줄 한 가지 활동을 해볼게요.",
      typography_28_0_0: "휴대폰을 이용하여 60초 타이머를 맞춰볼까요?",
      typography_29_0_0:
        "60초 동안 잠시 눈을 감고 그 어떤 생각도 하지 않으려고 노력해보세요.",
      typography_30_0_0:
        "자, 그러면 시작해볼까요? 60초의 타이머가 끝나면 다음으로 진행해주세요.",
      typography_31_0_0:
        "60초 동안 마음속에 어떤 생각이 떠올랐나요? 적어볼까요?",
      typography_32_0_0:
        "주의 깊게 살펴보면 우리 마음에는 수없이 많은 생각들이 끊임없이 떠오른다는 것을 알 수 있었을 거예요.",
      typography_33_0_0:
        "우리가 아주 자유롭고 고상하게 특정한 형태의 생각을 만들어낸다고 생각했던 것과는 정반대의 모습이죠.",
      typography_34_0_0: "마음이 작동하는 방식은 팝콘 기계와 같아요.",
      typography_35_0_0:
        "우리 뇌는 마치 팝콘 기계처럼 생각을 끊임없이 만들어내죠. 지금도 탁! 탁! 팝콘이 튀어 오르고 있네요.",
      typography_36_0_0:
        "팝콘 기계 속에서 팝콘이 예측 불가능한 패턴으로 튀어 오르듯, 우리의 마음속에는 다양한 생각들이 무작위적으로 끊임없이 떠오릅니다.",
      typography_37_0_0:
        "어떤 팝콘을, 얼마나 만들지는 우리의 의지와 무관하답니다.",
      typography_38_0_0:
        "팝콘은 무작위적으로 튀어 오르기 때문에 우린 다음에 어떤 팝콘이 튀어 오를지 의식적으로 선택할 수 없어요.",
      typography_39_0_0:
        "마찬가지로 생각 또한 그저 우리의 지난 경험과 환경적 조건에 따라 불규칙적으로 마음속에 떠오를 뿐입니다.",
      typography_40_0_0: "생각은 '나'가 아니다",
      typography_41_0_0: "지금까지의 이야기를 정리해 볼까요?",
      typography_42_0_0:
        '"우리는 자유롭게 생각하는 존재가 아니고, 마음은 생각을 무작위로 만들어낸다."',
      typography_43_0_0:
        "그렇다면 마음속에 끊임없이 부정적인 생각들이 떠오르면 대체 어떻게 하란 말인 걸까요?",
      typography_44_0_0:
        "문제 해결은 생각이 곧 '나 자신'을 의미하는 게 아니라는 것에서부터 시작돼요.",
      typography_45_0_0:
        "앞서 60초 동안 마음속에 어떤 생각이 떠오르는지 살펴보았던 활동을 되새겨보세요.",
      typography_46_0_0:
        "분명 나는 마음속에 무작위로 떠오른 생각들을 관찰하고 있었어요.",
      typography_47_0_0:
        "그 생각들을 바라보는 '나'는 분명 그것들과 별개로 존재하고 있었답니다.",
      typography_48_0_0:
        "하지만 많은 사람들은 '생각 = 나'라고 인지하고 마음속에 떠오르는 생각들에 아주 무의식적이고 자동적으로 반응하게 됩니다.",
      typography_49_0_0:
        "강한 생각에 휩싸여 벌컥 화를 내기도 하고, 지속적으로 자신을 갉아먹는 생각에 그대로 굴복하기도 하죠.",
      typography_50_0_0:
        "이처럼 생각이 곧 나 자신이라고 믿고 마음속 떠오르는 생각에 자동적으로 반응하는 것을 두고, 우리는 '생각과 거리가 가깝다'고 표현해요.",
      typography_51_0_0:
        "우리는 앞으로 '거리두기'를 통해 생각과 나 사이에 공간을 마련하고, 부정적인 생각에 덜 영향 받을 수 있는 능력을 기를 거예요.",
      typography_52_0_0:
        "이를 위해 우선 여기서는 우리 마음이 어떻게 작동하는지 배웠어요. 정리하자면 다음과 같겠죠?",
      typography_53_0_0: "우리는 자유롭게 생각하는 것이 아니고,",
      typography_54_0_0:
        "마음은 마치 끊임없이 생각을 만들어내는 팝콘 기계와 같이 작동하며, ",
      typography_55_0_0:
        "우리는 끊임없이 만들어진 생각이 나 자신이라고 무의식적으로 믿고 그 생각에 강하게 반응하곤 한다.",
      typography_56_0_0: "그럼 다음 활동으로 넘어가볼게요. 고생 많으셨어요.",
    },
  },
};
