import { axiosInstance, type CustomResponseFormat } from "api/instance";
import { ConceptType } from "./conceptApi";

export const AI_CONCEPT_DOMAIN = "ai/concept";
export const AI_CONCEPT_RECOMMEND_ENDPOINT = "recommend";

export const AI_QUESTION_DOMAIN = "ai/question";
export const AI_QUESTION_RECOMMEND_ENDPOINT = "recommend";

export const AI_COMMENT_DOMAIN = "ai/comment";
export const AI_COMMENT_LIST_ENDPOINT = "list";
export const AI_COMMENT_DELETE_ENDPOINT = "delete";
export const AI_COMMENT_UPDATE_ENDPOINT = "update";

export interface InsideQType {
  location: string;
  question: string;
}

export interface CommentType {
  editorKey: string;
  commentList: { aiCommentKey: string; comment: string }[];
}

export const aiApi = {
  recommend_C: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<ConceptType>>(
      `${AI_CONCEPT_DOMAIN}/${AI_CONCEPT_RECOMMEND_ENDPOINT}`,
      { params: { pid: pid } }
    ),
  recommend_Q: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<InsideQType[]>>(
      `${AI_QUESTION_DOMAIN}/${AI_QUESTION_RECOMMEND_ENDPOINT}`,
      { params: { pid: pid } }
    ),

  getCommentList: (pid: string, tk: string, trk?: string) =>
    axiosInstance.get<CustomResponseFormat<CommentType[]>>(
      `${AI_COMMENT_DOMAIN}/${AI_COMMENT_LIST_ENDPOINT}`,
      { params: { pid: pid, tk: tk, trk: trk } }
    ),

  updateComment: (
    pid: string,
    tk: string,
    data: { aiCommentKey: string; comment: string; reason: string },
    trk?: string
  ) =>
    axiosInstance.post<void>(
      `${AI_COMMENT_DOMAIN}/${AI_COMMENT_UPDATE_ENDPOINT}`,
      data,
      { params: { pid: pid, tk: tk, trk: trk } }
    ),
  deleteComment: (
    pid: string,
    tk: string,
    data: { aiCommentKey: string; reason?: string },
    trk?: string
  ) =>
    axiosInstance.post<void>(
      `${AI_COMMENT_DOMAIN}/${AI_COMMENT_DELETE_ENDPOINT}`,
      data,
      { params: { pid: pid, tk: tk, trk: trk } }
    ),
};
