export const translation_5_3 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "이제 분리하기를 연습해봅시다.",
      typography_1_0_0:
        "디스턴싱에 아주 익숙해지면 분리하기 과정이 머리속에서 일어날 수 있습니다.",
      typography_2_0_0: "마치 수학 계산을 암산하듯이요.",
      typography_3_0_0:
        "하지만 처음부터 암산을 하긴 쉽지 않듯이, 우리는 적어가며 연습해 나가는 과정을 먼저 거쳐야 합니다.",
      typography_4_0_0: "차근차근 하나씩 배워나가 보아요.",
      typography_5_0_0: "각각의 요소",
      typography_6_0_0: "분리하기를 할 때의 각각의 요소는 총 다섯 가지입니다.",
      typography_7_0_0: "상황, 생각, 감정, 감각, 행동/충동입니다.",
      typography_8_0_0:
        "각 요소를 분리할 때 주의할 점들이 있는데요. 하나씩 살펴보도록 하죠.",
      typography_9_0_0: "상황",
      typography_10_0_0: "1. 객관적인 상황만을 적어야 합니다.",
      typography_11_0_0: "주관적인 생각이나 감정은 적지 않아야 합니다.",
      typography_12_0_0: "벌어진 일을 사실 그대로 적을 수 있도록 노력해보세요.",
      typography_13_0_0: "2. 내적 사건도 상황이 될 수 있습니다.",
      typography_14_0_0:
        '"동료가 내 인사에 답을 하지 않고 지나감"과 같은 외적 사건만이 상황이 되는 것은 아닙니다.',
      typography_15_0_0:
        '"자다가 눈을 떴는데, 어제 회사 미팅 장면이 떠오름"과 같은 마음속에서 벌어진 내적 사건도 상황이 될 수 있습니다.',
      typography_16_0_0: "3. 순간을 적어야 합니다.",
      typography_17_0_0:
        "우선 익숙해지기 전까지는 사건의 맥락은 포함시키지 않아야 합니다.",
      typography_18_0_0:
        "과거에 어땠고 저쨌고 등은 적지 않아야 한다는 뜻입니다.",
      typography_19_0_0:
        "맥락이 섞여 들어가면 상황은 모호해지고 주관적이게 될 확률이 높습니다.",
      typography_20_0_0:
        "중요한 건 나의 부정적 반응 자아낸 기폭제가 무엇이냐는 겁니다.",
      typography_21_0_0:
        "그 결정적인 순간을 잘 파악해 적을 수 있도록 노력해보세요.",
      typography_22_0_0: "생각",
      typography_23_0_0: "1. 날것 그대로의 독백 형태로 적습니다.",
      typography_24_0_0:
        "그러면 자연스럽게 나의 해석, 판단, 평가가 들어가게 될 것입니다.",
      typography_25_0_0: "생각은 주관적인 것이니 잘 적힌 것이죠.",
      typography_26_0_0: "2. 핵심 생각만을 두세 문장 정도로 적습니다.",
      typography_27_0_0:
        "많은 사람들이 생각을 적으며 자신의 생각을 하염없이 털어놓곤 합니다.",
      typography_28_0_0: "이는 잘못된 방식입니다.",
      typography_29_0_0:
        "분리하기는 '감정의 배출'이나 '생각 곱씹기'가 아닙니다.",
      typography_30_0_0:
        "상황에 적은 그 찰나의 순간에, 나의 감정에 가장 큰 영향을 미친 생각을 한두 문장 추려서 적을 수 있어야 합니다.",
      typography_31_0_0: "감정",
      typography_32_0_0: "말 그대로 감정을 적습니다.",
      typography_33_0_0:
        "혹시 내가 느끼는 감정이 무엇인지 잘 모르겠다면, 일단은 인터넷에 '감정 단어'를 검색해서 참고해도 좋습니다.",
      typography_34_0_0: "감각",
      typography_35_0_0: "몸에서 느껴지는 감각을 적으면 됩니다.",
      typography_36_0_0:
        "가슴이 답답하거나, 머리가 지끈거리거나, 근육에 힘이 들어가는 등이 그 예시입니다.",
      typography_37_0_0: "행동/충동",
      typography_38_0_0:
        '그래서 "이렇게" 행동했다(또는 행동하고 싶었다)를 적으면 됩니다.',
      typography_39_0_0: "어렵지 않을 겁니다.",
      typography_40_0_0: "연습해보기",
      typography_41_0_0: "자, 분리하기에 대한 이론 공부는 모두 끝났습니다.",
      typography_42_0_0:
        "이제 실습을 해볼텐데요. 첫 시작은 나의 경험이 아닌 남의 경험을 갖고 해보도록 하지요.",
      typography_43_0_0:
        "누군가의 마음에 다음과 같은 내적 경험이 꾸러미로 떠올랐다고 해봅시다.",
      typography_44_0_0:
        '"진짜 짜증난다. 쟤는 왜 내가 부탁하는 일마다 모두 다 거절하는 것 같지? 한두 번도 아니고. 내가 만만한가? 아니면 내가 지난번에 실수를 해서 그런가. 아무리 그래도 그렇지. 저렇게까지 거절할 일인가. 아, 머리 아프다. 가슴이 답답하다. 그냥 제안 자체를 하지 말까? 시위하듯 입 다물고 있어볼까?"',
      typography_45_0_0: "감정과 판단을 배제한 순간의 트리거를 적어주세요.",
      typography_46_0_0:
        "그 순간 가장 크게 영향을 미친 핵심 생각을 두세 문장으로 적어주세요.",
      typography_47_0_0: "당시 느꼈던 감정을 적어주세요.",
      typography_48_0_0: "당시 몸에서 느꼈던 감각을 적어주세요.",
      typography_49_0_0: "이후에 이어진 행동이나 충동을 적어주세요.",
      typography_50_0_0: "여기까지가 분리하기입니다. 어떠셨나요? 괜찮았나요?",
      typography_51_0_0:
        "잘했는지 궁금하면 코치 선생님에게 확인해달라고 해봐도 좋을 것 같네요.",
      typography_52_0_0:
        "그리고 오늘부터 스스로 분리하기를 연습할 수 있도록 <분리 노트>를 제공해 드릴테니 홈 화면에서 확인해보세요.",
    },
  },
};
