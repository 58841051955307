import { useNavigate } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/joy";
import { sendMessage } from "utils/webview";

export default function StepPayPaymentFail() {
  let navigate = useNavigate();

  return (
    <Stack
      direction="column"
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100%" }}
    >
      <Typography level="h3">결제에 실패했습니다</Typography>
      <Button
        onClick={() => {
          navigate("/");
          sendMessage(
            JSON.stringify({
              event: "close_steppay",
            })
          );
        }}
      >
        돌아가기
      </Button>
    </Stack>
  );
}
