import { Box, Stack } from "@mui/joy";
import useGetDeviceInfo from "hooks/useGetDeviceInfo";
import useIsInappWebview from "hooks/useIsInappWebview";
import useIsLatest from "hooks/useIsLatest";
import { forwardRef } from "react";
import { useRecoilValue } from "recoil";
import { layoutAtom } from "recoil/layoutAtom";
import { extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";

interface FloatingAreaProps {
  children: React.ReactNode;
}
const FloatingArea = forwardRef<HTMLDivElement, FloatingAreaProps>(
  ({ children }, ref) => {
    const isInappWebview = useIsInappWebview();
    const { isLeftSideBarOpen, taskWidth } = useRecoilValue(layoutAtom);
    const { homeIndicatorHeight } = useGetDeviceInfo();
    const isLatest = useIsLatest();
    const backgroundColor = extractHexColor(
      theme.vars.palette.background.level1
    );

    return (
      <Box
        ref={ref}
        sx={{
          position: "fixed",
          zIndex: 10,
          bottom: 0,
          ...(isLatest
            ? {
                left: isInappWebview ? 0 : "var(--Sidebar-width)",
                width: isInappWebview
                  ? "100%"
                  : `calc(100% - var(--Sidebar-width, 0))`,
              }
            : {
                left: isLeftSideBarOpen ? "var(--Sidebar-width)" : 0,
                width: isLeftSideBarOpen
                  ? `calc(100% - var(--Sidebar-width, 0))`
                  : "100%",
              }),
          pointerEvents: "none",
        }}
      >
        <Box
          sx={{
            zIndex: 10,
            left: 0,
            width: "100%",
            height: "50px",
            background: `linear-gradient(180deg, ${backgroundColor}00 0%, ${backgroundColor} 100%)`,
          }}
        />
        <Box
          sx={{
            backgroundColor: "white",
            boxShadow: `0px -1px 0px rgba(0, 0, 0, 0.1)`,
            px: "20px",
            pt: "20px",
            pb: `${20}px`,
            pointerEvents: "auto",
          }}
        >
          <Stack
            className={` w-full ${
              taskWidth === "normal" && "max-w-[720px] mx-auto"
            }`}
            alignItems="center"
          >
            {children}
          </Stack>
        </Box>
      </Box>
    );
  }
);

export default FloatingArea;
