import Typography from "@mui/joy/Typography";
import { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/joy";
import {
  ConceptKeyType,
  CoreBeliefKeyType,
  CoreBeliefType,
  AutomaticThoughtKeyType,
  AutomaticThoughtType,
  ConceptNoteType,
  NoteType,
  ConceptType,
  ConceptItemStatus,
  IndividualThoughtKeyType,
  IndividualThoughtNoteType,
  IndividualThoughtType,
  CoreExperienceReferenceListType,
  CoreBeliefReferenceListType,
  IntermediateBeliefReferenceListType,
  IntermediateBeliefNoteType,
  CoreBeliefNoteType,
} from "api/conceptApi";
import * as _ from "lodash";
import InputWithAiRecommendation from "./InputWithAiRecommendation";
import DeleteAutomaticThoughtButton from "components/conceptNote/DeleteAutomaticThoughtButton";
import ConceptNoteConfirmButton from "components/conceptNote/ConceptNoteConfirmButton";
import {
  convertNoteToReal,
  DataId1Type,
  DataId2Type,
  Id2Type,
  Id3Type,
  IdType,
  NoteDataId1Type,
  NoteDataId2Type,
} from "./InsideC";
import { rainbowGradient } from "components/task/header/progressbar/TaskProgressBar";
import {
  isNegativeAutomaticThought,
  isPositiveAutomaticThought,
} from "logic/logics";
import { valueDomainList } from "api/dashboardApi";

const getIdNoteFromData = ({
  data,
  id1,
  id2,
  index,
  id3,
}: {
  data: ConceptNoteType;
  id1: IdType;
  id2?: Id2Type;
  index?: number;
  id3?: Id3Type;
}) => {
  const referenceData = data || {};
  const note =
    id2 && referenceData[id1]
      ? index !== undefined
        ? !id3
          ? ((referenceData[id1] as NoteDataId1Type)[id2] as NoteType[])[index]
          : ((referenceData[id1] as NoteDataId1Type)[id2] as NoteDataId2Type[])[
              index
            ][id3]
        : (referenceData[id1] as NoteDataId1Type)[id2]
      : referenceData[id1];
  return note as NoteType | string;
};

export const getIdValueFromData = ({
  data,
  id1,
  id2,
  index,
  id3,
}: {
  data: ConceptType;
  id1: IdType;
  id2?: Id2Type;
  index?: number;
  id3?: Id3Type;
}) => {
  const referenceData = data || {};
  const note =
    id2 && referenceData[id1]
      ? index !== undefined
        ? !id3
          ? ((referenceData[id1] as DataId1Type)[id2] as string[])[index]
          : ((referenceData[id1] as DataId1Type)[id2] as DataId2Type[])[index]
          ? ((referenceData[id1] as DataId1Type)[id2] as DataId2Type[])[index][
              id3
            ]
          : ""
        : (referenceData[id1] as DataId1Type)[id2]
      : referenceData[id1];
  return note as string;
};

export default function ConceptListItem({
  noteData,
  data,
  setNoteData,
  setData,
  id,
  title,
  format,
  isRequireModificationReason,
  status,
  readOnly,
}: {
  noteData: ConceptNoteType;
  data: ConceptType;
  setNoteData: React.Dispatch<
    React.SetStateAction<ConceptNoteType | undefined>
  >;
  setData: React.Dispatch<React.SetStateAction<ConceptType | undefined>>;
  id: ConceptKeyType;
  title: string;
  format?: { id: Id2Type; label: string }[];
  isRequireModificationReason?: boolean;
  status?: ConceptItemStatus;
  readOnly?: boolean;
}) {
  const currentNoteData = getIdNoteFromData({ data: noteData, id1: id });

  const currentValue = getIdValueFromData({ data: data, id1: id });

  const blankThoughtTrapList = Array.from({ length: 2 }, (v, i) => ({
    value: "",
    isHumanCheckRequired: false,
  }));
  const blankThoughtList = Array.from({ length: 1 }, (v, i) => ({
    thought: { value: null, isHumanCheckRequired: false },
    thoughtTrap: { value: null, isHumanCheckRequired: false },
  }));

  const [canBeConfirmed, setCanBeConfirmed] = useState<boolean>(false);

  useEffect(() => {
    const savedData = convertNoteToReal(noteData);
    const canBeConfirmed =
      _.isEqual(data[id], savedData[id]) &&
      (isNegativeAutomaticThought(id)
        ? ["situation", "thoughtList", "behavior"].every((each) => {
            const value = ((data[id] || {}) as AutomaticThoughtType)[
              each as AutomaticThoughtKeyType | "thoughtList"
            ];

            return typeof value === "string"
              ? value?.length > 0
              : value?.some(
                  (element) =>
                    element &&
                    element.thought.length > 0 &&
                    element.thoughtTrap.length > 0
                );
          }) &&
          ["emotion", "physicalResponse"].every((each) => {
            return (
              (
                ((data[id] || {}) as AutomaticThoughtType)[
                  each as AutomaticThoughtKeyType
                ] || ""
              ).length > 0
            );
          })
        : isPositiveAutomaticThought(id)
        ? ["situation", "thought", "valueDomain", "emotion", "behavior"].every(
            (each) =>
              (
                ((data[id] as AutomaticThoughtType) || {})[
                  each as AutomaticThoughtKeyType
                ] || ""
              ).length > 0
          ) // : id === "copingStrategy"
        : // ? ["surrender", "overcompensation", "avoidance"].some(
        //     (each) =>
        //       (
        //         (data[id] as CopingStrategyType)[
        //           each as CopingStrategyKeyType
        //         ] || ""
        //       ).length > 0
        //   )
        id === "coreBelief"
        ? ["coreBelief1", "coreBelief2", "coreBelief3"].some(
            (each) =>
              (
                ((data[id] as CoreBeliefType) || {})[
                  each as CoreBeliefKeyType
                ] || ""
              ).length > 0
          )
        : id === "intermediateBelief"
        ? Object.values(data[id] || {}).some(
            (element) => element?.trim().length > 0
          )
        : id === "coreExperience"
        ? (data[id] || "").length > 0
        : false);

    setCanBeConfirmed(canBeConfirmed);
  }, [noteData, data]);

  const thoughtTrapOptionList = [
    "책임 과다",
    "근거 부족",
    "부정 편향",
    "평가",
    "개념화",
    "반추",
    "잘 모르겠어요",
  ];

  const copingStrategyOptionList = [
    "굴복",
    "과잉보상",
    "회피",
    "잘 모르겠어요",
  ];

  const valueList = [...valueDomainList, "잘 모르겠어요"];

  return (
    <Stack spacing={1}>
      <Stack direction={"row"} justifyContent="space-between">
        <Stack direction={"row"} spacing={1}>
          <Typography level="body-xs">{title}</Typography>
          {(status === "note_confirmed" || status === "confirmed") && (
            <Stack
              justifyContent={"center"}
              sx={{ color: "primary.solid", width: "16px", height: "16px" }}
            >
              <span
                style={{ fontSize: "16px" }}
                className="material-symbols-rounded"
              >
                check
              </span>
            </Stack>
          )}
          {id.startsWith("automaticThought") &&
            ((data[id] || {}) as AutomaticThoughtType).isConfirmAvailable && (
              <Typography
                level="body-xs"
                sx={{
                  whiteSpace: "nowrap",
                  background: rainbowGradient,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  color: "transparent",
                  display: "inline",
                }}
              >
                {"확정 가능"}
              </Typography>
            )}
        </Stack>
        <Stack direction={"row"} spacing={1}>
          {id.startsWith("automaticThought") && status !== "confirmed" && (
            <DeleteAutomaticThoughtButton automaticThoughtKey={id} />
          )}
          {status === "preparing" && (
            <ConceptNoteConfirmButton
              conceptNoteKey={id}
              label={
                title.includes("자동적 사고")
                  ? "자동적 사고"
                  : title.includes("가치")
                  ? "가치"
                  : title
              }
              disabled={!canBeConfirmed}
            />
          )}
        </Stack>
      </Stack>

      {!format ? (
        <InputWithAiRecommendation
          type="textarea"
          initialValue={
            typeof currentNoteData === "string"
              ? currentNoteData
              : (currentNoteData as NoteType)?.value
          }
          // value={currentValue}
          isHumanCheckRequired={
            (currentNoteData as NoteType)?.isHumanCheckRequired
          }
          setData={setData}
          setNoteData={setNoteData}
          id1={id}
          isRequireModificationReason={isRequireModificationReason}
          showReferenceList={["coreExperience"].includes(id)}
          referenceList={
            noteData[`${id}ReferenceList` as CoreExperienceReferenceListType]
          }
          readOnly={readOnly}
        />
      ) : (
        <Stack spacing={1}>
          {format.map(({ id: id2, label }) => {
            const currentNoteData = getIdNoteFromData({
              data: noteData,
              id1: id,
              id2: id2,
            });
            const isHumanCheckRequired = (currentNoteData as NoteType)
              ?.isHumanCheckRequired;
            const thoughtList = ((noteData[id] || {}) as NoteDataId1Type)[
              id2
            ] as IndividualThoughtNoteType[];
            return (
              <Grid
                key={`${id}_${id2}`}
                container
                spacing={2}
                sx={{
                  p: 0,
                  width: "100%",
                  "--Grid-rowSpacing": 0,
                }}
              >
                <Grid xs={2.5} sx={{ pt: "10px" }}>
                  <Typography
                    sx={{
                      ...((label === "가치 영역" || label === "가치") && {
                        color: "transparent",
                      }),
                    }}
                  >
                    {label}
                  </Typography>
                </Grid>

                <Grid xs={9.5}>
                  {id2 === "copingStrategy" ? (
                    <InputWithAiRecommendation
                      type="select"
                      initialValue={
                        (
                          getIdNoteFromData({
                            data: noteData || {},
                            id1: id,
                            id2,
                          }) as NoteType
                        )?.value
                      }
                      isHumanCheckRequired={isHumanCheckRequired}
                      setData={setData}
                      setNoteData={setNoteData}
                      id1={id}
                      id2={id2}
                      options={copingStrategyOptionList}
                      isRequireModificationReason={isRequireModificationReason}
                    />
                  ) : id2 === "thoughtList" ? (
                    <Stack spacing={1}>
                      {(thoughtList || []).map(
                        (individualThought, thoughtIndex) =>
                          (
                            Object.keys(
                              individualThought
                            ) as IndividualThoughtKeyType[]
                          ).map((each) => (
                            <InputWithAiRecommendation
                              key={`${id}_${id2}_thought${thoughtIndex}_${each}`}
                              type={
                                each === "thoughtTrap" ? "select" : "textarea"
                              }
                              thoughtList={
                                thoughtList?.map(
                                  (each: IndividualThoughtNoteType) =>
                                    Object.fromEntries(
                                      Object.entries(each).map(
                                        ([key3, nestedNote2]) => [
                                          key3,
                                          nestedNote2.value,
                                        ]
                                      )
                                    )
                                ) as IndividualThoughtType[]
                              }
                              initialValue={
                                (individualThought[each] as NoteType).value
                              }
                              isHumanCheckRequired={
                                (individualThought[each] as NoteType)
                                  .isHumanCheckRequired
                              }
                              setData={setData}
                              setNoteData={setNoteData}
                              id1={id}
                              id2={id2}
                              index={thoughtIndex}
                              id3={each}
                              {...(each === "thoughtTrap" && {
                                options: thoughtTrapOptionList,
                              })}
                              isRequireModificationReason={
                                isRequireModificationReason
                              }
                            />
                          ))
                      )}
                    </Stack>
                  ) : id2 === "valueDomain" ? (
                    <InputWithAiRecommendation
                      type="select"
                      initialValue={
                        (
                          getIdNoteFromData({
                            data: noteData || {},
                            id1: id,
                            id2,
                          }) as NoteType
                        )?.value
                      }
                      isHumanCheckRequired={isHumanCheckRequired}
                      setData={setData}
                      setNoteData={setNoteData}
                      id1={id}
                      id2={id2}
                      options={valueList}
                      isRequireModificationReason={isRequireModificationReason}
                    />
                  ) : (
                    <InputWithAiRecommendation
                      type="textarea"
                      initialValue={
                        (
                          getIdNoteFromData({
                            data: noteData || {},
                            id1: id,
                            id2,
                          }) as NoteType
                        )?.value
                      }
                      isHumanCheckRequired={isHumanCheckRequired}
                      setData={setData}
                      setNoteData={setNoteData}
                      id1={id}
                      id2={id2}
                      isRequireModificationReason={isRequireModificationReason}
                      showReferenceList={[
                        "coreBelief",
                        "intermediateBelief",
                      ].includes(id)}
                      {...(["coreBelief", "intermediateBelief"].includes(
                        id
                      ) && {
                        referenceList: (
                          noteData[id] as CoreBeliefNoteType &
                            IntermediateBeliefNoteType
                        )[
                          `${id2}ReferenceList` as
                            | CoreBeliefReferenceListType
                            | IntermediateBeliefReferenceListType
                        ],
                      })}
                    />
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
}
