import {
  Chip,
  Radio,
  RadioGroup,
  Sheet,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { surveyApi } from "api/surveyApi";
import CustomCard from "components/common/CustomCard";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import ButtonWithModal from "components/common/ButtonWithModal";

export default function Survey() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sat = searchParams.get("sat");
  const type = searchParams.get("type");
  const showContinue = type === "1";

  const [showSubmitToast, setShowSubmitToast] = useState(false);
  useEffect(() => {
    if (showSubmitToast) {
      setTimeout(() => {
        setShowSubmitToast(false);
      }, 2000);
    }
  }, [showSubmitToast]);

  const surveyData = [
    {
      id: "feelingWhenTermination",
      type: "select",
      question:
        "만약 지금 서비스가 종료되어 내일부터 이용할 수 없게 된다면 어떤 기분이 들 것 같나요?",
      options: [
        "매우 아쉬울 것 같아요",
        "조금 아쉬울 것 같아요",
        "전혀 아쉽지 않을 것 같아요",
        "지금은 사용하고 있지 않아 해당 사항이 없어요",
      ],
    },
    {
      id: "satisfiedPart",
      type: "input",
      question:
        "이번 주 경험에서 특별히 도움이 되었던 부분이 있나요? 있다면 어떤 부분이 도움이 되었나요?",
    },
    {
      id: "unsatisfiedPart",
      type: "input",
      question:
        "이번주 경험에서 다소 아쉬웠던 부분이 있었나요? 있다면 어떤 부분이 아쉬웠을까요?",
    },
    ...(showContinue
      ? [
          {
            id: "isContinue",
            type: "select",
            question: "다음주도 프로그램을 진행하고 싶으신가요?",
            options: ["네, 진행할게요.", "아니요. 이제 그만 하겠습니다."],
          },
        ]
      : []),
  ];

  type keyType =
    | "feelingWhenTermination"
    | "satisfiedPart"
    | "unsatisfiedPart"
    | "isContinue";
  interface SurveyAnswerType {
    feelingWhenTermination?: string;
    satisfiedPart?: string;
    unsatisfiedPart?: string;
    isContinue?: string;
  }

  const initialAnswer = {
    feelingWhenTermination: undefined,
    satisfiedPart: undefined,
    unsatisfiedPart: undefined,
    ...(showContinue && { isContinue: undefined }),
  };
  const [answer, setAnswer] = useState<SurveyAnswerType>(initialAnswer);

  const submitQueryFn = useCallback(
    () =>
      surveyApi.submit({
        ...Object.fromEntries(
          Object.entries(answer).filter(([key, value]) => !!value)
        ),
        ...(showContinue && {
          isContinue: answer.isContinue === "네, 진행할게요." || false,
        }),
        sat: sat || "",
      }),
    [answer]
  );

  const { mutate: submit } = useMutation(submitQueryFn, {
    onSuccess: () => {
      setShowSubmitToast(true);
    },
  });

  function isAllFieldFilled(answer: SurveyAnswerType) {
    return Object.values(answer).every(
      (element) => element && element.trim().length > 0
    );
  }

  const closeBrowser = () => {
    var _ua = window.navigator.userAgent;
    const isIOS = /iPad|iPhone|iPod/.test(_ua);

    if (_ua.toLocaleLowerCase().indexOf("kakaotalk") > -1) {
      window.location.href = isIOS
        ? "kakaoweb://closeBrowser"
        : "kakaotalk://inappbrowser/close";
    } else {
      navigate("/");
      // window.open("about:blank", "_self")?.open()?.close();
    }
  };
  return (
    <Sheet
      className={"w-full h-full grow flex flex-col overflow-auto "}
      sx={{ backgroundColor: "background.level1", px: "28px" }}
    >
      <div className="w-full sticky top-0 z-[100]">
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            px: "4px",
            py: "16px",
            borderBottom: 1,
            backgroundColor: "background.level1",
            borderColor: "background.level3",
          }}
        >
          <Typography level="title-lg">{"만족도 조사"}</Typography>

          <Stack
            direction="row"
            alignItems={"center"}
            spacing={1.5}
            sx={{ py: 0 }}
          >
            <Typography
              level="body-xs"
              className={`${
                showSubmitToast ? "opacity-1" : "opacity-0"
              } transition-all`}
            >
              제출되었습니다
            </Typography>

            <ButtonWithModal
              disabled={!isAllFieldFilled(answer)}
              startDecorator={undefined}
              buttonText="제출하기"
              title={"제출되었습니다"}
              subtitle={`소중한 의견 감사합니다. 한 분 한 분 모든 의견에 귀 기울이며 더 좋은 프로그램을 만들어 나갈 수 있도록 노력할게요.`}
              actionText={"확인"}
              onClick={() => {
                submit();
              }}
              action={() => {
                closeBrowser();
                setAnswer(initialAnswer);
              }}
              onClose={() => {
                closeBrowser();
                setAnswer(initialAnswer);
              }}
            />
          </Stack>
        </Stack>
      </div>

      <div className="w-full h-full max-w-[720px] mx-auto">
        <Stack spacing={1} sx={{ py: 5, pb: 20 }}>
          {surveyData.map(({ id, type, question, options }) => (
            <CustomCard key={question}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Typography>{question}</Typography>
                {type === "input" ? (
                  <Textarea
                    minRows={1}
                    onChange={(e) => {
                      setAnswer((answer) => ({
                        ...answer,
                        [id as keyType]: e.target.value,
                      }));
                    }}
                  />
                ) : options ? (
                  <RadioGroup
                    name="best-movie"
                    aria-labelledby="best-movie"
                    orientation="vertical"
                    sx={{ gap: 1 }}
                  >
                    {options.map((name) => {
                      const checked = answer[id as keyType] === name;
                      return (
                        <Chip
                          key={name}
                          variant="plain"
                          color={checked ? "primary" : "neutral"}
                          sx={{
                            width: "100%",
                            whiteSpace: "pre-wrap",
                            py: 1,
                            borderRadius: "4px",
                          }}
                        >
                          <Radio
                            variant="outlined"
                            color={checked ? "primary" : "neutral"}
                            disableIcon
                            overlay
                            label={name}
                            value={name}
                            checked={checked}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setAnswer((answer) => ({
                                  ...answer,
                                  [id as keyType]: name,
                                }));
                              }
                            }}
                          />
                        </Chip>
                      );
                    })}
                  </RadioGroup>
                ) : (
                  <></>
                )}
              </Stack>
            </CustomCard>
          ))}
        </Stack>
      </div>
    </Sheet>
  );
}
