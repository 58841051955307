import { Content_6_11_A } from "data/task/v1/6-11-A";
import getConfirmedThoughtmapState from "./getConfirmedThoughtMapState";

export default async function get_6_11_A_RawData(patientId: string) {
  try {
    const confirmedThoughtmapState = await getConfirmedThoughtmapState(
      patientId
    );

    return {
      taskId: `6-11-A`,
      content: Content_6_11_A(
        confirmedThoughtmapState?.automaticThoughtForValueIdList || []
      ),
    };
  } catch (error) {
    // 에러 핸들링
    console.error("Error in get_6_11_A_RawData:", error);
    throw error; // 또는 에러에 대한 처리를 진행할 수 있음
  }
}
