export const translation_4_8_A = {
  en: {},
  ko: {
    v1: {
      "$A$ $B$": "$A$ $B$",
      typography_0_0_0: "행동 실험 만들기",
      typography_1_0_0:
        "만약 우리의 생각을 행동 실험으로 검증해 본다면 어떻게 계획해 볼 수 있을까요?",
      typography_2_0_0: "우선 나의 자동적 사고를 살펴볼까요?",
      typography_3_0_0: "우리가 검증하려는 자동적 사고는 다음과 같아요. ",
      typography_4_0_0: `이번 자동적 사고에 나타난 가설은 다음과 같이 요약할 수 있어요.  `,
      typography_5_0_0:
        "우리가 행동 실험으로 검증하려는 가설은 다시 말해 이렇게 나타낼 수 있겠죠. ",
      typography_6_0_0:
        "그렇다면 이번에 우리가 하려는 행동 실험의 제목을 정한다면 이렇게 말할 수 있겠네요. ",
      typography_7_0_0: `행동 실험 연구자가 된 우리는 "$A$"을(를) 위해 자동적인 생각과 관련된 행동을 직접 실천해 보고, 예상했던 결과가 실제로 나타나는지 검증해 보려고 해요. 한번 시작해 볼까요?`,
      typography_8_0_0: `평소에 "$A$"과(와) 관련된 상황에서 나는 어떻게 행동했나요?`,
      typography_9_0_0:
        "이게 바로 안전행동이랍니다. 안전행동은 걱정이나 불안으로부터 회피하려는 무의식적인 행동으로 우리가 하려는 행동 실험에 방해가 될 수 있어요.",
      typography_10_0_0:
        "그렇다면 이제는 원래 하던 행동과는 다른 행동으로, 우리의 가설을 검증하기 위해 어떤 행동을 할 수 있을까요?",
      typography_11_0_0: `"$A$"을(를) 실행하게 된다면 어떤 결과가 나타날지 생각해 볼까요?`,
      typography_12_0_0: `우선, "$A$"이(가) 맞다면 "$B$"을(를) 실행하였을 때 어떤 결과가 나타날까요?`,
      typography_13_0_0: `반대로 "$A$"이(가) 틀렸다면 "$B$"을(를) 실행하였을 때 어떤 결과가 나타날까요?`,
      typography_14_0_0: `좋아요. 이제 우리의 가설이 맞았는지 틀렸는지 검증하기 위하여 어떤 행동 실험을 할 수 있을지 정해보는 시간을 가져보도록 해요. `,
      typography_15_0_0: `행동 실험 단계 만들기`,
      typography_16_0_0: `우리가 설계한 "$A$"을(를) 정리해 보면 다음과 같아요. `,
      typography_17_0_0: `막상 실험을 진행하려고 보니 어떤가요? 바로 진행하기에는 마음의 준비가 필요할지도 모르겠어요. 그렇지요?`,
      typography_18_0_0: `이제 우리는 "$A$"을(를) 상대적으로 하기 쉬운 것부터 어려운 것까지 여러 단계의 작은 활동으로 단계를 나누어볼 거예요.`,
      typography_19_0_0: `예를 들어, "회의 중 사람들 앞에서 내 의견 말해보기" 행동 실험을 할 예정이라면 1단계의 행동으로 '다른 사람의 의견에 동의할 때 눈을 맞추며 고개를 끄덕이기'가 좋은 시작점이 될 수 있겠지요.  `,
      typography_20_0_0: `"$A$"의 단계를 한 번 나누어볼까요? 최소 3개까지 단계를 떠올려보세요. `,
      typography_21_0_0: `고생하셨습니다. 다음 활동에서는 "$A$"을(를) 성공적으로 실행하기 위해 단계별로 계획을 세워볼 거예요.`,
      typography_22_0_0: `코치 선생님이 살펴보고 다음 활동을 안내해드릴테니 조금만 기다려주세요!`,
    },
  },
};
