import { Stack, Typography } from "@mui/joy";
import { TestResultType } from "api/dashboardApi";
import { selfCheckData, SelfCheckIdType } from "data/selfCheckData";
import Chip from "components/common/Chip";
import LineChart from "components/common/LineChart";

function getMaxScoreById(id: SelfCheckIdType) {
  const resultRangeData = selfCheckData[id].result;
  return resultRangeData[resultRangeData.length - 1].range[1];
}

function Score({ score, maxScore }: { score?: number; maxScore: number }) {
  return (
    <Stack spacing={0}>
      <Typography sx={{ fontSize: "14px" }} fontWeight={"xl"}>
        {score !== undefined ? score : "?"}

        <Typography sx={{ fontWeight: 400, opacity: 0.5 }}>
          {` /${maxScore}`}
        </Typography>
      </Typography>
    </Stack>
  );
}

function TestResultById({
  id,
  title,
  scoreList,
}: {
  id: SelfCheckIdType;
  title: string;
  scoreList: number[];
}) {
  const latestScore =
    scoreList && scoreList.length > 0
      ? scoreList[scoreList.length - 1]
      : undefined;

  function getResultLabel(score: number) {
    const resultData = selfCheckData[id].result;
    const result = resultData.find(
      (element) => score >= element.range[0] && score <= element.range[1]
    );
    return result?.label;
  }

  return (
    <Stack
      direction="column"
      flexGrow={1}
      sx={{
        p: "20px",
        borderRadius: "12px",
        backgroundColor: "white",
      }}
    >
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Stack direction={"row"} spacing="10px" alignItems={"center"}>
            <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
              {title}
            </Typography>
            {latestScore !== undefined && (
              <Chip
                label={getResultLabel(latestScore) || ""}
                {...(latestScore > selfCheckData[id].abnormalScore && {
                  color: "red",
                })}
              ></Chip>
            )}
          </Stack>
          <Score score={latestScore} maxScore={getMaxScoreById(id)} />
        </Stack>
      </Stack>
      {scoreList.length > 0 && (
        <LineChart
          id={id}
          data={scoreList}
          maxScore={getMaxScoreById(id)}
          abnormalScore={selfCheckData[id].abnormalScore}
        />
      )}
    </Stack>
  );
}

export default function TestResultV2({
  anxiety,
  depression,
  stress,
}: TestResultType) {
  const depressionScoreList = depression?.scoreList || [];
  const anxietyScoreList = anxiety?.scoreList || [];
  const stressScoreList = stress?.scoreList || [];

  const isEmpty =
    depressionScoreList.length === 0 &&
    anxietyScoreList.length === 0 &&
    stressScoreList.length === 0;

  const data: { id: SelfCheckIdType; scoreList: number[] }[] = [
    {
      id: "depression",
      scoreList: depressionScoreList,
    },
    {
      id: "anxiety",
      scoreList: anxietyScoreList,
    },
    {
      id: "stress",
      scoreList: stressScoreList,
    },
  ];

  return (
    <Stack spacing={"16px"}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"flex-end"}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
          부정적 반응
        </Typography>
        {isEmpty && (
          <Typography
            sx={{
              fontSize: "14px",
              opacity: 0.4,
              fontWeight: 500,
            }}
          >
            2일차부터 측정 가능
          </Typography>
        )}
      </Stack>
      <Stack direction={"column"} spacing={"12px"}>
        {data.map(({ id, scoreList }, index) => {
          const title = selfCheckData[id as SelfCheckIdType].title;
          return (
            <TestResultById
              key={`testResult_${id}`}
              id={id}
              title={title}
              scoreList={scoreList}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}