import { Box, Stack, Typography } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import {
  HistoryItemType,
  ThoughtTrapChipType,
  toolApi,
  TOOL_THOUGHTRECORD_DOMAIN,
  TOOL_THOUGHTRECORD_HISTORY_ENDPOINT,
} from "api2/toolApi";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ko";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "routes/paths";
import { addAlpha } from "styles/hexOpacity";
import { FindingTrapIcon } from "./Status";

function Day({
  selected,
  onClick,
  date,
  hasDot,
  isDotFilled,
}: {
  selected: boolean;
  onClick: () => void;
  date: Dayjs;
  hasDot?: boolean;
  isDotFilled?: boolean;
}) {
  const dotColor = selected ? "white" : "#FF9B1A";
  return (
    <Stack
      sx={{
        flex: "0 0 auto",
        px: "6px",
        py: "12px",
        textAlign: "center",
        backgroundColor: selected ? addAlpha("#ffffff", 0.2) : "transparent",
        ...(selected && {
          background: `linear-gradient(151.61deg, #FCCF85 -0.28%, #FCA83C 99.81%)`,
        }),
        borderRadius: "8px",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: addAlpha("#ffffff", 0.2),
        },
      }}
      onClick={onClick}
      alignItems="center"
    >
      <Typography
        sx={{ height: "20px", fontSize: "14px", color: "black", opacity: 0.5 }}
      >
        {date.format("ddd")}
      </Typography>
      <Typography
        sx={{
          width: "40px",
          fontSize: "20px",
          color: "black",
          fontWeight: 600,
        }}
      >
        {date.format("D")}
      </Typography>
      <Box
        sx={{
          width: "5px",
          height: "5px",
          borderRadius: "50%",
          backgroundColor: hasDot && !isDotFilled ? dotColor : "transparent",
          ...(isDotFilled && { borderWidth: "1px", borderColor: dotColor }),
        }}
      />
    </Stack>
  );
}

export const NewThoughtTrapIndicator = () => {
  return (
    <Box
      sx={{
        width: "5px",
        height: "5px",
        borderRadius: "50%",
        backgroundColor: "#FF9B1A",
      }}
    />
  );
};

const ThoughtTrapChip = ({
  isNewThoughtTrapFound,
  countThoughtTrapFound,
  onClick,
}: {
  isNewThoughtTrapFound: boolean;
  countThoughtTrapFound: number;
  onClick: () => void;
}) => {
  return (
    <Box
      sx={{ pr: "1px", position: "relative", cursor: "pointer" }}
      onClick={onClick}
    >
      {isNewThoughtTrapFound && (
        <Box sx={{ position: "absolute", right: "-5px", top: 0 }}>
          <NewThoughtTrapIndicator />
        </Box>
      )}
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "14px",
          color: "#222222",
        }}
        startDecorator={<FindingTrapIcon />}
        endDecorator={
          <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
            {countThoughtTrapFound}
          </Typography>
        }
      >
        찾은 생각함정
      </Typography>
    </Box>
  );
};

export default function HorizontalCalendar({
  selectedDate,
  setSelectedDate,
  setIsFirstRecord,
}: {
  selectedDate: Dayjs;
  setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  setIsFirstRecord: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  dayjs.locale("ko");
  const today = dayjs();

  const dateWidth = 52;

  const getDates = () => {
    const startDate = today.subtract(1, "year").startOf("day");
    const endDate = today;
    const dates = [];

    let currentDate = startDate;

    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, "day")
    ) {
      dates.push(currentDate);
      currentDate = currentDate.add(1, "day");
    }

    return dates;
  };

  const [dates] = useState<Dayjs[]>(getDates());
  const [currentMonth, setCurrentMonth] = useState<string>(
    selectedDate.format("M월")
  );

  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const todayIndex = dates.findIndex((date) => date.isSame(today, "day"));
    const scrollPosition =
      todayIndex * dateWidth - window.innerWidth / 2 + dateWidth / 2; // 조정 가능한 스크롤 위치
    if (calendarRef.current) {
      calendarRef.current.scrollLeft = scrollPosition;
    }
  }, [dates]);

  const handleScroll = () => {
    if (calendarRef.current) {
      const scrollLeft = calendarRef.current.scrollLeft;
      const visibleIndex = Math.floor(scrollLeft / dateWidth);
      const visibleDate = dates[visibleIndex];
      if (visibleDate) {
        setCurrentMonth(visibleDate.format("M월"));
      }
    }
  };
  const [historyList, setHistoryList] = useState<HistoryItemType[]>();
  const [thoughtTrapData, setThoughtTrapData] = useState<ThoughtTrapChipType>();

  const getHistoryQueryFn = useCallback(() => toolApi.getHistory(), []);

  useQuery(
    [TOOL_THOUGHTRECORD_DOMAIN, TOOL_THOUGHTRECORD_HISTORY_ENDPOINT],
    getHistoryQueryFn,
    {
      onSuccess: (data) => {
        if (data.result) {
          const countByDate = data.result.countByDate;
          setThoughtTrapData({
            countThoughtTrapFound: data.result.countThoughtTrapFound,
            isNewThoughtTrapFound: data.result.isNewThoughtTrapFound,
          });
          setHistoryList(countByDate);
          if (countByDate && countByDate.length === 0) {
            setIsFirstRecord(true);
          } else {
            setIsFirstRecord(false);
          }
        }
      },
    }
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
        sx={{ px: "20px", mb: "12px" }}
      >
        <Typography
          sx={{
            fontWeight: 900,
            fontSize: "32px",
            color: "#222222",
          }}
        >
          {currentMonth}
        </Typography>
        <Box sx={{ pr: "1px", position: "relative" }}>
          <ThoughtTrapChip
            isNewThoughtTrapFound={
              thoughtTrapData?.isNewThoughtTrapFound || false
            }
            countThoughtTrapFound={thoughtTrapData?.countThoughtTrapFound || 0}
            onClick={() => {
              navigate(PATH.thoughtRecordTool_thoughtTrap);
            }}
          />
        </Box>
      </Stack>
      <Box
        ref={calendarRef}
        onScroll={handleScroll}
        sx={{
          display: "flex",
          width: "100%",
          overflowX: "scroll",
          color: "white",
          px: "20px",
        }}
        className="scrollbar-hide"
      >
        {dates.map((date) => (
          <Day
            key={date.format("YYYY-MM-DD")}
            selected={date.isSame(selectedDate, "day")}
            onClick={() => {
              setSelectedDate(date);
            }}
            date={date}
            hasDot={historyList
              ?.filter((element) => element.totalCount > 0)
              .map((each) => each.date)
              .includes(date.format("YYYY-MM-DD"))}
            isDotFilled={historyList
              ?.filter((element) => element.totalCount > element.completedCount)
              .map((each) => each.date)
              .includes(date.format("YYYY-MM-DD"))}
          />
        ))}
      </Box>
    </Box>
  );
}
