import { Stack } from "@mui/joy";
import React from "react";
import Link from "components/common/Link";
import useGetUrls from "hooks/useGetUrls";
import useGetDeviceInfo from "hooks/useGetDeviceInfo";

export default function FullPageContainer({
  children,
  hasFooter,
  backgroundColor,
}: {
  children: React.ReactNode;
  hasFooter?: boolean;
  backgroundColor?: string;
}) {
  const { homeIndicatorHeight } = useGetDeviceInfo();
  const { companyInfoUrl, privacyPolicyUrl, servicePolicyUrl } = useGetUrls();
  return (
    <Stack
      direction={"column"}
      alignItems="center"
      sx={{
        zIndex: 1000,
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "calc(var(--vh,1vh) * 100)",
        minHeight: "calc(var(--vh,1vh) * 100)",
        backgroundColor: backgroundColor || "background.level1",
        pt: "20px",
        pb: `${homeIndicatorHeight}px`,
      }}
    >
      {children}

      {hasFooter && (
        <Stack
          direction={"row"}
          spacing={1}
          sx={{ pt: 1, wordBreak: "keep-all" }}
        >
          <Link text={"사업자 정보"} url={companyInfoUrl} />
          <Link text={"개인정보 처리방침"} url={privacyPolicyUrl} />
          <Link text={"서비스 이용약관"} url={servicePolicyUrl} />
        </Stack>
      )}
    </Stack>
  );
}
