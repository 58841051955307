import { useState, useEffect } from "react";

export default function useDetectIOS() {
  const [isIOS, setIsIOS] = useState<boolean>();
  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") === -1 &&
    navigator.userAgent.indexOf("FxiOS") === -1; //webview, web browser 구분 못함

  const isIOSMobile = /(iPhone|iPod|iPad)/i.test(navigator.platform); //webview, web browser 구분 못함

  var isIOSWebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
    navigator.userAgent
  ); //webview, web browser 구분 가능

  useEffect(() => {
    if (isSafari || isIOSMobile) {
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  }, []);

  return {
    isIOS,
    isIOSMobile,
    isSafari,
    // isIOSWebview,
  };
}
