import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const BENEFIT_DOMAIN = "benefit";

export const BENEFIT_VERIFY_ENDPOINT = "verify";

export const benefitApi = {
  verify: (email: string) =>
    axiosInstance.post<CustomResponseFormat<{ isVerified: boolean }>>(
      `${BENEFIT_DOMAIN}/${BENEFIT_VERIFY_ENDPOINT}`,
      {
        email: email,
      }
    ),
};
