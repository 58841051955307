export const translation_7_4_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "가치는 내가 선택하는 삶의 방향이라는 것, 기억하시죠?",
      typography_1_0_0:
        "<생각기록지>에서 이야기 나누며 발견한 나의 가치에 관해 얘기해 볼 거예요.",
      typography_2_0_0:
        "그중에서도 특히 내가 긍정적으로 반응했던 생각에 대해서 살펴볼 거랍니다.",
      typography_3_0_0:
        "최근에 <생각기록지>에서 우리가 나눈 문답이에요. 한 번 찬찬히 읽어볼까요?",
      typography_4_0_0: "불러올 기록을 하나만 선택해주세요.",
      typography_5_0_0: "좋아요. 위 내용을 정리해볼게요.",
      typography_6_0_0: "그리고 이런 가치를 발견할 수 있었어요.",
      typography_7_0_0: "가치는 내가 선택하는 것이에요. 옳고 그름이 없지요.",
      typography_8_0_0:
        "그렇기에 남들의 눈치를 보거나 다른 사람의 기대를 충족시킬 필요가 없답니다.",
      typography_9_0_0:
        "어떤가요? 위의 가치는 내가 진정으로 선택하여 나아가고 싶은 방향이 맞나요?",
      typography_10_0_0: "어떤 부분에서 부족하다고 생각하시나요?",
      typography_11_0_0:
        "좋아요, 그럼 오늘은 여기까지 하도록 해요. 제가 확인 후 다시 말씀드릴게요.",
      typography_12_0_0:
        "좋아요. 가치를 하나 찾았네요. 조금씩 그려지고 있는 내 삶의 방향들은 <가치 나침반>에서 확인할 수 있답니다.",
      typography_13_0_0:
        "위 가치에 대해 더 말씀해주실 부분이 있을까요? 추가로 설명하고 싶거나 보완하고 싶은 부분이 있다면 공유해주세요.",
      typography_14_0_0:
        "좋아요. 그러면 오늘 이야기한 것에 맞춰 앞으로 프로그램을 진행해볼게요. 오늘도 고생 많았어요.",
    },
  },
};
