/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  type AxiosInstance,
  AxiosInterceptorManager,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
  AxiosRequestHeaders,
} from "axios";

export type CustomResponseFormat<T = any> = {
  code: number;
  debugMessage?: string;
  message?: string;
  status?: number;
  error?: string;
  data?: T;
  result?: T;
};

interface CustomInstance extends AxiosInstance {
  interceptors: {
    request: AxiosInterceptorManager<InternalAxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse<CustomResponseFormat>>;
  };
  getUri(config?: AxiosRequestConfig): string;
  request<T>(config: AxiosRequestConfig): Promise<T>;
  get<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  head<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  options<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
}
// Extend AxiosRequestConfig to include _retry
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

const onRequest = (config: CustomAxiosRequestConfig) => {
  console.info(
    "[",
    config.method?.toUpperCase(),
    config.url,
    "]",
    "\nparams: ",
    config.params
  );
  return config;
};
const HOST_API = process.env.REACT_APP_API_SERVER_HOST;
const API_KEY = process.env.REACT_APP_API_KEY;

const axiosInstance: CustomInstance = axios.create({
  withCredentials: true,
  baseURL: HOST_API,
  headers: {
    "X-Api-Key": API_KEY,
    "content-type": "application/json",
    // "X-Api-Key": API_KEY,
    // "X-Auth-Token": "",
    // "X-App-Id": "w:123",
  },
});
const onFulfilled = (response: AxiosResponse) => {
  return response.data;
};
const onRejected = async (error: AxiosError) => {
  const originalRequest = error.config as CustomAxiosRequestConfig;
  if (error.response && error.response.status === 401) {
    if (!originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const response = await axios.get(`${HOST_API}/tool/token/refresh`, {
          withCredentials: true,
        });
        const newAccessToken = response.data.result.accessToken;
        if (newAccessToken) {
          console.log("newAccessToken", newAccessToken);
          axiosInstance.defaults.headers[
            "Authorization"
          ] = `Bearer ${newAccessToken}`;
          if (originalRequest.headers) {
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${newAccessToken}`;
          } else {
            originalRequest.headers = {
              Authorization: `Bearer ${newAccessToken}`,
            } as AxiosRequestHeaders;
          }
        }

        return axiosInstance(originalRequest);
      } catch (err: any) {
        if (err.response.status === 401) {
          console.log("refresh token 없음");
          // return null;
        }

        return Promise.reject(err);
      }
    }
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(onRequest, (error: AxiosError) => {
  return Promise.reject(error);
});
axiosInstance.interceptors.response.use(onFulfilled, onRejected);

export const setAuthHeaders = (accessToken: string) => {
  if (
    axiosInstance.defaults?.headers &&
    axiosInstance.defaults.headers.common
  ) {
    // console.log("current", accessToken, appId);
    // @ts-ignore
    axiosInstance.defaults.headers["X-Auth-Token"] = accessToken;
  }
};

export { axiosInstance };
