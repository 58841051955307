import {
  Instruction,
  InstructionWithContent,
  InstructionWithTextarea,
  Header1,
} from "../../BlockComponent";
import { LastCard, MyWorryLines } from "../common";

export const Content_1_1_A = [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  Instruction("typography_2_0_0", { indentation: true }),
  Instruction("typography_3_0_0"),
  Instruction("typography_4_0_0"),
  Instruction("typography_5_0_0"),

  Header1("typography_6_0_0"),
  // Instruction("typography_7_0_0"),
  InstructionWithContent("typography_8_0_0_1", MyWorryLines()),
  Instruction("typography_8_0_0_2"),
  Instruction("typography_8_0_0_3"),
  InstructionWithTextarea("typography_9_0_0"),
  InstructionWithTextarea("typography_10_0_0"),
  InstructionWithTextarea("typography_11_0_0"),

  Header1("typography_12_0_0"),
  Instruction("typography_13_0_0"),
  InstructionWithTextarea("typography_14_0_0"),
  InstructionWithTextarea("typography_15_0_0"),
  Instruction("typography_16_0_0"),

  ...LastCard,
];
