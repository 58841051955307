import { isDev } from "config";

export const translation_1_1_A = {
  en: {
    v1: {
      typography_0_0_0:
        "In this activity, we'll explore what's been troubling you and how you'd like to change it.",
      typography_0_0_0_1:
        "In this activity, we'll explore what's been troubling you and how you'd like to change it.",
      typography_0_0_0_2:
        "I'll ask some simple questions for you to think about and answer.",
      typography_0_0_0_3:
        "Once I check your answers, I'll ask follow-up questions like this, but with indents.",
      typography_1_0_0:
        "Remember, everything shared in Distancing is confidential and only visible to your coach, so you can share without worries.",
      typography_14_0_0:
        "If it's hard to type on a phone, you can use a computer and go to https://distancing.im to write.",
      typography_2_0_0: "My concern",
      typography_3_0_0:
        "Let's start by jotting down what's been bothering you lately. This might be why you joined Distancing.",
      typography_4_0_0: "Please write it down in one sentence, like this:",
      typography_4_3_0: "When I feel down, I tend to overeat.",
      typography_5_0_0:
        "What's the main concern that's been troubling you recently? We'll delve into it more in the next activities, so just a brief note for now.",
      typography_6_0_0:
        "Thanks for sharing. When did these issues first start? How was it back then?",
      typography_7_0_0:
        "Have there been any changes in how these problems manifest? Are they getting worse, better, or occurring intermittently?",
      typography_8_0_0:
        "What efforts have you made to address these issues? Have you sought or are currently receiving professional help?",
      typography_9_0_0:
        "What does your daily routine look like? From waking up in the morning to going to bed at night, how do you spend your day? Feel free to describe weekdays and weekends separately if they differ.",
      typography_10_0_0: "My expectation",
      typography_11_0_0:
        "So, what made you decide to start Distancing 'at this moment'? What changes do you hope to see through the program?",
      typography_12_0_0:
        "Lastly, as we'll be addressing these issues one by one, do you have any concerns or sensitive areas we should be aware of?",
      typography_13_0_0:
        "Great. We'll use your input to guide the program. Let's move on to the next activity.",
    },

    v2: {
      typography_0_0_0:
        "In this activity, we'll explore what's been troubling you and how you'd like to change it.",
      typography_1_0_0:
        "I'll ask some simple questions for you to think about and answer.",
      typography_2_0_0:
        "Once I check your answers, I'll ask follow-up questions like this, but with indents.",
      typography_3_0_0:
        "We’ll map out your concerns and goals, and then set up the program to match.",
      typography_4_0_0:
        "Remember, everything shared in Distancing is confidential and only visible to your coach, so you can share without worries.",
      typography_5_0_0:
        "If it's hard to type on a phone, you can use a computer and go to https://distancing.im to write.",
      typography_6_0_0: "My concerns",
      typography_7_0_0:
        "Let's start by jotting down what's been bothering you lately. This might be why you joined Distancing.",
      typography_8_0_0:
        "What’s been really weighing on you these days? We’ll get into the nitty-gritty later, so a quick note will do for now.",
      typography_9_0_0:
        "When or in what situations do these concerns usually pop up?",
      typography_10_0_0:
        "What goes through your mind when you find yourself in these situations?",
      typography_11_0_0:
        "How do these thoughts specifically make day-to-day life tough?",
      typography_12_0_0: "My goals",
      typography_13_0_0:
        "Next, let’s chat about what you want to get out of Distancing.",
      typography_14_0_0:
        "What’s the main thing you’re hoping to tackle or dial down with Distancing?",
      typography_15_0_0:
        "Let’s say we hit our goals with Distancing. What’s your everyday life looking like then?",
      typography_16_0_0:
        "Cool. Based on what you’ve told me, I’ll steer the program in the right direction.",
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "이번 활동에서는 나의 고민거리가 무엇인지, 그리고 나는 이것을 어떻게 변화시키고 싶은지 살펴볼게요.",
      typography_0_0_0_1:
        "지금부터 제가 몇 가지 질문을 드릴 테니, 하나씩 살펴보시고 답변해 주시면 돼요.",
      typography_0_0_0_2:
        "그럼 제가 확인한 뒤에 이렇게 들여쓰기가 된 형태로 추가 질문을 남겨 드릴 거예요.",
      typography_0_0_0_3:
        "혹 질문이 너무 불편하거나, 천천히 진행되었으면 좋겠다고 여겨지면 언제든지 편하게 말씀해주세요.",
      typography_1_0_0:
        "디스턴싱에서 오가는 모든 이야기는 담당 코치 외에는 확인할 수 없으며, 절대 외부로 유출되지 않으니 걱정 않으셔도 돼요.",
      typography_14_0_0:
        "모바일 기기에서의 타이핑이 불편하다면 컴퓨터로 https://distancing.im 으로 접속해서 작성해주세요.",
      typography_2_0_0: "나의 고민",
      typography_3_0_0:
        "먼저 요즈음 나를 괴롭히는 고민에 대해 적어볼 거예요. 아마 디스턴싱을 시작한 이유가 되겠죠.",
      typography_4_0_0: "아래와 같이 간결하게 한 문장으로 적어주세요. ",
      typography_4_3_0: "우울해지면 자꾸 과식을 하게 돼요",
      typography_5_0_0:
        "요즈음 어떤 고민이 스스로를 가장 괴롭히나요? 이어지는 활동에서 더 자세히 살펴볼 거니 우선 간단하게만 적어주세요.",
      typography_6_0_0:
        "말씀해주셔서 감사해요. 위의 문제들이 처음 발생했던 때는 언제인가요? 그때는 어떠했나요?",
      typography_7_0_0:
        "그 뒤 문제의 양상에 변화가 있었을까요? 심해지고 있나요, 나아지고 있나요, 아니면 간헐적으로 발생하고 있나요?",
      typography_8_0_0:
        "문제를 다루기 위해 어떠한 노력을 해보았을까요? 전문가의 도움을 받은 적이 있거나 지금도 받고 계실까요?",
      typography_9_0_0:
        "나의 하루 일과는 어떤가요? 아침에 일어나서 밤에 잠들 때까지 어떻게 하루를 보내세요? 평일과 주말이 다르다면 각각 적어주셔도 좋아요.",
      typography_10_0_0: "나의 기대",
      typography_11_0_0:
        "그렇다면 '지금 이 시점에' 디스턴싱을 시작하기로 결심한 이유가 있을까요? 프로그램을 통해 어떠한 변화를 기대하나요?",
      typography_12_0_0:
        "마지막으로 앞으로 위와 같은 문제들을 하나씩 다뤄볼 텐데요. 혹시 염려되거나 조심스러운 부분도 있을까요?",
      typography_13_0_0:
        "좋아요. 적어주신 내용을 참고해서 프로그램을 진행하도록 할게요. 다음 활동으로 넘어가 봐요.",
    },

    v2: {
      typography_0_0_0:
        "이번 활동에서는 나의 고민거리가 무엇인지, 그리고 나는 이것을 어떻게 변화시키고 싶은지 살펴볼게요.",
      typography_1_0_0:
        "지금부터 제가 몇 가지 질문을 드릴 테니, 하나씩 살펴보시고 답변해 주시면 돼요.",
      typography_2_0_0:
        "그럼 제가 확인한 뒤에 이렇게 들여쓰기가 된 형태로 추가 질문을 남겨 드릴 거예요.",
      typography_3_0_0:
        "함께 고민과 목표를 정리한 뒤, 그에 맞게 프로그램을 구성할 거랍니다.",
      typography_4_0_0:
        "디스턴싱에서 오가는 모든 이야기는 담당 코치 외에는 확인할 수 없으며, 절대 외부로 유출되지 않으니 걱정 않으셔도 돼요.",
      typography_5_0_0:
        "모바일 기기에서의 타이핑이 불편하다면 컴퓨터로 https://distancing.im 으로 접속해서 작성해주세요.",
      typography_6_0_0: "나의 고민",
      typography_7_0_0:
        "먼저 요즈음 나를 괴롭히는 고민에 대해 적어볼 거예요. 아마 디스턴싱을 시작한 이유가 되겠죠.",
      typography_8_0_0:
        "요즈음 어떤 고민이 스스로를 가장 괴롭히나요? 이어지는 질문에서 더 자세히 살펴볼 거니 우선 간단하게만 적어주세요.",
      typography_8_0_0_1: "앞서 고민으로 이렇게 말씀주셨어요.",
      typography_8_0_0_2:
        "<생각기록지>를 몇 번 적어보셨다면 지금쯤 내가 어떤 상황에서 어떤 생각을 하며 어떻게 괴로운지 조금씩 알아가고 계실 텐데요.",
      typography_8_0_0_3:
        "6개 정도 질문을 드릴테니 한번 스스로를 정리하며 적어보세요.",
      typography_9_0_0:
        "말씀주신 고민은 주로 어떠한 상황이나 맥락에서 발생하나요?",
      typography_10_0_0:
        "이런 상황이나 맥락에 놓이게 되면 주로 어떤 생각을 하게 되나요?",
      typography_11_0_0:
        "그로 인해 나는 일상에서 어떤 어려움을 구체적으로 겪고 있나요?",
      typography_12_0_0: "나의 목표",
      typography_13_0_0:
        "이번엔 디스턴싱을 통해 이루고 싶은 목표에 대해 이야기해볼게요.",
      typography_14_0_0:
        "디스턴싱을 통해 해결하거나 줄이고 싶은 건 무엇일까요?",
      typography_15_0_0:
        "디스턴싱을 통해 우리의 목표를 이루었다 가정해봐요. 그때의 나는 구체적으로 어떤 일상을 보내고 있을까요?",
      typography_16_0_0:
        "좋아요. 적어주신 내용을 참고해서 프로그램을 진행하도록 할게요.",
    },

    // v3: {
    //   typography_0_0_0:
    //     "최근 나의 고민거리가 무엇인지, 그리고 나는 이것을 어떻게 변화시키고 싶은지 살펴볼게요.",
    //   typography_1_0_0:
    //     "지금부터 몇 가지 질문을 드릴 테니, 하나씩 살펴보시고 답변해 주시면 돼요.",
    //   typography_2_0_0:
    //     "함께 고민과 목표를 정리한 뒤, 그에 맞게 프로그램을 구성할 거랍니다.",
    //   typography_3_0_0:
    //     "디스턴싱에서 오가는 모든 이야기는 절대 외부로 유출되지 않으니 걱정하지 않으셔도 돼요.",
    //   typography_4_0_0: "나의 고민",
    //   typography_5_0_0:
    //     "먼저 요즈음 나를 괴롭히는 고민에 대해 적어볼 거예요. 아마 디스턴싱을 시작한 이유가 되겠죠.",
    //   typography_6_0_0:
    //     "요즈음 어떤 고민이 스스로를 가장 괴롭히나요? 이어지는 활동에서 더 자세히 살펴볼 거니 우선 간단하게만 적어주세요.",
    //   typography_7_0_0:
    //     "말씀주신 고민은 주로 어떠한 상황이나 맥락에서 발생하나요?",
    //   typography_8_0_0:
    //     "이런 상황이나 맥락에 놓이게 되면 주로 어떤 생각을 하게 되나요?",
    //   typography_9_0_0:
    //     "그로 인해 나는 일상에서 어떤 어려움을 구체적으로 겪고 있나요?",
    //   typography_10_0_0: "나의 목표",
    //   typography_11_0_0:
    //     "이번엔 디스턴싱을 통해 이루고 싶은 목표에 대해 이야기해볼게요.",
    //   typography_12_0_0:
    //     "디스턴싱을 통해 해결하거나 줄이고 싶은 건 무엇일까요?",
    //   typography_13_0_0:
    //     "디스턴싱을 통해 우리의 목표를 이루었다 가정해볼까요? 그때 나는 구체적으로 어떤 일상을 보내고 있을까요?",
    //   typography_14_0_0:
    //     "좋아요. 적어주신 내용을 참고해서 이제 코치 선생님이 프로그램을 구성해 주실 텐데요.",
    //   typography_15_0_0:
    //     "코치 선생님은 고민을 확인하신 뒤에 이렇게 들여쓰기가 된 형태로 추가 질문을 남겨드릴 거예요.",
    //   typography_16_0_0:
    //     "그리고 앞으로 내가 생각과 거리를 둘 수 있도록 생각을 관찰, 계획, 분석하는 과정을 도와줄거랍니다.",
    //   typography_17_0_0:
    //     "고민을 구체화 하는 질문과 맞춤 프로그램은 모두 체험 기간동안 무료로 받아보실 수 있어요.",
    //   typography_18_0_0:
    //     "7일 이내에 언제든 해지가 가능하니 내 고민을 다루는 답변을 먼저 받아보시겠어요?",
    //   typography_19_0_0:
    //     "코치 선생님의 답변을 받으려면 알림을 꼭 허용해 주세요.",
    // },
  },
};
