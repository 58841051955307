import { useCallback, useEffect, useState } from "react";
import { generateThoughtRecordKey } from "logic/logics";
import { useMutation } from "@tanstack/react-query";
import * as _ from "lodash";
import { thoughtRecordApi } from "api/thoughtRecordApi";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import useGetBlankThoughtRecordData from "./useGetBlankThoughtRecordData";
import { ProgramType } from "data/programData";

export default function useSaveThoughtRecordWithRawContent({
  percentageThought,
  date,
  situation,
  situation_indirect,
  situation_direct,
}: {
  percentageThought?: number;
  date?: string;
  situation?: string;
  situation_indirect?: string;
  situation_direct?: string;
}) {
  const patientState = useRecoilValue(patientAtom);

  const [thoughtRecordKey] = useState<string>(
    generateThoughtRecordKey(patientState?.patientId || "")
  );

  const blankData: ProgramType = useGetBlankThoughtRecordData({
    trk: thoughtRecordKey,
    isFromMeditationRecord: true,
  }) as ProgramType;

  const [data, setData] = useState<ProgramType>(blankData);

  useEffect(() => {
    if (blankData && blankData.content) {
      const data_temp = _.cloneDeep(blankData);
      const sentimentBlockIndex = blankData.content.findIndex((element) =>
        element.lines
          .flat()
          .find((element) => element.content.id === "sentiment")
      );

      if (
        sentimentBlockIndex > -1 &&
        data_temp.content[sentimentBlockIndex] &&
        data_temp.content[sentimentBlockIndex].lines
      ) {
        const lineIndex = blankData.content[
          sentimentBlockIndex
        ].lines.findIndex((element) =>
          element.flat().some((element) => element.content.id === "sentiment")
        );
        const cellIndex = 0;
        if (
          data_temp.content[sentimentBlockIndex].lines[lineIndex] &&
          data_temp.content[sentimentBlockIndex].lines[lineIndex][cellIndex]
        ) {
          const originalContent =
            data_temp.content[sentimentBlockIndex].lines[lineIndex][cellIndex]
              .content;
          data_temp.content[sentimentBlockIndex].lines[lineIndex][
            cellIndex
          ].content = {
            ...originalContent,
            ...{ selectedIndex: 0, value: "나쁜 일이에요" },
          };
        }
      }

      [
        { id: "percentageThought", value: percentageThought },
        { id: "date", value: date },
        { id: "situation", value: situation },
        { id: "situation_indirect", value: situation_indirect },
        { id: "situation_direct", value: situation_direct },
      ].forEach(({ id, value }) => {
        const blockIndex = blankData.content.findIndex((element) =>
          element.lines.flat().find((element) => element.content.id === id)
        );
        if (
          blockIndex > -1 &&
          data_temp.content[blockIndex] &&
          data_temp.content[blockIndex].lines
        ) {
          const lineIndex = blankData.content[blockIndex].lines.findIndex(
            (element) =>
              element.flat().some((element) => element.content.id === id)
          );
          const cellIndex = 0;
          if (
            data_temp.content[blockIndex].lines[lineIndex] &&
            data_temp.content[blockIndex].lines[lineIndex][cellIndex]
          ) {
            const currentContent =
              data_temp.content[blockIndex].lines[lineIndex][cellIndex].content;
            data_temp.content[blockIndex].lines[lineIndex][cellIndex].content =
              {
                ...currentContent,
                ...{
                  ...(id === "percentageThought"
                    ? { percentage: percentageThought }
                    : { value: value as string }),
                },
              };
            for (let i = 0; i <= blockIndex; i++) {
              data_temp.content[i].isShown = true;
            }
          }
        }
      });

      setData(data_temp);
    }
  }, [date, situation, situation_indirect, situation_direct]);

  const saveThoughtRecordQueryFn = useCallback(
    () =>
      thoughtRecordApi.saveData(
        `${thoughtRecordKey}`,
        `${patientState?.patientId}`,
        data
      ),
    [data]
  );

  const { mutate: saveThoughtRecordData } = useMutation(
    saveThoughtRecordQueryFn,
    {
      onSuccess: () => {},
    }
  );

  const save = () => {
    if (
      (situation || "").length > 0 ||
      ((situation_indirect || "").length > 0 &&
        (situation_direct || "").length > 0)
    ) {
      saveThoughtRecordData();
    }
  };

  return save;
}
