import { Button } from "@mui/joy";
import dayjs from "dayjs";
import { generateActivityRecordKey } from "logic/logics";
import * as _ from "lodash";
import { useRecoilValue } from "recoil";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { layoutAtom } from "recoil/layoutAtom";
import { patientAtom } from "recoil/patientAtom";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { ActivityRecordType } from "api/activityRecordApi";

export default function AddNewActivityRecordButton({
  setCurrentActivityRecord,
  disabled,
}: {
  setCurrentActivityRecord: React.Dispatch<
    React.SetStateAction<ActivityRecordType | undefined>
  >;
  disabled: boolean;
}) {
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  const { isTaskContainerSmall } = useRecoilValue(layoutAtom);

  const patientState = useRecoilValue(patientAtom);

  const debouncedOnClick = debounce(() => {
    setCurrentActivityRecord({
      activityRecordKey: generateActivityRecordKey(
        patientState?.patientId || ""
      ),
      date: dayjs().format("YYYY-MM-DD"),
    });
  }, 500); // 1000ms = 1초

  return (
    <Button
      fullWidth
      onClick={debouncedOnClick}
      variant="soft"
      sx={{
        minWidth: 0,
        paddingInline: 0,
        ...(isTaskContainerSmall && { "--Button-gap": 0 }),
      }}
      disabled={disabled}
      startDecorator={<AddRoundedIcon />}
    >
      {!isTaskContainerSmall && t_ui("추가하기")}
    </Button>
  );
}
