import { IconButton } from "@mui/joy";
import useGoBack from "hooks/useGoBack";

export default function CloseButton() {
  const goBack = useGoBack();
  return (
    <IconButton
      onClick={goBack}
      variant="plain"
      color="neutral"
      size="sm"
      sx={{
        "&:hover": {
          backgroundColor: "transparent",
          opacity: 0.5,
        },
      }}
    >
      <svg
        width="52"
        height="44"
        viewBox="0 0 52 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33 15L19 29"
          stroke={"#2E2B2B"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 15L33 29"
          stroke={"#2E2B2B"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconButton>
  );
}
