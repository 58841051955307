export const successActivityResource = {
  success_typography_0_0_0: "활동을 할 때엔 어떻게 느끼고 생각했나요?",
  success_typography_1_0_0: "활동을 하고 난 후에는 어떻게 느끼고 생각했나요?",
  success_typography_2_0_0:
    "기분은 어땠나요? (0점은 최고 나쁨, 10은 최고로 좋음)",
  success_typography_3_0_0: "어려운 점은 없었나요?",
  success_typography_4_0_0: "잘 해낸 스스로에게 어떤 말을 해주고 싶나요?",
};

export const translation_104_2_A = {
  en: {},
  ko: {
    v1: {
      네: "네",
      아니요: "아니요",
      "$A$ $B$": "$A$ $B$",
      typography_0_0_0: "$A$(을)를 실행한 뒤 다음으로 넘어가 주세요.",
      typography_1_0_0: "앞서 우리는 이렇게 계획했어요.",
      typography_2_0_0: "계획대로 잘 되었나요?",
      typography_3_0_0: "계획한 것을 잘 해내셨군요. 축하해요.",
      typography_4_0_0: "해낸 활동을 잠시 돌아볼게요.",
      ...successActivityResource,
      typography_10_0_0:
        "좋아요. 잊지 마세요. 작은 효능감들이 모여서 큰 변화를 만들어낸답니다.",
      typography_11_0_0:
        "이 변화에서 시작해 삶을 완전히 새로운 방향으로 돌린 사람들이 많다는 걸 꼭 기억하세요.",
      typography_12_0_0: "자, 이제 다음 활동으로 나아가봐요.",
      typography_13_0_0: "계획한 대로 해내는 데에 문제가 있었나 보네요.",
      typography_14_0_0:
        "괜찮아요. 중요한 건 이 실패로부터 나의 문제에 대해 더 잘 이해하는 것이랍니다.",
      typography_15_0_0:
        "나의 무기력 패턴을 이해하고 그로부터 벗어나는 데에 아주 큰 도움이 될 거예요.",
      typography_16_0_0: "어떤 이유로 활동을 진행할 수 없었나요?",
      typography_17_0_0: "그 이유에 대해 조금만 더 자세히 설명해 주시겠어요?",
      typography_18_0_0: "좋아요. 코치 선생님이 잠깐 확인해 주실 거예요.",
      typography_19_0_0: "금방 확인해 주실 테니 조금만 기다려주세요.",
      typography_20_0_0: "고생 많으셨어요.",
    },
  },
};
