import { Button } from "@mui/joy";
import useOpenTask from "hooks/useOpenTask";
import { useRecoilState } from "recoil";
import { patientAtom } from "recoil/patientAtom";

export default function OpenChatButton() {
  const openChat = useOpenTask(`chat`);
  const [patientState, setPatientState] = useRecoilState(patientAtom);
  return (
    <Button
      color="secondary"
      variant={patientState?.isTaskUpdateRequired ? "solid" : "soft"}
      onClick={() => {
        openChat();

        if (patientState) {
          setPatientState({ ...patientState, unreadMessageCount: 0 });
        }
      }}
      // startDecorator={<span className="material-symbols-outlined">chat</span>}
      startDecorator={
        <svg
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.97461 9C5.97461 6.79086 7.76547 5 9.97461 5H21.9746C24.1837 5 25.9746 6.79086 25.9746 9V23.9196C25.9746 24.7581 25.0047 25.2242 24.3499 24.7004L22.0703 22.8767C21.361 22.3093 20.4798 22.0002 19.5715 22.0002H9.97461C7.76547 22.0002 5.97461 20.2093 5.97461 18.0002V9ZM9.97461 15.5002C9.97461 14.6718 10.6462 14.0002 11.4746 14.0002H15.4746C16.303 14.0002 16.9746 14.6718 16.9746 15.5002C16.9746 16.3286 16.303 17.0002 15.4746 17.0002H11.4746C10.6462 17.0002 9.97461 16.3286 9.97461 15.5002ZM11.4746 9.00018C10.6462 9.00018 9.97461 9.67176 9.97461 10.5002C9.97461 11.3286 10.6462 12.0002 11.4746 12.0002H20.4746C21.303 12.0002 21.9746 11.3286 21.9746 10.5002C21.9746 9.67176 21.303 9.00018 20.4746 9.00018H11.4746Z"
            fill={"currentColor"}
          />
        </svg>
      }
    >
      코칭
    </Button>
  );
}
