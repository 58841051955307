import { useState, useEffect } from "react";
import useGetEventFromApp from "./useGetEventFromApp";

export default function useGetCertainEventFromApp({
  eventName,
}: {
  eventName: string;
}) {
  const [parsedMessage, setParsedMessage] = useState<any>();
  const parsedMessageFromApp = useGetEventFromApp();

  useEffect(() => {
    if (parsedMessageFromApp?.event === eventName) {
      setParsedMessage(parsedMessageFromApp);
    } else if (
      Array.isArray(parsedMessageFromApp) &&
      parsedMessageFromApp.find((element) => element.event === eventName)
    ) {
      setParsedMessage(
        parsedMessageFromApp.find((element) => element.event === eventName)
      );
    }
  }, [parsedMessageFromApp]);

  return parsedMessage;
}
