import { useCallback, useEffect, useState } from "react";
import TaskHeader from "components/task/header/TaskHeader";
import TaskContent from "components/task/content/TaskContent";
import { Box, Stack } from "@mui/joy";
import { ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";
import SegmentationNoteList from "./SegmentationNoteList";
import { AI_COMMENT_DOMAIN, AI_COMMENT_LIST_ENDPOINT } from "api/aiApi";
import { hasAiRecommendationBlock, isAllUserFieldFilled } from "logic/logics";
import useInterval from "hooks/useInterval";
import { useRecoilState, useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { userAtom } from "recoil/userAtom";
import { currentTaskSectionIndexAtom } from "recoil/currentTaskSectionIndexAtom";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import useGetCertainEventFromApp from "hooks/useGetCertainEventFromApp";
import { useNavigate, useSearchParams } from "react-router-dom";
import useIsInappWebview from "hooks/useIsInappWebview";
import {
  SEGMENTATIONNOTE_DOMAIN,
  SEGMENTATIONNOTE_LIST_ENDPOINT,
  segmentationNoteApi,
  SegmentationNoteType,
} from "api/segmentationNoteApi";
import useOpenNewSegmentationNote from "hooks/useOpenNewSegmentationNote";
import useGetSegmentationNoteData from "hooks/useGetSegmentationNoteData";

export default function SegmentationNote({ taskKey }: { taskKey: string }) {
  const [searchParams] = useSearchParams();
  const currentSegmentationNoteKey = searchParams.get("snk");

  const patientState = useRecoilValue(patientAtom);
  const user = useRecoilValue(userAtom);
  const isCoach = user?.accessToken && user?.role !== "patient";

  // const { isTaskContainerSmall } = useRecoilValue(layoutAtom);
  const isInAppWebview = useIsInappWebview();
  const isTaskContainerSmall = isInAppWebview;
  const [data, setData] = useState<ProgramContentType[] | undefined>();
  const [prevData, setPrevData] = useState<ProgramContentType[] | undefined>();

  const [segmentationNoteList, setSegmentationNoteList] = useState<
    SegmentationNoteType[] | undefined
  >();

  const navigate = useNavigate();
  const currentTabSectionIndex = useRecoilValue(currentTaskSectionIndexAtom);
  const [taskSectionState, setTaskSectionState] =
    useRecoilState(taskSectionAtom);

  useEffect(() => {
    if (segmentationNoteList) {
      if (currentSegmentationNoteKey) {
        setCurrentSegmentationNote(
          segmentationNoteList.find(
            (element) =>
              element.segmentationNoteKey === currentSegmentationNoteKey
          ) || {
            segmentationNoteKey: currentSegmentationNoteKey,
          }
        );

        navigate(`${window.location.pathname}`, { replace: true });
      }
    }
  }, [segmentationNoteList, currentSegmentationNoteKey]);

  const [currentSegmentationNote, setCurrentSegmentationNote] = useState<
    SegmentationNoteType | undefined
  >();
  const [isEmpty, setIsEmpty] = useState<boolean | undefined>(undefined);

  const [hasAiCommentTrkList, setHasAiCommentTrkList] = useState<string[]>([]);

  const {
    data: fetchedData,
    refetch,
    isFetching,
  } = useGetSegmentationNoteData({
    snk: currentSegmentationNote?.segmentationNoteKey,
    isFirst: isEmpty,
  });

  useInterval(
    () => {
      setPrevData(data);
      //변화 없으면 다시 가져와
      if (data && prevData && _.isEqual(data, prevData)) {
        refetch();
      }
    },
    10000,
    []
  );

  const refetchActivityEvent = useGetCertainEventFromApp({
    eventName: "refetch_activity",
  });

  useEffect(() => {
    if (refetchActivityEvent) {
      refetch();
    }
  }, [refetchActivityEvent]);

  //기록 추가한 직후
  useEffect(() => {
    if (
      currentSegmentationNote &&
      !segmentationNoteList?.find(
        (segmentationNote) =>
          segmentationNote.segmentationNoteKey ===
          currentSegmentationNote?.segmentationNoteKey
      )
    ) {
      setSegmentationNoteList((list) => [
        ...(list || []),
        { ...currentSegmentationNote, shouldBeDone: false },
      ]);
    }
  }, [currentSegmentationNote]);

  const getSegmentationNoteListqueryFn = useCallback(
    () => segmentationNoteApi.getList(`${patientState?.patientId}`),
    [patientState]
  );

  const openNewSegmentationNote = useOpenNewSegmentationNote();

  useQuery(
    [SEGMENTATIONNOTE_DOMAIN, SEGMENTATIONNOTE_LIST_ENDPOINT],
    getSegmentationNoteListqueryFn,
    {
      enabled: !!patientState?.patientId,
      onSuccess: (data) => {
        const list = data.result?.map((each) => ({
          ...each,
          shouldBeDone: each?.isDone
            ? false
            : segmentationNoteList?.find(
                (element) =>
                  element.segmentationNoteKey === each.segmentationNoteKey
              )?.shouldBeDone,
        }));
        if (
          currentSegmentationNote &&
          !list?.find(
            (segmentationNote) =>
              segmentationNote.segmentationNoteKey ===
              currentSegmentationNote?.segmentationNoteKey
          )
        ) {
          list?.push({ ...currentSegmentationNote, shouldBeDone: false });
        }
        setSegmentationNoteList(list);

        if (!list || list.length === 0) {
          setIsEmpty(true);
          if (!currentSegmentationNote) {
            openNewSegmentationNote();
          }
        } else {
          list.sort((a, b) => {
            return +new Date(b.date || "") - +new Date(a.date || "");
          });
          setIsEmpty(false);
          setHasAiCommentTrkList(
            list
              .filter((each) => each.hasAiRecommendation)
              .map((each) => each.segmentationNoteKey)
          );
          let currentIndex = 0;
          if (!currentSegmentationNote) {
            const incompleteDataIndex = isCoach
              ? list.findIndex((element) => element.isHighlight)
              : list.findIndex((element) => !element.isDone);
            currentIndex = Math.max(incompleteDataIndex, 0);
          } else {
            currentIndex = list.findIndex(
              (element) =>
                element.segmentationNoteKey ===
                currentSegmentationNote.segmentationNoteKey
            );
          }
          setCurrentSegmentationNote(list[currentIndex]);
        }
      },
    }
  );

  useEffect(() => {
    if (
      data &&
      data.length > 0 &&
      currentSegmentationNote?.segmentationNoteKey
    ) {
      const segmentationNoteKey = currentSegmentationNote?.segmentationNoteKey;
      if (hasAiRecommendationBlock(data)) {
        if (!hasAiCommentTrkList.includes(segmentationNoteKey)) {
          setHasAiCommentTrkList((hasAiCommentTrkList) =>
            hasAiCommentTrkList.concat([segmentationNoteKey])
          );
        }
      } else {
        setHasAiCommentTrkList((hasAiCommentTrkList) =>
          hasAiCommentTrkList.filter(
            (element) => element !== segmentationNoteKey
          )
        );
      }
    }
    if (currentSegmentationNote) {
      if (
        currentSegmentationNote.lastDoneAt &&
        !currentSegmentationNote.isDone &&
        isAllUserFieldFilled(data, false)
      ) {
        setSegmentationNoteList((noteList) => {
          if (noteList) {
            const data_temp = _.cloneDeep(noteList);
            data_temp.splice(
              data_temp.findIndex(
                (element) =>
                  element.segmentationNoteKey ===
                  currentSegmentationNote?.segmentationNoteKey
              ),
              1,
              { ...currentSegmentationNote, shouldBeDone: true }
            );

            return data_temp;
          }
          return noteList;
        });
      } else {
        setSegmentationNoteList((noteList) => {
          if (noteList) {
            const data_temp = _.cloneDeep(noteList);
            data_temp.splice(
              data_temp.findIndex(
                (element) =>
                  element.segmentationNoteKey ===
                  currentSegmentationNote?.segmentationNoteKey
              ),
              1,
              { ...currentSegmentationNote, shouldBeDone: false }
            );

            return data_temp;
          }
          return noteList;
        });
      }
    }
  }, [data]);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (fetchedData) {
      if (!_.isEqual(fetchedData.content, data)) {
        setData(fetchedData.content);
        queryClient.invalidateQueries([
          AI_COMMENT_DOMAIN,
          AI_COMMENT_LIST_ENDPOINT,
          currentSegmentationNote?.segmentationNoteKey,
        ]);
      }
    }
  }, [fetchedData]);

  const [moveToIndex, setMoveToIndex] = useState<number | undefined>(undefined);

  return (
    <>
      <Box
        sx={{
          flex: "none",
          zIndex: 100,
          // px: { xs: "16px", sm: "24px" },
        }}
      >
        <TaskHeader
          taskKey={taskKey}
          segmentationNoteKey={currentSegmentationNote?.segmentationNoteKey}
          title={`분리 노트`}
          contentData={data}
          setContentData={setData}
          setMoveToIndex={setMoveToIndex}
          isDone={currentSegmentationNote?.isDone}
          lastDoneAt={currentSegmentationNote?.lastDoneAt}
          {...(isTaskContainerSmall && {
            extraContent: (
              <SegmentationNoteList
                segmentationNoteList={segmentationNoteList}
                currentSegmentationNote={currentSegmentationNote}
                setCurrentSegmentationNote={setCurrentSegmentationNote}
              />
            ),
          })}
        />
      </Box>
      <Stack sx={{ flex: 1, overflow: "hidden" }} direction={"row"}>
        {!isTaskContainerSmall && (
          <SegmentationNoteList
            segmentationNoteList={segmentationNoteList}
            currentSegmentationNote={currentSegmentationNote}
            setCurrentSegmentationNote={setCurrentSegmentationNote}
          />
        )}

        {currentSegmentationNote?.segmentationNoteKey && (
          <Stack sx={{ flex: 1, overflow: "hidden" }}>
            <TaskContent
              taskKey={taskKey}
              segmentationNoteKey={currentSegmentationNote?.segmentationNoteKey}
              data={data}
              setData={setData}
              moveToIndex={moveToIndex}
              setMoveToIndex={setMoveToIndex}
              isDone={currentSegmentationNote?.isDone}
              translationVersion={fetchedData?.translationVersion}
              isConceptFetching={isFetching}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
}