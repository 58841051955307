import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const SUBSCRIPTION_DOMAIN = "subscription";
export const SUBSCRIPTION_ORDER_DETAIL_ENDPOINT = "activate";

export const subscriptionApi = {
  activate: () =>
    axiosInstance.get<CustomResponseFormat>(
      `${SUBSCRIPTION_DOMAIN}/${SUBSCRIPTION_ORDER_DETAIL_ENDPOINT}`
    ),
};
