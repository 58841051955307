import { translation_meditation } from "./meditation";
export const translation_8_6_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "자, 이제 앞으로 혼자서도 명상을 훈련할 수 있도록 루틴을 만들어볼 거예요.",
      typography_1_0_0:
        "가장 좋은 건 기상 후, 일상 중, 그리고 잠들기 전, 하루 세 번, 10분 이상 연습하는 것이에요.",
      typography_2_0_0: "기상 후는 하루의 관성을 만들기에 좋은 시간대랍니다.",
      typography_3_0_0: "수면을 통해 많은 기억과 생각들이 재처리된 상태에서",
      typography_4_0_0: `하루 중 겪는 다양한 일들로 뇌가 더 활성화되기 전에`,
      typography_5_0_0: "생각과의 관계를 다시 맺어 관성을 만드는 것이지요.",
      typography_6_0_0:
        "일상 중에 하는 연습 그 관성이 이어지도록 도와줄 거예요.",
      typography_7_0_0:
        "마지막으로 잠들기 전에 하는 훈련은 하루 중 겪었던 생각들이 더 잘 정리될 수 있도록 도와줄 거랍니다.",
      typography_8_0_0: "하지만 꼭 이 루틴을 따를 필요는 없어요.",
      typography_9_0_0: "일주일에 두세 번, 하루에 5-10분 정도도 괜찮답니다.",
      typography_10_0_0:
        "중요한 건 얼마나 ‘많이’ 하느냐가 아니라 얼마나 ‘꾸준히’ 하느냐예요.",
      typography_11_0_0: "그럼 계획을 세워볼까요? 언제 명상을 연습해볼까요?",
      ...translation_meditation.ko.v2,
      typography_13_0_0:
        "5분에서 20분 사이로 원하는 명상 시간을 정해서 훈련을 진행할 수 있을 거예요.",
      typography_14_0_0: "할 수 있는 만큼 해보기",
      typography_15_0_0:
        "연습을 꾸준히 하다 보면 시작할 때 정한 시간보다 더하고 싶을 때도 있을 거예요.",
      typography_16_0_0:
        "그래서 앞으로는 정한 시간이 끝나도 원한다면 더 연습할 수 있도록 할 거예요.",
      typography_17_0_0: "내가 원하는 만큼 진행하고 마무리하면 된답니다.",
      typography_18_0_0:
        "<명상훈련지>에서는 내가 얼마나 명상을 지속할 수 있었는지도 확인할 수 있어요.",
      typography_19_0_0:
        "연습이 잘될 때에는 더 오랫동안 연습해보며 명상 집중력을 조금씩 길러보는 것도 재밌을 거예요.",
      typography_20_0_0:
        "하지만 얼마나 많이, 또는 얼마나 오래 하느냐가 중요한 건 아니랍니다.",
      typography_21_0_0: "꾸준히 연습할 수 있다면 그것으로 충분해요.",
      typography_22_0_0: "통계는 나의 여정을 요약해주는 지표로만 참고해보세요.",
      typography_23_0_0: "잘하고 못하고는 없답니다.",
      typography_24_0_0: "자, 그러면 이제 꾸준히 연습해 보는 거예요.",
    },
  },
};
