import * as _ from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { ProgramContentType } from "data/BlockComponent";
import TextareaCell from "./TextareaCell";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import {
  PresetDataType,
  PresetKeyType,
  taskApi,
  TASK_DOMAIN,
  TASK_PRESET_DETAIL_ENDPOINT,
} from "api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import SelectCell from "./SelectCell";
import { Stack } from "@mui/joy";
import SavePresetWithReasonButton from "components/ai/SavePresetWithReasonButton";
import { CONCEPT_DOMAIN, CONCEPT_NOTE_DETAIL_ENDPOINT } from "api/conceptApi";
import { currentTaskSectionIndexAtom } from "recoil/currentTaskSectionIndexAtom";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import { userAtom } from "recoil/userAtom";

export default function PresetCell({
  taskKey,
  placeholder,
  initialHtmlString,
  initialValue,
  initialSelectedIndex,
  isCoachField,
  isCoach,
  blockIndex,
  readOnly,
  setData,
  options,
  lineIndex,
  cellIndex,
  editorKey,
  presetKey,
}: {
  taskKey: string;
  placeholder?: string;
  initialHtmlString?: string;
  initialValue?: string;
  initialSelectedIndex?: number;
  isCoachField?: boolean;
  isCoach?: boolean;
  blockIndex: number;
  readOnly?: boolean;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  options: string[];
  lineIndex: number;
  cellIndex: number;
  editorKey?: string;
  presetKey?: PresetKeyType;
}) {
  const queryClient = useQueryClient();
  const patientState = useRecoilValue(patientAtom);
  const userState = useRecoilValue(userAtom);
  const isManual = isCoach && userState?.isCoachAiSupportDisabled;

  const [presetValue, setPresetValue] = useState<string | undefined>();
  const [presetSelectedIndex, setPresetSelectedIndex] = useState<number>();
  const [modificationReason, setModificationReason] = useState<string>("");
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);

  useEffect(() => {
    if (isManual) {
      setPresetValue(initialValue);
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (
      presetValue === initialValue ||
      (presetSelectedIndex !== undefined &&
        presetSelectedIndex === initialSelectedIndex)
    ) {
      setShowSaveButton(false);
    } else {
      setShowSaveButton(true);
    }
  }, [presetValue, initialValue, presetSelectedIndex, initialSelectedIndex]);

  const queryFn = useCallback(
    () => taskApi.getPresetDetail(`${taskKey}`),
    [taskKey]
  );

  const currentIndex = useRecoilValue(currentTaskSectionIndexAtom);
  const taskSectionState = useRecoilValue(taskSectionAtom);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useQuery([TASK_DOMAIN, TASK_PRESET_DETAIL_ENDPOINT, taskKey], queryFn, {
    enabled:
      !!patientState?.patientId &&
      taskSectionState &&
      taskSectionState[currentIndex].currentTaskKey === taskKey &&
      !isManual,
    onSuccess: (fetchData) => {
      if (fetchData && fetchData.result) {
        const data = fetchData.result as PresetDataType;
        let value = data[presetKey as PresetKeyType];
        if (value && presetKey === "statementSubject") {
          value = statementSubjectMapping[value as "나" | "타인" | "세상"];
        }

        const selectedIndex = options?.findIndex(
          (element) => element === value
        );
        if ((selectedIndex || -1) > -1) {
          setPresetSelectedIndex(selectedIndex);
        }
        if (value) {
          setPresetValue(value);
        }

        if (value) {
          setData((data) => {
            if (data) {
              const data_temp = _.cloneDeep(data);

              data_temp[blockIndex].lines[lineIndex][cellIndex].content = {
                ...data_temp[blockIndex].lines[lineIndex][cellIndex].content,
                htmlString: value ? `<p>${value}</p>` : undefined,
                value: value,
              };
              return data_temp;
            }
            return data;
          });
        }
        setIsLoaded(true);
      }
    },
  });

  const updatePresetQueryFn = useCallback(
    () =>
      taskApi.updatePreset(taskKey, {
        [presetKey as PresetKeyType]:
          options && initialSelectedIndex !== undefined
            ? options[initialSelectedIndex]
            : initialValue,
        reason: modificationReason,
      }),
    [
      initialValue,
      modificationReason,
      presetKey,
      taskKey,
      initialSelectedIndex,
      options,
    ]
  );

  const { mutate: updatePreset } = useMutation(updatePresetQueryFn, {
    onSuccess: () => {
      setModificationReason("");
      setShowSaveButton(false);

      if (presetKey && ["concern", "goal"].includes(presetKey)) {
        queryClient.invalidateQueries(
          [
            CONCEPT_DOMAIN,
            CONCEPT_NOTE_DETAIL_ENDPOINT,
            patientState?.patientId,
            "개념화노트",
          ],
          { exact: true }
        );
      }
    },
  });

  const statementSubjectMapping = {
    나: "나 자신",
    타인: "나의 인간관계",
    세상: "내가 사는 세상",
  };

  return !isLoaded ? (
    <></>
  ) : presetKey === "statementSubject" ? (
    <Stack spacing={0.5} alignItems="end">
      <SelectCell
        defaultValue={initialValue}
        selectedIndex={options?.findIndex(
          (element) => element === initialValue
        )}
        blockIndex={blockIndex}
        options={options}
        setData={setData}
        lineIndex={lineIndex}
        cellIndex={cellIndex}
        isCoachField
        isCoach={isCoach}
      />
      {showSaveButton && (
        <SavePresetWithReasonButton
          initialValue={
            presetSelectedIndex !== undefined
              ? Object.values(statementSubjectMapping)[presetSelectedIndex]
              : ""
          }
          value={initialValue}
          updateAction={updatePreset}
          modificationReason={modificationReason}
          setModificationReason={setModificationReason}
          isRequireModificationReason={!isManual}
        />
      )}
    </Stack>
  ) : (
    <Stack spacing={0.5} alignItems="end">
      <TextareaCell
        placeholder={placeholder}
        initialHtmlString={initialHtmlString}
        initialValue={initialValue}
        isCoachField
        isCoach={isCoach}
        setData={setData}
        lineIndex={lineIndex}
        cellIndex={cellIndex}
        blockIndex={blockIndex}
        editorKey={editorKey}
      />
      {showSaveButton && (
        <SavePresetWithReasonButton
          initialValue={presetValue}
          value={initialValue}
          updateAction={updatePreset}
          modificationReason={modificationReason}
          setModificationReason={setModificationReason}
          isRequireModificationReason={!isManual}
        />
      )}
    </Stack>
  );
}
