import { useMutation, useQueryClient } from "@tanstack/react-query";
import { taskApi } from "api";
import {
  ACTIVITYRECORD_DOMAIN,
  ACTIVITYRECORD_LIST_ENDPOINT,
  activityRecordApi,
} from "api/activityRecordApi";
import { automaticThoughtIdType } from "api/conceptApi";
import { DASHBOARD_DETAIL_ENDPOINT, DASHBOARD_DOMAIN } from "api/dashboardApi";
import { HOME_DOMAIN, HOME_MEDITATION_LIST_ENDPOINT } from "api/homeApi";
import {
  MEDITATION_RECORD_DOMAIN,
  MEDITATION_RECORD_LIST_ENDPOINT,
  meditationRecordApi,
} from "api/meditationRecordApi";
import { selfCheckApi } from "api/selfCheckApi";
import {
  THOUGHTRECORD_DOMAIN,
  THOUGHTRECORD_LIST_ENDPOINT,
  thoughtRecordApi,
} from "api/thoughtRecordApi";
import ButtonWithModal from "components/common/ButtonWithModal";
import { ProgramContentType } from "data/BlockComponent";
import { PercentageType } from "data/CellComponent";
import useRefetchTaskList from "hooks/useRefetchTaskList";
import useRemoveTab from "hooks/useRemoveTab";
import useSaveMeditationRecordWithRawContent from "hooks/useSaveMeditationRecordWithRawContent";
import useSaveThoughtRecordWithRawContent from "hooks/useSaveThoughtRecordWithRawContent";
import useSubmitImpactScore from "hooks/useSubmitImpactScore";
import {
  getReferenceData,
  getSelfCheckScore,
  getTaskIdFromTaskKey,
  isAllUserFieldFilled,
} from "logic/logics";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { layoutAtom } from "recoil/layoutAtom";
import { taskListAtom } from "recoil/taskListAtom";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import { userAtom } from "recoil/userAtom";
import { sendMessage } from "utils/webview";
import {
  SEGMENTATIONNOTE_DOMAIN,
  SEGMENTATIONNOTE_LIST_ENDPOINT,
  segmentationNoteApi,
} from "api/segmentationNoteApi";

export default function DoneButton({
  taskKey,
  thoughtRecordKey,
  activityRecordKey,
  meditationRecordKey,
  segmentationNoteKey,
  data,
  doneWithEnter,
  setDoneWithEnter,
  isDone,
  size,
}: {
  taskKey: string;
  thoughtRecordKey?: string;
  activityRecordKey?: string;
  meditationRecordKey?: string;
  segmentationNoteKey?: string;
  data?: ProgramContentType[];
  doneWithEnter?: boolean;
  setDoneWithEnter?: React.Dispatch<React.SetStateAction<boolean>>;
  isDone?: boolean;
  size?: "sm" | "lg";
}) {
  const userState = useRecoilValue(userAtom);
  const isV2Program = userState?.programVersion === "v2";

  const isFirstDone = data?.every((element) => !element.indentation);
  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });
  const isSelfCheck =
    getTaskIdFromTaskKey(`${taskKey}`) === "0-1-A" ||
    getTaskIdFromTaskKey(`${taskKey}`) === "0-2-A" ||
    getTaskIdFromTaskKey(`${taskKey}`) === "2-3" ||
    getTaskIdFromTaskKey(`${taskKey}`) === "2-4" ||
    getTaskIdFromTaskKey(`${taskKey}`) === "102-1-A" ||
    getTaskIdFromTaskKey(`${taskKey}`) === "102-2-A";

  const isThoughtRecord = taskKey?.includes("2-2-T") && thoughtRecordKey;
  const isActivityRecord = activityRecordKey !== undefined;
  const isMeditationRecord =
    taskKey?.includes("5-7-T") && meditationRecordKey !== undefined;
  const isSegmentationNote = taskKey?.includes("5-4:") && segmentationNoteKey;

  const taskListState = useRecoilValue(taskListAtom);
  const setLayoutState = useSetRecoilState(layoutAtom);
  const resetTaskSection = useResetRecoilState(taskSectionAtom);

  const taskId = getTaskIdFromTaskKey(taskKey);
  const automaticThoughtId = taskId.includes("_")
    ? (taskId.split("_")[1] as automaticThoughtIdType)
    : undefined;

  const user = useRecoilValue(userAtom);

  const disabled =
    isDone === undefined ||
    !(!isDone && isAllUserFieldFilled(data, false, user));

  const isLastDone = taskListState
    .filter((element) => element.taskKey !== taskKey)
    .every((element) => element.isDone);

  const queryClient = useQueryClient();

  const saveSelfCheckQueryFn = useCallback(
    () => selfCheckApi.save(getSelfCheckScore(data)),
    [data]
  );

  const { mutate: saveSelfCheckData } = useMutation(saveSelfCheckQueryFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        DASHBOARD_DOMAIN,
        DASHBOARD_DETAIL_ENDPOINT,
      ]);
    },
  });

  const saveNewThoughtRecordDataWithRawRecord =
    useSaveThoughtRecordWithRawContent({
      percentageThought:
        (getReferenceData(data || [], "percentageThought") as PercentageType)
          ?.percentage || 0,
      date: getReferenceData(data || [], "date")?.value || "",
      situation: getReferenceData(data || [], "situation")?.value || "",
      situation_indirect:
        getReferenceData(data || [], "situation_indirect")?.value || "",
      situation_direct:
        getReferenceData(data || [], "situation_direct")?.value || "",
    });

  const saveNewMeditationRecordDataWithRawRecord =
    useSaveMeditationRecordWithRawContent({
      type: getReferenceData(data || [], "meditation_type")?.value || "",
    });

  const submit = useSubmitImpactScore({
    taskKey: taskKey,
    data: data || [],
    automaticThoughtId: automaticThoughtId,
  });

  const isModalOpen = isLastDone && !isSegmentationNote;

  const coachMatchTaskId = user?.isBehavioralActivationProgram
    ? "100-1-A"
    : "0-0-A";

  const firstTaskId = user?.isBehavioralActivationProgram ? "100-0-A" : "0-0-A";

  const refetchTaskList = useRefetchTaskList();
  const { removeTab } = useRemoveTab({ taskKey });
  const refetchTaskListAndOpenSidebar = () => {
    refetchTaskList();
    removeTab();
  };

  const { mutate: activityDone } = useMutation(
    () => taskApi.done(`${taskKey}`),
    {
      onSuccess: () => {
        refetchTaskList();
        if (!isModalOpen) {
          removeTab();
        }

        // queryClient.invalidateQueries([HOME_DOMAIN, HOME_TASK_LIST_ENDPOINT]);
        if (getTaskIdFromTaskKey(taskKey) === "8-6-A") {
          queryClient.invalidateQueries([
            HOME_DOMAIN,
            HOME_MEDITATION_LIST_ENDPOINT,
          ]);
        }
        if (coachMatchTaskId === getTaskIdFromTaskKey(taskKey)) {
          sendMessage(
            JSON.stringify({
              event: "refetch_chat",
            })
          );
        }
      },
    }
  );

  const { mutate: thoughtRecordDone } = useMutation(
    () => thoughtRecordApi.done(`${thoughtRecordKey}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          THOUGHTRECORD_DOMAIN,
          THOUGHTRECORD_LIST_ENDPOINT,
        ]);
        refetchTaskListAndOpenSidebar();
        if (isFirstDone) {
          saveNewMeditationRecordDataWithRawRecord();
        }
      },
    }
  );

  const { mutate: segmentationNoteDone } = useMutation(
    () => segmentationNoteApi.done(`${segmentationNoteKey}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          SEGMENTATIONNOTE_DOMAIN,
          SEGMENTATIONNOTE_LIST_ENDPOINT,
        ]);
        refetchTaskListAndOpenSidebar();
        if (isFirstDone) {
          saveNewMeditationRecordDataWithRawRecord();
        }
      },
    }
  );

  const { mutate: activityRecordDone } = useMutation(
    () => activityRecordApi.done(`${activityRecordKey}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ACTIVITYRECORD_DOMAIN,
          ACTIVITYRECORD_LIST_ENDPOINT,
        ]);
        refetchTaskListAndOpenSidebar();
      },
    }
  );
  const { mutate: meditationRecordDone } = useMutation(
    () => meditationRecordApi.done(`${meditationRecordKey}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          MEDITATION_RECORD_DOMAIN,
          MEDITATION_RECORD_LIST_ENDPOINT,
        ]);
        refetchTaskListAndOpenSidebar();
        if (isFirstDone) {
          saveNewThoughtRecordDataWithRawRecord();
        }
      },
    }
  );

  const doneAction = () => {
    if (data) {
      if (isSelfCheck) {
        saveSelfCheckData();
      }
      submit();
      // confirmThoughtmapIfChange();

      if (isThoughtRecord) {
        thoughtRecordDone();
      } else if (isActivityRecord) {
        activityRecordDone();
      } else if (isMeditationRecord) {
        meditationRecordDone();
      } else if (isSegmentationNote) {
        segmentationNoteDone();
      } else {
        activityDone();
      }
    }
  };
  return (
    <ButtonWithModal
      size={size}
      startDecorator={undefined}
      disabled={disabled}
      buttonText={t_ui("마치기")}
      title={
        isV2Program
          ? t_ui("오늘 자정에 새 활동이 업데이트돼요")
          : t_ui(
              getTaskIdFromTaskKey(taskKey) === firstTaskId
                ? "첫 번째 활동을 마쳤어요"
                : "주어진 활동을 모두 마쳤어요"
            )
      }
      subtitle={
        isV2Program
          ? t_ui("그동안 일상에서 배운 디스턴싱을 실천해보세요.")
          : t_ui(
              getTaskIdFromTaskKey(taskKey) === firstTaskId
                ? "잠시 뒤 코치 선생님이 배정될 거예요."
                : `코치 선생님이 확인 후에 메시지를 드릴 거예요.`
            )
      }
      actionText={t_ui("확인")}
      onClick={doneAction}
      action={() => {
        if (getTaskIdFromTaskKey(taskKey) === coachMatchTaskId) {
          sendMessage(
            JSON.stringify({
              event: "navigate_tab",
              params: {
                tabName: "코칭",
              },
            })
          );

          resetTaskSection();
        } else {
          // setLayoutState((state) => ({
          //   ...state,
          //   isLeftSideBarOpen: true,
          // }));

          removeTab();
        }
      }}
      disableModalOpen={!isModalOpen}
      clickWithEnter={doneWithEnter}
      setClickWithEnter={setDoneWithEnter}
    />
  );
}