import { axiosInstance, type CustomResponseFormat } from "api/instance";
import { UserType } from "recoil/userAtom";

export const USER_DOMAIN = "user";

export const USER_DETAIL_ENDPOINT = "detail";
export const USER_NICKNAME_SUBMIT_ENDPOINT = "nickname/submit";
export const USER_COACH_REASSIGN_ENDPOINT = "coach/reassign";

export const userApi = {
  getDetail: () =>
    axiosInstance.get<CustomResponseFormat<UserType>>(
      `${USER_DOMAIN}/${USER_DETAIL_ENDPOINT}`
    ),

  submitNickname: (nickname: string) =>
    axiosInstance.post<void>(
      `${USER_DOMAIN}/${USER_NICKNAME_SUBMIT_ENDPOINT}`,
      { nickname: nickname }
    ),
  reassignCoach: () =>
    axiosInstance.get<CustomResponseFormat>(
      `${USER_DOMAIN}/${USER_COACH_REASSIGN_ENDPOINT}`
    ),
};
