export const translation_4_6_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "우리는 생각을 하나의 팝콘으로 여기고 나와 거리를 두는 활동을 해보았어요. ",
      typography_1_0_0:
        "생각을 다양한 관점에서 살펴보는 일도 해보고, 어떤 함정이 있는지도 알게 되었지요. ",
      typography_2_0_0:
        "누군가에게는 이렇게 생각을 살펴보는 게 충분히 도움 되었을 거예요. ",
      typography_3_0_0:
        "하지만 또 다른 누군가는 생각을 다양한 관점에서 바라보는 작업을 하면서 고개를 갸우뚱했을 수도 있어요. ",
      typography_4_0_0: `"머리론 이해가 되는데 마음으로 잘 와닿지 않는걸."`,
      typography_5_0_0: "자연스러운 반응이에요. ",
      typography_6_0_0:
        "생각은 쉽게 바뀌는 게 아니고, 또 생각에서 한 걸음 멀어지기란 쉬운 일이 아니기 때문이죠.",
      typography_7_0_0:
        "이제껏 그렇게 생각해 왔는데 간단한 활동 하나로 다양한 관점을 수용하며 생각의 영향에서 벗어난다는 것은 너무 큰 기대인 것 같기도 해요. ",
      typography_8_0_0: "그렇다면 우리는 또 무엇을 할 수 있을까요?",
      typography_9_0_0:
        "이쯤 되면 여러분은 우리가 생각을 마치 하나의 관찰 대상처럼 다루고 있다는 걸 느꼈을 거예요. ",
      typography_10_0_0:
        "말 그대로 우리는 생각을 객관적인 관찰 대상으로 여기고 있어요. ",
      typography_11_0_0: "우리가 관찰한 생각을 다시 한번 볼까요?",
      typography_12_0_0:
        "어떤 상황에서 우리의 생각이 어떤 감정을 일으키고, 어떤 행동을 하게 되는지 이해가 되었나요? ",
      typography_13_0_0: `"$A$"(이)라고 생각한 우리는 "$B$"(이)라는 감정을 느끼고, 원하지 않는 "$C$"(이)라는 행동을 하게 되었네요.`,
      typography_14_0_0: `만약 우리가 "$A$"(이)라고 생각하지 않았어도 "$C$"(와)과 같은 행동을 했을까요?`,
      typography_15_0_0: `좋아요, 우리는 생각이 어떤 행동을 하도록 영향을 준다는 점을 이해한 것 같아요.`,
      typography_16_0_0: `그런데, 반대로 행동이 생각에도 영향을 미친다는 것을 알고 있나요?`,
      typography_17_0_0: `예를 들어, '사람들은 나에게 불친절해'라는 생각을 하는 사람이 '사람들에게 툴툴거리며 까칠하게 행동'했다면, 사람들이 이 사람에게 친절할 수 있을까요?`,
      typography_18_0_0: `아마 아닐 거예요. 그럼, 그 사람은 다시 '역시 사람들은 나에게 불친절해'라는 생각을 강하게 믿게 되겠죠.`,
      typography_19_0_0: `우리는 어떤가요? "$A$"(이)라는 생각을 해서 "$B$"(이)라는 행동을 했을 때 그 결과는 나에게 어떤 영향을 미치고 있을까요?`,
      typography_20_0_0: `어렵게 느껴질 수 있어요. 하지만 전혀 어렵지 않아요.`,
      typography_21_0_0: `우리는 이제 내 생각과 행동의 관계를 알아보기 위해 간단한 행동을 계획하고 실험해 보려고 해요.`,
      typography_22_0_0: `실험을 위해서는 원래의 패턴을 더 잘 이해할 필요가 있고, 우리가 알기 원하는 것을 명확하게 정리해야 할 거예요.`,
      typography_23_0_0: `마치 과학자처럼, 현상을 객관적으로 보고 설계해야 하는 거죠. `,
      typography_24_0_0: `나의 행동과 생각의 관계를 관찰하기 위해 우리가 불편하게 생각해왔던 상황에 새로운 행동을 해보는 거예요.`,
      typography_25_0_0: `다음 활동에서 하나씩 차근차근 함께해 봐요.`,
    },
  },
};
