export const translation_4_4_5_A = {
  en: {
    v1: {
      typography_0_0_0:
        "When we look at something, we often like to group certain characteristics together to define it as one.",
      typography_1_0_0: "It's because this helps us think faster.",
      typography_2_0_0:
        "It's an efficient use of our limited cognitive capacity.",
      typography_3_0_0: "We do something similar to ourselves as well.",
      typography_4_0_0:
        "We assign ourselves specific stories, and view ourselves within those narratives.",
      typography_5_0_0:
        "This is called 'self-conceptualization'. Or simply, 'conceptualization'.",
      typography_6_0_0: "Understanding Conceptualization",
      typography_7_0_0:
        "Often, Conceptualization easily morphs into a thinking trap, making it hard to distance ourselves from these thoughts.",
      typography_8_0_0: "It falls under the category of 'unhelpful thoughts'.",
      typography_9_0_0:
        "Before we delve deeper into Conceptualization thinking trap, let's try a simple activity. Fill in the blanks below:",
      typography_10_0_0: "I am a _______ person.",
      typography_11_0_0: "I am not a _______ person.",
      typography_12_0_0: "I am good at _______.",
      typography_13_0_0: "I am bad at _______.",
      typography_14_0_0:
        "How true are these statements? How close are they to an absolute truth?",
      typography_15_0_0: "Now, think about these variations:",
      typographyFromData_16_0_0:
        "I am always/anywhere/to anyone/constantly/unfailingly a $A$ person.",
      typographyFromData_17_0_0:
        "I am always/anywhere/to anyone/constantly/unfailingly not a $A$ person.",
      typographyFromData_18_0_0:
        "I am always/anywhere/to anyone/constantly/unfailingly good at $A$.",
      typographyFromData_19_0_0:
        "I am always/anywhere/to anyone/constantly/unfailingly bad at $A$.",
      typography_20_0_0:
        "How about now? Do the statements you completed earlier still apply in the same way?",
      typography_21_0_0:
        "Probably not. Even if I say I'm kind, I'm not always kind to everyone, everywhere.",
      typography_22_0_0:
        "And even if I label myself as a depressed person, I'm not constantly, perpetually in a state of depression.",
      typography_23_0_0: "It's a natural thing. Human beings are more complex.",
      typography_24_0_0:
        'The expression "I am X" can never fully capture the whole truth.',
      typography_25_0_0:
        "Whether X is positive or negative, there are certainly times when we are not X.",
      typography_26_0_0:
        "Yet, we love to label ourselves and create narratives.",
      typography_27_0_0:
        "Creating such conceptualized stories about ourselves is precisely Conceptualization thinking trap. It's a form of assigning identity.",
      typography_28_0_0: "Problems of Conceptualization",
      typography_29_0_0:
        "Falling into Conceptualization thinking trap leads us to cling to these self-assigned identities.",
      typography_30_0_0: "And we put effort into maintaining these identities.",
      typography_31_0_0:
        "A common example is self-conceptualizing using a specific mental health diagnosis or symptom.",
      typography_32_0_0:
        '"People saying "I am a bipolar" might add, "You know how life goes for someone with bipolar disorder, right?"',
      typography_33_0_0: `Or someone saying "I have anxiety" might justify their actions with "So, it's inevitable that I act this way."`,
      typography_34_0_0:
        "However, such Conceptualization makes us selectively accept information that fits our narrative.",
      typography_35_0_0: `Even in moments of societal success or personal achievement, someone might dismiss it with "But I'm a bipolar," ignoring their sense of accomplishment or self-efficacy.`,
      typography_36_0_0:
        'Even when they have the capacity and effort to overcome anxiety, they might not surpass the barrier of "being a panic disorder patient."',
      typography_37_0_0:
        "Another example is self-conceptualizing based on past experiences.",
      typography_38_0_0:
        'Someone might say, "I grew up watching my parents fight a lot," and justify their current behavior with "So, you see why I act this way, right?"',
      typography_39_0_0:
        "While it's true that difficult experiences have troubled us,",
      typography_40_0_0:
        "and they might be causing mental health issues at this moment,",
      typography_41_0_0:
        "anyone would struggle in such situations. It's not our fault.",
      typography_42_0_0:
        "But the problem is, by self-conceptualizing, we lose the momentum for change and gradually narrow our life's repertoire.",
      typography_43_0_0:
        "Shall we see how our life's repertoire narrows when we fall into Conceptualization thinking trap?",
      typography_44_0_0: "Imagine someone who thinks:",
      typography_44_2_0: `"I've failed. Ever since I was young, I caused a lot of trouble. It was mostly due to conflicts with my parents about my career choices. They had their own hopes for me, but I didn't share those aspirations. This led to frequent arguments. Struggling emotionally, I was diagnosed with ADHD at a hospital. Since I have ADHD, it makes sense that I struggle to focus on other things. What can I do now that things have turned out this way? If I had pursued what I wanted to do when I was younger, maybe I wouldn't have developed ADHD. If only things had gone differently, I might be living a completely different life."`,
      typography_45_0_0:
        'This person says, "I have ADHD," and by boxing themselves into this label, they avoid focusing on other tasks or trying out new things.',
      typography_46_0_0:
        "Or someone who had a long-standing conflict with their parents as a child, avoiding attempts to improve that relationship.",
      typography_47_0_0:
        "Such Conceptualization suppresses change, justifying our lack of transformation.",
      typography_48_0_0:
        "If caught in these conceptualized narratives, we might never escape, falling deeper into depression and anxiety.",
      typography_49_0_0:
        "It's like our mind is justifying the current situation with appropriate reasons,",
      typography_50_0_0:
        "keeping us in a never-ending cycle. (e.g., Depressed → Because of this → Can't change → Depressed again)",
      typography_51_0_0: "Distancing from Conceptualization",
      typography_52_0_0:
        "The way out of Conceptualization thinking trap is simpler than you might think.",
      typography_53_0_0:
        "Before you get too deep into these popcorn-like thoughts,",
      typography_54_0_0: "notice that they've popped into your mind,",
      typography_55_0_0: "and take a step back to look at them objectively.",
      typography_56_0_0:
        "Understand that you're creating a story from factual and autobiographical information.",
      typography_57_0_0:
        "Equating the conceptualized story with ourselves doesn't help us.",
      typography_58_0_0:
        "But the problem is, it's hard to detach from that narrative.",
      typography_59_0_0:
        "Even unconsciously, we might not want to let go of it.",
      typography_60_0_0:
        "The moment the equation 'conceptualized story = me' is established, any attack on that story feels like an attack on ourselves.",
      typography_61_0_0: `If someone says, "No, that's just a story," it hits us hard. It feels offensive.`,
      typography_62_0_0: `Having been so depressed and anxious because of that, and now you're telling me it's just a story? Are you mocking me? Who will compensate for my life?`,
      typography_63_0_0:
        "The moment the justification for our suffering and struggle disappears, we feel a strong sense of crisis.",
      typography_64_0_0:
        "But we need to face this. The conceptualized story of 'me' is just a psychological event that has arisen in our mind.",
      typography_65_0_0:
        "Factual and autobiographical information may not change, but the stories we create from them can change at any time.",
      typography_66_0_0:
        "Imagine if Stephen Hawking had viewed himself through the lens of 'a life with ALS'. How different his path might have been.",
      typography_67_0_0:
        "When we understand and accept this, our minds can break free from rigid, dark narratives and create new changes.",
      typography_68_0_0:
        "So, shall we now identify our self-conceptualization and practice distancing ourselves from it?",
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "인간은 어떠한 대상을 바라볼 때 몇몇 특성들을 묶어 하나의 그룹으로 정의하고 싶어해요.",
      typography_1_0_0: "그래야 우리가 생각을 더 빠르게 할 수 있기 때문이에요.",
      typography_2_0_0: "제한된 인지 용량을 효율적으로 사용하는 거죠.",
      typography_3_0_0: "우리는 스스로에게도 비슷한 일을 하는데요.",
      typography_4_0_0:
        "자신에게 특정한 이야기를 부여하고, 그 틀 속에서 스스로를 바라보곤 하죠.",
      typography_5_0_0:
        "이를 '자기 개념화'라고 불러요. 줄여서 '개념화'라고 하죠.",
      typography_6_0_0: "개념화 이해하기",
      typography_7_0_0:
        "많은 경우 개념화는 생각함정으로 쉽게 변질되어 생각과 거리를 두는 일을 힘들게 해요.",
      typography_8_0_0:
        "바로 '도움이 되지 않는 생각'에 속하는 개념화라는 생각함정이죠.",
      typography_9_0_0:
        "개념화 생각함정에 대해 자세히 알아보기 전에, 먼저 한 가지 간단한 활동을 해볼게요. 아래 빈칸에 들어갈 말을 적어보세요.",
      typography_10_0_0: "나는 _______한 사람이다.",
      typography_11_0_0: "나는 _______하지 않은 사람이다.",
      typography_12_0_0: "나는 _______을(를) 잘하는 사람이다.",
      typography_13_0_0: "나는 _______을(를) 잘하지 못하는 사람이다.",
      typography_14_0_0:
        "위와 같은 문장은 얼마나 사실인가요? 이는 얼마나 절대적 진리에 가까운가요?",
      typography_15_0_0: "이번엔 다음과 같은 문장을 하나씩 생각해보죠.",
      typographyFromData_16_0_0:
        "나는 언제나/어디서나/누구에게나/항상/변함없이 $A$한 사람이다.",
      typographyFromData_17_0_0:
        "나는 언제나/어디서나/누구에게나/항상/변함없이 $A$하지 않은 사람이다.",
      typographyFromData_18_0_0:
        "나는 언제나/어디서나/누구에게나/항상/변함없이 $A$을(를) 잘하는 사람이다.",
      typographyFromData_19_0_0:
        "나는 언제나/어디서나/누구에게나/항상/변함없이 $A$을(를) 잘하지 못하는 사람이다.",
      typography_20_0_0:
        "이번엔 어떤가요? 위와 같은 문장에서도 당신이 완성한 내용이 그대로 동일하게 적용되나요?",
      typography_21_0_0:
        "아마 그럴 수는 없을 거예요. 나는 친절하다고 해도, 항상, 어디서나, 누구에게나 친절하진 않으며,",
      typography_22_0_0:
        "나는 우울한 사람이라도 늘, 언제, 어디서나, 변함없이, 항상 우울한 건 아니에요.",
      typography_23_0_0:
        "이는 당연한 이야기입니다. 인간이라는 존재는 보다 복잡하기 때문이죠.",
      typography_24_0_0:
        '"나는 X다"라는 표현은 결코 전체의 진실을 표현하지 못해요.',
      typography_25_0_0:
        "X가 긍정적이든, 부정적이든, 우리는 분명 X가 아닐 때가 있죠.",
      typography_26_0_0:
        "하지만 우리는 스스로에 대해 꼬리표를 붙이고, 이야기 붙이기를 좋아해요.",
      typography_27_0_0:
        "이와 같이 자신에 대한 개념화된 이야기를 만드는 것이 바로 개념화 생각함정입니다. 일종의 정체성을 부여하는 것이죠.",
      typography_28_0_0: "개념화가 낳는 문제들",
      typography_29_0_0:
        "개념화 생각함정에 빠지면 스스로 부여한 정체성에 집착하게 돼요.",
      typography_30_0_0:
        "그리고 스스로 부여한 정체성을 유지하기 위한 노력을 하게 되죠.",
      typography_31_0_0:
        "가장 흔한 예시는 특정한 정신질환의 진단명(또는 증상)으로 자기 개념화를 하는 경우예요.",
      typography_32_0_0:
        '"저는 조울증 환자입니다"라고 자기 개념화를 하는 사람들은 "조울증 환자의 삶이 어떻게 흘러가는지 잘 아시죠?"라고 말하곤 해요.',
      typography_33_0_0:
        '"저는 불안 증상이 있어요"라고 자기 개념화를 하는 사람들은 "그러니 제가 이렇게 행동하는 건 어쩔 수 없는 거라고요"라고 말하곤 해요.',
      typography_34_0_0:
        "하지만 이러한 개념화는 우리로 하여금 개념화에 맞는 정보만 선별적으로 받아들이도록 만들곤 해요.",
      typography_35_0_0:
        '가령 사회에서 잘 역할하며 좋은 성과를 이뤄내는 순간에도 "나는 조울증 환자니까"라고 말하며 그 성취감이나 자기효능감을 무시해버리곤 해요.',
      typography_36_0_0:
        '불안하지만 그걸 뛰어넘을 역량과 노력이 뒷받침 되었던 순간에도 "공황장애 환자"라는 벽을 뛰어넘지 못하기도 하고요.',
      typography_37_0_0:
        "또 다른 예시는 과거의 이야기로 자기 개념화를 하는 경우예요.",
      typography_38_0_0:
        '누군가는 "저는 어릴 적 부모님이 싸우는 모습을 많이 보며 자랐어요"라고 이유를 대며 "그러니 제가 지금 왜 이러는지 아시겠죠?"라고 말해요.',
      typography_39_0_0: "물론 힘든 일이 우리를 괴롭혔던 건 사실이에요.",
      typography_40_0_0:
        "그로 인해 지금 이 시점에서 정신건강 문제를 겪으며 힘든 시기를 보내고 있을 수도 있죠.",
      typography_41_0_0:
        "누구든 그런 힘든 일을 겪었다면 충분히 그럴 수 있을 거예요. 그건 우리 탓이 아니랍니다.",
      typography_42_0_0:
        "하지만 문제는 그렇게 자기 개념화를 하다보면, 우린 변화의 동력을 잃고 점차 삶의 레퍼토리를 좁혀나가게 된다는 것에 있어요.",
      typography_43_0_0:
        "개념화 생각함정에 빠져 삶의 레퍼토리가 어떻게 좁아지는지 볼까요?",
      typography_44_0_0:
        "아래와 같은 생각이 떠오르는 사람이 있다고 생각해볼게요.",
      typography_44_2_0:
        '"나는 실패했다. 나는 어릴 적부터 문제를 많이 일으켰다. 진로 선택의 과정에서 부모님과 트러블이 많았기 때문이다. 부모님은 나에게 원하는 바가 있었으나 나는 그것을 원하지 않았다. 그렇다 보니 종종 싸우곤 했다. 심적으로 고생하던 나는 병원에서 ADHD 진단을 받았다. ADHD를 진단받았으니 다른 일들에도 집중 못 하는 건 당연하다. 이미 이렇게 되어버렸는데 어떻게 하겠는가? 어릴 때 내가 하고 싶은 바를 했었더라면 ADHD도 앓지 않을 수 있었다. 그렇게만 됐으면 나는 다른 삶을 살고 있을 수도 있다."',
      typography_45_0_0:
        '이 사람은 "난 ADHD야"라며 스스로를 틀에 가두며 다른 일에 집중하거나 새로운 일에 도전하는 변화를 피하고 있어요.',
      typography_46_0_0:
        '또 "나는 어릴 적 오랜 시간 부모님과 틀어졌다"며, 부모님과의 관계를 개선해보려는 변화를 피하고 있죠.',
      typography_47_0_0:
        "이처럼 마음속에 떠오른 개념화는 변화를 억압해요. 우리가 변화하지 않는 것에 대해 정당성을 부여해주죠.",
      typography_48_0_0:
        "개념화된 이야기에 사로잡힌 경우에는 평생 그 틀에서 벗어나지 못하고 우울과 불안의 늪에 빠져버리기도 합니다.",
      typography_49_0_0:
        "마치 응당 그래야 하는 것처럼, 우리의 마음이 지금 상황에 대한 적절한 이유를 제시하는 거죠.",
      typography_50_0_0:
        "결국 쳇바퀴처럼 돌고 있는 굴레에서 벗어나오지 못하게 됩니다. (예: 우울해 → 그건 이것 때문이지 → 바꿀 순 없는 걸 → 다시 우울해)",
      typography_51_0_0: "개념화로부터 거리두기",
      typography_52_0_0: "개념화 생각함정을 벗어나는 방법은 생각보다 간단해요.",
      typography_53_0_0:
        "팝콘처럼 마음속에 떠오른 생각에 강하게 매몰되기 전에,",
      typography_54_0_0: "그러한 생각이 마음속에 떠올랐음을 인지하고,",
      typography_55_0_0:
        "한 걸음 떨어져서 그 생각이 어떠한 생각인지 객관적으로 살펴보는 것이죠.",
      typography_56_0_0:
        "내가 과거의 사실적이고 자전적인 정보로부터 어떠한 이야기를 만들어내고 있는지 파악하면서요.",
      typography_57_0_0:
        "개념화된 이야기와 자기 자신을 동일시 하는 건 우리에게 아무런 도움이 되지 않아요.",
      typography_58_0_0:
        "하지만 문제는 그 이야기로부터 벗어나기 어렵다는 거죠.",
      typography_59_0_0:
        "심지어 우리는 은연 중에 그러한 이야기로부터 벗어나기 싫어하기도 해요.",
      typography_60_0_0:
        "'개념화된 이야기 = 나'라는 등식이 성립하는 순간, 그 이야기에 대한 공격은 나 자신에 대한 공격처럼 느껴지기 때문이죠.",
      typography_61_0_0:
        '가령 누군가 와서 "아니, 그건 이야기일 뿐이야"라고 하는 순간, 가슴이 철렁하죠. 기분도 나빠요.',
      typography_62_0_0:
        '"내가 그것 때문에 이렇게 우울하고 불안하게 지냈는데, 지금 와서 그건 그냥 이야기일 뿐이라고? 나를 놀리는 거야? 그럼 내 삶은 누가 보상해줘?"',
      typography_63_0_0:
        "내가 그토록 힘들고 고생했던 것에 대한 정당성과 이유가 없어지는 순간, 우리는 강력한 위기감을 느껴요.",
      typography_64_0_0:
        "하지만 우리는 이를 마주할 수 있어야 해요. 개념화된 나의 이야기는 그저 마음속에 떠오른 하나의 심리적 사건일 뿐이랍니다.",
      typography_65_0_0:
        "사실적이고 자전적인 정보는 바뀔 수 없지만, 그로부터 만들어지는 이야기는 얼마든지 바뀔 수 있어요.",
      typography_66_0_0:
        "스티븐 호킹 같은 인물이 '루게릭 환자의 삶'이라는 틀에서 스스로를 바라보았다면 그 후가 어떻게 바뀌었을지 생각해보세요.",
      typography_67_0_0:
        "그것을 이해하고 받아들일 수 있을 때, 우리의 마음은 경직되고 어두운 이야기로부터 벗어나 새로운 변화를 만들어낼 수 있답니다.",
      typography_68_0_0:
        "자, 그러면 이제 나에 대한 개념화를 찾고, 그로부터 거리두기를 연습해 볼까요?",
    },
    v2: {
      typography_0:
        "인간은 어떠한 대상을 바라볼 때 몇몇 특성들을 묶어 하나의 그룹으로 정의하고 싶어해요.",
      typography_1: "그래야 우리가 생각을 더 빠르게 할 수 있기 때문이에요.",
      typography_2: "제한된 인지 용량을 효율적으로 사용하는 거죠.",
      typography_3: "우리는 스스로에게도 비슷한 일을 하는데요.",
      typography_4:
        "자신에게 특정한 이야기를 부여하고, 그 틀 속에서 스스로를 바라보곤 하죠.",
      typography_5: "이를 '자기 개념화'라고 불러요. 줄여서 '개념화'라고 하죠.",
      typography_6: "개념화 이해하기",
      typography_7:
        "많은 경우 개념화는 생각함정으로 쉽게 변질되어 생각과 거리를 두는 일을 힘들게 해요.",
      typography_8: "바로 개념화라는 생각함정이죠.",
      typography_9:
        "개념화 생각함정에 대해 자세히 알아보기 전에, 먼저 한 가지 간단한 활동을 해볼게요. 아래 빈칸에 들어갈 말을 적어보세요.",
      typography_10: "나는 _______한 사람이다.",
      typography_11: "나는 _______하지 않은 사람이다.",
      typography_12: "나는 _______을(를) 잘하는 사람이다.",
      typography_13: "나는 _______을(를) 잘하지 못하는 사람이다.",
      typography_14:
        "위와 같은 문장은 얼마나 사실인가요? 이는 얼마나 절대적 진리에 가까운가요?",
      typography_15: "이번엔 다음과 같은 문장을 하나씩 생각해보죠.",
      typography_16:
        "나는 언제나/어디서나/누구에게나/항상/변함없이 $A$한 사람이다.",
      typography_17:
        "나는 언제나/어디서나/누구에게나/항상/변함없이 $A$하지 않은 사람이다.",
      typography_18:
        "나는 언제나/어디서나/누구에게나/항상/변함없이 $A$을(를) 잘하는 사람이다.",
      typography_19:
        "나는 언제나/어디서나/누구에게나/항상/변함없이 $A$을(를) 잘하지 못하는 사람이다.",
      typography_20:
        "이번엔 어떤가요? 위와 같은 문장에서도 당신이 완성한 내용이 그대로 동일하게 적용되나요?",
      typography_21:
        "아마 그럴 수는 없을 거예요. 나는 친절하다고 해도, 항상, 어디서나, 누구에게나 친절하진 않으며,",
      typography_22:
        "나는 우울한 사람이라도 늘, 언제, 어디서나, 변함없이, 항상 우울한 건 아니에요.",
      typography_23:
        "이는 당연한 이야기입니다. 인간이라는 존재는 보다 복잡하기 때문이죠.",
      typography_24:
        '"나는 X다"라는 표현은 결코 전체의 진실을 표현하지 못해요.',
      typography_25:
        "X가 긍정적이든, 부정적이든, 우리는 분명 X가 아닐 때가 있죠.",
      typography_26:
        "하지만 우리는 스스로에 대해 꼬리표를 붙이고, 이야기 붙이기를 좋아해요.",
      typography_27:
        "이와 같이 자신에 대한 개념화된 이야기를 만드는 것이 바로 개념화 생각함정입니다. 일종의 정체성을 부여하는 것이죠.",
      typography_28: "개념화는 세 가지 측면에서 문제를 일으켜요.",

      typography_29: "1. 세상을 편협하게 해석하는 문제",
      typography_30:
        "개념화에 빠져있으면 그에 부합하는 정보만 우리 뇌에 도달하고, 그렇지 않은 정보는 모두 철저하게 무시돼요.",
      typography_31: "스스로가 부여한 정체성에 집착하게 되죠.",
      typography_32:
        "가장 흔한 예시는 특정한 정신질환의 진단명으로 개념화를 하는 것이에요.",
      typography_33: "“저는 우울증 환자입니다.”",
      typography_34:
        "마음속 깊은 곳에서는 다음과 같은 팝콘이 스멀스멀 피어올라 따라붙어요.",
      typography_35: "‘우울증 환자의 삶이 어떻게 흘러가는지 아시죠?’",
      typography_36:
        "그들은 우울증 환자도 아주 다양한 삶의 모습을 지닐 수 있다는 것을,",
      typography_37:
        "자신에게는 우울증과는 전혀 어울리지 않는 밝고 쾌활하며 호기심 넘치는 모습도 동시에 존재한다는 것을 고려하지 않아요.",
      typography_38:
        "한편 자기 자신을 어떠한 특성을 가진 사람으로 개념화하는 경우도 많아요.",
      typography_39:
        "“나는 부족한 사람이야.” 이런 생각을 가지고 있는 사람들은 그들이 사회에서 잘 역할하고 좋은 성과를 이뤄내는 순간에도 그것을 평가절하해버리죠.",
      typography_40: "“그게 뭐 대수라고…”",
      typography_41: "반대로 자신이 부족하다는 정보는 아주 쉽게 받아들여요.",
      typography_42:
        "작은 실수에도 “그래. 역시 난 부족한 사람이었지”라고 생각하곤 하죠.",
      typography_43:
        "결국 마음속에 떠오른 생각은 하나의 심리적 사건이 아닌 그럴듯한 사실로 다가와요.",
      typography_44:
        "마음속에 떠오른 생각 중 스스로가 부여한 개념화에 어울리는 것들만 취사선택하게 된답니다.",
      typography_45: "거리두기는 요원해지고 삶의 레퍼토리는 한없이 협소해져요.",

      typography_46: "2. 생각, 행동, 감정에 이유를 대는 문제",
      typography_47: "누군가는 다음과 같이 스스로를 설명해요.",
      typography_48:
        "“저는 어릴 적 부모님이 싸우는 모습을 많이 보며 자랐어요.”",
      typography_49:
        "마음속 깊은 곳에서는 다음과 같은 팝콘이 스멀스멀 피어올라 따라붙어요.",
      typography_50: "‘그러니 제가 지금 왜 이러는지 아시겠죠?’",
      typography_51: "물론 힘든 일이 우리를 괴롭혔던 건 사실이에요.",
      typography_52:
        "그로 인해 내가 지금 이 시점에서 정신건강 문제를 겪으며 힘든 시기를 보내고 있는 것도 사실이에요.",
      typography_53:
        "누구든 그런 힘든 일을 겪었다면 충분히 힘든 시기를 보낼 수 있을 거예요.",
      typography_54: "지금 이 상황은 나의 탓이 아니랍니다.",
      typography_55:
        "하지만 문제는 그런 이야기로 스스로를 개념화하다 보면 변화의 동력을 잃고 점차 삶의 레퍼토리를 좁혀나가게 된다는 것이에요.",
      typography_56:
        "이런 이야기들은 왜 우리가 불안하고 우울하며 무기력하게 살아야 하는지에 대한 아주 합당한 이유를 제공해줘요.",
      typography_57:
        "그리고 우리가 그런 생각과 감정에 아주 착 달라붙어 있게 만들죠.",
      typography_58:
        "개념화된 이야기들이 뒷받침되었을 때 지금 내가 느끼는 부정적인 생각과 감정은 하나의 심리적 사건이기보다는 자아 그 자체로 느껴져요.",
      typography_59:
        "거리두기는 요원해지고 생각은 심리적 사건이 아니라 ‘나’ 그 자체가 된답니다.",

      typography_60: "3. 정말로 '나' 자신이 되는 문제",
      typography_61:
        "개념화의 마지막 문제는 그것이 정말로 거리를 두기 어려운 내용이라는 것이에요.",
      typography_62:
        "삶을 살아오며 경험한 것들과 그에 대한 자전적인 정보들은 정말로 ‘나’ 자신 같아요.",
      typography_63:
        "이는 단순히 마음속에 떠오른 생각을 하나의 심리적 사건으로 인지할 수 있느냐의 문제가 아니에요.",
      typography_64: "“저 사람 짜증나…”",
      typography_65:
        "조금 연습하다 보면 이 생각은 심리적 사건으로 바라볼 수 있어요.",
      typography_66:
        "“아, 내 마음속에는 지금 저 사람이 짜증난다는 생각이 떠올랐구나.”",
      typography_67:
        "짜증을 느끼더라도 그것에 매달리며 정신적으로 괴로워하지 않을 순 있답니다.",
      typography_68: "어쩌면 다른 선택을 할 수도 있죠.",
      typography_69: `“그렇지만 뭐… 나는 친절한 사람이 되고 싶어. 그냥 좋게 말해야겠다.”`,
      typography_70: `하지만 개념화된 이야기들은 달라요.`,
      typography_71: `이 이야기들은 정말로 ‘나’가 누구인가를 이야기해주고 있는 것 같아요.`,
      typography_72: `나는 내향적인 사람이다.`,
      typography_73: `나는 공부를 못하는 사람이다.`,
      typography_74: `나는 사람들과 어울리지 못했다.`,
      typography_75: `나는 왕따다.`,
      typography_76: `나는 부족하다.`,
      typography_77: `나는 연애 실패자다.`,
      typography_78: `나는 사랑받을 수 없는 사람이다.`,
      typography_79: `이런 생각들은 정말로 ‘나’ 자신 같아서 하나의 심리적 사건으로 바라보기가 쉽지 않답니다.`,
      typography_80: `좁아지는 삶의 레퍼토리`,

      typography_81:
        "자기 개념화를 하다보면, 우린 변화의 동력을 잃고 점차 삶의 레퍼토리를 좁혀나가게 돼요.",
      typography_82: "아래와 같은 생각이 떠오르는 사람이 있다고 생각해볼게요.",
      typography_82_1:
        '"나는 실패했다. 나는 어릴 적부터 문제를 많이 일으켰다. 진로 선택의 과정에서 부모님과 트러블이 많았기 때문이다. 부모님은 나에게 원하는 바가 있었으나 나는 그것을 원하지 않았다. 그렇다 보니 종종 싸우곤 했다. 심적으로 고생하던 나는 병원에서 ADHD 진단을 받았다. ADHD를 진단받았으니 다른 일들에도 집중 못 하는 건 당연하다. 이미 이렇게 되어버렸는데 어떻게 하겠는가? 어릴 때 내가 하고 싶은 바를 했었더라면 ADHD도 앓지 않을 수 있었다. 그렇게만 됐으면 나는 다른 삶을 살고 있을 수도 있다."',
      typography_83:
        '이 사람은 "난 ADHD야"라며 스스로를 틀에 가두며 다른 일에 집중하거나 새로운 일에 도전하는 변화를 피하고 있어요.',
      typography_84:
        '또 "나는 어릴 적 오랜 시간 부모님과 틀어졌다"며, 부모님과의 관계를 개선해보려는 변화를 피하고 있죠.',
      typography_85:
        "이처럼 마음속에 떠오른 개념화는 변화를 억압해요. 우리가 변화하지 않는 것에 대해 정당성을 부여해주죠.",
      typography_86:
        "개념화된 이야기에 사로잡힌 경우에는 평생 그 틀에서 벗어나지 못하고 우울과 불안의 늪에 빠져버리기도 합니다.",
      typography_87:
        "마치 응당 그래야 하는 것처럼, 우리의 마음이 지금 상황에 대한 적절한 이유를 제시하는 거죠.",
      typography_88:
        "결국 쳇바퀴처럼 돌고 있는 굴레에서 벗어나오지 못하게 됩니다. (예: 우울해 → 그건 이것 때문이지 → 바꿀 순 없는 걸 → 다시 우울해)",
      typography_89: "개념화로부터 거리두기",
      typography_90: "개념화 생각함정을 벗어나는 방법은 생각보다 간단해요.",
      typography_91: "팝콘처럼 마음속에 떠오른 생각에 강하게 매몰되기 전에,",
      typography_92: "그러한 생각이 마음속에 떠올랐음을 인지하고,",
      typography_93:
        "한 걸음 떨어져서 그 생각이 어떠한 생각인지 객관적으로 살펴보는 것이죠.",
      typography_94:
        "내가 과거의 사실적이고 자전적인 정보로부터 어떠한 이야기를 만들어내고 있는지 파악하면서요.",
      typography_95:
        "개념화된 이야기와 자기 자신을 동일시 하는 건 우리에게 아무런 도움이 되지 않아요.",
      typography_96: "하지만 문제는 그 이야기로부터 벗어나기 어렵다는 거죠.",
      typography_97:
        "심지어 우리는 은연 중에 그러한 이야기로부터 벗어나기 싫어하기도 해요.",
      typography_98:
        "'개념화된 이야기 = 나'라는 등식이 성립하는 순간, 그 이야기에 대한 공격은 나 자신에 대한 공격처럼 느껴지기 때문이죠.",
      typography_99:
        '가령 누군가 와서 "아니, 그건 이야기일 뿐이야"라고 하는 순간, 가슴이 철렁하죠. 기분도 나빠요.',
      typography_100:
        '"내가 그것 때문에 이렇게 우울하고 불안하게 지냈는데, 지금 와서 그건 그냥 이야기일 뿐이라고? 나를 놀리는 거야? 그럼 내 삶은 누가 보상해줘?"',
      typography_101:
        "내가 그토록 힘들고 고생했던 것에 대한 정당성과 이유가 없어지는 순간, 우리는 강력한 위기감을 느껴요.",
      typography_102:
        "하지만 우리는 이를 마주할 수 있어야 해요. 개념화된 나의 이야기는 그저 마음속에 떠오른 하나의 심리적 사건일 뿐이랍니다.",
      typography_103:
        "사실적이고 자전적인 정보는 바뀔 수 없지만, 그로부터 만들어지는 이야기는 얼마든지 바뀔 수 있어요.",
      typography_104:
        "스티븐 호킹 같은 인물이 '루게릭 환자의 삶'이라는 틀에서 스스로를 바라보았다면 그 후가 어떻게 바뀌었을지 생각해보세요.",
      typography_105:
        "그것을 이해하고 받아들일 수 있을 때, 우리의 마음은 경직되고 어두운 이야기로부터 벗어나 새로운 변화를 만들어낼 수 있답니다.",
      typography_106:
        "자, 그러면 이제 나에 대한 개념화를 찾고, 그로부터 거리두기를 연습해 볼까요?",
    },
  },
};
