import { GuideTypographyLine, Select, Typography } from "data/CellComponent";
import { selfCheckData } from "data/selfCheckData";
import {
  Content,
  Instruction,
  ProgramContentType,
  Header1,
  SelfCheckCard,
} from "../../BlockComponent";
import { LastCard } from "../common";

export const selfCheckTemplate = [
  Header1("selfcheck_guide"),
  ...selfCheckData.depression.questionList.map(
    ({ questionTranslationKey, evaluation }, index) =>
      SelfCheckCard("depression", questionTranslationKey, evaluation)
  ),
  ...selfCheckData.anxiety.questionList.map(
    ({ questionTranslationKey, evaluation }, index) =>
      SelfCheckCard("anxiety", questionTranslationKey, evaluation)
  ),
  ...selfCheckData.stress.questionList.map(
    ({ questionTranslationKey, evaluation }, index) =>
      SelfCheckCard("stress", questionTranslationKey, evaluation)
  ),
];

export const Content_0_1_A: ProgramContentType[] = [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  Content([
    [
      Typography(`typography_2_0_0`),
      Select(
        [
          { translationKey: "상황" },
          { translationKey: "생각" },
          { translationKey: "반응" },
        ],
        {
          id: "quiz1",
        }
      ),
      Typography(`typography_2_0_2`),
    ],
    [
      Typography(`typography_2_1_0`),
      Select(
        [
          { translationKey: "상황" },
          { translationKey: "생각" },
          { translationKey: "반응" },
        ],
        {
          id: "quiz2",
        }
      ),
      Typography(`typography_2_1_2`),
    ],
    [
      Typography(`typography_2_2_0`),
      Select(
        [
          { translationKey: "상황" },
          { translationKey: "생각" },
          { translationKey: "반응" },
        ],
        {
          id: "quiz3",
        }
      ),
      Typography(`typography_2_2_2`),
    ],

    GuideTypographyLine("typography_2_3_0_1", {
      answerList: [
        { id: "quiz1", answerIndex: 0 },
        { id: "quiz2", answerIndex: 1 },
        { id: "quiz3", answerIndex: 2 },
      ],
    }),
  ]),
  Instruction("typography_3_0_0"),
  Header1(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),
  Instruction(`typography_7_0_0`),

  Header1(`typography_8_0_0`),
  Instruction(`typography_9_0_0`),

  Instruction(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),
  Instruction(`typography_12_0_0`),
  Instruction(`typography_13_0_0`),
  Instruction(`typography_14_0_0`),
  Instruction(`typography_15_0_0`),
  Instruction(`typography_16_0_0`),

  Header1(`typography_17_0_0`),
  Instruction(`typography_18_0_0`),
  Instruction(`typography_19_0_0`),
  Instruction(`typography_20_0_0`),
  Instruction(`typography_21_0_0`),

  Instruction(`typography_22_0_0`),
  Instruction(`typography_23_0_0`),
  Instruction(`typography_24_0_0`),

  ...selfCheckTemplate,
  Instruction("selfcheck_finish"),
  ...LastCard,
];
