import { useRecoilValue } from "recoil";
import { taskListAtom } from "recoil/taskListAtom";

export default function useGetMeditationRecordVersion() {
  const taskListState = useRecoilValue(taskListAtom);

  const isOldVersion = !taskListState.some(
    (element) =>
      ["8-6-A", "8-8-A"].includes(element.taskId) && element.lastDoneAt
  );

  const isPracticeAvailable = taskListState.some(
    (element) => ["8-6-A"].includes(element.taskId) && element.lastDoneAt
  );
  const isRegulationAvailable = taskListState.some(
    (element) => ["8-8-A"].includes(element.taskId) && element.lastDoneAt
  );
  return { isOldVersion, isPracticeAvailable, isRegulationAvailable };
}
