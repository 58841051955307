import Box from "@mui/joy/Box";
import List from "@mui/joy/List";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { useCallback, useEffect, useState } from "react";
import { CircularProgress, ListDivider, Stack } from "@mui/joy";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  conceptApi,
  ConceptKeyType,
  ConceptKeyType_nested,
  CONCEPT_DOMAIN,
  CopingStrategyKeyType,
  CopingStrategyType,
  CoreBeliefKeyType,
  CoreBeliefType,
  AutomaticThoughtKeyType,
  AutomaticThoughtType,
  ConceptNoteType,
  CONCEPT_NOTE_DETAIL_ENDPOINT,
  NoteType,
  ConceptType,
  CopingStrategyNoteType,
  AutomaticThoughtNoteType,
  CoreBeliefNoteType,
  IntermediateBeliefKeyType,
  IntermediateBeliefNoteType,
  IntermediateBeliefType,
  IndividualThoughtKeyType,
  IndividualThoughtNoteType,
  IndividualThoughtType,
  AutomaticThoughtForValueType,
  AutomaticThoughtForValueKeyType,
  AutomaticThoughtForValueNoteType,
} from "api/conceptApi";
import { useQuery } from "@tanstack/react-query";
import * as _ from "lodash";
import { layoutAtom } from "recoil/layoutAtom";
import Memo from "components/conceptNote/Memo";
import useInterval from "hooks/useInterval";
import { conceptNoteAtom } from "recoil/conceptNoteAtom";
import {
  getAutomaticThoughtForValueIdList,
  getAutomaticThoughtIdList,
} from "logic/logics";
import AddNewAutomaticThoughtButton from "components/conceptNote/AddNewAutomaticThoughtButton";
import ConceptListItem from "./ConceptListItem";
import ConcernAndGoal from "components/conceptNote/ConcernAndGoal";
import { isDev } from "config";
import { addAlpha, extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";
import { DoubleLeftArrowSvg } from "static/image/common/SvgAssets";
import useSidebarToggle from "hooks/useSidebarToggle";

export type IdType = ConceptKeyType;
export type Id1Type = ConceptKeyType_nested;
export type DataId1Type = CoreBeliefType &
  IntermediateBeliefType &
  CopingStrategyType &
  AutomaticThoughtType &
  AutomaticThoughtForValueType;
export type NoteDataId1Type = CoreBeliefNoteType &
  IntermediateBeliefNoteType &
  CopingStrategyNoteType &
  AutomaticThoughtNoteType &
  AutomaticThoughtForValueNoteType;
export type Id2Type =
  | CoreBeliefKeyType
  | IntermediateBeliefKeyType
  | CopingStrategyKeyType
  | AutomaticThoughtKeyType
  | AutomaticThoughtForValueKeyType
  | "thoughtRecordKey"
  | "thoughtTrapList"
  | "thoughtList";

export type DataId2Type = IndividualThoughtType;
export type NoteDataId2Type = IndividualThoughtNoteType;
export type Id3Type = IndividualThoughtKeyType;

export const conceptMetaData: {
  [key: string]: { id: Id2Type; label: string }[];
} = {
  coreBelief: [
    { id: "coreBelief1" as Id2Type, label: "나" },
    { id: "coreBelief2" as Id2Type, label: "타인" },
    { id: "coreBelief3" as Id2Type, label: "세상" },
  ],
  intermediateBelief: [
    { id: "rules", label: "규칙" },
    { id: "attitudes", label: "태도" },
    { id: "assumptions", label: "가정" },
  ],
  copingStrategy: [
    { id: "surrender", label: "굴복" },
    { id: "overcompensation", label: "과잉보상" },
    { id: "avoidance", label: "회피" },
  ],
  automaticThought: [
    { id: "situation", label: "상황" },
    { id: "thoughtList", label: "생각" },
    { id: "emotion", label: "감정" },
    { id: "physicalResponse", label: "신체 반응" },
    { id: "behavior", label: "행동" },
  ],
  automaticThoughtForValue: [
    { id: "situation", label: "상황" },
    { id: "thought", label: "생각" },
    { id: "valueDomain", label: "가치 영역" },
    { id: "value", label: "가치" },
    { id: "emotion", label: "감정" },
    { id: "physicalResponse", label: "신체 반응" },
    { id: "behavior", label: "행동" },
  ],
};

export function isNoteType(obj: any): obj is NoteType {
  return (
    typeof obj === "object" &&
    obj !== null && // obj는 객체이며 null이 아니어야 합니다.
    "value" in obj &&
    "isHumanCheckRequired" in obj
  );
}

export function convertNoteToReal(data: ConceptNoteType) {
  return Object.fromEntries(
    Object.entries(data)
      // .filter(([key, note]) => typeof note !== "string")
      .filter(
        ([key, note]) => !key.includes("Status") && key !== "isOldVersion"
      )
      .map(([key, note]) =>
        typeof note === "string" || !note // concern,goal
          ? [key, note || ""]
          : isNoteType(note) // coreExperience
          ? [key, note?.value || ""]
          : [
              key,
              Object.fromEntries(
                Object.entries(note || {}).map(([key2, nestedNote]) => [
                  key2,
                  typeof nestedNote === "boolean" // automaticThought > isConfirmAvailable
                    ? nestedNote
                    : typeof nestedNote === "string" // automaticThought > thoughtRecordKey
                    ? nestedNote
                    : isNoteType(nestedNote)
                    ? nestedNote?.value || ""
                    : key2 === "thoughtTrapList" //NoteType[]
                    ? nestedNote?.map((each: NoteType) => each.value)
                    : key2 === "thoughtList" //IndividualThoughtNoteType[]
                    ? nestedNote?.map((each: IndividualThoughtNoteType) =>
                        Object.fromEntries(
                          Object.entries(each).map(([key3, nestedNote2]) => [
                            key3,
                            nestedNote2.value || "",
                          ])
                        )
                      )
                    : "",
                ])
              ),
            ]
      )
  ) as ConceptType;
}

export default function InsideC({ patientId }: { patientId: string }) {
  const [noteData, setNoteData] = useState<ConceptNoteType | undefined>();
  const [data, setData] = useState<ConceptType | undefined>();
  const [savedData, setSavedData] = useState<ConceptType | undefined>();

  const { closeSidebar: closeConceptNote } = useSidebarToggle({
    direction: "right",
  });

  //내담자 바뀌면 초기화
  useEffect(() => {
    setNoteData(undefined);
    setData(undefined);
    setSavedData(undefined);
    setCurrentAutomaticThoughtType("함정 찾기");
  }, [patientId]);

  const [prevData, setPrevData] = useState<ConceptType | undefined>();

  const queryFn = useCallback(
    () => conceptApi.noteDetail(patientId),
    [patientId]
  );

  const { refetch: getConceptNoteDetail } = useQuery(
    [CONCEPT_DOMAIN, CONCEPT_NOTE_DETAIL_ENDPOINT, patientId, "개념화노트"],
    queryFn,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data && data.result) {
          const list = data.result;
          console.log("InsideC: ", list, convertNoteToReal(list));
          setNoteData(list);
        }
      },
    }
  );

  useEffect(() => {
    if (noteData) {
      setData(convertNoteToReal(noteData));
      setSavedData(convertNoteToReal(noteData));
    }
  }, [noteData]);

  useInterval(
    () => {
      setPrevData(data);
      //변화 없으면 다시 가져와
      if (data && prevData && _.isEqual(data, prevData)) {
        getConceptNoteDetail();
      }
    },
    300000,
    []
  );

  const { isRightSideBarOpen } = useRecoilValue(layoutAtom);

  type automaticThoughtType = "함정 찾기" | "가치 찾기";
  const [currentAutomaticThoughtType, setCurrentAutomaticThoughtType] =
    useState<automaticThoughtType>("함정 찾기");

  const conceptNoteData: {
    id: ConceptKeyType;
    title: string;
    format?: { id: Id2Type; label: string }[];
  }[] = [
    {
      id: "concern" as ConceptKeyType,
      title: "고민",
    },
    {
      id: "goal" as ConceptKeyType,
      title: "목표",
    },
    {
      id: "coreExperience" as ConceptKeyType,
      title: "핵심 경험",
    },
    {
      id: "coreBelief" as ConceptKeyType,
      title: "핵심 믿음",
      format: conceptMetaData["coreBelief"],
    },
    {
      id: "intermediateBelief" as ConceptKeyType,
      title: "중간 믿음",
      format: conceptMetaData["intermediateBelief"],
    },
    // {
    //   id: "copingStrategy" as ConceptKeyType,
    //   title: "대처 전략",
    //   format: conceptMetaData["copingStrategy"],
    // },
  ];
  const automaticThoughtData: {
    id: ConceptKeyType;
    title: string;
    format?: { id: Id2Type; label: string }[];
  }[] =
    currentAutomaticThoughtType === "함정 찾기"
      ? [
          ...getAutomaticThoughtIdList(savedData || {}).map((each) => ({
            id: `automaticThought${each}` as ConceptKeyType,
            title: `자동적 사고 ${each}`,
            format: conceptMetaData[`automaticThought`],
          })),
        ]
      : [
          ...getAutomaticThoughtForValueIdList(savedData || {}).map((each) => ({
            id: `automaticThoughtForValue${each}` as ConceptKeyType,
            title: `가치 ${each}`,
            format: conceptMetaData[`automaticThoughtForValue`],
          })),
        ];

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: "fixed", lg: !isRightSideBarOpen ? "fixed" : "sticky" },
        transform: !isRightSideBarOpen ? "translateX(100%)" : "translateX(0)",
        transition: "transform 0.4s, width 0.4s",
        width: "var(--InsideC-width)",
        height: {
          xs: "calc(100% - var(--Header-height, 0))",
          lg: !isRightSideBarOpen
            ? "calc(100% - var(--Header-height, 0))"
            : "100%",
        },
        zIndex: { xs: 1000, lg: 0 },
        top: {
          xs: "var(--Header-height, 0)",
          lg: !isRightSideBarOpen ? "var(--Header-height, 0)" : 0,
        },
        right: 0,
        pb: 0,
        flexShrink: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        // borderLeft: "1px solid",
        // borderColor: "background.level3",
        backgroundColor: "background.level2",
      }}
    >
      {noteData && data && savedData ? (
        <Box
          sx={{
            width: "100%",
            minHeight: 0,
            overflow: "hidden auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            px: "20px",
            pb: 5,
          }}
        >
          <Box
            sx={{
              width: "100%",
              minHeight: "var(--StickyHeader-height)",
              height: "var(--StickyHeader-height)",
              position: "sticky",
              top: 0,
              backgroundColor: "background.level2",
              borderBottom: 1,
              borderColor: "divider",
              zIndex: 10,
            }}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              sx={{ height: "100%", cursor: "pointer" }}
              onClick={closeConceptNote}
            >
              <Typography level="title-lg">개념화 노트</Typography>

              <Box sx={{ transform: "rotate(180deg)" }}>
                <DoubleLeftArrowSvg />
              </Box>
            </Stack>
          </Box>
          <List sx={{ pt: "20px" }}>
            <Memo />
            <ListDivider sx={{ my: "20px", backgroundColor: "divider" }} />

            {conceptNoteData.map(({ id, title, format }) => (
              <Stack
                key={id}
                sx={{
                  pb: "20px",
                  ...(id !== "concern" && {
                    borderBottomWidth: "1px",
                    borderColor: "divider",
                    mb: "20px",
                  }),
                }}
              >
                <ConceptListItem
                  noteData={noteData}
                  data={data}
                  setNoteData={setNoteData}
                  setData={setData}
                  id={id as ConceptKeyType}
                  title={title}
                  format={format}
                  isRequireModificationReason={!isDev && !noteData.isOldVersion}
                  status={noteData[`${id as ConceptKeyType_nested}Status`]}
                  // readOnly={id === "concern"}
                />
              </Stack>
            ))}
            <Stack direction="row" sx={{ width: "100%", mb: "20px" }}>
              {["함정 찾기", "가치 찾기"].map((each, index) => (
                <Box
                  key={`automaticThoughtTab${index}`}
                  sx={{
                    pb: "8px",
                    flex: 1,
                    cursor: "pointer",
                    borderBottomWidth: "2px",
                    borderBottomColor:
                      currentAutomaticThoughtType === each
                        ? "primary.solid"
                        : addAlpha(
                            extractHexColor(theme.vars.palette.primary.soft),
                            0.1
                          ),
                  }}
                  onClick={() => {
                    setCurrentAutomaticThoughtType(
                      each as automaticThoughtType
                    );
                  }}
                >
                  <Typography level="body-xs" sx={{ textAlign: "center" }}>
                    {each}
                  </Typography>
                </Box>
              ))}
            </Stack>

            {automaticThoughtData.map(({ id, title, format }, index) => (
              <div key={id}>
                <ConceptListItem
                  noteData={noteData}
                  data={data}
                  setNoteData={setNoteData}
                  setData={setData}
                  id={id as ConceptKeyType}
                  title={title}
                  format={format}
                  isRequireModificationReason={!isDev && !noteData.isOldVersion}
                  status={noteData[`${id as ConceptKeyType_nested}Status`]}
                  // readOnly={id === "concern"}
                />
                {id !== "concern" &&
                  index < automaticThoughtData.length - 1 && (
                    <ListDivider
                      sx={{ my: "20px", backgroundColor: "divider" }}
                    />
                  )}
              </div>
            ))}

            <ListDivider sx={{ my: "20px", backgroundColor: "divider" }} />
            <AddNewAutomaticThoughtButton
              isForValue={currentAutomaticThoughtType === "가치 찾기"}
            />
          </List>
        </Box>
      ) : (
        <CircularProgress color="neutral" />
      )}
    </Sheet>
  );
}
