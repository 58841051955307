export const translation_4_10_A = {
  en: {},
  ko: {
    v1: {
      실행했어요: "실행했어요",
      "실행하지 못했어요": "실행하지 못했어요",
      일치했어요: "일치했어요",
      "일치하지 않았어요": "일치하지 않았어요",
      "$A$ $B$": "$A$ $B$",
      typography_0_0_0: `우리가 함께 계획한 행동 실험은 다음과 같았어요.`,
      typography_1_0_0: `"$A$"을(를) 계획한 대로 실행해보았나요? `,
      typography_2_0_0: `계획한 것을 잘 해내셨군요. 축하해요.`,
      typography_3_0_0: `"$A$"을(를) 어떻게 실행할 수 있었나요? 상세하게 기록할수록 좋아요.`,
      typography_4_0_0: `"$A$"은(를) "$B$"와(과) 일치하는 방향으로 나타났나요?`,
      typography_5_0_0: `이번 "$A$" 실험에서는 내 가설과 일치하는 결과가 나타났네요.`,
      typography_6_0_0: `그렇다고 아쉬워할 필요는 없어요. 잘 살펴보면 가설에 일치하는 결과이지만 나의 자동적인 생각을 100% 다 반영한 것은 아닐 거예요.`,
      typography_7_0_0: `단 한 번의 시도로 실험이 실패했다고 결론 내는 것도 너무 성급한 결정이에요. 하나의 경험을 모든 문제에 대입하여 결론짓는 것은 '생각함정'이라는 것도 이제 우리는 알고 있죠. `,
      typography_8_0_0: `그렇기 때문에 이번에도 모든 문제가 아닌 이번 실험에 한해서, 어떤 부분이 어려웠는지 확인해 보는 시간을 가져볼까 해요.`,
      typography_9_0_0: `우리는 생각에 도전하고, 검증해나가면서 새로운 경험을 하기 위한 시도를 꾸준히 할 필요가 있답니다. 변화는 이런 과정을 통해 서서히 나타나거든요. `,
      typography_10_0_0:
        "우리 차분하게 이번 결과를 다시 돌아보면서 점검해 보는 시간을 가져볼까요?",
      typography_11_0_0: `"$A$"의 결과는 어땠나요? 상세하게 기록할수록 좋아요.`,
      typography_12_0_0: `"$A$"을(를) 실시할 때 처음에 세웠던 계획대로 진행되었나요? 만약에 그렇지 않았다면 어떤 부분이 달랐나요?`,
      typography_13_0_0: `"$A$"의 결과를 보고 어떤 생각이 들었나요?`,
      typography_14_0_0: "우리가 검증하려는 자동적 사고는 다음과 같았어요.",
      typography_15_0_0: `"$A$"의 결과로 기존의 자동적 사고에 변화가 있었나요? 있었다면 어떤 변화가 있었나요?`,
      typography_16_0_0: `이전에 이 생각에 영향받는 정도를 $A$점이라고 하셨어요. 지금 이 생각은 나에게 얼마나 영향을 주고 있나요?`,
      typography_17_0_0: `행동 실험에서 계획한 행동을 실행하는 데 문제가 있었나 보네요. `,
      typography_18_0_0: `괜찮아요. 오래된 습관을 바꾸는 것은 어려운 일이죠. 아마 이번 상황에서 실험을 진행하는 데 어려운 부분이 있었을 거예요. 필요하다면 실행하기 조금 더 쉬운 방향으로 계획을 수정할 수도 있어요.`,
      typography_19_0_0: `단 한 번의 시도로 실험이 실패했다고 결론 내는 것도 너무 성급한 결정이에요. 하나의 경험을 모든 문제에 대입하여 결론짓는 것은 '생각함정'이라는 것도 이제 우리는 알고 있죠. `,
      typography_20_0_0: `그렇기 때문에 이번에도 모든 문제가 아닌 이번 실험에 한해서, 어떤 부분이 어려웠는지 확인해 보는 시간을 가져볼까 해요.`,
      typography_21_0_0: `"$A$"을(를) 실행하기 어려웠던 이유는 무엇인가요?`,
      typography_22_0_0: `좋아요. 고생 많으셨어요.`,
    },
  },
};
