import { useCallback } from "react";
import { ProgramContentType } from "data/BlockComponent";
import TextareaCell from "./TextareaCell";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { useQuery } from "@tanstack/react-query";
import { Stack } from "@mui/joy";
import {
  conceptApi,
  CONCEPT_DOMAIN,
  CONCEPT_REFERENCE_DETAIL_ENDPOINT,
  ReferenceKeyType,
  ReferenceListType,
} from "api/conceptApi";
import { setProgramContentData } from "logic/logics";

export default function ReferenceCell({
  taskKey,
  placeholder,
  initialHtmlString,
  initialValue,
  isCoach,
  blockIndex,
  setData,
  lineIndex,
  cellIndex,
  editorKey,
  referenceKey,
}: {
  taskKey: string;
  placeholder?: string;
  initialHtmlString?: string;
  initialValue?: string;
  isCoach?: boolean;
  blockIndex: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  editorKey?: string;
  referenceKey?: ReferenceKeyType;
}) {
  const patientState = useRecoilValue(patientAtom);

  const referenceDetailqueryFn = useCallback(
    () => conceptApi.getReferenceDetail(`${patientState?.patientId}`),
    [patientState]
  );

  useQuery(
    [CONCEPT_DOMAIN, CONCEPT_REFERENCE_DETAIL_ENDPOINT],
    referenceDetailqueryFn,
    {
      enabled: !!patientState?.patientId,
      onSuccess: (fetchData) => {
        if (fetchData && fetchData.result) {
          const data = fetchData.result as ReferenceListType;
          const value = data[referenceKey as ReferenceKeyType]
            ?.map((each) => `- ${each}`)
            ?.join("\n");
          if (!initialValue) {
            setProgramContentData({
              setData,
              blockIndex,
              lineIndex,
              cellIndex,
              newlyAddedData: {
                htmlString: value ? `<p>${value}</p>` : undefined,
                value: value,
              },
            });
          }
        }
      },
    }
  );

  return (
    <Stack spacing={0.5} alignItems="end">
      <TextareaCell
        placeholder={placeholder}
        initialHtmlString={initialHtmlString}
        initialValue={initialValue}
        isCoachField
        isCoach={isCoach}
        setData={setData}
        lineIndex={lineIndex}
        cellIndex={cellIndex}
        blockIndex={blockIndex}
        editorKey={editorKey}
      />
    </Stack>
  );
}
