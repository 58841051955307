import { Box, Button, Stack } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import {
  selfDistancingApi,
  SELFDISTANCING_DOMAIN,
  SELFDISTANCING_THOUGHT_DETAIL_ENDPOINT,
  ThoughtDetailType,
} from "api/selfDistancingApi";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Block, BlockType } from "components/tool/self-distancing/Block";
import ProgressBar from "components/tool/self-distancing/ProgressBar";
import { SummaryType } from "components/tool/self-distancing/Summary";

export default function DistancingWrapup({
  uuid,
  threadId,
}: {
  uuid: string;
  threadId: string;
}) {
  const navigate = useNavigate();
  const qnaRefs = useRef<HTMLDivElement[]>([]);

  const [summaryData, setSummaryData] = useState<ThoughtDetailType>();
  const wrapupList: {
    type: BlockType;
    question: string;
    summaryData?: SummaryType;
  }[] = [
    {
      type: "summary",
      question: "지금껏 떠올랐던 다양한 생각들을 한번 살펴볼까요?",
      summaryData: [
        { label: "자동적 사고", text: summaryData?.automaticThought || "" },
        {
          label: "이렇게도 생각해 봤어요",
          text: summaryData?.alternativeThought || "",
        },
      ],
    },
    {
      type: "plain",
      question: `마지막으로, 마음속에 떠오는 생각은 '나'가 아니라는 사실을 경험해 볼 거예요.`,
    },
  ];

  const [shouldPoll, setShouldPoll] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldPoll(false); // 5초 후에 폴링 중지
    }, 10000);
  }, []);

  useQuery(
    [SELFDISTANCING_DOMAIN, SELFDISTANCING_THOUGHT_DETAIL_ENDPOINT],
    () => selfDistancingApi.getThoughtDetail(uuid, threadId),
    {
      ...(!summaryData?.automaticThought &&
        shouldPoll && {
          refetchInterval: 1000,
        }),
      onSuccess: (data) => {
        if (data && data.result) {
          console.log(data.result);
          setSummaryData(data.result);
        }
      },
    }
  );
  const [lastShownIndex, setLastShownIndex] = useState<number>(0);

  const showNextQuestion = () => {
    setLastShownIndex((lastShownIndex) => lastShownIndex + 1);
  };

  const moveToIndex = (index: number) => {
    qnaRefs.current[index].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    if (lastShownIndex > 0) {
      moveToIndex(lastShownIndex);
    }
  }, [lastShownIndex]);

  return (
    <Stack
      sx={{
        width: "100%",
      }}
      alignItems="center"
    >
      <Stack sx={{ width: "100%", mb: "50px" }} direction="row" spacing={"3px"}>
        {[
          {
            label: "알아차리기",
            percentage: 100,
          },
          { label: "거리두기", percentage: 100 },
          { label: "기꺼이 경험하기", percentage: 0 },
        ].map(({ label, percentage }, index) => (
          <Box sx={{ flex: 1 }}>
            <ProgressBar label={label} percentage={percentage}></ProgressBar>
          </Box>
        ))}
      </Stack>
      <Stack
        direction={"column"}
        justifyContent="start"
        sx={{ width: "100%", pb: "100px" }}
        spacing="50px"
      >
        {wrapupList.map(({ type, question, summaryData }, index) => (
          <Box sx={{ ...(index > lastShownIndex && { visibility: "hidden" }) }}>
            <Block
              ref={(el) => (qnaRefs.current[index] = el as HTMLDivElement)}
              type={type as BlockType}
              summaryData={summaryData}
              question={question}
              {...(lastShownIndex < 1 && {
                onNextButtonClick: showNextQuestion,
              })}
              isNextButtonAvailable={lastShownIndex === index}
            />
          </Box>
        ))}
      </Stack>
      {lastShownIndex === 1 && (
        <Button
          size="lg"
          sx={{
            backgroundColor: "#232832",
            width: "100%",
            position: "sticky",
            bottom: "20px",
            "&:hover": {
              backgroundColor: `#23283288`,
            },
          }}
          onClick={() => {
            navigate("/tool/5");
          }}
        >
          계속 진행하기
        </Button>
      )}
    </Stack>
  );
}
