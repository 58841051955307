import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const SURVEY_DOMAIN = "survey";

export const SURVEY_SUBMIT_ENDPOINT = "submit";

export interface SurveyBodyType {
  sat?: string;
  feelingWhenTermination?: string;
  satisfiedPart?: string;
  unsatisfiedPart?: string;
  isContinue?: boolean;
}

export const surveyApi = {
  submit: (body: SurveyBodyType) =>
    axiosInstance.post<void>(
      `${SURVEY_DOMAIN}/${SURVEY_SUBMIT_ENDPOINT}`,
      body
    ),
};
