import { Box, List, Stack } from "@mui/joy";
import dayjs from "dayjs";
import * as _ from "lodash";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { layoutAtom } from "recoil/layoutAtom";
import { ActivityRecordType } from "api/activityRecordApi";
import ActivityRecordListItem from "./ActivityRecordListItem";
import AddNewActivityRecordButton from "./AddNewActivityRecordButton";

export default function ActivityRecordList({
  activityRecordList,
  currentActivityRecord,
  setCurrentActivityRecord,
}: {
  activityRecordList?: ActivityRecordType[];
  currentActivityRecord?: ActivityRecordType;
  setCurrentActivityRecord: React.Dispatch<
    React.SetStateAction<ActivityRecordType | undefined>
  >;
}) {
  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";
  const { isTaskContainerSmall } = useRecoilValue(layoutAtom);

  return (
    <Box
      sx={{
        flexShrink: 0,
        width: isTaskContainerSmall ? "64px" : "300px",
        transition: "transform 0.4s, width 0.4s",
        scrollPaddingBlockStart: "20px",
        pl: isTaskContainerSmall ? "16px" : "24px",
        mr: "2px",
        height: "100%",
        overflow: "hidden scroll",
      }}
    >
      <Stack
        justifyContent={"center"}
        sx={{
          width: "100%",
          minHeight: "calc(var(--StickyHeader-height) - 0px)",
          height: "calc(var(--StickyHeader-height) - 0px)",
          backgroundColor: "background.level1",
          zIndex: 10,
          position: "sticky",
          top: 0,
          borderBottom: 1,
          borderColor: "background.level3",
          mb: "20px",
        }}
      >
        <AddNewActivityRecordButton
          setCurrentActivityRecord={setCurrentActivityRecord}
          disabled={
            !(
              !isCoach &&
              activityRecordList?.every((element) => element.lastDoneAt)
            )
          }
        />
      </Stack>

      <List
        sx={{
          pt: "12px",
          "--ListItem-paddingY": 0,
          "--ListItem-paddingX": 0,
        }}
      >
        {activityRecordList?.map((activityRecord, index) => {
          return (
            <ActivityRecordListItem
              key={activityRecord.activityRecordKey}
              isFirstRecordOfMonth={
                index === 0 ||
                (index - 1 >= 0 &&
                  dayjs(activityRecordList[index - 1].date).get("month") !==
                    dayjs(activityRecordList[index].date).get("month"))
              }
              activityRecord={activityRecord}
              isSelected={
                currentActivityRecord?.activityRecordKey ===
                activityRecord.activityRecordKey
              }
              isSucceeded={activityRecord?.isSucceeded}
              setCurrentActivityRecord={setCurrentActivityRecord}
            />
          );
        })}
      </List>
    </Box>
  );
}
