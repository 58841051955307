export const translation_100_0_A = {
  en: {},
  ko: {
    v1: {
      트위터: "트위터",
      네이버: "네이버",
      인스타그램: "인스타그램",
      "지인 추천": "지인 추천",
      기타: "기타",
      typography_0_0_0:
        "안녕하세요. 디스턴싱에 오신 걸 환영해요. 아래 화살표를 눌러볼까요?",
      typography_1_0_0:
        "잘했어요. 디스턴싱은 무기력에서 벗어나도록 도와주는 프로그램이에요.",
      typography_2_0_0:
        "특히 무기력을 만드는 생각과 거리를 두고, 다시 내가 원하는 삶의 가치를 향해 나아갈 수 있도록 도와주죠.",
      typography_3_0_0:
        "아마 다양한 이유로 무기력의 덫에 빠져 여기까지 도착하셨을 거예요.",
      typography_4_0_0:
        "고단한 직장 생활, 어려운 인간관계, 거듭되는 실패로 등 저마다 다른 이유가 있겠죠.",
      typography_5_0_0:
        "이유가 무엇이든 언제부터인가 삶의 반경은 좁아지고 침대 밖으로 벗어나기 점점 더 어려워지고 계실 거예요.",
      typography_6_0_0: "삶에서 활기차고 흥미로운 일들은 점점 사라져가고요.",
      typography_7_0_0: `“인생에 재밌는 일이 하나도 없어. 내 인생은 무기력하고 점점 더 힘들게만 흘러가"라면서요.`,
      typography_8_0_0:
        "그렇담 잘 찾아오셨어요. 우리가 당신을 변화시켜 드릴 거예요.",
      typography_9_0_0: "과학적으로 검증된 모든 방법을 동원해서요.",
      typography_10_0_0:
        "시작하기에 앞서 한 가지 재밌는 사실을 하나 알려드릴게요.",
      typography_11_0_0:
        "지금 여기까지 읽은 사람들은 4주 뒤 무기력을 호전시킬 확률이 50% 증가해요.",
      typography_12_0_0: "이미 중요한 단계를 넘은 셈이죠.",
      typography_13_0_0:
        "알아요. 무기력하고 힘들 땐 이 글을 읽는 것조차 벅차고 힘들다는 사실을요.",
      typography_14_0_0:
        "하지만 이 단계만 넘어도 무기력을 호전시킬 확률이 50%나 증가한다는 사실을 잊지 마세요.",
      typography_15_0_0: "보이진 않겠지만 변화는 벌써 시작되고 있답니다.",
      typography_16_0_0:
        "디스턴싱이 지향하는 건 단 하나, 오직 ‘행동 변화'예요.",
      typography_17_0_0:
        "위대한 학자들은 사람의 기분을 변화시키는 열쇠는 그의 행동이 달라지도록 돕는 것이라는 걸 밝혔어요.",
      typography_18_0_0:
        "기분이 바뀔 때까지 행동하지 않고 기다리는 것이 아니라",
      typography_19_0_0: "바뀐 행동으로 인해 기분이 나아질 수 있다는 것이죠.",
      typography_20_0_0:
        "우리는 당신이 무기력의 굴레를 벗어나 다시 적극적으로 삶을 누릴 수 있도록 도울 거예요.",
      typography_21_0_0:
        "디스턴싱은 그러한 행동 변화를 촉진하기 위해 설계되어 있답니다.",
      typography_22_0_0:
        "물론 쉬운 일은 아니랍니다. 그렇기 때문에 훈련받은 전문 코치 선생님이 모든 과정을 도와줄 거예요.",
      typography_23_0_0:
        "자, 그러면 그 변화가 어떻게 만들어지는지 조금 더 자세히 살펴볼까요?",
      typography_24_0_0:
        "아차, 우리가 당신을 뭐라고 불러드리면 될까요? 원하는 닉네임을 적어주세요.",
      typography_25_0_0:
        "좋아요. 마지막으로 하나만 여쭤요. 디스턴싱은 어디서 처음 알게 되셨나요?",
      typography_26_0_0:
        "좋습니다. 그러면 이제 본격적으로 무기력에서 벗어나기 위한 여정을 시작해볼까요?",
    },
  },
};
