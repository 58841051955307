import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const RISK_DOMAIN = "risk";

export const RISK_DETAIL_ENDPOINT = "detail";
export const RISK_REPORT_ENDPOINT = "report";
export const RISK_SKIP_ENDPOINT = "skip";

export type RiskType = "self_harm_suicide";

export interface RiskDetailType {
  riskType: RiskType;
  taskKeyList: string[];
  detectedCount: number;
  isReportedToMedicalStaff: boolean;
  guideMessageForChat: string;
}

export const riskApi = {
  getDetail: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<RiskDetailType>>(
      `${RISK_DOMAIN}/${RISK_DETAIL_ENDPOINT}`,
      { params: { pid: pid } }
    ),

  report: (pid: string) =>
    axiosInstance.post<void>(
      `${RISK_DOMAIN}/${RISK_REPORT_ENDPOINT}`,
      undefined,
      { params: { pid: pid } }
    ),
  skip: (pid: string) =>
    axiosInstance.post<void>(
      `${RISK_DOMAIN}/${RISK_SKIP_ENDPOINT}`,
      undefined,
      { params: { pid: pid } }
    ),
};
