import { useEffect, useRef, useState } from "react";
import { isDev } from "config";

export default function useSpeechToText() {
  const [isRecording, setIsRecording] = useState(false);
  const websocketRef = useRef<WebSocket | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const sourceRef = useRef<MediaStreamAudioSourceNode | null>(null);
  const processRef = useRef<ScriptProcessorNode | null>(null);
  const [transcription, setTranscription] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const encoding = isSafari ? "LINEAR16" : "OGG_OPUS";
  const sampleRate = isSafari ? 8000 : 16000;
  const timeSlice = isSafari ? 1000 : 400;

  useEffect(() => {
    initializeWebSocket();
    return () => {
      stopRecording();

      if (websocketRef.current) {
        websocketRef.current.close();
      }
      // if (audioContextRef.current) {
      //   audioContextRef.current.close();
      // }
    };
  }, []);

  const initializeWebSocket = () => {
    let wsUri = isDev
      ? "wss://dev-api.distancing.im/stream/stt"
      : "wss://api.distancing.im/stream/stt";
    wsUri += `?encoding=${encoding}&sample_rate=${sampleRate}`;
    console.log(wsUri);
    const websocket = new WebSocket(wsUri);
    websocket.binaryType = "blob";

    websocket.onopen = (event) => {
      setStatus(`Connected to WebSocket server.`);
      // startRecording();
    };

    websocket.onclose = (event) => {
      setStatus(`Disconnected from WebSocket server. ${event.reason}`);
      setIsRecording(false);
    };

    websocket.onmessage = (evt) => {
      try {
        // console.log("Received: " + evt.data);
        const data = JSON.parse(evt.data);

        if (data.isFinal) {
          // setTranscription(
          //   (prev) => prev + ` ${data.alternatives[0].text || ""}`
          // );
          setTranscription(` ${data.alternatives[0].text || ""}`);
        }
      } catch (e) {}
    };

    websocket.onerror = (evt) => {
      setStatus(`WebSocket Error: ${evt}`);
    };

    websocketRef.current = websocket;
  };

  const startRecording = () => {
    if (
      !websocketRef.current ||
      websocketRef.current.readyState != WebSocket.OPEN
    ) {
      initializeWebSocket();
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        if (isSafari) {
          audioContextRef.current = new AudioContext({
            sampleRate: sampleRate,
          });
          sourceRef.current =
            audioContextRef.current.createMediaStreamSource(stream);
          processRef.current = audioContextRef.current.createScriptProcessor(
            8192,
            1,
            1
          );

          processRef.current.onaudioprocess = (e: AudioProcessingEvent) => {
            const data = e.inputBuffer.getChannelData(0);
            const raw = Int16Array.from(data, (x) => x * 32767);
            if (
              websocketRef.current &&
              websocketRef.current.readyState === WebSocket.OPEN
            ) {
              websocketRef.current.send(raw);
            }
          };

          sourceRef.current.connect(processRef.current);
          processRef.current.connect(audioContextRef.current.destination);
        } else {
          const mediaRecorder = new MediaRecorder(stream, {
            audioBitsPerSecond: sampleRate,
          });

          mediaRecorder.ondataavailable = (e) => {
            if (
              websocketRef.current &&
              websocketRef.current.readyState === WebSocket.OPEN
            ) {
              websocketRef.current.send(e.data);
            }
          };

          mediaRecorder.onstop = () => {
            if (websocketRef.current) {
              websocketRef.current.send("EOS");
            }
          };

          mediaRecorder.start(timeSlice); // Send data every N ms
          mediaRecorderRef.current = mediaRecorder;
        }
        setIsRecording(true);
      })
      .catch((err) => {
        console.error("The following error occurred: " + err);
      });
  };

  const stopRecording = () => {
    if (isSafari) {
      if (processRef.current) {
        processRef.current.disconnect();
      }
      if (sourceRef.current) {
        sourceRef.current.disconnect();
        sourceRef.current.mediaStream
          .getTracks()
          .forEach((track) => track.stop());
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    } else {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
        mediaRecorderRef.current = null;
      }
    }
    setIsRecording(false);
  };

  return {
    transcription,
    setTranscription,
    startRecording,
    stopRecording,
    isRecording,
  };
}
