export const translation_4_3 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "우리는 기존에 해왔던 방식 말고, 새로운 방식으로 마음의 평화를 얻을 겁니다.",
      typography_1_0_0:
        "디스턴싱을 체화해 더 유연하고 단단한 내면을 갖는 것이죠.",
      typography_2_0_0: '그러기 위해서는 "생각과 관계를 다시 맺는다"는 것이',
      typography_3_0_0:
        "기존에 우리가 해왔던 방식과 어떻게 다른지 명확하게 이해하고 넘어갈 필요가 있습니다.",
      typography_4_0_0: "관계를 다시 맺는다는 것은",
      typography_5_0_0:
        "디스턴싱은 '생각과의 전쟁'을 더 잘 해낼 수 있도록 돕는 것이 아닙니다.",
      typography_6_0_0:
        "오히려 전쟁이 일어나지 않는 새로운 판을 짤 수 있도록 도울 겁니다.",
      typography_7_0_0:
        "디스턴싱은 마음속에서 부정적인 생각을 없애고 긍정적인 생각으로 가득 채우는 것이 아닙니다.",
      typography_8_0_0:
        "오히려 어떠한 생각이든 생각을 그저 생각으로 바라볼 수 있는 거리감을 만드는 것입니다.",
      typography_9_0_0:
        "디스턴싱은 생각에서 보는 것(Look from thought)이 아닙니다.",
      typography_10_0_0: "생각을 보는 것(Look at thought)입니다.",
      typography_11_0_0:
        "디스턴싱은 생각의 내용을 탐구하며, 그것을 증명하거나 반박하는 것이 아닙니다.",
      typography_12_0_0:
        "오히려 속이 비어 껍데기만 있는 레몬처럼, 생각의 상징적 효과를 무력화시키는 것입니다.",
      typography_13_0_0: "앞으로 우리의 관심은",
      typography_14_0_0: "지금까지 여러분들의 관심은 생각의 내용이었습니다.",
      typography_15_0_0: "부정적인 생각이었고, 더 나은 생각이었습니다.",
      typography_16_0_0: "더 좋은 방향으로 생각하는 법이었습니다.",
      typography_17_0_0: "하지만 진짜 문제는 그것들이 아닙니다.",
      typography_18_0_0: "더 큰 문제는 우리가 생각을 코앞에서 바라보며,",
      typography_19_0_0:
        "이것이 어떻고 저것이 어떻고 하며 고군분투하고 있다는 점입니다.",
      typography_20_0_0:
        "앞으로 우리의 목표는 생각과의 새로운 관계를 맺는 것이라는 점을 꼭 기억하시길 바랍니다.",
      typography_21_0_0:
        "한 걸음 떨어져서 바라보면 전혀 다른 관점을 취할 수 있습니다.",
      typography_22_0_0:
        "마음속에 어떤 생각이 떠오르든 그것에 반응하지 않을 수 있습니다.",
      typography_23_0_0:
        "그 시작은 생각과의 거리가 좁아지는 문제를 인식하는 것입니다.",
      typography_24_0_0:
        "이제 생각과 관계를 다시 맺는 실천적인 훈련들로 넘어가보죠.",
    },
  },
};
