export const translation_4_9_A = {
  en: {},
  ko: {
    v1: {
      "$A$ $B$": "$A$ $B$",
      typography_0_0_0: `지난 활동에서 우리가 계획한 "$A$"을(를) 정리해보면 다음과 같아요.`,
      typography_1_0_0: "그리고 단계별 활동을 이렇게 정해보았죠.",
      typography_2_0_0: `"$A$"을 어떻게 실행하면 좋을지 정해볼까요?`,
      typography_3_0_0: `"$A$"은(는) 언제 하는 게 좋을까요?`,
      typography_4_0_0: `예상되는 어려움 대비하기`,
      typography_5_0_0:
        "자, 본격적으로 실험을 실행하기에 앞서 여러 가지 어려움에 대비하는 시간을 가져볼까요?",
      typography_6_0_0: `지난 활동에서 우리는 "$A$" 상황에서 평소에 하던 행동을 정리해 보았어요. `,
      typography_7_0_0: `안전행동은 부정적인 생각을 일으키는 상황을 회피하려는 무의식적인 행동으로 우리가 하려는 행동 실험에 방해가 될 수 있어요. `,
      typography_8_0_0: `안전행동을 하지 않고 성공적인 행동 실험을 진행하려면 어떻게 해야 할까요?`,
      typography_9_0_0: `예를 들어, "회의 중 사람들 앞에서 내 의견 말해보기" 행동 실험을 하는 사람의 안전행동과 대처방안은 다음과 같을 거예요.`,
      typography_9_1_0:
        "긴장하는 모습을 보여주고 싶지 않아서 아는 내용이어도 모른 척하고 가만히 있기",
      typography_9_2_0: "고개를 들어 사람들이 나를 의식하고 있는지 확인해 보기",
      typography_10_0_0:
        "여러분은 나의 안전행동이 나타날 때 어떻게 대처해 볼 수 있을까요?",
      typography_11_0_0: `좋아요. 안전행동이 나타났을 때 대처방안을 잘 떠올려보고 실행해보아요👍`,
      typography_12_0_0: `안전행동뿐만 아니라 그 밖에도 행동 실험을 할 때 예상되는 어려움이 있을 거예요. `,
      typography_13_0_0: `하지만 우리가 어떻게 대처할 수 있을지 생각해 놓는다면 걱정이 한층 덜어질 거예요.`,
      typography_14_0_0: `"$A$"을(를) 하는 데에 예상되는 어려움이 있을까요?`,
      typography_15_0_0: `만약 실험을 하면서 이 어려움을 마주하면 어떻게 대처할 수 있을까요?`,
      typography_16_0_0: `나의 행동 실험 확인하기`,
      typography_17_0_0: `우리가 계획한 행동 실험을 정리해 보면 다음과 같겠네요. `,
      typography_18_0_0: `이제부터 우리는 이번 활동에서 정한 대로 행동 실험을 진행해 볼 거예요. "$A$"을(를) 실시하기로 한 날짜에 어떻게 실험을 진행하였는지 확인하고 소감을 나눌 수 있도록 활동을 드릴게요.`,
    },
  },
};
