import { Button, Stack } from "@mui/joy";
import { isDev } from "config";
import { useEffect, useRef, useState } from "react";
import streamOfThought from "static/stream_of_thought.png";

export default function AudioButton({
  onAudioEnded,
}: {
  onAudioEnded?: () => void;
}) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [play, setPlay] = useState(false);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const url = `https://media.distancing.im/audio/stream_of_thoughts_5min_ko.mp3`;

  // 재생
  const start = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
    setPlay(true);
  };
  // 일시 정지
  const stop = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    setPlay(false);
  };

  function handleEnded() {
    setPlay(false);
    if (onAudioEnded) {
      onAudioEnded();
    }
  }

  function handleDurationChange() {
    setDuration(parseInt(`${audioRef.current?.duration}`));
  }

  function handleTimeUpdate() {
    setCurrentTime(parseInt(`${audioRef.current?.currentTime}`));
  }
  function handlePause() {
    setPlay(false);
  }

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("durationchange", handleDurationChange);
      audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
      audioRef.current.addEventListener("ended", handleEnded);
      audioRef.current.addEventListener("pause", handlePause);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current?.removeEventListener(
          "durationchange",
          handleDurationChange
        );
        audioRef.current?.removeEventListener("timeupdate", handleTimeUpdate);
        audioRef.current?.removeEventListener("ended", handleEnded);
        audioRef.current?.removeEventListener("pause", handlePause);
      }
    };
  }, []);

  function parseSecondsToFormat(seconds: number) {
    return `${Math.floor(seconds / 60)}:${String(seconds % 60).padStart(
      2,
      "0"
    )}`;
  }
  return (
    <Stack
      sx={{
        width: "100%",
      }}
      alignItems="center"
      spacing="20px"
    >
      <img src={streamOfThought} alt="stream_of_thought" />
      <div className={`${!isDev && "hidden"}`}>
        <audio ref={audioRef} src={url} controls></audio>
      </div>

      <Button
        size="lg"
        sx={{ width: "100%" }}
        onClick={(event) => {
          if (play) {
            stop();
          } else {
            start();
          }
        }}
        variant={play ? "soft" : "solid"}
      >
        {play
          ? `${parseSecondsToFormat(currentTime)} / ${parseSecondsToFormat(
              duration
            )}`
          : currentTime === 0 || currentTime === duration
          ? "생각의 강 시작하기"
          : "이어서 재생하기"}
      </Button>
    </Stack>
  );
}
