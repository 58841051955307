import { ButtonGroupMultipleChoice } from "data/CellComponent";
import {
  Instruction,
  InstructionWithContent,
  InstructionWithImage,
  Header1,
} from "../../BlockComponent";
import { LastCard } from "../common";

const NotificationTimeBlock = InstructionWithContent("typography_42_0_0", [
  [
    ButtonGroupMultipleChoice(
      [
        {
          translationKey: "오전 7시",
        },
        {
          translationKey: "오전 10시",
        },
        {
          translationKey: "오후 1시",
        },
        {
          translationKey: "오후 4시",
        },
        {
          translationKey: "오후 7시",
        },
        {
          translationKey: "오후 10시",
        },
      ],
      { id: "notification_time" }
    ),
  ],
]);

export const Content_101_1_2_A = (pushNotificationGranted?: boolean) => {
  return [
    Instruction("typography_0_0_0"),
    Instruction("typography_1_0_0"),
    Instruction("typography_2_0_0"),
    Instruction("typography_3_0_0"),
    Instruction("typography_4_0_0"),
    Instruction(`typography_5_0_0`),
    Instruction("typography_6_0_0"),
    Instruction("typography_7_0_0"),
    Instruction("typography_8_0_0"),
    Instruction("typography_9_0_0"),
    Instruction("typography_10_0_0"),

    Header1("typography_11_0_0"),
    Instruction(`typography_12_0_0`),
    Instruction("typography_13_0_0"),
    Instruction("typography_14_0_0"),
    Instruction("typography_15_0_0"),
    Instruction("typography_16_0_0"),
    Instruction("typography_17_0_0"),
    Instruction("typography_18_0_0"),
    Instruction("typography_19_0_0"),
    Instruction("typography_20_0_0"),
    InstructionWithImage("typography_21_0_0", "0"),
    Instruction("typography_22_0_0"),
    Instruction(`typography_23_0_0`),
    Instruction(`typography_24_0_0`),
    Instruction(`typography_25_0_0`),

    Header1("typography_26_0_0"),
    InstructionWithImage("typography_27_0_0", "1"),
    Instruction(`typography_28_0_0`),
    Instruction(`typography_29_0_0`),
    Instruction("typography_30_0_0"),
    Instruction("typography_31_0_0"),
    Instruction("typography_32_0_0"),

    Header1("typography_33_0_0"),
    Instruction("typography_34_0_0"),
    Instruction("typography_35_0_0"),
    Instruction("typography_36_0_0"),
    Instruction("typography_37_0_0"),
    Instruction("typography_38_0_0"),
    Instruction("typography_39_0_0"),
    Instruction("typography_40_0_0"),
    ...(!pushNotificationGranted
      ? [
          InstructionWithContent("typography_41_0_0", [
            [
              {
                type: "pushNotification",
                content: { showFromIdIfDenied: "알림 미허용" },
              },
            ],
          ]),
          NotificationTimeBlock,
        ]
      : [NotificationTimeBlock]),

    Instruction("typography_43_0_0", {
      blockId: "알림 미허용",
    }),
    Instruction("typography_44_0_0"),
    Instruction("typography_45_0_0"),
    ...LastCard,
  ];
};
