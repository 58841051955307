import { Instruction, ProgramContentType } from "../../BlockComponent";
import { selfCheckTemplate } from "./2-3";
import { LastCard } from "../common";

export const Content_2_4: ProgramContentType[] = [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  Instruction("typography_2_0_0"),
  ...selfCheckTemplate,

  ...LastCard,
];