import { useSearchParams } from "react-router-dom";
import useFetch from "hooks/useFetch";
import { useEffect } from "react";
import DefaultPage from "pages/container/DefaultPage";
import { CircularProgress, Stack } from "@mui/joy";
import { sendMessage } from "utils/webview";
import { useRecoilValue } from "recoil";
import { permanentDataAtom } from "recoil/permanentDataAtom";

export default function PaymentSuccess() {
  const [searchParams] = useSearchParams();

  const orderId = searchParams.get("orderId");
  const paymentKey = searchParams.get("paymentKey");
  const payAmount = Number(searchParams.get("amount"));

  useEffect(() => {
    if (orderId && payAmount && paymentKey) {
      paymentSuccess();
    }
  }, [orderId, payAmount, paymentKey]);

  const [paymentSuccessData, paymentSuccess] = useFetch({
    url: `/payment/success`,
    requestBody: JSON.stringify({
      orderId: orderId,
      paymentKey: paymentKey,
      payAmount: payAmount,
    }),
  });
  const permanentDataState = useRecoilValue(permanentDataAtom);
  const isInappWebview = permanentDataState?.isWebview;
  useEffect(() => {
    if (paymentSuccessData) {
      if (isInappWebview) {
        sendMessage(
          JSON.stringify({
            event: "close_modal",
          })
        );
      } else {
      }
    }
  }, [paymentSuccessData]);

  return (
    <DefaultPage
      content={
        <Stack alignItems={"center"}>
          <CircularProgress color="neutral" />
        </Stack>
      }
    />
  );
}
