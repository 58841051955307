export const thinkingTrapResource = {
  en: {
    "책임 과다": "Extra Blame",
    "근거 부족": "Little Evidence",
    "부정 예측": "Negative Bias",
    "부정 편향": "Negative Bias",
    평가: "Judgment",
    개념화: "Conceptualization",
    반추: "Rumination",

    "책임 과다 - 나의 기여를 과장함":
      "Extra Blame - Overestimating my contribution",
    "근거 부족 - 부족한 근거로 추론함":
      "Little Evidence - Guessing without sufficient reasons",
    "부정 편향 - 나쁜 전개만을 예상함":
      "Negative Bias - Expecting only the bad and worst",
    "평가 - 가치 판단을 내림": "Judgment - Deciding what's good or bad",
    "개념화 - 이유를 대며 정당화함":
      "Conceptualization - Finding reasons to justify",
    "반추 - 과거나 미래를 곱씹음":
      "Rumination - Stuck on past or future thoughts",
  },
  ko: {
    "책임 과다": "책임 과다",
    "근거 부족": "근거 부족",
    "부정 예측": "부정 예측",
    "부정 편향": "부정 편향",
    평가: "평가",
    개념화: "개념화",
    반추: "반추",

    "책임 과다 - 나의 기여를 과장함": "책임 과다 - 나의 기여를 과장함",
    "근거 부족 - 부족한 근거로 추론함": "근거 부족 - 부족한 근거로 추론함",
    "부정 편향 - 나쁜 전개만을 예상함": "부정 편향 - 나쁜 전개만을 예상함",
    "평가 - 가치 판단을 내림": "평가 - 가치 판단을 내림",
    "개념화 - 이유를 대며 정당화함": "개념화 - 이유를 대며 정당화함",
    "반추 - 과거나 미래를 곱씹음": "반추 - 과거나 미래를 곱씹음",
  },
};
