export const translation_4_1_A = {
  en: {
    v1: {
      typography_0_0_0:
        "By now, you might have identified an automatic thought that suddenly pops up and troubles you.",
      typography_1_0_0:
        "We're going to practice distancing ourselves from that thought.",
      typography_2_0_0: "Here's how we do it:",
      typography_3_0_0: "First, notice the thoughts that come to your mind.",
      typography_4_0_0:
        "This means observing your thoughts without overreacting to them, just watching them as they appear.",
      typography_5_0_0:
        "Since it's not easy at first, we've been identifying and organizing automatic thoughts together. But gradually, you'll learn to do this on your own.",
      typography_6_0_0:
        "Second, instead of trying to push the thought away, step back and examine it.",
      typography_7_0_0: "We'll practice this together starting now.",
      typography_8_0_0:
        "Lastly, the third step is about not reacting to thoughts that are inaccurate or unhelpful and shifting back towards life directions that align with your values.",
      typography_9_0_0:
        "We'll start this once you're comfortable with the first two steps.",
      typography_10_0_0:
        "Now, shall we practice the second step, observing thoughts without pushing them away?",
      typography_11_0_0: "Treating thoughts as a third entity",
      typography_12_0_0:
        "From now on, we'll practice treating thoughts as if they're a third entity.",
      typography_13_0_0:
        "Thoughts are not 'you'. Just think of them as objects that pop up in your mind.",
      typography_14_0_0:
        "Imagine how your hair looks messy in the morning depending on how you slept.",
      typography_15_0_0:
        "But that hairstyle isn't 'you', right? Similarly, we'll consider thoughts as related but not the essence of ourselves.",
      typography_16_0_0:
        "Before treating thoughts as a third entity, let's do a symbolic activity.",
      typography_17_0_0: "Here we have a automatic thought we've gathered.",
      typography_18_0_0:
        "Imagine placing these thoughts not right in front of you, but about a meter away on the floor.",
      typography_19_0_0:
        "Now, give your automatic thought a name or nickname. For example:",
      typography_20_0_0:
        "James named his gloomy and negative thoughts <Dark Cloud>.",
      typography_21_0_0: "Carol called her sudden anxiety <Ninja>.",
      typography_22_0_0:
        "What name or nickname would you give your automatic thought?",
      typography_23_0_0: "Why did you choose that name or nickname?",
      typography_24_0_0:
        "Great. If your named thought had a color, what would it be?",
      typography_25_0_0: "If it had a size, how big would it be?",
      typography_26_0_0: "What shape would it be?",
      typography_27_0_0: "How strong is it?",
      typography_28_0_0: "How fast is it?",
      typography_29_0_0: "What's its surface texture like?",
      typography_30_0_0:
        "Now, imagine the thought with these characteristics lying a meter away from you.",
      typography_31_0_0: "What are you thinking as you look at it?",
      typography_32_0_0:
        "Great job. We just practiced viewing a thought as an object. You were thinking about your thought.",
      typography_33_0_0:
        "We'll keep practicing this perspective, though the methods may vary. Remember this sense of distance.",
      typography_34_0_0: "Types of thinking traps",
      typography_35_0_0:
        "We won't always be looking at thoughts' colors, sizes, or shapes. That was just a warm-up exercise.",
      typography_36_0_0:
        "Moving forward, we'll examine thoughts through a more refined framework called 'thinking traps'.",
      typography_37_0_0:
        "Think of a thinking trap as a pitfall our minds easily fall into.",
      typography_38_0_0:
        "When trapped, we react strongly to the thought, unable to distance ourselves from it.",
      typography_39_0_0: "There are two types of thinking traps,",
      typography_40_0_0: "each with three subtypes, making six in total.",
      typography_41_0_0:
        "Each operates differently but all cause mental issues.",
      typography_42_0_0:
        "We'll explore these thinking traps as we encounter them.",
      typography_43_0_0: "Looking at thoughts",
      typography_44_0_0:
        "Distancing from thoughts is often described as 'looking at a thought'.",
      typography_45_0_0:
        "Conversely, being strongly influenced by and unable to distance from thoughts is 'looking from a thought'.",
      typography_46_0_0:
        "We'll learn the importance of looking at thoughts, not from them.",
      typography_47_0_0:
        "And if there's a thinking trap, we'll learn not to react to it, steering life towards more helpful directions.",
      typography_48_0_0:
        "We'll treat thoughts as a third entity. In the process, you'll discover that there's 'you', separate from 'your thoughts', observing them.",
      typography_49_0_0: "Shall we start observing our thoughts now?",
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "아마 지금쯤이면 내 마음속에서 '탁'하고 튀어올라 나를 괴롭히는 생각, 자동적 사고를 하나 찾으셨을 거예요.",
      typography_1_0_0: "지금부터는 그 생각과 거리를 두는 연습을 해볼 거예요.",
      typography_2_0_0: "생각으로부터 거리를 두는 과정은 다음과 같이 진행돼요.",
      typography_3_0_0:
        "첫 번째는 마음속에 떠오르는 생각을 알아차리는 것이에요.",
      typography_4_0_0:
        "즉, 마음속에 어떤 생각에 떠올랐을 때 그 생각에 과도하게 반응하지 않고, 그저 그 생각을 바라보는 것이죠.",
      typography_5_0_0:
        "처음부터 그렇게 하기는 쉽지 않기 때문에 지금은 자동적 사고를 함께 찾아 정리해 보았답니다. 하지만 점차 스스로도 할 수 있도록 연습할 거예요.",
      typography_6_0_0:
        "두 번째는 그 생각을 몰아내려고 하지 않고 한 걸음 떨어져서 그 생각을 살펴보는 거예요.",
      typography_7_0_0: "이 과정은 지금부터 함께 연습해 볼 거랍니다.",
      typography_8_0_0:
        "마지막 세 번째는 마음속에 떠오른 생각에 과하게 반응하지 않고, 다시 나의 가치에 맞는 방향으로 삶의 방향성을 전환하는 것이에요.",
      typography_9_0_0:
        "이 과정은 첫 번째, 두 번째 과정이 익숙해지면 시작할 거랍니다.",
      typography_10_0_0:
        "자, 우선은 두 번째 과정, 즉, 생각을 몰아내려고 하지 않고 한 걸음 떨어져서 그 생각을 살펴보는 연습을 해볼까요?",
      typography_11_0_0: "생각을 제3의 대상처럼",
      typography_12_0_0:
        "우리는 지금부터 생각을 제3의 대상처럼 다루는 연습을 할 거예요.",
      typography_13_0_0:
        "생각은 '나' 자신이 아니랍니다. 그냥 마음속에 떠오른 하나의 대상처럼 여겨보는 거예요.",
      typography_14_0_0:
        "아침에 자고 일어나면 지난밤 내가 어떻게 뒤척였는지에 따라 머리 스타일이 뒤죽박죽이었던 것을 떠올려보세요.",
      typography_15_0_0:
        "하지만 그 머리 스타일은 '나' 자신은 아닐 거예요. 지금 여기서는 생각도 머리 스타일과 마찬가지로 '나와 관련은 있지만 그렇다고 나 자신은 아닌' 대상처럼 생각해보도록 하죠.",
      typography_16_0_0:
        "생각을 제3의 대상처럼 다루기 전에 한 가지 상징적인 활동을 해볼게요.",
      typography_17_0_0: "여기 우리가 함께 정리한 자동적 사고가 있습니다.",
      typography_18_0_0:
        "위에 적힌 생각을 나의 코앞이 아니라 1m 정도 떨어진 바닥에 놓고 이를 바라본다고 상상해보세요.",
      typography_19_0_0:
        "자, 지금부터 이 생각에 이름이나 별명을 지어줄 겁니다. 예를 들면 이렇게요.",
      typography_20_0_0:
        "제임스는 우울하고 부정적인 자신의 생각을 떠올리며 <먹구름>이라는 이름을 붙였습니다.",
      typography_21_0_0:
        "케롤는 갑자기 찾아오는 자신의 불안감을 떠올리며 <닌자>라는 호칭을 붙였습니다.",
      typography_22_0_0:
        "자, 그럼 나의 자동적 사고에는 어떤 이름이나 별명을 붙여줄까요?",
      typography_23_0_0: "그런 이름이나 별명을 지은 이유는 무엇인가요?",
      typography_24_0_0: "좋습니다. 그것에 색깔이 있다면, 어떤 색깔인가요?",
      typography_25_0_0: "그것에 크기가 있다면, 얼마나 큰가요?",
      typography_26_0_0: "그것에 모양이 있다면, 어떤 모양인가요?",
      typography_27_0_0: "그것에 힘이 있다면, 그 힘은 어느 정도인가요?",
      typography_28_0_0: "그것에 속도가 있다면, 얼마나 빠른가요?",
      typography_29_0_0: "그것에 표면 질감이 있다면, 무엇처럼 느껴지는가요?",
      typography_30_0_0:
        "이제 위와 같은 특성을 가진 생각과 나 사이에 1m 정도 거리가 떨어져 있는 모습을 상상해보세요.",
      typography_31_0_0: "그것을 바라보는 나는 어떤 생각을 하고 있나요?",
      typography_32_0_0:
        "좋습니다. 우리는 방금 생각을 하나 대상처럼 여기는 활동을 해보았습니다. 당신은 '생각에 대한 생각'을 하고 있었죠.",
      typography_33_0_0:
        "앞으로도 '생각에 대한 생각'을 하는 연습을 해 볼 텐데요. 방식은 다르겠지만 생각을 대하는 관점 자체는 방금과 동일하니, 그 거리감을 잘 기억해두세요.",
      typography_34_0_0: "생각함정의 종류",
      typography_35_0_0:
        "우리가 앞으로도 계속 생각에 대해 앞으로도 색깔, 크기, 모양을 살펴볼 건 아니랍니다. 이건 간단한 몸풀기 연습이었어요.",
      typography_36_0_0:
        "앞으로 우린 '생각 함정'이라는 조금 더 정교한 틀을 토대로 생각을 살펴볼 거예요.",
      typography_37_0_0:
        "생각 함정은 우리 마음이 쉽게 걸려드는 함정이라고 생각하시면 돼요.",
      typography_38_0_0:
        "함정에 빠진 우리는 그 생각에 강하게 반응해버리며, 그것과 거리를 둘 생각을 전혀 하지 못하게 돼요.",
      typography_39_0_0: "생각함정에는 두 가지 유형이 있고,",
      typography_40_0_0:
        "각 유형당 세 가지씩해서, 총 여섯 가지의 생각함정이 있답니다.",
      typography_39:
        "생각 함정에 해당하는 생각들은 정확하지 않을 수도 있고, 때론 정확하지만 그다지 도움되지 않을 수도 있답니다.",
      typography_41_0_0:
        "각각이 작동하는 방식은 다르지만 모두 동일하게 마음의 문제를 일으키죠.",
      typography_42_0_0:
        "앞으로 생각함정이 하나씩 발견될 때마다 함께 살펴봐요.",
      typography_43_0_0: "생각을 보기",
      typography_44_0_0:
        "생각으로부터 거리 두는 일을 흔히 '생각을 보기'라고 설명하곤 해요.",
      typography_45_0_0:
        "반대로 생각으로부터 거리를 두지 못하고 생각에 영향을 강하게 받는 것을 '생각에서 보기'라고 하죠.",
      typography_46_0_0:
        "우리는 앞으로 생각에서 보는 것이 아니라 생각을 보는 것이 중요하다는 것을 배워나갈 거예요.",
      typography_47_0_0:
        "그리고 그 생각에 생각 함정이 있다면 그에 반응하지 않고 더 나에게 도움이 되는 방향으로 삶을 이끌 수 있도록 할 거예요.",
      typography_48_0_0:
        "우리는 생각을 제3의 대상처럼 다루게 될 거랍니다. 그 과정에서 '생각'과 별개로 '생각을 관찰하는 나'가 존재한다는 걸 발견하게 될 거예요.",
      typography_49_0_0: "자, 그러면 이제 생각 관찰을 시작해볼까요?",
    },
  },
};
