import { automaticThoughtIdType } from "api/conceptApi";
import {
  CoachTextarea,
  CoachTextareaLine,
  DividerLine,
  LineWithSolidLabel,
  SolidLabelLine,
  TextareaLine,
  TypographyFromDataLine,
} from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithCoachTextarea,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithContent,
  InstructionWithDataWithTextarea,
  InstructionWithTextarea,
} from "../../BlockComponent";
import { AutomaticThought, LastCard } from "../common";

export const Content_4_8_A = (automaticThoughtId: automaticThoughtIdType) => [
  Header1("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  Instruction("typography_2_0_0"),
  InstructionWithContent(
    `typography_3_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),
  InstructionWithContent("typography_4_0_0", [
    ...LineWithSolidLabel(
      "가정",
      CoachTextareaLine({
        id: "가정",
        placeholder: `'~한다면'의 형태로 적어주세요`,
      })
    ),
    ...LineWithSolidLabel(
      "결과",
      CoachTextareaLine({
        id: "결과",
        placeholder: `'~할 것이다'의 형태로 적어주세요`,
      })
    ),
  ]),
  InstructionWithContent(
    "typography_5_0_0",
    LineWithSolidLabel(
      "가설",
      TypographyFromDataLine(
        { A: { id: "가정" }, B: { id: "결과" } },
        "$A$ $B$",
        { id: "가설" }
      )
    )
  ),
  InstructionWithCoachTextarea(`typography_6_0_0`, {
    placeholder: `실험의 제목을 간략하게 별명처럼 적어주세요`,
    id: "제목",
  }),
  InstructionWithData({ A: { id: "제목" } }, "typography_7_0_0"),
  InstructionWithDataWithTextarea({ A: { id: "제목" } }, "typography_8_0_0", {
    id: "안전행동",
  }),
  Instruction("typography_9_0_0"),
  InstructionWithTextarea("typography_10_0_0", { id: "실험행동" }),
  InstructionWithData({ A: { id: "실험행동" } }, `typography_11_0_0`),
  InstructionWithDataWithContent(
    { A: { id: "가설" }, B: { id: "실험행동" } },
    "typography_12_0_0",
    [
      ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
        SolidLabelLine(`결과`, { labelIndex: each }),
        TextareaLine({ id: `가설 일치 예상 ${each}` }),
      ]),
    ]
  ),
  InstructionWithDataWithContent(
    { A: { id: "가설" }, B: { id: "실험행동" } },
    "typography_13_0_0",
    [
      ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
        SolidLabelLine(`결과`, { labelIndex: each }),
        TextareaLine({ id: `가설 기각 예상 ${each}` }),
      ]),
    ]
  ),
  Instruction("typography_14_0_0"),

  Header1("typography_15_0_0"),
  InstructionWithDataWithContent({ A: { id: "제목" } }, "typography_16_0_0", [
    ...LineWithSolidLabel(
      "실험 제목",
      TypographyFromDataLine({ A: { id: "제목" } }, "$A$")
    ),

    ...LineWithSolidLabel(
      "실험 가설",
      TypographyFromDataLine({ A: { id: "가설" } }, "$A$")
    ),

    ...LineWithSolidLabel(
      "실험 내용",
      TypographyFromDataLine({ A: { id: "실험행동" } }, "$A$")
    ),

    ...[
      SolidLabelLine(`가설이 맞다면`),
      ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
        TypographyFromDataLine({ A: { id: `가설 일치 예상 ${each}` } }, "$A$"),
      ]),
    ],

    ...[
      SolidLabelLine(`가설이 틀렸다면`),
      ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
        TypographyFromDataLine({ A: { id: `가설 기각 예상 ${each}` } }, "$A$"),
      ]),
    ],
  ]),
  Instruction("typography_17_0_0"),
  InstructionWithData({ A: { id: "실험행동" } }, "typography_18_0_0"),
  Instruction("typography_19_0_0"),
  InstructionWithDataWithContent(
    { A: { id: "실험행동" } },
    "typography_20_0_0",
    [
      ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((each) => [
        SolidLabelLine(`단계`, { prefixLabelIndex: each }),
        TextareaLine({ id: `step_${each}`, optional: each > 3 }),
      ]),
    ]
  ),
  InstructionWithData({ A: { id: "실험행동" } }, "typography_21_0_0"),
  Instruction("typography_22_0_0"),
  ...LastCard,
];
