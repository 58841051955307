import { automaticThoughtIdType } from "api/conceptApi";
import { Percentage } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithContent,
  InstructionWithDataWithTextarea,
  InstructionWithImage,
  InstructionWithTextarea,
} from "../../BlockComponent";
import { AutomaticThought, LastCard } from "../common";

export type thoughtTrapMappingKey =
  | "4-5-1-A"
  | "4-5-2-A"
  | "4-5-3-A"
  | "4-5-4-A"
  | "4-5-5-A"
  | "4-5-6-A";
export const thoughtTrapMapping = {
  "4-5-1-A": "책임 과다 - 나의 기여를 과장함",
  "4-5-2-A": "근거 부족 - 부족한 근거로 추론함",
  "4-5-3-A": "부정 편향 - 나쁜 전개만을 예상함",
  "4-5-4-A": "평가 - 가치 판단을 내림",
  "4-5-5-A": "개념화 - 이유를 대며 정당화함",
  "4-5-6-A": "반추 - 과거나 미래를 곱씹음",
};

export const thoughtTrapList = Object.values(thoughtTrapMapping);

export const Content_4_5_A_start = (
  automaticThoughtId: automaticThoughtIdType
) => [
  Instruction("start_typography_0_0_0"),
  Instruction("start_typography_1_0_0"),

  InstructionWithContent(
    `start_typography_2_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),
  Instruction("start_typography_3_0_0"),
  InstructionWithImage(`start_typography_4_0_0`, "0.png"),
  InstructionWithImage(`start_typography_5_0_0`, "1.png"),
  Instruction("start_typography_6_0_0"),
  Instruction("start_typography_7_0_0"),
];

export const Content_4_5_A_end = (
  automaticThoughtId: automaticThoughtIdType,
  thoughtTrapId: number
) => [
  Header1(`end_typography_0_0_0`),
  InstructionWithContent(`end_typography_1_0_0`, [
    ...AutomaticThought({ id: automaticThoughtId }),
    [
      {
        type: "temp",
        content: {
          id: `생각함정`,
          translationKey: `${
            thoughtTrapMapping[
              `4-5-${thoughtTrapId}-A` as thoughtTrapMappingKey
            ]
          }`,
        },
      },
    ],
  ]),

  InstructionWithData(
    { A: { id: "생각함정" } },
    "end_typographyFromData_2_0_0"
  ),
  InstructionWithTextarea(`end_typography_3_0_0`),
  Instruction("end_typography_3"),
  Instruction("end_typography_3_1"),
  Instruction("end_typography_3_2"),
  Instruction("end_typography_3_3"),
  Instruction("end_typography_3_4"),
  Instruction("end_typography_3_5"),
  Instruction("end_typography_3_6"),
  Instruction("end_typography_3_7"),

  Instruction(`end_typography_4_0_0`),
  Instruction(`end_typography_5_0_0`),
  Instruction(`end_typography_6_0_0`),
  // Instruction(`end_typography_7_0_0`),
  Instruction(`end_typography_7`),
  Instruction(`end_typography_8`),

  InstructionWithContent(
    `end_typography_8_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),

  InstructionWithDataWithContent(
    { A: { taskId: `4-2-A_${automaticThoughtId}`, id: "영향받는 정도" } },
    "end_typographyFromData_9_0_0",
    [[Percentage({ id: "영향받는 정도" })]]
  ),
  InstructionWithData(
    { A: { id: "생각함정" } },
    "end_typographyFromData_10_0_0"
  ),
  Instruction(`end_typography_10`),
  Instruction(`end_typography_11_0_0`),
  Instruction(`end_typography_12_0_0`),
  Instruction(`end_typography_13_0_0`),
  ...LastCard,
];

export const Content_4_5_A_end_type2 = (
  automaticThoughtId: automaticThoughtIdType,
  thoughtTrapId: number
) => [
  Header1(`end_typography_0_0_0`),
  InstructionWithContent(`end_typography_1_0_0`, [
    ...AutomaticThought({ id: automaticThoughtId }),
    [
      {
        type: "temp",
        content: {
          id: `생각함정`,
          translationKey: `${
            thoughtTrapMapping[
              `4-5-${thoughtTrapId}-A` as thoughtTrapMappingKey
            ]
          }`,
        },
      },
    ],
  ]),

  InstructionWithData(
    { A: { id: "생각함정" } },
    "end_typographyFromData_2_0_0"
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "생각" } },
    "end_typography_2_0_0_3"
  ),
  Instruction("end_typography_2_0_0_4"),
  // InstructionWithTextarea(`end_typography_3_0_0`),

  Instruction(`end_typography_4_0_0`),
  Instruction(`end_typography_5_0_0`),
  Instruction(`end_typography_6_0_0`),
  Instruction(`end_typography_7_0_0`),

  InstructionWithContent(
    `end_typography_8_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),

  InstructionWithDataWithContent(
    { A: { taskId: `4-2-A_${automaticThoughtId}`, id: "영향받는 정도" } },
    "end_typographyFromData_9_0_0",
    [[Percentage({ id: "영향받는 정도" })]]
  ),
  InstructionWithData(
    { A: { id: "생각함정" } },
    "end_typographyFromData_10_0_0"
  ),

  Instruction(`end_typography_11_0_0`),
  Instruction(`end_typography_12_0_0`),
  Instruction(`end_typography_13_0_0`),
  ...LastCard,
];
