export const translation_101_1_2_A = {
  en: {},
  ko: {
    v1: {
      "오전 7시": "오전 7시",
      "오전 10시": "오전 10시",
      "오후 1시": "오후 1시",
      "오후 4시": "오후 4시",
      "오후 7시": "오후 7시",
      "오후 10시": "오후 10시",
      typography_0_0_0:
        "앞으로 우리의 일상에서 나타나는 ‘부정 강화 행동'을 찾아내 이를 바꾸는 연습을 할 거예요.",
      typography_1_0_0:
        "또한 반대로 ‘긍정 강화 행동'도 찾아내 조금씩 늘려가는 연습도 할 거랍니다.",
      typography_2_0_0:
        "그러기 위해서는 우리 삶에서 어떤 일이 벌어지고 있는지 이해할 필요가 있어요.",
      typography_3_0_0:
        "그래서 오늘부터 <활동기록지>에 나의 일상 속 행동들을 기록해 나갈 거예요.",
      typography_4_0_0: "방법은 간단하답니다. 날짜와 시간을 적고,",
      typography_5_0_0:
        "어떤 활동을 했는지, 그때 어떻게 느끼고 생각했는지 적은 후,",
      typography_6_0_0: "마지막으로 당시 기분을 점수로 기록하면 돼요.",
      typography_7_0_0:
        "우리는 이 기록을 분석하며 ‘부정 강화 행동'과 ‘긍정 강화 행동'을 찾고 행동 변화를 만들어낼 거예요.",
      typography_8_0_0: "기록 자체는 그다지 어렵지 않을 거랍니다.",
      typography_9_0_0:
        "그보다 더 중요한 건 <활동기록지>의 목적에 대해 이해하는 것인데요.",
      typography_10_0_0:
        "<활동기록지>를 작성할 땐 다음과 같은 부분을 꼭 기억해 주세요.",
      typography_11_0_0: "첫째, 이차적인 문제가 우선",
      typography_12_0_0:
        "<활동기록지>를 적자고 이야기하면 많은 사람들이 한두 번 적고는 이를 시시하게 여기며 다음과 같이 말합니다.",
      typography_13_0_0:
        "“당신은 제 무기력을 이해하지 못하는 것 같군요. 저의 무기력에서 ‘주된 문제'는 이런 가벼운 것들이 아니란 말입니다.”",
      typography_14_0_0:
        "맞는 말이에요. 당신의 무기력은 분명 삶을 흔든 어떠한 주된 문제들로 인해 발생했을 거예요.",
      typography_15_0_0:
        "일상생활의 어려움이든, 지속적인 작은 스트레스든, 혹은 중대한 삶의 변화든 간에 말이에요.",
      typography_16_0_0:
        "지금 나에게 중요한 건 ‘가족 간의 불화’, ‘최근의 대인관계 갈등’, ‘과거의 상처' 같은 것들이라고 생각할 거예요.",
      typography_17_0_0:
        "맞아요. 그 문제는 분명 당신에게 무기력이 찾아온 중요한 원인이죠.",
      typography_18_0_0:
        "하지만 우리가 당신을 무기력에서 빠져나오도록 만들기 위해 초점을 맞추는 부분은 ‘이차적인 문제'예요.",
      typography_19_0_0:
        "삶을 살아가다 보면 한 번쯤은 '주된 문제'를 마주하기 마련이에요.",
      typography_20_0_0:
        "하지만 이후 사람들이 우울과 무기력의 늪에 빠지는 건 주된 문제에 대해 대처하는 방식 때문이랍니다",
      typography_21_0_0:
        "즉, 부정 강화 행동을 통해 부정적인 상태를 벗어나고자 했던 노력이 오히려 우울과 무기력을 만들며 악순환이 발생하는 것이죠.",
      typography_22_0_0: "이 이차적인 문제는 무기력을 증폭시키고 지속시키죠.",
      typography_23_0_0:
        "우울감과 무기력에 빠져 있을 때에는 주된 문제를 해결하기 쉽지 않아요.",
      typography_24_0_0:
        "다시 말해, 우울감과 무기력에 빠져있는 상태에서 주된 문제를 해결하려고 접근하면 실패할 가능성이 높죠.",
      typography_25_0_0:
        "즉, 우리의 이차적인 문제인 '우울감과 무기력'을 해결한 후 주된 문제에 접근하는 편이 더 빠르고 쉽답니다.",
      typography_26_0_0: "둘째, 문제와 나를 분리",
      typography_27_0_0:
        "<활동기록지>를 작성할 때에는 나 자신과 거리를 두고, 마치 관찰자가 되어 자신을 관찰하는 자세로 임하는 것이 중요해요.",
      typography_28_0_0:
        "반복적으로 이야기하지만 지금 내가 무기력을 느끼는 데에는 다 이유가 있답니다.",
      typography_29_0_0: "어떤 행동들이 무기력을 강화하고 있기 때문이죠.",
      typography_30_0_0:
        "따라서 <활동기록지>는 그것이 무엇인지 객관적으로 파악하기 위해 중요한 도구예요.",
      typography_31_0_0:
        "지금 내가 겪는 무기력은 나에게 어떤 문제가 있어서가 아니라, 나에게 어떤 강력한 심리학적 요인이 작용하고 있기 때문이에요.",
      typography_32_0_0:
        "그러니 <활동기록지>를 쓸 때에는 무기력한 자신에게 집중하지 말고, 한 걸음 떨어져서 어떤 일이 벌어졌는지 관찰하는 게 중요하답니다.",
      typography_33_0_0: "셋째, 자주 적을수록 좋다",
      typography_34_0_0: "<활동기록지>는 자주 적을수록 좋아요.",
      typography_35_0_0:
        "자주 적을수록 우리가 분석하여 활용할 수 있는 행동 목록들이 많아지니까요.",
      typography_36_0_0:
        "기본 원칙은 제법 기분이 좋거나 나쁜 일이 생겼을 때에 기록하는 것이에요.",
      typography_37_0_0:
        "조금 더 여유가 있다면 3시간에 한 번은 접속하여 지난 시간 동안 어떤 일들이 있었는지 기록해보면 좋아요.",
      typography_38_0_0:
        "이상적으로는 매시간 들어와서 기록하며 자신의 일상을 면밀히 관찰하는 것이에요.",
      typography_39_0_0:
        "자주 적을수록 스스로에 대해 더 많은 걸 알게 될 거예요.",
      typography_40_0_0: "잊지 않고 적을 수 있도록 푸시 알림으로 알려드릴게요.",
      typography_41_0_0: "알림을 허용해주세요.",
      typography_42_0_0:
        "하루 중 언제 알려드리면 좋을까요? 원하는 시간을 모두 선택해주세요.",
      typography_43_0_0: "이제 <활동기록지>를 작성하러 가볼게요.",
      typography_44_0_0:
        "활동기록지를 최소 한 번 이상 작성한 경우에는 무기력을 극복할 확률이 12%나 높아진답니다.",
      typography_45_0_0: "자, 그러면 이제 기록해 볼까요?",
    },
  },
};
