import { Sheet } from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";

export default function CustomCard({
  transparent,
  noBorder,
  children,
  sx,
}: {
  transparent?: boolean;
  noBorder?: boolean;
  children: React.ReactNode;
  sx?: SxProps;
}) {
  return (
    <Sheet
      sx={{
        backgroundColor: transparent ? "transparent" : "card.background",
        borderWidth: noBorder ? 0 : "1px",
        borderTopColor: "card.lightStroke",
        borderLeftColor: "card.lightStroke",
        borderRightColor: "background.level3",
        borderBottomColor: "background.level3",
        ...sx,
      }}
      className={`relative w-full flex flex-col`}
    >
      {children}
    </Sheet>
  );
}
