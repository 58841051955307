import {
  Box,
  ListItemButton,
  ListItemContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/joy";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import dayjs from "dayjs";
import * as _ from "lodash";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { ThoughtRecordType } from "api/thoughtRecordApi";
import { memo } from "react";

function ThoughtRecordListItem({
  width,
  direction = "column",
  isFirstRecordOfMonth,
  thoughtRecord,
  isSelected,
  setCurrentThoughtRecord,
  shouldBeDone,
}: {
  width: string;
  direction?: "row" | "column";
  isFirstRecordOfMonth: boolean;
  thoughtRecord: ThoughtRecordType;
  isSelected?: boolean;
  setCurrentThoughtRecord: React.Dispatch<
    React.SetStateAction<ThoughtRecordType | undefined>
  >;
  shouldBeDone?: boolean;
}) {
  const {
    thoughtRecordKey,
    date,
    situation,
    isHighlight,
    hasAiRecommendation,
  } = thoughtRecord;

  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";
  const isManual = isCoach && user?.isCoachAiSupportDisabled;

  const hasAiComment = isCoach && !isManual && hasAiRecommendation;

  return (
    <Stack direction={direction} key={thoughtRecordKey}>
      {isFirstRecordOfMonth && (
        <Typography
          level="body-xs"
          sx={{
            px: "12px",
            py: "8px",
            opacity: "0.5",
            fontWeight: 500,
            whiteSpace: "nowrap",
          }}
        >
          {`${dayjs(date).format("MMM")}`}
        </Typography>
      )}
      <ListItemButton
        sx={{ width: width }}
        onClick={() => {
          setCurrentThoughtRecord(thoughtRecord);
          (document.activeElement as HTMLElement)?.blur();
        }}
      >
        <ListItemContent>
          <Box
            sx={{
              py: "12px",
              borderRadius: "8px",
              backgroundColor: isSelected ? "background.level2" : "transparent",
            }}
          >
            <Stack
              key={`thoughtRecord_${thoughtRecordKey}`}
              direction={"row"}
              alignItems="center"
              sx={{
                position: "relative",
              }}
            >
              {isHighlight && (
                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    width: "4px",
                    height: "100%",
                    borderRadius: "4px",
                    backgroundColor: "primary.solid",
                  }}
                />
              )}

              <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                sx={{
                  width: "48px",
                  minWidth: "48px",
                  mr: "8px",
                }}
              >
                <Typography
                  level={"title-lg"}
                  {...(shouldBeDone && { color: "danger" })}
                >
                  {dayjs(date).get("date")}
                </Typography>
                <Typography
                  sx={{ opacity: 0.5 }}
                  {...(shouldBeDone && { color: "danger" })}
                >
                  {dayjs(date).format("ddd")}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  flex: 1,
                  opacity: direction === "row" ? 0 : 1,
                  pr: hasAiComment ? "12px" : "20px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {situation}
              </Typography>
              {/* {hasAiComment && (
                <SvgIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    mr: "8px",
                    color: "primary.solid",
                  }}
                >
                  <AutoAwesomeIcon />
                </SvgIcon>
              )} */}
            </Stack>
          </Box>
        </ListItemContent>
      </ListItemButton>
    </Stack>
  );
}

export default memo(ThoughtRecordListItem);
