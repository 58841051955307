import { Typography } from "@mui/joy";
import dayjs from "dayjs";
import { memo } from "react";
import { useTranslation } from "react-i18next";

function TypographyCell({ value }: { value?: string }) {
  const { t: t_common } = useTranslation("translation", {
    keyPrefix: "task.activity.common",
  });
  return (
    <Typography
      level={"h3"}
      sx={{
        wordBreak: "keep-all",
      }}
    >
      {`${dayjs(value).format(t_common("MMM D일 ddd요일"))}`}
    </Typography>
  );
}

export default memo(TypographyCell);
