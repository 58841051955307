export const translation_4_4_2_A = {
  en: {
    v1: {
      typography_0_0_0:
        "Our brains have this incredible and essential skill: reasoning. It's deeply rooted in our biology.",
      typography_1_0_0:
        "To take a step back from our thoughts, we need to grasp how our ability to reason has developed.",
      typography_2_0_0: "Reasoning: a shared ability",
      typography_3_0_0:
        "It might sound lofty, but reasoning is a shared ability across life forms, often happening unconsciously and reflexively.",
      typography_4_0_0: "Picture this: a toad spots a caterpillar.",
      typography_5_0_0:
        "Quick as a flash, the toad snatches it up, seeming satisfied.",
      typography_6_0_0:
        "Then comes another caterpillar, brightly colored this time.",
      typography_7_0_0: "Again, the toad snaps it up.",
      typography_8_0_0:
        "But soon, the toad starts to weaken. This caterpillar was poisonous.",
      typography_9_0_0: "Reasoning in nature",
      typography_10_0_0:
        "Let's speed up time. What became of toads that ate these colorful caterpillars?",
      typography_11_0_0:
        "They didn't make it. Only those who could tell the poisonous from the safe survived.",
      typography_12_0_0:
        "Through evolution, toads gained a kind of “eat or not eat” reasoning ability.",
      typography_13_0_0: "But how did the toad make its decision?",
      typography_14_0_0:
        "It might have pondered deeply (though that's unlikely).",
      typography_15_0_0: "More likely, it used various signs.",
      typography_16_0_0: "Shape, smell, movement of the caterpillar.",
      typography_17_0_0:
        "Toads evolved to instinctively avoid the dangerous ones.",
      typography_18_0_0: "Human reasoning",
      typography_19_0_0: "Now, let's consider a more evolved example: humans.",
      typography_20_0_0:
        "We've taken pattern recognition through reasoning to an extreme.",
      typography_21_0_0:
        "If our ancestors ate red berries and got sick, they'd learn to avoid them.",
      typography_22_0_0:
        "Humans developed the reasoning skills to decide “how to interpret” or “how to think/act.”",
      typography_23_0_0: "But how do humans reason?",
      typography_24_0_0:
        "It feels like we deliberate with intent (and it certainly feels that way).",
      typography_25_0_0:
        "Yet, our reasoning is influenced by a mix of experiences, memories, information, environment (even weather), often unconsciously.",
      typography_26_0_0:
        "We form conclusions based on these elements, even if we're not aware of it.",
      typography_27_0_0: "How precise is reasoning?",
      typography_28_0_0: "So, how accurate is our reasoning?",
      typography_29_0_0:
        "Sadly, in daily life, we don’t always use high-level logical reasoning.",
      typography_30_0_0:
        "Philosophers might write analytical papers on their thought processes,",
      typography_31_0_0:
        "but everyday life isn't like that. We often jump to conclusions with little information.",
      typography_32_0_0:
        "It makes sense. Those who waited for complete information before deciding likely didn't survive.",
      typography_33_0_0: "The pitfalls of reasoning",
      typography_34_0_0:
        "Our imperfect reasoning process can lead to thinking traps.",
      typography_35_0_0:
        "Especially when engulfed in negative thoughts and emotions, our reasoning becomes even less refined.",
      typography_36_0_0:
        "This thinking trap we call 'Little Evidence' happens when we base our reasoning on insufficient information, our emotions, or our own negative thoughts.",
      typography_37_0_0: "A type of 'inaccurate thoughts'.",
      typography_38_0_0: "Little Evidence can show up in different ways.",
      typography_39_0_0:
        "We might overlook the many aspects of beauty, just seeing things as 'pretty' or 'not pretty', falling into black-and-white thinking.",
      typography_40_0_0:
        "We might label ourselves as 'not good enough' based on scant evidence, using negative self-labels.",
      typography_41_0_0:
        "Post-breakup, thinking “I'm unlovable” is an overgeneralization.",
      typography_42_0_0:
        "Cherry-picking a few failures and concluding “I'm not cut out for this job” is another form.",
      typography_43_0_0:
        "Believing someone dislikes you because they rescheduled, is like mind-reading.",
      typography_44_0_0:
        "Even when achieving something noteworthy, we might belittle it, thinking “It's nothing special,” undervaluing positive experiences.",
      typography_45_0_0: "Distancing from Little Evidence",
      typography_46_0_0:
        "We often believe we reason logically and intentionally.",
      typography_47_0_0:
        "But think about the toad. Our reasoning can also be passive and automatic.",
      typography_48_0_0:
        "Recognizing that our reasoning can happen 'independently of our will', 'influenced by emotions and thoughts', 'automatically', is key.",
      typography_49_0_0:
        "Before you get too deep into these popcorn-like thoughts,",
      typography_50_0_0: "notice that they've popped into your mind,",
      typography_51_0_0: "and take a step back to look at them objectively.",
      typography_52_0_0:
        "Through consistent practice in observing our automatic reasoning,",
      typography_53_0_0:
        "we can gain more psychological flexibility, becoming more liberated from negative thoughts and emotions.",
      typography_54_0_0:
        "Shall we now explore how our reasoning process works?",
    },
  },
  ko: {
    v1: {
      typography_0_0_0:
        "인간의 두뇌가 가진 능력 중 가장 혁신적이고 중요한 것은 추론 능력입니다. 이는 지극히 생물학적인 능력이죠.",
      typography_1_0_0:
        "생각으로부터 거리를 두려면 추론 능력이 어떻게 발전한 것인지 이해할 필요가 있습니다.",
      typography_2_0_0: "추론, 생명체의 공통된 능력",
      typography_3_0_0:
        "거창해 보이지만 사실 추론은 생명체의 공통된 능력입니다. 거의 무의식적이고 반사적이죠.",
      typography_4_0_0:
        "자, 두꺼비가 있습니다. 두꺼비 앞으로 애벌레가 지나가네요.",
      typography_5_0_0:
        "날름. 두꺼비가 혀를 뻗어 애벌레를 삼켰습니다. 만족스러운 모습이네요.",
      typography_6_0_0:
        "이번엔 다른 애벌레가 지나갑니다. 이번에는 아주 화려한 색의 애벌레네요.",
      typography_7_0_0: "날름. 이번에도 두꺼비가 애벌레를 삼켰네요.",
      typography_8_0_0:
        "그런데 뭔가 표정이 좋지 않습니다. 두꺼비는 점점 힘을 잃어가네요. 애벌레에 독이 있었던 모양이에요.",
      typography_9_0_0: "생명체의 추론",
      typography_10_0_0:
        "자, 이제 수만 년의 시간이 흘렀다고 생각해볼까요? 화려한 애벌레를 먹는 두꺼비는 어떻게 되었을까요?",
      typography_11_0_0:
        "다 멸종해버리고 말았죠. 독이 든 애벌레와 그렇지 않은 애벌레를 잘 구분하는 두꺼비만 살아남았습니다.",
      typography_12_0_0:
        "이처럼 진화 과정을 통해 두꺼비는 일종의 “할 것인가, 말 것인가” 추론 능력을 얻게 되었습니다.",
      typography_13_0_0: "두꺼비는 어떻게 추론을 한 것일까요?",
      typography_14_0_0:
        "곰곰이 생각했을 수도 있겠죠(물론 그렇진 않을 겁니다).",
      typography_15_0_0: "아마 다양한 정보를 활용했을 것입니다.",
      typography_16_0_0: "애벌레의 모양, 냄새, 움직임. 그러한 정보를 활용하여",
      typography_17_0_0:
        "무의식 중에 독이 든 애벌레를 구분할 수 있는 두꺼비만이 진화를 통해 살아남았습니다.",
      typography_18_0_0: "인간의 추론",
      typography_19_0_0:
        "조금 더 진화적으로 발전한 개체를 살펴볼까요? 우리 인간이 좋은 예시입니다.",
      typography_20_0_0:
        "인간은 추론을 통한 패턴 인식 능력이 극단적으로 발전한 생명체입니다.",
      typography_21_0_0:
        "우리의 선조들은 빨간 열매를 먹고 배가 아팠다면 다음부터는 빨간 열매를 먹지 않을 수 있었죠.",
      typography_22_0_0:
        "인간은 진화 과정을 통해 “어떻게 받아들일 것인가?” 혹은 “어떻게 생각/행동할 것인가?”를 판단할 수 있는 추론 능력을 얻게 되었습니다.",
      typography_23_0_0: "그렇다면 인간은 어떻게 추론한 것일까요?",
      typography_24_0_0:
        "의지를 갖고 곰곰이 생각했을 수도 있겠죠(분명히 그렇게 ‘느껴질’ 것입니다).",
      typography_25_0_0:
        "하지만 추론 과정의 이면에는 우리의 다양한 경험, 기억, 정보, 환경(심지어는 당시의 날씨조차도) 등이 무의식적으로 활용되고 있음이 분명합니다.",
      typography_26_0_0:
        "우리는 그러한 정보들을 바탕으로 추론하여 결론을 짓죠. 설령 우리가 그것을 의식하지 못하고 있을지라도요.",
      typography_27_0_0: "추론은 얼마나 정교한가?",
      typography_28_0_0: "그렇다면 우리의 추론 능력은 얼마나 정교할까요?",
      typography_29_0_0:
        "아쉽게도 우리는 일상적인 일에 있어서 높은 수준의 논리적 추론을 거치지 않습니다.",
      typography_30_0_0:
        "물론 논리학자들은 “나는 왜 이렇게 생각했는가”에 대해 객관적이고 체계적인 논문을 작성할 수도 있을 것입니다.",
      typography_31_0_0:
        "하지만 대부분의 일상은 그렇게 흘러가지 않습니다. 우리는 아주 작은 정보만으로도 효과적으로 결론을 짓거든요.",
      typography_32_0_0:
        "이는 당연합니다. 모든 정보를 완벽히 수집한 뒤에야 결론을 짓는 인간은 살아남지 못했을 테니까요.",
      typography_33_0_0: "추론의 부작용",
      typography_34_0_0:
        "그다지 정확하지 않은 추론 과정은 불가피하게 생각함정을 만들어내게 됩니다.",
      typography_35_0_0:
        "특히 부정적인 생각과 감정에 휩싸였을 때에는 더욱이 정교하지 않은 방식으로 추론을 진행할 때가 많습니다.",
      typography_36_0_0:
        "이처럼 부족한 정보에 기반하거나, 나의 감정에 기반하거나, 내가 가진 부정적인 생각에 기반해 추론하여 결론을 짓는 생각함정을 '근거 부족'이라고 말합니다.",
      typography_37_0_0:
        "근거 부족은 '정확하지 않은 생각'에 해당하는 생각함정이에요.",
      typography_38_0_0:
        "근거 부족 생각함정은 여러 가지 모습으로 나타나는데요.",
      typography_39_0_0:
        "아름다움을 이루는 다양한 속성을 인지하지 못하고, '예쁨'과 '예쁘지 않음', 두 가지로만 결론을 내리며 흑백 논리에 빠지기도 하고요.",
      typography_40_0_0:
        "일부의 근거만으로 “나는 모자란 놈이니까…”라고 말하며 스스로에 대해 '모자란 놈'이라는 꼬리표를 반복적으로 사용하는 경우도 있습니다.",
      typography_41_0_0:
        "한편, 연인과 이별한 후 “나는 모든 사람에게 사랑받지 못할 존재인가 봐.”라고 생각하며 성급하게 일반화하기도 하고요.",
      typography_42_0_0:
        "과거 실수했던 소수의 경험만을 선택적으로 취사선택하며, “나는 진짜 업무에는 재능이 없는 사람이야.”라는 결론을 내리기도 하지요.",
      typography_43_0_0:
        "상대가 나와의 약속을 미뤘다는 사실로 “저 사람은 나를 싫어해.”라고 생각하며 독심술처럼 상대의 마음을 규정짓기도 합니다.",
      typography_44_0_0:
        "또한 좋은 성취를 이뤄냈음에도 불구하고 평가를 절하하며 “그 정도 일이야 누구나 다 하는 정도인 걸…”이라고 생각하며 긍정적인 일의 의미를 과소 해석하기도 해요.",
      typography_45_0_0: "근거 부족으로부터 거리두기",
      typography_46_0_0:
        "우리는 스스로가 자신의 의지대로 이성적으로 판단한다고 느끼는 경향이 있습니다.",
      typography_47_0_0:
        "하지만 두꺼비의 경우를 떠올려보세요. 우리들의 추론 과정도 충분히 수동적이고 자동적일 수 있답니다.",
      typography_48_0_0:
        "따라서 중요한 건 나의 추론 과정 또한 ‘나의 의지와 무관하게’, ‘내 감정과 생각에 영향을 받아’, ‘자동적으로’ 발생할 수 있다는 것을 깨닫는 것입니다.",
      typography_49_0_0:
        "팝콘처럼 마음속에 떠오른 생각에 강하게 매몰되기 전에,",
      typography_50_0_0: "그러한 생각이 마음속에 떠올랐음을 인지하고,",
      typography_51_0_0:
        "한 걸음 떨어져서 그 생각이 어떠한 생각인지 객관적으로 살펴보는 것이죠.",
      typography_52_0_0:
        "우리가 지속적인 연습을 통해 자동적인 추론 과정을 거리를 두고 바라볼 수 있다면,",
      typography_53_0_0:
        "우리는 더 높은 심리적 유연성을 토대로 부정적인 생각과 감정으로부터 보다 자유로워질 수 있을 거랍니다.",
      typography_54_0_0:
        "자, 그러면 이제 나의 추론 과정이 어떠했는지 살펴볼까요?",
    },
  },
};
