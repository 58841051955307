import { IconButton, Stack, SvgIcon } from "@mui/joy";
import { NextArrowSvg } from "static/image/common/SvgAssets";
import { extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";

export default function NextButton({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
}) {
  const activatedColor = extractHexColor(theme.vars.palette.primary.solid);
  const deactivatedColor = extractHexColor(
    theme.vars.palette.primary.deactivated
  );
  return (
    <Stack direction={"row"} justifyContent="end">
      <IconButton disabled={disabled} onClick={onClick}>
        <SvgIcon sx={{ width: "14px", height: "14px" }} inheritViewBox>
          <NextArrowSvg color={!disabled ? activatedColor : deactivatedColor} />
        </SvgIcon>
      </IconButton>
    </Stack>
  );
}
