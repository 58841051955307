import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { tokenAtom } from "recoil/tokenAtom";
import useFetch2 from "./useFetch2";

export default function useLoginWeb({
  url,
  requestBody,
}: {
  url: string;
  requestBody: string;
}) {
  const [loginResult, login] = useFetch2({
    url: url,
    requestBody: requestBody,
  });

  const setTokenState = useSetRecoilState(tokenAtom);

  useEffect(() => {
    if (loginResult) {
      setTokenState((state) => ({
        ...state,
        accessToken: loginResult.accessToken,
      }));
    }
  }, [loginResult]);

  return login;
}
