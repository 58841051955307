import { useCallback, useEffect, useState } from "react";
import TaskHeader from "components/task/header/TaskHeader";
import TaskContent from "components/task/content/TaskContent";
import { Box, Stack } from "@mui/joy";
import { ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";
import ActivityRecordList from "./ActivityRecordList";
import { generateActivityRecordKey, isAllUserFieldFilled } from "logic/logics";
import {
  activityRecordApi,
  ActivityRecordType,
  ACTIVITYRECORD_DOMAIN,
  ACTIVITYRECORD_LIST_ENDPOINT,
} from "api/activityRecordApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import useInterval from "hooks/useInterval";
import { useRecoilValue } from "recoil";
import { layoutAtom } from "recoil/layoutAtom";
import { patientAtom } from "recoil/patientAtom";
import { useQuery } from "@tanstack/react-query";
import { userAtom } from "recoil/userAtom";
import useSetConceptData from "hooks/useSetConceptData";
import useGetActivityRecordData from "hooks/useGetActivityRecordData";

export default function ActivityRecord({ taskKey }: { taskKey: string }) {
  const { isTaskContainerSmall } = useRecoilValue(layoutAtom);
  const [data, setData] = useState<ProgramContentType[] | undefined>();
  const [prevData, setPrevData] = useState<ProgramContentType[] | undefined>();
  const { t: t_common } = useTranslation("translation", {
    keyPrefix: "task.activity.common",
  });

  const [activityRecordList, setActivityRecordList] = useState<
    ActivityRecordType[] | undefined
  >();

  const [currentActivityRecord, setCurrentActivityRecord] = useState<
    ActivityRecordType | undefined
  >();

  const {
    data: fetchedData,
    refetch,
    isFetching,
  } = useGetActivityRecordData({
    ark: currentActivityRecord?.activityRecordKey,
    taskKey: currentActivityRecord?.taskKey,
  });

  // 개념화노트, 생각지도 데이터 세팅
  const { isConceptNoteFetching, isConceptFetching } = useSetConceptData({
    taskKey: taskKey,
  });

  useInterval(
    () => {
      setPrevData(data);
      //변화 없으면 다시 가져와
      if (data && prevData && _.isEqual(data, prevData)) {
        refetch();
      }
    },
    10000,
    []
  );
  const patientState = useRecoilValue(patientAtom);
  const user = useRecoilValue(userAtom);
  const isCoach = user?.accessToken && user?.role !== "patient";
  const getActivityRecordListqueryFn = useCallback(
    () => activityRecordApi.getList(`${patientState?.patientId}`),
    [patientState]
  );

  useQuery(
    [ACTIVITYRECORD_DOMAIN, ACTIVITYRECORD_LIST_ENDPOINT],
    getActivityRecordListqueryFn,
    {
      onSuccess: (data) => {
        const list = data.result;

        setActivityRecordList(list);
        if (!list || list.length === 0) {
          if (!currentActivityRecord) {
            setCurrentActivityRecord({
              activityRecordKey: generateActivityRecordKey(
                patientState?.patientId || ""
              ),
            });
          }
        } else {
          list.sort((a, b) => {
            return (
              +new Date(`${b.date}T${b.time}` || "") -
              +new Date(`${a.date}T${a.time}` || "")
            );
          });

          let currentIndex = 0;
          if (!currentActivityRecord) {
            const incompleteDataIndex = isCoach
              ? list.findIndex((element) => element.isHighlight)
              : list.findIndex((element) => !element.isDone);
            currentIndex = Math.max(incompleteDataIndex, 0);
          } else {
            currentIndex = list.findIndex(
              (element) =>
                element.activityRecordKey ===
                currentActivityRecord.activityRecordKey
            );
          }
          // setActivityRecordList(list);
          setCurrentActivityRecord(list[currentIndex]);
        }
      },
    }
  );

  useEffect(() => {
    if (currentActivityRecord) {
      if (!currentActivityRecord.isDone && isAllUserFieldFilled(data, false)) {
        setActivityRecordList((activityRecordList) => {
          if (activityRecordList) {
            const data_temp = _.cloneDeep(activityRecordList);
            data_temp.splice(
              data_temp.findIndex(
                (element) =>
                  element.activityRecordKey ===
                  currentActivityRecord?.activityRecordKey
              ),
              1,
              { ...currentActivityRecord, shouldBeDone: true }
            );

            return data_temp;
          }
          return activityRecordList;
        });
      } else {
        setActivityRecordList((activityRecordList) => {
          if (activityRecordList) {
            const data_temp = _.cloneDeep(activityRecordList);
            data_temp.splice(
              data_temp.findIndex(
                (element) =>
                  element.activityRecordKey ===
                  currentActivityRecord?.activityRecordKey
              ),
              1,
              { ...currentActivityRecord, shouldBeDone: false }
            );

            return data_temp;
          }
          return activityRecordList;
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (fetchedData) {
      if (!_.isEqual(fetchedData.content, data)) {
        setData(fetchedData.content);
      }
    }
  }, [fetchedData]);

  const [moveToIndex, setMoveToIndex] = useState<number | undefined>(undefined);

  return (
    <Stack
      direction="row"
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <ActivityRecordList
        activityRecordList={activityRecordList}
        currentActivityRecord={currentActivityRecord}
        setCurrentActivityRecord={setCurrentActivityRecord}
      />
      {currentActivityRecord?.activityRecordKey && (
        <Stack
          direction={"column"}
          className="flex-1 h-full"
          sx={{ minWidth: 0 }}
        >
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 100,
              px: isTaskContainerSmall ? "16px" : "24px",
            }}
          >
            <TaskHeader
              taskKey={taskKey}
              activityRecordKey={currentActivityRecord?.activityRecordKey}
              title={`${dayjs(currentActivityRecord?.date).format(
                t_common("MMM D일 ddd요일")
              )}`}
              contentData={data}
              setContentData={setData}
              setMoveToIndex={setMoveToIndex}
              isDone={currentActivityRecord?.isDone}
            />
          </Box>
          <div className="w-full h-full overflow-hidden ">
            <TaskContent
              taskKey={taskKey}
              activityRecordKey={currentActivityRecord?.activityRecordKey}
              data={data}
              setData={setData}
              moveToIndex={moveToIndex}
              setMoveToIndex={setMoveToIndex}
              isDone={currentActivityRecord?.isDone}
              translationVersion={fetchedData?.translationVersion}
              isConceptFetching={
                isConceptNoteFetching || isConceptFetching || isFetching
              }
            />
          </div>
        </Stack>
      )}
    </Stack>
  );
}
