import { Stack, Typography } from "@mui/joy";
import { ABCStatusType } from "api2/toolApi";
export const IsolatingIcon = ({ color = "#222222" }: { color?: string }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 2V3.82319C7 4.54953 6.60622 5.21877 5.97129 5.57151L3.52871 6.92849C2.89378 7.28123 2.5 7.95047 2.5 8.67681V12"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.67321 13.7C2.59623 13.8333 2.40378 13.8333 2.3268 13.7L0.508142 10.55C0.431162 10.4167 0.527387 10.25 0.681347 10.25L4.31865 10.25C4.47261 10.25 4.56884 10.4167 4.49186 10.55L2.67321 13.7Z"
        fill={color}
      />
      <path
        d="M7 2V3.82319C7 4.54953 7.39378 5.21877 8.02871 5.57151L10.4713 6.92849C11.1062 7.28123 11.5 7.95047 11.5 8.67681V12"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3268 13.7C11.4038 13.8333 11.5962 13.8333 11.6732 13.7L13.4919 10.55C13.5688 10.4167 13.4726 10.25 13.3187 10.25L9.68135 10.25C9.52739 10.25 9.43116 10.4167 9.50814 10.55L11.3268 13.7Z"
        fill={color}
      />
      <path
        d="M6.82679 13.7C6.90377 13.8333 7.09622 13.8333 7.1732 13.7L8.99186 10.55C9.06884 10.4167 8.97261 10.25 8.81865 10.25L5.18135 10.25C5.02739 10.25 4.93116 10.4167 5.00814 10.55L6.82679 13.7Z"
        fill={color}
      />
      <path
        d="M7 10.5V1"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const FindingTrapIcon = ({ color = "#222222" }: { color?: string }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6V9C2 12.3137 4.68629 15 8 15V15C11.3137 15 14 12.3137 14 9V6"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.82679 12.7C7.90377 12.8333 8.09622 12.8333 8.1732 12.7L9.99186 9.55C10.0688 9.41667 9.97261 9.25 9.81865 9.25L6.18135 9.25C6.02739 9.25 5.93116 9.41667 6.00814 9.55L7.82679 12.7Z"
        fill={color}
      />
      <path
        d="M8 11.5V2"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const CheckIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8.5" r="8" fill="#222222" />
      <path
        d="M4.5 8.5L7 11L11.5 6.5"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default function Status({ status }: { status: ABCStatusType }) {
  const data = {
    in_progress_isolation: { icon: <IsolatingIcon />, text: "분리 중" },
    in_progress_finding_thought_trap: {
      icon: <FindingTrapIcon />,
      text: "함정 찾는 중",
    },
    completed: { icon: <CheckIcon />, text: "완료" },
  };
  return (
    <Stack direction="row" alignItems="center" spacing="8px">
      {data[status].icon}
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 500,
          color: "#222222",
          opacity: 0.6,
        }}
      >
        {data[status].text}
      </Typography>
    </Stack>
  );
}
