import { ProgramType } from "data/programData";
import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const ACTIVITY_DOMAIN = "activity";

export const ACTIVITY_DETAIL_ENDPOINT = "detail";
export const ACTIVITY_PLAN_CANDIDATE_ADD_ENDPOINT = "plan/candidate/add";

interface ActivityDetailType {
  data: ProgramType;
}

export const activityApi = {
  getDetail: (ark: string) =>
    axiosInstance.get<CustomResponseFormat<ActivityDetailType>>(
      `${ACTIVITY_DOMAIN}/${ACTIVITY_DETAIL_ENDPOINT}`,
      { params: { ark: ark } }
    ),
  addPlanCandidate: (ark: string, name: string) =>
    axiosInstance.post<void>(
      `${ACTIVITY_DOMAIN}/${ACTIVITY_PLAN_CANDIDATE_ADD_ENDPOINT}`,
      { name: name },
      {
        params: { ark: ark },
      }
    ),
};
