import { selfCheckData } from "data/selfCheckData";
import {
  Instruction,
  ProgramContentType,
  Header1,
  SelfCheckCard,
} from "../../BlockComponent";
import { LastCard } from "../common";

export const selfCheckTemplate = [
  Header1("selfcheck_guide"),
  ...selfCheckData.depression.questionList.map(
    ({ questionTranslationKey, evaluation }, index) =>
      SelfCheckCard("depression", questionTranslationKey, evaluation)
  ),
  ...selfCheckData.anxiety.questionList.map(
    ({ questionTranslationKey, evaluation }, index) =>
      SelfCheckCard("anxiety", questionTranslationKey, evaluation)
  ),
];

export const Content_102_1_A: ProgramContentType[] = [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  Instruction("typography_2_0_0"),
  Instruction("typography_3_0_0"),
  Instruction("typography_4_0_0"),
  Instruction("typography_5_0_0"),

  ...selfCheckTemplate,
  Instruction("selfcheck_finish"),
  ...LastCard,
];
