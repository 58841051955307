import { CircularProgress, Stack } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import TypographyCell from "./TypographyCell";
import {
  ABCType,
  thoughtRecordApi,
  THOUGHTRECORD_ABC_ENDPOINT,
  THOUGHTRECORD_DOMAIN,
} from "api/thoughtRecordApi";
import { setProgramContentData } from "logic/logics";
import { CellType, IsolationDataType } from "data/CellComponent";
import { ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";

export default function IsolationCell({
  thoughtRecordKey,
  isShown,
  setData,
  blockIndex,
  lineIndex,
  cellIndex,
  isolatedData,
}: {
  thoughtRecordKey: string;
  isShown: boolean;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  blockIndex: number;
  lineIndex: number;
  cellIndex: number;
  isolatedData?: IsolationDataType;
}) {
  const getABCQueryFn = useCallback(
    () => thoughtRecordApi.getABC(thoughtRecordKey),
    [thoughtRecordKey, isShown]
  );

  const hasData = Object.values(isolatedData || {}).some(
    (element) => (element as string | string[])?.length > 0
  );
  const [isProcessing, setIsProcessing] = useState(!hasData);

  useQuery(
    [THOUGHTRECORD_DOMAIN, THOUGHTRECORD_ABC_ENDPOINT, blockIndex],
    getABCQueryFn,
    {
      enabled: isProcessing && isShown,
      ...(isProcessing && { refetchInterval: 1000 }),
      onSuccess: (data) => {
        setIsProcessing(data.result?.isProcessing || false);
        if (!data.result?.isProcessing) {
          console.log("다시 불러옴", blockIndex);
          const ABCData = data.result;
          const isolation: IsolationDataType = {
            상황: getValue(ABCData?.situation),
            생각: getValue(ABCData?.thoughtList),
            감정: getValue(ABCData?.emotionList),
            신체: getValue(ABCData?.physicalReactionList),
            행동: getValue(ABCData?.behaviorList),
          };
          const isolatedDataLine: CellType[] = [
            {
              type: "temp",
              content: {
                id: `situation`,
                value: getValue(ABCData?.situation),
              },
            },
            {
              type: "temp",
              content: {
                id: `thought`,
                value: getValue(ABCData?.thoughtList),
              },
            },
            {
              type: "temp",
              content: {
                id: `emotion`,
                value: getValue(ABCData?.emotionList),
              },
            },
            {
              type: "temp",
              content: {
                id: `physicalResponse`,
                value: getValue(ABCData?.physicalReactionList),
              },
            },
          ];
          setData((data) => {
            if (data) {
              if (data) {
                const data_temp = _.cloneDeep(data);

                data_temp[blockIndex].lines.push(isolatedDataLine);
                return data_temp;
              }
            }
            return data;
          });
          setProgramContentData({
            setData,
            blockIndex,
            lineIndex,
            cellIndex,
            newlyAddedData: {
              isolatedData: isolation,
            },
          });
        }
      },
    }
  );

  function getValue(data?: string | string[]) {
    return data && data.length > 0
      ? typeof data === "string"
        ? data
        : data?.join(",")
      : "";
  }

  return !isProcessing ? (
    <Stack spacing={"8px"}>
      {Object.entries(isolatedData || {})?.map(([key, value]) => (
        <Stack key={`isolation_${key}`} spacing={"4px"}>
          <TypographyCell level="title-sm" color="primary.solid" text={key} />
          <TypographyCell
            text={value?.length > 0 ? value : "아직 구체화되지 않았어요"}
            opacity={value?.length > 0 ? 1 : 0.5}
          />
        </Stack>
      ))}
    </Stack>
  ) : (
    <CircularProgress color="neutral" size="sm" />
  );
}
