import { translation_meditation } from "./meditation";

export const translation_8_3_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "첫 번째 연습은 어떠셨나요?",
      typography_1_0_0: "아마 어색해서 쉽지 않으셨을 것 같아요.",
      typography_2_0_0: "괜찮아요. 누구나 처음엔 그렇답니다.",
      typography_3_0_0: "조금씩 건강한 습관으로 자리 잡을 수 있을 거예요.",
      typography_4_0_0: `생각의 강 연습의 핵심은 마음속에 새로운 공간감을 만드는 것이에요.`,
      typography_5_0_0:
        "생각의 강 비유에서 ‘나’는 나뭇잎도 아니고, 심지어는 시냇물도 아니죠.",
      typography_6_0_0:
        "나는 멀찍이 떨어져 시냇물, 즉, 나의 마음을 바라보고 있을 뿐이에요.",
      typography_7_0_0: "마음속에는 다양한 생각이 흘러갑니다.",
      typography_8_0_0: "나는 시냇물 옆에 서서 그 생각들을 바라볼 수 있어요.",
      typography_9_0_0:
        "물론 처음부터 공간감을 마련하는 게 쉽지 않을 수도 있어요.",
      typography_10_0_0:
        "생각을 따라가고 있는 자신을 발견했을 때, 다시 어느 공간으로 돌아와야 하는지 헷갈릴 수도 있죠.",
      typography_11_0_0:
        "그래서 이번에는 “다시 나의 자리로 돌아온다”라는 것에 조금 더 집중을 해볼까 해요.",
      typography_12_0_0: "호흡에 초점을 두기",
      typography_13_0_0: "자, 다시 동일하게 생각의 강 훈련을 진행할 거예요.",
      typography_14_0_0:
        "다만 이번엔 시냇물 옆에 앉아서 호흡에 집중을 해볼 거랍니다.",
      typography_15_0_0:
        "가만히 앉아서 내가 어떻게 숨을 쉬는지, 공기의 흐름, 몸의 움직임 등을 관찰해보세요.",
      typography_16_0_0: "만약 역시나 생각을 따라가고 있는 자신을 발견한다면,",
      typography_17_0_0:
        "강에서 빠져나와 다시 시냇물 옆에 앉아 주의를 호흡으로 돌려보세요.",
      typography_18_0_0:
        "들숨, 날숨을 섬세하게 관찰하며 주의력을 호흡에 기울이면 됩니다.",
      typography_19_0_0:
        "또 주의력을 잃고 생각을 따라가고 있는 자신을 발견하면, 또다시 주의를 호흡으로 돌려보세요.",
      typography_20_0_0:
        "생각의 강 연습을 하되, 다시 돌아올 나의 자리를 호흡에 두는 거예요.",
      typography_21_0_0:
        "이때 한 가지 명심할 건 이 호흡을 ‘이완을 위한 도피처'로 생각하면 안 된다는 거예요.",
      typography_22_0_0: "이완도 좋지만 가장 중요한 건 관계를 새로 맺는 것,",
      typography_23_0_0:
        "즉, 생각을 하나의 심리적 사건으로 바라보고 그것에 정신없이 따라가지 않는 것이에요.",
      typography_24_0_0:
        "그러니 호흡을 초점으로 연습하다가 점차 이것이 익숙해지면,",
      typography_25_0_0:
        "조금씩 호흡이 아니라 생각을 그저 생각으로 바라보는 느낌, 마음속의 공간감을 찾아보세요.",
      typography_26_0_0:
        "그렇게 했을 때 생각의 강 훈련은 단순한 주의력 연습, 또는 이완 훈련이 아니라,",
      typography_27_0_0:
        "생각과의 관계를 다시 맺어 생각을 하나의 심리적 사건으로 바라보는 연습이 될 거랍니다.",
      typography_28_0_0: `두 번째 연습`,
      typography_29_0_0: "자, 오늘은 10분 정도 연습해보기로 해요.",
      typography_30_0_0:
        "이번 연습에서는 호흡에 초점을 두고 나의 자리로 돌아오는 거예요.",
      ...translation_meditation.ko.v2,
    },
  },
};
