export const translation_7_6_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "앞서 내가 선택한 삶의 방향은 다음과 같아요.",
      typography_1_0_0:
        "오늘은 이 방향으로 앞으로의 삶을 꾸려나갈 수 있도록 몇 가지 준비를 해볼 거랍니다.",
      typography_2_0_0: "상상해 보기",
      typography_3_0_0:
        "먼저, 가치 행동을 설계하는 데에 도움을 줄 수 있는 몇 가지 질문을 드려볼게요.",
      typography_4_0_0: `$A$(은)는 나에게 왜 중요한가요? 어떻게 이것이 나에게 중요한 가치가 되었을까요?`,
      typography_5_0_0:
        "$A$(이)가 정말로 실현된다면 어떤 기분이 들까요? 무엇이 좋을까요?",
      typography_6_0_0:
        "$A$에 가까워진 나를 상상해볼게요. 그렇다면 나는 당장 어떻게 다르게 행동하고 있을까요?",
      typography_7_0_0: `좋아요. 상상해 보니 어떠셨나요? 이제 내 가치에 맞는 행동들로 하나씩 삶을 채워나가 볼게요.`,
      typography_8_0_0: `단계 만들기`,
      typography_9_0_0: "처음부터 완벽하게 많은 것을 해내는 건 쉽지 않아요.",
      typography_10_0_0:
        "조금씩이라도 꾸준히 가치를 향해 나아가는 게 중요해요. 가치는 내 인생의 방향이지 목적지가 아니니까요.",
      typography_11_0_0: `그래서 우리는 5가지 단계로 가치 행동을 준비해 하나씩 실행해 볼 거랍니다.`,
      typography_12_0_0: `쉬운 것부터 어려운 것까지 순서대로 단계를 나눌 거예요.`,
      typography_13_0_0: `예를 들어, "엄마와 친구처럼 친밀하게 지내기"가 가치라면 다음과 같이 단계를 나눠볼 수 있을 거예요.`,
      typography_13_1_0: `엄마에게 전화 걸어 간단히 안부만 묻기`,
      typography_13_2_0: `미리 준비한 얘기거리로 엄마와 3분 통화하기`,
      typography_13_3_0: `엄마에게 화장품 선물하기`,
      typography_13_4_0: `엄마와 외식 데이트하기`,
      typography_13_5_0: `엄마와 당일치기 국내여행 다녀오기`,
      typography_14_0_0: `1단계부터 5단계 모두 "엄마와 친구처럼 친밀하게 지내기"로 가는 삶의 여정인 것이죠.`,
      typography_15_0_0: `이렇게 단계를 나눈 뒤에는 가장 쉬운 1단계부터 차례대로 하나씩 진행하게 될 거랍니다.`,
      typography_16_0_0: `자 이제 나의 가치인 "$A$"(으)로 향하는 단계를 만들어볼까요?`,
      typography_17_0_0: `좋아요. 이제 1단계부터 조금 더 자세한 계획을 짜볼 텐데요.`,
      typography_18_0_0: `코치 선생님이 살펴보고 다음 활동을 안내해드릴테니 조금만 기다려주세요.`,
    },
  },
};
