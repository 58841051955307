import { useRecoilValue } from 'recoil';
import { Stack, Typography } from '@mui/joy';
import React, { useCallback, useEffect, useState } from 'react';
import TestResultV2 from 'components/dashboard/TestResultV2';
import { patientAtom } from 'recoil/patientAtom';
import { DASHBOARD_DETAIL_ENDPOINT, DASHBOARD_DOMAIN, dashboardApi, DashboardType } from 'api/dashboardApi';
import { useQuery } from '@tanstack/react-query';
import ContentTemplate from '../content/ContentTemplate';

export default function MyProgressContentV2() {
  const patientState = useRecoilValue(patientAtom);

  const queryFn = useCallback(
    () => dashboardApi.load(`${patientState?.patientId}`),
    [patientState],
  );
  const [dashboardData, setDashboardData] = useState<DashboardType>();
  useQuery([DASHBOARD_DOMAIN, DASHBOARD_DETAIL_ENDPOINT], queryFn, {
    enabled: !!patientState?.patientId,
    onSuccess: (data) => {
      setDashboardData(data.result);
    },
  });

  useEffect(() => {
    if (!patientState?.patientId) {
      setDashboardData({
        concept: {},
        history: {},
        selfCheckResult: {},
      });
    }
  }, [patientState]);

  return (
    <ContentTemplate>
      {dashboardData && (
        <Stack spacing={'100px'} sx={{}}>
          <Stack spacing={2}>
            <TestResultV2 {...dashboardData.selfCheckResult} />
          </Stack>
        </Stack>
      )}
    </ContentTemplate>
  );
}
