import { Stack, Typography } from "@mui/joy";
import { ValueListByDomainType } from "api/dashboardApi";
import IconButton from "components/common/IconButton";
import { LeftArrow, RightArrow } from "static/image/common/SvgAssets";

export default function ValueWithBehaviorList({
  valueListByDomain,
  goNext,
  goPrevious,
}: {
  valueListByDomain?: ValueListByDomainType;
  goNext?: () => void;
  goPrevious?: () => void;
}) {
  return (
    <Stack
      sx={{
        width: "100%",
        maxWidth: "370px",
      }}
      spacing="20px"
    >
      <Stack direction="row" alignItems={"center"}>
        <IconButton iconNode={<LeftArrow />} onClick={goPrevious} />

        <Stack
          sx={{ flex: 1 }}
          spacing="6px"
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
            {valueListByDomain?.domain}
          </Typography>
        </Stack>
        <IconButton iconNode={<RightArrow />} onClick={goNext} />
      </Stack>
      <Stack
        sx={{
          backgroundColor: "white",
          py: "12px",
          px: "20px",
          borderRadius: "12px",
          borderWidth: "1px",
          borderColor: "divider",
        }}
      >
        {valueListByDomain?.valueList.map(
          ({ value, countValueBehaviorCompleted, countValueBehaviorTotal }) => (
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
              spacing={"8px"}
              sx={{ py: "12px" }}
            >
              <Typography>{value}</Typography>

              <Typography sx={{ fontWeight: 700 }}>
                {countValueBehaviorCompleted}
                <Typography
                  sx={{ fontWeight: 400 }}
                >{`/${countValueBehaviorTotal}`}</Typography>
              </Typography>
            </Stack>
          )
        )}
      </Stack>
    </Stack>
  );
}
