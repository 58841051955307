import { Box, Stack, Typography } from "@mui/joy";

export interface ProgressbarStepType {
  threshold: number;
  label: string;
}
export default function ProgressBar({
  steps = [
    { threshold: 0, label: "분리하기" },
    { threshold: 50, label: "생각함정 찾기" },
    { threshold: 100, label: "완료" },
  ],
  percentage,
}: {
  steps?: ProgressbarStepType[];
  percentage: number;
}) {
  return (
    <Stack sx={{ width: "100%" }} spacing="6px">
      <Box sx={{ px: "8px" }}>
        <Stack
          sx={{
            width: "100%",
            height: "16px",
            position: "relative",
          }}
          justifyContent="center"
        >
          {steps.map(({ threshold, label }, index) => (
            <Box
              key={`marker_${threshold}`}
              sx={{
                position: "absolute",
                zIndex: 10,
                left: `${threshold}%`,
                transform: "translateX(-50%)",
                width: `16px`,
                height: "16px",
                borderRadius: "50%",
                backgroundColor: "white",
                border: "4px solid #FD5005",
                opacity: threshold === percentage ? 1 : 0,
                scale: threshold === percentage ? 1 : 0,
                transition: "all 0.5s",
              }}
            ></Box>
          ))}

          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "6px",
              borderRadius: "10px",
              backgroundColor: "white",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                width: `calc(${percentage}% + 18px)`,
                height: "100%",
                borderRadius: "10px",
                backgroundColor: "#FD5005",
                transition: "all 0.5s",
              }}
            ></Box>
          </Box>
        </Stack>
      </Box>
      <Stack direction="row" justifyContent={"space-between"}>
        {steps.map(({ threshold, label }, index) => (
          <Box
            key={`progressbar_label_${index}`}
            sx={{
              opacity: percentage >= threshold ? 1 : 0,
              transition: "all 0.5s",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                color: "#222222",
                opacity: 0.6,
              }}
            >
              {label}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}
