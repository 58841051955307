export const translation_7_7_A = {
  en: {},
  ko: {
    v1: {
      "$A$ $B$": "$A$ $B$",
      typography_0_0_0: `우리는 지금 이러한 삶의 방향을 향해 나아가고 있어요.`,
      typography_1_0_0:
        "지난번에는 난이도에 따라 다음과 같이 단계를 만들어 보았는데요.",
      typography_2_0_0: `오늘은 $A$단계에 대한 구체적인 계획을 짜보려고 해요.`,
      typography_3_0_0: `계획하기`,
      typography_4_0_0: `$A$(을)를 어떻게 진행할지 구체적으로 계획해 볼게요.`,
      typography_5_0_0:
        "$A$(을)를 누구와 함께할까요? 아니면 혼자 하는 것일까요?",
      typography_6_0_0: `$A$(을)를 할 때에는 정확히 무얼 하는 게 좋을까요?`,
      typography_7_0_0: `$A$(은)는 정확히 어디에서 하는 게 좋을까요?`,
      typography_8_0_0: `$A$(은)는 언제 하는 게 좋을까요?`,
      typography_9_0_0: `$A$(을)를 하는 데에 예상되는 장애물이 있을까요?`,
      typography_10_0_0:
        "만약 실행하는 도중에 그 장애물을 만난다면 어떻게 해결할 수 있을까요?",
      typography_11_0_0: `정리하기`,
      typography_12_0_0: `좋아요. $A$을(를) 해낼 계획을 정리하면 다음과 같겠네요.`,
      typography_13_0_0: `좋아요. 이제 직접 계획한 가치 행동을 실행으로 옮겨볼 텐데요.`,
      typography_14_0_0: `계획한 시각 한 시간 전에 알려 드려 잊지 않게 도와드릴게요.`,
      typography_15_0_0: `알림을 허용해주세요.`,
      typography_16_0_0: `그럼 가치 행동을 실행한 뒤, 다음 활동에서 다시 만나요.`,
    },
  },
};
