import { ProgramType } from "data/programData";
import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const ACTIVITYRECORD_DOMAIN = "activity-record";

export const ACTIVITYRECORD_LOAD_ENDPOINT = "data/load";
export const ACTIVITYRECORD_SAVE_ENDPOINT = "data/save";
export const ACTIVITYRECORD_DONE_ENDPOINT = "done";
export const ACTIVITYRECORD_UNDONE_ENDPOINT = "undone";
export const ACTIVITYRECORD_LIST_ENDPOINT = "list";

interface LoadedDataType {
  data: ProgramType;
}
export interface ActivityRecordType {
  activityRecordKey: string;
  date?: string;
  time?: string;
  activity?: string;
  isDone?: boolean;
  isSucceeded?: boolean;
  isHighlight?: boolean;
  lastDoneAt?: string;
  taskKey?: string;
  shouldBeDone?: boolean; // 프론트에서만 씀
}

export const activityRecordApi = {
  getData: (ark: string) =>
    axiosInstance.get<CustomResponseFormat<LoadedDataType>>(
      `${ACTIVITYRECORD_DOMAIN}/${ACTIVITYRECORD_LOAD_ENDPOINT}`,
      { params: { ark: ark } }
    ),
  saveData: (ark: string, pid: string, body?: ProgramType) =>
    axiosInstance.post<void>(
      `${ACTIVITYRECORD_DOMAIN}/${ACTIVITYRECORD_SAVE_ENDPOINT}`,
      { data: body },
      {
        params: { ark: ark, pid: pid },
      }
    ),
  done: (ark: string) =>
    axiosInstance.post<void>(
      `${ACTIVITYRECORD_DOMAIN}/${ACTIVITYRECORD_DONE_ENDPOINT}`,
      undefined,
      { params: { ark: ark } }
    ),
  undone: (ark: string, pid: string) =>
    axiosInstance.post<void>(
      `${ACTIVITYRECORD_DOMAIN}/${ACTIVITYRECORD_UNDONE_ENDPOINT}`,
      undefined,
      { params: { ark: ark, pid: pid } }
    ),

  getList: (pid: string) =>
    axiosInstance.get<CustomResponseFormat<ActivityRecordType[]>>(
      `${ACTIVITYRECORD_DOMAIN}/${ACTIVITYRECORD_LIST_ENDPOINT}`,
      { params: { pid: pid } }
    ),
};
