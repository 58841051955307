import { Stack, Typography } from "@mui/joy";
import React from "react";
import Answer from "./Answer";
import AudioButton from "./AudioButton";
import NextButton from "./NextButton";
import Summary, { SummaryType } from "./Summary";
export type BlockType = "textarea" | "summary" | "plain" | "audio";
interface PropsType {
  type: BlockType;
  question: string;
  answerLabel?: string;
  handleChange?: (value: string) => void;
  onNextButtonClick?: () => void;
  isNextButtonAvailable: boolean;
  summaryData?: SummaryType;
  onAudioEnded?: () => void;
}

export const Block = React.forwardRef<HTMLDivElement, PropsType>(
  (
    {
      type,
      question,
      answerLabel,
      handleChange,
      onNextButtonClick,
      isNextButtonAvailable,
      summaryData,
      onAudioEnded,
    }: PropsType,
    ref
  ) => {
    return (
      <div ref={ref}>
        <Stack direction="column" spacing={"10px"}>
          <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
            {question}
          </Typography>

          {question.length > 0 &&
            (type === "textarea" ? (
              <Answer answerLabel={answerLabel} handleChange={handleChange} />
            ) : type === "summary" && summaryData ? (
              <Summary data={summaryData} />
            ) : type === "audio" ? (
              <AudioButton onAudioEnded={onAudioEnded} />
            ) : (
              <></>
            ))}

          {onNextButtonClick && (
            <NextButton
              disabled={!isNextButtonAvailable}
              onClick={onNextButtonClick}
            />
          )}
        </Stack>
      </div>
    );
  }
);
