import { Content_6_5_A } from "data/task/v1/6-5-A";
import getCopingStrategyState from "./getCopingStrategyState";

export default async function get_6_5_A_RawData(patientId: string) {
  try {
    const response = await getCopingStrategyState(patientId);

    // 비동기 작업이 완료된 후에 결과를 반환
    console.log("from 6-5-A:", response);

    return {
      taskId: `6-5-A`,
      content: Content_6_5_A(
        response?.hasSurrender || false,
        response?.hasOvercompensation || false,
        response?.hasAvoidance || false
      ),
    };
  } catch (error) {
    // 에러 핸들링
    console.error("Error in get_6_5_A_RawData:", error);
    throw error; // 또는 에러에 대한 처리를 진행할 수 있음
  }
}
