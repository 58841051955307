export const translation_4_4_4_A = {
  en: {
    v1: {
      평가: "Judgment",
      기술: "Description",
      typography_0_0_0: "Let's delve a bit deeper into 'Judgment'.",
      typography_1_0_0:
        "Judgment, also known as 'self-assessment', is literally valuing situations or oneself.",
      typography_2_0_0: "Judgment in itself isn’t bad.",
      typography_3_0_0:
        "We’ll focus on how Judgment can negatively impact our minds and understand its mechanics.",
      typography_4_0_0: "Understanding Judgment",
      typography_5_0_0:
        "One common ‘unhelpful thought’ that’s hard to distance from is Judgment.",
      typography_6_0_0:
        "To grasp Judgment, first understanding ‘description’ is necessary.",
      typography_7_0_0:
        "Description refers to primary attributes of a subject, like in the examples below.",
      typography_7_3_0: "This is a table 'made of wood'.",
      typography_7_5_0: "I'm 'feeling anxious', and my 'heart is racing'.",
      typography_8_0_0:
        "Such descriptions generally relate to primary attributes experienced or observed through senses.",
      typography_9_0_0:
        "On the other hand, judgment involves our value decisions, or secondary attributes, about something.",
      typography_10_0_0:
        "We often attach evaluative labels to things, as in the examples below.",
      typography_10_3_0: "This is a 'great' table.",
      typography_10_5_0: "This anxiety is 'so awful, I can hardly stand it'.",
      typography_11_0_0:
        "Thus, judgment is more about our value decisions on something rather than the thing itself.",
      typography_12_0_0:
        "Evaluations like 'good', 'unbearable', 'unfair' evoke specific emotions in us, just like the word 'lemon' stimulates salivation.",
      typography_13_0_0:
        "If it’s hard to identify an evaluative statement, simply categorize it for now as 'good' or 'bad'.",
      typography_14_0_0:
        'Statements like "This situation is unbearable", "I’m stupid and pathetic", "Why am I so lazy and unproductive?" are, in essence, simplified as:',
      typography_15_0_0:
        'This situation is bad, "I am not good", "Why am I so bad?"',
      typography_16_0_0: "Distinguishing Judgment from description",
      typography_17_0_0:
        "Now, let’s differentiate between judgment and description.",
      typography_18_0_0:
        "If we say, 'This chair is good', is it judgment or description?",
      typography_18_3_0: "Read the above and think it over once more.",
      typography_19_0_0:
        "Okay, now if we say, 'This chair is made of wood', is this judgment or description?",
      typography_19_3_0: "Read the above and think it over once more.",
      typography_20_0_0:
        "What if someone else points to the wooden chair and says, 'No, this chair is metal. You’re wrong.'? Is there a way to determine which is correct?",
      typography_21_0_0:
        "There probably is a way. We could check if it’s really wood or analyze it scientifically.",
      typography_22_0_0:
        "If you say the chair is 'good' and someone else says it's 'bad', can we determine which judgment is correct?",
      typography_23_0_0:
        "Probably not. Since judgments about the chair’s value are personal, we can't definitively say what’s right.",
      typography_24_0_0:
        "Clearly, the chair’s 'goodness' or 'badness' is not an intrinsic attribute.",
      typography_25_0_0:
        "Let’s examine an individual's records. Decide if the following are judgments or descriptions.",
      typography_26_0_0: "I scored 20 on the test.",
      typography_26_3_0: "Read the above and think it over once more.",
      typography_27_0_0: "I’m such an idiot for not scoring well.",
      typography_27_3_0: "Read the above and think it over once more.",
      typography_28_0_0:
        'In the example, "I scored 20 on the test" is a description of my situation, a verifiable attribute about me.',
      typography_29_0_0:
        'However, "I’m such an idiot" is not an attribute. It’s a value judgment, a psychological event in my mind.',
      typography_30_0_0:
        'If we strongly react to the judgment "I’m such an idiot", it narrows our life’s repertoire.',
      typography_31_0_0: "How does it narrow our life?",
      typography_32_0_0:
        "If I believe I’m foolish, it might seem wiser not to engage in intellectually demanding tasks.",
      typography_33_0_0:
        "One might ruminate on such judgments (e.g., 'Why am I so foolish?').",
      typography_34_0_0:
        "We might even establish negative self-concepts (e.g., 'I was the person who caused such outcomes; I’m foolish').",
      typography_35_0_0:
        "Judgments thus rigidly constrain our minds psychologically.",
      typography_36_0_0: "Judgments also trigger reflexive reactions.",
      typography_37_0_0:
        "Let’s examine another person’s records. Decide if these are judgments or descriptions.",
      typography_38_0_0:
        "I have to present in front of 500 people and feel nervous about making mistakes.",
      typography_38_3_0: "Read the above and think it over once more.",
      typography_39_0_0:
        "Damn, I’m pathetic. I’m so anxious, I can’t stand it. I wish I could disappear.",
      typography_39_3_0: "Read the above and think it over once more.",
      typography_40_0_0:
        'In the record above, "I have to present in front of 500 people" and "feeling nervous" are descriptions of what that person is experiencing.',
      typography_41_0_0:
        "It's the actual situation they're in, and it's how they're really feeling.",
      typography_42_0_0:
        'But "pathetic", "can’t stand it", "wish to disappear" are not attributes within the person.',
      typography_43_0_0: "They’re psychological events in the mind.",
      typography_44_0_0:
        'If we react literally to "can’t stand it, wish to disappear", what might happen?',
      typography_45_0_0:
        "Anxiety might make you forget the presentation. Your voice might shake. You might give up at the slightest mistake.",
      typography_46_0_0:
        "This isn’t to say feeling this way is wrong or you’re the problem.",
      typography_47_0_0:
        "It’s natural to feel and think this way in certain situations.",
      typography_48_0_0:
        "But if we realize these judgments are not our attributes but value decisions created by thoughts, we can choose not to react to them as 'unhelpful'.",
      typography_49_0_0:
        'What if we could look at our thoughts from a distance, like "I’m having thoughts that it’s hard for me to handle this anxiety"?',
      typography_50_0_0:
        'The thought "Damn, I’m so anxious I can’t stand it" differs greatly from "I’m having thoughts about struggling with anxiety".',
      typography_51_0_0:
        "The former, like a lemon, quickly triggers a symbolic effect, while the latter creates space between us and our minds.",
      typography_52_0_0:
        "We need to create this space by distancing ourselves from our thoughts.",
      typography_53_0_0: "Distancing from Judgment",
      typography_54_0_0:
        "Escaping Judgment thinking traps is simpler than it seems.",
      typography_55_0_0:
        "Before you get too deep into these popcorn-like thoughts,",
      typography_56_0_0: "notice that they've popped into your mind,",
      typography_57_0_0: "and take a step back to look at them objectively.",
      typography_58_0_0: "distinguishing between judgment and description.",
      typography_59_0_0:
        "Realizing that judgments are not us is very useful. That’s where distancing from negative judgments begins.",
      typography_60_0_0:
        'It’s not just about understanding the concept that "judgments are not me".',
      typography_61_0_0:
        "The key is noticing when such thoughts arise and habitually separating judgment from description, distancing from their negative impact.",
      typography_62_0_0:
        "We’ll tackle Judgment thinking traps systematically ahead.",
      typography_63_0_0:
        "If you get into the habit of separating judgments from descriptions and keeping a distance,",
      typography_64_0_0:
        "you'll be able to handle any negative thoughts more flexibly and bounce back stronger, no matter what comes up in your mind.",
      typography_65_0_0:
        "Now, shall we try distinguishing judgments from descriptions about ourselves?",
    },
  },
  ko: {
    v1: {
      평가: "평가",
      기술: "기술",
      typography_0_0_0: "이번에는 '평가'에 대해 조금 더 자세히 이해해볼게요.",
      typography_1_0_0:
        "평가는 '자기 평가'라고도 하는데요. 문자 그대로 자신과 관련된 상황이나 자기 자신에 대한 가치 판단을 뜻해요.",
      typography_2_0_0: "평가 그 자체가 나쁜 건 아니에요.",
      typography_3_0_0:
        "우리는 평가가 우리 마음속에서 어떻게 부정적인 영향을 미칠 수 있는지, 그 원리에 초점을 맞춰 이야기를 나눠보도록 할게요.",
      typography_4_0_0: "평가 이해하기",
      typography_5_0_0:
        "거리두기가 잘되지 않는 '도움이 되지 않는 생각' 중 가장 흔한 것은 평가예요.",
      typography_6_0_0:
        "우선 평가를 이해하기 위해서는 먼저 '기술'을 이해할 필요가 있어요.",
      typography_7_0_0:
        "기술은 어떤 대상의 일차적인 속성을 뜻해요. 아래와 같은 예시처럼요.",
      typography_7_3_0: "이것은 '나무로 만든' 탁자다.",
      typography_7_5_0:
        "나는 '불안을 느끼고' 있으며, 내 '심장은 빠르게 뛰고' 있다.",
      typography_8_0_0:
        "이처럼 기술은 주로 감각을 통해 직접 경험하거나 관찰할 수 있는 일차적인 속성을 뜻해요.",
      typography_9_0_0:
        "반면 평가는 그 대상에 대한 우리의 가치 판단, 즉, 이차적인 속성을 말해요.",
      typography_10_0_0:
        "우리는 어떠한 대상에 평가적인 설명을 붙일 수 있죠. 아래와 같은 예시처럼요.	",
      typography_10_3_0: "이것은 '좋은' 탁자다.",
      typography_10_5_0: "이런 불안은 '끔찍해서 도저히 견딜 수 없다.'",
      typography_11_0_0:
        "이처럼 평가는 어떠한 대상 그 자체보다는, 그것에 대한 우리들의 가치 판단으로 만들어져요.",
      typography_12_0_0:
        "'좋은', '견딜 수 없는', '부당한' 같은 평가는 그 상징적인 효과를 통해 마치 레몬이라는 단어가 침샘을 자극한 것과 같이 우리에게 특정한 감정을 불러일으켜요.",
      typography_13_0_0:
        "어떤 표현이 평가인지 이해하기 어렵다면 지금은 단순하게 이분법적으로 나누어 이해해도 괜찮아요. '좋다'와 '나쁘다'로요.",
      typography_14_0_0:
        '"이 상황은 정말 견딜 수가 없다", "나는 바보 같고 한심하다", "나는 왜 이렇게 게으르고 생산적이지 못한 걸까?"라는 평가도 결국 아주 단순하게 바라보면,',
      typography_15_0_0:
        '"이 상황은 너무 나쁘다", "나는 정말 좋지 않다", "나는 왜 이렇게 나쁜 걸까?"와 같은 의미니까요.',
      typography_16_0_0: "평가와 기술 구분하기",
      typography_17_0_0: "자, 이제 평가와 기술의 차이를 구분해볼게요.",
      typography_18_0_0:
        "여기 있는 의자는 '좋은' 의자라고 말한다면, 그것은 평가일까요, 기술일까요?",
      typography_18_3_0: "위의 내용을 읽고 다시 한 번 생각해봐요.",
      typography_19_0_0:
        "좋아요. 이번엔 이 의자는 '나무로 만든 것'이라고 말한다면 어떨까요? 이것은 평가인가요, 기술인가요?",
      typography_19_3_0: "위의 내용을 읽고 다시 한 번 생각해봐요.",
      typography_20_0_0:
        "만일 다른 사람이 와서 나무 의자를 가리켜 '아니, 이 의자는 나무가 아니라 금속으로 되어 있어. 너의 생각은 틀렸어.'라고 이야기한다면 어떨까요? 어느 것이 맞는지 판단한 방법이 있을까요?",
      typography_21_0_0:
        "아마 판단할 방법이 있을 거예요. 잘라서 실제 나무인지 확인해보아도 되고, 과학적인 분석을 해보아도 되겠죠.",
      typography_22_0_0:
        "이번에는 의자를 가리켜 당신은 의자가 '좋다'고 말하고, 다른 사람은 의자가 '나쁘다'라고 말한다면 어떨까요? 두 평가 중 어떤 것이 맞는지 판단할 방법이 있나요?",
      typography_23_0_0:
        "아마 적절한 방법이 없을 거예요. 의자에 대한 가치판단인 평가는 대상에 대한 나의 가치판단이기 때문에 무엇이 맞다고 할 수는 없기 때문이에요.",
      typography_24_0_0:
        "의자의 '좋음'이나 '나쁨'은 분명 의자 자체의 특성이 아니에요.",
      typography_25_0_0:
        "이번엔 한 사람의 기록을 살펴볼게요. 아래 두 기록이 평가에 해당하는지, 기술에 해당하는지 선택해보세요.",
      typography_26_0_0: "나는 시험에서 20점을 받았다.",
      typography_26_3_0: "위의 내용을 읽고 다시 한 번 생각해봐요.",
      typography_27_0_0: "좋은 점수를 받지 못했다니, 나는 정말 바보 같다.",
      typography_27_3_0: "위의 내용을 읽고 다시 한 번 생각해봐요.",
      typography_28_0_0:
        '위 예시에서도 마찬가지로 "나는 시험에서 20점을 받았다"는 나의 상황에 대한 기술이에요. 그건 확인할 수 있는 나에 대한 속성이죠.',
      typography_29_0_0:
        '반면 "나는 정말 바보 같다"라는 평가는 나의 특성이 아니에요. 가치판단이죠. 마음속에 떠오른 하나의 심리적 사건이에요.',
      typography_30_0_0:
        '"나는 정말 바보 같다"라는 평가에 강하게 반응한다면 삶의 레퍼토리가 좁아지는 결과가 발생해요.',
      typography_31_0_0: "삶의 레퍼토리가 어떻게 좁아지는지 볼까요?",
      typography_32_0_0:
        "만일 내가 바보라면 제법 지성을 요구하는 행위는 하지 않는 것이 더 현명하겠죠.",
      typography_33_0_0:
        "그러한 평가를 끊임없이 반추하며 곱씹을 수도 있을 거예요(예: '나는 대체 왜 이렇게 바보 같을까?').",
      typography_34_0_0:
        "아주 그럴듯한 이야기를 만들어 스스로에 대한 부정적인 개념을 확립할 수도 있죠(예: '나는 당시에 이러이러한 결과를 만들었던 사람이고, 그런 나는 바보 같은 존재다').",
      typography_35_0_0:
        "이처럼 평가는 우리의 마음을 특정한 방향으로 강하게 제한하게 해요. 심리적으로 경직되게 하는 것이죠.",
      typography_36_0_0: "한편 평가는 반사적인 반응을 만들어내기도 해요.",
      typography_37_0_0:
        "다른 사람의 기록을 살펴볼게요. 아래 두 기록이 평가에 해당하는지, 기술에 해당하는지 선택해 보세요.",
      typography_38_0_0:
        "나는 500명 앞에서 발표를 해야 하고 실수를 할까봐 긴장한 상태다.",
      typography_38_3_0: "위의 내용을 읽고 다시 한 번 생각해봐요.",
      typography_39_0_0:
        "젠장, 너무 한심해. 불안해서 버틸 수가 없어. 사라져 버리고 싶어.",
      typography_39_3_0: "위의 내용을 읽고 다시 한 번 생각해봐요.",
      typography_40_0_0:
        '위 기록에서 "나는 500명 앞에서 발표를 해야 한다" 그리고 "긴장한 상태"라는 기술은 그 사람의 특성이에요.',
      typography_41_0_0:
        "실제 그 사람이 처한 상황이고, 그 사람은 그렇게 느끼고 있으니까요.",
      typography_42_0_0:
        '하지만 그 상황에서 "한심하다", "버틸 수가 없다", "사라져 버리고 싶다"라는 평가는 그 사람 안에 있는 특성이 아니랍니다.',
      typography_43_0_0: "마음속에 떠오른 심리적 사건이죠.",
      typography_44_0_0:
        '만약 "버틸 수 없다, 사라지고 싶다"라는 평가에 대해 곧이곧대로 반응하게 된다면 어떻게 될까요?',
      typography_45_0_0:
        "너무 불안해서 발표 내용을 다 까먹을지도 몰라요. 목소리가 떨릴지도 모르죠. 조금만 실수를 해도 그냥 포기해 버릴 수도 있을 거예요.",
      typography_46_0_0:
        "그렇게 느끼는 것이 잘못됐고, 결국 우리가 문제라고 이야기하고 싶은 게 아니랍니다.",
      typography_47_0_0:
        "충분히 그렇게 느낄 만한 상황이고, 또 그렇게 느끼고 생각하는 게 당연했을 수도 있어요.",
      typography_48_0_0:
        "하지만 가만히 생각해보았을 때 정말로 그 평가가 나의 특성이 아니고, 생각이 만든 가치 판단이라면, 우리는 그것이 '도움되지 않는다'고 이야기하고 반응하지 않을 수도 있답니다.",
      typography_49_0_0:
        '만약 이 상황에서 "나는 너무 불안해서 버티기 힘들다는 생각을 하고 있다"라는 식으로 나의 생각을 거리두고 바라볼 수 있다면 어떨까요?',
      typography_50_0_0:
        '"젠장, 너무 불안해서 버틸 수가 없어"라는 생각은 "나는 너무 불안해서 버티기 힘들다는 생각을 하고 있다"라는 생각과 매우 다르답니다.',
      typography_51_0_0:
        "전자의 생각은 마치 레몬처럼 아주 빠르게 침샘에서 침이 퍼져나가도록 상징적 효과를 발휘하는 반면, 후자의 생각은 마음과 나 사이에 공간을 마련하죠.",
      typography_52_0_0:
        "우리는 마음을 후자와 같이 바라보며 생각과의 거리두기를 통해 공간감을 만들 필요가 있어요.",
      typography_53_0_0: "평가로부터 거리두기",
      typography_54_0_0: "평가 생각함정을 벗어나는 방법은 생각보다 간단해요.",
      typography_55_0_0:
        "팝콘처럼 마음속에 떠오른 생각에 강하게 매몰되기 전에,",
      typography_56_0_0: "그러한 생각이 마음속에 떠올랐음을 인지하고,",
      typography_57_0_0:
        "한 걸음 떨어져서 그 생각이 어떠한 생각인지 객관적으로 살펴보는 것이죠.",
      typography_58_0_0: "평가와 기술을 구분하면서요.",
      typography_59_0_0:
        "평가가 나 자신이 아님을 인지하는 것은 매우 유용할 거예요. 그곳에서부터 부정적인 평가로부터 거리두기가 시작되니까요.",
      typography_60_0_0:
        '이건 단순히 이해한다고 해결될 문제가 아니랍니다. "평가가 나 자신이 아니다"라는 문장 자체를 이해하는 건 어렵지 않아요.',
      typography_61_0_0:
        "중요한 건 그런 생각이 들었을 때를 알아차리고, 의도적으로 평가와 기술을 분리하며 부정적인 영향으로부터 거리를 두는 게 얼마나 습관적으로 진행될 수 있느냐는 것이죠.",
      typography_62_0_0:
        "우리는 앞으로 체계적인 방법을 통해 평가 생각함정들을 공략할 건데요.",
      typography_63_0_0:
        "만약 여러분이 습관적으로 평가와 기술을 구분하여 거리를 둘 수 있다면",
      typography_64_0_0:
        "마음속에 어떤 부정적인 생각이 떠올라도 보다 유연하고 회복탄력성 있게 대처할 수 있을 거랍니다.",
      typography_65_0_0:
        "자, 그러면 이제 나에 대한 평가와 기술을 구분해 볼까요?",
    },
    v2: {
      typography_0: "이번에는 '평가'에 대해 조금 더 자세히 이해해볼게요.",
      typography_1:
        "평가는 '자기 평가'라고도 하는데요. 문자 그대로 자신과 관련된 상황이나 자기 자신에 대한 가치 판단을 뜻해요.",
      typography_2: "평가 그 자체가 나쁜 건 아니에요.",
      typography_3:
        "우리는 평가가 우리 마음속에서 어떻게 부정적인 영향을 미칠 수 있는지, 그 원리에 초점을 맞춰 이야기를 나눠보도록 할게요.",
      typography_4: "평가 이해하기",
      typography_5:
        "우선 평가를 이해하기 위해서는 먼저 '사실'을 이해할 필요가 있어요.",
      typography_6:
        "사실은 어떤 대상의 일차적인 속성을 뜻해요. 아래와 같은 예시처럼요.",
      typography_6_1: "이것은 '나무로 만든' 탁자다.",
      typography_6_2:
        "나는 '불안을 느끼고' 있으며, 내 '심장은 빠르게 뛰고' 있다.",
      typography_7:
        "이처럼 사실은 주로 감각을 통해 직접 경험하거나 관찰할 수 있는 일차적인 속성을 뜻해요.",
      typography_8:
        "반면 평가는 그 대상에 대한 우리의 가치 판단, 즉, 이차적인 속성을 말해요.",
      typography_9:
        "우리는 어떠한 대상에 평가적인 설명을 붙일 수 있죠. 아래와 같은 예시처럼요.	",
      typography_9_1: "이것은 '좋은' 탁자다.",
      typography_9_2: "이런 불안은 '끔찍해서 도저히 견딜 수 없다.'",
      typography_10:
        "이처럼 평가는 어떠한 대상 그 자체보다는, 그것에 대한 우리들의 가치 판단으로 만들어져요.",
      typography_11:
        "'좋은', '견딜 수 없는', '부당한' 같은 평가는 그 상징적인 효과를 통해 마치 레몬이라는 단어가 침샘을 자극한 것과 같이 우리에게 특정한 감정을 불러일으켜요.",
      typography_12:
        "어떤 표현이 평가인지 이해하기 어렵다면 지금은 단순하게 이분법적으로 나누어 이해해도 괜찮아요. '좋다'와 '나쁘다'로요.",
      typography_13:
        '"이 상황은 정말 견딜 수가 없다", "나는 바보 같고 한심하다", "나는 왜 이렇게 게으르고 생산적이지 못한 걸까?"라는 평가도 결국 아주 단순하게 바라보면,',
      typography_14:
        '"이 상황은 너무 나쁘다", "나는 정말 좋지 않다", "나는 왜 이렇게 나쁜 걸까?"와 같은 의미니까요.',
      typography_15: "평가와 사실 구분하기",
      typography_16: "자, 이제 평가와 사실의 차이를 구분해볼게요.",
      typography_17:
        "여기 있는 의자는 '좋은' 의자라고 말한다면, 그것은 평가일까요, 사실일까요?",
      typography_quiz_incorrect: "위의 내용을 읽고 다시 한 번 생각해봐요.",
      typography_18:
        "좋아요. 이번엔 이 의자는 '나무로 만든 것'이라고 말한다면 어떨까요? 이것은 평가인가요, 사실인가요?",
      typography_19:
        "만일 다른 사람이 와서 나무 의자를 가리켜 '아니, 이 의자는 나무가 아니라 금속으로 되어 있어. 너의 생각은 틀렸어.'라고 이야기한다면 어떨까요? 어느 것이 맞는지 판단한 방법이 있을까요?",
      typography_20:
        "아마 판단할 방법이 있을 거예요. 잘라서 실제 나무인지 확인해보아도 되고, 과학적인 분석을 해보아도 되겠죠.",
      typography_21:
        "이번에는 의자를 가리켜 당신은 의자가 '좋다'고 말하고, 다른 사람은 의자가 '나쁘다'라고 말한다면 어떨까요? 두 평가 중 어떤 것이 맞는지 판단할 방법이 있나요?",
      typography_22:
        "아마 적절한 방법이 없을 거예요. 그것은 의자에 대한 나의 가치판단이기 때문에 무엇이 맞다고 할 수는 없기 때문이에요.",
      typography_23:
        "의자의 '좋음'이나 '나쁨'은 분명 의자 자체의 특성이 아니에요.",
      typography_24:
        "이번엔 한 사람의 기록을 살펴볼게요. 아래 두 기록이 평가에 해당하는지, 사실에 해당하는지 선택해보세요.",
      typography_25: "나는 시험에서 20점을 받았다.",
      typography_26: "좋은 점수를 받지 못했다니, 나는 정말 바보 같다.",
      typography_27:
        '위 예시에서도 마찬가지로 "나는 시험에서 20점을 받았다"는 나의 상황에 대한 사실이에요. 그건 확인할 수 있는 나에 대한 속성이죠.',
      typography_28:
        '반면 "나는 정말 바보 같다"라는 평가는 나의 특성이 아니에요. 가치판단이죠. 마음속에 떠오른 하나의 심리적 사건이에요.',

      typography_29: "평가라는 함정",
      typography_30:
        "많은 괴로움, 특히 스스로를 향하는 어떤 생각들 때문에 발생하는 괴로움은 주로 사실과 가치 판단을 혼동하는 과정에서 발생해요.",
      typography_31: `“나는 형편없다”라는 생각이 마음속에 떠오를 순 있어요.`,
      typography_32:
        "하지만 마음속에 어떤 생각이 떠올랐다고 그것이 ‘나’ 자체에 내재된 속성이자 사실인 건 아니에요.",
      typography_33: "내가 정말로 형편없는 것은 아니랍니다.",
      typography_34: `‘형편없다’는 건 나의 몸 그 어느곳에도 존재하지 않아요.`,
      typography_35: `‘형편없다’는 수식어는 나의 내재적인 특성이 아니에요.`,
      typography_36: "마음이 만들어낸 평가인 것이지요. 가치 판단입니다.",
      typography_37:
        "이 평가는 마치 레몬이 침샘에서 침을 분비시키는 것처럼 아주 강력한 상징적 효과를 발휘해요.",
      typography_38:
        "하지만 이것이 평가라는 점을 인지할 수 있다면 평가는 그 상징적 효과를 잃어버려요.",
      typography_39:
        "생생해 보였던 레몬도 실은 빈 껍데기라는 것이 드러난답니다.",
      typography_40:
        "내가 형편없는 게 아니라, 나의 마음속에 “형편없다”라는 생각이 떠올랐고 내가 그것을 사실인 양 받아들이고 반응했을 뿐이에요.",
      typography_41: `“나는 형편없다”라는 생각과 “‘나는 형편없다’는 생각이 마음속에 떠올랐구나”라는 생각은 아주 큰 차이가 있답니다.`,
      typography_42: "평가로부터 거리두기",
      typography_43: "평가 생각함정을 벗어나는 방법은 생각보다 간단해요.",
      typography_44: "팝콘처럼 마음속에 떠오른 생각에 강하게 매몰되기 전에,",
      typography_45: "그러한 생각이 마음속에 떠올랐음을 인지하고,",
      typography_46:
        "한 걸음 떨어져서 그 생각이 어떠한 생각인지 객관적으로 살펴보는 것이죠.",
      typography_47: "평가와 사실을 구분하면서요.",
      typography_48:
        "평가가 나 자신이 아님을 인지하는 것은 매우 유용할 거예요. 그곳에서부터 부정적인 평가로부터 거리두기가 시작되니까요.",
      typography_49:
        '이건 단순히 이해한다고 해결될 문제가 아니랍니다. "평가가 나 자신이 아니다"라는 문장 자체를 이해하는 건 어렵지 않아요.',
      typography_50:
        "중요한 건 그런 생각이 들었을 때를 알아차리고, 의도적으로 평가와 사실을 분리하며 부정적인 영향으로부터 거리를 두는 게 얼마나 습관적으로 진행될 수 있느냐는 것이죠.",
      typography_51:
        "우리는 앞으로 체계적인 방법을 통해 평가 생각함정들을 공략할 건데요.",
      typography_52:
        "만약 여러분이 습관적으로 평가와 사실을 구분하여 거리를 둘 수 있다면",
      typography_53:
        "마음속에 어떤 부정적인 생각이 떠올라도 보다 유연하고 회복탄력성 있게 대처할 수 있을 거랍니다.",
      typography_54: "자, 그러면 이제 나에 대한 평가와 사실을 구분해 볼까요?",
    },
  },
};
