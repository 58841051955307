export const translation_9_4_A = {
  en: {},
  ko: {
    v1: {
      typography_0: `지금까지 <생각기록지>에 상황, 생각, 감정, 신체 반응, 행동을 나눠서 적는 것, 즉 분리하기(isolation)를 연습했을 텐데요.`,
      typography_1: `사실 부정적인 감정이 강할 때에는 그것을 털어놓기에 바빠 이를 제대로 관찰하여 기록하기 어려웠을 때가 많았을 거예요.`,
      typography_2: `지금부터는 부정적인 경험을 마주했을 때 이를 거부하지 않고 오히려 더 꼼꼼하고 세밀하게 관찰하는 연습을 해 볼 거랍니다.`,
      typography_3: `마치 나 자신의 바라보는 적극적인 관찰자가 된 것처럼 마주하고 있는 심리적인 경험을 최대한 파악하고 알아차려보는 거예요.`,
      typography_4: `혹시나 그 과정에서 감정적으로 힘든 순간이 있다면 앞서 배웠던 ‘심호흡 그라운딩', ‘근육이완법', ‘호흡 명상’을 활용해보아도 좋아요.`,
      typography_5: `자, 오늘은 상황을 더 잘 관찰하고 분리하며 기꺼이 경험하는 연습을 해볼게요.`,
      typography_6: `사실과 해석 구별하기`,
      typography_7: `‘분리하기'를 할 때 가장 힘든 부분은 사실과 해석을 구별하는 것이에요.`,
      typography_8: `특히 마음이 힘들 땐 사실과 해석을 섞어서 기록할 때가 많은데요.`,
      typography_9: `지금부터는 한 발짝 떨어져서 부정적인 감정이 들 때 나는 어떤 상황에 놓여있고, 어떤 생각을 하게 되는지 최대한 자세히 관찰해보는 거예요.`,
      typography_10: `사람들은 때로 추측하거나 예상한 것을 사실로 보고 하기도 해요.`,
      typography_11: `아래와 같은 식이죠.`,
      typography_12: `상황: “상사가 나를 해고하고 싶어 함" (추측)`,
      typography_13: `상황: “회사에서 해고될 위기에 처함" (예상)`,
      typography_14: `하지만 이러한 해석은 사실이 아니라 나의 생각이랍니다.`,
      typography_15: `기꺼이 경험하며 관찰할 때에는 어떤 생각이 들었는지 떠올려보고,`,
      typography_16: `“나는 어떠한 사실에 그러한 생각을 하게 되었나?”라고 질문해보며, 사실 자체만을 상황에 기록해보세요.`,
      typography_17: `위 예시에는 다음과 같은 것들이 상황에 속할 수 있을 거예요.`,
      typography_18: `상황: “상사가 나에게 지난 프로젝트가 좋진 않았다고 이야기함"`,
      typography_19: `상황: “상사가 아침에 인사를 받지 않음"`,
      typography_20: `아마 부정적인 감정에 휩싸였을 때에는 이런 일이 쉽진 않을 텐데요.`,
      typography_21: `그때 “지금 이런 감정을 느끼고 있구나"라고 생각하며 그 감정이 내 마음속에 머무르도록 허용해두고,`,
      typography_22: `한 발짝 떨어져서 스스로를 더 자세히 관찰하며 사실과 해석을 엄밀하게 구분해보는 거예요.`,
      typography_23: `내적 사건과 외적 사건`,
      typography_24: `우리는 일반적으로 일상 속에서 발생한 일들을 상황에 적었을 텐데요.`,
      typography_25: `이처럼 나의 외부에서 발생한 상황을 외적 사건이라고 해요.`,
      typography_26: `한편 생각, 감정, 신체 반응, 행동도 상황이 될 수 있답니다.`,
      typography_27: `이를 내적 사건이라고 해요.`,
      typography_28: `생각도 때론 상황이 될 수도 있답니다.`,
      typography_29: `예를 들면 어떠한 기억이나 생각이 침습적으로 찾아올 때가 있어요.`,
      typography_30: `이럴 경우에는 침습적으로 찾아온 기억이나 생각 그 자체를 상황에 적어도 괜찮아요.`,
      typography_31: `감정도 상황이 될 수 있답니다.`,
      typography_32: `아침에 일어났더니 무기력함을 느꼈고, 그 무기력함이 부정적인 생각과 감정을 만들어낼 수도 있답니다.`,
      typography_33: `신체 반응도 상황이 될 수 있어요.`,
      typography_34: `예를 들면 사람이 많은 공간에 놓인 후 가슴이 두근거리는 걸 느꼈을 때, 이는 중요한 트리거가 되기도 한답니다.`,
      typography_35: `이후 "어, 이러면 안 되는데"와 같은 생각이 이어질 수 있죠.`,
      typography_36: `행동도 상황이 될 수 있답니다.`,
      typography_37: `나도 모르게 방어적인 답변과 행동을 한 후, 부정적인 생각이나 수치심을 느낄 수도 있을 거예요.`,
      typography_38: `이처럼 내적 사건도 상황이 될 수 있답니다.`,
      typography_39: `앞으로는 부정적인 감정에 휩싸였을 때, 그 감정이 내 마음속에 머무르도록 허용해두고`,
      typography_40: `이 감정의 출발이 무엇이었는지 잘 관찰한 후, 이를 상황에 적도록 해보세요.`,
      typography_41: `종종 내적 사건이 중요한 상황이 되기도 한답니다.`,
      typography_42: `간접 상황과 직접 상황`,
      typography_43: `앞으로는 상황을 다음 두 가지로 나눠서 적어볼 거예요.`,
      typography_44: `첫 번째는 간접 상황이에요.`,
      typography_45: `간접 상황은 나의 반응에 영향을 미칠 수 있는 전반적인 맥락을 뜻해요.`,
      typography_46: `예를 들면 잠이 부족하거나, 식사를 거르거나, 아프거나, 일시적인 장애를 겪거나, 과음했거나, 약물을 복용했거나, 많은 스트레스를 주는 사건을 경험한 경우에는 당연히 나의 반응이 더 예민해질 수밖에 없겠죠.`,
      typography_47: `간접 상황에서는 최근의 맥락을 써도 괜찮아요.`,
      typography_48: `가령 사람들이 계속 돈을 빌려달라고 요청하는 상황이라면 처음에 요청했던 사람보다 열 번째로 요청한 사람에게 화낼 가능성이 높겠죠.`,
      typography_49: `이때 “지난 며칠간 사람들이 계속 돈을 빌려달라고 요청함"은 중요한 간접 상황이 될 수 있을 거예요.`,
      typography_50: `두 번째는 직접 상황이에요.`,
      typography_51: `직접 상황은 나의 생각, 신체 반응, 감정을 유발한 직접적인 트리거를 뜻해요.`,
      typography_52: `그렇다 보니 직접 상황은 나의 생각, 신체 반응, 감정이 시작되기 바로 직전에 일어난 사건을 뜻한답니다.`,
      typography_53: `즉, 다른 순간이 아닌 특정 순간에 나의 반응이 시작되게 만든 단서가 직접 상황이에요.`,
      typography_54: `위 예시에서는 열 번째로 돈을 빌려달라고 요청한 사람이 민수라면 “민수가 나에게 돈을 빌려달라고 연락이 옴"이 직접 상황에 해당될 거예요.`,
      typography_55: `이처럼 간접 상황과 직접 상황을 잘 구분해서 적으면 나의 반응을 더 잘 이해할 수 있고, 때론 문제의 실마리를 더 잘 찾아낼 수도 있답니다.`,
      typography_56: `왜 이것이 중요한가요?`,
      typography_57: `마지막으로 이 과정이 '기꺼이 경험하기'를 하는 데에 중요한 이유에 대해서 한 번 더 설명드릴게요.`,
      typography_58: `부정적인 심리적 경험을 했을 때, 이를 더 자세히 관찰하며 사실과 해석을 구별하고,`,
      typography_59: `내적 사건과 외적 사건을 고려하고,`,
      typography_60: `간접 상황과 직접 상황을 찾아내려면 그 경험 자체에 더 잘 머무를 수 있어야 해요.`,
      typography_61: `피하려고 애쓰지 않고, 그 또한 나의 마음속에 떠오르는 하나의 심리적 사건으로 바라보며 기꺼이 경험해야 관찰할 수 있을 겁니다.`,
      typography_62: `말씀드렸던 것처럼 이 일이 쉽진 않을 거예요.`,
      typography_63: `하지만 그럴 때마다 함께 연습했던 명상 도구들을 활용하며 기록을 더 잘 이어 나갈 수 있도록 연습해보세요.`,
      typography_64: `연습하다 보면 어느샌가 생각에 휩싸이지 않고 사실과 해석을 구별하며 거리를 두고 바라보고 있는 자신을 발견하게 될 거랍니다.`,
      typography_65: `자, 그러면 오늘 배운 내용을 토대로 기록을 작성해볼까요?`,
    },
  },
};
