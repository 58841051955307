import { createSvgIcon } from "@mui/material";
import { extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";

export default function HighlightIndicator() {
  const color = extractHexColor(theme.vars.palette.primary.solid);
  const LogoIcon = createSvgIcon(
    <svg
      width="24"
      height="30"
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 18C24 24.6274 18.6274 30 12 30C5.37258 30 0 24.6274 0 18C0 12.7505 7.52892 4.52378 10.6596 1.32991C11.4036 0.570869 12.5964 0.570869 13.3404 1.32991C16.4711 4.52378 24 12.7505 24 18Z"
        fill={color}
      />
    </svg>,
    "HighlightIcon"
  );

  return <LogoIcon />;
}
