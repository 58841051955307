import { debounce } from "lodash";
import { useState, useEffect, useCallback } from "react";
import { getMessageFromApp } from "utils/webview";

export default function useGetEventFromApp() {
  const [parsedMessage, setParsedMessage] = useState<any>();

  useEffect(() => {
    getMessageFromApp(setParsedMessage);
  }, []);

  return parsedMessage;
}
