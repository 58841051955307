import { ProgramContentType } from "data/BlockComponent";
import { atom } from "recoil";

export interface TempTaskDataType {
  [taskKey: string]: ProgramContentType[];
}
export const taskDataAtom = atom<TempTaskDataType>({
  key: "tempTaskData",
  default: {},
});
