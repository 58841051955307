import { useCallback, useEffect, useState } from "react";
import TaskHeader from "components/task/header/TaskHeader";
import useGetTaskData from "hooks/useGetTaskData";
import TaskContent from "components/task/content/TaskContent";
import { Box, Stack } from "@mui/joy";
import { ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";
import useInterval from "hooks/useInterval";
import { useRecoilValue } from "recoil";
import { taskListAtom } from "recoil/taskListAtom";
import { ProgramType } from "data/programData";
import { aiApi, AI_COMMENT_DOMAIN, AI_COMMENT_LIST_ENDPOINT } from "api/aiApi";
import { patientAtom } from "recoil/patientAtom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { userAtom } from "recoil/userAtom";
import { addAiComment, isAllUserFieldFilled } from "logic/logics";
import useSetConceptData from "hooks/useSetConceptData";
import useGetCertainEventFromApp from "hooks/useGetCertainEventFromApp";

export default function Activity({
  taskKey,
  noHeader,
}: {
  taskKey: string;
  noHeader?: boolean;
}) {
  const user = useRecoilValue(userAtom);
  const isCoach =
    user?.accessToken && user?.role !== "patient" && !taskKey.includes("Tool");

  const isAiCoachProduct = user?.role === "patient" && user.isAiCoachProduct;
  const isManual = isCoach && user.isCoachAiSupportDisabled;

  const queryClient = useQueryClient();

  const taskListState = useRecoilValue(taskListAtom);
  const currentTaskState = taskListState.find(
    (element) => element.taskKey === taskKey
  );

  const patientState = useRecoilValue(patientAtom);
  const [data, setData] = useState<ProgramType | undefined>();

  const [contentData, setContentData] = useState<
    ProgramContentType[] | undefined
  >();
  const [prevContentData, setPrevContentData] = useState<
    ProgramContentType[] | undefined
  >();

  const [hasAiComment, setHasAiComment] = useState<boolean>(false);
  const { data: fetchedData, refetch } = useGetTaskData({
    taskKey: `${taskKey}`,
  });

  // 개념화노트, 생각지도 데이터 세팅
  const { isConceptNoteFetching, isConceptFetching } = useSetConceptData({
    taskKey: taskKey,
  });

  const isGetAiComment =
    ((isCoach ? !isManual : user?.isInAutoProcessingStage) &&
      contentData &&
      currentTaskState?.isOpen &&
      isAllUserFieldFilled(contentData)) ||
    false;

  const getCommentListQueryFn = useCallback(
    () => aiApi.getCommentList(`${patientState?.patientId}`, taskKey),
    [patientState, taskKey]
  );

  useQuery(
    [AI_COMMENT_DOMAIN, AI_COMMENT_LIST_ENDPOINT, taskKey],
    getCommentListQueryFn,
    {
      enabled: isGetAiComment,
      onSuccess: (data) => {
        const aiCommentList = data.result;
        // const aiCommentList = [
        //   {
        //     editorKey: "b9a4856f-d5cc-4bb5-b584-150e944f6d2a",
        //     commentList: [
        //       { aiCommentKey: "11", comment: "코멘트 블라블라 1" },
        //       { aiCommentKey: "22", comment: "코멘트 블라블라 2" },
        //     ],
        //   },
        //   {
        //     editorKey: "353add40-cdee-4dc8-b809-a33c048f8833",
        //     commentList: [{ aiCommentKey: "33", comment: "코멘트 블라블라 3" }],
        //   },
        // ];

        setHasAiComment((aiCommentList || []).length > 0);
        addAiComment(setContentData, aiCommentList);
      },
    }
  );

  useEffect(() => {
    setHasAiComment(false);
  }, [taskKey]);

  useInterval(
    () => {
      setPrevContentData(contentData);
      //변화 없으면 다시 가져와
      if (
        contentData &&
        prevContentData &&
        _.isEqual(contentData, prevContentData)
      ) {
        refetch();
      }
    },
    10000,
    []
  );

  const refetchActivityEvent = useGetCertainEventFromApp({
    eventName: "refetch_activity",
  });

  useEffect(() => {
    if (refetchActivityEvent) {
      refetch();
    }
  }, [refetchActivityEvent]);

  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData);
      queryClient.invalidateQueries([
        AI_COMMENT_DOMAIN,
        AI_COMMENT_LIST_ENDPOINT,
        taskKey,
      ]);
    }
  }, [fetchedData]);

  useEffect(() => {
    setContentData(data?.content);
  }, [data]);

  const [moveToIndex, setMoveToIndex] = useState<number | undefined>(undefined);

  return (
    <>
      <Box
        sx={{
          flex: "none",
          zIndex: 100,
          // px: { xs: "16px", sm: "24px" },
        }}
      >
        {!noHeader && (
          <TaskHeader
            taskKey={taskKey}
            contentData={contentData}
            setContentData={setContentData}
            setData={setData}
            setMoveToIndex={setMoveToIndex}
            isDone={currentTaskState?.isDone}
            {...(isGetAiComment && !hasAiComment && { canMoveOn: true })}
            isInitialized={!data}
          />
        )}
      </Box>

      <Stack sx={{ flex: 1, overflow: "hidden" }}>
        <TaskContent
          taskKey={taskKey}
          data={contentData}
          setData={setContentData}
          moveToIndex={moveToIndex}
          setMoveToIndex={setMoveToIndex}
          isDone={currentTaskState?.isDone}
          translationVersion={data?.translationVersion}
          isConceptFetching={isConceptNoteFetching || isConceptFetching}
        />
      </Stack>
    </>
  );
}
