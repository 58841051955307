import { Button } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { sendMessage } from "utils/webview";

export default function ButtonLink({
  disabled,
  text,
  url,
}: {
  disabled: boolean;
  text: string;
  url: string;
}) {
  return (
    <Button
      onClick={() => {
        sendMessage(
          JSON.stringify({
            event: "open_link",
            params: {
              url: url,
            },
          })
        );
      }}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}
