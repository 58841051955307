export const translation_3_1 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "앞서 생각이 자동적이라는 사실을 배웠습니다.",
      typography_1_0_0:
        "오늘은 생각에 관한 흥미로운 비밀을 하나 더 배워보려고 합니다.",
      typography_2_0_0: "이 또한 경험적인 방식으로 알아보려고 하는데요.",
      typography_3_0_0:
        "다시 한번 강조하지만, 생각만으로는 생각을 이해할 수 없습니다.",
      typography_4_0_0:
        "생각을 이해하기 위해서는 경험적인 지식을 하나씩 쌓아나가는 게 매우 중요합니다.",
      typography_5_0_0: "꼭 아래 지시대로 실천해보세요.",
      typography_6_0_0: "레몬",
      typography_7_0_0: "지금부터 레몬을 상상해 봅시다.",
      typography_8_0_0: "아주 신선하고 매끄러운 레몬을 떠올려 보세요.",
      typography_9_0_0: "노란쌕 껍질에는 물방울이 맺혀있습니다.",
      typography_10_0_0: "이번엔 칼로 레몬을 반으로 잘라봅시다.",
      typography_11_0_0: "과즙이 세어나오며 레몬이 반으로 잘립니다.",
      typography_12_0_0:
        "투명한 노란빛의 과육이 탱글탱글하게 빛을 반사해냅니다.",
      typography_13_0_0: "이제 레몬을 입으로 가져갑니다.",
      typography_14_0_0: "그리고 크게 한입 베어 물어봅니다.",
      typography_15_0_0: "아주 싱그러운 레몬을 크게 한입 베어 뭅니다.",
      typography_16_0_0: "생생한 레몬",
      typography_17_0_0:
        "어땠나요? 아마 위의 실험을 제대로 행했다면, 입 안에 침이 고였을 것입니다.",
      typography_18_0_0:
        "그런데 이상하지 않나요? 우리는 어떠한 레몬도 실제로 보지도, 만지지도, 맛보지도 않았습니다.",
      typography_19_0_0:
        "우리가 한 것이라곤 그저 레몬을 머리속에서 떠올렸을 뿐입니다.",
      typography_20_0_0: "그런데 침샘은 자연스럽게 침을 분비해냈죠.",
      typography_21_0_0: "확실히 레몬은 실재하지 않았습니다.",
      typography_22_0_0:
        "그런데 우리의 몸과 머리는 레몬이 실재하는 것처럼 여겨버렸네요.",
      typography_23_0_0: "상징적인 효과",
      typography_24_0_0: "이처럼 생각은 상징적인 효과를 지닙니다.",
      typography_25_0_0:
        "우리는 어떤 이름을 듣기만 해도, 어떤 이미지를 보기만 해도, 어떤 생각을 하기만 해도, 그 대상을 아주 생생하게 떠올릴 수 있습니다.",
      typography_26_0_0:
        "인간에게는 내면에서 벌어지는 정신적 사건을 마치 실제 사건으로 만들어버리는 능력이 있습니다.",
      typography_27_0_0:
        "이는 인간이 진화 과정에서 얻게된 아주 특별한 능력입니다.",
      typography_28_0_0: "사실 이러한 능력은 아주 유용합니다.",
      typography_29_0_0:
        "이 때문에 인간들을 추상적 개념을 약속하며 종교와 과학이 있는 지구상의 유일한 종이 되었습니다.",
      typography_30_0_0:
        "하지만 이 상징적 효과는 양날의 검이 되어, 우리에게 심리적 괴로움을 주기도 합니다.",
      typography_31_0_0:
        "다음 활동에서는 생각의 상징적 효과가 어떻게 우리를 괴롭히는지 살펴보도록 하죠.",
    },
  },
};
