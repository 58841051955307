import { automaticThoughtIdType } from "api/conceptApi";
import { SelectIntermediateBelief, ValueCompass } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithButtonGroup,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithTextarea,
} from "../../BlockComponent";

import {
  AutomaticThought,
  LastCard,
  MyAutomaticThoughtLines,
  NotificationOnceAndInstructionBlocks,
  ConceptItem,
} from "../common";

export const Content_6_12_A = (
  isPushNotificationGranted: boolean,
  automaticThoughtId: automaticThoughtIdType
) => {
  return [
    Instruction(`typography_0`),
    Instruction(`typography_1`),
    InstructionWithContent(
      `typography_2_${automaticThoughtId}`,
      AutomaticThought({ id: automaticThoughtId })
    ),
    InstructionWithData(
      { A: { id: `automaticThought${automaticThoughtId}_thoughtTrap` } },
      `typography_3`
    ),
    Instruction(`typography_4`),
    Instruction(`typography_5`),
    Instruction(`typography_6`),

    Header1(`typography_7`),
    InstructionWithContent(
      `typography_8`,
      ConceptItem({ conceptKey: "intermediateBelief" })
    ),
    InstructionWithContent(`typography_9`, [
      [SelectIntermediateBelief({ id: "intermediateBelief" })],
    ]),
    InstructionWithContent(
      `typography_10`,
      ConceptItem({ conceptKey: "coreBelief" })
    ),

    InstructionWithButtonGroup(
      `typography_11`,
      [
        {
          text: "과잉보상",
        },
        {
          text: "회피",
        },
        {
          text: "굴복",
        },
      ],
      { id: "copingStrategy" }
    ),

    InstructionWithContent(`typography_12`, MyAutomaticThoughtLines()),
    InstructionWithTextarea(`typography_13`),
    InstructionWithTextarea(`typography_14`),

    Header1(`typography_15`),
    Instruction(`typography_16`),
    Instruction(`typography_17`),
    Instruction(`typography_18`),
    Instruction(`typography_19`),
    Instruction(`typography_20`),
    Instruction(`typography_21`),
    Instruction(`typography_22`),

    InstructionWithButtonGroup(`typography_23`, [
      {
        text: "네, 준비됐어요",
        showFromId: "명상 진행",
        hideFromId: "명상 미진행",
      },
      {
        text: "아니요, 힘들 것 같아요",
        showFromId: "명상 미진행",
        hideFromId: "명상 진행",
      },
    ]),

    Instruction(`typography_24`, { blockId: "명상 진행" }),
    ...NotificationOnceAndInstructionBlocks(
      isPushNotificationGranted,
      "1",
      "distancing_thoughtmap_25min",
      true
    ),
    InstructionWithTextarea("meditation_thought_0"),

    Header1(`typography_25`, { blockId: "명상 미진행" }),
    Instruction(`typography_26`),
    Instruction(`typography_27`),
    Instruction(`typography_28`),
    Instruction(`typography_29`),
    Instruction(`typography_30`),
    InstructionWithContent(`typography_31`, [ValueCompass()]),
    InstructionWithTextarea(`typography_32`),
    Instruction(`typography_33`),
    Instruction(`typography_34`),
    Instruction(`typography_35`),
    Instruction(`typography_36`),
    Instruction(`typography_37`),

    ...LastCard,
  ];
};
