import { translation_4_5_A } from "./4-5-A";
export const translation_4_5_4_A = {
  en: {
    v1: {
      ...translation_4_5_A.en.v1.start,
      typography_0_0_0: "Stepping back from Judgment",
      typography_1_0_0: "Stepping back from Judgment involves three steps:",
      typography_1_3_0:
        "Notice when you're making judgments in your mind. Try to tell the difference between description and your judgments.",
      typography_1_5_0:
        "Figure out how these judgments can negatively affect you.",
      typography_1_7_0:
        "Create some space from these judgments and choose responses that align better with your values.",
      typography_2_0_0:
        "Let's tackle this one step at a time. First, let’s revisit the automatic thoughts.",
      typography_3_0_0:
        "Can we separate descriptions and judgments here? First, write down the descriptions from your record, then jot down your personal judgments about them. If your record doesn't clearly show judgments like 'good' or 'bad', feel free to write in more detail now.",
      typography_4_0_0:
        "Are these judgments really the same as me? Can I say they are absolute truths about me?",
      typography_5_0_0:
        "Now, think about the impact of accepting these judgments as part of who you are.",
      typographyFromData_6_0_0:
        'If you accept "$A$" as it is, what limitations might you create in your thoughts and actions?',
      typographyFromData_7_0_0:
        'If you accept "$A$" as it is, how might you end up overthinking it and wasting energy?',
      typographyFromData_8_0_0:
        'If you accept "$A$" as it is, what kind of story might you create about yourself?',
      typographyFromData_9_0_0: `If you decide not to react to "$A$", where would you rather spend that energy and time?`,
      typographyFromData_10_0_0: `Now, try rewriting "$A$" from a perspective of distancing. Here's an example to guide you.`,
      typography_10_4_0:
        "Darn, I feel so worthless. I wish I could just disappear.",
      typography_10_6_0:
        "I'm having the thought that 'I'm so worthless I want to disappear.'",
      typography_10_10_0: "I can't believe I got a bad score, I'm such a fool.",
      typography_10_12_0:
        "In my mind, I'm recognizing the thought 'I'm such a fool.'",
      typography_11_0_0:
        "Read the rewritten Judgment again. Close your eyes for about 10 seconds, take deep breaths, and imagine the distance between you and your thoughts. It really helps to visualize it!",
      ...translation_4_5_A.en.v1.end,
    },
  },
  ko: {
    v1: {
      ...translation_4_5_A.ko.v1.start,
      typography_0_0_0: "평가로부터 거리두기",
      typography_1_0_0: "평가로부터 거리두기는 다음과 같은 세 단계로 진행돼요.",
      typography_1_3_0:
        "마음속에 평가가 떠오른다는 것을 알아차리고 평가와 기술을 구분한다.",
      typography_1_3_1:
        "마음속에 평가가 떠오른다는 것을 알아차리고 평가와 사실을 구분한다.",
      typography_1_5_0: "평가가 어떻게 부정적인 영향을 발휘하는지 파악한다.",
      typography_1_7_0:
        "평가로부터 거리를 두고 다시 나의 가치에 맞는 반응을 선택한다.",
      typography_2_0_0:
        "하나씩 해볼게요. 자, 우선 아래 자동적 사고를 다시 읽어보세요.",
      typography_3_0_0:
        "여기서 기술과 평가를 구분해 볼까요? 우선 기록에서 기술을 찾아서 적어본 후, 그 기술에 대한 나의 평가를 적어보세요. 만약 기록에 좋다, 나쁘다 등과 같은 가치 판단인 평가가 명확하게 담겨있지 않다면 지금 더 자세히 적어봐도 좋아요.",
      typography_3_1_0:
        "여기서 사실과 평가를 구분해 볼까요? 우선 기록에서 사실을 찾아서 적어본 후, 그 사실에 대한 나의 평가를 적어보세요. 만약 기록에 좋다, 나쁘다 등과 같은 가치 판단인 평가가 명확하게 담겨있지 않다면 지금 더 자세히 적어봐도 좋아요.",
      typography_4_0_0:
        "위에서 적은 평가가 나와 동일하다고, 또는 평가가 내가 가진 절대적인 특성이라 이야기할 수 있을까요?",
      typography_5_0_0:
        "그러면 이러한 평가를 그대로 나의 특성으로 받아들이면 어떠한 영향이 있는지 파악해볼까요?",
      typographyFromData_6_0_0:
        '"$A$"(을)를 그대로 받아들이면, 이후 나의 생각이나 행동에는 어떠한 제약이 발생할까요?',
      typographyFromData_7_0_0:
        '"$A$"(을)를 그대로 받아들이면, 이후 나는 어떤식으로 이 평가를 끊임없이 곱씹으며 에너지를 낭비하게 될까요?',
      typographyFromData_8_0_0:
        '"$A$"(을)를 그대로 받아들이면, 이후 나에 대해 어떤 이야기를 만들게 될까요?',
      typographyFromData_9_0_0:
        '만약 내가 "$A$"(이)라는 평가에 반응하지 않기로 선택할 수 있다면, 나는 그 에너지와 시간을 어느 곳에 사용하고 싶나요?',
      typographyFromData_10_0_0:
        '마음속에 떠오른 "$A$"을(를) 거리두기를 통해 바라본 표현으로 다시 바꿔 적어보세요. 아래와 같은 예시를 참고해보세요.',
      typography_10_4_0: "젠장, 너무 한심하네. 사라져 버리고 싶어.",
      typography_10_6_0:
        "나는 '너무 한심해서 사라지고 싶다'는 생각을 하고 있다.",
      typography_10_10_0: "좋은 점수를 받지 못했다니, 나는 정말 바보 같다.",
      typography_10_12_0:
        "나의 마음속엔 '나는 정말 바보 같다'라는 평가가 떠올랐다.",
      typography_11_0_0:
        "거리두기를 통해 바꿔 적어본 평가를 다시 읽어보세요. 잠시 눈을 감고 약 10초 동안 심호흡을 하며 생각과 나 사이의 거리감을 상상해 보면 더 좋아요.",
      ...translation_4_5_A.ko.v1.end,
    },
  },
};
