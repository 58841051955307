import { Stack } from '@mui/joy';
import useIsInappWebview from 'hooks/useIsInappWebview';
import OverviewItemV2 from './OverviewItemV2';

export default function OverviewV2() {
  const isInappWebview = useIsInappWebview();

  return (
    <Stack
      direction={isInappWebview ? 'row' : 'column'}
      justifyContent='center'
      spacing='8px'
      sx={{
        width: '100%',
      }}
    >
      {['progress'].map((each, index) => (
        <Stack sx={{ flex: 1 }}>
          <OverviewItemV2 taskKey={each} />
        </Stack>
      ))}
    </Stack>
  );
}
