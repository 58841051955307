export const translation_9_1_A = {
  en: {},
  ko: {
    v1: {
      typography_0:
        "지금까지 자동적 사고를 찾고 다루는 작업까지 진행해 보았을 텐데요.",
      typography_1: "좀 어떠셨나요?",
      typography_2: "마음처럼 되지 않았다고 해도 괜찮아요.",
      typography_3:
        "계속 연습하다 보면 생각함정에 빠지지 않고 생각과 거리를 둘 수 있을 거랍니다.",
      typography_4:
        "많은 분들이 자동적 사고에서 생각함정을 찾고 나면 다음과 같은 질문을 해요.",
      typography_5: `“그러면 더 올바른 생각은 무엇일까요?”, “어떻게 하면 이런 생각을 안 할 수 있을까요?”`,
      typography_6: "사실 이런 질문은 아주 자연스러운 것인데요.",
      typography_7: "우리 마음은 문제 해결적인 본능을 지니고 있기 때문이에요.",
      typography_8: `외부 세계의 문제 해결`,
      typography_9: "한 가지 재밌는 실험을 해볼게요.",
      typography_10: "눈앞에 책상이 놓여있다고 상상해보세요.",
      typography_11: `깨끗하고 넓은 책상이에요.`,
      typography_12: "나는 책상에서 공부를 하려고 해요.",
      typography_13: "그런데 책상에 못이 하나 박혀있네요.",
      typography_14:
        "반쯤 박혀있는 못은 위험하기도 하고, 걸리적거려서 공부를 하는 데에 방해가 되네요.",
      typography_15: "마침 책상 한 켠에는 망치가 놓여있어요.",
      typography_16: "망치의 뒤쪽 부분에는 못을 뺄 수 있는 홈이 있군요.",
      typography_17:
        "자, 이제 잠시 눈을 감고 머릿속에서 문제를 해결해 보시길 바랄게요.",
      typography_18: "딱 10초만 상상해볼까요? 10초 후 다음으로 넘어가세요.",
      typography_19: "좋아요. 아마 손쉽게 문제를 해결했을 거예요.",
      typography_20: "망치의 뒤쪽 부분으로 못을 뽑아내면 되죠.",
      typography_21: "우리의 마음은 이러한 일을 아주 손쉽게 해내요.",
      typography_22: "직접 할 필요도 없죠.",
      typography_23:
        "외부 세계에 대해서 우리 마음은 문제 해결에 있어 아주 천부적인 재능을 지니고 있답니다.",
      typography_24: "내부 세계의 문제 해결",
      typography_25: "이번에는 다른 실험을 해볼게요.",
      typography_26:
        "자, 잠시 눈을 감고 최근 겪었던 부정적인 경험을 떠올려보시길 바랍니다.",
      typography_27:
        "수치심, 죄책감, 자기 의심, 걱정, 후회, 외로움, 그냥 모든 것이 제대로 돌아가지 않고 있다는 느낌. 무엇이든 괜찮아요.",
      typography_28:
        "나를 괴롭혔던 생각이나 감정, 또는 기억을 떠올려보시길 바랍니다.",
      typography_29: "좋습니다. 이는 우리의 마음속에 박힌 못이에요.",
      typography_30: "자, 이제 머릿속에서 이 문제를 해결하려고 노력해볼까요?",
      typography_31:
        "이번에는 30초 정도 시간이 필요하겠네요. 자, 시작할게요. 마음속에 박힌 못을 뽑아보세요.",
      typography_32: `이번엔 어땠나요? 좀처럼 마음처럼 되지 않았을 거예요.`,
      typography_33: `“어떻게 해야 하지?”, “생각을 하지 않아야 하나?”, “해결책을 찾아볼까?”, “그때 내가 어떻게 해야 했지?”`,
      typography_34: `대부분은 이런 생각을 하며 30초를 흘려보내요.`,
      typography_35: `그리고 그 30초는 우리의 삶 속에서 300분이 되고, 300일이 되고, 300개월이 되죠.`,
      typography_36: `우리는 일평생을 그렇게 문제를 해결하기 위해 마음과 씨름하며 삶을 살아갑니다.`,
      typography_37: `내부 세계의 반대 원칙`,
      typography_38: `마음이 문제 해결 방법론을 내면에 적용하는 순간부터 문제가 발생하기 시작해요.`,
      typography_39: `외부 세계, 특히 물리적인 세계에 있어서는 “해결 방법을 찾아내서 문제를 없애라”라는 원칙이 아주 잘 작동하죠.`,
      typography_40: `하지만 같은 방식을 우리 내면에 적용하면 혼란만 깊어져요.`,
      typography_41: `어쩌면 내부 세계에서는 정확히 반대 원칙이 작동하고 있는 것 같기도 해요.`,
      typography_42: `“해결 방법을 찾아내서 문제를 없애려고 시도해라. 이제 문제는 더 깊어질 것이다”라고요.`,
      typography_43: `우리 마음속에서 생각이 어떻게 튀어오르고 어떤 효과를 지니는지,`,
      typography_44: `그리고 마음이 본질적으로 어떤 목표를 향해 작동하는지를 이해한다면 우린 다음과 같은 결론을 내릴 수 있겠죠.`,
      typography_45: `“마음속에 떠오른 것들은 하나의 사건일 뿐이다. 문제 해결을 위해 애쓰지 않아도 된다. 오히려 상황만 악화될 뿐이다.”`,
      typography_46: `그럼에도 우리는 마음속에 떠오르는 생각과 감정을 없애거나, 통제하거나, 대체하려고 많은 노력을 기울이는데요.`,
      typography_47: `여러분도 예외가 아닐 거예요.`,
      typography_48: `한번 같이 살펴볼까요?`,
    },
  },
};
