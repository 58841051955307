import {
  automaticThoughtIdType,
  ConceptKeyType_automaticThought,
} from "api/conceptApi";
import {
  GuideTypographyLine,
  Select,
  Thoughtmap,
  TypographyFromConceptNoteLine,
} from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithButtonGroup,
  InstructionWithContent,
  InstructionWithTextarea,
} from "../../BlockComponent";

import { AutomaticThought, CopingStrategyTemplate, LastCard } from "../common";

export const Content_6_99_A = [
  Instruction(`typography_0_0_0`),
  Instruction(`typography_1_0_0`),
  Instruction(`typography_2_0_0`),
  Instruction(`typography_3_0_0`),
  Instruction(`typography_4_0_0`),

  ...Array.from(
    { length: 3 },
    (v, i) => `${i + 1}` as automaticThoughtIdType
  ).flatMap((each) => [
    Header1(`typography_common_0_0_0_${each}`),
    InstructionWithContent(
      `typography_common_1_0_0`,
      AutomaticThought({ id: each })
    ),

    InstructionWithContent(`typography_common_2_0_0`, [
      ...CopingStrategyTemplate(),
      [
        Select(
          [
            {
              translationKey: "굴복",
            },
            {
              translationKey: "과잉보상",
            },
            {
              translationKey: "회피",
            },
          ],
          { id: `자동적 사고 대처전략_${each}` }
        ),
      ],

      GuideTypographyLine("typography_common_2_3_0", {
        answerList: [{ id: `자동적 사고 대처전략_${each}` }],
      }),
    ]),
    InstructionWithContent(
      `typography_common_3_0_0`,
      [
        // [
        //   Select(
        //     [
        //       {
        //         translationKey: "굴복",
        //       },
        //       {
        //         translationKey: "과잉보상",
        //       },
        //       {
        //         translationKey: "회피",
        //       },
        //     ],
        //     {
        //       id: `자동적 사고 대처전략_${each}-answer`,
        //       coach: true,
        //     }
        //   ),
        // ],

        TypographyFromConceptNoteLine(
          `automaticThought${each}` as ConceptKeyType_automaticThought,
          "copingStrategy",
          { id: `자동적 사고 대처전략_${each}-answer`, coach: true }
        ),
      ],
      { hideIfPatient: true }
    ),
  ]),

  InstructionWithButtonGroup(`typography_5_0_0`, [
    {
      translationKey: "네",
      showFromId: "동의",
      hideFromId: "비동의",
    },
    {
      translationKey: "아니요",
      showFromId: "비동의",
      hideFromId: "동의",
    },
  ]),
  InstructionWithTextarea(`typography_6_0_0`, undefined, {
    blockId: "비동의",
  }),
  Instruction(`typography_7_0_0`),
  Instruction(`typography_8_0_0`, { blockId: "동의" }),
  Instruction(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),
  InstructionWithContent("typography_11_0_0", [Thoughtmap()]),
  Instruction(`typography_12_0_0`),
  Instruction(`typography_13_0_0`),

  ...LastCard,
];
