import { isDev } from "config";
import { PATH } from "routes/paths";

export const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: `${window.location.origin}/${PATH.emailAuthResult}`,
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: isDev
      ? "dev.orwellhealth.distancing"
      : "com.orwellhealth.distancing",
  },
  android: {
    packageName: isDev
      ? "dev.orwellhealth.distancing"
      : "com.orwellhealth.distancing",
    installApp: true,
    minimumVersion: "32",
  },
  dynamicLinkDomain: `distancing${isDev ? "dev" : "prod"}.page.link`,
};
