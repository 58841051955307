import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import {
  AutomaticThoughtKeyType,
  CopingStrategyKeyType,
  AutomaticThoughtType,
  CopingStrategyType,
  CoreBeliefKeyType,
  CoreBeliefType,
  ConceptKeyType,
  IntermediateBeliefType,
  IntermediateBeliefKeyType,
  AutomaticThoughtForValueKeyType,
  AutomaticThoughtForValueType,
} from "api/conceptApi";
import { ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";
import TextareaCell from "./TextareaCell";
import SelectCell from "./SelectCell";
import { conceptNoteAtom } from "recoil/conceptNoteAtom";
import { setProgramContentData } from "logic/logics";

export default function TypographyFromConceptNoteCell({
  taskKey,
  initialHtmlString,
  initialValue,
  isCoach,
  blockIndex,
  setData,
  lineIndex,
  cellIndex,
  editorKey,
  id1,
  id2,
}: {
  taskKey: string;
  initialHtmlString?: string;
  initialValue?: string;
  isCoach?: boolean;
  blockIndex: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  editorKey?: string;
  id1: ConceptKeyType;
  id2?:
    | AutomaticThoughtKeyType
    | AutomaticThoughtForValueKeyType
    | CopingStrategyKeyType
    | IntermediateBeliefKeyType
    | CoreBeliefKeyType;
}) {
  const conceptNoteState = useRecoilValue(conceptNoteAtom);

  useEffect(() => {
    if (conceptNoteState) {
      // console.log("TypographyFromConceptNoteCell:", conceptNoteState);
      const value = id2
        ? (
            (conceptNoteState[id1] || {}) as AutomaticThoughtType &
              AutomaticThoughtForValueType &
              CopingStrategyType &
              IntermediateBeliefType &
              CoreBeliefType
          )[id2]
        : ((conceptNoteState[id1] || "") as string);
      if (value !== initialValue) {
        console.log("[TEST]", value, initialValue);
        setProgramContentData({
          setData,
          blockIndex,
          lineIndex,
          cellIndex,
          newlyAddedData: {
            htmlString: value ? `<p>${value}</p>` : undefined,
            value: value,
          },
        });
      }
    }
  }, [conceptNoteState]);

  const copingStrategyList = ["굴복", "과잉보상", "회피"];

  return id2 === "copingStrategy" ? (
    <SelectCell
      defaultValue={initialValue}
      selectedIndex={copingStrategyList.findIndex(
        (element) => element === initialValue
      )}
      blockIndex={blockIndex}
      options={copingStrategyList}
      setData={setData}
      lineIndex={lineIndex}
      cellIndex={cellIndex}
      isCoachField
      isCoach={isCoach}
    />
  ) : (
    // <TypographyCell text={initialValue} />
    <TextareaCell
      initialHtmlString={initialHtmlString}
      initialValue={initialValue}
      isCoachField
      isCoach={isCoach}
      setData={setData}
      lineIndex={lineIndex}
      cellIndex={cellIndex}
      blockIndex={blockIndex}
      editorKey={editorKey}
      readOnly
    />
    // <TypographyCell text={initialValue} />
  );
}
