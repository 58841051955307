export const translation_6_99_A = {
  en: {
    v1: {
      네: "Yes",
      아니요: "No",
      굴복: "Surrender",
      과잉보상: "Overcompensation",
      회피: "Avoidance",
      typography_0_0_0:
        "Automatic thoughts are shaped by core beliefs and the coping strategies. You know this, right?",
      typography_1_0_0:
        "So, automatic thoughts often fit into specific types of coping strategies.",
      typography_2_0_0:
        "Today, let's figure out which coping strategy my automatic thoughts are linked to.",
      typography_3_0_0: "After this, you'll see your Thought Map halfway done.",
      typography_4_0_0: "Ready to start?",
      typography_common_0_0_0_1: "First automatic thought",
      typography_common_1_0_0:
        "Let's take a look at this pattern of automatic thought.",
      typography_common_2_0_0:
        "Which of the three coping strategies do you think it fits into?",
      typography_common_2_3_0: "Shall we think it over again?",
      typography_common_3_0_0: "Please choose the answer.",
      typography_common_0_0_0_2: "Second automatic thought",
      typography_common_0_0_0_3: "Third automatic thought",
      typography_5_0_0:
        "Do you think your automatic thoughts and coping strategies are well connected?",
      typography_6_0_0: `Why do you think that?`,
      typography_7_0_0:
        "That's all for today. I'll review and get back to you.",
      typography_8_0_0:
        "Well done. It seems like you chose the right coping strategies for the automatic thoughts.",
      typography_9_0_0:
        "Being able to know which coping strategy your automatic thoughts are related to is a good sign.",
      typography_10_0_0:
        "I believe you'll get better at distancing yourself from your thoughts as you continue to draw your thought map.",
      typography_11_0_0:
        "Let's take a look at what we've done on the thought map so far.",
      typography_12_0_0: `Next time, we'll focus on identifying core beliefs.`,
      typography_13_0_0: "Shall we wrap up here today?",
    },
  },
  ko: {
    v1: {
      네: "네",
      아니요: "아니요",
      굴복: "굴복",
      과잉보상: "과잉보상",
      회피: "회피",
      typography_0_0_0:
        "자동적 사고는 핵심 믿음과 대처 전략으로 만들어진 생각이에요. 기억하시죠?",
      typography_1_0_0:
        "따라서 자동적 사고는 흔히 특정한 형태의 대처 전략에 속할 때가 많은데요.",
      typography_2_0_0:
        "오늘은 나의 자동적 사고가 대처 전략 중 어느것에 해당하는지 살펴볼 거예요.",
      typography_3_0_0:
        "활동이 끝나고 나면 절반 쯤 그려진 생각 지도를 확인할 수 있을 거예요.",
      typography_4_0_0: "자, 그러면 시작해 볼까요?",

      typography_common_0_0_0_1: "첫 번째 자동적 사고",
      typography_common_1_0_0: "아래 자동적 사고 패턴을 한번 살펴볼까요?",
      typography_common_2_0_0:
        "위 패턴은 세 가지 대처 전략 중 어디에 속하는 것 같나요?",
      typography_common_2_3_0: "다시 한 번 생각해볼까요?",
      typography_common_3_0_0: "정답을 선택해주세요.",

      typography_common_0_0_0_2: "두 번째 자동적 사고",
      typography_common_0_0_0_3: "세 번째 자동적 사고",

      typography_5_0_0: "나의 자동적 사고와 대처전략이 잘 연결된 것 같나요?",
      typography_6_0_0: `어떤 점에서 그렇게 생각하나요?`,
      typography_7_0_0:
        "오늘은 여기까지 하도록 해요. 제가 확인 후 다시 말씀드릴게요.",
      typography_8_0_0:
        "좋아요. 세 가지 자동적에 대한 대처 전략은 모두 적절하게 잘 선택하신 것 같네요.",
      typography_9_0_0:
        "나의 자동적 사고가 어떤 대처 전략에 해당하는지 스스로 잘 파악할 수 있다는 건 좋은 신호랍니다.",
      typography_10_0_0:
        "앞으로 생각 지도를 그려나가며 생각으로부터 거리두는 일을 더 잘하실 수 있을 거라 믿어요.",
      typography_11_0_0: "우선 지금까지 그려진 나의 생각 지도를 살펴볼까요?",
      typography_12_0_0: `다음 활동에서는 핵심 믿음을 찾아볼 거예요.`,
      typography_13_0_0: "자, 그러면 우선 여기 마무리 할까요?",
    },
  },
};
