import { experimental_extendTheme } from "@mui/material/styles";

export const materialTheme = experimental_extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: "#479683",
        },
        text: { primary: "#232832" },
      },
    },
    dark: {
      palette: {
        primary: {
          main: "#259F82",
        },
        text: { primary: "#E0E4EC" },
      },
    },
  },
});
