export const translation_0_1 = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0: "우리는 왜 이다지도 괴로운 걸까요?",
      typography_1_0_0: '"저는 원래 소심해서..."',
      typography_2_0_0: `"저는 어렸을 때 이런 일이 있었거든요."`,
      typography_3_0_0: '"글쎄요. 어쩌면 제 정신에 문제가 있는 게 아닐까요?"',
      typography_4_0_0:
        "지금은 뭐든 좋습니다. 하지만 자기 비난은 접어두고 조금 더 큰 시야로 살펴봅시다.",
      typography_5_0_0: "진화적 관점에서",
      typography_6_0_0: "인간은 어떻게 지구의 정복자가 되었을까요?",
      typography_7_0_0: '바로 인간의 "문제 해결의 뇌" 때문입니다.',
      typography_8_0_0: `우리는 상상하고, 계획하고, 추론하며 수많은 문제를 해결할 수 있습니다.`,
      typography_9_0_0:
        "우리는 먹어보거나, 만져보거나, 직접 보지 않아도, 문제를 해결하며 더 나은 미래를 만들어나갈 수 있습니다.",
      typography_10_0_0: '하지만 이 "문제 해결의 뇌"는 양날의 검입니다.',
      typography_11_0_0:
        "미래를 예측하고 과거를 반성할 수 있는 똑똑한 인간의 뇌는 오늘날 큰 부작용을 낳고 말았습니다.",
      typography_12_0_0:
        "불안해지면 더 위협적인 것을 예측할 수 있지만, 더 위협적인 것을 예측하며 우린 더욱 불안해집니다.",
      typography_13_0_0:
        "우울해지면 자기 반성을 더 잘 할 수 있지만, 더 자기 반성을 하며 우린 더욱 우울해집니다.",
      typography_14_0_0:
        "결국 이렇게 생각과 감정을 오가는 악순환에 빠지고 마는 것이죠.",
      typography_15_0_0:
        "인간은 지구의 정복자 된 동시에, 지구에서 가장 불행한 존재가 되어버렸습니다.",
      typography_16_0_0:
        "우리는 척추 동물(vertebrate) 중 최고이지만, 동시에 끊임없이 반추(rumination)하며 생각을 곱씹는 안타까운 반추동물(ruminebrate)입니다.",
      typography_17_0_0: "틀에 갖힌 인간",
      typography_18_0_0:
        "더 불행한 사실은, 문제 해결의 뇌가 우리에게 '자기 정체성'이라는 저주를 걸었다는 것입니다.",
      typography_19_0_0:
        "우리는 그러한 정체성 때문에 더 잘 적응하고, 더 잘 기능하고, 더 효과적으로 정보를 처리할 수 있습니다.",
      typography_20_0_0:
        '"나는 사교적인 사람"이라는 정체성 때문에, 새로운 친구들 앞에서 서슴없이 장난을 치고 자기를 표현하곤 하죠.',
      typography_21_0_0:
        "하지만 동시에 우리는 끊임없이 경직된 사고에 갇히기도 합니다.",
      typography_22_0_0:
        '"나는 실패만 하는 사람"이라는 정체성 때문에 새로운 시도와 기회를 차단한 채 소극적으로 살아가는 사람들이 많습니다.',
      typography_23_0_0:
        '"이처럼 우리는 문제 해결의 뇌 때문에, 우울의 틀, 불안의 틀, 무능의 틀, 죄책감의 틀, 그리고 수치심의 틀에 갇히고 맙니다.',
      typography_24_0_0: "진화적 거래",
      typography_25_0_0: "이처럼 우리는 '문제 있는 마음'을 갖고 있습니다.",
      typography_26_0_0:
        "이는 수억년간의 진화의 과정 속에서 '문제 해결의 뇌'를 갖는 대신 지불한 대가이기도 하죠.",
      typography_27_0_0:
        "좁은 시야에서 보면 그 모든 것들이 나의 부족함 때문에 생기는 것 같지만,",
      typography_28_0_0:
        "사실 인간이 그렇게 진화해 온 것 뿐입니다. 여러분들의 잘못은 없습니다.",
      typography_29_0_0: "나의 책임",
      typography_30_0_0: "다만 하나 분명히 하고 싶은게 있습니다.",
      typography_31_0_0:
        "문제 있는 '마음'을 가진 여러분들은 아무런 잘못이 없지만,",
      typography_32_0_0: "마음을 훈련할 책임은 여러분에게 있습니다.",
      typography_33_0_0:
        "누가 뭐래도 삶은 내가 스스로 이끌어 나가는 것이니까요.",
      typography_34_0_0:
        "갑자기 번개가 쳐서 삶이라는 곳간을 파괴했다고 해봅시다.",
      typography_35_0_0:
        "이건 당신의 잘못이 아닙니다. 불가피한 자연의 산물이니까요.",
      typography_36_0_0:
        "그러나 그 삶이라는 곳간을 수리할 책임은 당신에게 있습니다.",
      typography_37_0_0: "그 책임은 누구도 대신 해줄 순 없지요.",
      typography_38_0_0: "책임감이라는 말에 부담감부터 느껴지나요?",
      typography_39_0_0:
        "걱정마세요. 번개로 망가진 곳간을 효과적으로 수리할 수 있는 뛰어난 방법이 있으니까요.",
      typography_40_0_0:
        "다음 활동에서 그 방법인 '디스턴싱'에 대해 계속 알아보도록 하죠.",
    },
  },
};
