import { useState, useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { localizationAtom } from "recoil/localizationAtom";
import { userAtom } from "recoil/userAtom";
import axios from "../utils/axios";

interface FetchResponse {
  code: number;
  result: any;
  message?: string;
  debugMessage?: string;
}

interface ErrorResponse {
  status: number;
  error: string;
  debugMessage?: string;
}

type UseFetchType = [
  FetchResponse | undefined,
  () => Promise<void>,
  ErrorResponse | undefined
];

export default function useFetch(request: {
  url: string;
  requestBody?: string;
  params?: { [key: string]: unknown };
}): UseFetchType {
  const [value, setValue] = useState<FetchResponse>();
  const params = useMemo(() => request.params || {}, [request.params]);
  const [errorData, setErrorData] = useState<ErrorResponse>();
  const user = useRecoilValue(userAtom);
  const locale = useRecoilValue(localizationAtom);

  const fetchData = useCallback(async () => {
    try {
      const response = !request.requestBody
        ? await axios(user?.accessToken, locale as string).get(request.url, {
            params: params,
          })
        : await axios(user?.accessToken, locale as string).post(
            request.url,
            request.requestBody,

            {
              params: params,
            }
          );

      if (response.data.code === 0) {
        const data = response.data;
        console.log("useFetch", request, data);
        setValue(data);
      } else {
        // console.log(response.data);
      }
    } catch (error: any) {
      // console.error("result", error.response);
      setErrorData(error.response && error.response.data);
    }
  }, [request, params]);

  return [value, fetchData, errorData];
}
