import { automaticThoughtIdType } from "api/conceptApi";
import { SolidLabelLine, TypographyFromDataLine } from "data/CellComponent";
import {
  Instruction,
  InstructionWithButtonGroup,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithTextarea,
} from "../../BlockComponent";
import { CoachActionBlock, LastCard } from "../common";

export const Content_7_8_A = (
  automaticThoughtId: automaticThoughtIdType,
  step: number
) => {
  const referenceTaskId = `7-7-A_${automaticThoughtId}#${step}`;

  return [
    InstructionWithContent("typography_0_0_0", [
      ...[
        "누구와",
        "무엇을",
        "어디서",
        "언제",
        "예상 장애물",
        "장애물 극복법",
      ].flatMap((each) => [
        SolidLabelLine(`${each}`),
        TypographyFromDataLine(
          {
            A: {
              id: each,
              taskId: referenceTaskId,
            },
          },
          "$A$"
        ),
      ]),
    ]),
    InstructionWithData(
      {
        A: {
          id: `step_${step}`,
          taskId: `7-6-A_${automaticThoughtId}`,
        },
      },
      `typography_1_0_0`
    ),

    InstructionWithButtonGroup(
      `typography_2_0_0`,
      [
        {
          text: "네",
          showFromId: "성공",
          hideFromId: "실패",
        },
        {
          text: "아니요",
          showFromId: "실패",
          hideFromId: "성공",
        },
      ],
      { id: "실험_결과_실행_여부" }
    ),
    Instruction("typography_3_0_0", { blockId: "성공" }),
    InstructionWithTextarea("typography_4_0_0", { id: "느낀 점" }),
    InstructionWithTextarea("typography_5_0_0", { id: "어려웠던 점" }),
    InstructionWithTextarea("typography_6_0_0", { id: "앞으로의 기대" }),
    Instruction("typography_7_0_0"),
    Instruction("typography_8_0_0"),
    Instruction("typography_9_0_0", { nextBlockId: "마무리" }),

    Instruction("typography_10_0_0", { blockId: "실패", isHidden: true }),
    Instruction("typography_11_0_0", { isHidden: true }),
    Instruction("typography_12_0_0", { isHidden: true }),
    Instruction("typography_13_0_0", { isHidden: true }),
    InstructionWithTextarea("typography_14_0_0", undefined, { isHidden: true }),
    ...CoachActionBlock,
    Instruction("typography_15_0_0", { blockId: "마무리" }),

    ...LastCard,
  ];
};
