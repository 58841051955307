export const translation_4_7_A = {
  en: {},
  ko: {
    v1: {
      typography_0_0_0:
        "지금까지 우리는 생각과 행동의 관계에서 행동의 중요성을 살펴보았죠.",
      typography_1_0_0:
        "행동은 생각의 영향을 받고, 생각은 다시 행동의 영향을 받아요. ",
      typography_2_0_0:
        "그래서 우리는 생각과 행동의 관계를 실험적으로 살펴보기로 했지요.",
      typography_3_0_0: "여기에는 주의할 점이 있습니다.",
      typography_4_0_0: `행동 실험은 우리가 걱정하는 일을 피하기 위한 것이 아니라는 점이죠. `,
      typography_5_0_0:
        "오히려 걱정하는 상황에 부딪히는 것이 필요할 때도 있어요.",
      typography_6_0_0:
        "걱정하는 상황에 부딪히라니, 이 얼마나 불안하고 두려운 일인가요.",
      typography_7_0_0:
        "어쩌면 이런 두려움으로 원래의 행동 말고 다른 행동을 하는 내 모습을 상상하기 어려울 수도 있죠.",
      typography_8_0_0: "그럴 때 많은 사람이 안전하고 쉬운 길을 선택하곤 해요.",
      typography_9_0_0: "예를 들어볼까요?",
      typography_10_0_0:
        "발표를 할 때면 남들이 자신을 무능하다고 생각할 것 같다며 걱정하는 사람이 있습니다.",
      typography_11_0_0: "우리는 그와 함께 행동 실험을 계획했죠.",
      typography_12_0_0: "회의 시간에 발표를 하는 것이었습니다.",
      typography_13_0_0: `하지만 그는 남들이 자신을 무능하다고 생각할 것이 두려워 회의 시간에 종이만 바라보며 미리 적어둔 말만 읊었습니다. 다른 사람들의 눈을 바라보지 않고 말이죠.`,
      typography_14_0_0: `이러한 행동을 안전행동이라고 부릅니다.`,
      typography_15_0_0: `행동 실험을 할 때 걱정되는 바를 피하고자 내가 더 안전함을 느낄 수 있는 행동을 하는 거죠.`,
      typography_16_0_0: `안전행동을 하게 되면 행동 실험의 효과는 떨어지기 마련입니다.`,
      typography_17_0_0: `설령 실험이 성공적이었다고 하더라도 “이번엔 성공적이었지만 다음엔 어떻게 될지 몰라”와 같은 생각을 가지게 되죠. `,
      typography_18_0_0: `결국 효과적이지 않은 실험이 되고 맙니다.`,
      typography_19_0_0: `조금씩 앞으로 나갈 필요가 있습니다.`,
      typography_20_0_0: `물론 행동 실험을 할 때면 힘들고, 불안하고, 무의미해 보인다는 점을 잘 알고 있습니다.`,
      typography_21_0_0: `하지만 조금만 용기를 내었으면 합니다.`,
      typography_22_0_0: `쉬운 실험부터 단계적으로 진행한다면, 당신은 곧 생각의 영향에서 벗어나 더 자유롭게 생각하고 행동할 수 있는 사람이 될지도 모르니까요.`,
      typography_23_0_0: `행동 실험은 당신을 조금씩 변화시킬 것입니다.`,
      typography_24_0_0: `두려워하는 상황에 실제로 맞닥뜨리는 것은 쉬운 일이 아니죠. `,
      typography_25_0_0: `그러나 이는 우리의 문제와 생각에 대해 더 현실적인 인식을 가질 수 있는 기회이기도 해요. `,
      typography_26_0_0: `안전 행동이 정말로 우리에게 필요한 것인지 살펴볼 기회인 것이죠.`,
      typography_27_0_0: `대부분의 사람은 이 실험을 제안하면 엄청나게 겁을 먹는데, 그것은 이런 안전 행동이 자신을 끔찍한 재난에서 보호하고 있다고 믿기 때문이죠.`,
      typography_28_0_0: `이번에도 쉽지만은 않겠지만, 잘 해낼 수 있을 거예요.`,
    },
  },
};
