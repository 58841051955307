import { useRecoilValue, useSetRecoilState } from "recoil";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import * as _ from "lodash";
import { currentTaskSectionIndexAtom } from "recoil/currentTaskSectionIndexAtom";
import { patientAtom } from "recoil/patientAtom";
import useOpenTask from "./useOpenTask";

export default function useOpenSegmentationNote(segmentationNoteKey?: string) {
  const patientState = useRecoilValue(patientAtom);
  const taskKey = `5-4:${patientState?.patientId}`;
  const currentIndex = useRecoilValue(currentTaskSectionIndexAtom);
  const setTaskSectionState = useSetRecoilState(taskSectionAtom);

  const openTask = useOpenTask(taskKey, undefined, undefined, segmentationNoteKey);

  function addToTabAndOpen() {
    openTask();
    setTaskSectionState((taskSectionState) => {
      if (taskSectionState) {
        const copy = _.cloneDeep(taskSectionState);
        copy[currentIndex] = {
          ...taskSectionState[currentIndex],
          currentSegmentationNoteKey: segmentationNoteKey,
        };
        return copy;
      }
      return taskSectionState;
    });
  }

  return addToTabAndOpen;
}