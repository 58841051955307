import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import React, { useCallback, useMemo } from "react";
import * as _ from "lodash";
import {
  thoughtRecordApi,
  THOUGHTRECORD_CONVERSATION_CONTINUE_ENDPOINT,
  THOUGHTRECORD_DOMAIN,
  THOUGHTRECORD_RAW_SUBMIT_ENDPOINT,
} from "api/thoughtRecordApi";
import {
  AIInstruction,
  AIInstructionWithTextarea,
  ProgramContentType,
} from "data/BlockComponent";

export default function useApiCallBeforeMoveon({
  trk,
  setData,
  index,
  queryKey,
  userInput,
  openNextBlock,
}: {
  trk: string;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  index: number;
  queryKey: string[];
  userInput?: string;
  openNextBlock: () => void;
}) {
  const submitRawContentQueryFn = useCallback(
    () => thoughtRecordApi.submitRaw(trk, userInput || ""),
    [trk, userInput]
  );

  const { mutateAsync: submit } = useMutation(submitRawContentQueryFn, {
    onSuccess: (data) => {
      openNextBlock();
    },
    onError: (data) => {
      console.log(data);
    },
  });

  const continueConversationQueryFn = useCallback(
    () => thoughtRecordApi.continueConversation(trk, userInput || ""),
    [trk, userInput]
  );

  const { mutateAsync: continueConversation } = useMutation(
    continueConversationQueryFn,
    {
      onSuccess: (data) => {
        if (data.result) {
          const nextMessageId = data.result.nextMessageId;

          setData((data) => {
            if (data) {
              data.splice(
                index + 1,
                0,
                // AIInstructionWithTextarea(nextMessageId)
                AIInstruction(nextMessageId)
              );
              return data;
            }
            return data;
          });
          openNextBlock();
        }
      },
    }
  );

  const action = useMemo(
    () =>
      _.isEqual(queryKey, [
        THOUGHTRECORD_DOMAIN,
        THOUGHTRECORD_RAW_SUBMIT_ENDPOINT,
      ])
        ? submit
        : _.isEqual(queryKey, [
            THOUGHTRECORD_DOMAIN,
            THOUGHTRECORD_CONVERSATION_CONTINUE_ENDPOINT,
          ])
        ? continueConversation
        : null,
    [queryKey]
  );

  return { action };
}
