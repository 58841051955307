import { useRecoilValue } from "recoil";
import { localizationAtom } from "recoil/localizationAtom";

export default function useGetUrls() {
  const locale = useRecoilValue(localizationAtom);

  const companyInfoUrl = locale.includes("ko")
    ? "https://orwellhealth.notion.site/51d3c13a5998467489e4f2b8979b629e?pvs=4"
    : "https://orwellhealth.notion.site/Distancing-Business-Information-30b14f0821ab4d4192f01441e0022f1c?pvs=4";
  const privacyPolicyUrl = locale.includes("ko")
    ? "https://orwellhealth.notion.site/318678eb371746eb9782f655bd37f65d?pvs=4"
    : "https://orwellhealth.notion.site/Distancing-Privacy-Policy-45ef2df36c1844a79fde5dbad5a74419?pvs=4";
  const servicePolicyUrl = locale.includes("ko")
    ? "https://orwellhealth.notion.site/aae0caabfaf94b208b6084bee3970847"
    : "https://orwellhealth.notion.site/Distancing-Service-Terms-and-Conditions-318a5fe897384820a7d74262d4d6084f?pvs=4";
  return {
    companyInfoUrl,
    privacyPolicyUrl,
    servicePolicyUrl,
  };
}
